import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { theme } from "@komodorio/design-system";
import { OperationResult } from "urql";

import { isFaultyResultMessage } from "../../common/utils";
import { Result } from "../../common/styles";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import {
  ExecuteGithubRevertMutationVariables,
  GithubRevertOutput,
  Maybe,
  useExecuteGithubRevertMutation,
  useLatestServiceDeployQuery,
} from "../../../../generated/graphql";
import { useQueryWithVariables } from "../../../../shared/hooks/useQueryWithVariables";
import { GitCompare } from "../../../../shared/types/git";
import {
  ActionSentMessage,
  useShouldShowActionSentMessage,
} from "../../common/useActionSent";

type GithubRevertResult = OperationResult<
  { __typename?: "mutation_root" } & {
    githubRevert?: Maybe<
      { __typename?: "githubRevertOutput" } & Pick<
        GithubRevertOutput,
        "success"
      >
    >;
  },
  ExecuteGithubRevertMutationVariables
>;

interface RevertModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  serviceId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  currentRevision?: number;
}

const isGithubDomain = (urlHost: string) => {
  return urlHost === "github.com";
};
const useGithubRevert = (
  gitCompareUrl: GitCompare | undefined,
  serviceId: string,
  cluster: string,
  namespace: string,
  deployName: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const [failureMessage, setFailureMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [result, setResult] = useState<unknown>("");
  const [, executeRevert] = useExecuteGithubRevertMutation();
  const execute = useCallback(async () => {
    if (!gitCompareUrl) return;
    const url = gitCompareUrl.url;
    setIsFetching(true);
    const res = await executeRevert({
      gitCompareUrl: url,
      serviceId: serviceId,
      cluster: cluster,
      namespace: namespace,
      deployName: deployName,
    });
    handleGitRevertResponse(res, setFailureMessage, setResult);
    setIsFetching(false);
  }, [cluster, deployName, executeRevert, gitCompareUrl, namespace, serviceId]);

  const resetGitRevert = useCallback(() => {
    setResult(undefined);
    setIsFetching(false);
    setFailureMessage("");
  }, []);
  return { execute, failureMessage, isFetching, result, resetGitRevert };
};

const handleGitRevertResponse = (
  res: GithubRevertResult,
  setFailureMessage: (message: string) => void,
  setResult: (result: unknown) => void
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  if (res.error) {
    setFailureMessage(res.error.message);
  }
  if (res.data?.githubRevert?.success) {
    setResult(res.data.githubRevert.success);
  }
};

const RevertModal: React.FC<RevertModalProps> = ({
  isModalOpen,
  handleClose,
  serviceId,
  resourceName,
  currentRevision,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [showMessageActionSent, setShowMessageActionSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { replicasOnlyAsAutomaticDeploys } = useOverridableFlags();

  const deployQueryVars = useMemo(
    () => ({
      serviceId: serviceId,
      replicasOnly: replicasOnlyAsAutomaticDeploys ? "replicas_only" : "",
    }),
    [serviceId, replicasOnlyAsAutomaticDeploys]
  );

  const latestServiceDeploy = useQueryWithVariables(
    useLatestServiceDeployQuery,
    deployQueryVars
  );

  const latestServiceDeployEventData = useMemo(() => {
    if (!latestServiceDeploy?.event_deploy?.length) {
      return;
    }
    const lastDeployEvent = latestServiceDeploy.event_deploy[0];
    const gitCompareArray = lastDeployEvent.gitCompare as [GitCompare];
    if (gitCompareArray.length < 1) {
      return;
    }
    const gitCompare = gitCompareArray[0] as GitCompare;
    const serviceId = (lastDeployEvent?.services as string[])[0] ?? "";
    const cluster = lastDeployEvent?.clusterName ?? "";
    const namespace = lastDeployEvent?.namespace ?? "";
    const deployName = lastDeployEvent.deploymentName ?? "";
    const splitPathname = gitCompare.url.split("/"); // unsplited element reference [https://github.com/komodorio/mono/compare/benchmark...master]
    if (splitPathname.length < 5) {
      return;
    }
    if (!isGithubDomain(splitPathname[2])) {
      return;
    }
    return { gitCompare, serviceId, cluster, namespace, deployName };
  }, [latestServiceDeploy]);

  const {
    execute: executeGithubRevert,
    failureMessage: githubRevertFailureMessage,
    isFetching: isGithubRevertFetching,
    result: githubRevertResult,
  } = useGithubRevert(
    latestServiceDeployEventData?.gitCompare,
    latestServiceDeployEventData?.serviceId ?? "",
    latestServiceDeployEventData?.cluster ?? "",
    latestServiceDeployEventData?.namespace ?? "",
    latestServiceDeployEventData?.deployName ?? ""
  );

  const shouldShowActionSentMessage = useShouldShowActionSentMessage(
    isGithubRevertFetching
  );

  useEffect(() => {
    setShowMessageActionSent(shouldShowActionSentMessage);
  }, [shouldShowActionSentMessage]);

  const errorOutput = errorMessage || githubRevertFailureMessage;

  useEffect(() => {
    if (githubRevertResult) {
      if (isFaultyResultMessage(githubRevertResult as string)) {
        setErrorMessage(githubRevertResult as string);
        setActionSent(false);
      } else {
        handleClose();
      }
    }
  }, [githubRevertResult, handleClose]);

  if (showMessageActionSent) {
    return (
      <ActionSentMessage
        isOpen={showMessageActionSent}
        onClose={() => {
          setShowMessageActionSent(false);
          handleClose();
        }}
      />
    );
  }

  return (
    <>
      <Modal isOpen={isModalOpen} width="28rem" onRequestClose={handleClose}>
        <ModalHeader>
          <Typography variant="headline">
            Revert Service: {resourceName}
          </Typography>
        </ModalHeader>
        <ModalContent>
          <Typography size="medium">
            This action will create a pr on your source control that will{" "}
            <b>revert</b> the service to the previous revision.{" "}
            {currentRevision !== undefined && (
              <>
                Current revision is: <b>{currentRevision}</b>
              </>
            )}
            <br />
            <br />
          </Typography>
          {errorOutput ? (
            <Result>
              <Typography color={theme.foreground.fgPink}>
                {errorOutput}
              </Typography>
            </Result>
          ) : null}
        </ModalContent>
        <ModalActions>
          <Button variant="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size="small"
            disabled={
              actionSent ||
              latestServiceDeployEventData?.gitCompare?.url === undefined
            }
            onClick={async () => {
              setErrorMessage("");
              await executeGithubRevert();
              setActionSent(true);
            }}
          >
            GitHub Revert
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RevertModal;
