import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { ContainerStatus } from "kubernetes-types/core/v1";

import Pod from "../../resources/pod";
import { LogsViewer } from "../../../common/LogsViewer/LogsViewer";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { usePodContainerLogs } from "../LogsTab/usePodContainerLogs";

import { PreviousLogs } from "@/components/common/LogsViewer/PodLogsBar/PreviousLogs";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
`;

type SelectedContainer = { value: string; label: string };

export interface PodLogsProps {
  id: string;
  cluster: string;
  namespace: string;
  name: string;
  containerStatuses?: ContainerStatus[];
  fullObj?: Pod["fullObj"];
  selectedContainer: SelectedContainer | undefined;
}

export const PodLogs: React.FC<PodLogsProps> = (pod: PodLogsProps) => {
  const {
    id,
    cluster,
    namespace,
    name,
    containerStatuses = [],
    selectedContainer,
  } = pod;
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    loading,
    logs,
    isPreviousLogs,
    setIsPreviousLogs,
    failureMessage,
    requestMoreLogs,
    tail,
    isConnectionEstablished,
  } = usePodContainerLogs(pod, selectedContainer);

  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();

  setMainContainerDivRef(containerRef);
  useEffect(() => {
    reportLoadingState("podLogs", Boolean(!isConnectionEstablished));
  }, [isConnectionEstablished, reportLoadingState]);

  const podIdentifier = useMemo(
    () => ({ id, cluster, namespace, name }),
    [id, cluster, namespace, name]
  );

  const previousLogsToggle = (
    <PreviousLogs
      pod={podIdentifier}
      setIsPreviousLogs={setIsPreviousLogs}
      isPreviousLogs={isPreviousLogs}
      containerName={selectedContainer?.value}
      containerStatuses={containerStatuses}
    />
  );

  return (
    <Container
      aria-label={AriaLabels.Inspection.Pod.LogsTab}
      ref={containerRef}
    >
      <LogsViewer
        podLogsRes={logs}
        failureMessage={failureMessage}
        loading={loading}
        podName={pod.name}
        containerName={selectedContainer?.value ?? ""}
        requestedCount={tail}
        onLoadMoreClicked={requestMoreLogs}
        previousContainerControl={previousLogsToggle}
        clusterName={cluster}
        namespace={namespace}
        showEnlargeButton={true}
      />
    </Container>
  );
};
