import React from "react";
import { useNavigate } from "react-router-dom";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { Airflow } from "@komodorio/design-system/icons/logos/Airflow";
import { Argo } from "@komodorio/design-system/icons/logos/Argo";
import { Workflows24, Spark } from "@komodorio/design-system/icons";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import Tab from "@mui/material/Tab";

import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { WorkflowsRoutes } from "@/components/k8sAddons/addons/workflows/types";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const tooltipText = (engineName: string) =>
  `Connect a cluster with ${engineName} to begin using this feature`;

type TabLabelType = { icon: React.ReactElement; text: string };
const tabs: Record<WorkflowsRoutes, TabLabelType> = {
  [WorkflowsRoutes.airflow]: {
    icon: <Airflow />,
    text: "Airflow",
  },
  [WorkflowsRoutes.spark]: {
    icon: <Spark />,
    text: "Spark",
  },
  [WorkflowsRoutes.argo]: {
    icon: <Argo />,
    text: "Argo Workflow",
  },
  [WorkflowsRoutes.custom]: {
    icon: <Workflows24 />,
    text: "Custom Workflow",
  },
};

export const WorkflowsTabs: React.FC<{
  tabValue: WorkflowsRoutes;
  engines: Record<WorkflowsRoutes, boolean>;
  children: React.ReactNode;
}> = ({ tabValue, engines, children }) => {
  const navigate = useNavigate();
  const { sparkWorkflows: sparkWorkflowsFF } = useOverridableFlags();

  const onTabChange = (_: React.SyntheticEvent, newValue: WorkflowsRoutes) => {
    if (tabValue === newValue) return;
    navigate(`../${newValue}`);
  };

  return (
    <ResponsiveLayout>
      <TabContext value={tabValue}>
        <TabList onChange={onTabChange} sx={{ marginTop: "16px" }}>
          {Object.entries(tabs).map(([key, { icon, text }]) => {
            const route = key as WorkflowsRoutes;
            if (route === WorkflowsRoutes.spark && !sparkWorkflowsFF) {
              return null;
            }
            return (
              <Tab
                key={route}
                icon={icon}
                iconPosition="start"
                label={
                  <LightTooltip
                    title={!engines[route] ? tooltipText(text) : ""}
                    slotProps={{
                      popper: { sx: { maxWidth: "225px" } },
                    }}
                    placement="top"
                  >
                    <div>{text}</div>
                  </LightTooltip>
                }
                disabled={!engines[route]}
                value={route}
                sx={{
                  minHeight: "unset",
                  "&.MuiTab-root.Mui-disabled": { pointerEvents: "auto" },
                }}
                aria-label={`${route} tab`}
              />
            );
          })}
        </TabList>
      </TabContext>
      {children}
    </ResponsiveLayout>
  );
};
