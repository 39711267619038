import { useCallback, useEffect, useState } from "react";

import { useNamespacesFilter } from "@/components/Inspection/InspectionViews/inpectionViewsHooks";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

type Props = {
  namespaces: string[];
  isAwaitingNamespacesChange: boolean;
  resourceKeyInLS?: string;
};

export const useSetInitialNamespace = ({
  namespaces,
  isAwaitingNamespacesChange,
  resourceKeyInLS,
}: Props) => {
  const { oneNamespaceByDefaultInHelm } = useOverridableFlags();
  const [selectedNamespaces, setSelectedNamespaces] =
    useNamespacesFilter(resourceKeyInLS);
  const [initialNamespace, setInitialNamespace] = useState<string | undefined>(
    undefined
  );

  const resetInitialNamespace = useCallback(() => {
    setInitialNamespace(undefined);
    setSelectedNamespaces([]);
  }, [setSelectedNamespaces]);

  useEffect(() => {
    if (isAwaitingNamespacesChange || !oneNamespaceByDefaultInHelm) {
      return;
    }
    if (!namespaces.length) {
      setInitialNamespace(undefined);
      return;
    }

    if (initialNamespace) {
      return;
    }
    // select namespace from session user selection
    if (selectedNamespaces.length) {
      setSelectedNamespaces([selectedNamespaces[0]]);
      setInitialNamespace(selectedNamespaces[0]);
      return;
    }
    // select namespace from list
    setSelectedNamespaces([namespaces[0]]);
    setInitialNamespace(namespaces[0]);
  }, [
    initialNamespace,
    isAwaitingNamespacesChange,
    namespaces,
    oneNamespaceByDefaultInHelm,
    resetInitialNamespace,
    selectedNamespaces,
    setSelectedNamespaces,
  ]);

  return {
    initialNamespace,
    resetInitialNamespace,
  };
};
