import React, { useRef } from "react";

import {
  GraphsContainer,
  MainContainer,
  MetricsContainer,
} from "../../MetricsTab/styles";
import { LoadingStateIds } from "../workloadMetricsTabConstants";
import { useSetInitialLoadTime } from "../hooks/useSetInitialLoadTime";

import { WorkloadMetricsPreferences } from "./WorkloadMetricsPreferences/WorkloadMetricsPreferences";
import { MetricsPerContainer } from "./MetricsPerContainer/MetricsPerContainer";
import { WorkloadGraph } from "./graphs/WorkloadGraph";

import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";
import { getTimeWindowLabel } from "@/shared/constants/datadog";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

interface WorkloadMetricsTabContentProps {
  komodorUidParams: KomodorUidParams;
}

const WorkloadMetricsTabContentComponent: React.FC<
  WorkloadMetricsTabContentProps
> = ({
  komodorUidParams = { cluster: "", name: "", namespace: "", kind: "" },
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const { setMainContainerDivRef } = useDatadogReportLoadingTimeContext();

  setMainContainerDivRef(divRef);
  useSetInitialLoadTime();

  const syncId = `${komodorUidParams.cluster}/${komodorUidParams.namespace}/${komodorUidParams.name}`;

  return (
    <MetricsContainer ref={divRef}>
      <MainContainer>
        <WorkloadMetricsPreferences />
        <GraphsContainer>
          <WorkloadGraph
            komodorUidParams={komodorUidParams}
            loadingStateId={LoadingStateIds.workLoadMemory}
            type={"memory"}
            metricGraphProps={{
              syncId: syncId,
            }}
          />
          <WorkloadGraph
            komodorUidParams={komodorUidParams}
            loadingStateId={LoadingStateIds.workLoadCPU}
            type={"cpu"}
            metricGraphProps={{
              syncId: syncId,
            }}
          />
        </GraphsContainer>
      </MainContainer>
      <MetricsPerContainer komodorUidParams={komodorUidParams} id={syncId} />
    </MetricsContainer>
  );
};

export const WorkloadMetricsTabContent: React.FC<
  WorkloadMetricsTabContentProps
> = (props) => {
  const { timeWindow } = useWorkloadMetricsTabContext();
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.WorkloadMetrics, {
      filters: {
        timeWindow: getTimeWindowLabel(timeWindow.timeframe),
      },
    });

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <WorkloadMetricsTabContentComponent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
