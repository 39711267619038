import {
  Typography,
  MultiSelect,
  OptionType,
} from "@komodorio/design-system/deprecated";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { uniq } from "lodash";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  All_CATEGORIES_CHAR,
  AvailabilityCategories,
} from "../../AvailabilityRuleSections";
import { getCategoriesFromOptions } from "../../AvailabilityRuleSections/AvailabilityTriggersSections";
import { SinksOptions } from "../../../common/types";
import { ZIndex } from "../../../../../constants/zIndex";

import {
  HealthCategories,
  MonitorConfiguration,
} from "@/generated/monitorsApi";

export const SinkContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-bottom: 1rem;
  align-items: center;
`;
const NotifyOnCategories: React.FC<{
  rule: MonitorConfiguration;
  handleChange: (sinksOptions: SinksOptions) => void;
}> = ({ rule, handleChange }) => {
  const [lastNotifyOnCategories, setLastNotifyOnCategories] = useState(
    rule.sinksOptions?.notifyOn
      ? rule.sinksOptions?.notifyOn
      : rule.variables?.categories ?? []
  );
  const [notifyOnCategories, setNotifyOnCategories] = useState<string[]>(
    lastNotifyOnCategories
  );

  const notifyOnCategoriesOptions = useMemo(() => {
    const categories = rule.variables?.categories ?? [];
    const isAllSelected = notifyOnCategories.some(
      (c) => c === All_CATEGORIES_CHAR
    );

    if (categories.find((o) => o === All_CATEGORIES_CHAR)) {
      return AvailabilityCategories.map((o) => ({
        ...o,
        disabled: isAllSelected && o.value !== All_CATEGORIES_CHAR,
      }));
    }
    return AvailabilityCategories.filter((category) =>
      categories.includes(category.value)
    );
  }, [notifyOnCategories, rule.variables?.categories]);

  const updateNotifyOn = useCallback(
    (selectedOptions: OptionType<HealthCategories>[]) => {
      const selectedCategories = getCategoriesFromOptions(selectedOptions);
      setNotifyOnCategories(selectedCategories);
      handleChange({
        notifyOn: selectedCategories,
      });
    },
    [handleChange]
  );

  const handleNotifyOnChange = useCallback(
    (selectedOptions: OptionType<HealthCategories>[]) => {
      setLastNotifyOnCategories(getCategoriesFromOptions(selectedOptions));
      updateNotifyOn(selectedOptions);
    },
    [updateNotifyOn]
  );

  useEffect(() => {
    const combinedCategories = uniq([
      ...notifyOnCategories,
      ...lastNotifyOnCategories,
    ]);
    const isAllCategories = !!combinedCategories.find(
      (c) => c === All_CATEGORIES_CHAR
    );
    updateNotifyOn(
      isAllCategories
        ? notifyOnCategoriesOptions
        : notifyOnCategoriesOptions.filter((o) =>
            combinedCategories.includes(o.value)
          )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule.variables?.categories]);

  return (
    <SinkContainer>
      <Typography size="medium">Notify about:</Typography>{" "}
      <MultiSelect
        size="medium"
        width="17rem"
        value={notifyOnCategoriesOptions.filter((o) =>
          notifyOnCategories.includes(o.value)
        )}
        placeholder={"Select categories"}
        options={notifyOnCategoriesOptions}
        onChange={handleNotifyOnChange}
        listMaxHeight="14rem"
        listZIndex={ZIndex.AboveEventDrawer.toString()}
      />
    </SinkContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NotifyOnCategories;
