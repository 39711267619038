import React, { useEffect, useMemo } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import prettyBytes from "pretty-bytes";
import { muiColors, theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { formatDistance } from "date-fns";
import { IFlatChange } from "json-diff-ts/lib/jsonDiff";

import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import {
  correlatedMetricsSelector,
  isMetricsSupportedSelector,
  issueSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useNextStep } from "../../StepsLogic";
import { DeployCard } from "../CorrelatedDeploys/DeployCard";
import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import {
  useIssueMetricsEndTime,
  useGetDeployMetricsEvents,
} from "../useCorrelatedMetrics";
import { Metrics } from "../../../Metrics/Metrics";
import { TrendsContainer } from "../common/styles";
import { MetricsGraphType } from "../../../Metrics/types";
import { useMetricsDefaultAggregationType } from "../../metricsHooks";
import { useDeployByServiceId } from "../CorrelatedDeploys/useDeployByServiceId";
import { useDeploysById } from "../CorrelatedDeploys/useDeploysById";
import { useScrollTracking } from "../common/useScrollTracking";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const LimitDecreasedComponent: React.FC = () => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const issue = useInvestigationModeStore(issueSelector);
  const podMetrics = useInvestigationModeStore(correlatedMetricsSelector);
  const isMetricsSupported = useInvestigationModeStore(
    isMetricsSupportedSelector
  );
  const { nextStep, goToNextStep } = useNextStep();

  const metricsAggType = useMetricsDefaultAggregationType();

  const output = issue?.results?.limitDecreased?.output;

  const correlatedDeploy =
    issue?.results?.limitDecreased?.output?.decreasedInDeploy;

  const [deploys, isFetchingDeploys] = useDeploysById(
    correlatedDeploy?.id ? [correlatedDeploy?.id] : []
  );

  const lastDeploys = useDeployByServiceId({
    serviceIds: deploys[0]?.services ?? [],
    limit: 1,
    fields: ["id", "eventTime", "generation"],
  });

  const events = useGetDeployMetricsEvents(issue, deploys);

  const metricsEndTime = useIssueMetricsEndTime(issue);

  const subtitleMessage = useMemo(() => {
    const timeBeforeIssue = formatDistance(
      new Date(deploys?.[0]?.eventTime ?? 0),
      new Date(issue?.eventTime ?? 0),
      {
        addSuffix: false,
      }
    );

    return output?.oldMemoryLimitBytes && output?.newMemoryLimitBytes
      ? `Memory Limit decreased from ${prettyBytes(output.oldMemoryLimitBytes, {
          maximumFractionDigits: 1,
        })} to ${prettyBytes(output.newMemoryLimitBytes, {
          maximumFractionDigits: 1,
        })} for the container ${timeBeforeIssue} before the issue started`
      : "";
  }, [deploys, issue?.eventTime, output]);

  const canRollback = useMemo(() => {
    return (
      lastDeploys?.length === 1 &&
      deploys?.[0]?.id !== undefined &&
      lastDeploys?.[0]?.id !== undefined &&
      deploys?.[0]?.id === lastDeploys?.[0]?.id &&
      lastDeploys[0]?.generation !== undefined &&
      lastDeploys[0]?.generation > 1
    );
  }, [deploys, lastDeploys]);
  const elementRef = useScrollTracking<HTMLDivElement>();

  useEffect(() => {
    reportLoadingState(
      "limitDecreasedData",
      !issue || !podMetrics || isFetchingDeploys
    );
  }, [isFetchingDeploys, issue, podMetrics, reportLoadingState]);

  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          We detected that the memory limit was decreased prior to the first
          issue occurrence
        </Typography>
        <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
          {subtitleMessage}
        </Typography>
      </StepHeader>
      <StepContent ref={elementRef}>
        {podMetrics && (output?.hasMetrics || !isMetricsSupported) && (
          <Metrics
            isMetricsSupported={isMetricsSupported}
            endTimestamp={metricsEndTime}
            events={events}
            metrics={podMetrics}
            graphType={MetricsGraphType.CONTAINER}
            aggregationType={metricsAggType}
            paddingInMinutes={10}
          />
        )}{" "}
        {deploys?.[0] !== undefined && (
          <DeployCard
            deploy={deploys[0]}
            isCollapsed={false}
            canRollback={canRollback}
            filterK8sChangesMethod={(changes: IFlatChange[]) => {
              const filtered = changes.filter((change) => {
                return change.path.includes("resources.limits.memory");
              });
              return filtered.length ? filtered : changes;
            }}
            trend={
              <TrendsContainer>
                <Typography variant={"body1"}>
                  Memory request was decreased in this revision
                </Typography>
              </TrendsContainer>
            }
          />
        )}
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.LimitDecreasedStep.SkipStepButton
            }
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};
export const LimitDecreased: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.investigationModeLimitDecreased,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <LimitDecreasedComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
