import React, { useState } from "react";
import { Metadata, MetadataProps } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

import {
  Section,
  SectionName,
} from "../WorkflowIssueEventDetails/triage/common/styles";

import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { Addon, Entity } from "@/generated/addonsApi";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { buildKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";
import { WORKFLOW_RUN_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { getWorkflowLabels } from "@/components/k8sAddons/addons/workflows/workflowUtils";
import DeletedPod from "@/components/ResourceView/resources/deletedPod";
import { ResourceDrawer } from "@/components/ResourceView/ResourceDrawer";
import Pod from "@/components/ResourceView/resources/pod";

const WhereContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max-content, 150px));
  gap: 8px 24px;
`;

const MetadataWithTooltip = (props: MetadataProps) => (
  <Metadata
    {...props}
    maxWidth={150}
    tooltipProps={{
      componentsProps: lightMuiTooltipStyle,
      title: props.value,
    }}
  />
);

export const PodWhereSection: React.FC<{
  clusterName: string;
  namespace: string;
  labels: Record<string, string> | undefined;
  podResource: Pod | DeletedPod | undefined;
  controlledBy?: string;
  nodeName?: string;
  podName: string;
  podNameAriaLabel?: string;
}> = ({
  clusterName,
  namespace,
  labels,
  podResource,
  controlledBy,
  nodeName,
  podName,
  podNameAriaLabel,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const workflowLabels = labels && getWorkflowLabels(labels);
  const { dagId, taskId, runId, tryNumber, engine } = workflowLabels ?? {};
  const [showPodDrawer, setShowPodDrawer] = useState(false);

  const komodorWorkflowId =
    buildKomodorWorkflowId({
      cluster: clusterName,
      namespace,
      engine: engine ?? "",
      dagId: dagId ?? "",
    }) ?? "";
  const onWorkflowClick = () =>
    pushDrawer({
      drawerType: DrawerType.K8sAddonLiveStateDrawer,
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      clusterName,
      uid: komodorWorkflowId,
      name: dagId ?? "",
      urlStates: {
        [WORKFLOW_RUN_PARAM_KEY]: runId ?? "",
      },
    });

  return (
    <>
      <Section grayBg>
        <SectionName>where</SectionName>
        <WhereContainer>
          <MetadataWithTooltip
            title={"pod"}
            value={podName}
            onValueClick={() => setShowPodDrawer(true)}
            aria-label={podNameAriaLabel}
          />
          <MetadataWithTooltip title="cluster" value={clusterName} />
          <MetadataWithTooltip title="namespace" value={namespace} />
          {controlledBy && controlledBy !== "<none>" && (
            <MetadataWithTooltip title="controlled by" value={controlledBy} />
          )}
          {nodeName && <MetadataWithTooltip title="node" value={nodeName} />}
          {tryNumber && (
            <MetadataWithTooltip title="try number" value={tryNumber} />
          )}
          {dagId && (
            <MetadataWithTooltip
              title="dag_id"
              value={dagId}
              onValueClick={onWorkflowClick}
            />
          )}
          {runId && (
            <MetadataWithTooltip
              title="run_id"
              value={runId}
              onValueClick={onWorkflowClick}
            />
          )}
          {taskId && <MetadataWithTooltip title="task_id" value={taskId} />}
        </WhereContainer>
      </Section>
      {showPodDrawer && podResource && (
        <ResourceDrawer
          open
          onClose={() => setShowPodDrawer(false)}
          resource={podResource}
        />
      )}
    </>
  );
};
