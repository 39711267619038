import uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ResourceTableModelRow } from "komodor-types";

import { useInterval } from "../../../components/common/useInterval";
import { useOverridableFlags } from "../../context/featureFlags/OverridableFlags";
import getAgeFromDate from "../../../components/Inspection/utils/getAgeFromDate";
import { useAgentInfoById } from "../useAgentInfo/useAgentInfo";
import { doesAgentVersionSupportResourceListFromAtmAndLiveData } from "../../utils/agent/agent";
import { INSPECTION_ATM_LIVE_DATA_PARAM_KEY } from "../../config/urlSearchParamsKeys";
import { useIsTabVisible } from "../useIsTabVisible";

import { AtmOutputType, Status } from "./useAtmRequest";
import useAtmListResources, {
  AtmResourceListResponseType,
} from "./requests/useAtmListResources";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export interface InspectionAtmRequestParams {
  resourceKind: string;
  agentId: string;
  cluster: string;
  shouldPause?: boolean;
  namespaces?: string[];
  labelSelector?: string;
  fieldSelector?: string;
  isLive?: boolean;
}

const DefaultFetchIntervalMilliseconds = 1000;
const ErrorFetchIntervalMilliseconds = 3000;
const withUpdatedAge = (item: ResourceTableModelRow): ResourceTableModelRow => {
  if (item?.age && item?.creationTimestamp) {
    return {
      ...item,
      age: getAgeFromDate(new Date(item.creationTimestamp)),
    };
  }
  return item;
};
const withDefaultNameSort = (data: ResourceTableModelRow[]): void => {
  data.sort((a, b) => {
    if (!(a?.name && b?.name)) {
      return 0;
    }
    return a.name.localeCompare(b.name);
  });
};
const updateDataIfLive = (
  isLiveData: boolean,
  isNewDataAdded: boolean,
  data: ResourceTableModelRow[]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ResourceTableModelRow[] => {
  if (!isLiveData) {
    return data;
  }
  if (isNewDataAdded) {
    withDefaultNameSort(data);
  }
  return data.map((item) => withUpdatedAge(item));
};

export const useLiveData = (agentId: string): boolean => {
  const { atmLiveData } = useOverridableFlags();
  const { agentProperties: agentInfo } = useAgentInfoById(agentId);
  return useMemo(
    () =>
      atmLiveData !== false &&
      doesAgentVersionSupportResourceListFromAtmAndLiveData(agentInfo),
    [agentInfo, atmLiveData]
  );
};

export const useLiveDataWithUrlParam = (
  agentId: string
): { isLiveActive: boolean; isLiveDataSupported: boolean } => {
  const isLiveDataSupported = useLiveData(agentId);
  const [isAtmLiveData] = useStringifiedStateInSearchParams<boolean>(
    INSPECTION_ATM_LIVE_DATA_PARAM_KEY
  );

  const isLiveActive = useMemo(
    () => isLiveDataSupported && (isAtmLiveData ?? true),
    [isAtmLiveData, isLiveDataSupported]
  );
  return { isLiveActive, isLiveDataSupported };
};

const getInterval = (interval: unknown): number => {
  return interval && Number(interval) > 0
    ? Number(interval) * 1000
    : DefaultFetchIntervalMilliseconds;
};

export interface ListResourcesResult {
  result?: AtmResourceListResponseType;
  status: Status;
  refresh: () => void;
  errorMessage: string;
}

const useAtmResourcesListLiveResult = ({
  resourceKind,
  agentId,
  cluster,
  shouldPause = false,
  namespaces = undefined,
  labelSelector = undefined,
  fieldSelector = undefined,
  isLive = false,
}: InspectionAtmRequestParams): ListResourcesResult => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [mostRecentUpdate, setMostRecentUpdate] = useState<string | null>(null);
  const [, setIsFirstLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(true);

  const [resourcesResponse, setResourcesResponse] =
    useState<AtmResourceListResponseType>();

  const {
    numberOfSecondsAtmLiveUpdates,
    numberOfSecondsUntilAtmDataRecived,
    inspectionRefreshWhenTabIsDefocused,
  } = useOverridableFlags();

  const calculatedFetchIntervalMilliseconds = useMemo(() => {
    if (isPaginating) {
      return getInterval(numberOfSecondsUntilAtmDataRecived);
    }
    return getInterval(numberOfSecondsAtmLiveUpdates);
  }, [
    isPaginating,
    numberOfSecondsAtmLiveUpdates,
    numberOfSecondsUntilAtmDataRecived,
  ]);

  const handleResponse = useCallback(
    (responseJSON: AtmResourceListResponseType) => {
      setIsPaginating(responseJSON.isPaginating);
      setResourcesResponse((prevState) => {
        const responseSessionId = responseJSON.sessionId;
        const isSameSession = responseSessionId === sessionId;
        const previousData = isSameSession ? prevState?.data || [] : [];
        if (responseJSON.data.length === 0) {
          const data = previousData;
          return {
            ...responseJSON,
            data: updateDataIfLive(isLive, false, data),
          };
        }

        // https://lodash.com/docs/#uniqBy
        const uniqueMergedData = uniqBy(
          [...previousData, ...responseJSON.data].reverse(), // Reversing is required because `uniqBy` because the order of result values is determined by the order they occur in the array.
          "id"
        ).filter((item) => item?.deletedAt === "");
        return {
          ...responseJSON,
          data: updateDataIfLive(isLive, true, uniqueMergedData),
        };
      });
      setMostRecentUpdate(responseJSON.mostRecentUpdate);
      setSessionId(responseJSON.sessionId);
      setIsFirstLoading(false);
    },
    [sessionId, setMostRecentUpdate, setSessionId, setIsFirstLoading, isLive]
  );

  const { execute, status, resetState, errorMessage } = useAtmListResources(
    agentId,
    cluster,
    resourceKind,
    AtmOutputType.Wide,
    handleResponse,
    mostRecentUpdate,
    namespaces,
    labelSelector,
    fieldSelector
  );

  const resetCallback = useCallback(() => {
    resetState();
    setMostRecentUpdate(null);
    setResourcesResponse(undefined);
  }, [resetState]);

  useEffect(() => {
    resetCallback();
  }, [
    cluster,
    resourceKind,
    namespaces,
    resetCallback,
    labelSelector,
    fieldSelector,
  ]);
  const isError = useMemo(
    () => errorMessage || resourcesResponse?.failureMessage,
    [errorMessage, resourcesResponse?.failureMessage]
  );
  const isResourcesFetchedOrError = useMemo(
    () => (resourcesResponse && !resourcesResponse.isPaginating) || isError,
    [isError, resourcesResponse]
  );
  const isTabVisible = useIsTabVisible();
  const shouldRefreshLiveData = useMemo(
    () =>
      !shouldPause &&
      (isLive || !isResourcesFetchedOrError) &&
      (inspectionRefreshWhenTabIsDefocused || isTabVisible),
    [
      isLive,
      isResourcesFetchedOrError,
      shouldPause,
      isTabVisible,
      inspectionRefreshWhenTabIsDefocused,
    ]
  );
  const refreshCallback = useCallback(() => {
    if (status !== Status.Initializing && shouldRefreshLiveData) {
      execute();
    }
    // Do nothing - used when `atmLiveData` feature flag is OFF or not paginating, or namespaces are not fetched yet.
  }, [execute, shouldRefreshLiveData, status]);

  useEffect(() => {
    if (status === Status.Initializing && !shouldPause) {
      execute();
    }
  }, [shouldPause, execute, status]);

  useInterval(
    refreshCallback,
    isError
      ? ErrorFetchIntervalMilliseconds
      : calculatedFetchIntervalMilliseconds
  );

  return {
    result: resourcesResponse,
    status: status,
    refresh: resetCallback,
    errorMessage: errorMessage || resourcesResponse?.failureMessage || "",
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useAtmResourcesListLiveResult;
