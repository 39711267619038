import { orderBy } from "lodash";
import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkloadHealthyPodsDetails from "./detailsComponents/workloadHealthyPodsDetails/WorkloadHealthyPodsDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowPodsHealthy from "./detailsComponents/workloadHealthyPodsDetails/WorkloadHealthyPodsDetailsEvents";
import { ExternalDetails, WorkloadHealthyPodsOutput } from "./types";

export const enum CustomPodReason {
  Evicted = "Evicted",
  Healthy = "Healthy",
}

export const enum CustomContainerState {
  Running = "Running",
  Terminated = "Terminated",
  Waiting = "Waiting",
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class WorkloadHealthyPodsCheck extends WorkflowCheck {
  readonly output;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(
    check: BaseWorkflowCheck,
    eventTime?: Date,
    services?: string[],
    clusterName?: string,
    namespace?: string
  ) {
    super(check);
    this.title = "Pods health";
    this.output = check.output as WorkloadHealthyPodsOutput & ExternalDetails;
    this.output.pods = this.output?.pods?.map((p) => {
      const podEventReason = p?.podEvents?.[0]?.reason;
      const sortedContainers = orderBy(
        p?.containers ?? [],
        ["isHealthy", "restartCount"],
        ["asc", "desc"]
      );
      return {
        ...p,
        calculatedReason: p.calculatedReason
          ? p.calculatedReason
          : p.isHealthy
          ? CustomPodReason.Healthy
          : p.isEvicted
          ? CustomPodReason.Evicted
          : podEventReason || "Reason missing",
        containers: sortedContainers,
      };
    });
    const desiredReplicas = this.output?.desiredReplicas ?? 0;
    const podsLength = this.output?.podsCount ?? this.output?.pods?.length ?? 0;
    const unhealthyPodsLength =
      this.output?.pods?.filter((p) => !p?.isHealthy).length ?? 0;

    let shortOutput = "All pods are healthy";
    if (!check.passed) {
      if (this.output?.failureMessage) {
        shortOutput =
          this.output?.failureMessage +
          ` (ready replicas: ${this.output.readyReplicas}, desired replicas ${desiredReplicas})`;
      } else if (podsLength === 0) {
        shortOutput = `Found 0 pods while desired is ${desiredReplicas}`;
      } else if (unhealthyPodsLength === 0) {
        shortOutput = `No unhealthy pods, found ${podsLength} ready pods while desired is ${desiredReplicas}`;
      } else {
        shortOutput = `${unhealthyPodsLength} of ${podsLength} ${
          podsLength > 1 ? "pods" : "pod"
        } ${
          unhealthyPodsLength > 1 ? "are" : "is"
        } unhealthy (desired replicas ${desiredReplicas})`;
      }
    }
    this.shortOutput = shortOutput;
    this.output.workloadId = services?.[0] ?? "";
    this.output.eventTime = eventTime;
    this.output.clusterName = clusterName;
    this.output.namespace = namespace;
  }

  renderCheckDetails(): JSX.Element {
    return <WorkloadHealthyPodsDetails check={this} aria-label="pod-health" />;
  }
  renderCheckEventDetails(): JSX.Element {
    return <WorkflowPodsHealthy check={this} aria-label="pod-health" />;
  }
}
