import { useMemo } from "react";

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
const useTicks = (start: Date, end: Date, tickCount: number): number[] =>
  useMemo(() => {
    const first = start.getTime();
    const last = end.getTime();
    const step = (last - first) / (tickCount - 1);
    return [
      first,
      ...Array.from(
        { length: tickCount - 2 },
        (_, i) => first + Math.floor(step * (i + 1))
      ),
      last,
    ];
  }, [start, end, tickCount]);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useTicks;
