import { theme } from "@komodorio/design-system";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Helm24 } from "@komodorio/design-system/icons";

import { AvailableActions } from "../inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Resource from "../../ResourceView/resources";
import {
  ResourceStatusEnum,
  StatusTag,
} from "../../ResourceView/headers/common";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Header } from "../../ResourceView/common/Header/Header";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourcesTab } from "./tabs/ResourceTab/ResourcesTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ManifestsTab } from "./tabs/ManifestsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  GenericViewCompare,
  CompareType,
} from "./tabs/components/GenericViewCompare";

import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

const HELM_CHART_KIND = "Helm chart";

export interface LastActionResult {
  command: string;
  success: boolean;
  message: string | JSX.Element;
  timestamp: Date;
}

export const getResourceStatusByChartStatus = (
  status: string
): ResourceStatusEnum => {
  switch (status) {
    case "deployed":
      return ResourceStatusEnum.Healthy;
    case "superseded":
    case "unknown":
    case "uninstalled":
    case "uninstalling":
      return ResourceStatusEnum.Unknown;
    case "pending-install":
    case "pending-upgrade":
    case "pending-rollback":
      return ResourceStatusEnum.Pending;
    case "failed":
      return ResourceStatusEnum.Failed;
    default:
      return ResourceStatusEnum.Unknown;
  }
};
export const getK8sResourceStatusByChartStatus = (
  status: string
): ResourceStatusEnum => {
  switch (status) {
    case "Available":
    case "Active":
    case "Established":
    case "Bound":
    case "Ready":
    case "Exists":
    case "AbleToScale":
    case "DisruptionAllowed":
      return ResourceStatusEnum.Healthy;
    case "Progressing":
      return ResourceStatusEnum.Warning;
    case "Unknown":
      return ResourceStatusEnum.Unknown;
    default:
      return ResourceStatusEnum.Failed;
  }
};
export enum HelmTabNames {
  Resources = "Resources",
  Manifests = "Manifests",
  Values = "Values",
  Notes = "Notes",
}

interface HelmChartParams {
  id: string;
  cluster: string;
  name: string;
  namespace: string;
  agentId: string;
  status: string;
  currentRevision: string;
  appVersion: string;
  parsedManifest?: unknown[];
  manifest?: string;
  values?: Record<string, unknown> | null;
  notes?: string;
  revisions?: { revision: string; id: string }[];
  lastActionCb?: (value: LastActionResult) => Promise<unknown>;
  isLastRevision?: boolean;
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class HelmChart implements Resource {
  readonly agentId: string;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly name;
  readonly icon = (): JSX.Element => <Helm24 color={theme.icon} />;
  readonly status;
  readonly kind: string;
  annotations: { [key: string]: string };
  readonly labels: { [key: string]: string };
  readonly parsedManifests: unknown[] | undefined;
  readonly manifest: string;
  readonly values: Record<string, unknown> | null;
  readonly notes: string | undefined;
  readonly revisions: { revision: string; id: string }[];
  readonly currentRevision: string;
  readonly appVersion: string;
  isLastRevision: boolean | undefined;
  lastActionCb: ((value: LastActionResult) => void) | undefined;
  lastActionResult: LastActionResult | undefined;
  readonly defaultTab: number;

  readonly drawerTabs = [
    { label: HelmTabNames.Resources },
    { label: HelmTabNames.Manifests },
    { label: HelmTabNames.Values },
    { label: HelmTabNames.Notes },
  ];
  readonly actions = [
    AvailableActions.UpgradeHelmChart,
    AvailableActions.RollbackHelmChart,
    AvailableActions.UninstallHelmChart,
  ];

  constructor(helmChartParams: HelmChartParams) {
    this.id = helmChartParams.id;
    this.cluster = helmChartParams.cluster;
    this.namespace = helmChartParams.namespace;
    this.name = helmChartParams.name;
    this.status = helmChartParams.status;
    this.kind = HELM_CHART_KIND;
    this.annotations = {};
    this.labels = {};
    this.parsedManifests = helmChartParams.parsedManifest;
    this.manifest = helmChartParams.manifest ?? "";
    this.values = helmChartParams.values ?? {};
    this.notes = helmChartParams.notes ?? "";
    this.revisions = helmChartParams.revisions ?? [];
    this.currentRevision = helmChartParams.currentRevision;
    this.agentId = helmChartParams.agentId;
    this.appVersion = helmChartParams.appVersion;
    this.lastActionCb = helmChartParams.lastActionCb;
    this.lastActionResult = undefined;
    this.isLastRevision = helmChartParams.isLastRevision;
    this.defaultTab = 0;
  }

  renderResources(tableHeader: JSX.Element): JSX.Element {
    return (
      <ResourcesTab
        resource={this}
        showColumns={{
          type: true,
          name: true,
          status: false,
          statusMessage: false,
        }}
        tableHeader={tableHeader}
      />
    );
  }
  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel value={selectedTab} index={0}>
          <ResourcesTab
            resource={this}
            showColumns={{
              type: true,
              name: true,
              status: this.isLastRevision ?? false,
              statusMessage: this.isLastRevision ?? false,
            }}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1} eager={true}>
          <ManifestsTab resource={this} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2} eager={true}>
          <GenericViewCompare
            compareType={CompareType.Values}
            resource={this}
            datadogViewName={DatadogViewNamesEnum.helmDrawerValuesTab}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3} eager={true}>
          <GenericViewCompare
            compareType={CompareType.Notes}
            resource={this}
            datadogViewName={DatadogViewNamesEnum.helmDrawerNotesTab}
          />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        status={
          this.status !== undefined ? (
            <StatusTag status={getResourceStatusByChartStatus(this.status)}>
              {this.status.toUpperCase()}
            </StatusTag>
          ) : undefined
        }
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }

  setLastActionResult(result: LastActionResult): void {
    this.lastActionResult = result;
    this.lastActionCb?.(result);
  }

  setLastActionCb(callback: (value: LastActionResult) => void): void {
    this.lastActionCb = callback;
  }
}
