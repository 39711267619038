import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useReliabilityDataInUrl } from "@/components/reliability/hooks/useReliabilityDataInUrl";
import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { Dictionary } from "@/shared/types/Dictionary";

export const useLoadingStateHooks = () => {
  useResetStateOnPathChange();
  useTriggerStartViewOnFiltersChange();
};

const useResetStateOnPathChange = () => {
  const { resetLoadingState, triggerStartView } =
    useDatadogReportLoadingTimeContext();

  const onPathChange = useCallback(() => {
    resetLoadingState();
    triggerStartView();
  }, [resetLoadingState, triggerStartView]);

  useTriggerCallbackOnPathChange(onPathChange);
};

const useTriggerStartViewOnFiltersChange = () => {
  const { triggerStartView } = useDatadogReportLoadingTimeContext();

  const [dataInUrl] = useReliabilityDataInUrl();
  const filtersRef = useRef<Dictionary<FilterOptionInUrl[]> | undefined>();

  useEffect(() => {
    if (!dataInUrl.filters) {
      filtersRef.current = dataInUrl.filters;
      return;
    }
    if (!filtersRef.current) {
      filtersRef.current = dataInUrl.filters;
      return;
    }
    if (
      JSON.stringify(filtersRef.current) === JSON.stringify(dataInUrl.filters)
    ) {
      return;
    }
    filtersRef.current = dataInUrl.filters;
    triggerStartView();
  }, [dataInUrl.filters, triggerStartView]);
};

const useTriggerCallbackOnPathChange = (callback: () => void) => {
  const location = useLocation();
  const locationPathRef = useRef<string>(location.pathname);

  useEffect(() => {
    if (locationPathRef.current !== location.pathname) {
      locationPathRef.current = location.pathname;
      callback();
    }
  }, [location.pathname, callback]);
};
