import React, { ReactNode } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography/Typography";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 16px 0 16px;
  gap: 8px;
  width: 100%;
`;

type Props = {
  title: string;
  children: ReactNode;
};

export const MetricsContainer: React.FC<Props> = ({ title, children }) => {
  return (
    <Container>
      <Typography variant="h6"> {title} </Typography>
      {children}
    </Container>
  );
};
