import { useUpdateUser } from "@/shared/hooks/auth-service/client/users/useUpdateUser";
import { useCreateRbacUserRole } from "@/shared/hooks/auth-service/client/rbacUserRoles/useCreateRbacUserRole";
import { useDeleteRbacUserRole } from "@/shared/hooks/auth-service/client/rbacUserRoles/useDeleteRbacUserRole";
import { useUpdateRbacUserRole } from "@/shared/hooks/auth-service/client/rbacUserRoles/useUpdateRbacUserRole";

export const useUserManagement = () => {
  const { mutateAsync: editUser, isLoading: editLoading } = useUpdateUser();
  const {
    mutateAsync: createRbacUserRole,
    isLoading: createRbacUserRoleLoading,
    isError: createRbacUserRoleError,
  } = useCreateRbacUserRole();
  const {
    mutateAsync: deleteRbacUserRole,
    isLoading: deleteRbacUserRoleLoading,
    isError: deleteRbacUserRoleError,
  } = useDeleteRbacUserRole();
  const {
    mutateAsync: updateRbacUserRole,
    isLoading: updateRbacUserRoleLoading,
    isError: updateRbacUserRoleError,
  } = useUpdateRbacUserRole();

  return {
    editUser,
    editLoading,
    createRbacUserRole,
    createRbacUserRoleLoading,
    createRbacUserRoleError,
    deleteRbacUserRole,
    deleteRbacUserRoleLoading,
    deleteRbacUserRoleError,
    updateRbacUserRole,
    updateRbacUserRoleLoading,
    updateRbacUserRoleError,
  };
};
