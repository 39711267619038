import { useContext } from "react";

import {
  PodContainersRequestStatusContext,
  PodContainersRequestStatusContextState,
} from "@/components/ResourceView/tabs/MetricsTab/context/PodContainersRequestStatusContext";

export const usePodContainersRequestStatusContext =
  (): PodContainersRequestStatusContextState =>
    useContext(PodContainersRequestStatusContext);
