import React from "react";

import { DarkenOverlay } from "../../../../../common/Overlay";
import ModalComponent from "../../../../../common/Modal";
import {
  AddExternalLinkMutationVariables,
  External_Link_Context_Enum,
} from "../../../../../../generated/graphql";
import { contactMessage } from "../../../../../../constants";
import {
  isExternalLink,
  isLinkContext,
  Link,
} from "../links/useKomodorAnnotations";
import { AnalyticEvents } from "../../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../../shared/hooks/analytics";

import { customModalStyles, ErrorMessage } from "./LinkModal.styles";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isValidUrl = (url: string): boolean => {
  try {
    const dismissBrackets = url.replace(/\[/g, "").replace(/\]/g, "");
    new URL(dismissBrackets);
    return true;
  } catch {
    return false;
  }
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum UpsertLinkErrorType {
  LinkNotValid = "LinkNotValid",
  AddLinkError = "AddLinkError",
  DeleteLinkError = "DeleteLinkError",
  NoError = "",
}

export type FormValues = {
  name: string;
  link: string;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getLinkInfo = (
  link: string,
  displayName: string,
  acceptedCluster: boolean,
  acceptedNamespace: boolean,
  context: External_Link_Context_Enum,
  k8sClusterName?: string,
  namespace?: string,
  serviceId?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): AddExternalLinkMutationVariables => {
  return {
    displayName: displayName,
    link: link ?? "",
    k8sClusterName: acceptedCluster && k8sClusterName ? k8sClusterName : "",
    namespace: acceptedNamespace && namespace ? namespace : "",
    serviceId: serviceId ?? "",
    externalLinkContextValue: context,
  };
};

export const ModalErrorMessage: React.FC<{
  errorMessage: string;
  expectedType: string;
}> = ({ errorMessage }) => (
  <>
    {errorMessage && errorMessage === UpsertLinkErrorType.AddLinkError && (
      <ErrorMessage>{contactMessage}</ErrorMessage>
    )}
  </>
);

export const LinkModal: React.FC<{
  title: string;
  show: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
}> = ({ title, show, children, handleClose }) => (
  <>
    <DarkenOverlay open={show} />
    <ModalComponent
      isOpen={show}
      handleCloseModal={handleClose}
      title={title}
      customStyle={customModalStyles.content}
    >
      {children}
    </ModalComponent>
  </>
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const dispatchLinkMetric =
  (context: External_Link_Context_Enum, link: Link) => (): void => {
    const linkType = isExternalLink(link)
      ? AnalyticEvents.Links.LinkType.ExternalLink
      : AnalyticEvents.Links.LinkType.AnnotationLink;
    dispatchEvent(AnalyticEvents.Links.External_Link_Clicked, {
      linkContext: context,
      linkType,
    });
  };

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const showLink = (
  link: Link,
  context: External_Link_Context_Enum
): boolean => !isExternalLink(link) || isLinkContext(link, context);
