/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button as DeprecatedButton } from "@komodorio/design-system/deprecated";
import { Plus16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { isNumber, min, compact } from "lodash";
import Button from "@mui/material/Button";

import { useKomodorServices } from "../../../../../../shared/hooks/useKomodorServices";
import { useOverridableFlags } from "../../../../../../shared/context/featureFlags/OverridableFlags";
import { Input } from "../../../../../common/Input";
import searchIcon from "../../../../../common/SVGs/search.svg";
import {
  KubernetesPodsResource,
  KubernetesResource,
} from "../../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { SupportedKubernetesResources } from "../../../../../Inspection/inspectionConfiguration/SupportedKubernetesResources";
import {
  Timeframe,
  TimeWindow,
} from "../../../../../../shared/types/TimeWindow";
import { DeletedPodsSwitch } from "../../../../../eventsView/PodsSelector/DeletedPodsSwitch";
import Resource from "../../../../resources";
import { SelectedRowsOnlySwitch } from "../../../../../../shared/components/SelectedRowsOnlySwitch";

import {
  RelatedResourceParameters,
  relatedResources,
} from "./relatedResources";
import { ResourcesSelectionDialog } from "./ResourcesSelectionDialog";
import { sendAnalyticsFromRelatedResources } from "./relatedResourcesAnalytics";
import { useRowSelection } from "./hooks";

const DEFAULT_MAX_SELECTABLE_ROWS = 3;

const StyledButton = styled(DeprecatedButton).attrs({
  variant: "secondary",
  icon: Plus16,
})`
  grid-row-end: span 2;
  align-self: end;
  margin: 1px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  gap: 0.75rem;
`;

const ClearSection = styled.div`
  display: grid;
  justify-content: end;
  align-items: center;
`;

interface RelatedResourcesSelectorProps {
  selectedResourceUids: { [key: string]: string[] };
  setSelectedResourceUids: (selectedResourceNames: {
    [key: string]: string[];
  }) => void;
  timeWindow: TimeWindow;
  onApplySelection: () => void;
  resource?: Resource;
}

export const RelatedResourcesSelector: React.FC<
  RelatedResourcesSelectorProps
> = ({
  selectedResourceUids,
  setSelectedResourceUids,
  timeWindow,
  onApplySelection,
  resource,
}) => {
  const supportedRelatedResources = SupportedKubernetesResources.filter(
    (resourceType) => Array.from(relatedResources.keys()).includes(resourceType)
  );

  const services = useKomodorServices().all;
  const { serviceId = resource?.id } = useParams<{
    serviceId: string | undefined;
  }>();
  const { maxSelectableRelatedResources, hpaEventsInRelatedResources } =
    useOverridableFlags();

  const filteredSupportedRelatedResources = !hpaEventsInRelatedResources
    ? supportedRelatedResources.filter(({ NavBarName }) => NavBarName !== "HPA")
    : supportedRelatedResources;

  const [modalOpen, setModalOpen] = useState(false);
  const [currentResourceType, setCurrentResourceType] =
    useState<KubernetesResource>(KubernetesPodsResource);
  const [searchTerm, setSearchTerm] = useState("");
  const [showOnlySelectedRows, setShowOnlySelectedRows] = useState(false);
  const {
    selectedRowUidsByResourceType,
    setSelectedRowUidsByResourceType,
    toggleRowSelection,
    clearSelectionByResourceType,
  } = useRowSelection(filteredSupportedRelatedResources);
  const [showDeletedPods, setShowDeletedPods] = useState(false);

  useEffect(() => {
    const selectedResourceUidsByResourceType =
      convertToSelectedResourceUidsByResourceType(
        selectedResourceUids,
        relatedResources,
        resource?.kind ?? ""
      );

    setSelectedRowUidsByResourceType(selectedResourceUidsByResourceType);
  }, [
    selectedResourceUids,
    modalOpen,
    resource?.kind,
    setSelectedRowUidsByResourceType,
  ]);

  const selectedRowUids =
    selectedRowUidsByResourceType.get(currentResourceType) ?? [];

  const service = services?.find((service) => service.id === serviceId);
  const cluster = service?.k8sCluster;
  const namespace = useMemo(() => {
    const tag = service?.tags.find((t) => t.label === "namespace");
    return tag?.value;
  }, [service?.tags]);

  const eventsDetectionTimeframe = useMemo(() => {
    const thirtyDaysAgo = new Date(
      new Date().setDate((timeWindow.end ?? new Date()).getDate() - 30)
    );

    return {
      end: timeWindow.end,
      start: new Date(min([timeWindow.start, thirtyDaysAgo]) ?? thirtyDaysAgo),
      timeframe: Timeframe.Custom,
    };
  }, [timeWindow]);

  const closeModal = useCallback(() => {
    setSearchTerm("");
    setModalOpen(false);
  }, [setSearchTerm, setModalOpen]);

  const applySelection = useCallback(
    (selectedRowNamesByResourceType: Map<KubernetesResource, string[]>) => {
      const newSelectedResourceUids: { [_: string]: string[] } = {};
      selectedRowNamesByResourceType.forEach((selectedNames, resourceType) => {
        const relatedResource = relatedResources.get(resourceType);
        if (!relatedResource) {
          return;
        }
        const paramKey = relatedResource.searchParamKey(resource?.kind ?? "");
        newSelectedResourceUids[paramKey] = selectedNames;
      });
      onApplySelection();
      sendAnalyticsFromRelatedResources(
        selectedResourceUids,
        newSelectedResourceUids
      );
      setSelectedResourceUids(newSelectedResourceUids);
      closeModal();
    },
    [
      closeModal,
      resource?.kind,
      selectedResourceUids,
      setSelectedResourceUids,
      onApplySelection,
    ]
  );

  const isPods = currentResourceType.Kind === KubernetesPodsResource.Kind;

  return (
    <>
      <StyledButton
        data-e2e-selector="related-resources-button"
        onClick={() => setModalOpen(true)}
      >
        Add resources to timeline
      </StyledButton>

      <ResourcesSelectionDialog
        aria-label="service-view related-resources"
        open={modalOpen}
        close={closeModal}
        eventsDetectionTimeframe={
          isPods ? timeWindow : eventsDetectionTimeframe
        }
        applySelection={applySelection}
        cluster={cluster}
        currentResourceType={currentResourceType}
        resourceTypes={filteredSupportedRelatedResources}
        onResourceTypeChange={(resourceType: KubernetesResource) => {
          setCurrentResourceType(resourceType);
        }}
        selectedRowNamesByResourceType={selectedRowUidsByResourceType}
        toggleRowSelection={toggleRowSelection}
        maxSelectableRows={
          isNumber(maxSelectableRelatedResources)
            ? maxSelectableRelatedResources
            : DEFAULT_MAX_SELECTABLE_ROWS
        }
        tableModifiers={
          <Grid>
            <Input
              value={searchTerm}
              onChange={(searchTerm: string) => {
                setSearchTerm(searchTerm);
              }}
              placeholder={`${currentResourceType.Kind} name, labels, annotations`}
              icon={searchIcon}
              data-testid="search-box"
            />

            <ClearSection>
              <Button
                disabled={selectedRowUids.length === 0}
                onClick={() =>
                  clearSelectionByResourceType(currentResourceType)
                }
              >
                Clear all selections
              </Button>
            </ClearSection>

            <SelectedRowsOnlySwitch
              checked={showOnlySelectedRows}
              onToggle={() => {
                setShowOnlySelectedRows(
                  (prevShowSelectedRowsOnly) => !prevShowSelectedRowsOnly
                );
              }}
              selectedRowsNumber={selectedRowUids.length}
            />
          </Grid>
        }
        secondaryTableModifiers={
          isPods ? (
            <DeletedPodsSwitch
              checked={showDeletedPods}
              onToggle={() => {
                setShowDeletedPods(
                  (prevShowDeletedPods) => !prevShowDeletedPods
                );
              }}
              disabled={!isPods}
            />
          ) : undefined
        }
        serviceId={serviceId}
        searchTerm={searchTerm}
        showDeletedPods={showDeletedPods}
        showOnlySelectedRows={showOnlySelectedRows}
        namespace={namespace}
      />
    </>
  );
};

const convertToSelectedResourceUidsByResourceType = (
  selectedResourceNames: {
    [key: string]: string[];
  },
  relatedResources: Map<KubernetesResource, RelatedResourceParameters>,
  resourceViewKind: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  return new Map(
    compact(
      Object.entries(selectedResourceNames).map(
        ([searchParamKey, selectedResourceNames]) => {
          const resourceType = findMatchingResourceTypeFor(
            searchParamKey,
            relatedResources,
            resourceViewKind
          );

          return resourceType
            ? [resourceType, selectedResourceNames]
            : undefined;
        }
      )
    )
  );
};

const findMatchingResourceTypeFor = (
  searchParamKey: string,
  relatedResources: Map<KubernetesResource, RelatedResourceParameters>,
  resourceViewKind: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const relatedResourcesList = Array.from(relatedResources.entries());

  const [resourceType] =
    relatedResourcesList.find(
      ([, value]) => value.searchParamKey(resourceViewKind) === searchParamKey
    ) ?? [];

  return resourceType;
};
