import React from "react";

import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { selectRightSizingRecommendationModal } from "../../../store/costOptimizationStoreSelectors";

import { RightSizingRecommendationModal } from "./RightSizingRecommendationModal";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { staticCostOptimizationContext } from "@/components/CostOptimizationView/constants/costOptimizationConstants";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

export const RightSizingModalContainer: React.FC = () => {
  const { rowId } = useCostOptimizationStore(
    selectRightSizingRecommendationModal
  );
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.costOptimizationRecommendationsModal,
        context: staticCostOptimizationContext,
      }}
    >
      <RightSizingRecommendationModal rowId={rowId} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
