import { AxiosInstance } from "axios";
import { useMutation } from "@tanstack/react-query";

import {
  APIKey,
  ApiKeysApiApiV1ApiKeysIdPutRequest,
  apiV1ApiKeysIdPutUrl,
} from "@/generated/auth";
import { useAuthApiClient } from "@/shared/hooks/auth-service/client/apiClient";

const UPDATE_API_KEY_PATH = "/api-keys/:id";

const updateApiKey = async (
  apiClient: AxiosInstance,
  params: ApiKeysApiApiV1ApiKeysIdPutRequest
): Promise<APIKey> => {
  const { data } = await apiClient.put(
    apiV1ApiKeysIdPutUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useUpdateApiKey = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [UPDATE_API_KEY_PATH],
    (params: ApiKeysApiApiV1ApiKeysIdPutRequest) =>
      updateApiKey(apiClient, params)
  );
};
