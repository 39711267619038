import { useMemo } from "react";

import { defaultViolationStatuses } from "@/components/reliability/constants/reliabilityConstants";
import {
  CheckCategory,
  GenericViolationFilterCategory,
  ImpactGroupType,
  ViolationCountBy,
  ViolationFilterOperator,
} from "@/generated/reliabilityApi";
import { ViolationsTableConfiguration } from "@/components/reliability/ReliabilityTypes";

export const useGetKomodorServiceReliability = (komodorUid: string) => {
  const filterBy = useMemo<GenericViolationFilterCategory[]>(
    () => [
      {
        category: ViolationCountBy.KomodorUid,
        value: [komodorUid],
        operator: ViolationFilterOperator.Include,
      },
      {
        category: ViolationCountBy.Status,
        value: defaultViolationStatuses,
        operator: ViolationFilterOperator.Include,
      },
      {
        category: ViolationCountBy.ImpactGroupType,
        value: [ImpactGroupType.Dynamic],
        operator: ViolationFilterOperator.Include,
      },
      {
        category: ViolationCountBy.CheckCategory,
        value: [CheckCategory.Infrastructure, CheckCategory.Workload],
        operator: ViolationFilterOperator.Include,
      },
    ],
    [komodorUid]
  );

  const scope = useMemo<ViolationsTableConfiguration>(() => {
    return filterBy.reduce<ViolationsTableConfiguration>((acc, filter) => {
      acc[filter.category] = filter.value;
      return acc;
    }, {});
  }, [filterBy]);

  return {
    filterBy,
    scope,
  };
};
