import { useMemo } from "react";

import { generateUid } from "../../../../../../../shared/utils/generateUid";
import { ConfigMapNamesExtractor } from "../../../../../../../shared/utils/ExtractNamesFromService/ConfigMapNamesExtractor";
import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { useNamesExtractionFromService } from "../../../../../../../shared/hooks/useNamesExtractionFromService";
import { Response } from "../types";

export const useConfigMapsByServiceId = (
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: TimeWindow | undefined,
  serviceId: string | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): Response => {
  const [configMapNames, refresh] = useNamesExtractionFromService(
    serviceId,
    new ConfigMapNamesExtractor()
  );
  const configMaps = useMemo(
    () =>
      configMapNames.map((configMapname) => ({
        name: configMapname,
        namespace: namespace ?? "",
        uid: generateUid({
          name: configMapname,
          namespace: namespace ?? "",
          cluster: clusterName ?? "",
        }),
      })) ?? [],
    [configMapNames, namespace, clusterName]
  );

  return useMemo(
    () => ({
      rows: configMaps,
      refresh: refresh,
    }),
    [configMaps, refresh]
  );
};
