import { toNumber, toString, get, compact } from "lodash";
import {
  Null,
  Record,
  String,
  Array as RTArray,
  Number,
  Static,
} from "runtypes";

export const MetricTaskResultError = {
  results: {},
  errorParsingResults: "Error parsing results due to internal server error",
};

enum PrometheusResourceName {
  Node = "node",
  Pod = "pod",
}

interface PointValue {
  timeInUnix: number;
  value: number;
}

interface GroupByName {
  // Group can be pods or nodes for example
  [name: string]: PointValue[];
}

export interface MetricsTaskResult {
  results: { [metricName: string]: GroupByName };
  errorParsingResults?: string;
}

export const HttpTaskResult = RTArray(
  Record({
    stderr: String.Or(Null),
    stdout: String.Or(Null),
  })
);
type HttpTaskResult = Static<typeof HttpTaskResult>;

const SingleResult = Record({
  metric: Record({}),
  value: RTArray(String.Or(Number)),
});
type SingleResult = Static<typeof SingleResult>;

const StdoutPrometheus = Record({
  status: String,
  data: Record({
    resultType: String,
    result: RTArray(SingleResult),
  }),
});

const parseSingleResult = (
  resourceResult: SingleResult,
  resourceName: string
): { points: PointValue[]; name: string } | null => {
  let name = toString(get(resourceResult.metric, resourceName, ""));
  if (name === "") {
    // fallback to instance
    name = toString(get(resourceResult.metric, "instance", ""));
  }
  let point = null;
  if (resourceResult.value.length) {
    point = {
      timeInUnix: toNumber(resourceResult.value[0]),
      value: toNumber(resourceResult.value[1]),
    };
  }
  if (name === "" || !point) return null;

  return { points: [point], name };
};

const parseResponseFromPrometheus = (
  stdout: string | null,
  stderr: string | null,
  resourceName: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): GroupByName | null => {
  if (!stdout || stderr) {
    return null;
  }
  let parsedStdout = "";
  try {
    parsedStdout = JSON.parse(stdout);
  } catch {
    return null;
  }
  if (!StdoutPrometheus.guard(parsedStdout)) {
    return null;
  }
  if (parsedStdout.status !== "success" || !parsedStdout.data.result.length) {
    return null;
  }

  const results = compact(
    parsedStdout.data.result.map((result) =>
      parseSingleResult(result, resourceName)
    )
  );

  return results.reduce<GroupByName>((allResults, p) => {
    allResults[p.name] = p.points;
    return allResults;
  }, {});
};

export const parsePodsResponseFromPrometheus = (
  httpResults: HttpTaskResult
): MetricsTaskResult => {
  if (httpResults.length !== 2) {
    return MetricTaskResultError;
  }
  // first response is for cpu usage
  const cpuGroup = parseResponseFromPrometheus(
    httpResults[0].stdout,
    httpResults[0].stderr,
    PrometheusResourceName.Pod
  );
  // second response is for memory usage
  const memoryGroup = parseResponseFromPrometheus(
    httpResults[1].stdout,
    httpResults[1].stderr,
    PrometheusResourceName.Pod
  );
  if (!cpuGroup || !memoryGroup) return MetricTaskResultError;

  return { results: { cpu: cpuGroup, memory: memoryGroup } };
};

export const parseNodesResponseFromPrometheus = (
  httpResults: HttpTaskResult
): MetricsTaskResult => {
  if (httpResults.length !== 6) {
    return MetricTaskResultError;
  }

  const metrics = [
    "cpu",
    "cpuAllocation",
    "memory",
    "memoryAllocation",
    "disk",
    "diskSize",
  ]; // The order is constant and determined according to build prometheus metrics function
  const results: { [_: string]: GroupByName } = {};

  for (const [i, req] of httpResults.entries()) {
    const result = parseResponseFromPrometheus(
      req.stdout,
      req.stderr,
      PrometheusResourceName.Node
    );
    if (!result) {
      return MetricTaskResultError;
    }
    results[metrics[i]] = result;
  }
  return { results };
};
