import React, { createContext, useCallback, useState } from "react";

import {
  MetricsAggregationType,
  MetricsFullTimeWindow,
} from "../../../../Metrics/types";

import { Timeframe } from "@/shared/types/TimeWindow";

export type WorkloadMetricsTabContextState = {
  timeWindow: MetricsFullTimeWindow;
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void;
  initialLoadTime: number;
  setInitialLoadTime: (time: number) => void;
  selectedAggregationMetric: MetricsAggregationType;
  setSelectedAggregationMetric: (metric: MetricsAggregationType) => void;
};

const initialState: WorkloadMetricsTabContextState = {
  timeWindow: {
    start: new Date(new Date().setHours(new Date().getHours() - 24)),
    end: new Date(),
    timeframe: Timeframe.Last24Hours,
  },
  setTimeWindow: () => undefined,
  initialLoadTime: 0,
  setInitialLoadTime: () => undefined,
  selectedAggregationMetric: MetricsAggregationType.Avg,
  setSelectedAggregationMetric: () => undefined,
};

export const WorkloadMetricsTabContext =
  createContext<WorkloadMetricsTabContextState>(initialState);

const {
  timeWindow: initialTimeWindow,
  selectedAggregationMetric: initialSelectedMetric,
  initialLoadTime: initialInitialLoadTime,
} = initialState;

type WorkloadMetricsTabContextProviderProps = {
  children: React.ReactNode;
  givenTimeWindow?: MetricsFullTimeWindow;
  givenAggregationMetric?: MetricsAggregationType;
};

export const WorkloadMetricsTabContextProvider: React.FC<
  WorkloadMetricsTabContextProviderProps
> = ({ children, givenTimeWindow, givenAggregationMetric }) => {
  const [timeWindow, setTimeWindow] = useState<MetricsFullTimeWindow>(
    givenTimeWindow ?? initialTimeWindow
  );
  const [selectedAggregationMetric, setSelectedAggregationMetric] =
    useState<MetricsAggregationType>(
      givenAggregationMetric ?? initialSelectedMetric
    );
  const [initialLoadTime, setInitialLoadTime] = useState<number>(
    initialInitialLoadTime
  );

  const onSetTimeWindow = useCallback(
    (tw: Partial<MetricsFullTimeWindow>) => {
      setTimeWindow({
        ...timeWindow,
        ...tw,
      });
    },
    [timeWindow]
  );

  const contextState: WorkloadMetricsTabContextState = {
    timeWindow: timeWindow,
    setTimeWindow: onSetTimeWindow,
    initialLoadTime,
    setInitialLoadTime,
    selectedAggregationMetric,
    setSelectedAggregationMetric,
  };

  return (
    <WorkloadMetricsTabContext.Provider value={contextState}>
      {children}
    </WorkloadMetricsTabContext.Provider>
  );
};
