import React from "react";
import {
  Status,
  StatusColor,
  StatusProps,
} from "@komodorio/design-system/komodor-ui";

import { WorkflowStatus as WorkflowStatusType } from "@/generated/addonsApi";

const wfStatusColorMap: { [key in WorkflowStatusType]: StatusColor } = {
  [WorkflowStatusType.Completed]: "success",
  [WorkflowStatusType.Running]: "running",
  [WorkflowStatusType.Submitted]: "running",
  [WorkflowStatusType.Failed]: "error",
  [WorkflowStatusType.SubmissionFailed]: "error",
  [WorkflowStatusType.PendingRerun]: "warning",
  [WorkflowStatusType.Deleted]: "neutral",
  [WorkflowStatusType.Invalidating]: "warning",
  [WorkflowStatusType.Succeeding]: "success",
  [WorkflowStatusType.Failing]: "error",
  [WorkflowStatusType.Unknown]: "neutral",
};

export const WorkflowStatus: React.FC<
  { status: WorkflowStatusType } & Partial<StatusProps>
> = ({
  status,
  size = "medium",
  variant = "outlined",
  textTransform = "uppercase",
}) => {
  if (!status) {
    return null;
  }

  const statusColor: StatusColor = wfStatusColorMap[status] || "neutral";

  return (
    <Status
      size={size}
      variant={variant}
      color={statusColor}
      label={status}
      textTransform={textTransform}
    />
  );
};
