import React from "react";

import { TabContentContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { RelatedResourcesSection } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/RelatedResourcesSection";
import { StatusSection } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/StatusSection/StatusSection";
import { SpecSection } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/SpecSection";
import { AccordionValuesAsChipsList } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionValuesAsChipsList";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";
import { AddOnRootCauseAnalysis } from "@/components/k8sAddons/LiveStateDrawer/components/AddOnRootCauseAnalysis";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";
import { useTopSectionProps } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useHeaderProps";
import { CertificateStatusEnum } from "@/generated/addonsApi";
import { useCertificateDataAsResource } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateDataAsResource";

const { annotations: annotationsAriaLabel, labels: labelsAriaLabel } =
  certificatesPageAriaLabels.drawer.sections;

export const CertificateDetails: React.FC = () => {
  const { data: certificateData } = useCertificateData();
  const { status } = useTopSectionProps();
  const resource = useCertificateDataAsResource();
  const annotations = certificateData?.data?.object.metadata.annotations;
  const labels = certificateData?.data?.object.metadata.labels;
  const isUnhealthyStatus =
    status === CertificateStatusEnum.Expired ||
    status === CertificateStatusEnum.Failed;

  return (
    <TabContentContainer>
      <AddOnRootCauseAnalysis
        resource={resource}
        flowType={FlowType.CertificateIssue}
        isHealthy={!isUnhealthyStatus}
      />
      <RelatedResourcesSection />
      <StatusSection />
      <SpecSection />
      <AccordionValuesAsChipsList
        title="Annotations"
        values={annotations}
        ariaLabel={annotationsAriaLabel}
      />
      <AccordionValuesAsChipsList
        title="Labels"
        values={labels}
        ariaLabel={labelsAriaLabel}
      />
    </TabContentContainer>
  );
};
