import { useMemo } from "react";

import { useResourcesAPIPost } from "../../../shared/hooks/resources-api/client";
import { NODE_STATE } from "../../../shared/hooks/resources-api/requestResponseMaps";

import Node from "./Node";
import NodeStatus from "./NodeStatus";

const useNodesByClusterNameQuery = (clusterName?: string): Node[] => {
  const { data } = useResourcesAPIPost(
    NODE_STATE,
    {
      clusterName: clusterName,
      fields: ["wide"],
    },
    !clusterName
  );

  return useMemo(
    () =>
      data?.data?.wide?.map((node) => {
        return new Node(
          node.name,
          new NodeStatus(
            node.ready ?? "",
            node.diskPressure ?? "",
            node.memoryPressure ?? ""
          )
        );
      }) ?? [],
    [data]
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useNodesByClusterNameQuery;
