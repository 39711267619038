import { useCallback, useState } from "react";

import { useAuthorization } from "../roles/useAuthorization";

import { requestAgentTaskManager } from "./client";
import {
  INSPECTION_ERROR,
  INSPECTION_USER_PERMISSIONS_ERROR,
} from "./constants/atmResources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AtmGetResourceResponseType } from "./requests/useAtmGetResource";

import { notifyDDError } from "@/shared/hooks/exceptionManagement";

const STATUS_FORBIDDEN = 403;

export enum Status {
  Initializing = "Initializing",
  Fetched = "Fetched",
  Error = "Error",
}
export enum AtmOutputType {
  Wide = "wide",
  Json = "json",
}
export interface AtmRequestResult {
  status: Status;
  execute: () => void;
  resetState: () => void;
  errorMessage: string;
  isFetching: boolean;
}

export const useAtmRequest = (
  path: string,
  body: string,
  handleResponse: (response: AtmGetResourceResponseType) => boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): AtmRequestResult => {
  const [status, setStatus] = useState<Status>(Status.Initializing);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isFetching, setIsFetching] = useState(false);
  const authorization = useAuthorization();

  const handleError = useCallback(
    async (
      response: Response | undefined = undefined,
      responseJSON: AtmGetResourceResponseType | undefined = undefined
    ) => {
      const err = new Error("ATM Request Error");
      setIsFetching(false);
      setStatus(Status.Error);
      if (
        response?.status === STATUS_FORBIDDEN ||
        responseJSON?.failureMessage?.includes("is forbidden")
      ) {
        setErrorMessage(INSPECTION_USER_PERMISSIONS_ERROR);
        notifyDDError(err, {
          message: INSPECTION_USER_PERMISSIONS_ERROR,
          url: path,
        });
      } else if (status !== Status.Fetched) {
        let errorMessage = INSPECTION_ERROR;
        const resJson = responseJSON || (await response?.json());
        if (resJson?.Status) {
          errorMessage = resJson.Status;
        }
        if (resJson?.Message) {
          errorMessage = resJson.Message;
        }
        setErrorMessage(errorMessage);
        notifyDDError(err, {
          message: errorMessage,
          url: path,
        });
      }
    },
    [path, status]
  );

  const resetState = useCallback(() => {
    setStatus(Status.Initializing);
    setIsFetching(false);
    setErrorMessage("");
  }, []);

  const execute = useCallback(async () => {
    if (!authorization || isFetching || body === "null") return;
    setIsFetching(true);
    try {
      const response = await requestAgentTaskManager(path, authorization, body);
      if (!response?.ok) {
        handleError(response);
        return;
      }
      const responseJSON = await response.json();
      const success = handleResponse(responseJSON);

      if (responseJSON?.failureMessage) {
        handleError(response, responseJSON);
        return;
      }

      if (success) {
        setStatus(Status.Fetched);
        setErrorMessage("");
        setIsFetching(false);
      } else {
        handleError();
      }
    } catch (e) {
      handleError();
    }
  }, [authorization, body, handleError, handleResponse, isFetching, path]);

  return { execute, status, resetState, errorMessage, isFetching };
};
