import React from "react";
import {
  GridColDef,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { compact } from "lodash";

import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import {
  PageTableContainer,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";
import {
  columnsConfig,
  useDagsLoadingColumns,
  useDagsTableColumns,
} from "@/components/k8sAddons/addons/workflows/hooks/dagsTableHooks";
import { dagsListPageAriaLabels } from "@/components/k8sAddons/addons/workflows/DagsListPage/ariaLabels";
import { getDisplayingItemsText } from "@/shared/utils/tableUtils";
import { Operator, WorkflowDag } from "@/generated/addonsApi";
import { WorkflowEngine } from "@/components/k8sAddons/addons/workflows/types";

export const DagsTable: React.FC<{ engineType?: WorkflowEngine }> = ({
  engineType,
}) => {
  const navigate = useNavigate();
  const { searchFilter } = useSearchFieldAsGenericFilter({
    filterName: columnsConfig.name.field,
  });

  const response = useFetchLiveStateList({
    additionalRequestFilters: compact([
      {
        name: columnsConfig.workflowEngine.field,
        operator: engineType ? Operator.In : Operator.Nin,
        value: engineType ? [engineType] : Object.values(WorkflowEngine),
      },
      searchFilter,
    ]),
  });

  const { isFetchingData, rowCount, rowsToUse, columnsToUse, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useDagsLoadingColumns({
        engineType,
      }),
      dataStateColumns: useDagsTableColumns({ engineType }),
      tableTitle: "DAGs",
    });

  const commonLiveStateTableProps =
    useCommonLiveStateTableProps(isFetchingData);

  const ariaLabel = isFetchingData
    ? `${dagsListPageAriaLabels.table} loading`
    : `${dagsListPageAriaLabels.table} content`;

  return (
    <PageTableContainer>
      <Typography variant="h5" color="text.secondary">
        {getDisplayingItemsText(
          commonLiveStateTableProps.paginationModel.page,
          commonLiveStateTableProps.paginationModel.pageSize,
          rowCount
        )}
      </Typography>
      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...commonLiveStateTableProps}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        onRowClick={(params: GridRowParams<WorkflowDag>) =>
          navigate(params.row.komodorWorkflowId)
        }
        getRowId={(row) => row.komodorWorkflowId ?? row.id}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={[
          {
            groupId: "latest-run",
            headerName: "Latest Run",
            children: [
              { field: columnsConfig.started.field },
              { field: columnsConfig.duration.field },
              { field: columnsConfig.issues.field },
              { field: columnsConfig.status.field },
            ],
            renderHeaderGroup: (params) => (
              <Typography variant="h6">{params.headerName}</Typography>
            ),
          },
        ]}
      />
    </PageTableContainer>
  );
};
