import { useEffect } from "react";

import { RightSizingCostSummaryByServiceResponse } from "../../../../../generated/metricsApi";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectSetRightSizingRecommendationsState,
  selectRightSizingRecommendationModal,
  selectRightSizingRecommendationsState,
  selectLastVisitTime,
} from "../../../store/costOptimizationStoreSelectors";
import { useFetchCostRightSizingRecommendationsData } from "../../shared/RecommendationsModal/recommendationsModalHooks";
import { useGetRowData } from "../RightSizingTable/rightSizingTableHooks";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const useFetchRightSizingRecommendationData = (): void => {
  const setRightSizingRecommendationsState = useCostOptimizationStore(
    selectSetRightSizingRecommendationsState
  );

  const { rowId } = useCostOptimizationStore(
    selectRightSizingRecommendationModal
  );

  const prevRecommendationState = useCostOptimizationStore(
    selectRightSizingRecommendationsState
  );

  const lastVisitTime = useCostOptimizationStore(selectLastVisitTime);
  const rowData = useGetRowData(rowId);
  useFetchCostRightSizingRecommendationsData({
    rowData,
    prevRecommendationState,
    lastVisitTime,
    onDataFetched: setRightSizingRecommendationsState,
  });
};

export const useReportLoadTime = (
  data: RightSizingCostSummaryByServiceResponse | undefined,
  enabled: boolean
): void => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    if (enabled) {
      reportLoadingState("rightSizingRecommendationModal", !data?.rows?.length);
    }
  }, [data?.rows?.length, reportLoadingState, enabled]);
};
