import { ColorScheme } from "@/components/k8sAddons/constants/colorScheme";
import { ONE_DAY_IN_MS } from "@/shared/constants/time";
import { INVALID_TIME } from "@/components/k8sAddons/constants/time";
import { AggregationsListItemProps } from "@/components/k8sAddons/components/AggregationsListItem";
import { CertificateStatusEnum, Operator } from "@/generated/addonsApi";
import {
  getSevenDaysFromEndOfDay,
  getStartOfDayInUtc,
  getThirtyDaysFromEndOfDay,
} from "@/components/k8sAddons/utils/timeUtils";

export const getTypographySchemeByTimeElapsed = (timeElapsedInMs: number) => {
  const daysElapsed = timeElapsedInMs / ONE_DAY_IN_MS;
  if (timeElapsedInMs === INVALID_TIME) {
    return { color: ColorScheme["info"], fontWeight: 400 };
  }
  if (daysElapsed > 14) {
    return { color: ColorScheme["info"] };
  }
  if (daysElapsed > 10) {
    return { color: ColorScheme["warning"], fontWeight: 700 };
  }
  return { color: ColorScheme["error"], fontWeight: 700 };
};

export const getAggregationFiltersList = (): AggregationsListItemProps[] => {
  return [
    {
      title: "Total Certs",
      filterCategory: "name",
      color: "info",
    },
    {
      title: "Valid Certs",
      filterCategory: "status",
      color: "success",
      filters: [
        {
          value: ["valid"],
          operator: Operator.In,
          name: "status",
        },
      ],
    },
    {
      title: "Expired Certs",
      filterCategory: "status",
      color: "error",
      filters: [
        {
          value: ["expired"],
          operator: Operator.In,
          name: "status",
        },
      ],
    },
    {
      title: "Failed Certs",
      filterCategory: "status",
      color: "error",
      filters: [
        {
          value: [CertificateStatusEnum.Failed],
          operator: Operator.In,
          name: "status",
        },
      ],
    },
    {
      title: "Expiring within 7 days",
      filterCategory: "status",
      color: "warning",
      filters: [
        {
          value: [getStartOfDayInUtc().toISOString()],
          operator: Operator.Gte,
          name: "expiration",
        },
        {
          value: [new Date(getSevenDaysFromEndOfDay()).toISOString()],
          operator: Operator.Lte,
          name: "expiration",
        },
      ],
    },
    {
      title: "Expiring within 30 days",
      filterCategory: "status",
      color: "warning",
      filters: [
        {
          value: [new Date(getSevenDaysFromEndOfDay()).toISOString()],
          operator: Operator.Gt,
          name: "expiration",
        },
        {
          value: [new Date(getThirtyDaysFromEndOfDay()).toISOString()],
          operator: Operator.Lte,
          name: "expiration",
        },
      ],
    },
  ];
};
