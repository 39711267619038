import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Warning from "@mui/icons-material/Warning";
import { LightningBolt16 } from "@komodorio/design-system/icons";
import Button from "@mui/material/Button";
import ChevronRight from "@mui/icons-material/ChevronRight";

import { IssueBannerContainer } from "../../IssueBanner/IssueBanner";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { TriageDataDb } from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/types";
import { useInsights } from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/insights/useInsights";
import { SmartTextsJoin } from "../../../shared/utils/SmartTextJoin";
import { useGetMonitorRunById } from "../../../shared/hooks/monitors-api/client/monitors/useGetMonitorRunById";
import { WorkflowConfigType } from "../../monitorsView/common/types";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

type AvailabilityIssueBannerProps = {
  issueId: string;
  serviceId: string;
  triageData?: TriageDataDb;
  text: string;
};
export const AvailabilityIssueBanner: React.FC<
  AvailabilityIssueBannerProps
> = ({ issueId, serviceId, triageData, text }) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const { data, isFetching } = useGetMonitorRunById(
    {
      id: issueId,
      type: WorkflowConfigType.Availability,
      fields: ["triageData"],
    },
    {
      enabled: !triageData,
    }
  );

  const insights = useInsights(
    triageData ?? (data?.data?.triageData as TriageDataDb)
  );

  if (isFetching) {
    return null;
  }
  return (
    <IssueBannerContainer
      aria-label={AriaLabels.ResourceView.IssueBanner.Pod.AvailabilityIssue}
      bgColor={muiColors.pink[25]}
      onClick={() =>
        pushDrawer({
          drawerType: DrawerType.EventDrawerById,
          eventType: WorkflowConfigType.Availability,
          serviceId,
          eventIds: [issueId],
        })
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "8px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: "6px" }}>
          <Box sx={{ display: "flex", columnGap: "6px", alignItems: "center" }}>
            <Warning
              sx={{ width: 20, height: 20, color: muiColors.pink[600] }}
            />
            <Typography variant="overline2" sx={{ color: muiColors.pink[700] }}>
              {text}
            </Typography>
          </Box>
          {insights.length > 0 ? (
            <Box
              sx={{ display: "flex", columnGap: "6px", alignItems: "center" }}
            >
              <Typography variant="overline2" color="text.secondary">
                Insights
              </Typography>
              <LightningBolt16 color={muiColors.orange[400]} />
              <SmartTextsJoin
                texts={insights}
                icon={<LightningBolt16 color={muiColors.orange[400]} />}
                ariaLabel={
                  AriaLabels.ResourceView.IssueBanner.Pod
                    .AvailabilityIssueTooltip
                }
              />
            </Box>
          ) : null}
        </Box>
        <Button
          variant="outlined"
          size="small"
          endIcon={<ChevronRight width="10px" height="10px" />}
        >
          Investigate
        </Button>
      </Box>
    </IssueBannerContainer>
  );
};
