import React, { useEffect } from "react";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { useAgentInfoById } from "../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { KubernetesCRDResource } from "../../inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";
import { useInspectionParams } from "../../InspectionViews/common";
import { useInspectionData } from "../../utils/useGetInspectionData";
import AtmTable from "../../getMainViewContent";
import useInspectionFilters from "../../filters/useInspectionFilters";
import { CRDsFilters } from "../components/CRDsFilters";
import { useFilteredClusters } from "../../filters/useFilteredClusters";
import { useIsAgentSupportedCRDsFromAtm } from "../utils/useIsAgentSupportedCRDs";
import { getCRPageError } from "../components/CRPageError";
import { LinesLoader } from "../../../common/loaders/Line";
import { CRDsPageContainer } from "../components/styles";

import { CRDsDataProvider } from "./CRDsDataProvider";

import { getViewOptionsByResource } from "@/components/Inspection/InspectionViews/datadogReporting/inspectionViewsReportingUtils";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const CRDsPageContent: React.FC = () => {
  const {
    cluster = "",
    onClusterChange,
    agentId = "",
  } = useInspectionParams(KubernetesCRDResource);

  const { inspectionAtm } = useOverridableFlags();
  const { agentProperties: agentInfo } = useAgentInfoById(agentId, cluster);
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  const { resourceList, refresh, isLiveDataSupported } = useInspectionData({
    agentId,
    agentInfo,
    cluster,
    kubernetesResource: KubernetesCRDResource,
  });

  const filteredTableResults = useInspectionFilters({
    resourceList,
    kubernetesResource: KubernetesCRDResource,
  });

  const { filteredClusterSuggestions, clusterToUse: selectedCluster } =
    useFilteredClusters(cluster);

  const { isAgentSupported, fetching: fetchingAgentInfo } =
    useIsAgentSupportedCRDsFromAtm(selectedCluster);

  const inspectionFilters = (
    <CRDsFilters
      clustersOptions={filteredClusterSuggestions}
      kubernetesResource={KubernetesCRDResource}
      resourceList={resourceList}
      onClusterChange={onClusterChange}
      selectedCluster={selectedCluster}
    />
  );
  const errorView = getCRPageError({
    agentId,
    inspectionAtm,
    cluster: selectedCluster,
    isAgentSupported,
    inspectionFiltersComponent: inspectionFilters,
  });

  useEffect(() => {
    reportLoadingState("resource", fetchingAgentInfo || resourceList.fetching);
  }, [fetchingAgentInfo, reportLoadingState, resourceList.fetching]);

  if (fetchingAgentInfo) {
    return (
      <CRDsPageContainer>
        {inspectionFilters}
        <LinesLoader marginTop="100px" />
      </CRDsPageContainer>
    );
  }

  if (errorView.hasError) {
    return errorView.component;
  }

  return (
    <CRDsDataProvider agentId={agentId} cluster={selectedCluster}>
      <CRDsPageContainer>
        {inspectionFilters}
        <AtmTable
          clusterName={selectedCluster}
          resourceList={resourceList}
          kubernetesResource={KubernetesCRDResource}
          filteredTableResults={filteredTableResults}
          refresh={refresh}
          shouldUseLiveData={isLiveDataSupported}
          agentUpgradeRequired={false}
        />
      </CRDsPageContainer>
    </CRDsDataProvider>
  );
};

export const CRDsPage: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        ...getViewOptionsByResource(KubernetesCRDResource),
      }}
    >
      <CRDsPageContent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
