import { useMemo } from "react";
import { subHours } from "date-fns";

import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";
import {
  DELETED_PODS_PREFIX,
  INSPECTION_SHOW_DELETED_PODS_PARAM_KEY,
  TIME_WINDOW_PARAM_KEY,
} from "@/shared/config/urlSearchParamsKeys";
import { useDrawerTimeWindowFromUrl } from "@/components/ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/useDrawerTimeWindowFromUrl";
import { DELETED_PODS_TIMEFRAME_PARAM_KEY } from "@/components/Inspection/historicalFetch/useResourceListFromDatabase";
import { Timeframe } from "@/shared/types/TimeWindow";

const initialTimeWindow = {
  start: subHours(new Date(), 1),
  end: new Date(),
  timeframe: Timeframe.LastHour,
};

export const useDeletedPodsProperties = () => {
  const [showDeletedPodsUrlParam, setShowDeletedPodsUrlParam] =
    useDrawerUrlState<boolean>(INSPECTION_SHOW_DELETED_PODS_PARAM_KEY);
  const [deletedPodsTimeWindowUrlParam, setDeletedPodsTimeWindowUrlParam] =
    useDrawerTimeWindowFromUrl(
      initialTimeWindow,
      DELETED_PODS_PREFIX + TIME_WINDOW_PARAM_KEY,
      DELETED_PODS_TIMEFRAME_PARAM_KEY
    );

  return useMemo(
    () => ({
      showDeletedPodsUrlParam,
      setShowDeletedPodsUrlParam,
      deletedPodsTimeWindowUrlParam,
      setDeletedPodsTimeWindowUrlParam,
    }),
    [
      deletedPodsTimeWindowUrlParam,
      setDeletedPodsTimeWindowUrlParam,
      setShowDeletedPodsUrlParam,
      showDeletedPodsUrlParam,
    ]
  );
};
