import { TaskType } from "komodor-types";
import { useMemo, useState } from "react";

import useAgentTaskLogic from "../tasks/useAgentTaskLogic";

import { MetricsTaskResult } from "./metricsTaskResultParser";
import { MonitoringProvider } from "./MonitoringProvider";

interface MetricsAgentTaskProps {
  agentId: string | undefined;
  shouldBringMetrics: boolean;
  monitoringProvider: MonitoringProvider;
}

const MetricsAgentTaskMaxRetries = 6;
export const BRINGING_METRICS_INTERVAL_IN_SECONDS = 3;

function getTimeNowRounded(): number {
  const coefficient = 1000 * BRINGING_METRICS_INTERVAL_IN_SECONDS;
  const now = new Date();
  return (
    new Date(Math.round(now.getTime() / coefficient) * coefficient).getTime() /
    1000
  );
}

const useMetricsAgentTask = ({
  agentId,
  shouldBringMetrics,
  monitoringProvider,
}: MetricsAgentTaskProps): {
  metricsResult: MetricsTaskResult | null;
  errorBringingMetrics: string | null;
  isFetching: boolean;
  refresh: () => void;
} => {
  const [retries, setRetries] = useState(0);
  const [triggerManually, setTriggerManually] = useState(false);

  const nowRounded = getTimeNowRounded();
  const executeTaskVars = useMemo(() => {
    if (!shouldBringMetrics || !agentId) return null;
    const taskData = monitoringProvider.getMetricsTasksData(nowRounded);
    if (!taskData) return null;

    setRetries(0);
    setTriggerManually(true);
    return {
      agentId,
      type: TaskType.METRICS,
      data: taskData,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, nowRounded, shouldBringMetrics]);

  const { resultFromQuery, failureMessage, refresh } = useAgentTaskLogic({
    retries,
    setRetries,
    maxRetries: MetricsAgentTaskMaxRetries,
    executeTaskVars,
    triggerManually,
    setTriggerManually,
  });

  const errorBringingMetrics = useMemo(() => {
    if (failureMessage) {
      return failureMessage;
    }
    if (retries > MetricsAgentTaskMaxRetries) {
      return "the number of retries exceeded the maximum";
    }
    return null;
  }, [failureMessage, retries]);

  const metricsResult = useMemo(() => {
    if (resultFromQuery?.agent_task_result.length && monitoringProvider) {
      return monitoringProvider.parseMetricTasksResponse(
        resultFromQuery.agent_task_result[0].result
      );
    }
    return null;
  }, [monitoringProvider, resultFromQuery?.agent_task_result]);
  const isFetching = useMemo(() => {
    return shouldBringMetrics && !errorBringingMetrics && !metricsResult;
  }, [errorBringingMetrics, metricsResult, shouldBringMetrics]);
  return { metricsResult, errorBringingMetrics, isFetching, refresh };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useMetricsAgentTask;
