export enum ServicesComparisonTabs {
  Attributes = "AttributesComparison",
  Metrics = "MetricsComparison",
}

export const BASELINE_SERVICE_OPTION_KEY = "baselineServiceOption";
export const COMPARED_SERVICES_OPTION_KEY = "comparedServicesOptions";
export const SELECTED_TAB_KEY = "selectedTab";
export const SHOW_ONLY_DRIFT_KEY = "showOnlyDrift";

export const MAX_COMPARED_SERVICES = 10;
export const MAX_YAML_LINES = 7;
export const MAX_CONTAINER_NAMES = 2;

export const CELL_HEIGHT = "137px";
export const CELL_WIDTH = "150px";
export const CELL_MAX_WIDTH = "174px";

export const DRIFT_ATTRIBUTES_COMPONENT_NAME = "DriftAnalysisAttributes";
