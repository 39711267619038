import React, { useContext, useMemo } from "react";

import { getAppConfig } from "../config/appConfig";
import { getDatadogReplayUrl } from "../hooks/exceptionManagement";

export interface AnalyticsAPI {
  dispatchEventViaBackend: (
    eventName: string,
    properties?: { [key: string]: unknown },
    sendEventToSegment?: boolean,
    inInitial?: boolean,
    integrationsNotToInclude?: string[]
  ) => Promise<void>;
  identifySegment: (
    data: { [_: string]: unknown },
    eventType: string,
    userId: string,
    inInitial?: boolean
  ) => Promise<Response | undefined>;
}

const defaultAnalyticsContext: AnalyticsAPI = {
  dispatchEventViaBackend: async () => undefined,
  identifySegment: async () => undefined,
};
const ANALYTICS_ADMIN_USER_EMAIL = "komodor.analytics@admin.com";
const analyticsContext = React.createContext(defaultAnalyticsContext);

export const AnalyticsApiProvider: React.FC<{
  accessToken: string | null;
  children?: React.ReactNode;
}> = ({ accessToken, children }) => {
  const value = useMemo(() => {
    if (!accessToken) {
      return defaultAnalyticsContext;
    }
    const analyticsApi: AnalyticsAPI = {
      // [CU-86c1gn74n] fix max-params
      // eslint-disable-next-line max-params
      dispatchEventViaBackend: async (
        eventName,
        properties,
        sendEventToSegment = true,
        inInitial = false,
        integrations?: string[]
      ): Promise<void> => {
        if (getAppConfig().env === "development") return;
        if (/HeadlessChrome/.test(navigator.userAgent)) return;
        return new Promise((resolve) => {
          if (typeof properties === "undefined") properties = {};
          properties["accountname"] = window.komodor.userMetadata?.accountName;
          properties["sessionReplayURL"] = getDatadogReplayUrl();
          properties["email"] = window.komodor.userEmail;

          if (sendEventToSegment)
            sendToSegment(
              eventName,
              properties,
              accessToken,
              inInitial,
              integrations
            );
        });
      },
      // [CU-86c1gn74n] fix max-params
      // eslint-disable-next-line max-params
      identifySegment: async (data, eventType, userId, inInitial = false) => {
        if (getAppConfig().env === "development") return;
        if (/HeadlessChrome/.test(navigator.userAgent)) return;
        return sendData(data, eventType, userId, accessToken, inInitial);
      },
    };
    return analyticsApi;
  }, [accessToken]);

  return (
    <analyticsContext.Provider value={value}>
      {children}
    </analyticsContext.Provider>
  );
};
const useAnalyticsApi = (): AnalyticsAPI => useContext(analyticsContext);
// [CU-86bx58peb] fix fast refresh
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default useAnalyticsApi;

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
function createBody(
  segmentCallType: string,
  userId: string,
  params: { [p: string]: unknown },
  eventName: string | undefined,
  integrations?: string[]
): Record<string, unknown> {
  const data: Record<string, unknown> = { userId: userId };
  if (segmentCallType === "identify") {
    data["traits"] = params;
  } else if (segmentCallType === "track") {
    if (!eventName) {
      throw new Error("no eventName parameter on segment track call");
    }
    params["datadogReplay"] = getDatadogReplayUrl();
    data["properties"] = params;
    data["context"] = {
      groupId: window.komodor.userMetadata?.accountSalesforceId,
    };
    if (!window.komodor.userMetadata?.accountSalesforceId) {
      integrations
        ? integrations.push("Totango")
        : (integrations = ["Totango"]);
    }
    data["eventName"] = eventName;
    data["integrations"] = integrations?.reduce(
      (acc, str) => ({ ...acc, [str]: false }),
      { All: true }
    );
  }
  return data;
}

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
function sendToSegment(
  eventName: string,
  properties: { [p: string]: unknown },
  accessToken: string,
  inInitial: boolean,
  integrations?: string[]
) {
  sendToSegmentThroughAPI(
    eventName,
    properties,
    accessToken,
    inInitial,
    integrations
  );
}

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
function sendToSegmentThroughAPI(
  eventName: string,
  properties: { [p: string]: unknown },
  accessToken: string,
  inInitial: boolean,
  integrations?: string[]
) {
  const userEmail = window.komodor.userEmail;
  if (!userEmail) {
    sessionStorage.removeItem("userLoggedIn");
    return;
  }
  sendData(
    properties,
    "track",
    userEmail,
    accessToken,
    inInitial,
    eventName,
    integrations
  );
}

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
function sendData(
  data: { [p: string]: unknown },
  eventType: string,
  userId: string,
  accessToken: string,
  inInitial: boolean,
  eventName?: string,
  integrations?: string[]
): Promise<Response | undefined> {
  const body = createBody(eventType, userId, data, eventName, integrations);
  const auth_skipper = inInitial ? ANALYTICS_ADMIN_USER_EMAIL : "";
  return fetch(`${getAppConfig().analyticsApiUrl}/segment/${eventType}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    //credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "api-key": auth_skipper,
      Authorization: accessToken,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(body),
  });
}
