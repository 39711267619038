import Typography from "@mui/material/Typography";
import React from "react";

import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { AccordionContentItemContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { ExternalDnsRecord } from "@/generated/addonsApi";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import { EMPTY_FIELD } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/constants";

export const SettingsSection: React.FC<{
  externalDns: ExternalDnsRecord;
}> = ({ externalDns }) => {
  const { sources, dnsProvider, domainFilters } = externalDns;
  return (
    <AccordionWrapper
      title={"Settings"}
      accordionContent={
        <>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Sources
            </Typography>
            <Typography variant="body2">
              {sources.length ? sources.sort().join(", ") : EMPTY_FIELD}
            </Typography>
          </AccordionContentItemContainer>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              DNS provider
            </Typography>
            <Typography variant="body2">
              {dnsProvider ? dnsProvider : EMPTY_FIELD}
            </Typography>
          </AccordionContentItemContainer>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Domain filters
            </Typography>
            <Typography variant="body2">
              {domainFilters.length
                ? domainFilters.sort().join(", ")
                : EMPTY_FIELD}
            </Typography>
          </AccordionContentItemContainer>
        </>
      }
      ariaLabel={externalDnsListPageAriaLabels.drawer.sections.settings}
    />
  );
};
