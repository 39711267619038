import React from "react";

import {
  ACTIONS_ROUTE,
  POLICIES_ROUTE,
  ROLES_ROUTE,
} from "@/components/routes/routes";
import {
  RBAC_ACTION,
  RBAC_POLICY,
  RBAC_ROLE,
} from "@/shared/config/urlSearchParamsKeys";
import {
  ColumnDefinitionGetterProps,
  TooltipTextCellProps,
} from "@/components/Settings/Users/EffectivePermissions/types";
import { COLUMN_MAX_WIDTH } from "@/components/Settings/Users/EffectivePermissions/constants";
import { TooltipTextCellWrapper } from "@/components/Settings/Users/EffectivePermissions/TooltipTextCellWrapper";

export const getColumnDefinition = ({
  allowLinks,
}: ColumnDefinitionGetterProps) => [
  {
    field: "cluster",
    headerName: "Cluster",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
  {
    field: "namespace",
    headerName: "Namespace",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
  {
    field: "action",
    headerName: "Action",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    urlGetter: (action: string) => `${ACTIONS_ROUTE}?${RBAC_ACTION}=${action}`,
    accountFeature: "rbacClusterSync",
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
  {
    field: "policyName",
    headerName: "Policy",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    urlGetter: (policy: string) => `${POLICIES_ROUTE}?${RBAC_POLICY}=${policy}`,
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
  {
    field: "roleName",
    headerName: "Role",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    urlGetter: (role: string) => `${ROLES_ROUTE}?${RBAC_ROLE}=${role}`,
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
];
