import { CheckRawReultWithSpecLevel } from "../StaticPreventionUtils";

export const tabs = ["Checks", "Ignored checks"];

export const filterResults = (
  checks: CheckRawReultWithSpecLevel[],
  selectedOptions: string[],
  showPassedChecks: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const filteredChecks = checks.filter((check) =>
    selectedOptions.includes(check.Category)
  );
  return showPassedChecks
    ? filteredChecks
    : filteredChecks.filter((check) => !check.Success);
};
