import { FormattedAccount } from "./types";

import { Account } from "@/generated/accounts";

export const getFormattedAccounts = (accounts: Account[]): FormattedAccount[] =>
  accounts.map((account) => ({
    label: account.displayName ?? account.name,
    value: account.id,
    isDisabled: account.disabled,
  }));
