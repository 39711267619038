/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from "react";
import {
  StackedComparisonTable,
  ComparisonTable,
  ComparisonTableProps,
  Compared,
  RowData,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import { EnvVar, Probe } from "kubernetes-types/core/v1";
import { muiColors } from "@komodorio/design-system";
import YAML from "yaml";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import CircleCheckIcon from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";

import { useServicesComparisonContext } from "../context/useServicesComparisonContext";
import { MAX_COMPARED_SERVICES, MAX_YAML_LINES } from "../constants";

import { NoServicesAddedState } from "./NoServicesAddedState";
import { DiffModal } from "./DiffModal";
import { YamlViewer } from "./YamlViewer";
import { NameFieldCell } from "./styles";

import {
  ServiceComparisonResponse,
  ServiceComparisonResponseContainers,
} from "@/generated/workspacesApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";

type ComparisonContainer = {
  komodorUid: string;
  cluster: string;
  namespace: string;
  name: string;
  resourceKind: string;
  image: string;
  envVars: Array<EnvVar> | undefined;
  livenessProbe: Probe | undefined;
  readinessProbe: Probe | undefined;
  startupProbe: Probe | undefined;
  memoryRequests: string;
  memoryLimits: string;
  cpuRequests: string;
  cpuLimits: string;
};

type CellRenderer =
  ComparisonTableProps<ComparisonContainer>["attributes"][number]["renderCell"];

export type ContainersAttributesComparisonProps = {
  data: ServiceComparisonResponse | undefined;
  showOnlyDrift: boolean;
  isLoading: boolean;
  isError: boolean;
  errorStateElement: React.ReactNode;
};

const getDrawerData = ({
  data,
  isBaseline,
}: RowData<ComparisonContainer>): DrawerStatePush => {
  return {
    drawerType: DrawerType.ResourceDrawerByData,
    cluster: isBaseline ? data.cluster : data.data?.cluster?.value ?? "",
    namespace: isBaseline ? data.namespace : data.data?.namespace?.value ?? "",
    resourceType: isBaseline
      ? data.resourceKind
      : data.data?.resourceKind?.value ?? "",
    resourceName: isBaseline ? data.name : data.data?.name?.value ?? "",
  };
};

export const ContainersAttributesComparison: React.FC<
  ContainersAttributesComparisonProps
> = ({ data, showOnlyDrift, isLoading, isError, errorStateElement }) => {
  const { baselineServiceId, comparedServicesIds } =
    useServicesComparisonContext();
  const [seeAllModal, setSeeAllModal] = useState<{
    title: string;
    content: string;
  } | null>(null);
  const [diffModal, setDiffModal] = useState<{
    title: string;
    comparedUid: string;
    oldObj: unknown;
    newObj: unknown;
  } | null>(null);

  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const cellRenderer = useCallback(
    (field: keyof ComparisonContainer, title?: string): CellRenderer =>
      ({ data, isBaseline }) => {
        const isYaml =
          field === "livenessProbe" ||
          field === "readinessProbe" ||
          field === "startupProbe" ||
          field === "envVars";
        if (isYaml) {
          const content = YAML.stringify(
            isBaseline ? data[field] : data.data?.[field]?.value,
            { indent: 2 }
          );
          const lines = content.split("\n");
          return (
            <Box
              display="flex"
              flexDirection="column"
              marginTop="-16px"
              marginBottom="-16px"
            >
              <Typography
                variant="body2"
                color={isBaseline ? muiColors.gray[600] : "text.primary"}
                whiteSpace="pre-wrap"
              >
                {lines.slice(0, MAX_YAML_LINES).join("\n")}
              </Typography>
              {lines.length > MAX_YAML_LINES && (
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setSeeAllModal({ title: title ?? "", content });
                  }}
                  sx={{
                    marginTop: "8px",
                    cursor: "pointer",
                    textDecoration: "none",
                    verticalAlign: "middle",
                  }}
                >
                  See All{" "}
                  <LaunchOutlined
                    sx={{
                      fontSize: "16px",
                      verticalAlign: "middle",
                      position: "relative",
                      top: "-2px",
                    }}
                  />
                </Link>
              )}
            </Box>
          );
        }

        if (field === "name") {
          return (
            <NameFieldCell>
              {isBaseline ? data[field] : data.data?.[field]?.value}
            </NameFieldCell>
          );
        }
        const centerText =
          field === "cpuRequests" ||
          field === "cpuLimits" ||
          field === "memoryRequests" ||
          field === "memoryLimits";
        return (
          <Typography
            variant="body2"
            color={isBaseline ? muiColors.gray[600] : "text.primary"}
            textAlign={centerText ? "center" : "left"}
          >
            {
              (isBaseline
                ? data[field]
                : data.data?.[field]?.value) as React.ReactNode
            }
          </Typography>
        );
      },
    []
  );

  const getBaselineContainer = useCallback(
    (container: ServiceComparisonResponseContainers): ComparisonContainer => {
      const baselineParsedKomodorUid = baselineServiceId
        ? parseKomodorUid(baselineServiceId.value)
        : undefined;

      const b = container.baselineServiceContainer;
      return {
        komodorUid: baselineServiceId?.value ?? "",
        cluster: baselineParsedKomodorUid?.cluster ?? "",
        namespace: baselineParsedKomodorUid?.namespace ?? "",
        name: baselineParsedKomodorUid?.name ?? "",
        resourceKind: baselineParsedKomodorUid?.kind ?? "",
        image: b.image.value ?? "",
        envVars: b.envVars.value as Array<EnvVar> | undefined,
        livenessProbe: b.livenessProbe.value,
        readinessProbe: b.readinessProbe.value,
        startupProbe: b.startupProbe.value,
        memoryRequests: b.memoryRequests.value ?? "",
        memoryLimits: b.memoryLimits.value ?? "",
        cpuRequests: b.cpuRequests.value ?? "",
        cpuLimits: b.cpuLimits.value ?? "",
      };
    },
    [baselineServiceId]
  );

  const getComparedServicesContainers = useCallback(
    (
      container: ServiceComparisonResponseContainers
    ): Compared<ComparisonContainer>[] => {
      return (
        comparedServicesIds
          ?.map((serviceUid, i): Compared<ComparisonContainer> => {
            const parsedComparedKomodorUid = serviceUid
              ? parseKomodorUid(serviceUid.value)
              : undefined;
            const serviceContainer = container.targetServicesContainer?.[i];
            const serviceData = {
              komodorUid: {
                value: serviceUid.value,
                isDiff: false,
                isEmpty: false,
              },
              cluster: {
                value: parsedComparedKomodorUid?.cluster,
                isDiff: false,
                isEmpty: false,
              },
              namespace: {
                value: parsedComparedKomodorUid?.namespace,
                isDiff: false,
                isEmpty: false,
              },
              resourceKind: {
                value: parsedComparedKomodorUid?.kind,
                isDiff: false,
                isEmpty: false,
              },
              name: {
                value: parsedComparedKomodorUid?.name,
                isDiff: false,
                isEmpty: false,
              },
            };
            if (!serviceContainer) {
              return {
                isLoading: true,
                isMissingData: true,
                data: serviceData,
              };
            }
            return {
              isLoading,
              isMissingData: serviceContainer.isContainerExists === false,
              data: {
                ...serviceData,
                ...(serviceContainer && serviceContainer.isContainerExists
                  ? {
                      ...serviceContainer,
                      envVars: {
                        ...serviceContainer.envVars,
                        value: serviceContainer.envVars.value as
                          | Array<EnvVar>
                          | undefined,
                      },
                    }
                  : {}),
              },
            };
          })
          .filter((_, i) => {
            if (!showOnlyDrift) {
              return true;
            }
            return data?.targetServices?.[i]?.isDiff === true;
          }) ?? []
      );
    },
    [comparedServicesIds, data?.targetServices, isLoading, showOnlyDrift]
  );

  const getAttributes = useCallback(
    (
      baseline: ComparisonContainer | undefined,
      container?: ServiceComparisonResponseContainers | undefined
    ): ComparisonTableProps<ComparisonContainer>["attributes"] => {
      const compared =
        container !== undefined ? getComparedServicesContainers(container) : [];
      return (
        [
          {
            headerName: "Service/Attribute",
            field: "name",
            renderCell: cellRenderer("name"),
            showValueAlways: true,
            onClick: (data) => {
              pushDrawer(getDrawerData(data));
            },
            cellBodyClassName: "service-name-cell",
          },
          {
            headerName: "Image",
            field: "image",
            hideValueIfDifferent: false,
            renderCell: cellRenderer("image"),
          },
          {
            headerName: "CPU requests",
            field: "cpuRequests",
            renderCell: cellRenderer("cpuRequests"),
          },
          {
            headerName: "CPU limits",
            field: "cpuLimits",
            renderCell: cellRenderer("cpuLimits"),
          },
          {
            headerName: "Memory requests",
            field: "memoryRequests",
            renderCell: cellRenderer("memoryRequests"),
          },
          {
            headerName: "Memory limits",
            field: "memoryLimits",
            renderCell: cellRenderer("memoryLimits"),
          },
          {
            headerName: "Env variables",
            field: "envVars",
            hideValueIfDifferent: true,
            renderCell: cellRenderer("envVars", "Environment variables"),
            onClick: ({ data, isBaseline }) => {
              if (isBaseline) {
                return;
              }
              setDiffModal({
                title: "Env variables",
                comparedUid: data.data?.komodorUid?.value ?? "",
                oldObj: baseline?.envVars,
                newObj: data.data?.envVars?.value,
              });
            },
          },
          {
            headerName: "Liveness probes",
            field: "livenessProbe",
            hideValueIfDifferent: true,
            renderCell: cellRenderer("livenessProbe", "Liveness probes"),
            onClick: ({ data, isBaseline }) => {
              if (isBaseline) {
                return;
              }
              setDiffModal({
                title: "Liveness probes",
                comparedUid: data.data?.komodorUid?.value ?? "",
                oldObj: baseline?.livenessProbe,
                newObj: data.data?.livenessProbe?.value,
              });
            },
          },
          {
            headerName: "Readiness probes",
            field: "readinessProbe",
            hideValueIfDifferent: true,
            renderCell: cellRenderer("readinessProbe", "Readiness probes"),
            onClick: ({ data, isBaseline }) => {
              if (isBaseline) {
                return;
              }
              setDiffModal({
                title: "Readiness probes",
                comparedUid: data.data?.komodorUid?.value ?? "",
                oldObj: baseline?.readinessProbe,
                newObj: data.data?.readinessProbe?.value,
              });
            },
          },
          {
            headerName: "Startup probes",
            field: "startupProbe",
            hideValueIfDifferent: true,
            renderCell: cellRenderer("startupProbe", "Startup probes"),
            onClick: ({ data, isBaseline }) => {
              if (isBaseline) {
                return;
              }
              setDiffModal({
                title: "Startup probes",
                comparedUid: data.data?.komodorUid?.value ?? "",
                oldObj: baseline?.startupProbe,
                newObj: data.data?.startupProbe?.value,
              });
            },
          },
        ] as ComparisonTableProps<ComparisonContainer>["attributes"]
      ).filter((attribute) => {
        if (
          compared.length === 0 ||
          !showOnlyDrift ||
          attribute.field === "name" ||
          attribute.field === "cluster" ||
          attribute.field === "namespace" ||
          attribute.field === "resourceKind" ||
          attribute.field === "komodorUid"
        ) {
          return true;
        }
        return (
          container?.baselineServiceContainer?.[attribute.field]
            ?.isDiffAcrossTargetServices === true
        );
      });
    },
    [getComparedServicesContainers, cellRenderer, pushDrawer, showOnlyDrift]
  );

  const isDriftDetected = useMemo(() => {
    return data?.containers?.some((container) => container.isDiff) ?? false;
  }, [data?.containers]);

  return (
    <>
      {(!showOnlyDrift || isDriftDetected) && (
        <StackedComparisonTable title="Container Attributes">
          {!data?.containers ? (
            <ComparisonTable
              title="Container"
              isStacked={true}
              limit={MAX_COMPARED_SERVICES}
              isLoading={isLoading}
              attributes={getAttributes(undefined)}
              baseline={undefined}
              compared={
                comparedServicesIds?.map((serviceUid) => {
                  const name = parseKomodorUid(serviceUid.value)?.name ?? "";
                  return {
                    data: {
                      name: { value: name, isDiff: false, isEmpty: false },
                    },
                    isMissingData: false,
                    isLoading: true,
                  };
                }) ?? []
              }
              noBaselineElement={undefined}
              noComparedItemsElement={
                isLoading ? undefined : <NoServicesAddedState />
              }
              isError={isError}
              errorStateElement={errorStateElement}
            />
          ) : (
            data.containers.map((container) => {
              const baseline = getBaselineContainer(container);
              const compared = getComparedServicesContainers(container);
              const showContainer = !showOnlyDrift || container.isDiff;
              return showContainer ? (
                <ComparisonTable
                  key={container.name}
                  limit={MAX_COMPARED_SERVICES}
                  title={container.name}
                  titleLeftElement={
                    compared.length === 0 ? null : container.isDiff ? (
                      <CompareArrowsIcon
                        sx={{
                          color: "error.dark",
                        }}
                      />
                    ) : (
                      <Box display="flex" gap="4px" alignItems="center">
                        <Divider
                          orientation="vertical"
                          sx={{
                            height: "22px",
                            width: "1px",
                            marginLeft: "4px",
                            marginRight: "20px",
                          }}
                        />
                        <CircleCheckIcon
                          sx={{
                            fontSize: "20px",
                            color: muiColors.green[500],
                          }}
                        />
                        <Typography variant="body2">
                          No drift detected
                        </Typography>
                      </Box>
                    )
                  }
                  isStacked={true}
                  initialIsOpen={
                    data.containers?.length === 1 && !!container?.isDiff
                  }
                  collapsible
                  isLoading={false}
                  noBaselineElement={null}
                  noComparedItemsElement={
                    comparedServicesIds === null ||
                    comparedServicesIds?.length === 0 ? (
                      <NoServicesAddedState />
                    ) : null
                  }
                  missingComparedDataElement={
                    <Typography variant="h5" textAlign="center">
                      Container
                      <br /> does not exist
                    </Typography>
                  }
                  attributes={getAttributes(baseline, container)}
                  baseline={baseline}
                  compared={compared}
                />
              ) : null;
            })
          )}
        </StackedComparisonTable>
      )}
      {baselineServiceId !== null && seeAllModal !== null ? (
        <YamlViewer
          baselineUid={baselineServiceId.value}
          title={seeAllModal.title}
          content={seeAllModal.content}
          onClose={() => setSeeAllModal(null)}
        />
      ) : null}
      {baselineServiceId !== null && diffModal !== null ? (
        <DiffModal
          title={diffModal?.title}
          baselineUid={baselineServiceId?.value ?? ""}
          comparedUid={diffModal.comparedUid}
          oldObj={diffModal?.oldObj}
          newObj={diffModal?.newObj}
          onClose={() => setDiffModal(null)}
          isYaml={true}
        />
      ) : null}
    </>
  );
};
