import { createContext, PropsWithChildren, useCallback, useState } from "react";

import { User } from "@/generated/auth";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useGetUsers } from "@/shared/hooks/auth-service/client/users/useGetUsers";

const STALE_TIME = 1000 * 60 * 5;

export type AccountUsersContextState = {
  users: { [key: string]: User };
};

const initialState: AccountUsersContextState = {
  users: {},
};

export const AccountUsersContext =
  createContext<AccountUsersContextState>(initialState);

export const AccountUsersContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<AccountUsersContextState>({
    ...initialState,
  });

  const { accountId } = useUserMetadata();

  const onGetUsersSuccess = useCallback(
    (data: User[]) => {
      const newUsers = { ...state.users };
      data.forEach((user: User) => {
        if (!newUsers[user.id]) {
          newUsers[user.id] = user;
        }
      });
      setState(() => ({
        users: newUsers,
      }));
    },
    [state.users]
  );

  useGetUsers(
    { accountId },
    {
      enabled: true,
      refetchOnMount: "always",
      onSuccess: onGetUsersSuccess,
      refetchInterval: STALE_TIME,
      refetchIntervalInBackground: true,
    }
  );

  return (
    <AccountUsersContext.Provider value={{ ...state }}>
      {children}
    </AccountUsersContext.Provider>
  );
};
