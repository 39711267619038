import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { groupBy, sortBy } from "lodash";

import MonitorRuleDrawer from "../monitorsConfiguration/MonitorRuleDrawer";
import MonitorFilters from "../MonitorsFilters";
import Divider from "../common/Divider";
import { Spacer } from "../common/styles";
import FetchDataLoading from "../../common/loaders/LoadingState";
import { MONITORS_RULE_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { useActiveAgents } from "../../../shared/hooks/useAgents";

import ClusterMonitors from "./ClusterMonitors";
import DeleteRuleModal from "./DeleteRuleModal";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useGetMonitorsConfigurations } from "@/shared/hooks/monitors-api/client/monitors/useGetMonitorsConfigurations";
import { MonitorConfiguration, MonitorType } from "@/generated/monitorsApi";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";

const Container = styled.div`
  display: flex;
  margin-block-end: 10rem;
`;

const Clusters = styled.div`
  width: 100%;
`;

export const ManageMonitorsContent: React.FC = () => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  const [cluster, setCluster] = useState<string | undefined>();
  const [ruleType, setRuleType] = useState<string | undefined>();
  const activeAgents = useActiveAgents();

  const {
    data: configurations,
    refetch,
    isLoading,
    isFetching,
  } = useGetMonitorsConfigurations({ isDeleted: false });

  const configurationsOfActiveClusters = useMemo(
    () =>
      configurations?.filter((configuration) =>
        activeAgents?.some(
          (agent) => agent.clusterName === configuration.sensors?.[0]?.cluster
        )
      ),
    [configurations, activeAgents]
  );

  const filteredMonitorsByCluster = useMemo(
    () =>
      configurationsOfActiveClusters?.filter(
        (configuration) =>
          cluster === "" || configuration?.sensors?.[0].cluster === cluster
      ),
    [configurationsOfActiveClusters, cluster]
  );
  const filteredMonitorsByClusterAndType = useMemo(() => {
    return filteredMonitorsByCluster?.filter(
      (configuration) => ruleType === "" || configuration.type === ruleType
    );
  }, [filteredMonitorsByCluster, ruleType]);

  const monitorsByCluster = sortBy(
    Object.entries(
      groupBy(
        filteredMonitorsByClusterAndType,
        ({ sensors }) => sensors?.[0].cluster
      )
    ),
    ([cluster]) => cluster
  );

  const [isTypeStatic, setIsTypeStatic] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useStateInSearchParams(
    MONITORS_RULE_PARAM_KEY
  );
  const [selectedRule, setSelectedRule] = useState<MonitorConfiguration>();
  useEffect(() => {
    if (selectedRuleId && !selectedRule) {
      setSelectedRule(configurations?.find((c) => c?.id === selectedRuleId));
    }
  }, [configurations, selectedRule, selectedRuleId]);

  const handleEditRule = useCallback(
    (ruleId: string) => {
      setSelectedRuleId(ruleId);
    },
    [setSelectedRuleId]
  );

  const handleAddRule = useCallback(
    (rule: MonitorConfiguration | undefined, isTypeStatic?: boolean) => {
      setSelectedRule(rule);
      setIsTypeStatic(isTypeStatic ?? false);
    },
    []
  );

  const [ruleToDelete, setRuleToDelete] = useState<string | null>(null);
  const handleDeleteRule = useCallback((ruleId: string) => {
    setRuleToDelete(ruleId);
  }, []);

  useEffect(() => {
    reportLoadingState("monitors", isFetching);
  }, [reportLoadingState, isFetching]);

  const existingRuleTypes = useMemo(() => {
    const configs = filteredMonitorsByCluster
      ?.map((k) => k.type)
      .filter((type): type is MonitorType => type !== undefined);

    return (
      Array.from(new Set(configs)).sort((t1, t2) =>
        t1.toLowerCase().localeCompare(t2.toLowerCase())
      ) ?? []
    );
  }, [filteredMonitorsByCluster]);

  const existingClusters = useMemo(() => {
    const clusters = configurationsOfActiveClusters
      ?.map((k) => k.sensors?.[0].cluster)
      .filter((cluster): cluster is string => cluster !== undefined);

    return Array.from(new Set(clusters)).sort() ?? [];
  }, [configurationsOfActiveClusters]);

  const handleClose = useCallback(() => {
    setSelectedRuleId(null);
    setSelectedRule(undefined);
  }, [setSelectedRuleId]);

  return (
    <>
      <div>
        <MonitorFilters
          clusters={existingClusters}
          cluster={cluster}
          setCluster={setCluster}
          ruleTypes={existingRuleTypes}
          ruleType={ruleType}
          setRuleType={setRuleType}
        />
        <Divider />
        <Spacer />
        <Container>
          {isLoading ? (
            <FetchDataLoading dataName="monitor rules" />
          ) : (
            <Clusters>
              {monitorsByCluster.map(([cluster, monitors]) => (
                <ClusterMonitors
                  key={cluster}
                  clusterName={cluster}
                  monitors={monitors}
                  handleAddRule={handleAddRule}
                  handleEditRule={handleEditRule}
                  handleDeleteRule={handleDeleteRule}
                />
              ))}
            </Clusters>
          )}
        </Container>
      </div>
      <MonitorRuleDrawer
        isOpen={!!selectedRule}
        initRule={selectedRule}
        handleClose={handleClose}
        refreshMonitors={refetch}
        isTypeStatic={isTypeStatic || !!selectedRuleId}
      />
      <DeleteRuleModal
        ruleToDelete={ruleToDelete}
        handleClose={() => setRuleToDelete(null)}
        refreshMonitors={refetch}
      />
    </>
  );
};

export const ManageMonitors: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.realtimePoliciesPage,
        context: {
          beTeam: "troubleshooting",
          feTeam: "troubleshooting",
        },
      }}
    >
      <ManageMonitorsContent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
