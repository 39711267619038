import React, { useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";

import { UpgradeButtonAndModal } from "../../UpgradeAgentVersionPrompt/UpgradeButtonAndModal";

import { ScopeFilterOption } from "./types";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const DisabledMenuItem = styled(MenuItem)`
  && {
    padding: 4px 0;
    overflow: hidden;
    cursor: default;
    width: fit-content;
    display: flex;
    max-width: 100%;
    &:hover {
      background-color: unset;
    }
  }
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const DisabledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 2px;
  background-color: ${muiColors.gray[100]};
  margin: 6px;
  flex-shrink: 0;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const DisabledTypography = styled(Typography).attrs({
  variant: "body3",
  noWrap: true,
})`
  && {
    opacity: 0.4;
  }
`;

export const useDisabledOptions = (options: ScopeFilterOption[]) =>
  useCallback(() => {
    return options
      .filter((option) => option.option.disabled)
      .map((disabledOption) => {
        return (
          <SingleOption
            key={disabledOption.option.label}
            disabledOption={disabledOption}
          />
        );
      });
  }, [options]);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const SingleOption: React.FC<{
  disabledOption: ScopeFilterOption;
}> = ({ disabledOption }) => {
  const { option, isClusterNew } = disabledOption;
  return (
    <Tooltip
      title={option.disabledTooltipContent}
      placement={"top"}
      key={option.value}
    >
      <DisabledMenuItem key={option.value} value={option.value}>
        <DisabledCheckbox />
        <DisabledTypography>{option.label}</DisabledTypography>
        {!isClusterNew && (
          <UpgradeButtonAndModal
            unsupportedClusters={[option.label]}
            buttonVariant="text"
          />
        )}
      </DisabledMenuItem>
    </Tooltip>
  );
};
