import React, { useMemo } from "react";
import styled from "styled-components";
import { Typography } from "@komodorio/design-system/deprecated";
import { muiColors } from "@komodorio/design-system";

import Select from "../../../common/controls/Select";
import { WorkflowConfigType } from "../../common/types";
import { pvcDefaultRule } from "../PVCRuleSections";
import { nodeDefaultRule } from "../NodeRuleSections";
import { availabilityDefaultRule } from "../AvailabilityRuleSections";
import { jobDefaultRule } from "../JobRuleSections";
import { cronJobDefaultRule } from "../CronJobRuleSections";
import { useFormValidations } from "../../../../shared/context/ValidationsProvider";
import { deployDefaultRule } from "../DeployRuleSections";
import AvailableWorkflows, {
  useWorkflowsFlags,
} from "../../common/availableWorkflows";
import { InfoLink } from "../../../common/InfoLink";
import { workflowDefaultRule } from "../WorkflowRuleSections";

import { MonitorConfiguration, MonitorType } from "@/generated/monitorsApi";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const WorkflowMonitorRuleDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]};
`;

const Type = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const defaultRuleByType = new Map([
  [MonitorType.Pvc, pvcDefaultRule],
  [MonitorType.Node, nodeDefaultRule],
  [MonitorType.Availability, availabilityDefaultRule],
  [MonitorType.Job, jobDefaultRule],
  [MonitorType.CronJob, cronJobDefaultRule],
  [MonitorType.Deploy, deployDefaultRule],
  [MonitorType.Workflow, workflowDefaultRule],
]);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getDefaultRule = (
  cluster: string,
  type?: MonitorType
): MonitorConfiguration | undefined => {
  const values = defaultRuleByType.get(type ?? WorkflowConfigType.Availability);
  if (values) {
    return {
      ...values,
      sensors: [{ ...values.sensors?.[0], cluster }],
    };
  }
  return;
};

const ruleTypeOptions = {
  [WorkflowConfigType.Availability]: "Availability",
  [WorkflowConfigType.NodeIssue]: "Node",
  [WorkflowConfigType.Deploy]: "Deploy",
  [WorkflowConfigType.PVC]: "PVC",
  [WorkflowConfigType.Job]: "Job",
  [WorkflowConfigType.CronJob]: "CronJob",
  [WorkflowConfigType.Workflow]: "Workflow Pod",
};
const options = Object.entries(ruleTypeOptions).map(([value, label]) => ({
  label,
  value,
}));

const RuleTypeSection: React.FC<{
  currentCluster: string;
  currentType: MonitorType;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
  isStatic?: boolean;
}> = ({ currentCluster, currentType, setRule, isStatic = false }) => {
  const { clearErrors } = useFormValidations();

  const handleRuleTypeChange = (type: MonitorType) => {
    const defaultRule = getDefaultRule(currentCluster, type);
    if (defaultRule) {
      setRule(defaultRule);
      clearErrors();
    }
  };
  const wfFlags = useWorkflowsFlags();

  const filteredOptions = useMemo(
    () => options.filter((o) => wfFlags[o.value]),
    [wfFlags]
  );

  const workflow = AvailableWorkflows[currentType];
  return (
    <>
      <Container>
        {isStatic ? (
          <Type>{ruleTypeOptions[currentType]}</Type>
        ) : (
          <>
            <Select
              onChange={(e) =>
                handleRuleTypeChange(e.target.value as WorkflowConfigType)
              }
              value={currentType}
              disabled={filteredOptions.length === 1}
            >
              {filteredOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </>
        )}
        <InfoLink url={workflow.docsLink} text={"What does this mean?"} />
      </Container>
      {currentType === WorkflowConfigType.Workflow && (
        <>
          <br />
          <WorkflowMonitorRuleDescription>
            <Typography bold>{workflow.shortDescription}</Typography>
            <Typography>{workflow.longDescription}</Typography>
          </WorkflowMonitorRuleDescription>
        </>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RuleTypeSection;
