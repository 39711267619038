import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";

import {
  APIKey,
  ApiKeysApiApiV1ApiKeysGetRequest,
  apiV1ApiKeysGetUrl,
} from "@/generated/auth";

export const API_KEYS_PATH = "/api-keys";

const fetchApiKeys = async (
  apiClient: AxiosInstance,
  params: ApiKeysApiApiV1ApiKeysGetRequest = {}
): Promise<APIKey[]> => {
  const { data } = await apiClient.get(
    apiV1ApiKeysGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetApiKeys = (
  params?: ApiKeysApiApiV1ApiKeysGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [API_KEYS_PATH, params],
    () => fetchApiKeys(apiClient, params),
    {
      enabled,
    }
  );
};
