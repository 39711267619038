import React from "react";
import { PodDisruptionBudget } from "kubernetes-types/policy/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { PSP } from "@komodorio/design-system/icons";

import { Header } from "../common/Header/Header";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class PDB implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [{ label: ResourceTabName.Yaml }];
  readonly actions = [AvailableActions.Describe];
  readonly fullObj;
  readonly defaultTab;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(cluster: string, pdb: PodDisruptionBudget, agentId: string) {
    this.agentId = agentId;
    this.id = pdb.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = pdb.metadata?.namespace ?? "";
    this.name = pdb.metadata?.name ?? "";
    this.kind = pdb.kind ?? "";
    this.labels = pdb.metadata?.labels ?? {};
    this.annotations = pdb.metadata?.annotations ?? {};
    this.icon = PSP;
    this.fullObj = pdb;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
