import { useMemo } from "react";

import { KubernetesServiceTypeEnum } from "../../../../shared/types/ServiceVertex";
import {
  buildKomodorUid,
  buildKomodorUidFromResource,
} from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { PodStateRequestFieldsEnum } from "../../../../generated/resourcesApi";
import { getJobControllerUid } from "../../../../shared/utils/jobUtils";
import { useGetPodsState } from "../../../../shared/hooks/resources-api/client/useGetPodsState";
import Resource from "../../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Rollout from "../../resources/rollout";
import Job from "../../resources/job";
import { getLabelSelector } from "../../../../shared/utils/podUtils";

import WorkflowRun from "@/components/ResourceView/resources/workflowRun";

export const useResourcePodsState = (resource: Resource) => {
  const [params, enable] = useMemo(() => {
    if (resource instanceof Rollout) {
      /*
        KubernetesServiceTypeEnum.Rollout is an ugly fix for a bug in brain, which in case when pod's owner is replicaset,
        always maps it to Deployment: https://app.clickup.com/t/86bx47fta
      */
      const komodorUid = buildKomodorUid({
        clusterName: resource.cluster,
        namespace: resource.namespace,
        resourceName: resource.name,
        kind: KubernetesServiceTypeEnum.Deployment,
      });
      return [
        {
          fields: [PodStateRequestFieldsEnum.Json],
          rootOwnerKomodorUid: komodorUid,
        },
        !!komodorUid,
      ];
    } else if (resource instanceof Job) {
      const labelSelector = getLabelSelector(resource.selector);
      const controllerUid = getJobControllerUid(resource.selector);
      return [
        {
          clusterName: resource.cluster,
          fields: [PodStateRequestFieldsEnum.Json],
          resourceOwnerUid: controllerUid,
          labelSelector,
        },
        !!controllerUid && !!labelSelector,
      ];
    } else if (resource instanceof WorkflowRun) {
      const labelSelector = getLabelSelector(resource.selector);
      return [
        {
          clusterName: resource.cluster,
          fields: [PodStateRequestFieldsEnum.Json],
          labelSelector,
        },
        !!labelSelector,
      ];
    } else {
      const komodorUid = buildKomodorUidFromResource(resource) ?? "";
      return [
        {
          fields: [PodStateRequestFieldsEnum.Json],
          rootOwnerKomodorUid: komodorUid,
        },
        !!komodorUid,
      ];
    }
  }, [resource]);

  return useGetPodsState(params, enable);
};
