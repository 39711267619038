import React, { useEffect, useRef } from "react";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useFilterListFromUrlAsGenericFilters } from "@/components/k8sAddons/hooks/filters/useFilterListFromUrlAsGenericFilters";
import { GenericFilter } from "@/generated/addonsApi";

export const LoadTimeReportingHandler: React.FC = () => {
  const { triggerStartView, resetLoadingState } =
    useDatadogReportLoadingTimeContext();
  const filtersInUrl = useFilterListFromUrlAsGenericFilters();
  const lastFiltersInUrl = useRef<GenericFilter[] | undefined>(undefined);

  useEffect(() => {
    if (lastFiltersInUrl.current !== filtersInUrl) {
      if (lastFiltersInUrl.current?.length === 0) {
        triggerStartView(true);
      } else {
        resetLoadingState();
      }

      lastFiltersInUrl.current = filtersInUrl;
    }
  }, [filtersInUrl, resetLoadingState, triggerStartView]);

  return null;
};
