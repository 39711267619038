export enum NodeStatuses {
  ready = "Ready",
  notReady = "NotReady",
  diskPressure = "DiskPressure",
  memoryPressure = "MemoryPressure",
}

class NodeStatus {
  private ready: boolean;
  private diskPressure: boolean;
  private memoryPressure: boolean;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(
    ready: boolean | string,
    diskPressure: boolean | string,
    memoryPressure: boolean | string
  ) {
    this.ready = typeof ready === "boolean" ? ready : ready === "True";
    this.diskPressure =
      typeof diskPressure === "boolean"
        ? diskPressure
        : diskPressure === "True";
    this.memoryPressure =
      typeof memoryPressure === "boolean"
        ? memoryPressure
        : memoryPressure === "True";
  }

  get(): NodeStatuses {
    if (!this.ready) {
      return NodeStatuses.notReady;
    } else if (this.diskPressure) {
      return NodeStatuses.diskPressure;
    } else if (this.memoryPressure) {
      return NodeStatuses.memoryPressure;
    } else {
      return NodeStatuses.ready;
    }
  }
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeStatus;
