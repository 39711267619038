import React, { useEffect, useMemo, useState } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { WorkloadMetricsTabContextProvider } from "../../context/WorkloadMetricsTabContext";
import { useWorkloadMetricsTabContext } from "../../context/useWorkloadMetricsTabContext";
import { GraphContainerWithStatus } from "../../../../../Metrics/GraphContainerWithStatus";
import { ResourceGraph } from "../../../../../Metrics/graphs/ResourceGraph";
import { NoLimitPerOneContainerLabel } from "../../../../../Metrics/NoLimitLabel";
import { WorkloadGraphProps } from "../../types";
import { useWorkloadContainerGraph } from "../../hooks/useWorkloadContainerGraph";

import { EnlargedContainerGraph } from "./EnlargedContainerGraph";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type ContainerWorkloadMemoryGraphProps = WorkloadGraphProps & {
  wantedContainer: MuiSelectionOption<string> | undefined;
};

export const WorkloadContainerGraph: React.FC<
  ContainerWorkloadMemoryGraphProps
> = ({
  komodorUidParams,
  loadingStateId,
  wantedContainer,
  type,
  metricGraphProps,
}) => {
  const [isGraphEnlarged, setIsGraphEnlarged] = useState(false);
  const { initialLoadTime, selectedAggregationMetric, timeWindow } =
    useWorkloadMetricsTabContext();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { graphProps, uid, error, loading, hasGraphData, toEpochMs, hasLimit } =
    useWorkloadContainerGraph({
      komodorUidParams,
      type,
      wantedContainer,
      endTime: initialLoadTime,
    });

  useEffect(() => {
    reportLoadingState(loadingStateId, loading);
  }, [loading, loadingStateId, reportLoadingState]);

  const title = type === "cpu" ? "Cpu Usage" : "Memory Usage";
  const mergedProps = useMemo(() => {
    return { ...graphProps, ...metricGraphProps };
  }, [graphProps, metricGraphProps]);

  return (
    <>
      {isGraphEnlarged && (
        <WorkloadMetricsTabContextProvider
          givenTimeWindow={timeWindow}
          givenAggregationMetric={selectedAggregationMetric}
        >
          <EnlargedContainerGraph
            initialWantedContainer={wantedContainer}
            komodorUidParams={komodorUidParams}
            endTime={toEpochMs}
            onClose={() => setIsGraphEnlarged(false)}
            type={type}
          />
        </WorkloadMetricsTabContextProvider>
      )}
      <GraphContainerWithStatus
        uid={uid}
        error={error}
        loading={loading}
        hasGraphData={hasGraphData}
        title={title}
        info={
          !loading && !error && !hasLimit ? (
            <NoLimitPerOneContainerLabel />
          ) : null
        }
        onEnlargeGraph={() => setIsGraphEnlarged(true)}
      >
        <ResourceGraph type={type} {...mergedProps} />
      </GraphContainerWithStatus>
    </>
  );
};
