export const aOrAn = (word: string): string => {
  const firstLetter = word.charAt(0);
  const aOrAn = ["a", "e", "i", "o", "u"].includes(firstLetter) ? "an" : "a";
  return `${aOrAn} ${word}`;
};

export const aOrAnForInvestigationErrors = (word: string): string => {
  const firstLetter = word.charAt(0).toLowerCase();
  const aOrAn = ["a", "e", "i", "o", "u"].includes(firstLetter)
    ? word.toLowerCase().startsWith("oom")
      ? "a"
      : "an"
    : "a";
  return `${aOrAn} ${word}`;
};

export const shortenString = (
  str: string,
  numCharsBeforeDiv: number,
  numCharsAfterDiv: number,
  div = "..."
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): string => {
  if (str.length <= numCharsBeforeDiv + numCharsAfterDiv) {
    return str;
  } else {
    const firstChars = str.slice(0, numCharsBeforeDiv);
    const lastChars = str.slice(-numCharsAfterDiv);
    return `${firstChars}${div}${lastChars}`;
  }
};

export const getPluralEnabledMessage = (
  count: number,
  suffix: string,
  locale = "en"
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): string => {
  const pluralRules = new Intl.PluralRules(locale);

  const msg: { [key: string]: string } = {
    one: `${count} ${suffix}`,
    other: `${count} ${suffix}s`,
  };

  const selected = pluralRules.select(count);
  return msg[selected];
};
