import React, { useEffect } from "react";

import ResourceListTable from "../../../../../../common/ResourceListTable/ResourceListTable";
import { SelfSupplyingResourceListTableProps } from "../types";
import { useHpasByServiceId } from "../graphql";
import { filterResourceTableBySearchTerm } from "../../../../../../Inspection/utils/filterResultsList";

import {
  getUnselectableRowNames,
  onlySelected,
} from "./utils/resourceListTablesUtils";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type HpasListTableProps = SelfSupplyingResourceListTableProps;

export const HpasListTable: React.FC<HpasListTableProps> = ({
  serviceId,
  cluster,
  rowSelection,
  eventsDetectionTimeframe,
  searchTerm,
  showOnlySelectedRows = false,
  ...props
}) => {
  const {
    rows = [],
    refresh = () => Function.prototype(),
    isLoading,
    errorMessage,
  } = useHpasByServiceId(serviceId, cluster);

  const filteredRows = filterResourceTableBySearchTerm(rows, searchTerm);
  const unselectableRowNames = getUnselectableRowNames(rows, rowSelection);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () => reportLoadingState("relatedResourcesHpasData", !!isLoading),
    [isLoading, reportLoadingState]
  );
  return (
    <ResourceListTable
      cluster={cluster}
      rows={
        showOnlySelectedRows
          ? onlySelected(filteredRows, rowSelection?.selectedRowNames ?? [])
          : filteredRows
      }
      refreshResults={refresh}
      rowSelection={
        rowSelection ? { ...rowSelection, unselectableRowNames } : undefined
      }
      overridingColumns={overridingColumns}
      loadingProps={{ isLoading }}
      errorMessage={errorMessage}
      {...props}
    />
  );
};

const overridingColumns = [
  {
    name: "name",
    clickable: false,
  },
  {
    name: "maxReplicas",
    clickable: false,
  },
  {
    name: "minReplicas",
    clickable: false,
  },
  {
    name: "replicas",
    clickable: false,
  },
];
