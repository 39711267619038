import { useQuery } from "@tanstack/react-query";

import {
  COST_RIGHT_SIZING_ROWS_ENDPOINT,
  EndpointRequestPathMap,
} from "../requestResponseMaps";
import {
  CostApiApiV1CostRightSizingRowsGetRequest,
  RightSizingCostRowsResponse,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

type UseGetRightSizingRowsParams = {
  params: CostApiApiV1CostRightSizingRowsGetRequest;
  pageIndex: number;
  enabled?: boolean;
};

export const useGetRightSizingRows = ({
  params,
  pageIndex,
  enabled,
}: UseGetRightSizingRowsParams) => {
  const apiClient = useMetricsApiClient();
  let url = "";
  try {
    url = EndpointRequestPathMap[COST_RIGHT_SIZING_ROWS_ENDPOINT](
      params,
      apiClient.defaults.baseURL ?? ""
    );
  } catch (err) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useQuery(
    [COST_RIGHT_SIZING_ROWS_ENDPOINT, params, pageIndex],
    async () => await apiClient.get<RightSizingCostRowsResponse>(url),
    { enabled: enabled && !!url }
  );
};
