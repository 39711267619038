import React, { useEffect } from "react";

import { SelfSupplyingResourceListTableProps } from "../types";
import ResourceListTable from "../../../../../../common/ResourceListTable/ResourceListTable";
import { useNamesExtractionFromService } from "../../../../../../../shared/hooks/useNamesExtractionFromService";
import { generateUid } from "../../../../../../../shared/utils/generateUid";
import { PvcNamesExtractor } from "../../../../../../../shared/utils/ExtractNamesFromService/PvcNamesExtractor";
import { filterResourceTableBySearchTerm } from "../../../../../../Inspection/utils/filterResultsList";

import {
  getUnselectableRowNames,
  onlySelected,
} from "./utils/resourceListTablesUtils";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const PvcsListTable: React.FC<SelfSupplyingResourceListTableProps> = ({
  serviceId,
  namespace,
  cluster,
  searchTerm,
  rowSelection,
  showOnlySelectedRows,
  ...props
}) => {
  const [pvcNames, refreshPvcNames, isLoading] = useNamesExtractionFromService(
    serviceId,
    new PvcNamesExtractor()
  );
  const pvcs = pvcNames.map((pvcName) => ({
    uid: generateUid({ name: pvcName, namespace: namespace ?? "", cluster }),
    name: pvcName,
  }));

  const filteredPvcs = filterResourceTableBySearchTerm(pvcs, searchTerm);

  const unselectableRowNames = getUnselectableRowNames(pvcs, rowSelection);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () => reportLoadingState("relatedResourcesPvcsData", !!isLoading),
    [isLoading, reportLoadingState]
  );

  return (
    <ResourceListTable
      rows={
        showOnlySelectedRows
          ? onlySelected(filteredPvcs, rowSelection?.selectedRowNames ?? [])
          : filteredPvcs
      }
      cluster={cluster}
      refreshResults={refreshPvcNames}
      overridingColumns={overridingColumns}
      rowSelection={
        rowSelection ? { ...rowSelection, unselectableRowNames } : undefined
      }
      {...props}
    />
  );
};

const overridingColumns = [
  {
    name: "name",
    clickable: false,
  },
];
