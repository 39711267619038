import { ResourceTableModelRow } from "komodor-types";
import { useMemo } from "react";

import { ResourceListResponse } from "../../../components/Inspection/InspectionViews/common";

import { Status } from "./useAtmRequest";

export const useParseResultToInspectionList = (
  status: Status,
  errorMessage: string,
  data?: {
    [x: string]: string;
  }[],
  isPaginating?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ResourceListResponse => {
  return useMemo(() => {
    const rows = data ?? [];
    const isPaginatingWithDefault = isPaginating ?? true;
    const result: ResourceListResponse = {
      fetching:
        status === Status.Initializing ||
        (isPaginatingWithDefault && data?.length === 0),
      emptyResult:
        status !== Status.Initializing &&
        !isPaginatingWithDefault &&
        data?.length === 0,
      isPaginating: isPaginating,
      errorMessage: errorMessage,
      rows: rows,
    };

    return result;
  }, [data, errorMessage, isPaginating, status]);
};

type ResourceResponse = {
  data: ResourceTableModelRow[];
  fetching: boolean;
  isPaginating: boolean;
};
export const useParseAtmResult = (
  status: Status,
  data?: {
    [x: string]: string;
  }[],
  isPaginating?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ResourceResponse => {
  return useMemo(() => {
    return {
      data: data ?? [],
      fetching:
        status === Status.Initializing ||
        (!!isPaginating && data?.length === 0),
      isPaginating: isPaginating ?? false,
    };
  }, [data, isPaginating, status]);
};
