import { useMemo } from "react";

import { useFilterListFromUrlAsGenericFilters } from "./useFilterListFromUrlAsGenericFilters";

export const useGetFilterValues = (filterCategory: string) => {
  const filtersList = useFilterListFromUrlAsGenericFilters();

  return useMemo(
    () => filtersList.find((filter) => filter.name === filterCategory)?.value,
    [filterCategory, filtersList]
  );
};
