import React from "react";

import { useDatadogReportLoadingTimeContextProps } from "@/components/k8sAddons/hooks/useDatadogReportingProps";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { LoadTimeReportingHandler } from "@/components/k8sAddons/components/DatadogReportingContainer/LoadTimeReportingHandler";

export const DatadogReportingContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      {...useDatadogReportLoadingTimeContextProps()}
    >
      {children}
      <LoadTimeReportingHandler />
    </DatadogReportLoadingTimeContextProvider>
  );
};
