import React from "react";

import { columnsConfig } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/hooks/nodeAutoscalerTableHooks";
import { PageFiltersContainer } from "@/components/k8sAddons/styles";
import { AddonFilterHandler } from "@/components/k8sAddons/components/AddonFilterHandler/AddonFilterHandler";
import { sharedFilterProps } from "@/components/k8sAddons/constants/filters";

export const Filters: React.FC = () => {
  return (
    <PageFiltersContainer>
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.cluster.field}
        label={"Cluster"}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.autoscalerType.field}
        label={"Type"}
      />
    </PageFiltersContainer>
  );
};
