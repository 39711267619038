import React, { ReactElement, useState } from "react";
import styled, { css } from "styled-components";

import { darkBlueBrand, gray13, blackGreyText } from "../../../../Colors";
import { useEscape } from "../../../../shared/hooks/useEscape";
import Overlay from "../../Overlay";

import dropdownArrow from "./dropdownArrow";

export const Container = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width ?? "100%"};
  .noBackground,
  .workspaceView {
    width: revert;
  }
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const panelStyle = css`
  color: #3d4048;
  background: #ffffff;
  border: 1px solid #bcc0c8;
  border-radius: 0.25rem;
  font-size: 0.75rem;
`;

export const Bar = styled.button`
  ${panelStyle};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${dropdownArrow}
  &.noBackground {
    background-color: transparent;
    border: none;
    justify-content: left;
    cursor: pointer;
    text-transform: lowercase;
    :before {
      content: "From the ";
    }
    :hover:not(.disabled) {
      text-decoration: underline;
    }
  }
  &.disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
`;

export const Text = styled.div`
  padding: 0.44rem 0.1rem;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const List = styled.div`
  min-width: 100%;
  position: absolute;
  z-index: 20;
  transform: translateY(0.25rem);
  ${panelStyle};
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.4);
  .workspaceView {
    min-width: revert;
  }
`;

export const ListContent = styled.div`
  display: flex;
`;

const CustomContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

export const Options = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Option = styled.button`
  outline: none;
  border: none;
  :nth-child(n + 2) {
    border-top: 1px solid #ced5e8;
  }
  background-color: transparent;
  :hover {
    background-color: rgba(0, 122, 255, 0.3);
  }
  font-size: inherit;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${darkBlueBrand};
  & + ${Option} {
    border-top: none;
  }
`;

export const OptionContent = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  max-width: ${({ width }) => (width ? `calc(${width} - 1rem)` : "none")};
`;

export const Placeholder = styled.label`
  color: rgba(110, 122, 150, 0.6);
`;

export const Empty = styled.div`
  text-align: center;
  padding: 0.5rem;
`;

export type OptionType<T> = { label: string; value: T };

export interface SingleSmartSelectProps<T> {
  options: OptionType<T>[];
  placeholder: string;
  label?: string;
  value?: OptionType<T>;
  width?: string;
  enrichLabel?: (option: OptionType<T>) => string;
  enrichLabelElement?: (option: OptionType<T>) => JSX.Element;
  children?(close: () => void): ReactElement;
  onChange: (value: OptionType<T>, preventClose: () => void) => void;
  className?: string;
  e2eSelector?: string;
  disabled?: boolean;
}

const Label = styled.div`
  background-color: ${gray13};
  font-weight: bold;
  color: ${blackGreyText};
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  margin-right: 0.25em;
`;

export function SingleSmartSelect<T>({
  options,
  value,
  placeholder,
  label,
  width,
  enrichLabel,
  enrichLabelElement,
  children,
  onChange,
  className,
  e2eSelector,
  disabled = false,
}: SingleSmartSelectProps<T>): ReactElement | null {
  const [open, setOpen] = useState(false);

  useEscape(() => setOpen(false));

  const handleOptionClick = (option: OptionType<T>) => {
    let closePrevented = false;
    Promise.resolve()
      .then(() =>
        onChange(option, () => {
          closePrevented = true;
        })
      )
      .then(() => {
        if (!closePrevented) {
          setOpen(false);
        }
      });
  };

  const Content = children ? CustomContent : ListContent;

  return (
    <Container width={width}>
      <Bar
        disabled={disabled}
        onClick={() => setOpen(true)}
        className={className}
        data-e2e-selector={e2eSelector}
        aria-label={e2eSelector}
      >
        {label && <Label>{label}</Label>}
        <Text>
          {value?.label || <Placeholder>{placeholder}</Placeholder>}
          {value?.label && enrichLabelElement && (
            <>{enrichLabelElement(value)}</>
          )}
        </Text>
      </Bar>
      {open && (
        <>
          <Overlay open onClick={() => setOpen(false)} />
          <List className={className}>
            <Content>
              <Options>
                {options.length ? (
                  options.map((o, i) => (
                    <Option
                      key={i}
                      onClick={() => handleOptionClick(o)}
                      data-e2e-selector={`${e2eSelector}-option`}
                    >
                      <OptionContent width={width}>
                        <Text>
                          {o.label}
                          {enrichLabelElement && <>{enrichLabelElement(o)}</>}
                        </Text>
                        {enrichLabel && <div>{enrichLabel(o)}</div>}
                      </OptionContent>
                    </Option>
                  ))
                ) : (
                  <Empty>
                    <Placeholder>No options</Placeholder>
                  </Empty>
                )}
              </Options>
              {children && children(() => setOpen(false))}
            </Content>
          </List>
        </>
      )}
    </Container>
  );
}
