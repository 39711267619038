import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
`;

export type ErrorTableResultsProps = {
  message: string;
  onRetry?: () => void;
};

export const ErrorTableResults: React.FC<ErrorTableResultsProps> = ({
  message,
  onRetry,
}) => {
  return (
    <Container>
      <ErrorOutline />
      <Typography variant={"body2"} color={"text.primary"}>
        {message}
      </Typography>
      {onRetry && (
        <Button variant={"contained"} onClick={onRetry}>
          Retry
        </Button>
      )}
    </Container>
  );
};
