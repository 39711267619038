import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import styled from "styled-components";

interface Props {
  value: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const SelectionTooltipLabel: React.FC<Props> = ({ value }) => {
  const contents = useMemo(() => {
    const splits = value.split("|").slice(1);
    const selectionName = splits.at(-1) ?? "";

    const path = splits.slice(0, -1).join("/");

    return { path, selectionName };
  }, [value]);

  return (
    <Container>
      <Typography fontSize={12} fontWeight={400} color="text.secondary">
        {contents.path + "/"}
      </Typography>

      <Typography fontSize={12} fontWeight={400} color="text.primary">
        {contents.selectionName}
      </Typography>
    </Container>
  );
};

export { SelectionTooltipLabel };
