import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "@komodorio/design-system/deprecated";

import { useQueryRefreshWithVariables } from "../../../../shared/hooks/useQueryWithVariables";
import { useFetchServiceDescribeQuery } from "../../../../generated/graphql";
import { useAgentInfoById } from "../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { doesAgentVersionSupportServiceDescribe } from "../../../../shared/utils/agent/agent";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import useAgentTaskExecution from "../../../../shared/hooks/useAgentTaskExecution/useAgentTaskExecution";

import { DescribeModalProps } from "./DescribeModal.types";
import { DescribeContent } from "./DescribeModalLazyContent";

const TASK_TYPE = "service-describe";
const MAX_RETRIES = 5;

/**
 * logical part - loaded eagerly. Render part - loaded lazily
 */
export const DescribeModalContainer: React.FC<DescribeModalProps> = ({
  isOpen,
  handleClose,
  resourceId,
  resourceName,
  resourceType,
  namespace,
  agentId,
  cluster,
}) => {
  const { servicedescribeRetries } = useOverridableFlags();
  const maxRetries = useMemo(
    () => (servicedescribeRetries as number) || MAX_RETRIES,
    [servicedescribeRetries]
  );

  const { agentProperties: agentInfo } = useAgentInfoById(agentId);

  const [retries, setRetries] = useState(0);
  const isAgentAbleToSendTasks =
    doesAgentVersionSupportServiceDescribe(agentInfo);

  const executeTaskVars = useMemo(() => {
    if (!agentId || !resourceType || !isAgentAbleToSendTasks) {
      return null;
    }
    return {
      agentId: agentId,
      type: TASK_TYPE,
      data: {
        namespace: namespace,
        serviceName: resourceName,
        serviceType: resourceType.toLowerCase(),
      },
    };
  }, [agentId, isAgentAbleToSendTasks, namespace, resourceName, resourceType]);
  const [
    fetchPayloadVars,
    failureMessage,
    executeTaskCallback,
    setTaskTimeoutCallback,
  ] = useAgentTaskExecution(executeTaskVars);

  const closeModalCallback = useCallback(() => {
    handleClose();
    setRetries(0);
  }, [handleClose]);

  const [serviceDescribeRes, refresh] = useQueryRefreshWithVariables(
    useFetchServiceDescribeQuery,
    fetchPayloadVars
  );

  const isDoneFetching =
    Number(serviceDescribeRes?.service_description?.length) > 0;

  const refreshCallback = useCallback(() => {
    dispatchEvent(AnalyticEvents.ServiceView.ServiceDescribe_Refresh, {
      serviceId: resourceId,
    });
    executeTaskCallback();
    setRetries(0);
  }, [executeTaskCallback, resourceId]);

  useEffect(() => {
    if (failureMessage || isDoneFetching || !isOpen) return;
    if (retries > maxRetries) {
      setTaskTimeoutCallback();
      return;
    }
    const timeout = setTimeout(() => {
      if (!serviceDescribeRes) {
        refresh();
      }
      setRetries(retries + 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    failureMessage,
    isOpen,
    maxRetries,
    refresh,
    retries,
    serviceDescribeRes,
    setTaskTimeoutCallback,
    isDoneFetching,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModalCallback}
      width="50%"
      height="80%"
    >
      <DescribeContent
        refreshCallback={refreshCallback}
        isAgentAbleToSendTasks={isAgentAbleToSendTasks}
        closeModalCallback={closeModalCallback}
        serviceDescribeRes={serviceDescribeRes}
        retries={retries}
        maxRetries={maxRetries}
        failureMessage={failureMessage}
        isDoneFetching={isDoneFetching}
        agentId={agentId}
        resourceId={resourceId}
        resourceName={resourceName}
        resourceType={resourceType}
        namespace={namespace}
        cluster={cluster}
        lastDeployEndDate={undefined}
      />
    </Modal>
  );
};
