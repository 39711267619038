/**
 * util for new Date()
 * to avoid new Date() will return different time
 *
 */
export const nowTime = new Date();

export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
export const ONE_MINUTE_IN_MS = 60 * 1000;
export const ONE_HOUR_IN_MS = 60 * 60 * 1000;
export const ONE_DAY_IN_MINUTES = 24 * 60;
export const ONE_WEEK_IN_MS = 7 * ONE_DAY_IN_MS;
