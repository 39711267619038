import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useIsDemoService } from "@/components/InvestigationMode/StepsLogic";

export const useShowLogsAiBanner = ({ log }: { log: string }) => {
  const { demoLogsUi, klaudiaAi } = useOverridableFlags();
  const isDemoService = useIsDemoService();

  if (!klaudiaAi || log === "") {
    return false;
  }

  return !(demoLogsUi && isDemoService);
};
