import React, { useEffect, useMemo } from "react";
import { muiColors, theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import {
  correlatedMetricsSelector,
  isMetricsSupportedSelector,
  issueSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { Metrics } from "../../../Metrics/Metrics";
import {
  useGetDeployMetricsEvents,
  useIssueMetricsEndTime,
} from "../useCorrelatedMetrics";
import { MetricsGraphType } from "../../../Metrics/types";
import { useMetricsDefaultAggregationType } from "../../metricsHooks";
import { useScrollTracking } from "../common/useScrollTracking";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";

import { CorrelatedDeploysOutput, RelatedServicesDeploysOutput } from "./types";
import { useDeploysById } from "./useDeploysById";
import { CorrelatedDeploysContent } from "./CorrelatedDeploysContent";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const CorrelatedDeploysComponent: React.FC = () => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { relatedServicesDeploysUi } = useOverridableFlags();
  const issue = useInvestigationModeStore(issueSelector);
  const isMetricsSupported = useInvestigationModeStore(
    isMetricsSupportedSelector
  );

  const metricsAggType = useMetricsDefaultAggregationType();
  const podMetrics = useInvestigationModeStore(correlatedMetricsSelector);
  const { nextStep, goToNextStep } = useNextStep();
  const correlatedDeploys: CorrelatedDeploysOutput = issue?.results
    .correlatedDeploys.output ?? {
    correlatedDeploys: [],
  };
  const [deploys, isFetchingDeploys] = useDeploysById(
    correlatedDeploys?.correlatedDeploys?.map((deploy) => deploy.id)
  );

  const emptyRelatedServicesDeploys: RelatedServicesDeploysOutput = {
    downstreamRelatedDeploys: [],
    upstreamRelatedDeploys: [],
  };

  const relatedServicesDeploys: RelatedServicesDeploysOutput =
    relatedServicesDeploysUi
      ? issue?.results?.relatedServicesDeploys?.output ??
        emptyRelatedServicesDeploys
      : emptyRelatedServicesDeploys;

  const upstreamRelatedDeployIds = useMemo(
    () =>
      relatedServicesDeploys?.upstreamRelatedDeploys?.map(
        (deploy) => deploy.id
      ),
    [relatedServicesDeploys]
  );

  const downstreamRelatedDeployIds = useMemo(
    () =>
      relatedServicesDeploys?.downstreamRelatedDeploys?.map(
        (deploy) => deploy.id
      ),
    [relatedServicesDeploys]
  );

  const [upstreamRelatedDeploys, isFetchingUpstreamRelatedDeploys] =
    useDeploysById(upstreamRelatedDeployIds?.filter((id) => !!id) ?? []);

  const [downstreamRelatedDeploys, isFetchingDownstreamRelatedDeploys] =
    useDeploysById(downstreamRelatedDeployIds?.filter((id) => !!id) ?? []);

  const events = useGetDeployMetricsEvents(issue, deploys);

  const metricsEndTime = useIssueMetricsEndTime(issue);

  const subtitleMessage = useMemo(() => {
    return deploys.length ? `${deploys.length} correlated deploys found` : "";
  }, [deploys]);

  const elementRef = useScrollTracking<HTMLDivElement>();

  const isLoading = !(
    !!issue &&
    !!podMetrics &&
    !isFetchingDeploys &&
    !isFetchingUpstreamRelatedDeploys &&
    !isFetchingDownstreamRelatedDeploys
  );

  useEffect(() => {
    reportLoadingState("correlatedDeployData", isLoading);
  }, [reportLoadingState, isLoading]);

  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          Verify these correlated deploys
        </Typography>
        <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
          {subtitleMessage}
        </Typography>
      </StepHeader>
      <StepContent ref={elementRef}>
        {podMetrics &&
          (correlatedDeploys?.hasMetrics || !isMetricsSupported) && (
            <Metrics
              isMetricsSupported={isMetricsSupported}
              endTimestamp={metricsEndTime}
              events={events}
              metrics={podMetrics}
              graphType={MetricsGraphType.CONTAINER}
              aggregationType={metricsAggType}
              paddingInMinutes={10}
            />
          )}
        <CorrelatedDeploysContent
          correlatedDeploys={deploys}
          relatedServicesDeploys={{
            upstream: upstreamRelatedDeploys,
            downstream: downstreamRelatedDeploys,
          }}
        />
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.CorrelatedDeploysStep.SkipStepButton
            }
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};

export const CorrelatedDeploys: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.investigationModeCorrelatedDeploys,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <CorrelatedDeploysComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
