import Link from "@mui/material/Link";
import { useCallback, useMemo } from "react";

import FullResource from "../../resources";

import { Resource, SessionData } from "@/generated/aiApi";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import { dispatchEvent } from "@/shared/hooks/analytics";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export type RichSessionData = {
  id: SessionData["id"];
  isComplete: SessionData["isComplete"];
  isStuck: SessionData["isStuck"];
  operations: SessionData["operations"];
  enrichedOperations: (React.ReactNode[] | undefined)[];
  queries: SessionData["queries"];
  problemShort: React.ReactNode[] | undefined;
  problemElaborate: React.ReactNode[] | undefined;
  evidence: React.ReactNode[] | undefined;
  evidenceSource: React.ReactNode[] | undefined;
  recommendation: React.ReactNode[] | undefined;
  whatHappened: React.ReactNode[] | undefined;
  isNoProblemFound: boolean;
};

type EnrichLineOptions = {
  sessionResource: FullResource | undefined;
  resources: Resource[] | undefined;
  onResourceClick: (resource: Resource) => void;
  enabled: boolean;
};

export const enrichLine = (
  line: string | undefined,
  { resources, onResourceClick, enabled, sessionResource }: EnrichLineOptions
): React.ReactNode[] | undefined => {
  if (!line) {
    return undefined;
  }
  const words = line.split(" ");
  if (!resources) {
    return words;
  }
  return words
    .map((word) => {
      const found = resources?.filter((r) =>
        r.name === "" ? false : word.includes(r.name)
      );
      const resource = found?.reduce((longestResource, resource) => {
        if (longestResource === undefined) {
          return resource;
        }
        return longestResource.name.length > resource.name.length
          ? longestResource
          : resource;
      }, undefined as Resource | undefined);
      if (resource && enabled && !isSameResource(sessionResource, resource)) {
        return (
          <Link
            key={resource.name}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return onResourceClick(resource);
            }}
            sx={{ cursor: "pointer", textDecoration: "none" }}
          >
            {word}
          </Link>
        );
      }
      return word;
    })
    .map((word) => [word, " "])
    .flat();
};

export function useEnrichedSession(
  sessionResource: FullResource | undefined,
  session: SessionData | null
): RichSessionData | null {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { clickableAiInvestigationResources } = useOverridableFlags();
  const onResourceClick = useCallback(
    (resource: Resource) => {
      if (
        !clickableAiInvestigationResources ||
        isSameResource(sessionResource, resource)
      ) {
        return;
      }
      pushDrawer({
        drawerType: DrawerType.ResourceDrawerByData,
        cluster: sessionResource?.cluster ?? "",
        ...(sessionResource ? { cluster: sessionResource.cluster } : {}),
        namespace: resource.namespace,
        resourceType: resource.kind,
        resourceName: resource.name,
      });
      dispatchEvent(AnalyticEvents.KlaudiaAi.InvestigationResourceLinkClicked, {
        resource,
      });
    },
    [clickableAiInvestigationResources, pushDrawer, sessionResource]
  );
  const options = useMemo(
    () => ({
      enabled: Boolean(clickableAiInvestigationResources),
      onResourceClick,
      sessionResource,
      resources: session?.queries?.filter((q) => q.kind !== "") ?? [],
    }),
    [
      clickableAiInvestigationResources,
      onResourceClick,
      session?.queries,
      sessionResource,
    ]
  );
  return useMemo(() => {
    if (!session) {
      return null;
    }
    return {
      id: session.id,
      isComplete: session.isComplete,
      isStuck: session.isStuck,
      operations: session.operations,
      enrichedOperations: (session.operations ?? []).map((line) =>
        enrichLine(line, options)
      ),
      queries: session.queries,
      problemShort: enrichLine(session.problemShort, options),
      problemElaborate: enrichLine(session.problemElaborate, options),
      evidence: enrichLine(session.evidence, options),
      evidenceSource: enrichLine(session.evidenceSource, options),
      recommendation: enrichLine(session.recommendation, options),
      whatHappened: session.whatHappened?.map((line) =>
        enrichLine(line, options)
      ),
      isNoProblemFound: session.problemShort === "No problem",
    };
  }, [options, session]);
}

function isSameResource(
  sessionResource: FullResource | undefined,
  stepResource: Resource
) {
  return (
    sessionResource?.kind === stepResource.kind &&
    sessionResource?.namespace === stepResource.namespace &&
    sessionResource?.name === stepResource.name
  );
}
