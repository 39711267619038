import { useCallback, useEffect, useState } from "react";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const useIsAwaitingNamespacesOnClusterChange = (
  namespaces: string[] | undefined
) => {
  const { oneNamespaceByDefaultInHelm } = useOverridableFlags();
  const [isAwaitingNamespacesChange, setIsAwaitingNamespacesChange] =
    useState(false);

  const onClusterChange = useCallback(() => {
    setIsAwaitingNamespacesChange(true);
  }, []);

  useEffect(() => {
    // assert namespaces from previous cluster are cleared and new namespaces are being fetched
    if (isAwaitingNamespacesChange && namespaces === undefined) {
      setIsAwaitingNamespacesChange(false);
    }
  }, [isAwaitingNamespacesChange, namespaces]);

  return {
    isAwaitingNamespacesChange: oneNamespaceByDefaultInHelm
      ? isAwaitingNamespacesChange
      : false,
    onClusterChangeCb: onClusterChange,
  };
};
