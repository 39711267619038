import React, { PropsWithChildren } from "react";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";

type AuditLogDrawerProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  ariaLabel?: string;
}>;

export const AuditLogDrawer: React.FC<AuditLogDrawerProps> = ({
  onClose,
  open,
  ariaLabel,
  children,
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      aria-label={ariaLabel}
      sx={{
        "& .MuiDrawer-paper": {
          overflowY: "hidden",
          minWidth: "800px",
          width: "60%",
        },
      }}
    >
      <Stack
        sx={{
          padding: "16px 0",
          height: "100%",
        }}
        direction="column"
      >
        {children}
      </Stack>
    </Drawer>
  );
};
