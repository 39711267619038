import React, { useState } from "react";

import { useWorkloadsContainersMetrics } from "../../hooks/useWorkloadsContainersMetrics";
import { WorkloadsContainersGraphProps } from "../../types";
import { WorkloadsMetricsGraphWithStatus } from "../WorkloadsGraph/WorkloadsMetricsGraphWithStatus";

import { EnlargedWorkloadsContainersGraph } from "./EnlargedWorkloadsContainersGraph";

import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";
import { WorkloadMetricsTabContextProvider } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/WorkloadMetricsTabContext";

export const WorkloadsContainerGraph: React.FC<
  WorkloadsContainersGraphProps
> = ({
  loadingStateId,
  wantedContainer,
  type,
  syncId,
  komodorUids,
  baselineKomodorUid,
}) => {
  const [isGraphEnlarged, setIsGraphEnlarged] = useState(false);
  const { initialLoadTime, selectedAggregationMetric, timeWindow } =
    useWorkloadMetricsTabContext();
  const {
    graphProps,
    error,
    isLoading,
    isFetching,
    hasGraphData,
    toEpochMs,
    linesContent,
  } = useWorkloadsContainersMetrics({
    komodorUids,
    type,
    wantedContainer,
    endTime: initialLoadTime,
  });

  const title = type === "cpu" ? "Cpu Utilizations" : "Memory Utilizations";

  return (
    <>
      {isGraphEnlarged && (
        <WorkloadMetricsTabContextProvider
          givenTimeWindow={timeWindow}
          givenAggregationMetric={selectedAggregationMetric}
        >
          <EnlargedWorkloadsContainersGraph
            initialWantedContainer={wantedContainer}
            endTime={toEpochMs}
            onClose={() => setIsGraphEnlarged(false)}
            type={type}
            baselineKomodorUid={baselineKomodorUid}
            komodorUids={komodorUids}
            title={title}
          />
        </WorkloadMetricsTabContextProvider>
      )}
      <WorkloadsMetricsGraphWithStatus
        loadingStateId={loadingStateId}
        graphProps={graphProps}
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        hasGraphData={hasGraphData}
        linesContent={linesContent}
        title={title}
        komodorUids={komodorUids}
        type={type}
        syncId={syncId}
        onEnlargeGraph={() => setIsGraphEnlarged(true)}
      />
    </>
  );
};
