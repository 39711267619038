import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1WorkloadsDeletedPodsEventsSearchGetUrl,
  DeletedPodsResponse,
  EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest,
} from "../../../../generated/resourcesApi/api";

import { useResourcesApiClient } from "./apiClient";

const fetchDeletedPodsState = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest
) => {
  const { data } = await apiClient.get<DeletedPodsResponse>(
    apiV1WorkloadsDeletedPodsEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetDeletedPodsState = (
  params: EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest,
  options?: { enabled?: boolean }
) => {
  const apiClient = useResourcesApiClient();
  let url = "";
  try {
    url = apiV1WorkloadsDeletedPodsEventsSearchGetUrl(
      {},
      apiClient.defaults.baseURL ?? ""
    );
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }
  return useQuery(
    [url, params],
    () => fetchDeletedPodsState(apiClient, params),
    { ...(options || {}), enabled: options?.enabled && !!url }
  );
};
