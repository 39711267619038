/* eslint-disable max-lines */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OptionType } from "@komodorio/design-system/deprecated";
import {
  ActionMetadataGetResource,
  ActionMetadataUpdateContainersResources,
  ActionTypes,
  Container,
  TaskType,
} from "komodor-types";
import pluralize from "pluralize";
import { isEqual } from "lodash";

import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import { isFaultyResultMessage } from "../../common/utils";
import { useShouldShowActionSentMessage } from "../../common/useActionSent";
import { parseContainersResult } from "../../../../shared/utils/actions/parseContainersResult";
import { quantityToScalar } from "../../../../shared/utils/numbers/quantityToScalar";

import {
  ConfigureModalProps,
  ResourceConfigurationType,
  ResourceType,
} from "./types";
import { ConfigureModalContent } from "./ConfigureModalContent";

import { useExecuteAgentTaskAndReport } from "@/components/Actions/modals/actionModalHooks";

const getResourcesComparisonError = (
  resourceConfigType: ResourceConfigurationType
) => {
  return resourceConfigType === ResourceConfigurationType.Requests
    ? "Requests cannot be higher than limits"
    : "Limits cannot be lower than requests";
};

const ConfigureModal: React.FC<ConfigureModalProps> = (props) => {
  const {
    handleClose,
    resourceId,
    resourceName,
    resourceType,
    namespace,
    cluster,
    agentId,
    defaultContainerName,
  } = props;
  const [actionSent, setActionSent] = useState(false);
  const [showMessageActionSent, setShowMessageActionSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getWasSent, setGetWasSent] = useState(false);
  const [containers, setContainers] = useState<Container[]>([]);
  const [selectedContainer, setSelectedContainer] = useState<Container>();
  const [defaultContainers, setDefaultContainers] = useState<Container[]>([]);
  const [containersInputValidation, setContainersInputValidation] =
    useState<Container[]>();
  const getContainersResourcesMetadata: ActionMetadataGetResource = {
    cluster: cluster,
    namespace: namespace,
    serviceId: resourceId,
    resourceType: pluralize.singular(resourceType),
    resourceName: resourceName,
    isContainerResourcesOnly: true,
    type: ActionTypes.GetResource,
  };

  const metadata: ActionMetadataUpdateContainersResources = {
    cluster: cluster,
    namespace: namespace,
    serviceId: resourceId,
    resourceType: pluralize.singular(resourceType),
    resourceName: resourceName,
    type: ActionTypes.UpdateContainersResources,
    containers: JSON.stringify(containers),
  };
  const isError = useMemo(() => {
    return Boolean(
      containersInputValidation?.some((container) =>
        Object.entries(container.resources).some(([, cpuMemory]) =>
          Object.entries(cpuMemory).some(([, value]) => value !== "")
        )
      )
    );
  }, [containersInputValidation]);

  const isResourcesChanged = useMemo(() => {
    return !isEqual(defaultContainers, containers);
  }, [containers, defaultContainers]);

  const {
    execute: executeGetContainers,
    result: getContainersResult,
    deniedObject: getContainersDeniedObject,
    failureMessage: getContainersFailureMessage,
  } = useAgentTask(
    agentId,
    TaskType.ACTION_COMMAND,
    getContainersResourcesMetadata
  );

  const {
    execute,
    result: updateContainersResult,
    deniedObject: updateContainersDeniedObject,
    failureMessage: updateContainersFailureMessage,
    isFetching,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  const executeAndReport = useExecuteAgentTaskAndReport({
    execute,
    resourceType,
    reportType: "updateContainersResources",
  });

  const onExecuteAndReport = useCallback(() => {
    executeAndReport();
    setErrorMessage("");
  }, [executeAndReport]);

  const shouldShowActionSentMessage =
    useShouldShowActionSentMessage(isFetching);

  useEffect(() => {
    setShowMessageActionSent(shouldShowActionSentMessage);
  }, [shouldShowActionSentMessage]);

  useEffect(() => {
    if (getContainersDeniedObject || updateContainersDeniedObject) {
      setActionSent(false);
    }
  }, [getContainersDeniedObject, updateContainersDeniedObject]);

  useEffect(() => {
    if (!getWasSent && !props.containers) {
      executeGetContainers();
      setGetWasSent(true);
    }
  }, [executeGetContainers, getWasSent, props.containers]);

  useEffect(() => {
    setSelectedContainer(
      containers.find((c) => c.name === selectedContainer?.name)
    );
  }, [containers, selectedContainer?.name]);

  const errorOutput = useMemo(
    () =>
      errorMessage ||
      getContainersFailureMessage ||
      updateContainersFailureMessage,
    [errorMessage, getContainersFailureMessage, updateContainersFailureMessage]
  );

  const updateContainers = useCallback(
    (containers: Container[]) => {
      const defaultContainer =
        containers?.length > 0
          ? defaultContainerName
            ? containers.find((c) => c.name === defaultContainerName)
            : containers[0]
          : undefined;
      setSelectedContainer(defaultContainer);
      setContainers(containers);
      setDefaultContainers(containers);
      setContainersInputValidation(
        containers.map((c) => ({
          name: c.name,
          resources: {
            requests: { cpu: "", memory: "" },
            limits: { cpu: "", memory: "" },
          },
        }))
      );
    },
    [defaultContainerName]
  );

  useEffect(() => {
    if (props.containers) {
      updateContainers(props.containers);
    }
  }, [props.containers, updateContainers]);

  useEffect(() => {
    if (getContainersResult && containers.length === 0) {
      const parsedContainersResult = parseContainersResult(getContainersResult);
      updateContainers(parsedContainersResult ?? []);
    }
  }, [
    containers.length,
    defaultContainerName,
    getContainersResult,
    updateContainers,
  ]);

  useEffect(() => {
    if (updateContainersResult) {
      if (isFaultyResultMessage(updateContainersResult as string)) {
        setErrorMessage(updateContainersResult as string);
        setActionSent(false);
      } else if (errorOutput === "") {
        handleClose();
      }
    }
  }, [errorOutput, handleClose, updateContainersResult]);

  const options: OptionType<string>[] = useMemo(
    () =>
      containers.map((container) => ({
        label: container.name,
        value: container.name,
      })),
    [containers]
  );

  const getUpdatedContainers = (
    resourceType: ResourceType,
    resourceConfigType: ResourceConfigurationType,
    newValue: string | null,
    containers: Container[]
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
  ): Container[] => {
    return containers.map((container) => {
      if (container.name === selectedContainer?.name) {
        const updateObj = {
          [resourceConfigType]: {
            ...container.resources[resourceConfigType],
            [resourceType]: newValue,
          },
        };
        return {
          ...container,
          resources: {
            ...container.resources,
            ...updateObj,
          },
        };
      }
      return container;
    });
  };
  const handleInputValidation = (
    value: string,
    resourceType: ResourceType,
    resourceConfigType: ResourceConfigurationType
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
  ): void => {
    const container = containers.find(
      (c) => c.name === selectedContainer?.name
    );
    if (container) {
      let isInputValid = true;
      let containersValidation = containersInputValidation;
      let errorMessage = "";
      const parallelResourceConfiguration =
        resourceConfigType === ResourceConfigurationType.Requests
          ? ResourceConfigurationType.Limits
          : ResourceConfigurationType.Requests;
      const scalarValue = quantityToScalar(value);
      const isInputFormatValid = scalarValue !== undefined;

      const containerResourceParallelConfig =
        container.resources[parallelResourceConfiguration];

      const parallelValue =
        containerResourceParallelConfig?.[resourceType] ?? "";
      const parallelScalarValue = quantityToScalar(parallelValue);
      const isParallelInputFormatValid = parallelScalarValue !== undefined;
      if (isInputFormatValid && isParallelInputFormatValid) {
        isInputValid =
          parallelValue === "" ||
          value === "" ||
          (resourceConfigType === ResourceConfigurationType.Requests
            ? scalarValue <= parallelScalarValue
            : scalarValue >= parallelScalarValue);
        const parallelResourceError = isInputValid
          ? ""
          : getResourcesComparisonError(parallelResourceConfiguration);
        containersValidation = getUpdatedContainers(
          resourceType,
          parallelResourceConfiguration,
          parallelResourceError,
          containersValidation ?? []
        );
        errorMessage = isInputValid
          ? ""
          : getResourcesComparisonError(resourceConfigType);
      }
      if (!isInputFormatValid) {
        isInputValid = false;
        errorMessage = "Input format is incorrect";
        if (isParallelInputFormatValid) {
          containersValidation = getUpdatedContainers(
            resourceType,
            parallelResourceConfiguration,
            "",
            containersValidation ?? []
          );
        }
      }

      containersValidation = getUpdatedContainers(
        resourceType,
        resourceConfigType,
        errorMessage,
        containersValidation ?? []
      );
      setContainersInputValidation(containersValidation);
    }
  };

  const handleResourceChange = (
    resourceConfigType: ResourceConfigurationType,
    resourceType: ResourceType,
    newValue: string
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
  ) => {
    const updatedContainers = getUpdatedContainers(
      resourceType,
      resourceConfigType,
      newValue !== "" ? newValue : null,
      containers
    );
    setContainers(updatedContainers);
    handleInputValidation(newValue, resourceType, resourceConfigType);
  };
  const handleResetContainer = () => {
    setContainers(
      containers.map((container) => {
        if (container.name === selectedContainer?.name) {
          const c = defaultContainers.find(
            (c) => c.name === selectedContainer.name
          );
          return c ?? container;
        }
        return container;
      })
    );
    setContainersInputValidation(
      containersInputValidation?.map((c) => {
        if (c.name === selectedContainer?.name) {
          return {
            name: c.name,
            resources: {
              requests: { cpu: "", memory: "" },
              limits: { cpu: "", memory: "" },
            },
          };
        }
        return c;
      })
    );
  };

  return (
    <ConfigureModalContent
      {...props}
      errorOutput={errorOutput}
      containers={containers}
      setSelectedContainer={setSelectedContainer}
      selectedContainer={selectedContainer}
      containersInputValidation={containersInputValidation}
      resourceId={resourceId}
      resourceName={resourceName}
      resourceType={resourceType}
      namespace={namespace}
      agentId={agentId}
      defaultContainerName={defaultContainerName}
      setShowMessageActionSent={setShowMessageActionSent}
      showMessageActionSent={showMessageActionSent}
      actionSent={actionSent}
      setActionSent={setActionSent}
      isError={isError}
      getContainersDeniedObject={getContainersDeniedObject}
      updateContainersDeniedObject={updateContainersDeniedObject}
      handleResetContainer={handleResetContainer}
      executeAndReport={onExecuteAndReport}
      options={options}
      handleResourceChange={handleResourceChange}
      isResourcesChanged={isResourcesChanged}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ConfigureModal;
