import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";
import { ReliabilityRoutes } from "../../../constants/reliabilityConstants";
import { PagePadding } from "../../../ReliabilityStyles";

import { AllPolicies } from "./AllPolicies";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { ViewOptions } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

const Container = styled.div`
  ${PagePadding};
`;

const ddViewOptions: ViewOptions = {
  name: DatadogViewNamesEnum.reliabilityPoliciesPage,
  context: {
    beTeam: "operate",
    feTeam: "operate",
  },
};

export const PoliciesPage: React.FC = () => {
  const { showReliabilityPolicies } = useOverridableFlags();
  const navigate = useNavigate();

  if (!showReliabilityPolicies) {
    navigate(ReliabilityRoutes.violations);
  }
  return (
    <Container>
      <DatadogReportLoadingTimeContextProvider viewOptions={ddViewOptions}>
        <AllPolicies />
      </DatadogReportLoadingTimeContextProvider>
    </Container>
  );
};
