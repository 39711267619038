import React, { useEffect, useMemo, useState } from "react";
import { sortBy } from "lodash";
import {
  LabeledSwitch,
  SearchField,
  Status,
} from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Add from "@mui/icons-material/Add";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";

import DataTable, { Column } from "../../common/table/DataTable";
import { ActionsCol, Buttons, CopyText, Header } from "../styles";

import DeleteKeyModal from "./DeleteKeyModal";
import GenerateKeyModal from "./GenerateKeyModal";
import { ConfigKeyModal } from "./ConfigKeyModal";

import { APIKey } from "@/generated/auth";
import { useGetApiKeys } from "@/shared/hooks/auth-service/client/apiKeys/useGetApiKeys";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { ValidationsProvider } from "@/shared/context/ValidationsProvider";
import { useTimezone } from "@/shared/context/TimezoneProvider";
import { UserSettingsWrapper } from "@/pages/user-settings/UserSettingsWrapper";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { NonExpireKeyModal } from "@/components/Settings/ApiKeys/NonExpireKeyModal";
import { apiKeysAriaLabels } from "@/components/Settings/ApiKeys/apiKeysAriaLabels";
import { useGetUsers } from "@/shared/hooks/auth-service/client/users/useGetUsers";
import {
  ApiKeysTTLFeature,
  APIKeyWithExpiration,
} from "@/components/Settings/ApiKeys/types";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const ApiKeysComponent: React.FC = () => {
  const [search, setSearch] = useState("");
  const [openAddApiKey, setOpenAddApiKey] = useState(false);
  const [openConfigApiKey, setOpenConfigApiKey] = useState(false);
  const [apiKeyToDelete, setApiKeyToDelete] = useState<APIKey>();
  const [apiKeyToForever, setApiKeyToForever] = useState<APIKey>();
  const [showOnlyValidKeys, setShowOnlyValidKeys] = useState(false);
  const { data: users } = useGetUsers();
  const { apiKeysExpirationFeature } = useOverridableFlags();

  const { canManageUsers } = useHasPermissions();
  const { data: features, refresh: refreshFeatures } = useAccountFeatures();

  const [apiKeysTTLFeature, setApiKeysTTLFeature] = useState<ApiKeysTTLFeature>(
    features.apiKeysTTL ?? { enabled: false, TTLInDays: 0 }
  );
  const { format } = useDateFormatter({ timeZoneName: undefined });
  const { timeZoneName } = useTimezone();

  const { data: apiKeys, refetch, isLoading } = useGetApiKeys();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isLoadingApiKeys", isLoading);
  }, [isLoading, reportLoadingState]);

  useEffect(() => {
    if (features.apiKeysTTL) {
      setApiKeysTTLFeature(features.apiKeysTTL);
    }
  }, [features]);

  const columns: Column<APIKeyWithExpiration>[] = useMemo(
    () => [
      { name: "Description", selector: (ak) => ak.name },
      {
        name: "API Key",
        selector: (ak) => <CopyText text={ak.key} obfuscatedChar={"****"} />,
      },
      {
        name: `Created At (${timeZoneName})`,
        selector: (ak) => format(new Date(ak.createdAt)),
      },
      ...(canManageUsers
        ? [
            {
              name: "User Name",
              selector: (ak: APIKeyWithExpiration) => ak.userName,
            },
          ]
        : []),
      ...(apiKeysTTLFeature.enabled
        ? [
            {
              name: `Expired At (${timeZoneName})`,
              selector: (ak: APIKeyWithExpiration) =>
                ak.isExpired ? (
                  <Status color="neutral" variant="outlined" label="Expired" />
                ) : ak.expiredAt ? (
                  format(new Date(ak.expiredAt))
                ) : (
                  <Status
                    color="warning"
                    variant="outlined"
                    label="Non-expiring"
                  />
                ),
            },
          ]
        : []),
      {
        name: "Actions",
        selector: (ak) => (
          <ActionsCol>
            {canManageUsers &&
              ak.expiredAt &&
              !ak.isExpired &&
              apiKeysTTLFeature.enabled && (
                <IconButton
                  onClick={() => setApiKeyToForever(ak)}
                  aria-label={apiKeysAriaLabels.buttons.expirationRemove}
                >
                  <EditOutlined />
                </IconButton>
              )}
            <IconButton
              onClick={() => setApiKeyToDelete(ak)}
              aria-label={apiKeysAriaLabels.buttons.delete}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </ActionsCol>
        ),
      },
    ],
    [canManageUsers, apiKeysTTLFeature.enabled, timeZoneName, format]
  );

  const apiKeysWithExpiration = useMemo<APIKeyWithExpiration[]>(() => {
    return apiKeys
      ? apiKeys.map((ak) => ({
          ...ak,
          isExpired: ak.expiredAt
            ? new Date(ak.expiredAt) <= new Date()
            : false,
          userName:
            users?.find((u) => u.id === ak.userId)?.displayName ?? ak.userId,
        }))
      : [];
  }, [apiKeys, users]);

  const filteredApiKeys = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return apiKeysWithExpiration
      ? sortBy(
          apiKeysWithExpiration.filter((ak) => {
            const matchesSearch = [ak.name, ak.key].some((s) =>
              s.toLowerCase().includes(lowerCaseFilter)
            );
            const matchesExpiration = showOnlyValidKeys ? !ak.isExpired : true;
            return matchesSearch && matchesExpiration;
          }),
          (ak) => ak.createdAt
        )
      : [];
  }, [apiKeysWithExpiration, search, showOnlyValidKeys]);

  return (
    <UserSettingsWrapper title={"API Keys"}>
      <Header>
        <Stack direction={"row"} columnGap={1}>
          <SearchField
            width="240px"
            placeholder="Search"
            value={search}
            showCloseIcon={true}
            ariaLabel={apiKeysAriaLabels.search}
            onSearch={setSearch}
          />
          {apiKeysTTLFeature.enabled && (
            <LabeledSwitch
              ariaLabel={apiKeysAriaLabels.buttons.showExpired}
              checked={!showOnlyValidKeys}
              onCheck={() => setShowOnlyValidKeys(!showOnlyValidKeys)}
              label="Show expired keys"
            />
          )}
        </Stack>
        <Buttons>
          <Button
            variant="outlined"
            startIcon={<OpenInNewOutlined />}
            onClick={() =>
              window.open(
                "https://api.komodor.com/api/docs/index.html",
                "_blank"
              )
            }
          >
            API Documentation
          </Button>{" "}
          {canManageUsers && !!apiKeysExpirationFeature && (
            <Button
              variant="outlined"
              startIcon={<OpenInNewOutlined />}
              onClick={() => setOpenConfigApiKey(true)}
              aria-label={apiKeysAriaLabels.buttons.config}
            >
              Configure API Keys
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setOpenAddApiKey(true)}
            aria-label={apiKeysAriaLabels.buttons.generate}
          >
            Generate Key
          </Button>
        </Buttons>
      </Header>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      <DataTable
        data={filteredApiKeys}
        columns={columns}
        getRowId={(ak) => ak.id}
        highlightedRows
      />
      {openAddApiKey && (
        <ValidationsProvider>
          <GenerateKeyModal
            open
            handleClose={() => setOpenAddApiKey(false)}
            refreshKeys={refetch}
          />
        </ValidationsProvider>
      )}
      {openConfigApiKey && (
        <ConfigKeyModal
          handleClose={() => setOpenConfigApiKey(false)}
          refreshKeys={refetch}
          refreshFeatures={refreshFeatures}
        />
      )}
      {apiKeyToDelete && (
        <DeleteKeyModal
          open
          handleClose={() => setApiKeyToDelete(undefined)}
          apiKey={apiKeyToDelete}
          refreshKeys={refetch}
        />
      )}
      {apiKeyToForever && (
        <NonExpireKeyModal
          handleClose={() => setApiKeyToForever(undefined)}
          apiKey={apiKeyToForever}
          refreshKeys={refetch}
        />
      )}
    </UserSettingsWrapper>
  );
};

const ApiKeys: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.userSettingsApiKeys,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <ApiKeysComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ApiKeys;
