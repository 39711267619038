import React from "react";
import { get } from "lodash";
import { ClusterRole as ClusterRoleType } from "kubernetes-types/rbac/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";

import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ClusterRoleDetailsTab } from "../tabs/DescribeTab/ClusterRole";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class ClusterRole implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.Describe];
  readonly rules;
  readonly fullObj;
  readonly defaultTab;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(cluster: string, clusterRole: ClusterRoleType, agentId: string) {
    this.agentId = agentId;
    this.id = clusterRole.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = clusterRole.metadata?.namespace ?? "";
    this.name = clusterRole.metadata?.name ?? "";
    this.kind = clusterRole.kind ?? "";
    this.labels = clusterRole.metadata?.labels ?? {};
    this.annotations = clusterRole.metadata?.annotations ?? {};
    this.fullObj = clusterRole;
    this.rules = clusterRole.rules ?? [];
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <ClusterRoleDetailsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        displayName={get(this, "fullObj.spec.names.kind")}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
