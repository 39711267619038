import { Dictionary } from "@/shared/types/Dictionary";
import { findActiveAgentByClusterName } from "@/shared/hooks/useAgents";
import { doesAgentVersionSupportResourceListFromAtmAndLiveData } from "@/shared/utils/agent/agent";
import {
  parseAgentInfo,
  agentInfoToProperties,
  agentResponse,
} from "@/shared/hooks/agents-api/utils";
import { WorkspaceKind, WorkspaceValue } from "@/generated/workspacesApi";

export const stringListToDictionary = (arr: string[]): Dictionary<boolean> => {
  return arr.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
};

export const boolDictionaryToStringList = (
  dic: Dictionary<boolean>
): string[] =>
  Object.entries(dic)
    .filter(([, selected]) => selected)
    .map(([serviceId]) => serviceId);

export const doesAgentSupportAtmLiveData = (
  agents: agentResponse | undefined,
  clusterName: string
): boolean => {
  const agent = findActiveAgentByClusterName(agents, clusterName);
  if (!agent) return false;
  const agentInfo = parseAgentInfo(agent);
  const agentProps = agentInfoToProperties(agentInfo);
  return doesAgentVersionSupportResourceListFromAtmAndLiveData(agentProps);
};

export const workspaceValueByType = (
  values: string[],
  type?: WorkspaceKind
): WorkspaceValue => {
  // TODO: use generated code for this
  switch (type) {
    case WorkspaceKind.ServiceId:
      return { serviceIds: values, kind: WorkspaceKind.ServiceId };
    case WorkspaceKind.Namespace:
      return { namespaces: values, kind: WorkspaceKind.Namespace };
    case WorkspaceKind.Label:
      return { labels: values, kind: WorkspaceKind.Label };
    default:
      return { serviceIds: [], kind: WorkspaceKind.ServiceId };
  }
};
