import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useIsDemoService, useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { komodorServiceSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useScrollTracking } from "../common/useScrollTracking";
import { DependenciesTabContent } from "../../../ResourceView/tabs/DependenciesTab/DependenciesTabContent";
import { Resource as DependenciesResource } from "../../../ResourceView/tabs/DependenciesTab/DependenciesTab";
import BlastRadius from "../../assets/blastRadius.svg";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
const Subtitle = styled.img.attrs({ src: BlastRadius, alt: "Blast Radius" })`
  align-self: start;
  object-fit: contain;
`;
const DependenciesComponent: React.FC = () => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const resource = useInvestigationModeStore(komodorServiceSelector);

  const { nextStep, goToNextStep } = useNextStep();
  const elementRef = useScrollTracking<HTMLDivElement>();

  const isLoading = !resource;
  const isDemoService = useIsDemoService();

  useEffect(() => {
    reportLoadingState("dependenciesData", isLoading);
  }, [isLoading, reportLoadingState]);

  if (isLoading) {
    return null;
  }
  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2">
          Check related services and potential blast radius
        </Typography>
        {isDemoService && (
          <>
            <br />
            <Subtitle />
          </>
        )}
      </StepHeader>
      <StepContent ref={elementRef}>
        {resource?.isDeleted || resource?.inactive ? (
          <Typography variant="h2">Resource is deleted</Typography>
        ) : (
          <>
            <Typography variant="h2">Dependency graph</Typography>
            <DependenciesTabContent
              resource={resource as DependenciesResource}
            />
          </>
        )}
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.DependenciesStep.SkipStepButton
            }
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};

export const Dependencies: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.investigationModeDependencies,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <DependenciesComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
