import React, { useRef } from "react";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import { muiTheme } from "@komodorio/design-system";

import { EffectivePermissionsContent } from "@/components/Settings/Users/EffectivePermissions/EffectivePermissionsContent";
import { CanIContainer } from "@/components/Settings/Users/Permissions/CanI/CanIContainer";
import { useOnContainerResizeWidth } from "@/components/common/LogsViewer/LogsBlackText/hooks/useContainerResizeWidth";
import { User } from "@/generated/auth";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { ariaLabels } from "@/components/Settings/Users/Permissions/constants";

const TAB_PANEL_DESIGN = {
  "&": {
    padding: "24px 0",
    width: "100%",
  },
};

enum PermissionsTabs {
  EffectivePermissions = "Effective Permissions",
  CanI = "canI",
}

export const PermissionsTabsContainer = ({
  error,
  user,
  isAdminView = false,
}: {
  error?: string;
  user?: User;
  isAdminView?: boolean;
}) => {
  const [tabIndex, setTabIndex] = React.useState<string>(
    PermissionsTabs.EffectivePermissions
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const width = useOnContainerResizeWidth(containerRef);
  const { canICheck } = useOverridableFlags();
  const { canGetKubeconfig } = useHasPermissions();

  const canIEnabled = isAdminView ? canICheck : canGetKubeconfig;

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue);
  };

  if (error) {
    return (
      <Typography color={muiTheme.palette.error.main}>
        There was an error fetching the effective permissions
      </Typography>
    );
  }

  if (!user) {
    return <LinesLoader />;
  }

  return (
    <Stack ref={containerRef} height="100%" flex={1}>
      <TabContext value={tabIndex}>
        <TabList onChange={handleChange} aria-label="permissions tabs">
          <Tab
            aria-label={ariaLabels.effectivePermissions.tab}
            label="Effective Permissions"
            value={PermissionsTabs.EffectivePermissions}
          />
          {canIEnabled ? (
            <Tab
              aria-label={ariaLabels.cani.tab}
              label="Can I...?"
              value={PermissionsTabs.CanI}
            />
          ) : null}
        </TabList>
        <Stack sx={{ width: "100%", flex: 1 }} alignItems="start">
          <TabPanel
            value={PermissionsTabs.EffectivePermissions}
            sx={TAB_PANEL_DESIGN}
          >
            <EffectivePermissionsContent
              user={user}
              allowLinks={isAdminView}
              width={width}
            />
          </TabPanel>
          {canIEnabled ? (
            <TabPanel value={PermissionsTabs.CanI} sx={TAB_PANEL_DESIGN}>
              <CanIContainer userId={user.id} isAdminView={isAdminView} />
            </TabPanel>
          ) : null}
        </Stack>
      </TabContext>
    </Stack>
  );
};
