import { useLocation } from "react-router-dom";

import { JOBS_ROUTE, SERVICES_ROUTE } from "@/components/routes/routes";

export const useIsServicesViewPath = () => {
  const location = useLocation();

  return (
    location.pathname.includes(SERVICES_ROUTE) ||
    location.pathname.includes(JOBS_ROUTE)
  );
};
