import { useMemo } from "react";
import { max, compact, flatten } from "lodash";

import {
  ClusterOverviewResponseInner,
  ClusterOverviewResponseSingleAggregation,
  Datapoint,
} from "../../../../../../generated/metricsApi";
import { GraphDataPoint } from "../../../../../Metrics/types";
import { roundToNearestNSeconds } from "../../../../../Metrics/utils";

import { ONE_MINUTE_IN_MS } from "@/shared/constants/time";

export interface AggregatedData {
  memory: {
    Avg?: GraphDataPoint[];
    Max?: GraphDataPoint[];
    P99?: GraphDataPoint[];
    P95?: GraphDataPoint[];
    P90?: GraphDataPoint[];
  };
  cpu: {
    Avg?: GraphDataPoint[];
    Max?: GraphDataPoint[];
    P99?: GraphDataPoint[];
    P95?: GraphDataPoint[];
    P90?: GraphDataPoint[];
  };
}

const memoryFields: Record<string, keyof GraphDataPoint> = {
  memoryUsageBytes: "usageBytes",
  allocatableMemoryBytes: "allocatableBytes",
  capacityMemoryBytes: "capacityBytes",
};

const cpuFields: Record<string, keyof GraphDataPoint> = {
  cpuUsageMillicores: "usageBytes",
  allocatableMillicores: "allocatableBytes",
  capacityMillicores: "capacityBytes",
};

const ROUND_TO_NEAREST_SECONDS = 10;

const shouldRemoveEdges = (dataPoints: GraphDataPoint[]): boolean => {
  if (dataPoints.length < 10) return false;
  return dataPoints[1].time - dataPoints[0].time <= ONE_MINUTE_IN_MS;
};

const createGraphDataPoints = (
  aggregation: ClusterOverviewResponseSingleAggregation | undefined,
  memoryFields: Record<string, keyof GraphDataPoint>,
  cpuFields: Record<string, keyof GraphDataPoint>
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): { memory: GraphDataPoint[]; cpu: GraphDataPoint[] } => {
  if (!aggregation) return { memory: [], cpu: [] };

  const memoryDataPoints: GraphDataPoint[] = [];
  const cpuDataPoints: GraphDataPoint[] = [];

  const addDataPoints = (
    dataPoints: Array<Datapoint> | undefined,
    fieldNameInGraph: keyof GraphDataPoint,
    targetArray: GraphDataPoint[]
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
  ) => {
    if (!dataPoints) return;
    dataPoints.forEach(({ timestampMs, value }) => {
      const roundTimestamp =
        roundToNearestNSeconds(timestampMs, ROUND_TO_NEAREST_SECONDS) * 1000;
      let dataPoint = targetArray.find((dp) => dp.time === roundTimestamp);
      if (!dataPoint) {
        dataPoint = { time: roundTimestamp };
        targetArray.push(dataPoint);
      }
      dataPoint[fieldNameInGraph] = value;
    });
  };

  for (const [field, graphField] of Object.entries(memoryFields)) {
    addDataPoints(
      aggregation[field as keyof ClusterOverviewResponseSingleAggregation],
      graphField,
      memoryDataPoints
    );
  }
  for (const [field, graphField] of Object.entries(cpuFields)) {
    addDataPoints(
      aggregation[field as keyof ClusterOverviewResponseSingleAggregation],
      graphField,
      cpuDataPoints
    );
  }

  const removeEdges = shouldRemoveEdges(memoryDataPoints);

  return {
    memory: memoryDataPoints.slice(
      removeEdges ? 2 : 0,
      removeEdges ? -2 : undefined
    ),
    cpu: cpuDataPoints.slice(removeEdges ? 2 : 0, removeEdges ? -2 : undefined),
  };
};

export const useTransformData = (
  input: ClusterOverviewResponseInner | undefined
): AggregatedData => {
  const memory = useMemo(
    () =>
      input
        ? {
            Avg: createGraphDataPoints(input.avg, memoryFields, cpuFields)
              .memory,
            Max: createGraphDataPoints(input.max, memoryFields, cpuFields)
              .memory,
            P99: createGraphDataPoints(input.p99, memoryFields, cpuFields)
              .memory,
            P95: createGraphDataPoints(input.p95, memoryFields, cpuFields)
              .memory,
            P90: createGraphDataPoints(input.p90, memoryFields, cpuFields)
              .memory,
          }
        : {},
    [input]
  );

  const cpu = useMemo(
    () =>
      input
        ? {
            Avg: createGraphDataPoints(input.avg, memoryFields, cpuFields).cpu,
            Max: createGraphDataPoints(input.max, memoryFields, cpuFields).cpu,
            P99: createGraphDataPoints(input.p99, memoryFields, cpuFields).cpu,
            P95: createGraphDataPoints(input.p95, memoryFields, cpuFields).cpu,
            P90: createGraphDataPoints(input.p90, memoryFields, cpuFields).cpu,
          }
        : {},
    [input]
  );

  return { memory, cpu };
};

export const useFindMaxValue = (
  dataPoints: GraphDataPoint[]
): number | undefined => {
  const values: number[] = compact(
    flatten(
      dataPoints.map(({ usageBytes, capacityBytes, allocatableBytes }) => [
        usageBytes,
        capacityBytes,
        allocatableBytes,
      ])
    )
  );
  return values.length > 0 ? max(values) : undefined;
};
