import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";

const ServiceTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ServiceTitle: React.FC<{
  parsedKomodorUid: KomodorUidParams;
}> = ({ parsedKomodorUid }) => {
  return (
    <ServiceTitleContainer>
      <Typography color="textSecondary" fontSize="12px" fontWeight={500}>
        {`${parsedKomodorUid.cluster}/${parsedKomodorUid.namespace}/`}
      </Typography>
      <Typography color="textPrimary" fontSize="12px" fontWeight={500}>
        {parsedKomodorUid.name}
      </Typography>
    </ServiceTitleContainer>
  );
};
