import styled from "styled-components";

import { AggregationType } from "@/generated/metricsApi";

export const nodesOverTimeAggregation: AggregationType = "avg";

export const colorPalette = [
  { stroke: "#1347FF", fill: "#C2CFFF" },
  { stroke: "#B50356", fill: "#FFD2E7" },
  { stroke: "#9D69F0", fill: "#D2B9FA" },
  { stroke: "#E08700", fill: "#FFE7C2" },
  { stroke: "#5AB0FF", fill: "#B6E1FF" },
  { stroke: "#AAB1C5", fill: "#EBEDF4" },
];

export const GraphDataKeyIndicator = styled.div<{ index: number }>`
  width: 8px;
  height: 8px;
  background-color: ${({ index }) =>
    colorPalette[index % colorPalette.length].stroke};
`;

export const MetricsColorPalette = {
  usage: "#476FFF",
  capacity: "#E08700",
  allocated: "#9D69F0",
};

export const MetricsGraphIndicator = styled.div<{
  metric: keyof typeof MetricsColorPalette;
}>`
  width: 8px;
  height: 8px;
  background-color: ${({ metric }) => MetricsColorPalette[metric]};
`;
