import React, { useMemo } from "react";
import { TypographyProps } from "@mui/material";

import Resource from "../../resources";
import { DescribeSection } from "../../tabs/DescribeTab/common/DescribeSection";
import { useResourceAiInvestigation } from "../common/useResourceAiInvestigation";
import { InvestigationTitle } from "../common/InvestigationTitle";
import { InvestigationContent } from "../common/InvestigationContent";

import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";

type ResourceAiInvestigationParams = {
  className?: string;
  showBorder?: boolean;
  requestId: string;
  flowType: FlowType;
  resource: Resource;
  drawerData?: DrawerStatePush;
  isHealthy: boolean;
  titleProps?: {
    collapsible?: boolean;
    showIcon?: boolean;
    showTitleText?: boolean;
    typographyProps?: TypographyProps;
  };
};
export const InvestigationSection: React.FC<ResourceAiInvestigationParams> = ({
  resource,
  isHealthy,
  requestId,
  titleProps,
  showBorder = true,
  className,
  flowType,
  drawerData,
}) => {
  const defaultResourceDrawerData = useMemo<DrawerStatePush>(
    () => ({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: resource.cluster,
      namespace: resource.namespace,
      resourceType: resource.kind,
      resourceName: resource.name,
    }),
    [resource]
  );

  const {
    enabled,
    richSession,
    onShareClicked,
    isError,
    isLoading,
    onRetryCallback,
  } = useResourceAiInvestigation({
    drawerData: drawerData || defaultResourceDrawerData,
    requestId,
    resource,
    isHealthy,
    flowType,
  });

  if (!enabled || (isHealthy && !richSession)) {
    return null;
  }

  return (
    <DescribeSection
      className={className}
      collapsible={titleProps?.collapsible}
      disabled={richSession?.isComplete && richSession?.isNoProblemFound}
      title={
        <InvestigationTitle
          showIcon={titleProps?.showIcon}
          showTitleText={titleProps?.showTitleText}
          titleProps={titleProps?.typographyProps}
          richSession={richSession}
          onShareClicked={onShareClicked}
        />
      }
      expandByDefault
    >
      <InvestigationContent
        richSession={richSession}
        isError={isError}
        onRetryCallback={onRetryCallback}
        isLoading={isLoading}
        showBorder={showBorder}
      />
    </DescribeSection>
  );
};
