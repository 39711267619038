import { useEffect, useMemo, useRef } from "react";
import { cloneDeep } from "lodash";
import Box from "@mui/material/Box";

import { useGetLoadingTimeContextPropsForResource } from "../../resources/hooks/resourceHooks";
import { ResourceTab } from "../../types";

import { useNeedUpgradeAgent } from "@/components/common/UpdateAgentModal/hooks";
import type Deployment from "@/components/ResourceView/resources/deployment";
import type Rollout from "@/components/ResourceView/resources/rollout";
import { DescribeLoader } from "@/components/ResourceView/tabs/DescribeTab/common/DescribeLoader";
import { ManagedRecordsSection } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ManagedRecordsSection";
import { RootCauseAnalysisSection } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/RootCauseAnalysisSection";
import { SettingsSection } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/SettingsSection";
import { StatusSection } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/StatusSection";
import {
  Addon,
  Entity,
  ExternalDnsRecordFromJSONTyped,
  ExternalDnsRecordFullFromJSON,
  ExternalDnsRecordSyncStatusEnum,
} from "@/generated/addonsApi";
import { useGetAddonLiveStateList } from "@/shared/hooks/k8s-add-ons/useGetAddonLiveStateList";
import { doesAgentVersionSupportExternalDNSStatus } from "@/shared/utils/agent/agent";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import { ErrorTableResults } from "@/components/common/table/ErrorResults";

const ExternalDNSSummaryTabContext: React.FC<{
  resource: Deployment | Rollout;
}> = ({ resource }) => {
  const divRef = useRef<HTMLDivElement>(null);

  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();
  const {
    data,
    isLoading: isLoadingExternalDns,
    refetch: refetchExternalDnsData,
    isError: isExternalDnsFetchError,
  } = useGetAddonLiveStateList({
    addon: Addon.ExternalDns,
    entity: Entity.ExternalDnsOperator,
    getEntityListRequest: {
      filter: [
        {
          name: "serviceName",
          operator: "eq",
          value: [resource.name],
        },
        {
          name: "clusterName",
          operator: "eq",
          value: [resource.cluster],
        },
        {
          name: "namespace",
          operator: "eq",
          value: [resource.namespace],
        },
      ],
    },
  });
  const externalDns = useMemo(() => {
    // eslint-disable-next-line new-cap
    return ExternalDnsRecordFromJSONTyped(data?.data?.data[0], false);
  }, [data?.data?.data]);

  const {
    data: managedRecords,
    refetch: refetchManagedRecords,
    isFetching: isLoadingManagedRecords,
  } = useGetAddonFullLiveState(
    {
      addon: Addon.ExternalDns,
      entity: Entity.ExternalDnsOperator,
      uid: resource.komodorUid,
      name: resource.name,
      getEntityRequest: {
        clusterName: resource.cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: ExternalDnsRecordFullFromJSON(json?.data?.data),
    })
  );

  // This is a workaround to prevent the issue of the managedRecords data being updated due to setting a refetchInterval in the external DNS list.
  const managedRecordsData = useMemo(() => {
    if (isLoadingManagedRecords) {
      return [];
    }
    return cloneDeep(managedRecords?.data.managedRecords ?? []);
  }, [isLoadingManagedRecords, managedRecords]);

  const [agentUpgradeRequired, isLoadingAgent] = useNeedUpgradeAgent(
    resource.agentId,
    resource.cluster,
    doesAgentVersionSupportExternalDNSStatus
  );

  useEffect(() => {
    reportLoadingState("externalDnsManagedRecords", isLoadingManagedRecords);
  }, [isLoadingManagedRecords, reportLoadingState]);

  useEffect(() => {
    reportLoadingState("externalDnsSummaryData", isLoadingExternalDns);
  }, [isLoadingExternalDns, reportLoadingState]);
  if (!resource.id || isLoadingExternalDns || isLoadingAgent) {
    return <DescribeLoader />;
  }

  if (isExternalDnsFetchError) {
    return (
      <Box
        sx={{
          width: "100%",
          paddingBlock: "60px",
        }}
      >
        <ErrorTableResults
          message={"We were unable to get the External-DNS data"}
          onRetry={refetchExternalDnsData}
        />
      </Box>
    );
  }
  setMainContainerDivRef(divRef);

  return (
    <div ref={divRef}>
      {!agentUpgradeRequired &&
        externalDns.syncStatus !== ExternalDnsRecordSyncStatusEnum.Synced && (
          <RootCauseAnalysisSection resource={resource} isHealthy={false} />
        )}
      <StatusSection externalDns={externalDns} />
      <SettingsSection externalDns={externalDns} />
      <ManagedRecordsSection
        isLoadingManagedRecords={isLoadingManagedRecords}
        managedRecords={managedRecordsData}
        refetchManagedRecords={refetchManagedRecords}
        isErrorFetchingManagedRecords={
          managedRecords?.data.failedFetchingManagedRecords ?? false
        }
      />
    </div>
  );
};

export const ExternalDNSSummaryTab: React.FC<{
  resource: Deployment | Rollout;
}> = (props) => {
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.ExternalDnsSummaryTab);

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <ExternalDNSSummaryTabContext {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
