import { useMemo } from "react";

import { useGetExternalLinks } from "../../../../../../shared/hooks/resources-api/client/metadata/useGetExternalLinks";

import { getLinkIcon } from "./utils"; // [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ExternalLinkProps, Link } from "./useKomodorAnnotations";

export const useExternalLinks = (
  k8sClusterName?: string,
  serviceId?: string,
  namespace?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): Link[] => {
  const externalLinksApiVariables = useMemo(() => {
    if (!k8sClusterName && !serviceId && !namespace) {
      return {};
    }
    return {
      clusterName: k8sClusterName,
      serviceId: serviceId,
      entireAccount: true,
      namespace: namespace,
    };
  }, [k8sClusterName, serviceId, namespace]);

  const { data } = useGetExternalLinks(externalLinksApiVariables, {
    enabled: Object.keys(externalLinksApiVariables).length > 0,
    refetchInterval: 5000,
  });

  return (
    data?.data?.map((e) => ({
      icon: getLinkIcon(e.link ?? "", e.displayName ?? ""),
      text: e.displayName ?? "",
      url: e.link ?? "",
      externalLinkProps: {
        linkId: e.id,
        externalLinkContext: e.externalLinkContextValue,
        serviceId: e.serviceId,
        k8sClusterName: e.clusterName,
        namespace: e.namespace,
      } as ExternalLinkProps,
    })) ?? []
  );
};
