/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Input,
  TabPanel,
  Tabs,
} from "@komodorio/design-system/deprecated";
import { Statement, PolicyTags } from "komodor-types";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Form, FormError } from "../../styles";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import VisualEditor from "./VisualEditor";
import { validateJsonStatements, validateStatements } from "./policySchema";
import { PreservedHeight } from "./common";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DynamicTagsPolicyEditFields } from "./dynamicTagsPolicyEditorFields";

import { useFormValidations } from "@/shared/context/ValidationsProvider";
import { dispatchEvent } from "@/shared/hooks/analytics";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import { RbacPolicy, RbacPolicyType } from "@/generated/auth";
import { useCreateRbacPolicy } from "@/shared/hooks/auth-service/client/rbacPolicies/useCreateRbacPolicy";
import { useUpdateRbacPolicy } from "@/shared/hooks/auth-service/client/rbacPolicies/useUpdateRbacPolicy";
import { LazyEditor } from "@/components/common/LazyEditor";

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export type PolicyForm = {
  name: string;
  type: RbacPolicyType;
  tags: PolicyTags;
  statements: Statement[];
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const defaultStatements: Statement[] = [
  {
    actions: [],
    resources: [
      {
        cluster: "",
        namespaces: [],
      },
    ],
  },
];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const defaultStatementsNamespacePattern: Statement[] = [
  {
    actions: [],
    resources: [
      {
        cluster: "",
        namespacePattern: "",
      },
    ],
  },
];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const defaultStatementsDynamicTags: Statement[] = [
  {
    actions: [],
    resources: [],
  },
];

const defaultValue: PolicyForm = {
  name: "",
  type: RbacPolicyType.Static,
  tags: {},
  statements: defaultStatements,
};

const validatePolicyName = (name: string | undefined) =>
  !name ? "Please fill policy name" : undefined;

const getTabs = (isValid: boolean) => [
  { label: "Visual Editor", disabled: !isValid },
  { label: "JSON" },
];

const PolicyFormModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  policy?: RbacPolicy;
  refreshPolicies: () => void;
  readOnly?: boolean;
}> = ({ open, handleClose, policy, refreshPolicies, readOnly }) => {
  const [value, setValue] = useState<PolicyForm>(defaultValue);
  const [stringStatements, setStringStatements] = useState(
    JSON.stringify(defaultStatements, null, 2)
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const { errors, setError, deleteError } = useFormValidations();

  useEffect(() => {
    if (policy) {
      setValue({
        name: policy.name,
        type: policy.type,
        tags: policy.tags,
        statements: policy.statements as Statement[],
      });
      setStringStatements(JSON.stringify(policy.statements, null, 2));
    }
  }, [policy]);

  const { callback: debouncedValidateAndParseJson } = useDebouncedCallback(
    (v) => {
      const [error, parsedJson] = validateJsonStatements(v, value.type);
      setError("json", error);
      if (parsedJson) {
        setValue({ ...value, statements: parsedJson });
      }
    },
    750
  );

  const { mutateAsync: addPolicy, isLoading: addLoading } =
    useCreateRbacPolicy();
  const { mutateAsync: editPolicy, isLoading: editLoading } =
    useUpdateRbacPolicy();

  const handleSave = async () => {
    const curTab = tabs[selectedTab].label;
    const { name, statements, type, tags } = value;
    const nameError = validatePolicyName(name);
    setError("name", nameError);
    // If editing a dynamic tags policy, we don't need to validate statements
    // It will still be validated on creation -- the `policy` object is the current object being edited
    if (curTab === "Visual Editor") {
      const statementsErrors = validateStatements(statements, type);
      statementsErrors.forEach((e) => {
        if (e.path.length === 0) {
          setError("policy", `Policy ${e.message}`);
          return;
        }
        setError(e.property, `Please select ${e.path.at(-1)}`);
      });
      if (statementsErrors.length || nameError) {
        return;
      }
    } else {
      const [jsonError] = validateJsonStatements(stringStatements, type);
      setError("json", jsonError);
      if (jsonError || nameError) {
        return;
      }
    }
    let result;
    try {
      if (policy) {
        result = await editPolicy({
          id: policy.id,
          updateRbacPolicyRequest: {
            name: name.trim(),
            statements,
            type,
            tags,
          },
        });
      } else {
        result = await addPolicy({ name: name.trim(), statements, type, tags });
      }
    } catch (e) {
      setError(
        "general",
        "Encountered an issue while saving the policy. Please retry or contact support for assistance."
      );
      return;
    }
    dispatchEvent(
      policy
        ? AnalyticEvents.Settings.EditPolicy
        : AnalyticEvents.Settings.AddPolicy,
      {
        policyName: result.name,
        editorType: curTab,
      }
    );
    refreshPolicies();
    handleClose();
  };

  const tabs = getTabs(!errors["json"]);
  const handleTabChange = (tabIndex: number) => {
    if (tabs[tabIndex].label === "JSON") {
      setStringStatements(JSON.stringify(value.statements, null, 2));
    } else {
      deleteError((k) => k.startsWith(`instance`));
    }
    deleteError("general");
    setSelectedTab(tabIndex);
  };

  const loading = editLoading || addLoading;

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason !== "backdropClick" && handleClose()}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          height: "80vh",
        },
      }}
      sx={{
        borderRadius: "16px",
        "&& .MuiDialogContent-root": {
          paddingTop: "20px",
        },
      }}
    >
      <DialogTitle variant="h3" sx={{ paddingInline: "32px" }}>
        {!readOnly && (policy ? "Edit" : "Add")} Policy
        {readOnly ? " (read only)" : null}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingInline: "32px",
          paddingBottom: "20px",
        }}
      >
        <Box sx={{ marginBottom: "16px" }}>
          <PreservedHeight>
            <Input
              size="medium"
              label="Policy name"
              value={value.name}
              onChange={(e) => {
                setValue({ ...value, name: e.target.value });
                setError("name", validatePolicyName(e.target.value));
              }}
              errorMessage={errors["name"]}
              disabled={readOnly}
            />
          </PreservedHeight>
          <DynamicTagsPolicyEditFields
            value={value}
            policy={policy}
            setValue={setValue}
            errors={errors}
            setError={setError}
          />
        </Box>
        <Form>
          <div>
            <Tabs tabs={tabs} value={selectedTab} onChange={handleTabChange} />
            <Divider />
          </div>
          <TabPanel value={selectedTab} index={0}>
            <EditorContainer>
              <VisualEditor
                value={value.statements}
                onChange={async (v) => setValue({ ...value, statements: v })}
                readOnly={readOnly}
                policyType={value.type}
              />
              {Object.entries(errors || {}).map(([, error], index) => (
                <FormError key={error ?? index} errMsg={error} isBoxStyle />
              ))}
            </EditorContainer>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <EditorContainer>
              <LazyEditor
                width="100%"
                height="40vh"
                mode="json"
                value={stringStatements}
                onChange={(v) => {
                  setStringStatements(v);
                  debouncedValidateAndParseJson(v);
                }}
                readOnly={
                  readOnly ||
                  value.type === RbacPolicyType.DynamicTag ||
                  value.type === RbacPolicyType.Wildcard
                }
              />
              <FormError
                errMsg={errors["json"] || errors["general"]}
                isBoxStyle
              />
            </EditorContainer>
          </TabPanel>
        </Form>
      </DialogContent>
      <DialogActions style={{ alignSelf: "flex-end" }}>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={loading || readOnly}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PolicyFormModal;
