import Typography from "@mui/material/Typography";
import React from "react";
import { parseISO } from "date-fns";
import Stack from "@mui/material/Stack";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { SyncStatus } from "@/components/k8sAddons/addons/externalDns/SyncStatus";
import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { AccordionContentItemContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { ExternalDnsRecord } from "@/generated/addonsApi";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import { AgentUpgradeRequiredWrapper } from "@/components/k8sAddons/addons/externalDns/AgentUpgradeRequiredWrapper";
import { EMPTY_FIELD } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/constants";

export const StatusSection: React.FC<{
  externalDns: ExternalDnsRecord;
}> = ({ externalDns }) => {
  const { format } = useDateFormatter();
  const {
    syncStatus,
    lastAttemptSyncTime,
    lastSuccessfulSyncTime,
    clusterName,
  } = externalDns;

  return (
    <AccordionWrapper
      title={"Status"}
      accordionContent={
        <>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Status
            </Typography>
            <AgentUpgradeRequiredWrapper cluster={clusterName}>
              <SyncStatus status={syncStatus} />
            </AgentUpgradeRequiredWrapper>
          </AccordionContentItemContainer>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Last sync attempt
            </Typography>
            <AgentUpgradeRequiredWrapper cluster={clusterName}>
              <Typography variant="body2">
                {lastAttemptSyncTime ? (
                  format(parseISO(lastAttemptSyncTime))
                ) : (
                  <Stack flexDirection="row" gap="4px">
                    {EMPTY_FIELD}
                    <LightTooltip
                      placement="top"
                      slotProps={{
                        tooltip: { sx: { maxWidth: "350px" } },
                      }}
                      title="This data is not available for External-DNS below version 12.2"
                    >
                      <InfoOutlined fontSize="small" color="action" />
                    </LightTooltip>
                  </Stack>
                )}
              </Typography>
            </AgentUpgradeRequiredWrapper>
          </AccordionContentItemContainer>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Last successful sync
            </Typography>
            <AgentUpgradeRequiredWrapper cluster={clusterName}>
              <Typography variant="body2">
                {lastSuccessfulSyncTime
                  ? format(parseISO(lastSuccessfulSyncTime))
                  : EMPTY_FIELD}
              </Typography>
            </AgentUpgradeRequiredWrapper>
          </AccordionContentItemContainer>
        </>
      }
      ariaLabel={externalDnsListPageAriaLabels.drawer.sections.status}
    />
  );
};
