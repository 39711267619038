import { useMemo } from "react";
import { compact } from "lodash";

import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { CONFIGURATION_HPAS_EVENT_SEARCH } from "../../../../../../../shared/hooks/resources-api/requestResponseMaps";
import { useResourcesAPIGet } from "../../../../../../../shared/hooks/resources-api/client";
import { buildKomodorUid } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { HpaEventsResponseDataInner } from "../../../../../../../generated/resourcesApi";

export const useHpasEventsFetch = (
  hpaUids: string[] | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [HpaEventsResponseDataInner[] | undefined, boolean] => {
  const hpaNames = compact(hpaUids?.map((hpaUid) => hpaUid.split(";").at(-1)));

  const uids = useMemo(
    () =>
      hpaNames.map((resourceName) =>
        buildKomodorUid({
          resourceName,
          clusterName: clusterName ?? "",
          namespace: namespace ?? "",
          kind: "HorizontalPodAutoscaler",
        })
      ),
    [clusterName, hpaNames, namespace]
  );

  const resultResourceApi = useResourcesAPIGet(
    CONFIGURATION_HPAS_EVENT_SEARCH,
    {
      fromEpoch: timeWindow?.start.toISOString(),
      toEpoch: timeWindow?.end.toISOString(),
      komodorUids: uids,
      fields: [
        "id",
        "name",
        "namespace",
        "clusterName",
        "eventTime",
        "services",
        "action",
        "oldDesiredReplicas",
        "newDesiredReplicas",
        "minReplicas",
        "maxReplicas",
      ],
    },
    !hpaUids?.length
  );

  return [resultResourceApi?.data?.data, resultResourceApi.loading];
};
