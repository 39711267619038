import React, { useState } from "react";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import { useVirtualizer, VirtualizerOptions } from "@tanstack/react-virtual";
import { selectAriaLabels } from "components/komodor-ui/Select/shared/ariaLabels";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "components/komodor-ui/Select/shared/types";
import { SelectItem } from "components/komodor-ui/Select/SingleSelectPopover/SingleSelectItem";

const DEFAULT_OPTION_HEIGHT = 33;

interface VirtualizedMenuListProps<T extends MuiSelectionOptionValue> {
  options: MuiSelectionOption<T>[];
  selectedOption: MuiSelectionOption<T> | undefined;
  onItemSelect: (value: T) => void;
  className?: string;
  classes?: {
    menuItem?: string;
  };
  customOptionElement?: (option: MuiSelectionOption<T>) => React.ReactNode;
  menuListTrailingElement?: React.ReactNode;
  maxHeight?: string;
  virtualizerProps?: Partial<VirtualizerOptions<Element, Element>>;
}
export const VirtualizedMenuList = <T extends MuiSelectionOptionValue>({
  options,
  selectedOption,
  onItemSelect,
  className,
  classes,
  customOptionElement,
  menuListTrailingElement,
  maxHeight,
  virtualizerProps,
}: VirtualizedMenuListProps<T>) => {
  const [menuListRef, setMenuListRef] = useState<HTMLUListElement | null>(null);
  const virtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => menuListRef,
    estimateSize: () => DEFAULT_OPTION_HEIGHT,
    ...virtualizerProps,
  });

  const dropdownOptions = virtualizer.getVirtualItems().map((value) => {
    const option = options[value.index];
    return (
      <Box
        key={value.key}
        data-index={value.index}
        sx={{
          position: "absolute",
          width: "100%",
          height: `${value.size}px`,
          transform: `translateY(${value.start}px)`,
        }}
      >
        <SelectItem
          className={classes.menuItem}
          option={option}
          selectedOption={selectedOption}
          onSelect={onItemSelect}
          customOptionElement={customOptionElement}
        />
      </Box>
    );
  });

  return (
    <MenuList
      ref={(node) => {
        if (node && node !== menuListRef) {
          setMenuListRef(node);
        }
      }}
      className={className}
      aria-label={selectAriaLabels.menuList}
      sx={{
        position: "relative",
        overflowY: "auto",
        maxHeight,
      }}
    >
      <Box
        sx={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {dropdownOptions}
      </Box>
      {menuListTrailingElement}
    </MenuList>
  );
};
