import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ActionButtonProps } from "./types";

import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { DRIFT_ANALYSIS_ROUTE } from "@/components/routes/routes";
import {
  BASELINE_SERVICE_OPTION_KEY,
  COMPARED_SERVICES_OPTION_KEY,
} from "@/components/Drift/Analysis/constants";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { initialServicesComparisonState } from "@/components/Drift/Analysis/context/initialState";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { closeAllDrawersSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { AriaLabels } from "@/shared/config/ariaLabels";

export const DetectDriftButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
}) => {
  const { driftDetection } = useOverridableFlags();
  const navigate = useNavigate();
  const closeAllDrawers = useDrawersStackStore(closeAllDrawersSelector);

  const baselineServiceOption = useMemo(
    () => ({
      label: resource.name,
      value:
        buildKomodorUid({
          kind: resource.kind,
          namespace: resource.namespace,
          resourceName: resource.name,
          clusterName: resource.cluster,
        }) ?? "",
    }),
    [resource.cluster, resource.kind, resource.name, resource.namespace]
  );

  const searchParams = new URLSearchParams({
    [BASELINE_SERVICE_OPTION_KEY]: JSON.stringify(baselineServiceOption),
    [COMPARED_SERVICES_OPTION_KEY]: JSON.stringify(
      initialServicesComparisonState.comparedServicesIds
    ),
  });

  return driftDetection ? (
    <Button
      ariaLabel={AriaLabels.DriftAnalysis.DetectDriftActionButton}
      disabled={false}
      onClick={() => {
        closeAllDrawers();
        navigate(
          {
            pathname: DRIFT_ANALYSIS_ROUTE,
            search: searchParams.toString(),
          },
          { replace: true }
        );
      }}
    />
  ) : null;
};
