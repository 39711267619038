import { MagicAi24 } from "@komodorio/design-system/icons";
import { muiColors, muiTheme } from "@komodorio/design-system";
import { useMemo } from "react";
import Alert from "@mui/material/Alert";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";

import Resource from "../../resources";
import { useResourceAiInvestigation } from "../common/useResourceAiInvestigation";
import { InvestigationContent } from "../common/InvestigationContent";
import { InvestigationTitle } from "../common/InvestigationTitle";

import EventDetailsSection from "@/components/common/ProcessList/details/components/EventDetailsSection";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import type JobEventGroup from "@/components/common/EventGroup/jobEvent/JobEventGroup";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { MonitorType } from "@/generated/monitorsApi";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";
import { useWorkflowRunById } from "@/components/common/EventGroup/workflowIssues/useWorkflowRunById";

export const InvestigationSection: React.FC<{
  resource: Resource;
  eventGroup: JobEventGroup;
}> = ({ resource, eventGroup }) => {
  const { klaudiaAiJobRun } = useOverridableFlags();
  const lastEvent = eventGroup.events[eventGroup.events.length - 1];

  const jobResource = useMemo(
    () => ({
      ...resource,
      kind: "Job",
      name: eventGroup.name,
      namespace: resource.namespace,
      cluster: resource.cluster,
    }),
    [eventGroup.name, resource]
  );

  const {
    enabled,
    richSession,
    isError,
    isLoading,
    onRetryCallback,
    onShareClicked,
  } = useResourceAiInvestigation({
    drawerData: {
      drawerType: DrawerType.EventDrawerById,
      eventIds: eventGroup.events.map((event) => event.id),
      eventType: MonitorType.Job,
      serviceId: eventGroup.serviceId,
    },
    requestId: eventGroup.jobUid,
    resource: jobResource,
    isHealthy: !eventGroup.isFailed,
    flowType: FlowType.FailedJobRun,
  });

  const { data: wfRun } = useWorkflowRunById(lastEvent.id, MonitorType.Job);

  const investigationContents = useMemo(() => {
    if (wfRun?.workflowConfiguration?.isDeleted) {
      return (
        <Alert
          variant="outlined"
          severity="error"
          icon={<ErrorOutline fontSize="inherit" />}
          sx={{
            width: "395px",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color={muiTheme.palette.error.dark}>
            AI investigation cannot run on a deleted job.
          </Typography>
        </Alert>
      );
    }

    return (
      <InvestigationContent
        richSession={richSession}
        isError={isError}
        onRetryCallback={onRetryCallback}
        isLoading={isLoading}
        showBorder={false}
      />
    );
  }, [
    richSession,
    onRetryCallback,
    isError,
    isLoading,
    wfRun?.workflowConfiguration?.isDeleted,
  ]);

  if (!enabled || !klaudiaAiJobRun || (!eventGroup.isFailed && !richSession)) {
    return null;
  }

  return (
    <EventDetailsSection
      icon={() => <MagicAi24 color="#2A47FF" width={20} height={20} />}
      title={
        <InvestigationTitle
          showIcon={false}
          richSession={richSession}
          onShareClicked={onShareClicked}
          titleProps={{ variant: "h4", color: muiColors.gray[500] }}
        />
      }
    >
      {investigationContents}
    </EventDetailsSection>
  );
};
