import { ResourceTableModelRow } from "komodor-types";
import React, { useEffect, useMemo } from "react";

import ResourceListTable from "../../../../../../../common/ResourceListTable/ResourceListTable";
import { SelfSupplyingResourceListTableProps } from "../../types";
import { generateUid } from "../../../../../../../../shared/utils/generateUid";
import {
  getUnselectableRowNames,
  onlySelected,
} from "../utils/resourceListTablesUtils";
import { Status } from "../../../../../../../../shared/hooks/ATM/useAtmRequest";
import { useGetPodAtmResources } from "../../../../../../../../shared/hooks/ATM/useGetPodAtmResources";
import { useResourceListOfDeletedPods } from "../../../../../../../Inspection/historicalFetch/useResourceListFromDatabase";
import { useBuildKomodorUidForService } from "../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { filterResourceTableBySearchTerm } from "../../../../../../../Inspection/utils/filterResultsList";

import { podsListTableColumns } from "./podsListTableColumns";

import { useResourceView } from "@/components/ResourceView/ResourceProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type PodsListTableAtmProps = SelfSupplyingResourceListTableProps & {
  agentId: string;
  showDeletedPods?: boolean;
};

export const PodsListTableAtm: React.FC<PodsListTableAtmProps> = ({
  agentId,
  serviceId,
  cluster,
  namespace,
  rowSelection,
  eventsDetectionTimeframe,
  searchTerm,
  showDeletedPods = false,
  showOnlySelectedRows = false,
  ...props
}) => {
  const resource = useResourceView();

  const {
    result: livePodsQueryResponse,
    status: livePodsQueryStatus,
    refresh,
    errorMessage,
  } = useGetPodAtmResources({
    agentId,
    cluster,
    namespace,
    resource,
  });

  const livePodRows = (livePodsQueryResponse?.data ?? []).map(
    attachUid(cluster)
  );

  const livePodsLoading =
    livePodsQueryStatus === Status.Initializing ||
    (!!livePodsQueryResponse?.isPaginating && livePodRows.length === 0);

  const ownerKomodorUid = useBuildKomodorUidForService(serviceId ?? "");
  const params = useMemo(
    () => (ownerKomodorUid ? { ownerKomodorUid } : null),
    [ownerKomodorUid]
  );
  const { data: deletedPodEntries, fetching: fetchingDeletedPods } =
    useResourceListOfDeletedPods(
      eventsDetectionTimeframe,
      params,
      !showDeletedPods
    );

  const deletedPodRows = deletedPodEntries.map(attachUid(cluster));

  const rows = [...livePodRows, ...deletedPodRows];
  const filteredRows = filterResourceTableBySearchTerm(rows, searchTerm);
  const unselectableRowNames = getUnselectableRowNames(rows, rowSelection);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () =>
      reportLoadingState(
        "relatedResourcesPodsData",
        livePodsLoading || (showDeletedPods && fetchingDeletedPods)
      ),
    [fetchingDeletedPods, livePodsLoading, reportLoadingState, showDeletedPods]
  );
  return (
    <ResourceListTable
      cluster={cluster}
      rows={
        showOnlySelectedRows
          ? onlySelected(filteredRows, rowSelection?.selectedRowNames ?? [])
          : filteredRows
      }
      refreshResults={refresh}
      rowSelection={
        rowSelection ? { ...rowSelection, unselectableRowNames } : undefined
      }
      overridingColumns={podsListTableColumns}
      loadingProps={{
        isLoading: livePodsLoading || (showDeletedPods && fetchingDeletedPods),
      }}
      isPaginating={livePodsQueryResponse?.isPaginating}
      errorMessage={errorMessage}
      {...props}
    />
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const attachUid =
  (cluster: string) =>
  ({ name, namespace, ...row }: ResourceTableModelRow) => ({
    ...row,
    name,
    namespace,
    cluster,
    uid: generateUid({ name, namespace, cluster }),
  });
