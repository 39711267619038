import { useMemo } from "react";

import { TimelineChartConfig } from "@/components/common/EventsChart/TimelineChart/useTimelineChartConfig";

const X_AXIS_HEIGHT = 48;
const PAGINATION_BAR_HEIGHT = 53;

export const useDynamicChartHeight = (
  numOfSwimlanes: number,
  shouldShowPagination: boolean,
  hideXAxis: boolean,
  chartConfig: TimelineChartConfig
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  return useMemo(() => {
    let height = numOfSwimlanes * chartConfig.swimlaneHeight;
    if (shouldShowPagination) {
      height += PAGINATION_BAR_HEIGHT;
    }
    if (!hideXAxis) {
      height += X_AXIS_HEIGHT;
    }
    return height + "px";
  }, [
    chartConfig.swimlaneHeight,
    hideXAxis,
    numOfSwimlanes,
    shouldShowPagination,
  ]);
};
