import { useMemo } from "react";

import {
  useAgentInfoById,
  AgentProperties,
} from "../../../shared/hooks/useAgentInfo/useAgentInfo";

export const useNeedUpgradeAgent = (
  agentId: string | undefined,
  cluster: string | undefined,
  agentSupportCheck: (agentProps: AgentProperties | null) => boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [boolean, boolean] => {
  const { agentProperties: agentInfo, fetching } = useAgentInfoById(
    agentId,
    cluster
  );
  return useMemo(() => {
    return [!agentSupportCheck(agentInfo), fetching];
  }, [agentInfo, agentSupportCheck, fetching]);
};
