import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { muiPalette } from "@komodorio/design-system";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useMemo, useState } from "react";
import { Metadata, SearchField } from "@komodorio/design-system/komodor-ui";

import {
  KomodorUidParams,
  parseKomodorUid,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ConnectedWorkloadsTable } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ConnectedWorkloadsCell/ConnectedWorkloadsTable";
import { useModal } from "@/shared/context/modal/useModal";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";

export const SEARCH_INPUT_PLACEHOLDER = "Workload name";

const {
  drawer: {
    sections: {
      managedRecords: {
        connectedWorkloadsModal: {
          whereSection: modalWhereSection,
          searchField,
        },
      },
    },
  },
} = externalDnsListPageAriaLabels;

export const ConnectedWorkloadsModal: React.FC<{
  host: string;
  source: string;
  workloadsUids: string[];
}> = ({ host, source, workloadsUids }) => {
  const { open, onClose } = useModal();
  const [searchTerm, setSearchTerm] = useState("");

  const {
    name = "",
    kind = "",
    namespace = "",
  } = parseKomodorUid(source) ?? {};

  const filteredParsedWorkloadsKomodorUids = useMemo<KomodorUidParams[]>(
    () =>
      workloadsUids
        .map((komodorUid) => parseKomodorUid(komodorUid))
        .filter((parsedKomodorUid): parsedKomodorUid is KomodorUidParams => {
          return (
            !!parsedKomodorUid &&
            parsedKomodorUid.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          );
        }),
    [workloadsUids, searchTerm]
  );

  return (
    <Dialog open={open} sx={{ height: "100%" }} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: `1px solid ${muiPalette.divider}`,
          alignItems: "center",
          padding: "16px 24px",
        }}
      >
        <Typography variant="h3">Connected Workloads</Typography>
        <IconButton onClick={onClose}>
          <Close color="action" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          flexDirection="row"
          gap="32px"
          sx={{ paddingBlock: "24px" }}
          aria-label={modalWhereSection}
        >
          <Metadata title="Host" value={host} />
          <Metadata title="Source" value={name} />
          <Metadata title="Source Kind" value={kind} />
          <Metadata title="Source Namespace" value={namespace} />
        </Stack>
        <Stack gap="16px">
          <SearchField
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            value={searchTerm}
            onSearch={setSearchTerm}
            ariaLabel={searchField}
          />
          <Stack gap="8px">
            <Typography variant="body3" color="text.secondary">
              Showing {filteredParsedWorkloadsKomodorUids.length} workloads
            </Typography>
            <ConnectedWorkloadsTable
              workloadsKomodorUids={filteredParsedWorkloadsKomodorUids}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "16px 24px",
          borderTop: `1px solid ${muiPalette.divider}`,
        }}
      >
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
