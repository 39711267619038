import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Label } from "../common/styles";
import Select from "../../../common/controls/Select";

import {
  ConfigurationVariables,
  ConfigurationVariablesCronJobConditionEnum,
} from "@/generated/monitorsApi";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const WithPadding = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const FailedJobText = (
  <>
    <b>failed</b> job
  </>
);

const CronJobTriggerConditionsSection: React.FC<{
  variables: ConfigurationVariables | undefined;
  handleChange: (variables: ConfigurationVariables) => void;
}> = ({ variables, handleChange }) => {
  const [condition, setCondition] = useState<string>(
    ConfigurationVariablesCronJobConditionEnum.First
  );

  const options = Object.entries(
    ConfigurationVariablesCronJobConditionEnum
  ).map(([value, label]) => ({
    label,
    value,
  }));

  useEffect(() => {
    if (variables?.cronJobCondition) {
      setCondition(
        variables.cronJobCondition ??
          ConfigurationVariablesCronJobConditionEnum.First
      );
    }
  }, [condition, handleChange, variables]);

  const handleConditionChange = (
    value: ConfigurationVariablesCronJobConditionEnum
  ) => {
    setCondition(value);
    handleChange({ ...variables, cronJobCondition: value });
  };

  return (
    <>
      <Container>
        <Label>Alert on</Label>
        <WithPadding>
          <Select
            onChange={(e) => {
              handleConditionChange(
                e.target.value as ConfigurationVariablesCronJobConditionEnum
              );
            }}
            value={condition}
          >
            {options.map(({ label, value }) => (
              <option key={value} value={label}>
                {label}
              </option>
            ))}
          </Select>
        </WithPadding>
        <Label>{FailedJobText}</Label>
      </Container>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CronJobTriggerConditionsSection;
