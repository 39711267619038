import React, { useMemo } from "react";
import ReactDiffViewer from "react-diff-viewer";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { diff, flattenChangeset } from "json-diff-ts";

import { getManifestTitle } from "../ManifestsTab";
import { diffStyles } from "../../../../common/ObjectDiff";
import { ManifestsMap } from "../../utils";

import { EmptyState } from "./EmptyData";

const GenericManifestsCompare: React.FC<{
  oldValue: ManifestsMap;
  newValue: ManifestsMap;
  showOnlyChanges: boolean;
  oldTitle: string;
  newTitle: string;
}> = ({ oldValue, newValue, showOnlyChanges, oldTitle, newTitle }) => {
  const oldValueArray = useMemo(
    () => Array.from(oldValue.entries()),
    [oldValue]
  );

  const manifestsEntries = useMemo(
    () =>
      oldValueArray.filter(([resourceKey, manifestValues]) => {
        const changes = flattenChangeset(
          diff(manifestValues.manifest, newValue.get(resourceKey)?.manifest)
        );
        return showOnlyChanges ? changes.length > 0 : true;
      }),
    [newValue, oldValueArray, showOnlyChanges]
  );

  const diffsArray = useMemo(() => {
    return manifestsEntries.length ? (
      manifestsEntries.map(([resourceKey, manifestValues], index) => (
        <div key={resourceKey} id={resourceKey}>
          <br />
          <ReactDiffViewer
            newValue={newValue.get(resourceKey)?.manifest}
            oldValue={manifestValues.manifest}
            rightTitle={
              <>
                <Typography
                  variant="title"
                  size="medium"
                  color={palette.gray[800]}
                >
                  {getManifestTitle(
                    newValue.get(resourceKey)?.name,
                    newValue.get(resourceKey)?.kind
                  )}
                </Typography>
              </>
            }
            leftTitle={
              <>
                <Typography
                  variant="title"
                  size="medium"
                  color={palette.gray[800]}
                >
                  {getManifestTitle(
                    oldValue.get(resourceKey)?.name,
                    oldValue.get(resourceKey)?.kind
                  )}
                </Typography>
              </>
            }
            splitView
            disableWordDiff
            showDiffOnly={showOnlyChanges}
            styles={diffStyles}
          />
        </div>
      ))
    ) : (
      <EmptyState text={"There are no changes to show"} />
    );
  }, [manifestsEntries, newValue, oldValue, showOnlyChanges]);

  const restOfComparableManifests = useMemo(() => {
    const restKeys = Array.from(oldValue.keys()).filter(
      (k) => !newValue.has(k)
    );
    return new Map(restKeys.map((key) => [key, oldValue.get(key)]));
  }, [newValue, oldValue]);

  return (
    <>
      {diffsArray}
      {Array.from(restOfComparableManifests.entries()).map(
        ([, restManifestValues]) => (
          <ReactDiffViewer
            key={restManifestValues?.name}
            oldValue={restManifestValues?.manifest}
            newValue={undefined}
            splitView
            disableWordDiff
            showDiffOnly={showOnlyChanges}
            rightTitle={getManifestTitle(
              restManifestValues?.name,
              restManifestValues?.kind
            )}
          />
        )
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default GenericManifestsCompare;
