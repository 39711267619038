import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Input, Typography } from "@komodorio/design-system/deprecated";
import { MagnifyingGlass16 } from "@komodorio/design-system/icons";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import HelmChart from "../../helmChart";
import { ResourceDrawerByData } from "../../../../ResourceView/ResourceDrawer";
import { SupportedKubernetesResources } from "../../../inspectionConfiguration/SupportedKubernetesResources";
import { HelmTabContainer } from "../../drawers/styles";
import { LinesLoader } from "../../../../common/loaders/Line";
import { EmptyState } from "../components/EmptyData";
import { KubernetesClusterRolesResource } from "../../../inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRolesResource";
import { KubernetesRoleBindingsResource } from "../../../inspectionConfiguration/supportedResourcesTypes/KubernetesRoleBindingsResource";
import { KubernetesRolesResource } from "../../../inspectionConfiguration/supportedResourcesTypes/KubernetesRolesResource";
import { KubernetesClusterRoleBindingsResource } from "../../../inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRoleBindingsResource";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceRow } from "./ResourceRow";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { ViewOptions } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";

export interface ShowResourceColumns {
  type: boolean;
  name: boolean;
  statusMessage: boolean;
  status: boolean;
}

interface ResourcesTabProps {
  resource: HelmChart;
  showColumns?: ShowResourceColumns;
  tableHeader?: JSX.Element;
}
export interface GenericResource {
  kind: string;
  metadata: {
    name: string;
    namespace?: string;
  };
  data?: Record<string, unknown>;
}

const Table = styled.table`
  width: 100%;
  border-spacing: 0 0.5rem;
  > tbody > tr > td {
    padding: 0.7rem;
  }
`;

const StyledTh = styled.th`
  padding: 0 0.75rem;
  text-align: start;
  white-space: nowrap;
`;

const ResourcesKindWithoutNamespace = [
  KubernetesClusterRolesResource.Kind,
  KubernetesRoleBindingsResource.Kind,
  KubernetesRolesResource.Kind,
  KubernetesClusterRoleBindingsResource.Kind,
];

const datadogViewOptions: ViewOptions = {
  name: DatadogViewNamesEnum.helmDrawerResourcesTab,
  context: {
    feTeam: "operate",
    beTeam: "operate",
  },
};

export const ResourcesTab: React.FC<ResourcesTabProps> = (props) => {
  return (
    <DatadogReportLoadingTimeContextProvider viewOptions={datadogViewOptions}>
      <ResourcesTabConsumer {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};

export const ResourcesTabConsumer: React.FC<ResourcesTabProps> = ({
  resource,
  showColumns = { type: true, name: true, statusMessage: true, status: true },
  tableHeader,
}) => {
  const [selectedResource, setSelectedResource] = useState<
    GenericResource | undefined
  >();

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  const onRowLoadingStateChange = useCallback(
    (index: number, isLoading: boolean) => {
      if (showColumns.status) {
        reportLoadingState(index.toString(), isLoading);
      }
    },
    [reportLoadingState, showColumns.status]
  );

  const onResourceClick = useCallback((k8sResource: GenericResource) => {
    const supportedResource = SupportedKubernetesResources.find(
      (r) => r.Kind === k8sResource.kind
    )
      ? k8sResource
      : undefined;
    setSelectedResource(supportedResource);
  }, []);

  const getResourceNamespace = (resource: GenericResource) => {
    return ResourcesKindWithoutNamespace.includes(resource.kind)
      ? ""
      : resource?.metadata?.namespace ?? "";
  };

  const [search, setSearch] = useState("");
  const filteredResources = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    const resources = resource.parsedManifests as GenericResource[] | undefined;
    if (resources) {
      return resources.filter((r) => {
        return [r?.metadata?.name ?? "", r?.kind ?? ""].some((s) => {
          return s.toLowerCase().includes(lowerCaseFilter);
        });
      });
    }
    return;
  }, [resource.parsedManifests, search]);

  return (
    <HelmTabContainer>
      <Input
        size="medium"
        width="20rem"
        placeholder="Search for resource type or name..."
        value={search}
        icon={MagnifyingGlass16}
        onChange={(e) => setSearch(e.target.value)}
      />
      {!filteredResources ? (
        <LinesLoader />
      ) : !filteredResources.length ? (
        <EmptyState text={"There are no resources to show"} />
      ) : (
        <>
          {tableHeader}
          <Typography>
            <Table>
              <thead>
                <tr>
                  {showColumns?.type && <StyledTh>Type</StyledTh>}
                  {showColumns?.name && <StyledTh>Name</StyledTh>}
                  {showColumns?.status && <StyledTh>Status</StyledTh>}
                  {showColumns?.statusMessage && (
                    <StyledTh>Status Message</StyledTh>
                  )}
                  <StyledTh />
                </tr>
              </thead>
              <tbody>
                {filteredResources.map((k8sResource, index) => (
                  <ResourceRow
                    key={`${resource.currentRevision}-${k8sResource.metadata.name}-${index}`}
                    index={index}
                    k8sResource={k8sResource}
                    onClickCallback={() => onResourceClick(k8sResource)}
                    agentId={resource.agentId}
                    cluster={resource.cluster}
                    namespace={getResourceNamespace(k8sResource)}
                    showColumns={showColumns}
                    onLoadingStateChange={onRowLoadingStateChange}
                  />
                ))}
              </tbody>
            </Table>
          </Typography>
          {selectedResource && (
            <ResourceDrawerByData
              open
              resourceName={selectedResource.metadata?.name ?? ""}
              resourceType={selectedResource.kind ?? ""}
              cluster={resource.cluster}
              namespace={getResourceNamespace(selectedResource)}
              onClose={() => setSelectedResource(undefined)}
            />
          )}
        </>
      )}
    </HelmTabContainer>
  );
};
