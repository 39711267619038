import { get, capitalize } from "lodash";
import { palette } from "@komodorio/design-system";

import { ServiceStatus } from "../ResourceView/headers/KomodorServiceHealthAvailability";
import { JobState } from "../../shared/types/job";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NATIVE_POD_EVENT_TYPE } from "./EventGroup/NativePodEventGroup";
import { HpaStatusConditions } from "./enums";

export interface StatusGroup {
  fillColor: string;
  backgroundColor: string;
  severity?: number;
  outlineColor?: string;
}

export const StatusGroups: Record<
  "good" | "warning" | "error" | "neutral" | "pending",
  StatusGroup
> = {
  good: {
    fillColor: palette.green[900],
    backgroundColor: palette.green[100],
    severity: 3,
  },
  warning: {
    fillColor: palette.orange[800],
    backgroundColor: palette.yellow[200],
    severity: 1,
  },
  error: {
    fillColor: palette.pink[700],
    backgroundColor: palette.pink[100],
    severity: 0,
  },
  neutral: {
    fillColor: palette.gray[700],
    backgroundColor: palette.gray[100],
    severity: 4,
  },
  pending: {
    fillColor: palette.blue[900],
    backgroundColor: palette.blue[100],
    severity: 2,
  },
};

export const GoodPodStatuses: { [key: string]: string } = {
  Running: "Running",
  Completed: "Completed",
};

export const podStatusToGroups: { [key: string]: StatusGroup } = {
  Deleted: StatusGroups.neutral,
  Evicted: StatusGroups.error,
  Running: StatusGroups.good,
  Pending: StatusGroups.warning,
  ContainerStatusUnknown: StatusGroups.warning,
  ContainerCreating: StatusGroups.warning,
  Completed: StatusGroups.good,
  Unknown: StatusGroups.warning,
  PodInitializing: StatusGroups.warning,
  CrashLoopBackOff: StatusGroups.error,
  CreateContainerConfigError: StatusGroups.error,
  CreateContainerError: StatusGroups.error,
  ErrImagePull: StatusGroups.error,
  Error: StatusGroups.error,
  ImageInspectError: StatusGroups.error,
  ImagePullBackOff: StatusGroups.error,
  InvalidImageName: StatusGroups.error,
  OOMKilled: StatusGroups.error,
  RunContainerError: StatusGroups.error,
  StartError: StatusGroups.error,
  Terminating: StatusGroups.neutral,
  Terminated: StatusGroups.neutral,
  RunningHealthy: StatusGroups.good,
  RunningUnhealthy: StatusGroups.error,
  CompletedHealthy: StatusGroups.good,
  CompletedUnhealthy: StatusGroups.error,
};

const nodeStateGroup: { [key: string]: StatusGroup } = {
  Ready: StatusGroups.good,
  DiskPressure: StatusGroups.warning,
  MemoryPressure: StatusGroups.warning,
  PIDPressure: StatusGroups.warning,
  NetworkUnavailable: StatusGroups.error,
  NotReady: StatusGroups.error,
};

const pvAndPvcStateGroup: { [key: string]: StatusGroup } = {
  Available: StatusGroups.good,
  Bound: StatusGroups.good,
  Released: StatusGroups.neutral,
  Failed: StatusGroups.error,
  Pending: StatusGroups.warning,
};

const nativePodStatusGroup: { [key: string]: StatusGroup } = {
  Normal: StatusGroups.good,
  Warning: StatusGroups.warning,
  Error: StatusGroups.error,
};

const helmStatusGroup: { [key: string]: StatusGroup } = {
  deployed: StatusGroups.good,
  superseded: StatusGroups.neutral,
  unknown: StatusGroups.neutral,
  uninstalled: StatusGroups.neutral,
  uninstalling: StatusGroups.neutral,
  "pending-install": StatusGroups.pending,
  "pending-upgrade": StatusGroups.pending,
  "pending-rollback": StatusGroups.pending,
  failed: StatusGroups.error,
};

const booleanStatusGroup: { [key: string]: StatusGroup } = {
  True: StatusGroups.good,
  False: StatusGroups.error,
};

const oppositeBooleanStatusGroup: { [key: string]: StatusGroup } = {
  True: StatusGroups.error,
  False: StatusGroups.good,
};

const jobsStatusGroup: { [key in JobState]: StatusGroup } = {
  [JobState.completed]: StatusGroups.good,
  [JobState.failed]: StatusGroups.error,
  [JobState.resumed]: StatusGroups.pending,
  [JobState.inprogress]: StatusGroups.pending,
  [JobState.started]: StatusGroups.pending,
  [JobState.suspended]: StatusGroups.neutral,
  [JobState.deleted]: StatusGroups.neutral,
  [JobState.noRuns]: StatusGroups.neutral,
  [JobState.unknown]: StatusGroups.neutral,
};

const getPodsStatusGroup = (podStatus = "", podHealth = ""): StatusGroup => {
  if (Object.values(GoodPodStatuses).includes(podStatus) && podHealth) {
    podStatus = podStatus + capitalize(podHealth);
  }
  return get(podStatusToGroups, podStatus, podStatusToGroups.Unknown);
};

export const getHpaStatusGroupByStatusConditions = (
  status: string,
  value: string
): StatusGroup => {
  if (!value || !status) {
    return StatusGroups.neutral;
  }
  switch (status) {
    case HpaStatusConditions.ScalingActive:
      return booleanStatusGroup[value];
    case HpaStatusConditions.AbleToScale:
      return booleanStatusGroup[value];
    case HpaStatusConditions.ScalingLimited:
      return oppositeBooleanStatusGroup[value];
    default:
      return StatusGroups.neutral;
  }
};

export const getStatusGroup = (
  status = "",
  resourceNameInK8s: string,
  health = ""
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): StatusGroup => {
  const defaultGroup = StatusGroups.neutral;
  let statusGroup = defaultGroup;
  switch (resourceNameInK8s) {
    case NATIVE_POD_EVENT_TYPE:
      statusGroup = nativePodStatusGroup[status];
      break;
    case "pods":
      if (status.startsWith("Init:")) {
        status = status.substring(5);
      }
      statusGroup = getPodsStatusGroup(status, health);
      break;
    case "nodes":
      if (status.includes(",")) {
        // in case of multiple statuses color by the first status
        status = status.substring(0, status.indexOf(","));
      }
      statusGroup = nodeStateGroup[status];
      break;
    case "persistentvolumes":
      statusGroup = pvAndPvcStateGroup[status];
      break;
    case "persistentvolumeclaims":
      statusGroup = pvAndPvcStateGroup[status];
      break;
    case "helm":
      statusGroup = helmStatusGroup[status];
      break;
    case "jobs":
      statusGroup = jobsStatusGroup[status as JobState];
      break;
    case "horizontalpodautoscalers":
      statusGroup = booleanStatusGroup[status];
      break;
  }
  return statusGroup ?? defaultGroup;
};

export const getHealthStatusGroup = (healthStatus: string): StatusGroup => {
  switch (healthStatus) {
    case ServiceStatus.HEALTHY:
      return StatusGroups.good;
    case ServiceStatus.UNHEALTHY:
      return StatusGroups.error;
    default:
      return StatusGroups.neutral;
  }
};
