import React from "react";
import { muiTheme } from "../../../themes";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

export type LightTooltipProps = TooltipProps & { maxWidth?: string | number };

export const LightTooltip: React.FC<LightTooltipProps> = ({
  children,
  maxWidth,
  ...rest
}) => {
  return (
    <Tooltip
      {...rest}
      slotProps={{
        ...rest.slotProps,
        popper: {
          ...rest.slotProps?.popper,
          sx: {
            [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]: {
              boxShadow: muiTheme.shadows[1],
              backgroundColor: muiTheme.palette.common.white,
              color: muiTheme.palette.text.primary,
              maxWidth: maxWidth ?? null,
            },
            [`&.${tooltipClasses.popper} .${tooltipClasses.arrow}`]: {
              color: muiTheme.palette.common.white,
              "&:before": {
                border: `1px solid ${muiTheme.palette.grey[200]}`,
              },
            },
            ...rest.slotProps?.popper?.sx,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
