import { compact, isEqual } from "lodash";

import { CommonApiResponse } from "../../types/commonApiResponse";

import { cancelFetchState } from "./initialState";

export function roundToNearestSeconds(seconds: number): string {
  return roundToNearestSecondsAsDate(seconds).getTime().toString();
}

export function roundToNearestSecondsAsDate(seconds: number): Date {
  if (!Number.isInteger(seconds) || seconds <= 0) {
    throw new Error("Seconds must be a positive number");
  }
  const now = new Date();
  const roundedMs =
    Math.round(now.getTime() / (seconds * 1000)) * (seconds * 1000);
  return new Date(roundedMs);
}

export const createPathWithParameters = <T extends Record<string, unknown>>(
  relativePath: string,
  parameters: T,
  basePath: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): string => {
  let url = new URL(`${basePath}${relativePath}`);
  const pathParams = extractPathParams(relativePath);

  for (const key in parameters) {
    const value = parameters[key];
    if (Array.isArray(value)) {
      for (const item of value) {
        url.searchParams.append(key, item.toString());
      }
    }

    if (!isValid(value)) {
      continue;
    }
    url = addValueToUrl(key, value, url, pathParams);
  }
  return url.toString();
};

const extractPathParams = (relativePath: string): string[] => {
  const pathParamIndexes = extractPathParamsIndexes(relativePath);

  const pathParams = pathParamIndexes.map((index) => {
    const startIndex = index + 1;
    const endIndex = relativePath.indexOf("/", index);

    const pathParam =
      endIndex === -1
        ? relativePath.substring(startIndex)
        : relativePath.substring(startIndex, endIndex);
    return pathParam;
  });

  return pathParams;
};

const extractPathParamsIndexes = (relativePath: string): number[] => {
  const pathParamIndexes = relativePath.split("").map((c, i) => {
    if (c === ":") {
      return i;
    }
    return undefined;
  });

  return compact(pathParamIndexes);
};

type HasToString = {
  toString(): string;
};

const isValid = (value: unknown): boolean => {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
};

const addValueToUrl = (
  key: string,
  value: unknown,
  url: URL,
  pathParams: string[]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): URL => {
  const valueString = (value as HasToString).toString();

  if (pathParams.includes(key)) {
    url.pathname = url.pathname.replace(`:${key}`, valueString);
  } else {
    url.searchParams.set(key, valueString);
  }
  return url;
};

export const isInitialCancelFetchState = (res: CommonApiResponse<object>) => {
  const { loading, data, dataRequestParams, error } = res;
  return isEqual({ loading, data, dataRequestParams, error }, cancelFetchState);
};
