"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeprecatedIntegrationType = exports.IntegrationType = void 0;
var IntegrationType;
(function (IntegrationType) {
    IntegrationType["GITHUB"] = "github";
    IntegrationType["GITLAB"] = "gitlab";
    IntegrationType["PAGERDUTY"] = "pager-duty";
    IntegrationType["SLACK"] = "slack";
    IntegrationType["KUBERNETES"] = "kubernetes";
    IntegrationType["SENTRY"] = "sentry";
    IntegrationType["OPSGENIE"] = "opsgenie";
    IntegrationType["OPSGENIE_API"] = "opsgenie-api";
    IntegrationType["ARGOCD"] = "argocd";
    IntegrationType["DATADOG"] = "datadog";
    IntegrationType["NEWRELIC_ALERTS"] = "newrelic_alerts";
    IntegrationType["GRAFANA_ALERTS"] = "grafana-alerts";
    IntegrationType["JIRA"] = "jira";
    IntegrationType["PROMETHEUS_ALERT_MANAGER"] = "prometheus-alert-manager";
    IntegrationType["DESKTOP"] = "desktop";
    IntegrationType["CUSTOM_EVENTS"] = "custom-events";
})(IntegrationType = exports.IntegrationType || (exports.IntegrationType = {}));
// PROMETHEUS_METRICS_SERVER is deprecated and should not be used, BE code still uses it until we will decide to remove it entirely
exports.DeprecatedIntegrationType = {
    PROMETHEUS_METRICS_SERVER: "prometheus-metrics-server",
};
