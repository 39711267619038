import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { compact } from "lodash";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import { WorkflowDag, WorkflowRun } from "@/generated/addonsApi";
import { DateCell } from "@/components/k8sAddons/components/table/DateCell";
import { WorkflowStatus } from "@/components/k8sAddons/addons/workflows/WorkflowStatus";
import { DurationCell } from "@/components/k8sAddons/components/table/DurationCell";
import { WorkflowEngineIcon } from "@/components/k8sAddons/addons/workflows/WorkflowEngineIcon";
import { disableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";
import {
  WorkflowEngine,
  WorkflowEngineType,
} from "@/components/k8sAddons/addons/workflows/types";
import { generateRandRangeList } from "@/components/k8sAddons/utils/tableUtils";
import { useGenerateSkeleton } from "@/components/k8sAddons/hooks/table/useGenerateSkeleton";

export const columnsConfig = disableLocalSortingInTableColumns<WorkflowDag>({
  name: {
    field: "dagId",
    headerName: "Name",
    flex: 1,
  },
  workflowEngine: {
    field: "engine",
    headerName: "Workflow Engine",
    flex: 0.75,
  },
  cluster: {
    field: "clusterName",
    headerName: "Cluster",
    flex: 0.75,
  },
  namespace: {
    field: "namespace",
    headerName: "Namespace",
    flex: 0.75,
  },
  runs: {
    field: "runs",
    headerName: "Runs",
    flex: 0.5,
    width: 100,
  },
  started: {
    field: "lastRunStartedAt",
    headerName: "Started",
    flex: 1,
  },
  duration: {
    field: "lastRunDurationSec",
    headerName: "Duration",
    flex: 0.5,
  },
  issues: {
    field: "lastRunHasIssues",
    headerName: "Issues",
    flex: 0.5,
    width: 100,
  },
  status: {
    field: "lastRunStatus",
    headerName: "Status",
    flex: 0.5,
  },
});

export const useDagsTableColumns = ({
  engineType,
}: {
  engineType?: WorkflowEngine;
}): GridColDef<WorkflowDag>[] => {
  return useMemo(() => {
    return compact([
      columnsConfig.name,
      !engineType
        ? {
            ...columnsConfig.workflowEngine,
            renderCell: (params) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "8px",
                  }}
                >
                  <WorkflowEngineIcon
                    engineType={params.row.workflowEngine as WorkflowEngineType}
                  />
                  {params.row.workflowEngine}
                </Box>
              );
            },
          }
        : null,
      columnsConfig.cluster,
      columnsConfig.namespace,
      columnsConfig.runs,
      {
        ...columnsConfig.started,
        renderCell: (params) => {
          return (
            <DateCell
              dateString={params.row.lastRun.startedAt}
              overrideOptions={{ timeZoneName: undefined }}
            />
          );
        },
      },
      {
        ...columnsConfig.duration,
        renderCell: (params) => {
          return (
            <DurationCell durationSeconds={params.row.lastRun.durationSec} />
          );
        },
      },
      {
        ...columnsConfig.issues,
        renderCell: (params) => {
          return params.row.lastRun.hasIssues ? (
            <WarningAmberOutlined sx={{ fill: muiColors.pink[700] }} />
          ) : null;
        },
      },
      {
        ...columnsConfig.status,
        renderHeader: (params) => (
          <Box display="flex" columnGap="4px" alignItems="center">
            {params.colDef.headerName}
            <LightTooltip
              slotProps={{
                popper: { sx: { maxWidth: "160px" } },
              }}
              placement="top"
              title={`The status of the workflow updates every ${
                engineType === WorkflowEngine.Spark ? "3" : "10"
              } minutes.`}
            >
              <InfoOutlined fontSize="small" color="action" />
            </LightTooltip>
          </Box>
        ),
        renderCell: (params) => {
          return (
            <WorkflowStatus status={params.row.lastRun.status} size="small" />
          );
        },
      },
    ]);
  }, [engineType]);
};

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<
  Record<keyof WorkflowDag | keyof WorkflowRun, number[]>
> = {
  dagId: generateRandRangeList(50, 150),
  workflowEngine: generateRandRangeList(50, 150),
  clusterName: generateRandRangeList(50, 150),
  namespace: generateRandRangeList(50, 150),
  runs: generateRandRangeList(50, 150),
  startedAt: generateRandRangeList(50, 150),
  durationSec: generateRandRangeList(50, 150),
  hasIssues: generateRandRangeList(50, 150),
  status: generateRandRangeList(50, 150),
};

export const useDagsLoadingColumns = ({
  engineType,
}: {
  engineType?: WorkflowEngine;
}): GridColDef<WorkflowDag>[] => {
  const generateSkeleton = useGenerateSkeleton(randRangeColumns);
  return useMemo(
    () =>
      compact([
        {
          ...columnsConfig.name,
          renderCell: ({ id }) => generateSkeleton({ id, columnName: "dagId" }),
        },
        !engineType
          ? {
              ...columnsConfig.workflowEngine,
              renderCell: ({ id }) =>
                generateSkeleton({ id, columnName: "workflowEngine" }),
            }
          : null,
        {
          ...columnsConfig.cluster,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "clusterName" }),
        },
        {
          ...columnsConfig.namespace,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "namespace" }),
        },
        {
          ...columnsConfig.runs,
          renderCell: ({ id }) => generateSkeleton({ id, columnName: "runs" }),
        },
        {
          ...columnsConfig.started,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "startedAt" }),
        },
        {
          ...columnsConfig.duration,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "durationSec" }),
        },
        {
          ...columnsConfig.issues,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "hasIssues" }),
        },
        {
          ...columnsConfig.status,
          renderCell: ({ id }) =>
            generateSkeleton({
              id,
              columnName: "status",
              skeletonProps: { sx: { borderRadius: "16px" } },
            }),
        },
      ]),
    [engineType, generateSkeleton]
  );
};
