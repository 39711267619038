import { getAppConfig } from "../../config/appConfig";
import { useApiGetWithUrl, useAPIPost } from "../common-api/request";
import { CommonApiResponse } from "../../types/commonApiResponse";
import { EndpointRequestTypeNames } from "../common-api/types";

import {
  EndpointRequestPathMap,
  EndpointsRequestMap,
  EndpointsResponseMap,
} from "./requestResponseMaps";

/**
 * @deprecated use react-query instead.
 */
export const useKomodorCostAPIGet = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<EndpointsResponseMap[T]> => {
  let url = "";
  try {
    url = EndpointRequestPathMap[relativePath](
      parameters as never,
      getAPIBasePath()
    );
  } catch (err) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useApiGetWithUrl<EndpointsResponseMap[T]>(url, pause && !!url);
};

/**
 * @deprecated use react-query instead.
 */
export const useKomodorCostAPIPost = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  reqBody: EndpointsRequestMap[T],
  pause = false
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<EndpointsResponseMap[T]> => {
  let url = "";
  try {
    url = EndpointRequestPathMap[relativePath](
      relativePath as never,
      getAPIBasePath()
    ) as keyof EndpointsResponseMap;
  } catch (err) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useAPIPost<EndpointsResponseMap[T]>(
    url as EndpointRequestTypeNames,
    reqBody,
    pause && !!url,
    ""
  );
};

const getAPIBasePath = (): string => {
  return getAppConfig().komodorCostAPIServerURL ?? "";
};
