import { ImpactGroupIdentifier } from "../../../generated/reliabilityApi";

export const impactGroupIdentifierToText: Record<
  ImpactGroupIdentifier,
  { title: string }
> = {
  NodePressure: {
    title: "Node Pressure",
  },
  DegradedService: {
    title: "Degraded Service",
  },
  EOLDeprecationOutdated: {
    title: "Cluster Upgrades",
  },
  NodeTerminations: {
    title: "Node Terminations",
  },
  FlakyServices: {
    title: "Flaky Services",
  },
  BestPractices: {
    title: "Best Practices",
  },
  Addons: {
    title: "Addons Risks",
  },
  FailedWorkloads: {
    title: "Failed Workloads",
  },
  FailedInfrastructureResources: {
    title: "Failed Infrastructure Resources",
  },
  CascadingFailure: {
    title: "Cascading Failures",
  },
};
