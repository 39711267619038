import React from "react";

import {
  ColumnsDefinitions,
  TooltipTextCellProps,
} from "@/components/Settings/Users/EffectivePermissions/types";
import { TooltipTextCell } from "@/components/Settings/Users/components/TooltipTextCell";

export const TooltipTextCellWrapper: React.FC<TooltipTextCellProps> = (
  props
) => {
  const link = (props.colDef as ColumnsDefinitions).urlGetter?.(props.value);
  const accountFeature = (props.colDef as ColumnsDefinitions).accountFeature;
  return (
    <TooltipTextCell
      text={props.value as string}
      link={props.allowLinks ? link : undefined}
      accountFeature={accountFeature}
    />
  );
};
