import React from "react";
import { LightTooltip, Status } from "@komodorio/design-system/komodor-ui";
import { parseISO } from "date-fns";
import { startCase } from "lodash";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Kubernetes16 } from "@komodorio/design-system/icons";
import { muiTheme } from "@komodorio/design-system";

import { K8S_RESOURCE_TOOLTIP } from "@/pages/organization-settings/account/AuditPage/constants";

const defaultValueRendererContent = (value: string) => (
  <Typography variant="body2" noWrap>
    {startCase(value)}
  </Typography>
);

export const defaultValueRenderer = (value: string) => (
  <LightTooltip title={startCase(value)}>
    {defaultValueRendererContent(value)}
  </LightTooltip>
);
export const identityRenderer = (value: string) => (
  <LightTooltip title={value}>
    <Typography variant="body2" noWrap>
      {value}
    </Typography>
  </LightTooltip>
);
export const statusRenderer = (value: string) => (
  <Status label={value} color={value === "success" ? "success" : "error"} />
);

export const timezoneRenderer = (
  value: string,
  format?: (date: Date) => string
) => {
  if (!format) {
    throw new Error("format function is required");
  }
  return identityRenderer(format(parseISO(value ?? "")));
};

export const k8sResourceRenderer = (value: string) => {
  return (
    <LightTooltip title={K8S_RESOURCE_TOOLTIP} placement="right">
      <Stack direction="row" columnGap="4px" alignItems="center">
        <Kubernetes16 fill={muiTheme.palette.text.disabled} />
        {defaultValueRendererContent(value)}
      </Stack>
    </LightTooltip>
  );
};
