/* eslint-disable max-lines */
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import {
  IconButton,
  Typography,
  Divider,
  Skeleton,
} from "@komodorio/design-system/deprecated";
import {
  Close24,
  AWS,
  GoogleCloud,
  Azure,
  LogoIconProps,
} from "@komodorio/design-system/icons";
import { capitalize, isEmpty, startCase } from "lodash";
import { Metadata } from "@komodorio/design-system/komodor-ui";

import { ObjectDiff } from "../../../ObjectDiff";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeChangeEventGroup, {
  NodeChangeEvent,
  NodeEventAction,
  actionText,
} from "../../../EventGroup/nodeEvent/NodeChangeGroup";
import DetailsList from "../components/DetailsList";
import {
  Container,
  FlexRow,
  Header,
  Section,
  SectionName,
  Tiles,
  Title,
} from "../WorkflowIssueEventDetails/triage/common/styles";
import { useResourceView } from "../../../../ResourceView/ResourceProvider";
import {
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
  RESOURCE_PREFIX,
} from "../../../../../shared/config/urlSearchParamsKeys";
import { useDateFormatter } from "../../../../../shared/hooks/useDateFormatter";
import { useNodeMetrics } from "../../../../Metrics/useNodeMetrics";
import { Metrics } from "../../../../Metrics/Metrics";
import { ImpactTile } from "../WorkflowIssueEventDetails/triage/common/ImpactTile";
import { useResourceObjectAsYaml } from "../../../../ResourceView/tabs/ResourceYAMLTab";
import { aOrAn } from "../../../../../shared/utils/textUtils";
import { formatQuantityToGB } from "../../../../../shared/utils/formatQuantity";
import { useIsMetricsDrawerSupported } from "../../../../Metrics/metricsHooks";
import { MetricsGraphType } from "../../../../Metrics/types";
import { useGetNodeEventById } from "../../../../../shared/hooks/resources-api/client/events/useGetNodeEventById";
import { useDrawersStackStore } from "../../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../../../shared/store/drawersStackStore/types";
import { useActiveAgent } from "../../../../../shared/hooks/useAgents";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import useResourceWithInterval from "../../../../ResourceView/useResource";
import HappyKomo from "../../../../../shared/assets/happyKomo.svg?react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ImpactedResourcesSection } from "./ImpactedResourcesSection/ImpactResourcesSection";

import { LazyEditor } from "@/components/common/LazyEditor";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const ConfigChangesDiffContainer = styled.div`
  max-height: 20rem;
  overflow: auto;
  border: 1px solid ${theme.divider};
  border-radius: 4px;
`;

const CapacityContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  padding: 0px 70px 0px 17px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const cloudProviderIcon: { [key: string]: React.FC<LogoIconProps> } = {
  "eks.amazonaws.com": AWS,
  "karpenter.sh": AWS,
  "cloud.google.com": GoogleCloud,
  "kubernetes.azure.com": Azure,
};

interface NodeChangeData {
  [key: string]: string | Record<string, string>;
  data: Record<string, string>;
}

export interface NodeTerminationImpactData {
  affectedServices: {
    komodorUid: string;
    healthState: string;
    terminatedPodsCount: number;
  }[];
  affectedJobs: {
    komodorUid: string;
    terminatedPodsCount: number;
  }[];
  affectedArgoWorkflows: {
    komodorUid: string;
    terminatedPodsCount: number;
  }[];
  affectedOrphanPods: string[];
  affectedDaemonSets: {
    komodorUid: string;
    terminatedPodsCount: number;
  }[];
}

interface NodeData {
  newObj?: NodeChangeData;
  oldObj?: NodeChangeData;
  capacity?: {
    [key: string]: string;
  } & {
    cpu?: string;
    memory?: string;
    pods?: string;
    "ephemeral-storage"?: string;
  };
  nodeInfo?: {
    [key: string]: string;
  } & {
    osImage?: string;
  };
  event_node_termination_impact?: NodeTerminationImpactData;
}

const useDetails = (
  event: NodeChangeEvent,
  nodeInfo?: NodeData["nodeInfo"]
): { label: string; value: string }[] => {
  const { format } = useDateFormatter();

  return useMemo(
    () => [
      {
        label: "Termination Reason",
        value: capitalize(event.terminationReason),
      },
      { label: "Created", value: format(event.nodeCreationTime) },
      {
        label: "Terminated",
        value:
          event.action === NodeEventAction.Delete
            ? format(event.eventTime)
            : "",
      },
      { label: "Region", value: event.region },
      { label: "Zone", value: event.zone },
      { label: "Instance Type", value: event.instanceType },
      { label: "Allocation Type", value: event.allocationType },
      { label: "OS", value: `${nodeInfo?.osImage} (${capitalize(event.os)})` },
    ],
    [event, format, nodeInfo?.osImage]
  );
};

interface NodeChangeDetailsProps {
  eventGroup: NodeChangeEventGroup;
  onClose?: () => void;
}

const NodeChangeDetailsComponent: React.FC<NodeChangeDetailsProps> = ({
  eventGroup,
  onClose,
}) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const resourceFromResourceView = useResourceView();
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const [, setEventId] = useStateInSearchParams(
    (resourceFromResourceView.id ? RESOURCE_PREFIX + EVENTS_TAB_PREFIX : "") +
      EVENT_PARAM_KEY
  );
  const { format } = useDateFormatter();

  const firstEvent = eventGroup.events[0];
  const { clusterName, nodeName, cloudProvider, terminationReason, action } =
    firstEvent;
  const agentId = useActiveAgent(clusterName) ?? "";
  const { resource: nodeResource } = useResourceWithInterval({
    agentId,
    cluster: clusterName,
    resourceName: nodeName,
    resourceType: "Node",
  });

  const isTerminated = action === NodeEventAction.Delete;

  const { data, error, isFetching } = useGetNodeEventById({
    id: firstEvent.id,
    fields: [
      "id",
      "newObj",
      "oldObj",
      "capacity",
      "nodeInfo",
      "event_node_termination_impact",
    ],
  });

  const {
    newObj,
    oldObj,
    capacity,
    nodeInfo,
    event_node_termination_impact: terminationImpact,
  } = (data?.data?.[0] as NodeData) ?? {};

  const fixedNewObj = isTerminated ? {} : newObj;
  const {
    cpu,
    memory,
    pods,
    "ephemeral-storage": ephemeralStorage,
  } = capacity ?? {};

  const preparedDetails = useDetails(firstEvent, nodeInfo);

  const endTimestamp = useMemo(() => {
    return eventGroup.endTime?.getTime() ?? new Date().getTime();
  }, [eventGroup.endTime]);
  const isMetricsSupported = useIsMetricsDrawerSupported(clusterName);

  const nodeMetrics = useNodeMetrics(
    clusterName,
    nodeName,
    endTimestamp,
    !!isMetricsSupported,
    isTerminated
  );

  const CloudProviderIcon = cloudProviderIcon[cloudProvider];

  const onNameClick =
    !isTerminated && nodeResource
      ? () => {
          pushDrawer({
            drawerType: DrawerType.ResourceDrawerByData,
            cluster: nodeResource.cluster,
            resourceType: nodeResource.kind,
            resourceName: nodeResource.name,
          });
        }
      : undefined;

  const nodeYaml = useResourceObjectAsYaml(
    isEmpty(fixedNewObj) ? oldObj : fixedNewObj,
    {
      dropManagedFields: true,
    }
  );

  const typeTitle = startCase(eventGroup.type);

  useEffect(() => {
    reportLoadingState("nodeChangeDetails", isFetching);
  }, [isFetching, reportLoadingState]);

  return (
    <>
      <Container>
        <Section>
          <IconButton
            icon={Close24}
            noBorder
            onClick={onClose ?? (() => setEventId(null))}
            aria-label={AriaLabels.EventDrawer.CloseButton}
          />
          <Header>
            <Title>
              <Typography variant="headline" bold>
                {typeTitle}
              </Typography>
              {terminationReason && (
                <Typography
                  variant="headline"
                  data-e2e-selector="terminationReason"
                >
                  - {capitalize(terminationReason)}
                </Typography>
              )}
            </Title>
            <div />
            <Typography size="medium" color={theme.foreground.fgSubtle}>
              On {format(eventGroup.startTime)} a Node was {actionText[action]}{" "}
              {terminationReason &&
                `due to
              ${aOrAn(terminationReason)}`}
            </Typography>
          </Header>
        </Section>
        <Divider />
        <Section grayBg>
          <SectionName>where</SectionName>
          <FlexRow>
            {CloudProviderIcon && <CloudProviderIcon width={32} height={32} />}
            <Metadata
              title="name"
              value={nodeName}
              onValueClick={onNameClick}
            />
            <Metadata title="cluster" value={clusterName} />
          </FlexRow>
        </Section>
        <Divider />
        <Section>
          <SectionName>details</SectionName>
          <DetailsList details={preparedDetails} rows={3} />
        </Section>
        <Divider />
        {isTerminated && (
          <>
            <Section>
              <SectionName>impact</SectionName>
              {terminationImpact ? (
                <ImpactedResourcesSection
                  terminationImpact={terminationImpact}
                />
              ) : !isFetching ? (
                <EmptyStateContainer>
                  <HappyKomo />
                  <MuiTypography variant="h5" color="text.primary">
                    No resources are affected
                  </MuiTypography>
                </EmptyStateContainer>
              ) : null}
            </Section>
            <Divider />
          </>
        )}
        <Section>
          <SectionName>capacity</SectionName>
          <CapacityContent>
            <Tiles>
              {cpu && (
                <>
                  <ImpactTile title="vCPU" value={cpu} unit="Cores" />
                  <Divider variant="vertical" />
                </>
              )}
              {memory && (
                <>
                  <ImpactTile
                    title="memory"
                    value={formatQuantityToGB(memory)}
                    unit="GiB"
                  />
                  <Divider variant="vertical" />
                </>
              )}
              {ephemeralStorage && (
                <>
                  <ImpactTile
                    title="ephemeral storage"
                    value={formatQuantityToGB(ephemeralStorage)}
                    unit="GiB"
                  />
                  <Divider variant="vertical" />
                </>
              )}
              {pods && <ImpactTile title="pods" value={pods} />}
            </Tiles>
            {isTerminated && (
              <Metrics
                isMetricsSupported={!!isMetricsSupported}
                endTimestamp={endTimestamp}
                metrics={nodeMetrics}
                memoryUnit="GiB"
                graphType={MetricsGraphType.NODE}
              />
            )}
          </CapacityContent>
        </Section>
        <Divider />
        <Section>
          <SectionName>config</SectionName>
          {isFetching ? (
            <Skeleton width="100%" height="20rem" />
          ) : error || !data?.data ? (
            <div>Failed to fetch data.</div>
          ) : !isEmpty(oldObj) && !isEmpty(fixedNewObj) ? (
            <ConfigChangesDiffContainer>
              <ObjectDiff oldObj={oldObj} newObj={fixedNewObj} />
            </ConfigChangesDiffContainer>
          ) : (
            <LazyEditor
              width="100%"
              height="20rem"
              readOnly
              mode="yaml"
              value={nodeYaml}
            />
          )}
        </Section>
      </Container>
    </>
  );
};

export const NodeChangeDetails: React.FC<NodeChangeDetailsProps> = ({
  eventGroup,
  onClose,
}) => {
  const getViewOptionsName = (): DatadogViewNamesEnum => {
    if (eventGroup.type === NodeEventAction.Delete) {
      return DatadogViewNamesEnum.eventNodeTerminated;
    }
    if (eventGroup.type === NodeEventAction.Create) {
      return DatadogViewNamesEnum.eventNodeCreated;
    }
    return DatadogViewNamesEnum.eventNodeUpdated;
  };

  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: getViewOptionsName(),
        context: {
          feTeam: "troubleshooting",
          beTeam: ["troubleshooting", "barzelim"],
        },
      }}
    >
      <NodeChangeDetailsComponent eventGroup={eventGroup} onClose={onClose} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
