import { useCallback, useEffect } from "react";

import {
  checkTypeToViewNameMap,
  staticReliabilityContext,
} from "../../../../../constants/dataDogReporting";
import { useCurrentDrawerState } from "../../../../../../../shared/context/drawersStack/helpers";
import { ViolationsDrawerType } from "../../../../../../../shared/store/drawersStackStore/types";

import { useGetCurrentTab } from "./useGetCurrentTab";

import { ViewOptions } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";

export const useReportDrawerLoadingTime = (loadingIsComplete: boolean) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(() => {
    reportLoadingState("drawerComponent", !loadingIsComplete);
  }, [loadingIsComplete, reportLoadingState]);
};

export const useDatadogReportingPropsForViolationsModal = () => {
  const currentDrawerTab = useGetCurrentTab();
  const currentDrawerState = useCurrentDrawerState<ViolationsDrawerType>();
  const isBestPracticesTab = useIsBestPracticesTab();

  const getCurrentViewOptions = useCallback((): ViewOptions => {
    let viewName: string | undefined = "";
    if (currentDrawerTab === "history") {
      viewName = isBestPracticesTab
        ? DatadogViewNamesEnum.reliabilityHistoryInInfrastructure
        : DatadogViewNamesEnum.reliabilityHistoryInWorkloadHealth;
    } else {
      if (currentDrawerState?.checkType) {
        viewName = checkTypeToViewNameMap[currentDrawerState.checkType];
      }
    }

    return {
      name: viewName as DatadogViewNamesEnum,
      context: staticReliabilityContext,
    };
  }, [currentDrawerState?.checkType, currentDrawerTab, isBestPracticesTab]);

  return { getCurrentViewOptions };
};
