import { getAppConfig } from "../../config/appConfig";

import { useCreateAxiosClient } from "@/shared/hooks/useCreateAxiosClient";

export type QueryOptions = {
  enabled?: boolean;
  refetchInterval?: number;
  keepPreviousData?: boolean;
  staleTime?: number;
  cacheTime?: number;
  skipWorkspaceId?: boolean;
};

export const useK8sAddonsApiClient = (skipWorkspaceId?: boolean) => {
  return useCreateAxiosClient(getAppConfig().addonsAPIServiceServerURL, {
    skipAppViewInHeaders: skipWorkspaceId,
  });
};
