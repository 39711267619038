// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import prettyBytes from "pretty-bytes";
import React, { useCallback, useMemo } from "react";
import { Legend, ReferenceLine } from "recharts";

import { useHpaReachedMaxContext } from "../context/useHpaReachedMaxContext";
import { useGetEpochsFromViolation } from "../../../../hooks/useGetEpochsFromViolation";
import { parseKomodorUid } from "../../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import Resource from "../../../../../../../../../ResourceView/resources";
import {
  getMetricsGenericLegendItems,
  getMetricsGraphTitle,
  getRequestValueByPercent,
} from "../hpaReachedMaxUtils";
import { MetricsGraphTitle } from "../MetricsGraphs/MetricsGraphTitle";
import { MetricsAggregationSelectorWrapper } from "../MetricsGraphs/MetricsAggregationSelectorWrapper";
import { sharedDashedLineProps } from "../../../../../../../../../Metrics/constants";
import {
  FilterOutMetricsMapper,
  MetricsAggregationType,
} from "../../../../../../../../../Metrics/types";
import { SingleMetricsGraphProps } from "../hpaReachedMaxTypes";
import { formatPercentage } from "../../../../../../../../../../shared/utils/formatPercentage";
import { getLineList } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/workloadMetricsTabUtils";
import { UTILIZATION_METRIC_COLOR } from "../hpaReachedMaxConstants";
import { GenericLegend } from "../../../../../../../../../Metrics/GenericLegend/GenericLegend";
import { useIsViewDeployEventDetailsClicked } from "../../../shared/metrics/hooks/useIsViewDeployEventDetailsClicked";
import { WorkloadGraphProps } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/types";

import { useGetCustomTooltipEntries } from "./useGetCustomTooltipEntries";

const excludeLines: FilterOutMetricsMapper = {
  limitBytes: true,
  recommendationBytes: true,
};

type Output = {
  resource: Resource;
  endTime: number;
  metricGraphProps: WorkloadGraphProps["metricGraphProps"];
  additionalProps: WorkloadGraphProps["additionalProps"];
};

export const useSharedGraphProps = ({
  resourceType,
  unit,
  value,
  averageUtilizationValue,
}: SingleMetricsGraphProps) => {
  const { serviceKomodorUid } = useHpaReachedMaxContext();
  // internally, WorkloadGraph uses timeWindowToEpochs() so the input should be milliseconds
  const { toEpoch } = useGetEpochsFromViolation("milliseconds");

  const metricsGraphTitle = getMetricsGraphTitle(resourceType);

  const valueToUse =
    unit === "percentage"
      ? getRequestValueByPercent({
          averageUtilizationPercent: value,
          averageUtilizationValue: averageUtilizationValue ?? 0,
        })
      : value ?? 0;

  const valueToDisplay =
    unit === "percentage"
      ? `${formatPercentage(value, {
          maximumFractionDigits: 0,
        })} utilization`
      : `${prettyBytes(value ?? 0, {
          space: true,
          maximumFractionDigits: 0,
        })} utilization`;

  const getLinesListByAggregationType = useCallback(
    (aggregationType?: MetricsAggregationType) => {
      if (!aggregationType) return [];
      return getLineList(aggregationType, excludeLines);
    },
    []
  );

  const getCustomTooltipEntries = useGetCustomTooltipEntries({
    resourceType,
    valueToUse,
  });

  const shouldClosePopoverContentOnClickCapture =
    useIsViewDeployEventDetailsClicked();

  return useMemo<Output>(() => {
    const {
      kind = "",
      cluster = "",
      namespace = "",
      name = "",
    } = parseKomodorUid(serviceKomodorUid ?? "") ?? {};

    const resource = {
      kind,
      cluster,
      namespace,
      name,
    } as Resource;

    return {
      resource,
      endTime: toEpoch,
      metricGraphProps: {
        getLinesListByAggregationType,
        getCustomTooltipEntries,
      },
      additionalProps: {
        graph: {
          title: <MetricsGraphTitle metricsType={resourceType} />,
        },
        enlargedGraphProps: {
          title: metricsGraphTitle,
          controls: [
            <MetricsAggregationSelectorWrapper key="MetricsAggregationSelectorWrapper" />,
          ],
          shouldCloseOnClickCapture: shouldClosePopoverContentOnClickCapture,
        },
        requestParams: {
          toEpoch,
        },
        ...(valueToUse > 0 && {
          additionalGraphElements: (
            <>
              <ReferenceLine
                {...sharedDashedLineProps}
                y={valueToUse}
                label={{
                  position: "insideBottomRight",
                  value: valueToDisplay,
                  style: { fill: UTILIZATION_METRIC_COLOR, fontSize: "12px" },
                }}
                stroke={UTILIZATION_METRIC_COLOR}
              />
              <Legend
                content={
                  <GenericLegend
                    items={getMetricsGenericLegendItems(resourceType)}
                  />
                }
              />
            </>
          ),
        }),
      },
    };
  }, [
    serviceKomodorUid,
    toEpoch,
    getLinesListByAggregationType,
    getCustomTooltipEntries,
    resourceType,
    metricsGraphTitle,
    shouldClosePopoverContentOnClickCapture,
    valueToUse,
    valueToDisplay,
  ]);
};
