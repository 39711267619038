import { useCallback, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { generateSocketPayload } from "../utils/generateSocketPayload";

import { isSocketReadyToSend } from "./isSocketReadyToSend";
import { SocketMessagePayload, SocketMessageType } from "./types";
import { extractPayloadFromMessage } from "./utils";

type InitiatePodExecSessionParams = {
  connection: WebSocket;
  onInitialized: () => void;
  sessionId?: string;
  resolve: (value: void) => void;
  reject: () => void;
  initData?: Record<string, string | number>;
  messageType: SocketMessageType;
  onConnectionConfirmedByTheAgent?: () => void;
  onAck?: () => void;
};

export const useHandleConnectionOnOpen = (): ((
  props: InitiatePodExecSessionParams
) => void) => {
  const initDataRef = useRef<Record<string, string | number> | undefined>();

  return useCallback(
    ({
      connection,
      onInitialized,
      sessionId,
      resolve,
      reject,
      initData: data,
      messageType,
      onConnectionConfirmedByTheAgent,
      onAck,
    }: InitiatePodExecSessionParams) => {
      const msgToSend = generateSocketPayload({
        messageType,
        data,
      });

      connection.onopen = () => {
        const isReady = isSocketReadyToSend(connection);

        if (isReady) {
          resolve();
          const isInitDataChanged = initDataRef?.current !== data;
          if (!sessionId || isInitDataChanged) {
            onConnectionConfirmedByTheAgent &&
              onConnectionConfirmedByTheAgent();
            initDataRef.current = data;
            connection.send(JSON.stringify(msgToSend));
          }
        } else {
          reject();
        }

        onInitialized();
      };
      connection.onmessage = (message: MessageEvent<SocketMessagePayload>) => {
        const { messageType, data } = extractPayloadFromMessage(message);

        if (messageType === SocketMessageType.Ack) {
          if (data?.ackedMessageID === msgToSend.messageId) {
            onAck && onAck();

            datadogRum.addAction("received_ack");
          }
        }
      };
    },
    []
  );
};
