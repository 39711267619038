import React from "react";
import { startCase } from "lodash";
import { LightTooltip, Status } from "@komodorio/design-system/komodor-ui";
import { parseISO } from "date-fns";
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { Kubernetes16 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { muiTheme } from "@komodorio/design-system";

import { K8S_RESOURCE_TOOLTIP } from "@/pages/organization-settings/account/AuditPage/constants";

export const getColumnsDefinition = ({
  timezoneName,
  format,
}: {
  timezoneName: string;
  format: (d: Date) => string;
}) => {
  return [
    {
      field: "eventTime",
      headerName: `Timestamp (${timezoneName})`,
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams) => {
        return format(parseISO(params.value ?? ""));
      },
      skeletonWidth: 200,
    },
    {
      field: "operation",
      headerName: "Type",
      valueGetter: (params: GridValueGetterParams) => startCase(params.value),
      flex: 1,
      skeletonWidth: 100,
    },
    {
      field: "action",
      headerName: "Action",
      valueGetter: (params: GridValueGetterParams) => startCase(params.value),
      flex: 1,
      skeletonWidth: 200,
    },
    {
      field: "entityName",
      headerName: "Affected resource",
      flex: 1,
      skeletonWidth: 300,
    },
    {
      field: "entityType",
      headerName: "Resource type",
      renderCell: (params: GridRenderCellParams) => {
        const isK8sResource = !!params.row.details?.resourceData;
        const tooltipTitle = isK8sResource ? K8S_RESOURCE_TOOLTIP : "";

        return (
          <LightTooltip title={tooltipTitle}>
            <Stack direction="row" columnGap="4px" alignItems="center">
              {isK8sResource && (
                <Kubernetes16 fill={muiTheme.palette.text.disabled} />
              )}
              <Typography variant="body2">{startCase(params.value)}</Typography>
            </Stack>
          </LightTooltip>
        );
      },
      flex: 1,
      skeletonWidth: 100,
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      skeletonWidth: 200,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => (
        <Status
          label={params.value}
          color={params.value === "success" ? "success" : "error"}
        />
      ),
      flex: 1,
      skeletonWidth: 100,
    },
  ];
};
