import { IntegrationType } from "komodor-types";

import { InstallationsResponse } from "../../../../../integrations/management/installed/useInstallationSubscription";
import { Commit, GitCompare } from "../../../../../../shared/types/git";
import githubIcon from "../../../../../integrations/management/logos/github.svg";
import gitlabIcon from "../../../assets/gitlab.svg";
import azureIcon from "../../../assets/azure.svg";
import bitbucketIcon from "../../../assets/bitbucket.svg";
import greyVCIcon from "../../../assets/vc-grey.svg";

export enum GitType {
  Github,
  Gitlab,
  AzureRepos,
  Bitbucket,
  NotRecognized,
}

export interface GitTypeFinder {
  type: GitType;
  hasAnnotations: boolean;
  hasInstallation: boolean;
  hasGitCompare: boolean;
}

export interface GitProviderData {
  url: string;
  gitGroup: string;
  diff: Commit[] | undefined;
}

const INFO_WITHOUT_INSTALLATION =
  "We compare the previous version ref to the new version, showing a link to your last commit.";
const INFO_WITH_INSTALLATION =
  "We compare the previous version ref to the new version, showing all new commits & PRs for every deploy!";

export const GitProviders = new Map([
  [
    GitType.Github,
    {
      icon: githubIcon,
      name: "Github",
      prPrefix: "#",
      commitUrl: ({ org, repo, sha }: Commit) =>
        `https://github.com/${org}/${repo}/commit/${sha}`,
      info: INFO_WITH_INSTALLATION,
    },
  ],
  [
    GitType.Gitlab,
    {
      icon: gitlabIcon,
      name: "GitLab",
      prPrefix: "!",
      commitUrl: ({ org, sub_groups, repo, sha }: Commit) =>
        `https://gitlab.com/${org}/${sub_groups.join(
          "/"
        )}/${repo}/-/commit/${sha}`,
      info: INFO_WITH_INSTALLATION,
    },
  ],
  [
    GitType.AzureRepos,
    {
      icon: azureIcon,
      name: "Azure Repos",
      prPrefix: "",
      commitUrl: () => "",
      info: INFO_WITHOUT_INSTALLATION,
    },
  ],
  [
    GitType.Bitbucket,
    {
      icon: bitbucketIcon,
      name: "Bitbucket",
      prPrefix: "",
      commitUrl: () => "",
      info: INFO_WITHOUT_INSTALLATION,
    },
  ],
  [
    GitType.NotRecognized,
    {
      icon: greyVCIcon,
      name: "Version Control",
      prPrefix: "",
      commitUrl: () => "",
      info: INFO_WITHOUT_INSTALLATION,
    },
  ],
]);

const checkGitProviderInAnnotations = (
  annotations: Record<string, string> | undefined,
  patterns: string[]
): boolean => {
  if (!annotations) return false;
  return Object.entries(annotations).some(([_, value]) =>
    patterns.some((p) => value.includes(p))
  );
};

const getGitTypeFromAnnotation = (
  annotations: Record<string, string> | undefined
): GitType | null => {
  if (checkGitProviderInAnnotations(annotations, ["azure.com"]))
    return GitType.AzureRepos;
  if (
    checkGitProviderInAnnotations(annotations, [
      "bitbucket.org",
      "bitbucket.com",
    ])
  ) {
    return GitType.Bitbucket;
  }
  if (checkGitProviderInAnnotations(annotations, ["gitlab.com"]))
    return GitType.Gitlab;
  if (checkGitProviderInAnnotations(annotations, ["github.com"]))
    return GitType.Github;
  return null;
};

const getGitTypeFromInstallation = (
  installations: InstallationsResponse
): GitType | null => {
  if (
    installations.data?.find(
      (ins) => ins.integration === IntegrationType.GITHUB
    )
  )
    return GitType.Github;
  if (
    installations.data?.find(
      (ins) => ins.integration === IntegrationType.GITLAB
    )
  )
    return GitType.Gitlab;
  return null;
};

const getGitTypeFromGitCompare = (
  gitCompares: GitCompare[] | null | undefined
): GitType | null => {
  if (!gitCompares) return null;
  const gitTypes = new Map([
    ["github", GitType.Github],
    ["azure", GitType.AzureRepos],
    ["gitlab", GitType.Gitlab],
    ["bitbucket", GitType.Bitbucket],
  ]);

  for (const gc of gitCompares) {
    if (gitTypes.has(gc.type)) {
      return gitTypes.get(gc.type) ?? null;
    }
  }
  return null;
};

export const getGitTypeFinder = (
  gitCompares: GitCompare[] | null | undefined,
  installation: InstallationsResponse,
  annotations: Record<string, string> | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): GitTypeFinder => {
  const gitTypeFromAnnotation = getGitTypeFromAnnotation(annotations);
  const gitTypeFromGitCompare = getGitTypeFromGitCompare(gitCompares);
  const gitTypeFromInstallation = getGitTypeFromInstallation(installation);

  let gitType;
  if (gitTypeFromAnnotation !== null) gitType = gitTypeFromAnnotation;
  else if (gitTypeFromGitCompare !== null) gitType = gitTypeFromGitCompare;
  else if (gitTypeFromInstallation !== null) gitType = gitTypeFromInstallation;
  else gitType = GitType.NotRecognized;

  return {
    type: gitType,
    hasAnnotations: gitTypeFromAnnotation ? true : false,
    hasInstallation: gitTypeFromInstallation ? true : false,
    hasGitCompare: gitTypeFromGitCompare ? true : false,
  };
};

export const getGitProviderData = (
  gitCompare?: GitCompare
): GitProviderData => {
  const gitGroup =
    !gitCompare?.group || gitCompare.group === "default"
      ? ""
      : ` (${gitCompare.group})`;
  const provider = {
    url: gitCompare?.url ?? "",
    gitGroup: gitGroup,
    diff: gitCompare?.commitDiff,
  };

  return provider;
};
