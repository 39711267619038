import React from "react";

import { SelfSupplyingResourceListTableProps } from "../types";

import { PodsListTableAtm } from "./PodsListTables";

type PodsListTableProps = SelfSupplyingResourceListTableProps & {
  showDeletedPods?: boolean;
};

export const PodsListTable: React.FC<PodsListTableProps> = ({
  agentId,
  ...props
}) => {
  return <PodsListTableAtm agentId={agentId ?? ""} {...props} />;
};
