import React from "react";

import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Addon, Entity, Operator } from "@/generated/addonsApi";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import {
  EXTERNAL_DNS_DEFAULT_SORT_BY_DIRECTION,
  EXTERNAL_DNS_DEFAULT_SORT_BY_FIELD,
} from "@/components/k8sAddons/addons/externalDns/constants";
import { Filters } from "@/components/k8sAddons/addons/externalDns/Filters";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { Table } from "@/components/k8sAddons/addons/externalDns/Table";
import { useShouldShowExternalDns } from "@/components/k8sAddons/addons/externalDns/hooks/useShouldShowExternalDns";
import { ExternalDNSEmptyState } from "@/components/k8sAddons/addons/externalDns/ExternalDNSEmptyState";
import { PageLoader } from "@/shared/components/PageLoader";
import { AddonPageContainer } from "@/components/k8sAddons/components/AddonPageContainer";

export const ExternalDNS: React.FC = () => {
  const [shouldShowExternalDns, isLoading] = useShouldShowExternalDns();

  if (isLoading) {
    return <PageLoader />;
  }
  if (!shouldShowExternalDns) {
    return <ExternalDNSEmptyState />;
  }
  return (
    <FiltersContextProvider
      urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
      searchTypeFilterOperator={Operator.Like}
    >
      <AddonContextProvider
        addonType={Addon.ExternalDns}
        addonEntity={Entity.ExternalDnsOperator}
        defaultSortModel={{
          field: EXTERNAL_DNS_DEFAULT_SORT_BY_FIELD,
          sort: EXTERNAL_DNS_DEFAULT_SORT_BY_DIRECTION,
        }}
      >
        <ResponsiveLayout>
          <AddonPageContainer>
            <Filters />
            <Table />
          </AddonPageContainer>
        </ResponsiveLayout>
      </AddonContextProvider>
    </FiltersContextProvider>
  );
};
