import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { groupBy } from "lodash";

import { useMonitorsApiClient } from "../apiClient";
import {
  MonitorsApiApiV1SearchGetRequest,
  MonitorsResponse,
  apiV1SearchGetUrl,
  MonitorData,
} from "../../../../../generated/monitorsApi";
import { WorkflowConfigType } from "../../../../../components/monitorsView/common/types";

export const MONITORS_SEARCH_PATH = "/search";

const fetchMonitorIssues = async (
  apiClient: AxiosInstance,
  params: MonitorsApiApiV1SearchGetRequest
): Promise<MonitorsResponse> => {
  const { data } = await apiClient.get(
    apiV1SearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetMonitorIssues = (
  params: MonitorsApiApiV1SearchGetRequest,
  options?: {
    enabled?: boolean;
    refetchInterval?: number;
  }
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery(
    [MONITORS_SEARCH_PATH, params],
    () => fetchMonitorIssues(apiClient, params),
    options
  );
};

export const useGetGroupedMonitorIssues = (
  params: MonitorsApiApiV1SearchGetRequest,
  options?: {
    enabled?: boolean;
    refetchInterval?: number;
  }
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery(
    [MONITORS_SEARCH_PATH, params],
    async () => {
      const data = await fetchMonitorIssues(apiClient, params);

      const issuesByType = groupBy(data?.data, (i) => i.type);
      const issuesByTypeByKeys = Object.fromEntries(
        Object.entries(issuesByType).map(([type, issues]) => {
          const issuesByKey = groupBy(issues, (issue) =>
            getKeyByIssueType(issue)
          );
          return [type, issuesByKey];
        })
      );

      return issuesByTypeByKeys;
    },
    options
  );
};

const getKeyByIssueType = (issue: MonitorData) => {
  let key = "";
  const { type, services, cluster, namespace, shortResourceName } = issue;
  switch (type) {
    case WorkflowConfigType.Job:
    case WorkflowConfigType.Availability:
      if (services?.length) {
        key = services[0];
      }
      break;
    case WorkflowConfigType.CronJob:
      if (services?.length && shortResourceName) {
        key = buildCronJobIssueKey(services[0], shortResourceName);
      }
      break;
    case WorkflowConfigType.NodeIssue:
      if (cluster && shortResourceName) {
        key = buildNodeIssueKey(cluster, shortResourceName);
      }
      break;
    case WorkflowConfigType.PVC:
    case WorkflowConfigType.Workflow:
    default:
      if (cluster && namespace && shortResourceName) {
        key = buildIssueKey(cluster, namespace, shortResourceName);
      }
  }
  return key;
};

export const buildCronJobIssueKey = (serviceId: string, name: string) => {
  return `${serviceId}/${name}`;
};
export const buildNodeIssueKey = (cluster: string, name: string) => {
  return `${cluster}/${name}`;
};
export const buildIssueKey = (
  cluster: string,
  namespace: string,
  name: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  return `${cluster}/${namespace}/${name}`;
};
