import React, { useEffect, useMemo } from "react";
import { capitalize } from "lodash";
import Skeleton from "@mui/material/Skeleton";

import { useGetCertificateData } from "./useGetCertificateData";

import { certificateStatusToColor } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import { SeverityColor } from "@/components/k8sAddons/types";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const {
  violationDrawer: {
    certificateExpiration: { certificateStatus },
  },
} = reliabilityArialLabels;

interface Props {
  uid: string;
  name: string;
}

export const Status: React.FC<Props> = ({ uid, name }) => {
  const { data, isLoading } = useGetCertificateData(uid, name);
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState(`certificateStatus ${uid}`, isLoading);
  }, [isLoading, reportLoadingState, uid]);

  const { status, color } = useMemo<{
    status: string;
    color: SeverityColor;
  }>(() => {
    if (!data?.data)
      return {
        status: "",
        color: "info",
      };
    const { status } = data.data.object.header;
    const color = certificateStatusToColor[status];

    return {
      status,
      color,
    };
  }, [data?.data]);

  if (isLoading) {
    return <Skeleton variant="text" sx={{ fontSize: "24px" }} width="60px" />;
  }

  if (!status) return null;

  return (
    <div aria-label={certificateStatus}>
      <AddonStatusChip
        color={color}
        label={capitalize(status)}
        size={"medium"}
      />
    </div>
  );
};
