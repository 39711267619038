import styled from "styled-components";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { muiColors } from "@komodorio/design-system";
import {
  CheckCircleOutlined16,
  CheckCircleOutlined24,
} from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useRef } from "react";

import logo from "../../../integrations/installation/kubernetes/assets/logo.gif";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { RichSessionData } from "../common/useEnrichedSession";

import { ShortPanel } from "./styles";

const StyledStepIcon = ({
  active,
  size,
}: {
  active: boolean;
  size: "small" | "large";
}) => {
  return (
    <>
      {active ? (
        <Logo height="24px" width="24px" />
      ) : size === "small" ? (
        <CheckCircleOutlined16 fill={muiColors.gray[500]} />
      ) : (
        <CheckCircleOutlined24 fill={muiColors.gray[500]} />
      )}
    </>
  );
};

const StepTypography = styled(Typography)<{
  active: boolean;
  size: "small" | "large";
}>`
  && {
    vertical-align: middle;
    ${({ active, size }) =>
      `font-weight: ${active ? "700" : "400"};
        ${
          !active &&
          size === "large" &&
          `
          color: ${muiColors.gray[500]};
          `
        } 
    `}
  }
`;

const Logo = styled.img.attrs({ src: logo })`
  object-fit: contain;
`;

const StyledStep = styled(Step)<{ size: "small" | "large" }>`
  + .MuiStepConnector-root {
    margin-left: ${(props) => (props.size === "small" ? "8px" : "12px")};
  }
`;

export const Loader: React.FC<{
  collapsed?: boolean;
  session: RichSessionData | null | undefined;
  size?: "small" | "large";
  linkToResources?: boolean;
}> = ({
  session,
  size = "large",
  linkToResources = false,
  collapsed = false,
}) => {
  const operations = useMemo(
    () =>
      (linkToResources ? session?.enrichedOperations : session?.operations) ??
      [],
    [linkToResources, session?.enrichedOperations, session?.operations]
  );
  const isOperationsEmpty = operations.length === 0;
  const stepperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Scroll to the bottom when steps are updated
    if (stepperRef.current) {
      stepperRef.current.scrollTop = stepperRef.current.scrollHeight;
    }
  }, [operations]);

  return (
    <ShortPanel center={isOperationsEmpty}>
      {isOperationsEmpty ? (
        <>
          <Logo
            aria-label={AriaLabels.AiInvestigator.LoaderLogo}
            height="60px"
            width="80px"
          />
          <Typography variant="subtitle2">
            Loading AI investigation...
          </Typography>
        </>
      ) : (
        <Stepper
          orientation="vertical"
          nonLinear
          activeStep={operations.length - 1}
          sx={{ overflowY: "auto", position: "relative" }}
          ref={stepperRef}
        >
          {(collapsed ? operations.slice(0, 1) : operations).map(
            (step, index) => {
              const active =
                index === operations.length - 1 && !session?.isComplete;
              return (
                <StyledStep key={JSON.stringify(step)} size={size}>
                  <StepLabel
                    sx={index === 0 ? { paddingTop: 0 } : undefined}
                    StepIconComponent={() => (
                      <StyledStepIcon size={size} active={active} />
                    )}
                  >
                    <StepTypography
                      active={active}
                      size={size}
                      variant={size === "small" ? "body2" : "subtitle2"}
                    >
                      {step}
                    </StepTypography>
                  </StepLabel>
                </StyledStep>
              );
            }
          )}
        </Stepper>
      )}
    </ShortPanel>
  );
};
