import axios from "axios";
import { useMemo } from "react";

import {
  RequestHeadersParams,
  useRequestHeaders,
} from "@/shared/hooks/common-api/headers";
import { useReportAxiosError } from "@/components/reliability/hooks/useReportAxiosError";

export const useCreateAxiosClient = (
  baseURL: string,
  headerRequestParams?: RequestHeadersParams
) => {
  const headers = useRequestHeaders(headerRequestParams);
  const reportAxiosError = useReportAxiosError();

  const client = useMemo(
    () =>
      axios.create({
        baseURL,
        headers,
      }),
    [baseURL, headers]
  );

  client.interceptors.response.use(undefined, (error) => {
    reportAxiosError(error);
    return Promise.reject(error);
  });

  return client;
};
