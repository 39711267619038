import React, { useMemo } from "react";
import { Divider, Tabs } from "@komodorio/design-system/deprecated";
import Box from "@mui/material/Box";
import styled from "styled-components";

import ResponsiveLayout from "../common/ResponsiveLayout";

import { ResourceProvider } from "./ResourceProvider";
import { Container, WhiteContainer, Content } from "./resourceStyles";
import Resource from "./resources";

import { SELECTED_TAB_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";
import { useHasPermissionToViewResource } from "@/components/ResourceView/useHasPermissionToViewResource";
import { PageLoader } from "@/shared/components/PageLoader";
import { ErrorFallbackComponent } from "@/components/ErrorBoundary/ErrorFallbackComponent";

const StyledErrorFallbackComponent = styled(ErrorFallbackComponent)`
  & > div {
    width: 100%;
    box-sizing: border-box;
  }
`;

interface ResourceViewProps {
  resource: Resource;
  leftHeaderActions?: JSX.Element;
}
const ResourceView: React.FC<ResourceViewProps> = (props) => {
  return (
    <ResourceProvider resource={props.resource}>
      <ResourceViewContent {...props} />
    </ResourceProvider>
  );
};
const ResourceViewContent: React.FC<ResourceViewProps> = ({
  resource,
  leftHeaderActions,
}) => {
  const { canViewResource, isLoadingCanViewResource } =
    useHasPermissionToViewResource(resource);

  const { drawerTabs, defaultTab } = resource;
  const [selectedTab, setSelectedTab] = useDrawerUrlState<string>(
    SELECTED_TAB_PARAM_KEY
  );
  const selectedTabIndex = useMemo(() => {
    const index = drawerTabs.findIndex((tab) => tab.label === selectedTab) || 0;

    return index < 0 ? defaultTab : index;
  }, [defaultTab, drawerTabs, selectedTab]);

  if (isLoadingCanViewResource) {
    return <PageLoader />;
  }
  if (canViewResource === false) {
    return <StyledErrorFallbackComponent resetHasError={() => undefined} />;
  }

  return (
    <Container>
      <WhiteContainer>
        <ResponsiveLayout>
          {resource.renderDrawerHeader(leftHeaderActions)}
        </ResponsiveLayout>
        {resource.renderDrawerMessage?.()}
        <ResponsiveLayout>
          <Tabs
            tabs={drawerTabs}
            value={selectedTabIndex}
            onChange={(selectedTabIndex) => {
              setSelectedTab(drawerTabs[selectedTabIndex].label);
            }}
          />
        </ResponsiveLayout>
      </WhiteContainer>
      <Divider />
      <Content>
        <Box margin={"24px"} height="100%">
          {resource.renderDrawerContent(selectedTabIndex)}
        </Box>
      </Content>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ResourceView;
