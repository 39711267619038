import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { KomobotEmptyState } from "@komodorio/design-system/assets";

import { useAuditLogsContext } from "@/pages/organization-settings/account/AuditPage/context/useAuditLogsContext";

export const NoResultsOverlay: React.FC = () => {
  const { clearFilters } = useAuditLogsContext();

  return (
    <Stack justifyContent="center" alignItems="center" height="100%" rowGap={1}>
      <KomobotEmptyState />
      <Typography variant="body2">No results found</Typography>
      <Button variant="outlined" onClick={clearFilters}>
        Clear filters
      </Button>
    </Stack>
  );
};
