import { XAxisProps } from "recharts";

import { lastTickFormatter } from "@/components/common/EventsChart/ticks";

export const timeXAxisProps: XAxisProps = {
  dataKey: "time",
  scale: "linear",
  type: "number",
  tickLine: false,
  axisLine: false,
  interval: 0,
  tickFormatter: lastTickFormatter,
};

export const yAxisProps = (yAxisLabel: string | undefined) => ({
  tick: { fontSize: 12 },
  tickLine: false,
  axisLine: false,
  label: {
    value: yAxisLabel,
    style: { textAnchor: "middle", fontSize: 12 },
    angle: -90,
    position: "left",
    offset: -10,
    hide: !yAxisLabel,
  },
});
