import React, { useMemo } from "react";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { muiColors, muiTheme } from "@komodorio/design-system";

const HAVE_PERMISSION = "You have the required permissions";
const NO_PERMISSION =
  "You don’t have the required permissions. Please contact your admin.";

export const Result: React.FC<{
  title: string;
  isPermitted: boolean;
  loading: boolean;
}> = ({ title, isPermitted, loading }) => {
  const permissionContent = useMemo(() => {
    if (loading) {
      return <Skeleton height="32px" width="320px" />;
    }
    return (
      <>
        {isPermitted ? (
          <CheckCircleIcon style={{ color: muiColors.green[500] }} />
        ) : (
          <CancelIcon style={{ color: muiTheme.palette.error.dark }} />
        )}
        <Typography variant="body2">
          {isPermitted ? HAVE_PERMISSION : NO_PERMISSION}
        </Typography>
      </>
    );
  }, [loading, isPermitted]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      columnGap={1}
      paddingY={1}
      paddingX={2}
      sx={{
        border: `1px solid ${muiTheme.palette.divider}`,
        borderRadius: "4px",
        backgroundColor: muiTheme.palette.background.paper,
      }}
    >
      <Stack direction="row" width="230px" columnGap={1}>
        <Typography variant="h4">{title}</Typography>
      </Stack>
      <Divider orientation="vertical" flexItem />
      {permissionContent}
    </Stack>
  );
};
