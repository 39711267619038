import { useCallback, useMemo, useRef } from "react";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";

import { CustomGraphDataPoint, GraphDataPoint } from "../types";
import { useMetricsZoom } from "../Zoom";

import { useMouseHover } from "@/components/common/EventsChart/useMouseHover";

export const INVALID_DATAPOINT_VALUE = -1;

const isInvalidDatapoint = (value: number): boolean => {
  return value === INVALID_DATAPOINT_VALUE;
};

export const useAvoidNoData = (
  datapoints: GraphDataPoint[] | undefined
): GraphDataPoint[] | undefined => {
  return useMemo(() => {
    if (!datapoints) {
      return undefined;
    }

    return datapoints.map((datapoint) => {
      const values = Object.entries(datapoint).map(([key, value]) => [
        key,
        isInvalidDatapoint(value) ? null : value,
      ]) as unknown as [keyof GraphDataPoint, number | null][];
      return Object.fromEntries(values) as unknown as GraphDataPoint;
    });
  }, [datapoints]);
};

export const useGraphProps = (data: CustomGraphDataPoint[]) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const { onMouseDown, onMouseMove, onMouseUp, ticks, TickComponent, domain } =
    useMetricsZoom({
      data,
    });
  const { mouseOver, setMouseOver, mousePos, setMousePos } = useMouseHover();

  const boundingRect = componentRef.current?.getBoundingClientRect();

  const onMouseMoveCb = useCallback(
    (state: CategoricalChartState, event: React.MouseEvent<SVGElement>) => {
      setMousePos({ x: event.clientX, y: event.clientY });
      onMouseMove(state);
    },
    [onMouseMove, setMousePos]
  );

  return {
    containerProps: {
      ref: componentRef,
      onMouseOver: () => setMouseOver(true),
      onMouseOut: () => setMouseOver(false),
    },
    chartProps: {
      onMouseDown,
      onMouseMove: onMouseMoveCb,
      onMouseUp,
    },
    xAxisProps: {
      ticks,
      domain,
      tick: <TickComponent />,
    },
    tooltipProps: {
      boundingRect,
      open: mouseOver,
      mousePos,
    },
  };
};
