import { get } from "lodash";
import { getTextWidth } from "../../../../utils/getTextWidth";

const FONT = "12px Roboto, sans-serif";
const ITEM_PADDING = 32; // padding + margin for each item
const HIDDEN_ITEMS_CHIP_WIDTH = 40;
const EXTRA_PREFIX = "Extra";

export const getExtraLabel = (labelPrefix: string) =>
  `${EXTRA_PREFIX}-${labelPrefix}`;

interface CalculateFittingItemsProps<T> {
  items: T[];
  textPath: string;
  cellWidth: number;
  reservedPlace?: number;
}

export function calculateFittingItems<T>({
  items,
  textPath,
  cellWidth,
  reservedPlace = HIDDEN_ITEMS_CHIP_WIDTH,
}: CalculateFittingItemsProps<T>): number {
  const availableWidth = cellWidth - reservedPlace; // Remove the fixed component width

  let currentWidth = 0;
  let fittingItems = 0;
  let index = 0;

  while (index < items.length) {
    const itemTextWidth =
      getTextWidth(get(items[index], textPath), FONT) + ITEM_PADDING;

    // Check if all the items can fit the cell without overflowing and without the hidden items chip
    if (
      currentWidth + itemTextWidth <= cellWidth &&
      fittingItems + 1 === items.length
    ) {
      fittingItems = items.length;
      index = items.length; // No more items can fit
    } else {
      if (currentWidth + itemTextWidth <= availableWidth) {
        currentWidth += itemTextWidth;
        fittingItems++;
        index++;
      } else {
        index = items.length; // No more items can fit
      }
    }
  }

  return fittingItems;
}
