import { columnsConfig } from "@/components/k8sAddons/addons/externalDns/hooks/tableHooks";
import { Addon, Entity } from "@/generated/addonsApi";
import { useGetAddonAggregations } from "@/shared/hooks/k8s-add-ons/useGetAddonAggregations";

export const useShouldShowExternalDns = () => {
  const { data, isLoading } = useGetAddonAggregations(
    {
      addon: Addon.ExternalDns,
      entity: Entity.ExternalDnsOperator,
      getEntityAggregationsRequest: {
        groupBy: columnsConfig.cluster.field,
        aggregation: "count",
      },
    },
    {
      skipWorkspaceId: true,
    }
  );

  return [data ? data.data.count > 0 : undefined, isLoading];
};
