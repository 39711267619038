import React, { useMemo } from "react";
import { orderBy } from "lodash";
import styled from "styled-components";
import { useParams } from "react-router-dom";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeEvictedPodsCheck, { PodOutput } from "../NodeEvictedPodsCheck";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import Recommendations from "../../../../common/Recommendations";
import {
  Divider,
  NormalText,
  NoUnderlinedLink,
  StyledFontTable,
  TableContainer,
  Margin,
  UnderlinedButton,
} from "../../../../common/styles";
import { FlexRows } from "../../../../../common/FlexContainers";
import ExpandedTableRow from "../../../../common/ExpandedTableRow";
import HiddenPodsInfo from "../../../../common/HiddenPodsInfo";

import { StateInSearchParamsType } from "@/shared/hooks/state/useStateInSearchParams";

export const NestedTableContainer = styled(TableContainer)`
  max-height: 8rem;
  background-color: white;
`;

const PodEvictedMessage = styled(FlexRows)`
  margin: 0.5rem 0;
  font-family: Roboto Mono;
`;
const PodEvictedMessageTitle = styled.span`
  margin-right: 0.5rem;
  font-family: Roboto Mono;
`;
const BackTh = styled.th`
  z-index: 0 !important;
`;
const notReadyInfo =
  "The node controller will trigger an API-initiated eviction for all Pods assigned to that node. The default eviction timeout duration is five minutes. New pods will be scheduled on another node.";
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getPodTableCells = (pod: PodOutput): (string | JSX.Element)[] => [
  <>
    {pod.name} <br />
    {pod?.serviceId && (
      <>
        (
        <NoUnderlinedLink to={`/main/deep-dive/${pod.serviceId}`}>
          View Service
        </NoUnderlinedLink>
        )
      </>
    )}
  </>,
  pod.qos,
  pod.priorityClassName,
  pod.priority.toString(),
];

export const ContainersResourceTable: React.FC<{
  pod: PodOutput;
}> = ({ pod }) => {
  return (
    <NestedTableContainer>
      <StyledFontTable>
        <thead>
          <tr>
            <BackTh>Container</BackTh>
            <BackTh>Resource Requests</BackTh>
            <BackTh>Resource Limits</BackTh>
          </tr>
        </thead>
        <tbody>
          {pod.containers.map((container) => (
            <tr key={container.name}>
              <td>{container.name}</td>
              <td>
                {container.resources?.requests?.memory &&
                  `Memory: ${container.resources?.requests?.memory}`}
                <br />
                {container.resources?.requests?.memory &&
                  `CPU: ${container.resources?.requests?.cpu}`}
              </td>
              <td>
                {container.resources?.limits?.memory &&
                  `Memory: ${container.resources?.limits?.memory}`}
                <br />
                {container.resources?.limits?.memory &&
                  `CPU: ${container.resources?.limits?.cpu}`}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledFontTable>
    </NestedTableContainer>
  );
};
const PodRow: React.FC<{
  pod: PodOutput;
  showExpanded: boolean;
}> = ({ pod, showExpanded }) => {
  const cells = getPodTableCells(pod);
  return (
    <ExpandedTableRow
      key={pod.name}
      cells={cells}
      expandComponent={
        showExpanded ? (
          <>
            <Margin>
              <Divider />
              <ContainersResourceTable pod={pod} />
              <PodEvictedMessage>
                <PodEvictedMessageTitle>Message:</PodEvictedMessageTitle>
                {pod.message}
              </PodEvictedMessage>
            </Margin>
          </>
        ) : undefined
      }
    />
  );
};

const NodeEvictedPodsDetails: React.FC<{
  check: NodeEvictedPodsCheck;
  setSelectedCheckType: StateInSearchParamsType[1];
}> = ({ check, setSelectedCheckType }) => {
  const checkOutput = check.output;
  const isMemoryPressure = checkOutput?.pressures?.isMemoryPressure;
  const isDiskPressure = checkOutput?.pressures?.isDiskPressure;
  const isPidPressure = checkOutput?.pressures?.isPidPressure;
  const isPressure = isMemoryPressure || isDiskPressure || isPidPressure;

  const { serviceId } = useParams<{ serviceId: string }>();
  const orderedPods = useMemo(
    () =>
      serviceId
        ? orderBy(
            checkOutput.pods,
            (p) => [p.serviceId === serviceId, "priority", "name"],
            ["desc", "desc", "asc"]
          )
        : orderBy(checkOutput.pods, ["priority", "name"], ["desc", "asc"]),
    [checkOutput.pods, serviceId]
  );
  return (
    <>
      {!check.passed && checkOutput?.pods && checkOutput.pods.length > 0 && (
        <>
          <br />
          <BoldGrayText>Evicted Pods</BoldGrayText>
          <TableContainer>
            <StyledFontTable>
              <thead>
                <tr>
                  <th />
                  <th>Pod name</th>
                  <th>QOS</th>
                  <th>PriorityClass</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {orderedPods.map((pod) => (
                  <PodRow key={pod.name} pod={pod} showExpanded />
                ))}
              </tbody>
            </StyledFontTable>
          </TableContainer>

          {isPressure && (
            <Recommendations>
              View the{" "}
              <UnderlinedButton
                onClick={() => setSelectedCheckType("node-under-pressure")}
              >
                node pressure check
              </UnderlinedButton>{" "}
              to learn more
            </Recommendations>
          )}
        </>
      )}
      <br />
      <HiddenPodsInfo hiddenPodsCount={checkOutput?.hiddenPods} />

      {check.passed ? (
        checkOutput.isNotReady && !isPressure ? (
          <>
            <br />
            <NormalText>{notReadyInfo}</NormalText>
          </>
        ) : isPressure ? (
          <>
            <br />
            <Recommendations>
              View the{" "}
              <UnderlinedButton
                onClick={() => setSelectedCheckType("node-under-pressure")}
              >
                node pressure check
              </UnderlinedButton>{" "}
              to learn more
            </Recommendations>
          </>
        ) : null
      ) : null}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeEvictedPodsDetails;
