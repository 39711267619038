import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { TextFieldProps } from "@mui/material";

import { gray } from "../../../themes/colors";
import { Close16, MagnifyingGlass24 } from "../../../icons";
import { defaultSelectSize } from "../../../constants";
import { Size } from "../../../types";
import { TextInput } from "../TextInput/TextInput";
import styled from "styled-components";

const StyledInputAdornment = styled(InputAdornment)<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    `
    visibility: hidden;
    pointer-events: none;
  `}
`;

type SearchFieldProps = {
  onSearch: (searchValue: string) => void;
  value: string;
  label?: string;
  placeholder?: string;
  width?: string;
  showCloseIcon?: boolean;
  classNames?: {
    container?: string;
    input?: string;
  };
  ariaLabel?: string;
  size?: Size;
};

export const SearchField: React.FC<SearchFieldProps> = ({
  onSearch,
  value,
  label,
  placeholder,
  width,
  showCloseIcon = true,
  classNames,
  ariaLabel,
  size = defaultSelectSize,
}) => {
  const shouldShowCloseIcon = value.length > 0 && showCloseIcon;
  return (
    <TextInput
      size={size as TextFieldProps["size"]}
      aria-label={ariaLabel}
      placeholder={placeholder}
      label={label}
      sx={{ width }}
      className={classNames?.container}
      onChange={(e) => onSearch(e.target.value)}
      value={value}
      InputProps={{
        className: classNames?.input,
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlass24 color={gray[500]} />
          </InputAdornment>
        ),
        endAdornment: showCloseIcon && (
          <StyledInputAdornment
            position="start"
            sx={{ marginRight: "0px" }}
            $disabled={!shouldShowCloseIcon}
          >
            <Close16 color={gray[500]} onClick={() => onSearch("")} />
          </StyledInputAdornment>
        ),
      }}
    />
  );
};
