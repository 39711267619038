import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";

import { InstallationScriptStepsNumber, StepsNumber } from "./types";

export const dispatchStepInformation = (stepNumber: number): void => {
  const AnalyticsSteps = AnalyticEvents.Integrations.Kubernetes["Steps"];

  dispatchEvent(AnalyticEvents.Integrations.Kubernetes.ClickedNextButton, {
    currentStep: AnalyticsSteps[stepNumber] ?? "Unknown",
  });
};

export const handleNext = (
  currentInstallStep: StepsNumber | InstallationScriptStepsNumber,
  setCurrentInstallStep: React.Dispatch<
    React.SetStateAction<StepsNumber | InstallationScriptStepsNumber>
  >,
  isSupportedDemoAccount?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): void => {
  dispatchStepInformation(currentInstallStep);
  if (!isSupportedDemoAccount && currentInstallStep === StepsNumber.Welcome) {
    setCurrentInstallStep(StepsNumber.Cluster_Name);
    return;
  }
  setCurrentInstallStep(currentInstallStep + 1);
};

export const handleBack = (
  currentInstallStep: StepsNumber | InstallationScriptStepsNumber,
  setCurrentInstallStep: React.Dispatch<
    React.SetStateAction<StepsNumber | InstallationScriptStepsNumber>
  >,
  isSupportedDemoAccount?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): void => {
  if (currentInstallStep === StepsNumber.Install_Command) {
    dispatchEvent(AnalyticEvents.Integrations.Kubernetes.ClickedBackResetSetup);
  }
  if (
    !isSupportedDemoAccount &&
    currentInstallStep === StepsNumber.Cluster_Name
  ) {
    setCurrentInstallStep(StepsNumber.Welcome);
    return;
  }
  setCurrentInstallStep(currentInstallStep - 1);
};

export const isWindows = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const windowsPlatforms = /(win32|win64|windows|wince)/i;
  return windowsPlatforms.test(userAgent);
};
