import { useMemo } from "react";

import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import CertManagerCertificate, {
  CertManagerCertificateType,
} from "@/components/ResourceView/resources/certManagerCertificate";

export const useCertificateDataAsResource = (): CertManagerCertificate => {
  const { data: certificateData } = useCertificateData();
  const clusterName = certificateData?.data?.object.header.clusterName || "";
  const agentId = useActiveAgent(clusterName) ?? "";

  return useMemo(
    () =>
      new CertManagerCertificate(
        clusterName,
        (certificateData?.data.json || {}) as CertManagerCertificateType,
        agentId
      ),
    [agentId, certificateData?.data.json, clusterName]
  );
};
