import { Container } from "kubernetes-types/core/v1.d";
import React from "react";
import styled from "styled-components";

import { DescribeSectionDivider } from "../../common/DescribeSectionDivider";
import { DescribeDataItem } from "../../common/DescribeItem";
import { GrayTag } from "../../common/DescribeTagListItem";

const Mounts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

export const ContainerMounts: React.FC<{ container: Container }> = ({
  container,
}) => {
  return container.volumeMounts ? (
    <>
      <DescribeSectionDivider title={"MOUNTS"} />
      <DescribeDataItem name="Mounts">
        <Mounts>
          {container.volumeMounts.map((mount) => (
            <div key={mount.mountPath}>
              <GrayTag size="small">{mount.mountPath}</GrayTag> from{" "}
              {mount.name}
              {mount.readOnly && " (ro)"}
            </div>
          ))}
        </Mounts>
      </DescribeDataItem>
    </>
  ) : null;
};
