import React, { useMemo, useState } from "react";
import {
  Input,
  OptionType,
  Select,
  Typography,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import TriggerConditionsSection from "../common/TriggerConditionsSection";
import { useFormValidations } from "../../../../shared/context/ValidationsProvider";

import { ConfigurationVariables } from "@/generated/monitorsApi";
const NodeTriggerText = (
  <>
    Node in <b>unhealthy</b> state for more than
  </>
);

const isValidPositiveNumber = (value: string) => {
  const number = Number(value);
  if (isNaN(number) || number < 1) {
    return false;
  }
  return true;
};

const UnitType = {
  Minutes: "m",
  Seconds: "s",
};
const validateThreshold = (creationThreshold: string) => {
  return isValidPositiveNumber(creationThreshold)
    ? undefined
    : "Please enter a positive number";
};
const ThresholdContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  height: 2.5rem;
`;

export const NodeTriggersSection: React.FC<{
  id: string;
  variables: ConfigurationVariables | undefined;
  handleChange: (variables: ConfigurationVariables) => void;
}> = ({ id, variables, handleChange }) => {
  const { errors, setError } = useFormValidations();
  const thresholdValidationKey = `${id}-threshold`;
  const thresholdError = errors[thresholdValidationKey];
  const fullNodeCreationThreshold = variables?.nodeCreationThreshold ?? "3m";
  const defaultNodeCreationThreshold = fullNodeCreationThreshold.slice(0, -1);

  const [unitType, setUnitType] = useState<string>(
    fullNodeCreationThreshold.slice(-1)
  );
  const options: OptionType<string>[] = useMemo(
    () =>
      Object.entries(UnitType).map(([key, value]) => ({
        label: key,
        value: value,
      })),
    []
  );

  const [nodeCreationThreshold, setNodeCreationThreshold] = useState(
    defaultNodeCreationThreshold
  );
  const handleThresholdChange = (value: string, unitType: string) => {
    setNodeCreationThreshold(value);
    const errorMessage = validateThreshold(value);
    if (!errorMessage) {
      handleChange({
        ...variables,
        nodeCreationThreshold: value + unitType,
      });
    }
    setError(thresholdValidationKey, errorMessage);
  };

  const handleUnitChange = (value: string) => {
    setUnitType(value);
    handleChange({
      ...variables,
      nodeCreationThreshold: nodeCreationThreshold + value,
    });
  };
  return (
    <>
      <TriggerConditionsSection
        id="duration"
        variables={variables}
        handleChange={handleChange}
        triggerText={NodeTriggerText}
      />
      <ThresholdContainer>
        <Typography size="medium" variant="text">
          Node startup grace threshold
        </Typography>
        <Input
          type="number"
          size="small"
          min="0"
          width="8rem"
          value={nodeCreationThreshold}
          errorMessage={thresholdError}
          placeholder="e.g. 3"
          onChange={(e) => handleThresholdChange(e.target.value, unitType)}
        />
        <Select
          size="small"
          width="7rem"
          listZIndex="6"
          options={options}
          value={options.find((o) => o.value === unitType)}
          onChange={(selected) => handleUnitChange(selected.value)}
        />
      </ThresholdContainer>
    </>
  );
};
