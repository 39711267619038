import React from "react";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PVCCreationCheck from "../PVCCreationCheck";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import { StyledTable } from "../../../../common/styles";

export const Row = styled.tr`
  line-height: 1rem;
`;

export const CodeTable = styled(StyledTable)`
  font-family: Roboto Mono;
`;

const PVCCreation: React.FC<{
  check: PVCCreationCheck;
}> = ({ check }) => {
  const checkOutput = check.output;

  return checkOutput?.events?.length ? (
    <>
      <br />
      <BoldGrayText>PVC Events</BoldGrayText>
      <CodeTable>
        <thead>
          <tr>
            <th>Type</th>
            <th>Reason</th>
            <th>Age</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {checkOutput.events.map((eventOutput) => (
            <Row key={eventOutput.reason}>
              <td>{eventOutput.type}</td>
              <td>{eventOutput.reason}</td>
              <td>{eventOutput.age}</td>
              <td>{eventOutput.message}</td>
            </Row>
          ))}
        </tbody>
      </CodeTable>
    </>
  ) : null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PVCCreation;
