import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Check16 } from "@komodorio/design-system/icons";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";

import { FeatureTag, TagText } from "../../shared/components/FeatureTag";

import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";
import { Alert } from "@/components/common/Alert/Alert";

const Root = styled.section`
  margin: 43px 55px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 54px;
`;

const CheckList = styled.div`
  border: 2px solid ${muiColors.gray[300]};
  border-radius: 6px;
  padding-inline: 24px;
  padding-block: 22px;

  & ul {
    margin: 0;
    padding-left: 0px;
    list-style-type: none;
  }
  & li {
    margin-top: 16px;
  }
`;

type FeaturePreviewPageProps = {
  title: string;
  children: React.ReactNode;
  bullets: string[];
  note?: string;
  img: React.ReactNode;
  tag?: TagText;
  overrideTagLabel?: string;
  joinButton?: boolean;
  action?: React.ReactNode;
  ariaLabel?: string;
  bulletsHeader?: string;
  bannerTitle?: string;
  bannerText?: string;
};

export const FeaturePreviewPage = ({
  title,
  children,
  bullets,
  note,
  img,
  tag,
  overrideTagLabel,
  joinButton,
  action,
  ariaLabel,
  bulletsHeader = "Included",
  bannerTitle,
  bannerText,
}: FeaturePreviewPageProps): JSX.Element => {
  const [hasClickedJoinBeta, setHasClickedJoinBeta] = React.useState(false);
  const [showClickedText, setShowClickedText] = React.useState(false);

  const onJoinBetaClick = useCallback(() => {
    if (hasClickedJoinBeta) {
      return;
    }

    setHasClickedJoinBeta(true);
    setShowClickedText(true);
    dispatchEvent(AnalyticEvents.ComingSoon.JoinPrivateBeta, {
      feature: title,
    });
  }, [title, hasClickedJoinBeta]);

  return (
    <Root aria-label={ariaLabel}>
      {(bannerTitle || bannerText) && (
        <>
          <Alert
            severity="warning"
            title={bannerTitle}
            text={bannerText}
            icon={WarningAmberOutlined as React.FC}
            showCloseButton
          />
          <br />
        </>
      )}
      <Header>
        <Typography variant="h3" color="text.primary">
          {title}
        </Typography>
        {tag ? (
          <FeatureTag tag={tag} overrideTagLabel={overrideTagLabel} />
        ) : null}
      </Header>
      <Content>
        <Box
          sx={{
            display: "flex",
            flexBasis: "434px",
            flexGrow: "0",
            gap: "32px",
            flexDirection: "column",
          }}
        >
          {typeof children === "string" ? (
            <Typography variant="body1" color="text.secondary">
              {children}
            </Typography>
          ) : (
            children
          )}
          <CheckList>
            <Typography variant="h4">{bulletsHeader}</Typography>
            <ul>
              {bullets.map((item) => (
                <li key={item}>
                  <Box
                    sx={{ display: "flex", gap: "7px", alignItems: "center" }}
                  >
                    <CheckCircle fontSize="small" color="primary" />
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color={muiColors.gray[800]}
                    >
                      {item}
                    </Typography>
                  </Box>
                </li>
              ))}
            </ul>
          </CheckList>
          {note ? (
            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight={500}
              fontStyle="italic"
            >
              {note}
            </Typography>
          ) : null}
          {action ? <div>{action}</div> : null}
          {joinButton && (
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={onJoinBetaClick}
                disabled={hasClickedJoinBeta}
                aria-label={`request early access for ${title}`}
                sx={{ width: "fit-content" }}
              >
                Request early access
              </Button>
              {showClickedText && (
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Check16 color={muiColors.green[700]} />
                  <Typography variant="button" color={muiColors.green[700]}>
                    Thank you! we’ll be in touch soon
                  </Typography>
                </Stack>
              )}
            </Stack>
          )}
        </Box>
        <Box sx={{ flexGrow: "2", maxWidth: "1170px" }}>{img}</Box>
      </Content>
    </Root>
  );
};
