/* tslint:disable */
/* eslint-disable */
/**
 * Agents Service API
 * This is an API that exposes agent-related functionality
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface AccountPartial
 */
export interface AccountPartial {
    /**
     * 
     * @type {string}
     * @memberof AccountPartial
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPartial
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPartial
     */
    'trialEndAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountPartial
     */
    'plan'?: string | null;
}
        /**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'clusterName': string;
    /**
     * 
     * @type {object}
     * @memberof Agent
     */
    'configuration': object;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'lastSeenAlive': string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'installationId': string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'accountId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Agent
     */
    'inactive': boolean;
}
        /**
 * 
 * @export
 * @interface AgentWithAccount
 */
export interface AgentWithAccount {
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'clusterName': string;
    /**
     * 
     * @type {object}
     * @memberof AgentWithAccount
     */
    'configuration': object;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'lastSeenAlive': string;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'installationId': string;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'accountId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWithAccount
     */
    'inactive': boolean;
    /**
     * 
     * @type {AccountPartial}
     * @memberof AgentWithAccount
     */
    'account'?: AccountPartial;
}
        /**
 * 
 * @export
 * @interface AgentWithAccountAllOf
 */
export interface AgentWithAccountAllOf {
    /**
     * 
     * @type {AccountPartial}
     * @memberof AgentWithAccountAllOf
     */
    'account'?: AccountPartial;
}
    
        /**
* Get all active agents
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AgentsActiveGetUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/agents/active`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* List agents
* @param params AgentsApiApiV1AgentsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AgentsGetUrl = (params: AgentsApiApiV1AgentsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/agents`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.installationId !== undefined) {
            localVarQueryParameter['installationId'] = params.installationId;
    }
    if (params.configurationParam !== undefined) {
            localVarQueryParameter['configurationParam'] = params.configurationParam;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.inactive !== undefined) {
            localVarQueryParameter['inactive'] = params.inactive;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    if (params.distinct !== undefined) {
            localVarQueryParameter['distinct'] = params.distinct;
    }
    if (params.fromLastSeenAliveEpoch !== undefined) {
            localVarQueryParameter['fromLastSeenAliveEpoch'] = params.fromLastSeenAliveEpoch;
    }
    if (params.toLastSeenAliveEpoch !== undefined) {
            localVarQueryParameter['toLastSeenAliveEpoch'] = params.toLastSeenAliveEpoch;
    }
    if (params.offset !== undefined) {
            localVarQueryParameter['offset'] = params.offset;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get Agent by ID
* @param params AgentsApiApiV1AgentsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AgentsIdGetUrl = (params: AgentsApiApiV1AgentsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1AgentsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/agents/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1AgentsGet operation in AgentsApi.
* @export
* @interface AgentsApiApiV1AgentsGetRequest
*/
export interface AgentsApiApiV1AgentsGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly clusterNames?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'clusterName' | 'configuration' | 'lastSeenAlive' | 'createdAt' | 'installationId' | 'accountId' | 'inactive'>}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly fields?: Array<'id' | 'clusterName' | 'configuration' | 'lastSeenAlive' | 'createdAt' | 'installationId' | 'accountId' | 'inactive'>

    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly installationId?: string

    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly configurationParam?: string

    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {boolean}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly inactive?: boolean

    /**
    * 
    * @type {number}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database. desc syntax is &#x60;-fieldName&#x60;. e.g. -lastSeenAlive
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly sort?: string

    /**
    * distinct by fields
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly distinct?: string

    /**
    * Start LastSeenAlive in epoch format
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly fromLastSeenAliveEpoch?: string

    /**
    * End LastSeenAlive in epoch format
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly toLastSeenAliveEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly offset?: number
}

/**
* Request parameters for apiV1AgentsIdGet operation in AgentsApi.
* @export
* @interface AgentsApiApiV1AgentsIdGetRequest
*/
export interface AgentsApiApiV1AgentsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsIdGet
    */
    readonly accountId?: string
}


    
