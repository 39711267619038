import React, { memo } from "react";

import { AriaLabels } from "../../../../shared/config/ariaLabels";
import {
  formatTooltipCPUTick,
  formatTooltipMemoryTick,
} from "../../../Metrics/utils";
import { UsageIcon } from "../../../Metrics/Legend";
import { TooltipContentEntry } from "../../../Metrics/Tooltip/TooltipContentEntry";
import { useTooltipMetricsAnalytics } from "../../../Metrics/Tooltip/useTooltipMetricsAnalytics";
import {
  MetricsTooltipContainer,
  MetricsTooltipGrid,
} from "../../../Metrics/Tooltip/metricsTooltipStyles";
import { TooltipDateTimeTitle } from "../../../Metrics/Tooltip/TooltipDateTimeTitle";
import { isTooltipDataValid } from "../../../Metrics/Tooltip/tooltipUtils";

import { sortTooltipPayload } from "./utils";
import { TooltipPayload } from "./types";

type TooltipProps = {
  payload: TooltipPayload[];
  dataTransformFunction: (data: string) => { value: string; format: string };
  label: number;
};

const MemoizedTooltip = memo(
  ({ payload, label, dataTransformFunction }: TooltipProps) => {
    const statContent = payload.map((data) => {
      const { value: transformedVal, format: transformedFormat } =
        dataTransformFunction(data.value.toString() ?? "");

      return (
        <TooltipContentEntry
          key={transformedVal}
          value={transformedVal}
          format={transformedFormat}
          title={data.name}
          icon={<UsageIcon fillColor={data.color} />}
        />
      );
    });

    useTooltipMetricsAnalytics();

    return (
      <MetricsTooltipContainer
        aria-label={AriaLabels.MetricsAvailability.Tooltip}
      >
        <TooltipDateTimeTitle label={label} />
        <MetricsTooltipGrid>{statContent}</MetricsTooltipGrid>
      </MetricsTooltipContainer>
    );
  }
);

type Props = {
  payload?: unknown[];
  label?: number;
};

export const PodContainersMemoryMetricsTooltip: React.FC<Props> = (props) => {
  if (!isTooltipDataValid(props)) return null;
  const payload = props.payload as TooltipPayload[];
  return (
    <MemoizedTooltip
      payload={sortTooltipPayload(payload)}
      label={props.label ?? 0}
      dataTransformFunction={formatTooltipMemoryTick}
    />
  );
};

export const PodContainersCpuMetricsTooltip: React.FC<Props> = (props) => {
  if (!isTooltipDataValid(props)) return null;
  const payload = props.payload as TooltipPayload[];

  return (
    <MemoizedTooltip
      payload={sortTooltipPayload(payload)}
      label={props.label ?? 0}
      dataTransformFunction={formatTooltipCPUTick}
    />
  );
};
