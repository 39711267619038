import { getAppConfig } from "../../config/appConfig";
import { useAPIGet, useAPIPost } from "../common-api/request";
import { CommonApiResponse } from "../../types/commonApiResponse";

import {
  EndpointsRequestMap,
  EndpointsResponseMap,
} from "./requestResponseMaps";

/**
 * @deprecated use react-query instead.
 */
export const useInsightsAPIGet = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<EndpointsResponseMap[T]> => {
  return useAPIGet<EndpointsResponseMap[T]>(
    relativePath,
    parameters,
    pause,
    getAPIBasePath()
  );
};

/**
 * @deprecated use react-query instead.
 */
export const useInsightsAPIPost = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  reqBody: EndpointsRequestMap[T],
  pause = false
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<EndpointsResponseMap[T]> => {
  return useAPIPost<EndpointsResponseMap[T]>(
    relativePath,
    reqBody,
    pause,
    getAPIBasePath()
  );
};

const getAPIBasePath = (): string => {
  const base = getAppConfig().insightsAPIServerURL ?? "";
  return base + "/api/v1";
};
