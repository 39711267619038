import { useCallback, useEffect, useMemo, useState } from "react";

import { LocalStorageItem } from "@/shared/utils/localStorageSettings";

export const useStateInLocalStorage = (
  item: LocalStorageItem,
  defaultValue: string
): [string, (value: string) => void] => {
  const [state, setState] = useState<string>(item.get() ?? defaultValue);

  const LSState = useMemo(() => state || defaultValue, [defaultValue, state]);

  const setLSState = useCallback(
    (value: string) => {
      item.set(value);
      //dispatch event so all other instances using this localStorageItem would get updated
      window.dispatchEvent(new Event("storage"));
    },
    [item]
  );

  useEffect(() => {
    if (!state) {
      setLSState(defaultValue);
    }
  }, [defaultValue, setLSState, state]);

  const onStorageEvent = useCallback(
    (event: StorageEvent | CustomEvent) => {
      const storageEvent = event as StorageEvent;
      if (storageEvent?.key && storageEvent.key !== item.key) {
        return;
      }
      setState(item.get() ?? defaultValue);
    },
    [defaultValue, item]
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageEvent);
    return () => window.removeEventListener("storage", onStorageEvent);
  }, [onStorageEvent]);

  return [LSState, setLSState];
};
