import { getAppConfig } from "../../config/appConfig";
import { useApiGetWithUrl } from "../common-api/request";
import { CommonApiResponse } from "../../types/commonApiResponse";

import {
  EndpointRequestPathMap,
  EndpointsRequestMap,
  EndpointsResponseMap,
  MonitorParameters,
} from "./requestResponseMaps";

/**
 * @deprecated use react-query instead. see implementations in services/web/src/shared/hooks/monitors-api/client
 */
export const useMonitorsAPIGet = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<EndpointsResponseMap[T]> => {
  let url = "";
  try {
    url = EndpointRequestPathMap[relativePath](
      parameters as MonitorParameters,
      getAPIBasePath()
    );
  } catch (err) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useApiGetWithUrl<EndpointsResponseMap[T]>(url, pause && !!url);
};

const getAPIBasePath = (): string => {
  return getAppConfig().monitorsAPIServerURL ?? "";
};
