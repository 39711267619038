import { useEffect, useMemo, useState } from "react";

import { columnsConfig } from "../hooks/nodeAutoscalerTableHooks";

import { useGetFilterValues } from "@/components/k8sAddons/hooks/filters/useGetFilterValues";
import { useGetAllAvailableClusters } from "@/components/k8sAddons/hooks/useGetAllAvailableClusters";
import { useFilterListFromUrlAsGenericFilters } from "@/components/k8sAddons/hooks/filters/useFilterListFromUrlAsGenericFilters";

export const useClusterNames = () => {
  const [enableRequest, setEnableRequest] = useState(false);

  const filter = useFilterListFromUrlAsGenericFilters(
    columnsConfig.cluster.field
  );

  const { data } = useGetAllAvailableClusters({
    enableRequest,
    filter: filter ?? undefined,
  });

  const clustersFromFilters = useGetFilterValues(columnsConfig.cluster.field);

  const allClusterOptions = useMemo<string[]>(
    () =>
      data?.data.items
        ?.map((item) => item.key)
        .filter((key): key is string => key !== undefined) ?? [],
    [data?.data.items]
  );

  const allClusters = clustersFromFilters ?? allClusterOptions;

  useEffect(() => {
    if (!clustersFromFilters && !data) {
      setEnableRequest(true);
      return;
    }
  }, [clustersFromFilters, data]);

  return {
    clusterNames: allClusters ?? [],
    hasClusters: !allClusters ? false : allClusters.length > 0,
  };
};
