import React, { useState } from "react";
import styled, { css } from "styled-components";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { useDebouncedCallback } from "use-debounce";
import { muiColors } from "@komodorio/design-system";

import { notifyDDError } from "../../../shared/hooks/exceptionManagement";
import { AriaLabels } from "../../../shared/config/ariaLabels";

const iconButtonsStyle = css`
  display: unset;
  margin: 24px 8px 8px;
  color: ${muiColors.gray[500]};
  height: 40px;
`;

const CopyIconButton = styled(IconButton)`
  && {
    ${iconButtonsStyle}
    float: right;
  }
`;

const CloseIconButton = styled(IconButton)`
  && {
    ${iconButtonsStyle}
    float: left;
  }
`;

/** @deprecated Use CopyButton from @komodorio/design-system/komodor-ui */
export const CopyButton: React.FC = () => {
  const [anchorCopyEl, setAnchorCopyEl] = useState<null | HTMLElement>(null);

  const { callback: debouncedSetAnchorCopyEl } = useDebouncedCallback(
    setAnchorCopyEl,
    1250
  );

  const onCopy = (event: React.MouseEvent<HTMLElement>) => {
    try {
      navigator.clipboard.writeText(window.location.href);
      if (!anchorCopyEl) {
        setAnchorCopyEl(event.currentTarget);
        debouncedSetAnchorCopyEl(null);
      }
    } catch (error) {
      notifyDDError(error as Error);
    }
  };

  const open = Boolean(anchorCopyEl);
  const id = open ? "copy-label-popper" : undefined;

  return (
    <>
      <CopyIconButton
        aria-describedby={id}
        aria-label={AriaLabels.EventDrawer.CopyButton}
        size="medium"
        onClick={onCopy}
      >
        <ContentCopy />
      </CopyIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorCopyEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: { sx: { borderRadius: "20px", boxShadow: "none" } },
        }}
      >
        <Chip
          label={<Typography variant="h6">COPIED!</Typography>}
          size="small"
        />
      </Popover>
    </>
  );
};

/** @deprecated Use CloseButton from @komodorio/design-system/komodor-ui */
export const CloseButton: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <CloseIconButton
      aria-label={AriaLabels.EventDrawer.CloseButton}
      size="medium"
      onClick={onClose}
    >
      <Close />
    </CloseIconButton>
  );
};
