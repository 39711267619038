import { useEffect, useState } from "react";

import { useAddonContext } from "../context/useAddonContext";

import { useRefetchIntervalFromContext } from "./useRefetchIntervalFromContext";

import { useGetAddonAggregations } from "@/shared/hooks/k8s-add-ons/useGetAddonAggregations";
import { GenericFilter } from "@/generated/addonsApi";

export const clusterNameFilterCategory = "clusterName";

type Props = {
  filter?: GenericFilter[] | undefined;
  enableRequest: boolean;
};

export const useGetAllAvailableClusters = ({
  filter = [],
  enableRequest,
}: Props) => {
  const [isPaginating, setIsPaginating] = useState(true);

  const { addonType, addonEntity } = useAddonContext();

  const refetchInterval = useRefetchIntervalFromContext(isPaginating);

  const clustersData = useGetAddonAggregations(
    {
      addon: addonType,
      entity: addonEntity,
      getEntityAggregationsRequest: {
        groupBy: clusterNameFilterCategory,
        aggregation: "count",
        filter,
      },
    },
    {
      refetchInterval: isPaginating ? refetchInterval : undefined,
      enabled: enableRequest,
    }
  );

  useEffect(() => {
    const { data } = clustersData;
    const hasData = data && data.data.items.length > 0;
    if (hasData) setIsPaginating(false);
  }, [clustersData]);

  return clustersData;
};
