import React from "react";
import Divider from "@mui/material/Divider";
import styled from "styled-components";

import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { Addon, Entity } from "@/generated/addonsApi";
import { Filters } from "@/components/k8sAddons/addons/CertManager/ControllersPage/Filters";
import { CertificateControllersPageTable } from "@/components/k8sAddons/addons/CertManager/ControllersPage/CertificateControllersPageTable";
import { useAddOnsRefetchInterval } from "@/components/k8sAddons/hooks/useAddOnsRefetchInterval";
import {
  DEFAULT_CERT_MANAGER_CONTROLLERS_SORT_BY_DIRECTION,
  DEFAULT_CERT_MANAGER_CONTROLLERS_SORT_BY_FIELD,
} from "@/components/k8sAddons/addons/CertManager/ControllersPage/controllersPageConstants";
import { AddonPageContainer } from "@/components/k8sAddons/components/AddonPageContainer";

const StyledDivider = styled(Divider)`
  && {
    width: 100%;
    height: 1px;
  }
`;

export const ControllersPage: React.FC = () => {
  return (
    <AddonContextProvider
      addonType={Addon.CertManager}
      addonEntity={Entity.Controller}
      setInitialClustersFilter={true}
      refetchInterval={useAddOnsRefetchInterval()}
      defaultSortModel={{
        field: DEFAULT_CERT_MANAGER_CONTROLLERS_SORT_BY_FIELD,
        sort: DEFAULT_CERT_MANAGER_CONTROLLERS_SORT_BY_DIRECTION,
      }}
    >
      <AddonPageContainer>
        <Filters />
        <StyledDivider variant={"fullWidth"} orientation={"horizontal"} />
        <CertificateControllersPageTable />
      </AddonPageContainer>
    </AddonContextProvider>
  );
};
