import { MonitorType } from "../../generated/monitorsApi";
import { useGetGroupedMonitorIssues } from "../hooks/monitors-api/client/issues/useGetMonitorIssues";

const REFRESH_OPEN_ISSUES_INTERVAL = 5000;

export const useOpenIssues = () => {
  const openIssuesFromApi = useGetGroupedMonitorIssues(
    {
      order: "DESC",
      types: [
        MonitorType.Availability,
        MonitorType.Node,
        MonitorType.Pvc,
        MonitorType.Workflow,
      ],
      fields: [
        "id",
        "type",
        "cluster",
        "namespace",
        "shortResourceName",
        "services",
        "eventTime",
        "reasons",
      ],
      includeOpen: true,
    },
    { refetchInterval: REFRESH_OPEN_ISSUES_INTERVAL }
  );

  return openIssuesFromApi;
};
