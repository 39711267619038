import React from "react";
import { sortBy } from "lodash";
import { useParams } from "react-router-dom";
import { Button } from "@komodorio/design-system/deprecated";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeHealthyUserPodsCheck from "../NodeHealthyUserPodsCheck";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import Recommendations from "../../../../common/Recommendations";
import ExpandedTableRow from "../../../../common/ExpandedTableRow";
import {
  ColoredLogsIcon,
  ContainerStateField,
  ContainerStateFieldTitle,
  Margin,
  NoUnderlinedLink,
  FixedContent,
  StyledFontTable,
  TableContainer,
  MarginTop,
} from "../../../../common/styles";
import { FailedContainer, PodsOutput } from "../../../CommonTypes";
import HiddenPodsInfo from "../../../../common/HiddenPodsInfo";

import { NestedTableContainer } from "./NodeEvictedPodsDetails";

const POD_HEALTH_RECOMMENDATIONS =
  "View the reason for unhealthy pods, it might be connected to the node issue";

const NodeHealthyUserPodsDetails: React.FC<{
  check: NodeHealthyUserPodsCheck;
}> = ({ check }) => {
  const checkOutput = check.output;
  const { serviceId } = useParams<{ serviceId: string }>();

  return (
    <>
      {checkOutput?.pods && checkOutput.pods.length > 0 && (
        <>
          <br />
          <Recommendations>{POD_HEALTH_RECOMMENDATIONS}</Recommendations>
          <br />
          <BoldGrayText>Unhealthy Pods</BoldGrayText>
          <TableContainer>
            <StyledFontTable>
              <thead>
                <tr>
                  <th />
                  <th>Pod name</th>
                  <th>Phase</th>
                  <th>Failed containers</th>
                </tr>
              </thead>
              <tbody>
                {sortBy(
                  checkOutput.pods,
                  (p) => (serviceId ? p.serviceId === serviceId : p.name),
                  ["desc"]
                ).map((pod) => (
                  <FailedPodRow key={pod.name} pod={pod} />
                ))}
              </tbody>
            </StyledFontTable>
          </TableContainer>
        </>
      )}
      <br />
      <HiddenPodsInfo hiddenPodsCount={checkOutput?.hiddenPods} />
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeHealthyUserPodsDetails;

export const FailedPodRow: React.FC<{
  pod: PodsOutput;
}> = ({ pod }) => {
  const isFailedContainer = pod.failedContainers?.length > 0;
  const containersNames = isFailedContainer
    ? pod.failedContainers.map((c) => c.name)?.join(", ")
    : "";

  return (
    <ExpandedTableRow
      key={pod.name}
      cells={[
        <>
          {pod.name} <br />
          {pod?.serviceId && (
            <>
              (
              <NoUnderlinedLink to={`/main/deep-dive/${pod.serviceId}`}>
                View Service
              </NoUnderlinedLink>
              )
            </>
          )}
        </>,
        pod.phase,
        containersNames,
      ]}
      expandComponent={
        isFailedContainer ? (
          <>
            {pod.failedContainers?.length === 1 ? (
              <ContainerStateContent container={pod.failedContainers[0]} />
            ) : (
              <Margin>
                <NestedTableContainer>
                  <StyledFontTable>
                    <tbody>
                      {pod.failedContainers?.map((c) => (
                        <ExpandedTableRow
                          nested
                          key={pod.name}
                          cells={[c.name]}
                          expandComponent={
                            <ContainerStateContent container={c} />
                          }
                        />
                      ))}
                    </tbody>
                  </StyledFontTable>
                </NestedTableContainer>
              </Margin>
            )}
            {pod?.serviceId && (
              <Margin>
                <NoUnderlinedLink
                  to={`/main/deep-dive/${pod.serviceId}?podId=notselected`}
                >
                  <MarginTop />
                  <Button variant="secondary" icon={ColoredLogsIcon}>
                    View Pods & Logs
                  </Button>
                </NoUnderlinedLink>
              </Margin>
            )}
          </>
        ) : undefined
      }
    />
  );
};

export const ContainerStateContent: React.FC<{
  container: FailedContainer;
}> = ({ container }) => {
  const state = container
    ? container?.currentState ?? container?.lastState
    : null;
  const isLastState = container?.currentState ? false : true;
  return (
    <Margin>
      <FixedContent>
        {container?.ready !== undefined && (
          <ContainerStateField>
            {`Ready:  ${container.ready}`}
          </ContainerStateField>
        )}
        {container?.started !== null && (
          <ContainerStateField>
            {`Started:  ${container.started}`}
          </ContainerStateField>
        )}
        {container?.restartCount !== undefined && (
          <ContainerStateField>
            {`Restart count:  ${container.restartCount}`}
          </ContainerStateField>
        )}
        {state && (
          <ContainerStateField>
            {isLastState && "Last "}
            {`State:  ${state.state}`}
          </ContainerStateField>
        )}
        {state?.reason && (
          <ContainerStateField>
            {`Reason:  ${state.reason}`}
          </ContainerStateField>
        )}
        {state?.message && (
          <ContainerStateField>
            <ContainerStateFieldTitle>Message:</ContainerStateFieldTitle>
            {state.message}
          </ContainerStateField>
        )}
        {state?.exitCode && (
          <ContainerStateField>
            {`Exit Code:  ${state.exitCode}`}
          </ContainerStateField>
        )}
      </FixedContent>
    </Margin>
  );
};
