import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import { OptionType } from "@komodorio/design-system/deprecated";

import {
  CostRightSizingSummary,
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../../../../generated/metricsApi";
import { useSaveContainersResources } from "../hooks/useSaveContainersResources";
import { useSaveMetricsParams } from "../hooks/useSaveMetricsParams";
import { useAddRecommendations } from "../hooks/useAddRecommendations";
import { RowsContent } from "../types";

import { Summary } from "./RecommendationContainer/Summary/Summary";
import { ContainersWrapper } from "./ContainersWrapper/ContainersWrapper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const useInitialData = (
  data: CostRightSizingSummary,
  modalStrategy: OptimizationStrategy,
  containersData?: RightSizingCostSummaryByService[]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  useSaveContainersResources(data);
  useSaveMetricsParams(data);
  useAddRecommendations({
    strategy: modalStrategy,
    containersRecommendations: containersData,
  });
};

interface Props {
  containers: RowsContent;
  containersData?: RightSizingCostSummaryByService[];
  modalStrategy: OptimizationStrategy;
  rowId?: string;
  disabled?: boolean;
  onChangeStrategy?: (rowId: string, selected: OptionType<string>) => void;
  rowData: CostRightSizingSummary;
  potentialSaving?: number;
}

export const Content: React.FC<Props> = ({
  containers,
  containersData,
  modalStrategy,
  rowId = "",
  disabled = false,
  onChangeStrategy,
  rowData,
  potentialSaving,
}) => {
  useInitialData(rowData, modalStrategy, containersData);

  return (
    <Container>
      <Summary
        rowId={rowId}
        serviceId={rowData.komodorServiceId}
        serviceName={rowData.service}
        kind={rowData.komodorServiceKind || ""}
        namespace={rowData.namespace}
        cluster={rowData.clusterName}
        potentialSaving={potentialSaving}
        isStrategySelectorDisabled={disabled}
        strategy={modalStrategy}
        onChangeStrategy={onChangeStrategy}
      />
      <Divider />
      <ContainersWrapper
        containers={containers}
        containersRecommendationsData={containersData}
        strategy={modalStrategy}
      />
    </Container>
  );
};
