import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";

import { MONITOR_CONFIGURATION_BY_ID_PATH } from "./useGetMonitorConfigurationById";

import {
  apiV1ConfigurationsIdDeleteUrl,
  ConfigurationsApiApiV1ConfigurationsIdDeleteRequest,
} from "@/generated/monitorsApi";

const deleteMonitorConfigurationById = async (
  apiClient: AxiosInstance,
  params: ConfigurationsApiApiV1ConfigurationsIdDeleteRequest
) => {
  const { data } = await apiClient.delete<void>(
    apiV1ConfigurationsIdDeleteUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useDeleteMonitorConfigurationById = () => {
  const apiClient = useMonitorsApiClient();
  return useMutation(
    [MONITOR_CONFIGURATION_BY_ID_PATH],
    async (params: ConfigurationsApiApiV1ConfigurationsIdDeleteRequest) =>
      deleteMonitorConfigurationById(apiClient, params)
  );
};
