import { GridColDef } from "@mui/x-data-grid-pro";
import { DataGrid, LinkCell } from "@komodorio/design-system/komodor-ui";

import {
  buildKomodorUid,
  KomodorUidParams,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { useModal } from "@/shared/context/modal/useModal";

const columns: GridColDef<KomodorUidParams>[] = [
  {
    field: "name",
    headerName: "Workload Name",
    flex: 1,
    renderCell: ({ row }) => <WorkloadNameCell {...row} />,
  },
  {
    field: "namespace",
    headerName: "Namespace",
    flex: 1,
  },
  {
    field: "kind",
    headerName: "Type",
    flex: 1,
  },
];

const WorkloadNameCell: React.FC<KomodorUidParams> = ({
  cluster,
  namespace,
  kind,
  name,
}) => {
  const { onClose: closeModal } = useModal();
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return (
    <LinkCell
      onClick={() => {
        closeModal();
        pushDrawer({
          drawerType: DrawerType.ResourceDrawerByData,
          cluster,
          namespace,
          resourceType: kind,
          resourceName: name,
        });
      }}
    >
      {name}
    </LinkCell>
  );
};

export const ConnectedWorkloadsTable: React.FC<{
  workloadsKomodorUids: KomodorUidParams[];
}> = ({ workloadsKomodorUids }) => {
  return (
    <DataGrid
      rows={workloadsKomodorUids}
      columns={columns}
      density="compact"
      getRowId={(row) =>
        buildKomodorUid({
          clusterName: row.cluster,
          namespace: row.namespace,
          kind: row.kind,
          resourceName: row.name,
        })
      }
      sx={{
        "& .MuiDataGrid-footerContainer": {
          minHeight: "unset",
          height: 40,
        },
      }}
    />
  );
};
