import React from "react";
import Box from "@mui/material/Box";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import { startCase } from "lodash";

import { ariaLabels } from "@/components/Settings/Users/const";

type FilterProps = {
  id: "cluster" | "namespace" | "role" | "policy" | "action";
  options: MuiSelectionOption<string>[];
  value?: MuiSelectionOption<string>[];
  onChange: (value: MuiSelectionOption<string>[]) => void;
};

export const Filter: React.FC<FilterProps> = ({
  id,
  options,
  value,
  onChange,
}) => {
  const label = startCase(id);
  const placeholder = `Select ${label}`;
  return (
    <Box flex={1}>
      <AdvancedMultiSelect
        options={options}
        label={label}
        ariaLabel={ariaLabels.userPermissionsDrawer.filters[id]}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Box>
  );
};
