import { useMemo } from "react";
import { orderBy } from "lodash";

interface ResourceStatus {
  status: string;
  statusMessage: string;
}

export interface Carp {
  status?: {
    phase?: string;
    message?: string;
    conditions?: {
      type: string;
      status: string;
      message: string;
      lastTransitionTime: Date;
    }[];
  };
}

export const useParseResourceStatusForHelm = (
  data: Carp,
  emptyResult: boolean,
  isSupported: boolean,
  errorMessage?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ResourceStatus => {
  return useMemo(() => {
    if (!isSupported) {
      return {
        status: "Unknown",
        statusMessage: errorMessage || "",
      };
    }
    if (emptyResult) {
      return {
        status: "Not Found",
        statusMessage: errorMessage ?? "Could not find resource",
      };
    }
    if (
      data?.status?.phase &&
      ["Active", "Error"].includes(data?.status?.phase)
    ) {
      return {
        status: data?.status?.phase,
        statusMessage: data?.status?.message ?? "",
      };
    }
    if (
      !data?.status?.phase &&
      data?.status?.conditions &&
      data?.status?.conditions.length > 0
    ) {
      const availableCondition = data.status.conditions.find(
        (c) => c.type === "Available"
      );
      if (availableCondition) {
        return {
          status: availableCondition.type,
          statusMessage: availableCondition.message,
        };
      }
      const orderedConditions = orderBy(
        data.status.conditions,
        "lastTransitionTime",
        "desc"
      );
      const lastCondition = orderedConditions[orderedConditions.length - 1];
      return {
        status:
          lastCondition.status === "False"
            ? "Not" + lastCondition.type
            : lastCondition.type,
        statusMessage: lastCondition.message,
      };
    }
    return {
      status: "Exists",
      statusMessage: "",
    };
  }, [
    data?.status?.conditions,
    data?.status?.message,
    data?.status?.phase,
    emptyResult,
    errorMessage,
    isSupported,
  ]);
};
