import { theme } from "@komodorio/design-system";
import { IconButton, Typography } from "@komodorio/design-system/deprecated";
import { Close24, InfoCircleOutlined16 } from "@komodorio/design-system/icons";
import React from "react";
import { TooltipWrapper } from "react-tooltip";
import styled from "styled-components";

import { AriaLabels } from "../../../../../../../../../shared/config/ariaLabels";
import { WorkflowRunWithResults } from "../../../../../../../../monitorsView/useWorkflowsRuns";
import { OpinionatedSummary } from "../../../../../../../../monitorsView/workflowRunView/checks/availability/detailsComponents/AvailabilitySummaryUtils";
import { IssueStatusTag } from "../../IssueStatusTag";
import { Header, Section, Title } from "../../styles";
import {
  IssueReasonTooltip,
  ISSUE_REASON_TOOLTIP,
} from "../../../tooltips/IssueReasonTooltip";
import { WORKFLOW_CONFIG_TYPES } from "../../../../../../../EventGroup/workflowIssues/constants";

import { ActionsPopover } from "./ActionPopover";
import { ViolationsStatusAction } from "./ViolationsStatusAction";

const Reason = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

const IconTooltipWrapper = styled(TooltipWrapper)`
  height: 16px;
`;

const HeaderSectionRoot = styled(Section)`
  padding-top: 0;
`;

type Props = {
  wfRun: WorkflowRunWithResults;
  reasons: string[];
  subtitle?: string;
  secondSubtitle?: JSX.Element;
  canManageMonitors?: boolean;
  onEditMonitorClick?: () => void;
  onClose: () => void;
  violationId?: string;
};

/** @deprecated Use DrawerHeader from @komodorio/design-system/komodor-ui  */
export const HeaderSection: React.FC<Props> = ({
  wfRun,
  reasons,
  secondSubtitle,
  subtitle,
  canManageMonitors,
  onEditMonitorClick,
  onClose,
  violationId,
}) => {
  const closed = !!wfRun?.closedAt;

  return (
    <HeaderSectionRoot>
      <IconButton
        icon={Close24}
        noBorder
        onClick={onClose}
        aria-label={AriaLabels.EventDrawer.CloseButton}
      />
      <Header>
        <Title>
          <Typography variant="headline" bold>
            {WORKFLOW_CONFIG_TYPES[wfRun.type]}
          </Typography>
          <Typography variant="headline">
            - {Array.from(new Set(reasons)).join(", ")}
          </Typography>
        </Title>
        <IssueStatusTag isClosed={closed} />
        {subtitle !== undefined && (
          <Typography size="medium" color={theme.foreground.fgSubtle}>
            {subtitle}
          </Typography>
        )}
      </Header>
      {violationId ? (
        <ViolationsStatusAction violationId={violationId} />
      ) : (
        <ActionsPopover
          canManageMonitors={canManageMonitors}
          onEditMonitorClick={onEditMonitorClick}
        />
      )}
    </HeaderSectionRoot>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getReasonSubtitleComponentByIssueSummary = (
  opinionatedSummary: OpinionatedSummary
) => {
  return (
    <>
      {opinionatedSummary.length > 0 && (
        <Reason>
          Error Reason:
          {opinionatedSummary.map((summary, index) => (
            <Reason key={index}>
              {summary.reason}
              <IconTooltipWrapper
                tooltipId={`${summary.reason}-${ISSUE_REASON_TOOLTIP}`}
              >
                <InfoCircleOutlined16 />
              </IconTooltipWrapper>
              <IssueReasonTooltip summary={summary} />
            </Reason>
          ))}
        </Reason>
      )}
    </>
  );
};
