import React, { useEffect, useState } from "react";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type ReportDescribeTabToDatadogParams = {
  divRef: React.RefObject<HTMLDivElement>;
  hasOwnerResources?: boolean;
  resourceId: string;
};
export const useReportDescribeTabToDatadog = ({
  divRef,
  hasOwnerResources,
  resourceId,
}: ReportDescribeTabToDatadogParams) => {
  const [ownerResourcesLoading, setOwnerResourcesLoading] = useState(true);
  const { setMainContainerDivRef, reportLoadingState } =
    useDatadogReportLoadingTimeContext();

  setMainContainerDivRef(divRef);

  useEffect(() => {
    reportLoadingState("resource", !resourceId);
    if (hasOwnerResources) {
      reportLoadingState("ownerResources", ownerResourcesLoading);
    }
  }, [
    hasOwnerResources,
    ownerResourcesLoading,
    reportLoadingState,
    resourceId,
  ]);

  return { setOwnerResourcesLoading };
};
