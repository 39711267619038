import { Line } from "recharts";

import { MetricsColorPalette, MetricsGraphIndicator } from "../constants";

import { sharedStraightLineProps } from "@/components/Metrics/constants";
import { LegendItem } from "@/components/Metrics/GenericLegend/GenericLegend";
import { TooltipItemProps } from "@/components/Metrics/types";

export const metricsLines = () => [
  <Line
    key="usage"
    dataKey={"usage"}
    stroke={MetricsColorPalette.usage}
    {...sharedStraightLineProps}
  />,
  <Line
    key="capacity"
    dataKey={"capacity"}
    stroke={MetricsColorPalette.capacity}
    {...sharedStraightLineProps}
  />,
  <Line
    key="allocated"
    dataKey={"allocated"}
    stroke={MetricsColorPalette.allocated}
    {...sharedStraightLineProps}
  />,
];

export const legendItems: LegendItem[] = [
  {
    label: "Usage",
    color: MetricsColorPalette.usage,
  },
  {
    label: "Capacity",
    color: MetricsColorPalette.capacity,
  },
  {
    label: "Allocated",
    color: MetricsColorPalette.allocated,
  },
];

export const tooltipItems = [
  {
    dataKey: "usage",
    title: "Usage",
    tooltipItemKey: "Usage",
    icon: <MetricsGraphIndicator metric="usage" />,
  },
  {
    dataKey: "capacity",
    title: "Capacity",
    tooltipItemKey: "Capacity",
    icon: <MetricsGraphIndicator metric="capacity" />,
  },
  {
    dataKey: "allocated",
    title: "Allocated",
    tooltipItemKey: "Allocated",
    icon: <MetricsGraphIndicator metric="allocated" />,
  },
] as TooltipItemProps[];
