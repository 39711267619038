import { Divider } from "@komodorio/design-system/deprecated";
import React, { useCallback, useMemo, useState } from "react";

import { AriaLabels } from "../../../../../../../shared/config/ariaLabels";
import {
  RESOURCE_PREFIX,
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
} from "../../../../../../../shared/config/urlSearchParamsKeys";
import { useDateFormatter } from "../../../../../../../shared/hooks/useDateFormatter";
import { useResourceView } from "../../../../../../ResourceView/ResourceProvider";
import MonitorRuleDrawer from "../../../../../../monitorsView/monitorsConfiguration/MonitorRuleDrawer";
import WorkflowCheck from "../../../../../../monitorsView/workflowRunView/checks";
import { PodCheckType } from "../../../../../../monitorsView/workflowRunView/checks/useWorkflowsChecks";
import { UnhealthyPodOutput } from "../../../../../../monitorsView/workflowRunView/checks/pod/types";
import { useHasPermissions } from "../../../../../../../shared/hooks/useUserMetadata/rbac";
import { Container, SectionName, SectionColumn } from "../common/styles";
import {
  HeaderSection,
  getReasonSubtitleComponentByIssueSummary,
} from "../common/sections/HeaderSection/HeaderSection";
import { SnapshotInfoDetail } from "../../../../../Alert/SnapshotInfo";
import PodEventsTable from "../../../../../table/PodEventsTable/PodEventsTable";
import ContainersTable from "../../../../../../monitorsView/common/availabilityComponents/ContainersTable";
import { parseIssueSummary } from "../../../../../../monitorsView/workflowRunView/checks/availability/detailsComponents/utils";
import { useGetPodResourceData } from "../../../PodPhaseDetails/useGetPodResource";

import FailedContainerLogsSection from "./sections/FailedContainerLogsSection";
import InfoSection from "./sections/InfoSection";
import FailedContainerMetricsSection from "./sections/FailedContainerMetricsSection";

import { PodWhereSection } from "@/components/common/ProcessList/details/PodPhaseDetails/PodWhereSection";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useGetMonitorConfigurationById } from "@/shared/hooks/monitors-api/client/monitors/useGetMonitorConfigurationById";
import { WorkflowRunWithResults } from "@/components/monitorsView/useWorkflowsRuns";
import { getWorkflowPodLabelsSelector } from "@/components/k8sAddons/addons/workflows/workflowUtils";

export const PodIssueDrawer: React.FC<{
  allChecks: WorkflowCheck[];
  wfRun: WorkflowRunWithResults;
  onClose?: () => void;
}> = ({ allChecks, wfRun, onClose }) => {
  const { canManageMonitors } = useHasPermissions();
  const [selectedRuleId, setSelectedRuleId] = useState<string>();

  const resourceFromResourceView = useResourceView();
  const [, setEventId] = useStateInSearchParams(
    (resourceFromResourceView.id ? RESOURCE_PREFIX + EVENTS_TAB_PREFIX : "") +
      EVENT_PARAM_KEY
  );
  const closed = !!wfRun?.closedAt;

  const clusterName = wfRun.cluster ?? "";
  const namespace = wfRun.namespace ?? "";

  const podCheckOutput = useMemo(() => {
    const podsHealthyCheck = allChecks.find(
      (c) => c.type === PodCheckType.PodHealthy
    );
    return podsHealthyCheck?.output
      ? (podsHealthyCheck?.output as UnhealthyPodOutput)
      : undefined;
  }, [allChecks]);

  const opinionatedSummary = useMemo(() => {
    if (!podCheckOutput?.issueSummary) return [];
    return [parseIssueSummary(podCheckOutput?.issueSummary)];
  }, [podCheckOutput?.issueSummary]);
  const { format } = useDateFormatter();

  const subtitle = useMemo(() => {
    const startedAt = `Started at ${format(wfRun.eventTime)}`;
    const endedAt =
      closed && wfRun.closedAt ? `, Closed at ${format(wfRun.closedAt)}` : "";
    return startedAt + endedAt;
  }, [closed, format, wfRun.closedAt, wfRun.eventTime]);

  const onEditMonitorClick = useCallback(() => {
    if (!canManageMonitors) {
      return;
    }
    setSelectedRuleId(wfRun?.workflowConfiguration?.id);
  }, [canManageMonitors, wfRun?.workflowConfiguration?.id]);
  const { data: monitorConfiguration, refetch } =
    useGetMonitorConfigurationById(
      {
        id: wfRun?.workflowConfiguration?.id ?? "",
      },
      Boolean(wfRun?.workflowConfiguration?.id)
    );

  const failedContainer = podCheckOutput?.issueSummary?.containersLogs?.[0];

  const subtitleComponent = useMemo(
    () => getReasonSubtitleComponentByIssueSummary(opinionatedSummary),
    [opinionatedSummary]
  );

  const podResourceResult = useGetPodResourceData({
    fromEpoch: wfRun.eventTime?.getTime().toString(),
    clusterName: wfRun.cluster ?? "",
    namespace: wfRun.namespace ?? "",
    podName: wfRun.shortResourceName,
    labels: getWorkflowPodLabelsSelector(podCheckOutput?.pod.labels),
  });
  return (
    <>
      <Container>
        <HeaderSection
          wfRun={wfRun}
          reasons={wfRun.reasons}
          secondSubtitle={subtitleComponent}
          canManageMonitors={canManageMonitors}
          subtitle={subtitle}
          onEditMonitorClick={onEditMonitorClick}
          onClose={
            onClose ??
            (() => {
              setEventId(null);
            })
          }
        />
        <Divider />
        {podCheckOutput?.pod && (
          <PodWhereSection
            podResource={podResourceResult?.podResource}
            podNameAriaLabel={AriaLabels.StandalonePod.ResourceLink}
            clusterName={clusterName}
            namespace={namespace}
            nodeName={podCheckOutput.pod.nodeName}
            controlledBy={
              podCheckOutput.pod.ownerReferences?.[0]?.name ??
              podResourceResult?.podResourceData?.controlledBy
            }
            labels={podCheckOutput.pod.labels}
            podName={podCheckOutput.pod.name}
          />
        )}
        <Divider />
        {closed && wfRun?.eventTime && (
          <SnapshotInfoDetail>
            All information presented here is a snapshot from{" "}
            <b>{format(new Date(wfRun?.eventTime))}</b> when this issue
            occurrence was triggered
          </SnapshotInfoDetail>
        )}
        <InfoSection
          startTime={wfRun.eventTime}
          endTime={wfRun.closedAt}
          errorMessage={podCheckOutput?.issueSummary?.errorMessage}
        />
        <Divider />
        {failedContainer && (
          <>
            <FailedContainerLogsSection
              failedContainer={failedContainer}
              clusterName={wfRun.cluster ?? ""}
              namespace={wfRun.namespace ?? ""}
            />
            <FailedContainerMetricsSection
              failedContainer={failedContainer}
              clusterName={wfRun.cluster ?? ""}
              namespace={wfRun.namespace ?? ""}
              eventTime={wfRun.eventTime}
            />
          </>
        )}
        {podCheckOutput?.pod?.podEvents &&
          podCheckOutput?.pod?.podEvents?.length > 0 && (
            <>
              <SectionColumn>
                <SectionName>pod events</SectionName>
                <PodEventsTable podEvents={podCheckOutput.pod.podEvents} />
              </SectionColumn>
              <Divider />
            </>
          )}
        {podCheckOutput?.pod?.containers &&
          podCheckOutput?.pod?.containers?.length > 0 && (
            <SectionColumn>
              <SectionName>containers</SectionName>
              <ContainersTable
                containers={podCheckOutput.pod.containers}
                clusterName={wfRun.cluster ?? ""}
                namespace={wfRun.namespace ?? ""}
                podName={podCheckOutput.pod.name}
                eventTime={wfRun.eventTime}
              />
            </SectionColumn>
          )}
      </Container>
      {monitorConfiguration && (
        <MonitorRuleDrawer
          isOpen={!!selectedRuleId}
          handleClose={() => {
            setSelectedRuleId(undefined);
          }}
          isTypeStatic={true}
          refreshMonitors={refetch}
          initRule={monitorConfiguration}
        />
      )}
    </>
  );
};
