/* eslint-disable max-lines */
import { sub } from "date-fns";

import {
  millisecondsToUnixTime,
  unixTimeToMilliseconds,
} from "../../shared/utils/timeUtils";
import { ONE_DAY_IN_MS } from "../../shared/constants/time";
import { formatNumber } from "../../shared/utils/formatNumber";
import { Timeframe } from "../../shared/types/TimeWindow";
import { OptimizationStrategy } from "../../generated/metricsApi";

import {
  AggregatedMetricsResponse,
  CpuUnit,
  CustomGraphDataPoint,
  DataPoint,
  GraphDataPoint,
  MemoryUnit,
  MetricName,
  MetricsData,
  MetricsGraphProps,
  TimeEpochs,
} from "./types";
import { EmptyStateStatus } from "./EmptyStateGraph";

import { Dictionary } from "@/shared/types/Dictionary";

export const ROUND_TO_NEAREST_SECONDS = 10;

export const roundToNearestNSeconds = (
  timestamp: number,
  seconds: number
): number => {
  return Math.round(timestamp / seconds) * seconds;
};

export const formatUnixTime = (unixTime: number): string => {
  const d = new Date(unixTime);
  const hours = d.getHours().toString().padStart(2, "0");
  const minutes = d.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const defaultFormatTick = (tick: string): string => {
  const num = parseFloat(tick);
  return formatNumber(num);
};

export const formatGraphMemoryTick = (
  tick: string,
  unit: MemoryUnit = "MiB"
): string => {
  const { value, formatOptions } = formatMemoryTick(tick, unit);
  return formatNumber(roundToNearestPowerOfTen(value), formatOptions);
};

export const formatMemoryTick = (
  tick: string,
  unit: MemoryUnit = "MiB"
): { value: number; formatOptions: Intl.NumberFormatOptions } => {
  const pow = unit === "TiB" ? 4 : unit === "GiB" ? 3 : 2;
  const num = parseInt(tick) / Math.pow(1024, pow);
  const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: unit === "GiB" || unit === "TiB" ? 1 : 0,
    minimumFractionDigits: 0,
  };
  return { value: num, formatOptions: options };
};

export const formatTooltipMemoryTick = (
  tick: string,
  unit: MemoryUnit = "MiB"
): { value: string; format: string } => {
  const { value, formatOptions } = formatMemoryTick(tick, unit);
  return {
    value: formatNumber(value, formatOptions),
    format: unit,
  };
};

export const formatCPUTick = (
  tick: string,
  unit: CpuUnit = "MilliCores"
): { value: number; formatOptions: Intl.NumberFormatOptions } => {
  const num = unit === "Cores" ? parseFloat(tick) / 1000 : parseFloat(tick);
  const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: unit === "Cores" ? 1 : 0,
    minimumFractionDigits: 0,
  };
  return { value: num, formatOptions: options };
};

export const formatGraphCPUTick = (
  tick: string,
  unit: CpuUnit = "MilliCores"
): string => {
  const { value, formatOptions } = formatCPUTick(tick, unit);
  return formatNumber(value, formatOptions);
};

export const formatTooltipCPUTick = (
  tick: string,
  unit: CpuUnit = "MilliCores"
): { value: string; format: string } => {
  const { value, formatOptions } = formatCPUTick(tick, unit);
  return {
    value: formatNumber(value, formatOptions),
    format: unit,
  };
};

export const defaultFormatTooltipTick = (tick: string): { value: string } => {
  return {
    value: `${defaultFormatTick(tick)}`,
  };
};

export const transformDataPoints = (
  dataPoint: DataPoint[],
  name: string
): CustomGraphDataPoint[] => {
  return dataPoint.reduce<CustomGraphDataPoint[]>((acc, curr) => {
    const time = unixTimeToMilliseconds(
      roundToNearestNSeconds(curr.x, ROUND_TO_NEAREST_SECONDS)
    );
    acc.push({ time, [name]: curr.y });
    return acc;
  }, []);
};

export const getDuration = (
  timeWindow: Timeframe
): { hours?: number; days?: number } => {
  switch (timeWindow) {
    case Timeframe.Last24Hours:
      return { hours: 24 };
    case Timeframe.Last7Days:
      return { days: 7 };
    case Timeframe.Last4Hours:
      return { hours: 4 };
    case Timeframe.LastHour:
      return { hours: 1 };
    default:
      throw new Error("Invalid time window");
  }
};

export const timeWindowToEpochs = (
  timeWindow: Timeframe,
  endTime?: Date
): TimeEpochs & { toEpochMs: number } => {
  const endTimeMs = endTime ?? new Date();
  const duration = getDuration(timeWindow);

  const fromEpoch = Math.round(
    millisecondsToUnixTime(sub(endTimeMs, duration).getTime())
  );
  const toEpoch = Math.round(millisecondsToUnixTime(endTimeMs.getTime()));

  return { fromEpoch, toEpoch, toEpochMs: endTimeMs.getTime() };
};

const calcTransformedData = (
  datapoints: DataPoint[],
  propName: string
): GraphDataPoint[] => {
  return datapoints.map((datapoint) => {
    const time = unixTimeToMilliseconds(
      roundToNearestNSeconds(datapoint.x, ROUND_TO_NEAREST_SECONDS)
    );
    return { time, [propName]: datapoint.y };
  });
};

export const transformData = (
  metricsData: MetricsData,
  recommendationStrategy?: OptimizationStrategy
): GraphDataPoint[] => {
  const {
    usage = [],
    request = [],
    limit = [],
    capacity = [],
    p90 = [],
    p95 = [],
    p99 = [],
    max = [],
    moderateRecommendation = [],
    conservativeRecommendation = [],
    aggressiveRecommendation = [],
  } = metricsData;

  const recommendationData =
    recommendationStrategy === "aggressive"
      ? aggressiveRecommendation
      : recommendationStrategy === "conservative"
      ? conservativeRecommendation
      : moderateRecommendation;

  const transformedData: GraphDataPoint[] = [
    ...calcTransformedData(usage, "usageBytes"),
    ...calcTransformedData(p90, "p90"),
    ...calcTransformedData(p95, "p95"),
    ...calcTransformedData(p99, "p99"),
    ...calcTransformedData(max, "max"),
    ...calcTransformedData(request, "requestBytes"),
    ...calcTransformedData(limit, "limitBytes"),
    ...calcTransformedData(capacity, "capacityBytes"),
    ...calcTransformedData(recommendationData, "recommendationBytes"),
  ];

  const mergedData: GraphDataPoint[] = [];

  transformedData.forEach((obj) => {
    const existingObj = mergedData.find(
      (dataPoint) => dataPoint.time === obj.time
    );
    if (existingObj) {
      Object.assign(existingObj, obj);
    } else {
      mergedData.push({ ...obj });
    }
  });

  return mergedData.sort((a, b) => a.time - b.time);
};

export const getLastNDays = (
  data: GraphDataPoint[],
  days: number
): GraphDataPoint[] => {
  if (data.length === 0) {
    return [];
  }
  const timestamp = data[data.length - 1].time - days * ONE_DAY_IN_MS;
  const index = data.findIndex((dataPoint) => {
    const dataPointTime = dataPoint.time;
    return dataPointTime > timestamp;
  });

  const nDaysData = index === -1 ? [] : data.slice(index);

  let lastNonNullIndex = nDaysData.length - 1;
  for (let i = nDaysData.length - 1; i >= 0; i--) {
    if (nDaysData[i].usageBytes !== null) {
      lastNonNullIndex = i;
      break;
    }
  }

  return nDaysData.slice(0, lastNonNullIndex + 1);
};

type EmptyGraphStatusParams = {
  data: Pick<AggregatedMetricsResponse, "loading" | "error">;
  isMetricsSupported: boolean | null;
  isOverRetention: boolean;
};
export const getEmptyGraphStatus = ({
  isOverRetention,
  data,
  isMetricsSupported,
}: EmptyGraphStatusParams): EmptyStateStatus => {
  const { loading, error } = data || {};
  if (isMetricsSupported === false) return EmptyStateStatus.Upgrade;
  if (isOverRetention) return EmptyStateStatus.Retention;
  if (error) return EmptyStateStatus.Error;
  if (loading) return EmptyStateStatus.Loading;
  return EmptyStateStatus.Empty;
};

export const isTimestampOverRetention = (endTimestamp: number): boolean => {
  const now = Date.now();
  const differenceInMs = now - endTimestamp;
  return differenceInMs > ONE_DAY_IN_MS * 30;
};

type GetLimitParams = {
  expectedPodContainersNum?: number;
  limitContainersNum?: number;
  limitMetrics?: DataPoint[];
};
export const getLimitMetricsByContainersNum = ({
  limitMetrics,
  limitContainersNum,
  expectedPodContainersNum,
}: GetLimitParams): DataPoint[] => {
  const shouldShowLimit =
    !expectedPodContainersNum ||
    !limitContainersNum ||
    expectedPodContainersNum === limitContainersNum;

  return shouldShowLimit ? limitMetrics ?? [] : [];
};

export const shouldShowLine = (
  lineNames: MetricName[],
  currentLine: MetricName
) => {
  return lineNames.includes(currentLine);
};

export const getLineNames = (props: MetricsGraphProps) => {
  return (props.getLinesListByAggregationType?.(props.aggregationType) ??
    props.showLinesList ?? [
      "usageBytes",
      "capacityBytes",
      "limitBytes",
      "requestBytes",
      "recommendationBytes",
    ]) as MetricName[];
};

export const roundToNearestPowerOfTen = (num: number): number => {
  if (num === 0) {
    return 0;
  }
  const length = Math.floor(Math.log10(Math.abs(num)));
  const multiplier = 10 ** (length - 1);
  return Math.ceil(num / multiplier) * multiplier;
};

export const calculateTimeframe = (
  start: Date | undefined,
  end: Date | undefined
): Timeframe => {
  if (!start || !end) {
    return Timeframe.Last7Days;
  }

  const duration = Math.abs(end.getTime() - start.getTime());

  const timeframeMappings: [number, Timeframe][] = [
    [3600000, Timeframe.LastHour],
    [14400000, Timeframe.Last4Hours],
    [86400000, Timeframe.Last24Hours],
    [604800000, Timeframe.Last7Days],
  ];

  for (const [durationThreshold, timeframe] of timeframeMappings) {
    if (duration <= durationThreshold) {
      return timeframe;
    }
  }

  return Timeframe.Last7Days;
};

export const prependMetricsWithPadding = (
  metrics: MetricsData,
  minutes: number
): MetricsData => {
  if (minutes === 0 || metrics.usage.length === 0) {
    return metrics;
  }
  const newMetrics = { ...metrics };
  const padding = [
    {
      x: metrics.usage[0].x - minutes * 60,
      y: null,
    },
  ];
  newMetrics.usage = [...padding, ...metrics.usage];
  return newMetrics;
};

type MergedItem = {
  time: number;
  [key: string]: number | null;
};

export const mergeMetrics = (metrics: Dictionary<CustomGraphDataPoint[]>) => {
  const groupedByTime = Object.entries(metrics).reduce<
    Record<number, MergedItem>
  >((acc, [metricKey, datapointsList]) => {
    datapointsList.forEach((datapoint) => {
      const { time, ...rest } = datapoint;
      acc[time] = acc[time] || {
        time,
      };
      acc[time][metricKey] = rest[metricKey];
    });
    return acc;
  }, {});

  return Object.values(groupedByTime).sort((a, b) => a.time - b.time);
};
