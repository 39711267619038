import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { datadogRum } from "@datadog/browser-rum";

import {
  DatadogReportLoadingTimeContextState,
  ReportLoadingTimeProps,
} from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { KOMODOR_LOADING_TIME } from "@/shared/constants/datadog";
import { useIsElementInViewport } from "@/shared/hooks/useIntersectionObserver";
import { useIsInCurrentlyDisplayedDrawer } from "@/shared/context/drawersStack/helpers";

type UseReportLoadingTimeProps = {
  loadingState: DatadogReportLoadingTimeContextState["loadingState"];
  getCurrentViewOptions: DatadogReportLoadingTimeContextState["getCurrentViewOptions"];
  containerRef: MutableRefObject<HTMLDivElement | null | undefined>;
  reportLoadingTimeProps?: ReportLoadingTimeProps;
};

export const useReportLoadingTime = ({
  loadingState,
  getCurrentViewOptions,
  containerRef,
  reportLoadingTimeProps,
}: UseReportLoadingTimeProps) => {
  const shouldReportLoadingTime = useRef<boolean>(false);
  const isElementInViewport = useIsElementInViewport({
    ref: containerRef as RefObject<Element>,
  });
  const isViewDisplayed =
    containerRef.current !== undefined ? isElementInViewport : true;
  const lastIsDisplayed = useRef(isViewDisplayed);
  const hasPreventedReportingDueToOverlappingDrawer = useRef(false);
  const { isInCurrentlyDisplayedDrawer, hasDrawersOpen } =
    useIsInCurrentlyDisplayedDrawer();

  const doesCurrentViewInsideDrawerAndShouldBeReported = useMemo(() => {
    return (
      !reportLoadingTimeProps && hasDrawersOpen && !isInCurrentlyDisplayedDrawer
    );
  }, [hasDrawersOpen, isInCurrentlyDisplayedDrawer, reportLoadingTimeProps]);

  const isReportingEnabled = useMemo(() => {
    if (doesCurrentViewInsideDrawerAndShouldBeReported) {
      return false;
    }

    return isViewDisplayed;
  }, [doesCurrentViewInsideDrawerAndShouldBeReported, isViewDisplayed]);

  const values = Object.values(loadingState);

  const { allComponentsAreLoading, loadingStateIsComplete } = useMemo(() => {
    if (values.length === 0) {
      return { allComponentsAreLoading: false, loadingStateIsComplete: false };
    }

    return {
      allComponentsAreLoading: values.every((loading) => loading),
      loadingStateIsComplete: values.every((loading) => !loading),
    };
  }, [values]);

  const reportLoadingTime = useCallback(() => {
    if (!shouldReportLoadingTime.current || !isReportingEnabled) return;

    if (loadingStateIsComplete) {
      shouldReportLoadingTime.current = false;
      datadogRum.addTiming(KOMODOR_LOADING_TIME);
    }
  }, [loadingStateIsComplete, isReportingEnabled]);

  const triggerStartView = useCallback(
    (checkLoadingTime?: boolean) => {
      if (doesCurrentViewInsideDrawerAndShouldBeReported) {
        hasPreventedReportingDueToOverlappingDrawer.current = true;
        return;
      }
      if (shouldReportLoadingTime.current || !isViewDisplayed) {
        return;
      }
      shouldReportLoadingTime.current = true;
      datadogRum.startView(getCurrentViewOptions());
      checkLoadingTime && reportLoadingTime();
    },
    [
      doesCurrentViewInsideDrawerAndShouldBeReported,
      isViewDisplayed,
      getCurrentViewOptions,
      reportLoadingTime,
    ]
  );

  useEffect(() => {
    if (hasPreventedReportingDueToOverlappingDrawer.current) {
      if (isInCurrentlyDisplayedDrawer || !hasDrawersOpen) {
        // component has prevented reporting b/c of an overlapping drawer and is now visible
        hasPreventedReportingDueToOverlappingDrawer.current = false;
        triggerStartView(true);
      }
    }
  }, [hasDrawersOpen, isInCurrentlyDisplayedDrawer, triggerStartView]);

  useEffect(() => {
    if (shouldReportLoadingTime.current || !isViewDisplayed) {
      return;
    }
    if (allComponentsAreLoading) {
      triggerStartView();
    }
  }, [
    allComponentsAreLoading,
    loadingState,
    triggerStartView,
    isViewDisplayed,
  ]);

  useEffect(() => {
    reportLoadingTime();
  }, [reportLoadingTime]);

  useEffect(() => {
    if (isViewDisplayed && !lastIsDisplayed.current) {
      triggerStartView(true);
    }

    lastIsDisplayed.current = isViewDisplayed;
  }, [isViewDisplayed, triggerStartView]);

  return { triggerStartView };
};
