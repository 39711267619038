import { MetricsTabContextState } from "./types";

import { Timeframe } from "@/shared/types/TimeWindow";

export const initialState: MetricsTabContextState = {
  timeWindow: {
    start: new Date(new Date().setHours(new Date().getHours() - 1)),
    end: new Date(),
    timeframe: Timeframe.LastHour,
  },
  setTimeWindow: () => undefined,
  podContainersMemoryUsage: [],
  podContainersCpuUsage: [],
  podContainersNames: [],
  setPodContainersMemoryUsage: () => undefined,
  setPodContainersCpuUsage: () => undefined,
  setPodContainersNames: () => undefined,
};
