import React, { useState } from "react";
import styled from "styled-components";
import { ChannelType } from "komodor-types";
import { Input, Typography } from "@komodorio/design-system/deprecated";

import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import ModalComponent from "../../../common/ModalComponent";
import { Divider } from "../../../common/styles";
import {
  Container,
  Content,
  lightStyle,
  Spacer,
  WorkflowConfigModalStyle,
} from "../styles";
import { gray10 } from "../../../../../Colors";
import { useInsertChannelConfiguration } from "../../../../notification/useInsertChannelConfiguration";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import EventsV2Logo from "../../../assets/eventsV2.png";

import { PagerDutySink } from "@/generated/monitorsApi";

const pdAddServiceIntegrationInfoUrl =
  "https://support.pagerduty.com/docs/services-and-integrations#add-integrations-to-an-existing-service";

const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  column-gap: 2rem;
`;

export const InfoButton = styled.a`
  ${lightStyle}
  color: ${gray10};
  cursor: pointer;
  text-decoration: underline;
`;

const Logo = styled.img`
  max-height: 10rem;
  max-width: 10rem;
  object-fit: contain;
  grid-row: 2 / 2;
  grid-column: 2 / 2;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddPagerDutyServiceIntegrationKeyModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  setChannel: (channel: PagerDutySink) => void;
  pagerDutyAccountName: string;
}> = ({ isOpen, handleClose, setChannel, pagerDutyAccountName }) => {
  const [integrationKey, setIntegrationKey] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [integrationKeyValid, setIntegrationKeyValid] = useState(true);
  const [serviceNameValid, setServiceNameValid] = useState(true);

  const insertPdServiceConfiguration = useInsertChannelConfiguration();

  const validateIntegrationKey = () => {
    const valid = integrationKey !== "";
    setIntegrationKeyValid(valid);
    return valid;
  };

  const validateServiceName = () => {
    const valid = serviceName !== "";
    setServiceNameValid(valid);
    return valid;
  };

  const handleAddChannel = () => {
    const integrationKeyValid = validateIntegrationKey();
    const serviceNameValid = validateServiceName();

    if (integrationKeyValid && serviceNameValid) {
      (async function () {
        await insertPdServiceConfiguration({
          type: ChannelType.PagerDuty,
          configuration: {
            channel: serviceName,
            integrationKey,
            pagerDutyAccountName,
          },
        });
        setChannel({
          channel: serviceName,
          integrationKey,
          pagerDutyAccountName,
        });
        setIntegrationKey(integrationKey);
        setServiceName(serviceName);
        handleClose();
      })();
    }
  };

  return (
    <ModalComponent
      title=""
      isOpen={isOpen}
      handleCloseModal={handleClose}
      customStyle={WorkflowConfigModalStyle}
    >
      <Container>
        <Header
          closeModalCallback={handleClose}
          title="Add New PagerDuty Service"
        />
        <Divider />
        <Content>
          <ModalGrid>
            <FlexCol>
              <Typography size="medium">
                PagerDuty account: <b>{pagerDutyAccountName}</b>
              </Typography>
              <Spacer />
              <Input
                size="medium"
                width="13rem"
                label="EventsV2 Integration Key"
                placeholder="Enter integration key"
                value={integrationKey}
                onChange={(e) => setIntegrationKey(e.target.value)}
                errorMessage={
                  !integrationKeyValid
                    ? "An integration key is required"
                    : undefined
                }
              />
              <Spacer />
              <Input
                size="medium"
                width="13rem"
                label="Service Name"
                placeholder="Enter service name"
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
                errorMessage={
                  !serviceNameValid ? "A service name is required" : undefined
                }
              />
            </FlexCol>
            <FlexCol>
              <InfoButton href={pdAddServiceIntegrationInfoUrl} target="_blank">
                How do I create an EventsV2 integration key?
              </InfoButton>
              <br />
              <Logo alt="EventsV2 Logo" src={EventsV2Logo} />
            </FlexCol>
          </ModalGrid>
          <Spacer />
        </Content>
        <Divider />
        <Footer
          backData={{
            label: "Cancel",
            action: handleClose,
          }}
          nextData={{ label: "Continue", action: handleAddChannel }}
        />
      </Container>
    </ModalComponent>
  );
};
