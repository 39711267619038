export function generateEmptyRows<R>(rows: number) {
  return Array.from({ length: rows }).map((_, index) => ({ id: index } as R));
}

export const getDisplayingItemsText = (
  page: number,
  pageSize: number,
  totalNumberOfItems: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const firstItem = totalNumberOfItems === 0 ? 0 : page * pageSize + 1;
  const lastItem = Math.min((page + 1) * pageSize, totalNumberOfItems);
  return `Displaying ${firstItem.toLocaleString()}-${lastItem.toLocaleString()} of ${totalNumberOfItems.toLocaleString()} items`;
};
