import { columnsConfig } from "@/components/k8sAddons/addons/workflows/hooks/dagsTableHooks";
import { WorkflowEngine } from "@/components/k8sAddons/addons/workflows/types";
import { Addon, Entity, Operator } from "@/generated/addonsApi";
import { useGetAddonAggregations } from "@/shared/hooks/k8s-add-ons/useGetAddonAggregations";

export const useShouldShowAirflow = () => {
  const { data, isLoading } = useGetAddonAggregations(
    {
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      getEntityAggregationsRequest: {
        groupBy: columnsConfig.workflowEngine.field,
        aggregation: "count",
        filter: [
          {
            name: columnsConfig.workflowEngine.field,
            operator: Operator.Eq,
            value: [WorkflowEngine.Airflow],
          },
        ],
      },
    },
    {
      skipWorkspaceId: true,
    }
  );

  return { shouldShow: data ? data.data.count > 0 : undefined, isLoading };
};

export const useShouldShowSpark = () => {
  const { data, isLoading } = useGetAddonAggregations(
    {
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      getEntityAggregationsRequest: {
        groupBy: columnsConfig.workflowEngine.field,
        aggregation: "count",
        filter: [
          {
            name: columnsConfig.workflowEngine.field,
            operator: Operator.Eq,
            value: [WorkflowEngine.Spark],
          },
        ],
      },
    },
    {
      skipWorkspaceId: true,
    }
  );

  return { shouldShow: data ? data.data.count > 0 : undefined, isLoading };
};

export const useShouldShowCustomWorkflows = () => {
  const { data, isLoading } = useGetAddonAggregations(
    {
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      getEntityAggregationsRequest: {
        groupBy: columnsConfig.workflowEngine.field,
        aggregation: "count",
        filter: [
          {
            name: columnsConfig.workflowEngine.field,
            operator: Operator.Nin,
            value: Object.values(WorkflowEngine),
          },
        ],
      },
    },
    {
      skipWorkspaceId: true,
    }
  );

  return { shouldShow: data ? data.data.count > 0 : undefined, isLoading };
};
