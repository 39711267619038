import { useEffect, useMemo } from "react";

import {
  clusterNameFilterCategory,
  useGetAllAvailableClusters,
} from "./useGetAllAvailableClusters";

import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";
import {
  FiltersStateInUrl,
  useStateInUrl,
} from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { Operator } from "@/generated/addonsApi";
import { getLocalStorageItemAsJSON } from "@/components/k8sAddons/utils/addonUtils";

export const useSetInitialCluster = (): boolean => {
  const [dataInUrl, setDataInUrl] = useStateInUrl();
  const {
    addonType,
    addonEntity,
    setInitialClustersFilter,
    initialClustersFilterWasSet,
    setInitialClustersFilterWasSet,
  } = useAddonContext();

  const hasFiltersInUrl = useMemo(
    () => !!Object.values(dataInUrl.filters ?? []).length,
    [dataInUrl.filters]
  );

  const hasFiltersInLocalStorage = useMemo(() => {
    const localStorageFilters = getLocalStorageItemAsJSON<FiltersStateInUrl>(
      addonType,
      addonEntity
    );
    return !!Object.values(localStorageFilters?.filters ?? []).length;
  }, [addonEntity, addonType]);

  const enableRequest =
    Boolean(setInitialClustersFilter) &&
    !hasFiltersInUrl &&
    !hasFiltersInLocalStorage &&
    !initialClustersFilterWasSet;

  const { data, error, isLoading } = useGetAllAvailableClusters({
    enableRequest,
  });

  useEffect(() => {
    if (!setInitialClustersFilter || initialClustersFilterWasSet) return;
    if (data?.data.isPaginating === false || error) {
      setInitialClustersFilterWasSet(true);
    }
    if (hasFiltersInUrl || hasFiltersInLocalStorage) {
      setInitialClustersFilterWasSet(true);
      return;
    }
    const hasData = data && data.data.items.length > 0;
    if (hasData) {
      if (enableRequest) {
        const firstClusterName = data.data.items[0].key ?? "";
        setDataInUrl(
          {
            filters: {
              ...dataInUrl.filters,
              [clusterNameFilterCategory]: [
                { values: [firstClusterName], operator: Operator.In },
              ],
            },
          },
          true
        );
        setInitialClustersFilterWasSet(true);
      }
    }
  }, [
    data,
    data?.data.items,
    dataInUrl.filters,
    setDataInUrl,
    hasFiltersInUrl,
    setInitialClustersFilter,
    initialClustersFilterWasSet,
    setInitialClustersFilterWasSet,
    isLoading,
    enableRequest,
    hasFiltersInLocalStorage,
    error,
  ]);

  return (
    !setInitialClustersFilter ||
    !!error || // do not disable all live-state requests if this request fails
    hasFiltersInUrl ||
    initialClustersFilterWasSet
  );
};
