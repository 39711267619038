import { useNavigate } from "react-router-dom";

import { useSessionStorage } from "../../../components/common/useSessionStorage";

export const sandboxSessionStorageParameter = "@komodor/isSandbox";
export const sandboxRoute = "sandbox";

export const isSandbox = (): boolean =>
  JSON.parse(sessionStorage.getItem(sandboxSessionStorageParameter) ?? "false");

const useKomodorMode = (isSandboxMode: boolean): (() => void) => {
  const [, setIsSandbox] = useSessionStorage(
    sandboxSessionStorageParameter,
    false
  );
  return () => {
    setIsSandbox(isSandboxMode);

    window.location.replace("/main/services");
  };
};

export const useSandboxMode = (): (() => void) => {
  return useKomodorMode(true);
};

export const useAppMode = (): (() => void) => {
  return useKomodorMode(false);
};

export const useRouteToSandbox = (
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>,
  ready: boolean,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  isSandbox: boolean,
  setIsSandbox: React.Dispatch<boolean>
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ((specificRoute?: string) => void) => {
  const navigate = useNavigate();

  return (specificRoute?: string) => {
    if (!isSandbox || !ready)
      navigate(specificRoute || "/main/services", { replace: true });
    setAccessToken(null);
    setReady(true);
    setIsSandbox(true);
  };
};
