import { getAppConfig } from "../../config/appConfig";
import { useApiGetWithUrl } from "../common-api/request";
import { EndpointsResponseType } from "../common-api/types";
import { CommonApiResponse } from "../../types/commonApiResponse";

import {
  EndpointRequestPathMap,
  EndpointsRequestMap,
  EndpointsResponseMap,
} from "./requestResponseMaps";

/**
 * @deprecated use react-query instead.
 */
export const useAppInsightsAPIGet = <
  M extends { [key in keyof EndpointsResponseType]: M },
  T extends keyof EndpointsResponseMap = keyof EndpointsResponseMap
>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<M> => {
  let url = "";
  try {
    url = EndpointRequestPathMap[relativePath](
      parameters as never,
      getAPIBasePath()
    );
  } catch (err) {
    // there was an issue getting the url, probably due to a missing parameter
  }
  return useApiGetWithUrl<M>(url, pause && !!url);
};

const getAPIBasePath = (): string => {
  return getAppConfig().appInsightsAPIServerURL;
};
