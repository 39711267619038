import { useMemo } from "react";
import { subHours } from "date-fns";

import { useDrawerTimeWindowFromUrl } from "@/components/ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/useDrawerTimeWindowFromUrl";
import {
  EVENTS_TAB_TIME_WINDOW,
  EVENTS_TAB_TIMEFRAME,
} from "@/shared/config/urlSearchParamsKeys";
import { Timeframe } from "@/shared/types/TimeWindow";
import {
  isCronJob,
  isJob,
} from "@/components/ResourceView/tabs/EventsTab/typeGuards";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import useIsFreeTier from "@/components/Freemium/useIsFreeTier";
import Resource from "@/components/ResourceView/resources";

const useInitialTimeWindow = (resource: Resource) => {
  const jobOrCronJobLastModified = useJobOrCronJobLastModified(resource);
  const isFreeTier = useIsFreeTier();

  return useMemo(() => {
    if (jobOrCronJobLastModified) {
      return {
        start: subHours(jobOrCronJobLastModified, 1),
        end: new Date(),
        timeframe: Timeframe.Custom,
      };
    }

    return {
      start: subHours(new Date(), 24),
      end: new Date(),
      timeframe: isFreeTier ? Timeframe.Last4Hours : Timeframe.Last24Hours,
    };
  }, [isFreeTier, jobOrCronJobLastModified]);
};

export const useTimeWindowFromUrl = (resource: Resource) => {
  const initialTimeWindow = useInitialTimeWindow(resource);

  return useDrawerTimeWindowFromUrl(
    initialTimeWindow,
    EVENTS_TAB_TIME_WINDOW,
    EVENTS_TAB_TIMEFRAME
  );
};

const useJobOrCronJobLastModified = (resource: Resource) => {
  const { jobsDeepDiveAutomaticTimeframeAdjustment } = useOverridableFlags();
  const isFreeTier = useIsFreeTier();

  return useMemo(() => {
    if (
      !!jobsDeepDiveAutomaticTimeframeAdjustment &&
      !isFreeTier &&
      (isJob(resource) || isCronJob(resource)) &&
      resource.lastModified &&
      resource.lastModified < subHours(new Date(), 24)
    ) {
      return resource.lastModified;
    }
    return undefined;
  }, [isFreeTier, jobsDeepDiveAutomaticTimeframeAdjustment, resource]);
};
