import React, { useEffect } from "react";

import { InventorySection } from "@/components/ClustersView/ClusterOverview/components/InventorySection/InventorySection";
import { useGetClusterByName } from "@/shared/hooks/workspaces-api/clusters/useGetClusterByName";
import {
  JobsData,
  NodeStatus,
  PodData,
  ServiceData,
} from "@/generated/workspacesApi";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const defaultCluster = {
  clusterName: "",
  inactive: false,
  pods: {
    green: { count: 0 },
    yellow: { count: 0 },
    red: { count: 0 },
    count: 0,
  } as unknown as PodData,
  nodes: {
    ready: { count: 0 },
    notReady: { count: 0 },
    count: 0,
  } as unknown as NodeStatus,
  services: {
    healthy: { count: 0 },
    unhealthy: { count: 0 },
    count: 0,
  } as unknown as ServiceData,
  jobs: {
    green: { count: 0 },
    gray: { count: 0 },
    red: { count: 0 },
    count: 0,
  } as unknown as JobsData,
  additionalInfo: {
    isPodsEnabled: false,
  },
};

export const InventorySectionByClusterName: React.FC<{
  clusterName: string;
}> = ({ clusterName }) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { data: cluster, isLoading } = useGetClusterByName(
    { name: clusterName },
    { enabled: !!clusterName, staleTime: 0 }
  );

  useEffect(() => {
    reportLoadingState("isFetchingCluster", isLoading);
  }, [reportLoadingState, isLoading]);

  const {
    inactive,
    pods,
    nodes,
    services,
    jobs,
    additionalInfo: { isPodsEnabled },
  } = cluster ?? defaultCluster;

  return (
    <InventorySection
      clusterName={clusterName}
      isClusterInactive={inactive}
      pods={pods}
      nodes={nodes}
      services={services}
      jobs={jobs}
      isPodsEnabled={isPodsEnabled}
      shouldShowLoader={isLoading}
    />
  );
};
