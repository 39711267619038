import React from "react";

import { NodeMetrics } from "./NodeMetrics/NodeMetrics";

import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { Addon, Entity } from "@/generated/addonsApi";
import { useAddOnsRefetchInterval } from "@/components/k8sAddons/hooks/useAddOnsRefetchInterval";
import { Filters } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/Filters";
import { NodeAutoscalersTable } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/NodeAutoscalersTable";
import {
  DEFAULT_AUTOSCALERS_SORT_BY_FIELD,
  DEFAULT_AUTOSCALERS_SORT_BY_DIRECTION,
} from "@/components/k8sAddons/addons/NodeAutoscalers/nodeAutoscalersConstants";
import { AddonPageContainer } from "@/components/k8sAddons/components/AddonPageContainer";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { WorkspaceGenericEmptyState } from "@/components/workspaces/WorkspaceGenericEmptyState";
import { NodeAutoscalersPageConstants } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/nodeAutoscalersConstants";

export const NodeAutoscalersPage: React.FC = () => {
  const { isClusterWorkspace, isClustersGroupWorkspace, currentWorkspace } =
    useWorkspaces();
  const refetchInterval = useAddOnsRefetchInterval();

  if (
    currentWorkspace !== undefined &&
    !isClusterWorkspace &&
    !isClustersGroupWorkspace
  ) {
    return (
      <WorkspaceGenericEmptyState
        title={NodeAutoscalersPageConstants.emptyStateBecauseOfWorkspaceTitle}
      />
    );
  }

  return (
    <AddonContextProvider
      addonType={Addon.AutoScalers}
      addonEntity={Entity.AutoScaler}
      refetchInterval={refetchInterval}
      defaultSortModel={{
        field: DEFAULT_AUTOSCALERS_SORT_BY_FIELD,
        sort: DEFAULT_AUTOSCALERS_SORT_BY_DIRECTION,
      }}
    >
      <AddonPageContainer>
        <Filters />
        <NodeMetrics />
        <NodeAutoscalersTable />
      </AddonPageContainer>
    </AddonContextProvider>
  );
};
