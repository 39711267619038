import React from "react";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import PodsContentAtm from "@/components/ResourceView/tabs/PodsTab/PodsData";
import { Panel } from "@/components/Inspection/styles";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";

export const WorkflowPodsTab: React.FC<{ resource: WorkflowRun }> = ({
  resource,
}) => {
  const agentId = useActiveAgent(resource.cluster);
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.Pods);

  if (!agentId) {
    return (
      <Panel>
        <LinesLoader />
      </Panel>
    );
  }

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <PodsContentAtm
        resource={resource}
        agentId={agentId}
        fetchDeletedPods
        deletedPodsTimeWindow={resource.timeWindow}
      />
    </DatadogReportLoadingTimeContextProvider>
  );
};
