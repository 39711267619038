import React, { useMemo } from "react";
import { Breadcrumbs } from "@komodorio/design-system/komodor-ui";

interface Props {
  value: string;
}

const BreadcrumbWrapper: React.FC<Props> = ({ value }) => {
  const splits = useMemo(() => {
    // Input format:                Kind|Cluster|Namespace|Name
    // Expected output format is:   Cluster|Namespace|Name
    return value.split("|").slice(1);
  }, [value]);

  return (
    <Breadcrumbs
      lastIsActive={true}
      allowOverflow={false}
      breadcrumbs={splits}
      compact={true}
    />
  );
};

export { BreadcrumbWrapper };
