import React, { useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { UpgradeAgentVersionAlert } from "../../../common/UpgradeAgentVersionAlert/UpgradeAgentVersionAlert";
import UpgradeRequired from "../../../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../../../common/upgradeAgentCta/UpgradeAgentCTA";
import { UpgradeRequiredModalStep } from "../../../common/UpdateAgentModal/types";
import { updateAgentToEnableRBACClusterSync } from "../../../common/UpgradeAgentVersionAlert/texts";
import { ClusterForRbacSync } from "../utils/rbacClusterStatusSync";

import { BorderedBox } from "./containers";

import { useGetHelmInstallationCommand } from "@/shared/hooks/useGetHelmInstallationCommand";

const Container = styled.div`
  margin: 0 10px;
`;

const AlertContainer = styled.div`
  padding: 0 0 1rem 0;
`;

const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

const UpdateRequiredOnClustersMessage: React.FC<{
  clusters: ClusterForRbacSync[];
}> = ({ clusters }) => (
  <Typography>
    The following clusters need to be updated:
    <BorderedBox sx={{ overflow: "auto", height: "100px" }}>
      <List>
        {clusters.map((cluster) => (
          <ListItem key={cluster.name} disablePadding>
            <Typography variant={"subtitle2"}>{cluster.name}</Typography>
          </ListItem>
        ))}
      </List>
    </BorderedBox>
  </Typography>
);

const UpgradeButtonAndModal: React.FC<{
  clusters: ClusterForRbacSync[];
}> = ({ clusters }) => {
  const [open, setOpen] = useState(false);
  const upgradeCommandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_KUBECONFIG_RBAC_SYNC
  );
  return (
    <Container>
      <Button variant={"text"} onClick={() => setOpen(true)}>
        <StyledTypography variant={"h6"} color={"primary"}>
          View clusters
        </StyledTypography>
      </Button>
      {open && (
        <UpgradeRequired
          handleClose={() => setOpen(false)}
          updateMessage={
            <UpdateRequiredOnClustersMessage clusters={clusters} />
          }
          upgradeCommand={upgradeCommandStr}
          desiredFeature="RBAC sync on Kubernetes clusters"
          initialStep={UpgradeRequiredModalStep.ShowCommand}
        />
      )}
    </Container>
  );
};

export const UpdateAgentForKubeconficRbacClusterSyncAlert: React.FC<{
  clusters: ClusterForRbacSync[];
}> = ({ clusters }) => {
  if (clusters.length === 0) {
    return null;
  }

  return (
    <AlertContainer>
      <UpgradeAgentVersionAlert
        upgradeButton={<UpgradeButtonAndModal clusters={clusters} />}
        title={""}
        text={updateAgentToEnableRBACClusterSync}
        severity={"warning"}
        padding={"4px 8px"}
      />
    </AlertContainer>
  );
};
