import { useMemo } from "react";
import { compact } from "lodash";

import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { buildKomodorUid } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { WORKLOADS_PODS_NATIVE_EVENT_SEARCH } from "../../../../../../../shared/hooks/resources-api/requestResponseMaps";
import { useResourcesAPIGet } from "../../../../../../../shared/hooks/resources-api/client";
import { NativePodFragment } from "../../../../../../common/EventGroup/nativePodEvent/types";

export const usePodsEventsFetch = (
  podUids: string[] | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined,
  disableFetch = false
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [NativePodFragment[] | undefined, boolean] => {
  const uids = useMemo(() => {
    if (!podUids || !namespace || !clusterName) return null;

    const podNames = compact(
      podUids?.map((podUid) => podUid.split(";").at(-1))
    );
    return podNames.map((podName) =>
      buildKomodorUid({
        kind: "Pod",
        clusterName,
        namespace,
        resourceName: podName ?? "",
      })
    );
  }, [clusterName, namespace, podUids]);

  const apiVars = useMemo(() => {
    return {
      komodorUids: uids ?? [],
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
    };
  }, [timeWindow?.end, timeWindow?.start, uids]);

  const { data, loading } = useResourcesAPIGet(
    WORKLOADS_PODS_NATIVE_EVENT_SEARCH,
    apiVars,
    (uids ?? []).length === 0 || !timeWindow || disableFetch
  );

  return [data?.data as NativePodFragment[] | undefined, loading];
};
