import { useMemo } from "react";

import { useDeletedPodsProperties } from "../historicalFetch/useDeletedPodsProperties";
import {
  KubernetesPodsResource,
  KubernetesResource,
} from "../inspectionConfiguration/SupportedResourcesTypes";

export const useShouldShowDeletedPods = (
  kubernetesResource: KubernetesResource
) => {
  const { showDeletedPodsUrlParam } = useDeletedPodsProperties();

  return useMemo(
    () =>
      showDeletedPodsUrlParam &&
      kubernetesResource.NameInK8S === KubernetesPodsResource.NameInK8S,
    [kubernetesResource.NameInK8S, showDeletedPodsUrlParam]
  );
};
