import React, { ReactNode, useCallback, useMemo } from "react";
import { palette } from "@komodorio/design-system";
import {
  Button,
  Divider,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
  MenuList,
  ActionItem,
} from "@komodorio/design-system/deprecated";
import { ResourceTableModelRow } from "komodor-types";
import styled from "styled-components";

import { AnalyticEvents } from "../../../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../../../shared/hooks/analytics";
import { KubernetesResource } from "../../../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { relatedResources } from "../relatedResources";
import { useActiveAgent } from "../../../../../../../shared/hooks/useAgents";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

const NoWrapText = styled.span`
  white-space: nowrap;
`;

interface ResourcesSelectionDialogProps {
  open: boolean;
  close: () => void;
  applySelection: (
    selectedRowNamesByResourceType: Map<KubernetesResource, string[]>
  ) => void;
  currentResourceType: KubernetesResource;
  resourceTypes: KubernetesResource[];
  onResourceTypeChange: (resourceType: KubernetesResource) => void;
  selectedRowNamesByResourceType: Map<KubernetesResource, string[]>;
  toggleRowSelection: (
    resourceType: KubernetesResource,
    rowName: string
  ) => void;
  cluster: string | undefined;
  searchTerm: string;
  eventsDetectionTimeframe: TimeWindow;
  tableModifiers?: ReactNode;
  secondaryTableModifiers?: ReactNode;
  maxSelectableRows?: number;
  includeAllRowsSelection?: boolean;
  serviceId?: string;
  showDeletedPods?: boolean;
  showOnlySelectedRows?: boolean;
  namespace: string | undefined;
}

export const ResourcesSelectionDialog: React.FC<
  ResourcesSelectionDialogProps
> = (props) => {
  const { open, close } = props;

  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      width="min(90%, 72rem)"
      height="max(calc(100% - 200px), 39rem)"
    >
      <DatadogReportLoadingTimeContextProvider
        viewOptions={{
          name: DatadogViewNamesEnum.serviceViewRelatedResourcesTab,
          context: {
            feTeam: "operate",
            beTeam: "barzelim",
          },
        }}
      >
        <ResourcesSelectionDialogContent {...props} />
      </DatadogReportLoadingTimeContextProvider>
    </Modal>
  );
};

const ResourcesSelectionDialogContent: React.FC<
  ResourcesSelectionDialogProps
> = ({
  close,
  applySelection,
  currentResourceType,
  resourceTypes,
  onResourceTypeChange,
  selectedRowNamesByResourceType,
  toggleRowSelection,
  cluster,
  tableModifiers,
  secondaryTableModifiers,
  searchTerm,
  serviceId,
  eventsDetectionTimeframe,
  maxSelectableRows = 1,
  includeAllRowsSelection = false,
  showDeletedPods = false,
  showOnlySelectedRows = false,
  namespace,
}) => {
  const selectedRowNames = useMemo(
    () => selectedRowNamesByResourceType.get(currentResourceType) ?? [],
    [currentResourceType, selectedRowNamesByResourceType]
  );
  const onClickApplySelection = useCallback(() => {
    if (selectedRowNames.length > 0) {
      dispatchEvent(AnalyticEvents.PodEvents.ApplySelection_clicked);
    }
    applySelection(selectedRowNamesByResourceType);
  }, [applySelection, selectedRowNames.length, selectedRowNamesByResourceType]);

  const agentId = useActiveAgent(cluster);

  const tableProps = {
    agentId,
    resourceType: currentResourceType,
    cluster: cluster ?? "",
    searchTerm,
    serviceId,
    rowSelection: {
      selectedRowNames,
      onRowSelection: (rowName: string) => {
        toggleRowSelection(currentResourceType, rowName);
      },
      maxSelectableRows,
      includeAllRowsSelection,
    },
    overrideRowClick: (row: ResourceTableModelRow) => {
      toggleRowSelection(currentResourceType, row.uid);
    },
    configurablePageSize: false,
    defaultPageSize: 5,
    includeActionsColumn: false,
    pageLabelAccessory: secondaryTableModifiers,
    eventsDetectionTimeframe,
    showDeletedPods,
    showOnlySelectedRows,
    namespace,
  };

  return (
    <>
      <ModalHeader>
        <Typography variant="headline">Add resources</Typography>
      </ModalHeader>

      <ModalContent>
        <Typography size="medium">
          {`Add up to ${maxSelectableRows} related Kubernetes resources to your service timeline and see changes and event
          history over time.`}
        </Typography>

        <Divider spacing="1rem" />

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "min-content auto",
          }}
        >
          <MenuList style={{ padding: "0 1.5rem 0 0" }}>
            {resourceTypes.map((resourceType, index) =>
              currentResourceType === resourceType ? (
                <ActionItem key={index}>
                  <Typography
                    variant="text"
                    size="medium"
                    bold
                    color={palette.darkBlue[500]}
                  >
                    <NoWrapText data-e2e-selector="related-resource-menu-item">
                      {resourceType.NavBarName}
                    </NoWrapText>
                  </Typography>
                </ActionItem>
              ) : (
                <ActionItem
                  key={index}
                  onClick={() => onResourceTypeChange(resourceType)}
                  aria-label="related-resources type"
                >
                  <Typography variant="text" size="medium">
                    <NoWrapText data-e2e-selector="related-resource-menu-item">
                      {resourceType.NavBarName}
                    </NoWrapText>
                  </Typography>
                </ActionItem>
              )
            )}
          </MenuList>
          <div>
            {tableModifiers}

            <div
              style={{ marginTop: "0.6rem" }}
              data-e2e-selector="add-resources-table-container"
            >
              {(cluster &&
                relatedResources
                  .get(currentResourceType)
                  ?.renderTable?.(tableProps)) ??
                null}
            </div>
          </div>
        </div>
      </ModalContent>

      <ModalActions style={{ position: "absolute", bottom: "1rem", right: 0 }}>
        <Button onClick={close} variant="secondary" data-testid="cancel-button">
          Cancel
        </Button>

        <Button
          onClick={onClickApplySelection}
          aria-label="apply-selection-button"
          variant="primary"
          data-testid="apply-button"
        >
          Apply selection
        </Button>
      </ModalActions>
    </>
  );
};
