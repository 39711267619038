import { Query } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const MAX_REFETCH_RETRY_COUNT = 3;

export const setReactQueryRefetchLimit = <T>(
  query: Query<AxiosResponse<T>> | Query,
  refetchInterval: number | undefined
) => {
  if (!refetchInterval) return undefined;
  return query.state.errorUpdateCount < MAX_REFETCH_RETRY_COUNT
    ? refetchInterval
    : undefined;
};
