import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@komodorio/design-system/komodor-ui";

import { FiltersSection } from "@/pages/organization-settings/account/AuditPage/components/FiltersSection";
import { usePageWidth } from "@/pages/hooks/usePageWidth";
import { NoRowsOverlay } from "@/pages/organization-settings/account/AuditPage/components/NoRowsOverlay";
import { NoResultsOverlay } from "@/pages/organization-settings/account/AuditPage/components/NoResultsOverlay";
import { AuditLog } from "@/generated/accounts";
import { AuditLogDrawer } from "@/pages/organization-settings/account/AuditPage/components/drawer/AuditLogDrawer";
import { useAuditLogDrawer } from "@/pages/organization-settings/account/AuditPage/components/drawer/useAuditLogDrawer";
import { useAuditGrid } from "@/pages/organization-settings/account/AuditPage/hooks/useAuditGrid";
import { auditPageAriaLabels } from "@/pages/organization-settings/account/AuditPage/constants";

export const AuditPageContent = () => {
  const { ref, width } = usePageWidth();
  const { isOpen, closeDrawer, drawerContent, setSelectedLog } =
    useAuditLogDrawer();

  const {
    columns,
    rows,
    handleOnPaginationModelChange,
    handleOnSortModelChange,
    paginationModel,
    sortModel,
    totalCount,
  } = useAuditGrid();

  return (
    <Stack direction="column" rowGap={2} ref={ref}>
      <FiltersSection />
      <Paper elevation={1} sx={{ width: width || "100%", flexGrow: 1 }}>
        <DataGrid
          aria-label={auditPageAriaLabels.table}
          autoHeight
          columns={columns}
          rows={rows}
          disableVirtualization
          paginationModel={paginationModel}
          onPaginationModelChange={handleOnPaginationModelChange}
          paginationMode="server"
          pagination
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleOnSortModelChange}
          rowCount={totalCount}
          slots={{
            noRowsOverlay: NoRowsOverlay,
            noResultsOverlay: NoResultsOverlay,
          }}
          sx={{
            "--DataGrid-overlayHeight": "300px",
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          onRowClick={(row) => setSelectedLog(row.row as AuditLog)}
        />
      </Paper>
      <AuditLogDrawer
        open={isOpen}
        onClose={closeDrawer}
        ariaLabel={auditPageAriaLabels.drawer.container}
      >
        {drawerContent}
      </AuditLogDrawer>
    </Stack>
  );
};
