export enum ResourceResultType {
  KomodorService = "KOMODOR_SERVICE",
  RawResource = "RAW_RESOURCE",
}

export interface IResourceDrawerByData {
  cluster: string;
  namespace?: string;
  resourceType: string;
  resourceName: string;
  buildPreloadResource?: boolean;
  additionalData?: {
    id?: string;
    controlledBy?: string;
    calculatedStatus?: string;
    isCustomResource?: boolean;
  };
  fetchFromDb?: boolean;
}

export interface IResourceDrawerByServiceId {
  serviceId: string;
}

export enum ResourceTab {
  Details = "Details",
  Events = "Events",
  Pods = "Pods",
  Nodes = "Nodes",
  Info = "Info",
  WorkloadMetrics = "Workload Metrics",
  NodeMetrics = "Node Metrics",
  PodMetrics = "Pod Metrics",
  PodLogs = "Logs",
  ServicePodLogs = "Service Pod Logs",
  Yaml = "YAML",
  PodDetails = "Pod Describe",
  ExternalDnsSummaryTab = "External DNS Summary",
}
