import { Container } from "komodor-types/src/entities/AgentTask";

import { notifyDDError } from "../../hooks/exceptionManagement";

export const parseContainersResult = (containersResult: unknown) => {
  try {
    const result = (containersResult as string)
      .replaceAll(",]", "]")
      .replaceAll("'", "");
    return JSON.parse(result) as Container[];
  } catch (e) {
    notifyDDError(new Error(`could not parse containers resources action`), {
      originalErrorMessage: (e as Error).message || "",
      containersResult,
    });
    return undefined;
  }
};
