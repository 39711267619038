import React from "react";
import { parseISO } from "date-fns";
import { uniqBy } from "lodash";
import { WorkflowType } from "komodor-types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { getStatusGroup } from "../statusMapper";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NativePodEventsDetails } from "../ProcessList/details/PodEventsDetails";
import { EventsChartTooltipComponent } from "../EventsChart/TimelineChart/EventTooltip";

import PodShape from "./shapes/pod.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupEventsByKey, mapAndSort, withServices } from "./groupEvents";
import WorkflowIssueEventGroup from "./workflowIssues/WorkflowIssueEventGroup";
import { POD_EVENT_TYPE } from "./nativePodEvent/utils";
import { NativePodFragment } from "./nativePodEvent/types";

import EventGroup, { EventBase, EventGroupTooltipProps } from ".";
export const NATIVE_POD_EVENT_TYPE = "Native pod event";

export type NativePodEvent = EventBase &
  Omit<NativePodFragment, keyof EventBase>;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NativePodEventGroup implements EventGroup {
  readonly backgroundColor;
  readonly fillColor;
  readonly icon = PodShape;

  readonly id;
  readonly health: string;
  readonly events: NativePodEvent[];
  readonly type: string;
  readonly severityType: string;
  readonly startTime: Date;
  readonly endTime: Date;
  readonly count: number;
  readonly serviceId;
  readonly podName: string;
  readonly namespace: string;
  readonly clusterName: string;
  readonly message: string;
  readonly reason: string;
  readonly summary: string;
  readonly status: string;
  readonly details: string;
  readonly nodeName: string;
  readonly nodeHealthStatus: string;

  constructor(events: NativePodEvent[], nodeIssues: WorkflowIssueEventGroup[]) {
    const lastEvent = events[events.length - 1];
    const statusGroup = getStatusGroup(
      lastEvent.severityType,
      NATIVE_POD_EVENT_TYPE
    );

    this.id = lastEvent.id;
    this.health = lastEvent.severityType === "Normal" ? "Healthy" : "Unhealthy";
    this.events = events;
    this.type = lastEvent.type;
    this.severityType = lastEvent.severityType;
    this.startTime = parseISO(events[0].firstTimestamp);
    this.endTime = parseISO(lastEvent.lastTimestamp);
    this.serviceId = lastEvent.serviceId;
    this.podName = lastEvent.podName;
    this.backgroundColor = statusGroup.backgroundColor;
    this.fillColor = statusGroup.fillColor;
    this.namespace = lastEvent.namespace;
    this.clusterName = lastEvent.clusterName;
    this.message = lastEvent.message;
    this.reason = lastEvent.reason;
    this.count = lastEvent.count;
    this.summary = `${lastEvent.podName} is ${this.health.toLowerCase()}`;
    this.status = lastEvent.reason;
    this.details = lastEvent.message;
    const relevantNode = nodeIssues.find((nodeIssue) => {
      return (
        nodeIssue.type === WorkflowType.Node &&
        this.startTime > nodeIssue?.startTime &&
        this.endTime < nodeIssue?.endTime
      );
    });
    this.nodeName = lastEvent.nodeName ?? "";
    this.nodeHealthStatus = relevantNode ? "Unhealthy" : "Healthy";
  }

  renderEventDetails(): JSX.Element {
    return <NativePodEventsDetails eventGroup={this} />;
  }

  renderTooltipContent(props: EventGroupTooltipProps): JSX.Element {
    return (
      <EventsChartTooltipComponent
        title={"Pod Event"}
        data={[
          {
            key: "Reason",
            value: this.status,
          },
          {
            key: "Last Updated",
            value: props.dateFormatter?.(this.endTime) ?? "",
          },
          {
            key: "Message",
            value: this.summary,
          },
        ]}
      />
    );
  }
}

export const groupNativePodEvents = (
  events: NativePodFragment[],
  serviceId: string,
  nodeIssues: WorkflowIssueEventGroup[]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): NativePodEventGroup[] => {
  const uniqEvents = uniqBy(events, "id");
  return groupEventsByKey(
    mapAndSort(withServices(uniqEvents, serviceId), toNativePodEvent),
    (e) => `${e.serviceId}:${e.id}`
  ).map((es) => new NativePodEventGroup(es, nodeIssues));
};

const toNativePodEvent = (
  e: NativePodFragment,
  serviceId: string
): NativePodEvent => {
  return {
    ...e,
    eventTime: parseISO(e.lastTimestamp),
    serviceId: serviceId,
    type: POD_EVENT_TYPE,
  };
};
