import { useResourcesAPIGet } from "../../../../shared/hooks/resources-api/client";
import { WORKLOADS_CRON_JOBS_EVENT_SEARCH } from "../../../../shared/hooks/resources-api/requestResponseMaps";
import {
  Create,
  DESC,
  Update,
  useBuildKomodorUidForJob,
} from "../../../../shared/hooks/resources-api/resourcesAPIUtils";

export const useCronJobNewSpec = (
  resourceId: string
): { cronJobObj: unknown; fetching: boolean } => {
  const komodorUid = useBuildKomodorUidForJob(resourceId);

  const { data: cronJob, loading } = useResourcesAPIGet(
    WORKLOADS_CRON_JOBS_EVENT_SEARCH,
    {
      limit: 1,
      order: DESC,
      komodorUids: [komodorUid ?? ""],
      fields: ["newObj"],
      actions: [Update, Create],
    },
    !komodorUid
  );

  const cronJobObj = cronJob?.data?.[0]?.newObj;
  return { cronJobObj, fetching: loading };
};
