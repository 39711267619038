import {
  DatadogContext,
  DatadogViewNamesEnum,
} from "@/shared/types/datadogReporting";
import { KubernetesResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ViewOptions } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { K8sResource } from "@/components/Inspection/InspectionViews/datadogReporting/inspectionViewReportingTypes";

export const getViewOptionsByResource = (
  kubernetesResource: KubernetesResource,
  isDeletedPods?: boolean | null
): ViewOptions => {
  return {
    name: getViewNameByResource(kubernetesResource, isDeletedPods),
    context: getViewContextByResource(kubernetesResource),
  };
};

const getViewNameByResource = (
  kubernetesResource: KubernetesResource,
  isDeletedPods?: boolean | null
): DatadogViewNamesEnum => {
  if (isDeletedPods) {
    return DatadogViewNamesEnum.deletedPodsPage;
  }
  return k8sNameToViewNameMap[kubernetesResource.NameInK8S as K8sResource];
};

const k8sNameToViewNameMap: Record<K8sResource, DatadogViewNamesEnum> = {
  nodes: DatadogViewNamesEnum.nodesPage,
  pods: DatadogViewNamesEnum.podsPage,
  deletedPods: DatadogViewNamesEnum.deletedPodsPage,
  replicasets: DatadogViewNamesEnum.replicaSetsPage,
  deployments: DatadogViewNamesEnum.deploymentsPage,
  jobs: DatadogViewNamesEnum.jobsPage,
  cronjobs: DatadogViewNamesEnum.cronJobsPage,
  statefulsets: DatadogViewNamesEnum.statefulSetsPage,
  daemonsets: DatadogViewNamesEnum.daemonSetsPage,
  persistentvolumeclaims: DatadogViewNamesEnum.pvcsPage,
  persistentvolumes: DatadogViewNamesEnum.pvsPage,
  storageclasses: DatadogViewNamesEnum.storageClassesPage,
  configmaps: DatadogViewNamesEnum.configMapsPage,
  secrets: DatadogViewNamesEnum.secretsPage,
  resourcequotas: DatadogViewNamesEnum.resourceQuotasPage,
  limitranges: DatadogViewNamesEnum.limitRangesPage,
  horizontalpodautoscalers: DatadogViewNamesEnum.hpasPage,
  poddisruptionbudgets: DatadogViewNamesEnum.pdbsPage,
  services: DatadogViewNamesEnum.kubernetesServicesPage,
  endpoints: DatadogViewNamesEnum.endpointsPage,
  ingresses: DatadogViewNamesEnum.ingressesPage,
  networkpolicies: DatadogViewNamesEnum.networkPoliciesPage,
  endpointslices: DatadogViewNamesEnum.endpointSlicesPage,
  serviceaccounts: DatadogViewNamesEnum.serviceAccountsPage,
  clusterroles: DatadogViewNamesEnum.clusterRolesPage,
  roles: DatadogViewNamesEnum.rolesPage,
  clusterrolebindings: DatadogViewNamesEnum.clusterRoleBindingsPage,
  rolebindings: DatadogViewNamesEnum.roleBindingsPage,
  namespaces: DatadogViewNamesEnum.namespacesPage,
  customresourcedefinitions: DatadogViewNamesEnum.customResourceDefinitionsPage,
  secret: DatadogViewNamesEnum.secretsPage,
  helm: DatadogViewNamesEnum.helmPage,
};

const getViewContextByResource = (
  kubernetesResource: KubernetesResource
): DatadogContext => {
  return k8sNameToReportingContext[kubernetesResource.NameInK8S as K8sResource];
};

const k8sNameToReportingContext: Record<K8sResource, DatadogContext> = {
  nodes: { feTeam: "operate", beTeam: "operate" },
  pods: { feTeam: "operate", beTeam: "barzelim" },
  deletedPods: { feTeam: "operate", beTeam: "barzelim" },
  replicasets: { feTeam: "operate", beTeam: "barzelim" },
  deployments: { feTeam: "operate", beTeam: "barzelim" },
  jobs: { feTeam: "operate", beTeam: "barzelim" },
  cronjobs: { feTeam: "operate", beTeam: "barzelim" },
  statefulsets: { feTeam: "operate", beTeam: "barzelim" },
  daemonsets: { feTeam: "operate", beTeam: "barzelim" },
  persistentvolumeclaims: { feTeam: "operate", beTeam: "barzelim" },
  persistentvolumes: { feTeam: "operate", beTeam: "barzelim" },
  storageclasses: { feTeam: "operate", beTeam: "barzelim" },
  configmaps: { feTeam: "operate", beTeam: "barzelim" },
  secrets: { feTeam: "operate", beTeam: "barzelim" },
  resourcequotas: { feTeam: "operate", beTeam: "barzelim" },
  limitranges: { feTeam: "operate", beTeam: "barzelim" },
  horizontalpodautoscalers: { feTeam: "operate", beTeam: "barzelim" },
  poddisruptionbudgets: { feTeam: "operate", beTeam: "barzelim" },
  services: { feTeam: "operate", beTeam: "barzelim" },
  endpoints: { feTeam: "operate", beTeam: "barzelim" },
  ingresses: { feTeam: "operate", beTeam: "barzelim" },
  networkpolicies: { feTeam: "operate", beTeam: "barzelim" },
  endpointslices: { feTeam: "operate", beTeam: "barzelim" },
  serviceaccounts: { feTeam: "operate", beTeam: "barzelim" },
  clusterroles: { feTeam: "operate", beTeam: "barzelim" },
  roles: { feTeam: "operate", beTeam: "barzelim" },
  clusterrolebindings: { feTeam: "operate", beTeam: "barzelim" },
  rolebindings: { feTeam: "operate", beTeam: "barzelim" },
  namespaces: { feTeam: "operate", beTeam: "barzelim" },
  customresourcedefinitions: { feTeam: "operate", beTeam: "barzelim" },
  helm: { feTeam: "operate", beTeam: "operate" },
  secret: { feTeam: "operate", beTeam: "barzelim" },
};
