import React from "react";

import isElectronContext from "../../../../shared/context/electron/isElectronContext";
import { RuleStepSectionContainer } from "../common/RuleStepSectionContainer";
import SinkSection from "../common/SinkSection";

import NodeScopeSection from "./NodeScopeSection";
import { NodeTriggersSection } from "./NodeTriggersSection";

import {
  ConfigurationVariables,
  MonitorConfiguration,
  MonitorType,
} from "@/generated/monitorsApi";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const nodeDefaultRule: MonitorConfiguration = {
  id: "",
  name: "",
  type: MonitorType.Node,
  active: true,
  sensors: [{ cluster: "" }],
  variables: {
    duration: 30,
    nodeCreationThreshold: "3m",
  },
};

const NodeRuleSections: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleVariablesChange = (variables: ConfigurationVariables) => {
    setRule((prev) => ({
      ...prev,
      variables,
    }));
  };

  return (
    <>
      <RuleStepSectionContainer sectionNumber={2} title="Trigger conditions">
        <NodeTriggersSection
          id="conditions"
          variables={rule.variables}
          handleChange={handleVariablesChange}
        />
      </RuleStepSectionContainer>
      <RuleStepSectionContainer sectionNumber={3} title="Select Scope:">
        <NodeScopeSection />
      </RuleStepSectionContainer>
      {!isElectronContext() && (
        <RuleStepSectionContainer
          sectionNumber={4}
          title="Where do you want to receive notifications? (Optional)"
        >
          <SinkSection rule={rule} setRule={setRule} />
        </RuleStepSectionContainer>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeRuleSections;
