import { Operator } from "@/generated/addonsApi";
import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { Dictionary } from "@/shared/types/Dictionary";

export const getFilterObject = (
  filters: Dictionary<FilterOptionInUrl[]> | undefined,
  withFilter: {
    filterName: string;
    filterValue: boolean;
  }
) => {
  return {
    filters: {
      ...(filters ?? {}),
      [withFilter.filterName]: [
        { values: [withFilter.filterValue.toString()], operator: Operator.Eq },
      ],
    },
  };
};
