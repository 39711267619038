import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";

import { MONITORS_CONFIGURATIONS_PATH } from "./useGetMonitorsConfigurations";

import {
  apiV1ConfigurationsPostUrl,
  ConfigurationsApiApiV1ConfigurationsPostRequest,
  MonitorConfiguration,
} from "@/generated/monitorsApi";

const createMonitorConfiguration = async (
  apiClient: AxiosInstance,
  params: ConfigurationsApiApiV1ConfigurationsPostRequest
) => {
  const { data } = await apiClient.post<MonitorConfiguration>(
    apiV1ConfigurationsPostUrl(params, apiClient.defaults.baseURL ?? ""),
    params.monitorConfigurationPostRequestBody
  );

  return data;
};

export const usePostMonitorConfiguration = () => {
  const apiClient = useMonitorsApiClient();
  return useMutation(
    [MONITORS_CONFIGURATIONS_PATH],
    async (params: ConfigurationsApiApiV1ConfigurationsPostRequest) =>
      createMonitorConfiguration(apiClient, params)
  );
};
