//JobsView
export const JOBS = "jobs";
export const JOBS_ROUTE = `/${JOBS}`;
export const JOB_ID_PARAM = "jobId";

//ServicesView
export const SERVICES = "services";
export const SERVICES_ROUTE = `/${SERVICES}`;
export const SERVICE_ID_PARAM = "serviceId";

export const EVENTS = "events";
export const EVENTS_ROUTE = `/main/${EVENTS}`;

export const INTEGRATIONS = "integration";
export const INTEGRATIONS_ROUTE = `/main/${INTEGRATIONS}`;

export const CLUSTERS = "clusters";
export const CLUSTERS_ROUTE = `/${CLUSTERS}`;

export const AGENTS = "agents";
export const AGENTS_ROUTE = `/${AGENTS}`;

export const RESOURCE_DEPENDENCIES = "resource-dependencies";

//Inspection
export const INSPECTION = "resources";
export const INSPECTION_ROUTE = `/main/${INSPECTION}`;

//Cost
export const COST_OPTIMIZATION = "cost-optimization";
export const COST_OPTIMIZATION_ROUTE = `/${COST_OPTIMIZATION}`;

//Investigation mode
export const INVESTIGATION = "investigation";
export const RUN_ID_PARAM = "runId";

// Expert Mode
export const EXPERT_OVERVIEW = "overview";

// Reliability
export const WORKLOAD_HEALTH = "workload-health";
export const WORKLOAD_HEALTH_ROUTE = `/${WORKLOAD_HEALTH}`;

export const INFRA_HEALTH = "infrastructure-health";

//Kubernetes Addons
export const K8S_ADDONS = "kubernetes-add-ons";
export const K8S_ADDONS_ROUTE = `/${K8S_ADDONS}`;
export const K8S_ADDONS_CERT_MANAGER = "cert-manager";
export const K8S_ADDONS_CERT_MANAGER_ROUTE = `${K8S_ADDONS_ROUTE}/${K8S_ADDONS_CERT_MANAGER}`;
export const K8S_ADDONS_WORKFLOWS = "workflows";
export const K8S_ADDONS_WORKFLOWS_ROUTE = `${K8S_ADDONS_ROUTE}/${K8S_ADDONS_WORKFLOWS}`;
export const WORKFLOW_ID_PARAM = "komodorWorkflowId";
export const K8S_ADDONS_EXTERNAL_DNS = "external-dns";
export const K8S_ADDONS_EXTERNAL_DNS_ROUTE = `${K8S_ADDONS_ROUTE}/${K8S_ADDONS_EXTERNAL_DNS}`;
export const K8S_ADDONS_NODE_AUTOSCALERS = "node-autoscalers";
export const K8S_ADDONS_NODE_AUTOSCALERS_ROUTE = `${K8S_ADDONS_ROUTE}/${K8S_ADDONS_NODE_AUTOSCALERS}`;

//Workspaces
export const WORKSPACE = "workspace";
export const EDIT_WORKSPACE = "edit";
export const CREATE_WORKSPACE = "new";
export const WORKSPACE_ID_PARAM = "id";
export const WORKSPACE_ROUTE = `/${WORKSPACE}`;
export const CREATE_WORKSPACE_ROUTE = `/${WORKSPACE}/${CREATE_WORKSPACE}`;

//Settings
export const SETTINGS = "settings";
export const SETTINGS_ROUTE = `/${SETTINGS}/profile`;
export const AUDIT = "audit";
export const AUDIT_ROUTE = `/${AUDIT}`;
export const USERS_ROUTE = `/${SETTINGS}/users`;
export const ROLES_ROUTE = `/${SETTINGS}/roles`;
export const POLICIES_ROUTE = `/${SETTINGS}/policies`;
export const ACTIONS_ROUTE = `/${SETTINGS}/actions`;
export const IP_WHITELIST_ROUTE = `/${SETTINGS}/cidr-whitelist`;
export const FEATURES_ROUTE = `/${SETTINGS}/features`;
export const USAGE_ROUTE = `/${SETTINGS}/usage`;
export const AGENTS_SETTINGS_ROUTE = `/${SETTINGS}/agents`;
export const RELIABILITY_SETTINGS_ROUTE = `/${SETTINGS}/reliability`;
export const AUDIT_SETTINGS_ROUTE = `/${SETTINGS}/audit-logs`;

// User settings
export const USER_SETTINGS = "user";
export const USER_PROFILE_ROUTE = `/${USER_SETTINGS}/profile`;
export const USER_API_KEYS_ROUTE = `/${USER_SETTINGS}/api-keys`;
export const USER_EMAIL_SETTINGS_ROUTE = `/${USER_SETTINGS}/email-settings`;
export const MY_PERMISSIONS_ROUTE = `/${USER_SETTINGS}/my-permissions`;
export const USER_GET_KUBECONFIG_ROUTE = `/${USER_SETTINGS}/kubeconfig`;
export const USER_LOGOUT_ROUTE = "/logout";

export const DEMO = "d";
export const DEMO_ROUTE = `/${DEMO}`;
export const DRIFT_ANALYSIS_DEMO = "drift-analysis";
export const DRIFT_ANALYSIS_DEMO_ROUTE = `/${DEMO}/${DRIFT_ANALYSIS_DEMO}`;
export const DRIFT_ANALYSIS = "drift-analysis";
export const DRIFT_ANALYSIS_ROUTE = `/${DRIFT_ANALYSIS}`;
