type ResourceWithKind = { kind: string };
type ResourceWithLastModified = {
  kind: string;
  lastModified: Date | undefined;
};

export const isJob = (
  resource: ResourceWithKind
): resource is ResourceWithLastModified => resource.kind === "Job";

export const isCronJob = (
  resource: ResourceWithKind
): resource is ResourceWithLastModified => resource.kind === "CronJob";
