import React from "react";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import styled from "styled-components";

import { CostOptimizationTab } from "../types/costOptimizationTypes";
import { useCostOptimizationDataInUrl } from "../hooks/persistentStateHooks";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { DEFAULT_TAB } from "../constants/costOptimizationConstants";

import { AllocationPage } from "./AllocationPage/AllocationPage";
import { RightSizingPage } from "./RightSizingPage/RightSizingPage";
import { CostOptimizationSettings } from "./CostOptimizationSettings/CostOptimizationSettings";
import { RightSizingModalContainer } from "./RightSizingPage/RightSizingRecommendationModal/RightSizingModalContainer";
import { RiskPageMock } from "./AllocationPage/RiskPageMock/RiskPageMock";

import { useTriggerDatadogViewNameOnFilterChange } from "@/components/CostOptimizationView/hooks/pageDataLoadedHooks";

type TabLabel = "Allocation" | "Right-sizing" | "Risk Analysis";

const tabLabels: Record<
  string,
  { label: TabLabel; value: CostOptimizationTab }
> = {
  allocation: { label: "Allocation", value: "allocation" },
  rightSizing: { label: "Right-sizing", value: "rightSizing" },
  riskAnalysis: { label: "Risk Analysis", value: "riskAnalysis" },
};

const StyledTabPanel = styled(TabPanel)`
  && {
    padding: 16px 0;
  }
`;

const StyledTabList = styled(TabList)`
  && {
    padding: 0 1rem 0 0;
    display: flex;
    align-items: center;
  }
`;

const SettingsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-self: center;
`;

export const CostOptimizationTabs: React.FC = () => {
  const [{ tab }, setDataInUrl] = useCostOptimizationDataInUrl();
  const { allocation, rightSizing, riskAnalysis } = tabLabels;
  const { komodorCostConfigSettings, showRiskAnalysis } = useOverridableFlags();

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    newValue && setDataInUrl({ tab: newValue as CostOptimizationTab });
  };

  useTriggerDatadogViewNameOnFilterChange();

  return (
    <div>
      <TabContext value={tab ?? DEFAULT_TAB}>
        <StyledTabList onChange={onTabChange}>
          <Tab label={allocation.label} value={allocation.value} />
          <Tab label={rightSizing.label} value={rightSizing.value} />
          {!!showRiskAnalysis && (
            <Tab label={riskAnalysis.label} value={riskAnalysis.value} />
          )}
          <SettingsContainer>
            {!!komodorCostConfigSettings && <CostOptimizationSettings />}
          </SettingsContainer>
        </StyledTabList>
        <StyledTabPanel value={allocation.value}>
          <AllocationPage />
        </StyledTabPanel>
        <StyledTabPanel value={rightSizing.value}>
          <RightSizingPage />
          <RightSizingModalContainer />
        </StyledTabPanel>
        {!!showRiskAnalysis && (
          <StyledTabPanel value={riskAnalysis.value}>
            <RiskPageMock />
          </StyledTabPanel>
        )}
      </TabContext>
    </div>
  );
};
