import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Drawer } from "@komodorio/design-system/deprecated";
import { TooltipWrapper } from "react-tooltip";
import { QueryObserverResult } from "@tanstack/react-query";

import { isSandbox } from "../../../shared/utils/sandbox";
import xIcon from "../assets/x.svg";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { LightText } from "../common/fonts";
import {
  useFormValidations,
  ValidationsProvider,
} from "../../../shared/context/ValidationsProvider";
import useAnalyticsApi from "../../../shared/context/analyticsProvider";
import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

import {
  RuleStepSectionContainer,
  Section,
} from "./common/RuleStepSectionContainer";
import RuleTypeSection from "./common/RuleTypeSection";
import PVCRuleSections, { pvcDefaultRule } from "./PVCRuleSections";
import NodeRuleSections from "./NodeRuleSections";
import AvailabilityRuleSections from "./AvailabilityRuleSections";
import JobRuleSections from "./JobRuleSections";
import CronJobRuleSections from "./CronJobRuleSections";
import DeployRuleSections from "./DeployRuleSections";

import WorkflowRuleSections from "@/components/monitorsView/monitorsConfiguration/WorkflowRuleSections";
import { MonitorConfiguration, MonitorType } from "@/generated/monitorsApi";
import { usePostMonitorConfiguration } from "@/shared/hooks/monitors-api/client/monitors/usePostMonitorConfiguration";
import { usePutMonitorConfigurationById } from "@/shared/hooks/monitors-api/client/monitors/usePutMonitorConfigurationById";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const CloseButton = styled.img.attrs({ src: xIcon, alt: "close" })`
  cursor: pointer;
  align-self: start;
  padding-top: 0.25rem;
`;

const HeaderSection = styled(Section)`
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #3b3d45;
  margin-top: 0.5rem;
`;

const SubmitSection = styled(Section)`
  margin-top: auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

interface MonitorRuleDrawerProps<T> {
  isOpen: boolean;
  initRule: MonitorConfiguration | undefined;
  isTypeStatic: boolean;
  handleClose: () => void;
  refreshMonitors: () => Promise<QueryObserverResult<T, unknown>>;
}

const MonitorRuleDrawer: React.FC<
  MonitorRuleDrawerProps<MonitorConfiguration | MonitorConfiguration[]>
> = ({ isOpen, initRule, isTypeStatic, handleClose, refreshMonitors }) => {
  return (
    <Drawer
      open={isOpen}
      direction="right"
      width="48rem"
      onOverlayClick={handleClose}
      onEscKeyPress={handleClose}
    >
      {isOpen && (
        <ValidationsProvider>
          <DrawerContent
            initRule={initRule}
            isTypeStatic={isTypeStatic}
            handleClose={handleClose}
            refreshMonitors={refreshMonitors}
          />
        </ValidationsProvider>
      )}
    </Drawer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MonitorRuleDrawer;

const ConfigRuleByType: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule, setRule }) => {
  switch (rule.type) {
    case MonitorType.Pvc:
      return <PVCRuleSections rule={rule} setRule={setRule} />;
    case MonitorType.Node:
      return <NodeRuleSections rule={rule} setRule={setRule} />;
    case MonitorType.Availability:
      return <AvailabilityRuleSections rule={rule} setRule={setRule} />;
    case MonitorType.Job:
      return <JobRuleSections rule={rule} setRule={setRule} />;
    case MonitorType.CronJob:
      return <CronJobRuleSections rule={rule} setRule={setRule} />;
    case MonitorType.Deploy:
      return <DeployRuleSections rule={rule} setRule={setRule} />;
    case MonitorType.Workflow:
      return <WorkflowRuleSections rule={rule} setRule={setRule} />;
    default:
      return null;
  }
};

const DrawerContent: React.FC<
  Omit<
    MonitorRuleDrawerProps<MonitorConfiguration | MonitorConfiguration[]>,
    "isOpen"
  >
> = ({ initRule, isTypeStatic, handleClose, refreshMonitors }) => {
  const [submitRequested, setSubmitRequested] = useState(false);
  const [rule, setRule] = useState<MonitorConfiguration>(pvcDefaultRule);
  const { errors } = useFormValidations();
  const isConfigurationValid = Object.values(errors).every(
    (err) => err === undefined
  );

  const { mutateAsync: createMonitorConfiguration } =
    usePostMonitorConfiguration();
  const { mutateAsync: updateMonitorConfiguration } =
    usePutMonitorConfigurationById();

  useEffect(() => {
    if (initRule) {
      setRule(initRule);
    }
  }, [initRule]);

  const analytics = useAnalyticsApi();
  const reportAnalytics = useCallback(
    (newRule: MonitorConfiguration) => {
      initRule
        ? dispatchEvent(AnalyticEvents.MonitorsView.Rule_Modified, {
            type: newRule.type,
            duration: newRule.variables?.duration,
            minAvailable: newRule.variables?.minAvailable,
          })
        : dispatchEvent(AnalyticEvents.MonitorsView.Rule_Created, {
            type: newRule.type,
            duration: newRule.variables?.duration,
            minAvailable: newRule.variables?.minAvailable,
          });

      const newSinks = (newRule.sinks as Record<string, string[]>) ?? {};
      const oldSinks = (initRule?.sinks as Record<string, string[]>) ?? {};

      Object.keys(newSinks).forEach((k) => {
        if (newSinks[k] && !oldSinks[k]) {
          analytics.dispatchEventViaBackend(
            AnalyticEvents.MonitorsView.Sink_Configured,
            {
              type: k,
            },
            true
          );
        }
      });
      Object.keys(oldSinks).forEach((k) => {
        if (oldSinks[k] && !newSinks[k]) {
          dispatchEvent(AnalyticEvents.MonitorsView.Sink_Removed, { type: k });
        }
      });
    },
    [analytics, initRule]
  );

  useEffect(() => {
    if (submitRequested && rule) {
      reportAnalytics(rule);
      (async () => {
        if (!rule.type || rule.sensors === undefined) {
          return;
        }
        const body = {
          ...rule,
          type: rule.type,
          sensors: rule.sensors,
        };
        if (rule.id) {
          await updateMonitorConfiguration({
            monitorConfigurationPostRequestBody: body,
            id: rule.id,
          });
        } else {
          await createMonitorConfiguration({
            monitorConfigurationPostRequestBody: body,
          });
        }
        await refreshMonitors();
        handleClose();
        setSubmitRequested(false);
      })();
    }
  }, [
    createMonitorConfiguration,
    handleClose,
    refreshMonitors,
    reportAnalytics,
    rule,
    submitRequested,
    updateMonitorConfiguration,
  ]);

  return (
    <Container>
      <HeaderSection topDivider={false}>
        <div>
          {rule.sensors && rule.sensors?.length > 0 && (
            <LightText fontSize="16px">
              Cluster: {rule.sensors[0].cluster}
            </LightText>
          )}
          <HeaderTitle>{rule.id ? "Edit Rule" : "New Rule"}</HeaderTitle>
        </div>
        <CloseButton onClick={handleClose} />
      </HeaderSection>
      <RuleStepSectionContainer sectionNumber={1} title="Rule Type:">
        {rule.sensors && rule.sensors?.length > 0 && rule.type && (
          <RuleTypeSection
            currentCluster={rule.sensors[0].cluster}
            currentType={rule.type}
            setRule={setRule}
            isStatic={isTypeStatic}
          />
        )}
      </RuleStepSectionContainer>
      <ConfigRuleByType rule={rule} setRule={setRule} />
      <SubmitSection>
        <ButtonsContainer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <TooltipWrapper
            tooltipId={DEFAULT_TOOLTIP}
            content={
              isSandbox()
                ? "This option is disabled in sandbox version"
                : undefined
            }
          >
            <Button
              variant="primary"
              disabled={submitRequested || !isConfigurationValid || isSandbox()}
              onClick={() => setSubmitRequested(true)}
            >
              {submitRequested ? "Saving..." : "Save Monitor"}
            </Button>
          </TooltipWrapper>
        </ButtonsContainer>
      </SubmitSection>
    </Container>
  );
};
