import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { DagView } from "@/components/k8sAddons/addons/workflows/DagView";
import { DagsListPage } from "@/components/k8sAddons/addons/workflows/DagsListPage/DagsListPage";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Operator } from "@/generated/addonsApi";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import { WORKFLOW_ID_PARAM } from "@/components/routes/routes";
import { WorkflowsTabs } from "@/components/k8sAddons/addons/workflows/WorkflowsTabs";
import {
  WorkflowEngine,
  WorkflowsRoutes,
} from "@/components/k8sAddons/addons/workflows/types";
import { ArgoWorkflowsRootView } from "@/components/argoWorkflowsView/ArgoWorkflowsRootView";
import ArgoWorkflowView from "@/components/argoWorkflowsView/ArgoWorkflowView";
import { useShouldShowArgoWorkflows } from "@/components/argoWorkflowsView/useShouldShowArgoWorkflows";
import {
  useShouldShowAirflow,
  useShouldShowCustomWorkflows,
  useShouldShowSpark,
} from "@/components/k8sAddons/addons/workflows/hooks/shouldShowWorkflowsHooks";
import { PageLoader } from "@/shared/components/PageLoader";
import { WorkflowsEmptyState } from "@/components/k8sAddons/addons/workflows/WorkflowsEmptyState";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { ViewOptions } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

export const Workflows: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sparkWorkflows: sparkWorkflowsFF } = useOverridableFlags();
  const showArgoWorkflows = useShouldShowArgoWorkflows();
  const { shouldShow: showAirflow, isLoading: isLoadingShouldShowAirflow } =
    useShouldShowAirflow();
  const { shouldShow: showSpark, isLoading: isLoadingShouldShowSpark } =
    useShouldShowSpark();
  const {
    shouldShow: showCustomWorkflows,
    isLoading: isLoadingShouldShowCustomWorkflows,
  } = useShouldShowCustomWorkflows();

  const isLoadingPage =
    isLoadingShouldShowAirflow ||
    (sparkWorkflowsFF && isLoadingShouldShowSpark) ||
    isLoadingShouldShowCustomWorkflows;

  const tabValue = useMemo(() => {
    if (location.pathname.includes(WorkflowsRoutes.airflow)) {
      return WorkflowsRoutes.airflow;
    }
    if (sparkWorkflowsFF && location.pathname.includes(WorkflowsRoutes.spark)) {
      return WorkflowsRoutes.spark;
    }
    if (location.pathname.includes(WorkflowsRoutes.argo)) {
      return WorkflowsRoutes.argo;
    }
    return WorkflowsRoutes.custom;
  }, [location.pathname, sparkWorkflowsFF]);

  const defaultRedirectUrl = useMemo(() => {
    if (showAirflow) {
      return WorkflowsRoutes.airflow;
    }
    if (sparkWorkflowsFF && showSpark) {
      return WorkflowsRoutes.spark;
    }
    if (showArgoWorkflows) {
      return WorkflowsRoutes.argo;
    }
    return WorkflowsRoutes.custom;
  }, [showAirflow, showArgoWorkflows, showSpark, sparkWorkflowsFF]);

  useEffect(() => {
    if (
      !isLoadingPage &&
      ((tabValue === WorkflowsRoutes.airflow && !showAirflow) ||
        (tabValue === WorkflowsRoutes.spark &&
          (!showSpark || !sparkWorkflowsFF)) ||
        (tabValue === WorkflowsRoutes.argo && !showArgoWorkflows))
    ) {
      navigate(defaultRedirectUrl);
    }
  }, [
    defaultRedirectUrl,
    isLoadingPage,
    navigate,
    showAirflow,
    showArgoWorkflows,
    showSpark,
    sparkWorkflowsFF,
    tabValue,
  ]);

  const tabsProps = useMemo(
    () => ({
      tabValue,
      engines: {
        airflow: showAirflow ?? false,
        spark: showSpark ?? false,
        argo: showArgoWorkflows,
        custom: true,
      },
    }),
    [showAirflow, showArgoWorkflows, showSpark, tabValue]
  );
  const datadogViewOptions = (name: DatadogViewNamesEnum): ViewOptions => {
    return {
      name: name,
      context: {
        feTeam: "troubleshooting",
        beTeam: "troubleshooting",
      },
    };
  };
  if (isLoadingPage) {
    return <PageLoader />;
  }

  return (
    <FiltersContextProvider
      urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
      searchTypeFilterOperator={Operator.Like}
    >
      <Routes>
        <Route
          path={WorkflowsRoutes.airflow}
          element={
            <DatadogReportLoadingTimeContextProvider
              viewOptions={datadogViewOptions(
                DatadogViewNamesEnum.airflowWorkflowsPage
              )}
            >
              <WorkflowsTabs {...tabsProps}>
                <DagsListPage engineType={WorkflowEngine.Airflow} />
              </WorkflowsTabs>
            </DatadogReportLoadingTimeContextProvider>
          }
        />
        {!!sparkWorkflowsFF && (
          <Route
            path={WorkflowsRoutes.spark}
            element={
              <DatadogReportLoadingTimeContextProvider
                viewOptions={datadogViewOptions(
                  DatadogViewNamesEnum.sparkWorkflowsPage
                )}
              >
                <WorkflowsTabs {...tabsProps}>
                  <DagsListPage engineType={WorkflowEngine.Spark} />
                </WorkflowsTabs>
              </DatadogReportLoadingTimeContextProvider>
            }
          />
        )}
        <Route
          path={WorkflowsRoutes.argo}
          element={
            <DatadogReportLoadingTimeContextProvider
              viewOptions={datadogViewOptions(
                DatadogViewNamesEnum.argoWorkflowPage
              )}
            >
              <WorkflowsTabs {...tabsProps}>
                <ArgoWorkflowsRootView />
              </WorkflowsTabs>
            </DatadogReportLoadingTimeContextProvider>
          }
        />
        <Route
          path={WorkflowsRoutes.custom}
          element={
            <DatadogReportLoadingTimeContextProvider
              viewOptions={datadogViewOptions(
                DatadogViewNamesEnum.workflowPage
              )}
            >
              <WorkflowsTabs {...tabsProps}>
                {showCustomWorkflows ? (
                  <DagsListPage />
                ) : (
                  <WorkflowsEmptyState />
                )}
              </WorkflowsTabs>
            </DatadogReportLoadingTimeContextProvider>
          }
        />
        <Route
          path={`${WorkflowsRoutes.airflow}/:${WORKFLOW_ID_PARAM}`}
          element={<DagView />}
        />
        {!!sparkWorkflowsFF && (
          <Route
            path={`${WorkflowsRoutes.spark}/:${WORKFLOW_ID_PARAM}`}
            element={<DagView />}
          />
        )}
        <Route
          path={`${WorkflowsRoutes.argo}/:${WORKFLOW_ID_PARAM}`}
          element={<ArgoWorkflowView />}
        />
        <Route
          path={`${WorkflowsRoutes.custom}/:${WORKFLOW_ID_PARAM}`}
          element={<DagView />}
        />
        <Route
          path="*"
          element={<Navigate to={defaultRedirectUrl} replace />}
        />
      </Routes>
    </FiltersContextProvider>
  );
};
