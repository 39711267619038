import { useMemo } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { uniq } from "lodash";

import {
  generateWorkloadMetricsRequestParams,
  useEpochsFromWorkloadMetricsTimeWindow,
} from "@/components/Metrics/hooks/workload/workloadMetricsTabHooks";
import { useIsMetricsSupportedForMultipleClusters } from "@/components/Metrics/metricsHooks";
import {
  KomodorUidParams,
  parseKomodorUid,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";
import {
  WorkloadMetricsContainers,
  WorkloadMetricsDataPoints,
} from "@/generated/metricsApi";
import { MetricType } from "@/components/Metrics/types";

type WorkloadsMetricsParamsInput = {
  komodorUids: string[];
  endTime?: number;
};

type WorkloadsMetricsContainersParamsInput = {
  komodorUids: string[];
  endTime?: number;
  type: MetricType;
};

export const useParseKomodorUids = (
  komodorUids: string[]
): KomodorUidParams[] => {
  return useMemo(
    () =>
      komodorUids
        .map((k) => parseKomodorUid(k))
        .filter((k): k is KomodorUidParams => k !== undefined),
    [komodorUids]
  );
};

type WorkloadMetricsContainersResponse = UseQueryResult<
  AxiosResponse<WorkloadMetricsContainers, unknown>,
  unknown
>;
type WorkloadMetricsDataPointsResponse = UseQueryResult<
  AxiosResponse<WorkloadMetricsDataPoints, unknown>,
  unknown
>;

export const useParseMultipleMetricsResults = (
  results:
    | WorkloadMetricsContainersResponse[]
    | WorkloadMetricsDataPointsResponse[]
) => {
  return useMemo(() => {
    return {
      error: results.every((r) => r.error),
      isLoading: results.some((r) => r.isLoading && r.fetchStatus !== "idle"),
      isFetching: results.some((r) => r.isFetching),
      data: results.map((r) => r.data?.data),
    };
  }, [results]);
};

export const useWorkloadsContainersMetricsParams = ({
  komodorUids,
  endTime,
  type,
}: WorkloadsMetricsContainersParamsInput) => {
  const { toEpochMs, fromEpoch, toEpoch } =
    useEpochsFromWorkloadMetricsTimeWindow(endTime);

  const parsedKomodorUids = useParseKomodorUids(komodorUids);
  const requestsParams = useMemo(
    () =>
      parsedKomodorUids.map((k) => ({
        ...generateWorkloadMetricsRequestParams(k, fromEpoch, toEpoch),
        kind: type,
      })),
    [fromEpoch, parsedKomodorUids, toEpoch, type]
  );

  const clustersNames = useMemo(
    () => requestsParams.map((params) => params.clusterName),
    [requestsParams]
  );
  const enabledClusters =
    useIsMetricsSupportedForMultipleClusters(clustersNames);

  return {
    enabledClusters,
    requestsParams,
    toEpochMs,
    fromEpoch,
    toEpoch,
  };
};

export const useWorkloadsMetricsParams = ({
  komodorUids,
  endTime,
}: WorkloadsMetricsParamsInput) => {
  const { toEpochMs, fromEpoch, toEpoch } =
    useEpochsFromWorkloadMetricsTimeWindow(endTime);

  const parsedKomodorUids = useParseKomodorUids(komodorUids);
  const requestsParams = useMemo(
    () =>
      parsedKomodorUids.map((k) =>
        generateWorkloadMetricsRequestParams(k, fromEpoch, toEpoch)
      ),
    [fromEpoch, parsedKomodorUids, toEpoch]
  );

  const clustersNames = useMemo(
    () => uniq(requestsParams.map((params) => params.clusterName)),
    [requestsParams]
  );
  const enabledClusters =
    useIsMetricsSupportedForMultipleClusters(clustersNames);

  return {
    enabledClusters,
    requestsParams,
    toEpochMs,
    fromEpoch,
    toEpoch,
  };
};
