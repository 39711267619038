import React, { useEffect, useState } from "react";

import { GraphContainerWithStatus } from "../../../../../Metrics/GraphContainerWithStatus";
import { ResourceGraph } from "../../../../../Metrics/graphs/ResourceGraph";
import { WorkloadMetricsTabContextProvider } from "../../context/WorkloadMetricsTabContext";
import { useWorkloadMetricsTabContext } from "../../context/useWorkloadMetricsTabContext";
import { NoLimitLabel } from "../../../../../Metrics/NoLimitLabel";
import { WorkloadGraphProps } from "../../types";
import { getTitle } from "../../workloadMetricsTabUtils";

import { useWorkloadMetrics } from "./useWorkloadMetrics";
import { EnlargedWorkloadGraph } from "./EnlargedWorkloadGraph";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const WorkloadGraph: React.FC<WorkloadGraphProps> = ({
  type,
  komodorUidParams,
  loadingStateId,
  metricGraphProps,
  additionalProps,
}) => {
  const { initialLoadTime, selectedAggregationMetric, timeWindow } =
    useWorkloadMetricsTabContext();
  const [isGraphEnlarged, setIsGraphEnlarged] = useState(false);
  const title = additionalProps?.graph?.title ?? getTitle(type);
  const customEndTime =
    additionalProps?.requestParams?.toEpoch || initialLoadTime;

  const { graphProps, uid, error, loading, hasGraphData, now, hasLimit } =
    useWorkloadMetrics({
      type,
      komodorUidParams,
      endTime: customEndTime,
    });

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState(loadingStateId, loading);
  }, [loading, loadingStateId, reportLoadingState]);

  return (
    <>
      {isGraphEnlarged && (
        <WorkloadMetricsTabContextProvider
          givenTimeWindow={timeWindow}
          givenAggregationMetric={selectedAggregationMetric}
        >
          <EnlargedWorkloadGraph
            komodorUidParams={komodorUidParams}
            endTime={now}
            onClose={() => setIsGraphEnlarged(false)}
            type={type}
            additionalProps={additionalProps?.enlargedGraphProps}
            metricGraphProps={metricGraphProps}
            children={additionalProps?.additionalGraphElements}
          />
        </WorkloadMetricsTabContextProvider>
      )}
      <GraphContainerWithStatus
        uid={uid}
        error={error}
        loading={loading}
        hasGraphData={hasGraphData}
        title={title}
        info={!loading && !error && !hasLimit ? <NoLimitLabel /> : null}
        onEnlargeGraph={() => setIsGraphEnlarged(true)}
      >
        <ResourceGraph
          type={type}
          {...graphProps}
          {...metricGraphProps}
          children={additionalProps?.additionalGraphElements}
        />
      </GraphContainerWithStatus>
    </>
  );
};
