import { QueryObserverOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { QueryOptions } from "@/shared/hooks/k8s-add-ons/apiClient";
import { setReactQueryRefetchLimit } from "@/shared/utils/react-query/setReactQueryRefetchLimit";

export const getReactQueryOptions = <T>(
  queryOptions: QueryOptions | undefined,
  url: string
) => {
  return <QueryObserverOptions<AxiosResponse<T>>>{
    ...(queryOptions || {}),
    enabled: queryOptions?.enabled && !!url,
    refetchInterval: (_, query) => {
      return setReactQueryRefetchLimit(query, queryOptions?.refetchInterval);
    },
  };
};
