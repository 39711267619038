import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { convertLocalDateToUtc } from "../../../../../../../../../../../shared/utils/dateUtils";
import { Deploy } from "../../../../../../../../../../../generated/resourcesApi";
import { reliabilityArialLabels } from "../../../../../../../../../reliabilityArialLabels";

import {
  deployStatusToTitle,
  getDeployChanges,
} from "./DeployEventPopoverContentUtils";
import { useOpenDeployEventDrawer } from "./useOpenDeployEventDrawer";

const Container = styled.div`
  padding: 0 8px;
  min-width: 240px;
  max-width: 300px;
`;

const EntryContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 4px;
  & :first-child {
    flex: 0 0 60px;
  }
  & :last-child {
    align-self: center;
  }
`;

const Title = styled(Typography).attrs({
  variant: "h6",
})`
  && {
    margin-bottom: 6px;
  }
`;

const Label = styled(Typography).attrs({
  variant: "body3",
  color: "textSecondary",
})``;

const Value = styled(Typography).attrs({
  variant: "body3",
  color: "textPrimary",
})``;

const Status = styled(Typography)<{ $colorOverride?: string }>`
  && {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    && {
      ${({ $colorOverride }) => $colorOverride && `color: ${$colorOverride};`}
    }
  }
`;

const viewDetailsArialLabel =
  reliabilityArialLabels.violationDrawer.metricsGraphs.deployEvent.viewDetails;

type GraphEventPopoverContentProps = {
  deployEvent: Deploy;
};

export const DeployEventPopoverContent: React.FC<
  GraphEventPopoverContentProps
> = ({ deployEvent }) => {
  const openDeployEventDrawer = useOpenDeployEventDrawer(deployEvent.id);
  const dateInUtc = convertLocalDateToUtc(new Date(deployEvent.eventTime));
  const finishedAt = format(dateInUtc, "MMMM d, yyyy HH:mm aa");
  const changes = getDeployChanges(deployEvent);
  const status = deployStatusToTitle[deployEvent.status] ?? {};

  return (
    <Container>
      <Title variant={"h6"}>Deploy</Title>
      <EntryContainer>
        <Label>Finished:</Label>
        <Value>{finishedAt}</Value>
      </EntryContainer>
      <EntryContainer>
        <Label>Status:</Label>
        <Status $colorOverride={status.color}>{status.title}</Status>
      </EntryContainer>
      <EntryContainer>
        <Label>Changes:</Label>
        <Value>{changes}</Value>
      </EntryContainer>
      <Button
        onClick={openDeployEventDrawer}
        variant={"text"}
        aria-label={viewDetailsArialLabel}
      >
        View details
      </Button>
    </Container>
  );
};
