import { Tag, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { StatusGroup } from "../../../statusMapper";

export const CapitalizedTypograohy = styled(Typography)`
  text-transform: capitalize;
`;

/** @deprecated Use komodor-ui/Status instead. */
const StyledTag = styled(Tag)<{ outlineColor?: string }>`
  ${({ outlineColor }) =>
    outlineColor
      ? `outline-style: solid;
    outline-width: thin; 
    outline-color: ${outlineColor};`
      : ""};
`;

export const StatusTag: React.FC<{
  status: string;
  statusGroup: StatusGroup;
  uppercase?: boolean;
}> = ({ status, statusGroup, uppercase }) => {
  return (
    <StyledTag
      color={statusGroup.backgroundColor}
      variant="round"
      outlineColor={statusGroup?.outlineColor}
    >
      <CapitalizedTypograohy
        color={statusGroup.fillColor}
        bold
        variant={uppercase ? "uppercase" : "text"}
      >
        {status}
      </CapitalizedTypograohy>
    </StyledTag>
  );
};
