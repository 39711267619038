import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";

import { useAddonContext } from "../../context/useAddonContext";
import { useRefetchIntervalFromContext } from "../../hooks/useRefetchIntervalFromContext";
import { useFilterListFromUrlAsGenericFilters } from "../../hooks/filters/useFilterListFromUrlAsGenericFilters";
import { useShouldRequestBeEnabled } from "../../hooks/useShouldRequestBeEnabled";
import { useSetIsPaginating } from "../../hooks/useSetIsPaginating";

import { useGetAddonAggregations } from "@/shared/hooks/k8s-add-ons/useGetAddonAggregations";
import { Dictionary } from "@/shared/types/Dictionary";
import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { GenericFilter } from "@/generated/addonsApi";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

type IsFilterFetchingDataParams = {
  filterCategory: string;
  isFetching: boolean;
};

export const useIsFilterFetchingData = ({
  filterCategory,
  isFetching,
}: IsFilterFetchingDataParams) => {
  const filtersDataInUrl = useFiltersDataInUrl();
  const [isFetchingData, setIsFetchingData] = useState(false);
  const lastFiltersInUrl = useRef(filtersDataInUrl);
  useEffect(() => {
    const allOtherFilters = Object.keys(filtersDataInUrl)
      .filter((key) => key !== filterCategory)
      .reduce<Dictionary<FilterOptionInUrl[]>>((acc, key) => {
        acc[key] = filtersDataInUrl[key];
        return acc;
      }, {});

    if (isEqual(lastFiltersInUrl.current, allOtherFilters)) {
      setIsFetchingData(false);
      return;
    }

    lastFiltersInUrl.current = allOtherFilters;
    setIsFetchingData(isFetching);
  }, [filterCategory, filtersDataInUrl, isFetching]);

  return { isFetchingData };
};

type FiltersOptionsProps = {
  filterCategory: string;
  additionalFilters: GenericFilter[];
};

export const useGetFiltersOptions = ({
  filterCategory,
  additionalFilters,
}: FiltersOptionsProps) => {
  const [isPaginating, setIsPaginating] = useState(true);

  const { addonType, addonEntity } = useAddonContext();
  const refetchInterval = useRefetchIntervalFromContext(isPaginating);

  const genericFiltersList =
    useFilterListFromUrlAsGenericFilters(filterCategory);

  const filters = [...genericFiltersList, ...additionalFilters];

  const addonAggregations = useGetAddonAggregations(
    {
      addon: addonType,
      entity: addonEntity,
      getEntityAggregationsRequest: {
        groupBy: filterCategory,
        aggregation: "count",
        filter: filters,
      },
    },
    {
      keepPreviousData: true,
      refetchInterval,
      enabled: useShouldRequestBeEnabled(),
    }
  );

  useSetIsPaginating({
    fetchedIsPaginating: addonAggregations.data?.data.isPaginating,
    setIsPaginating,
  });

  return addonAggregations;
};
