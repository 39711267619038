import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  apiV1MetadataExternalLinksGetUrl,
  ExternalLinksResponse,
  MetadataApiApiV1MetadataExternalLinksGetRequest,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

export const EXTERNAL_LINK_FETCH = "/metadata/external-links";

const fetchExternalLinks = async (
  apiClient: AxiosInstance,
  params: MetadataApiApiV1MetadataExternalLinksGetRequest
): Promise<ExternalLinksResponse> => {
  const { data } = await apiClient.get(
    apiV1MetadataExternalLinksGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetExternalLinks = (
  params: MetadataApiApiV1MetadataExternalLinksGetRequest,
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [EXTERNAL_LINK_FETCH, params],
    () => fetchExternalLinks(apiClient, params),
    options
  );
};
