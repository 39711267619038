import React, { useMemo } from "react";
import styled from "styled-components";

import { sectionGap } from "../../../../../../../../../constants/reliabilityConstants";
import { ReliabilityCollapsibleContainer } from "../../../../shared/metrics/components/ReliabilityCollapsibleContainer/ReliabilityCollapsibleContainer";
import { getCollapsibleContainerProps } from "../../../../../violationDrawerUtils";
import { MetricsGraph } from "../../../../shared/metrics/components/MetricsGraph/MetricsGraph";
import { useMetricsParams } from "../../../../shared/metrics/hooks/useMetricsParams";
import { useFetchContainersMetricsData } from "../../../../shared/metrics/hooks/useFetchContainersMetricsData";
import { useFetchContainersSummary } from "../../../../shared/metrics/hooks/useFetchContainersSummary";
import { MetricsSummary } from "../../../../shared/metrics/components/MetricsSummary/MetricsSummary";
import { ViewContainerSpec } from "../../../../shared/ViewContainerSpec/ViewContainerSpec";
import { reliabilityArialLabels } from "../../../../../../../../../reliabilityArialLabels";
import { MetricsAggregationType } from "../../../../../../../../../../Metrics/types";
import { MetricsContentContainer } from "../../../../../violationDrawerStyles";
import { useReportLoadingState } from "../../noisyNeighborHooks";

import { getSummaryCb } from "./summaryUtils";

const {
  violationDrawer: { graphsContainer: graphsContainerAriaLabel },
} = reliabilityArialLabels;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sectionGap};
`;

interface Props {
  komodorUid: string | undefined;
  noisyContainers: string[] | undefined;
}

export const Containers: React.FC<Props> = ({
  komodorUid = "",
  noisyContainers,
}) => {
  const { params, isInvalidParams } = useMetricsParams(komodorUid);

  const memoryRes = useFetchContainersMetricsData(
    { ...params, kind: "memory" },
    isInvalidParams
  );

  const memorySummaryRes = useFetchContainersSummary(
    { ...params, kind: "memory" },
    isInvalidParams
  );

  useReportLoadingState([memoryRes, memorySummaryRes], isInvalidParams);

  const content = useMemo(() => {
    if (!komodorUid || !noisyContainers) return null;
    return noisyContainers.map((containerName, index) => (
      <ReliabilityCollapsibleContainer
        key={containerName}
        {...getCollapsibleContainerProps({
          name: containerName,
          index,
          checkType: "noisyNeighbor",
        })}
        containerName={containerName}
        button={
          <ViewContainerSpec
            komodorUid={komodorUid}
            containerName={containerName}
          />
        }
        content={
          <MetricsContentContainer>
            <MetricsSummary
              title="Memory analysis"
              res={memorySummaryRes}
              getSummaryItems={getSummaryCb()}
              containerName={containerName}
            />
            <MetricsGraph
              komodorUid={komodorUid}
              res={memoryRes}
              type={"memory"}
              containerName={containerName}
              wantedAggregation={MetricsAggregationType.Max}
              graphTitle=""
            />
          </MetricsContentContainer>
        }
      />
    ));
  }, [komodorUid, memoryRes, memorySummaryRes, noisyContainers]);

  return <Container aria-label={graphsContainerAriaLabel}>{content}</Container>;
};
