export const externalDnsListPageAriaLabels = {
  table: "External DNS list page table",
  drawer: {
    sections: {
      rootCauseAnalysis: "External DNS drawer root cause analysis section",
      status: "External DNS drawer status section",
      settings: "External DNS drawer settings section",
      managedRecords: {
        section: "External DNS drawer managed records section",
        table: "External DNS drawer managed records table",
        searchField: "External DNS drawer managed records search field",
        connectedWorkloadsModal: {
          whereSection:
            "External DNS drawer connected workloads modal where section",
          searchField:
            "External DNS drawer connected workloads modal search field",
        },
      },
    },
  },
};
