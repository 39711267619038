import React, { useEffect, useMemo } from "react";
import { SingleSelect } from "@komodorio/design-system/komodor-ui";

import { InspectionFilterContainer } from "../../Inspection/filters/InspectionResourceListSearch";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";

const allRuleTypes = [
  { label: "Availability", value: "availability" },
  { label: "PVC", value: "PVC" },
  { label: "Node", value: "node" },
  { label: "Job", value: "job" },
  { label: "CronJob", value: "cronJob" },
  { label: "Deploy", value: "deploy" },
  { label: "Workflow", value: "workflow" },
];

const MonitorsListRuleTypeFilter: React.FC<{
  ruleTypes: string[];
  ruleType: string | undefined;
  setRuleType: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ ruleTypes, ruleType, setRuleType }) => {
  const suggestions = useMemo(() => {
    return [
      { label: "ALL", value: "" },
      ...ruleTypes.map((c) => ({
        label: allRuleTypes.filter((a) => a.value === c)?.[0]?.label,
        value: c,
      })),
    ];
  }, [ruleTypes]);

  useEffect(() => {
    if (!ruleType || !suggestions.find((s) => s.value === ruleType)) {
      setRuleType(suggestions[0].value);
    }
  }, [ruleType, setRuleType, suggestions]);

  return (
    <InspectionFilterContainer>
      <SingleSelect
        enableSearch
        options={suggestions}
        label="Rule Type"
        value={suggestions.find((s) => s.value === ruleType)}
        disableBlankOption
        onChange={(value) => {
          if (value?.value !== ruleType) {
            setRuleType(value?.value);
            dispatchEvent(AnalyticEvents.MonitorsView.Filter_Selected, {
              filterCategory: "ruleType",
              filterOption: value,
            });
          }
        }}
      />
    </InspectionFilterContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MonitorsListRuleTypeFilter;
