import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import {
  METRICS_POD_CPU_ENDPOINT,
  METRICS_POD_MEMORY_ENDPOINT,
} from "../../../../shared/hooks/metrics-api/requestResponseMaps";
import { useResourceUid } from "../../../../shared/hooks/resources/useResourceUid";

import { useMetricParams } from "./hooks/metricsTabHooks";
import { useContainersMetricsFetchers } from "./ContainerMetricsFetcher";
import { PodContainersGraph } from "./PodContainersGraph";
import { MetricsTimeSelector } from "./MetricsTimeSelector";
import { usePodMetricsWithZoom } from "./hooks/usePodMetricsWithZoom";

import { EnlargeableMetricsTabGraph } from "@/components/ResourceView/tabs/MetricsTab/EnlargeableMetricsTabGraph";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";
import { getTimeWindowLabel } from "@/shared/constants/datadog";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useReportDatadogLoadingState } from "@/components/ResourceView/tabs/MetricsTab/hooks/useReportDatadogLoadingState";
import { useMetricsTabContext } from "@/components/ResourceView/tabs/MetricsTab/context/useMetricsTabContext";

const Container = styled.div`
  background-color: ${palette.white[0]};
  border-radius: 4px;
  padding: 1rem;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1rem 0 2rem 0;
  > div {
    flex: 1;
    min-width: 320px;
  }
`;

type MetricsTabProps = {
  resource: Pod;
};

export const MetricsTabContentComponent: React.FC<MetricsTabProps> = ({
  resource,
}) => {
  const { setPodContainersNames } = useMetricsTabContext();
  const { setMainContainerDivRef } = useDatadogReportLoadingTimeContext();

  const { containerStatuses } = resource;
  const containerRef = useRef<HTMLDivElement>(null);

  setMainContainerDivRef(containerRef);
  useReportDatadogLoadingState();

  const metricsParams = useMetricParams(resource);

  const uid = useResourceUid(resource);

  useEffect(() => {
    setPodContainersNames(
      containerStatuses?.map((container) => container.name) ?? []
    );
  }, [containerStatuses, setPodContainersNames]);

  const { fromEpoch, toEpoch, graphProps } = usePodMetricsWithZoom();

  const containersMetricsFetchers = useContainersMetricsFetchers(
    containerStatuses,
    { ...metricsParams, fromEpoch, toEpoch }
  );

  const isMultipleContainers = useMemo(
    () => containerStatuses && containerStatuses?.length > 1,
    [containerStatuses]
  );

  const syncId = `${resource.cluster}/${resource.namespace}/${resource.name}`;

  return (
    <Container ref={containerRef}>
      <MetricsTimeSelector />
      <GraphContainer>
        <EnlargeableMetricsTabGraph
          resource={resource}
          title={"Memory Usage"}
          metricsParams={metricsParams}
          endpoint={METRICS_POD_MEMORY_ENDPOINT}
          uid={uid}
          syncId={syncId}
        />
        <EnlargeableMetricsTabGraph
          resource={resource}
          title={"CPU Usage"}
          metricsParams={metricsParams}
          endpoint={METRICS_POD_CPU_ENDPOINT}
          uid={uid}
          syncId={syncId}
        />
      </GraphContainer>
      {isMultipleContainers && (
        <GraphContainer>
          <PodContainersGraph
            uid={uid}
            type={"memory"}
            key={"memory"}
            resource={resource}
            endTime={metricsParams.toEpochMs}
            containerStatuses={containerStatuses}
            syncId={syncId}
            graphProps={graphProps}
          />
          <PodContainersGraph
            uid={uid}
            type={"cpu"}
            key={"cpu"}
            resource={resource}
            endTime={metricsParams.toEpochMs}
            containerStatuses={containerStatuses}
            syncId={syncId}
            graphProps={graphProps}
          />
        </GraphContainer>
      )}
      {containersMetricsFetchers}
    </Container>
  );
};

export const MetricsTabContent: React.FC<MetricsTabProps> = (props) => {
  const { timeWindow } = useMetricsTabContext();

  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.PodMetrics, {
      filters: {
        timeWindow: getTimeWindowLabel(timeWindow.timeframe),
      },
    });

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <MetricsTabContentComponent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
