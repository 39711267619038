import { useCallback, useEffect, useRef } from "react";

import { useCostOptimizationStore } from "../store/costOptimizationStore";
import { selectAllocationFilters } from "../store/costOptimizationStoreSelectors";
import {
  STATIC_TIME_FRAME,
  staticCostOptimizationContext,
} from "../constants/costOptimizationConstants";

import { useCostOptimizationDataInUrl } from "./persistentStateHooks";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { CostOptimizationPersistentState } from "@/components/CostOptimizationView/types/costOptimizationTypes";
import {
  ViewName,
  ViewOptions,
} from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import {
  DatadogContext,
  DatadogViewNamesEnum,
} from "@/shared/types/datadogReporting";

export const useGetViewOptions = () => {
  const [{ tab }] = useCostOptimizationDataInUrl();
  const allocationFilters = useCostOptimizationStore(selectAllocationFilters);

  return useCallback((): ViewOptions => {
    const context: DatadogContext = {
      ...staticCostOptimizationContext,
      aggregation: undefined,
      filters: undefined,
    };

    let viewName: DatadogViewNamesEnum;
    if (tab === "allocation") {
      viewName = DatadogViewNamesEnum.costOptimizationAllocation;
      context.aggregation = { groupBy: allocationFilters.groupBy };
      context.filters = { timeWindow: allocationFilters.timeWindow };
    } else {
      viewName = DatadogViewNamesEnum.costOptimizationRightSizing;
      context.filters = { timeWindow: STATIC_TIME_FRAME };
    }

    return {
      name: viewName,
      context,
    };
  }, [allocationFilters.groupBy, allocationFilters.timeWindow, tab]);
};

export const useTriggerDatadogViewNameOnFilterChange = () => {
  const [dataInUrl] = useCostOptimizationDataInUrl();
  const dataInUrlRef = useRef<CostOptimizationPersistentState>(dataInUrl);
  const { triggerStartView, resetLoadingState } =
    useDatadogReportLoadingTimeContext();

  useEffect(() => {
    if (!dataInUrl) {
      return;
    }
    if (JSON.stringify(dataInUrlRef.current) !== JSON.stringify(dataInUrl)) {
      dataInUrlRef.current = dataInUrl;
      resetLoadingState();
      triggerStartView();
    }
  }, [dataInUrl, resetLoadingState, triggerStartView]);
};

export const useDatadogReportingProps = (): ViewName => {
  const getCurrentViewOptions = useGetViewOptions();
  return { getCurrentViewOptions };
};

export const useRegisterComponentLoading = (componentName: string) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState(componentName, true);

    return () => {
      reportLoadingState(componentName, false);
    };
  }, [componentName, reportLoadingState]);
};
