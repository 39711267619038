import React, { useMemo, useState } from "react";

import {
  MetricsTabGraph,
  MetricsTabGraphCommonProps,
} from "@/components/ResourceView/tabs/MetricsTab/MetricsTabGraph";
import Pod from "@/components/ResourceView/resources/pod";
import {
  useMetricParams,
  useMetricsTabGraphData,
} from "@/components/ResourceView/tabs/MetricsTab/hooks/metricsTabHooks";
import { usePodMetricsWithZoom } from "@/components/ResourceView/tabs/MetricsTab/hooks/usePodMetricsWithZoom";
import { EnlargedMetricsGraph } from "@/components/Metrics/graphs/EnlargedMetricsGraph";
import { MetricsTimeSelector } from "@/components/ResourceView/tabs/MetricsTab/MetricsTimeSelector";
import { ConditionalNoLimitLabel } from "@/components/Metrics/NoLimitLabel";
import { ResourceGraph } from "@/components/Metrics/graphs/ResourceGraph";
import { timelineTicksNum } from "@/components/ResourceView/tabs/MetricsTab/constants";
import { MetricsTabContextProvider } from "@/components/ResourceView/tabs/MetricsTab/context/MetricsTabContext";
import { useMetricsTabContext } from "@/components/ResourceView/tabs/MetricsTab/context/useMetricsTabContext";

const EnlargedMetricsTabGraph = ({
  resource,
  title,
  metricsParams: { toEpochMs },
  endpoint,
  uid,
  onClose,
  isMultipleContainers,
}: MetricsTabGraphCommonProps & {
  resource: Pod;
  onClose: () => void;
}) => {
  const metricsParams = useMetricParams(resource, toEpochMs);

  const { fromEpoch, toEpoch, graphProps } = usePodMetricsWithZoom();

  const { data, state, type } = useMetricsTabGraphData(
    { ...metricsParams, fromEpoch, toEpoch },
    endpoint
  );

  return (
    <EnlargedMetricsGraph
      uid={uid}
      onClose={onClose}
      title={title}
      hasGraphData={!state.loading && data.length > 0}
      error={state.error}
      loading={state.loading}
      controls={[<MetricsTimeSelector key="MetricsTimeSelector" />]}
      info={
        <ConditionalNoLimitLabel
          data={state.metrics}
          isMultipleContainers={isMultipleContainers}
        />
      }
    >
      <ResourceGraph
        type={type}
        data={data}
        timelineTicksNum={timelineTicksNum}
        {...graphProps}
      />
    </EnlargedMetricsGraph>
  );
};

export const EnlargeableMetricsTabGraph = ({
  resource,
  title,
  metricsParams,
  endpoint,
  uid,
  syncId,
}: MetricsTabGraphCommonProps & { resource: Pod }) => {
  const [isMetricsGraphEnlarged, setIsMetricsGraphEnlarged] = useState(false);
  const { timeWindow } = useMetricsTabContext();

  const isMultipleContainers = useMemo(
    () => resource.containerStatuses && resource.containerStatuses?.length > 1,
    [resource]
  );

  return (
    <>
      {isMetricsGraphEnlarged && (
        <MetricsTabContextProvider givenTimeWindow={timeWindow}>
          <EnlargedMetricsTabGraph
            resource={resource}
            uid={uid}
            onClose={() => setIsMetricsGraphEnlarged(false)}
            title={title}
            metricsParams={metricsParams}
            endpoint={endpoint}
          />
        </MetricsTabContextProvider>
      )}
      <MetricsTabGraph
        title={title}
        metricsParams={metricsParams}
        endpoint={endpoint}
        uid={uid}
        onEnlargeGraph={() => setIsMetricsGraphEnlarged(true)}
        isMultipleContainers={isMultipleContainers}
        syncId={syncId}
      />
    </>
  );
};
