import { useMemo } from "react";
import { startCase } from "lodash";

import { useAuditLogsContext } from "@/pages/organization-settings/account/AuditPage/context/useAuditLogsContext";
import { useGetAuditFilters } from "@/shared/hooks/accounts-service/client/audit/useGetAudit";
import { useAccountUsersContext } from "@/shared/context/users/useAccountUsersContext";
import { User } from "@/generated/auth";

const mapFiltersData = (values: string[]) =>
  values.map((value) => ({ value, label: startCase(value) }));

const mapUsersData = (users: { [key: string]: User }, userIds: string[]) =>
  userIds.map((userId) => {
    const user = users[userId];
    if (!user) {
      return { value: userId, label: userId };
    }
    return { value: userId, label: user.email ?? "" };
  });

export const useGetAllFilterOptions = () => {
  const { apiStartTime } = useAuditLogsContext();
  const { data: filtersData } = useGetAuditFilters({
    enabled: true,
    params: { startTime: apiStartTime },
  });

  const { users } = useAccountUsersContext();

  return useMemo(
    () => ({
      operations: mapFiltersData(filtersData?.operations || []),
      actions: mapFiltersData(filtersData?.actions || []),
      entityTypes: mapFiltersData(filtersData?.entityTypes || []),
      userIds: mapUsersData(users, filtersData?.userIds || []),
    }),
    [filtersData, users]
  );
};
