import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

import { useFormValidations } from "../../../../../shared/context/ValidationsProvider";
import { ErrorMessage, FormTextInput, Label } from "../styles";

import { ConfigurationVariables } from "@/generated/monitorsApi";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const validateDuration = (duration: string): string | undefined => {
  const durationNumber = Number(duration);
  if (isNaN(durationNumber) || durationNumber < 5 || durationNumber > 600) {
    return "Please enter a duration value between 5 and 600";
  }
  return;
};

const TriggerConditionsSection: React.FC<{
  id: string;
  variables: ConfigurationVariables | undefined;
  handleChange: (variables: ConfigurationVariables) => void;
  triggerText: ReactElement;
}> = ({ id, variables, handleChange, triggerText }) => {
  const [stringDuration, setStringDuration] = useState("");

  useEffect(() => {
    if (variables?.duration) {
      setStringDuration(variables.duration.toString() ?? "");
    }
  }, [variables?.duration]);

  const { errors, setError } = useFormValidations();
  const durationError = errors[id];

  const handleDurationChange = (value: string) => {
    setStringDuration(value);
    const error = validateDuration(value);
    if (!error) {
      handleChange({ ...variables, duration: Number(value) });
    }
    setError(id, error);
  };

  return (
    <>
      <Container>
        <Label>{triggerText}</Label>
        <FormTextInput
          autoFocus
          isValid={!durationError}
          value={stringDuration}
          placeholder={"e.g. 30"}
          onChange={(e) => handleDurationChange(e.target.value)}
        />
        <Label>Seconds</Label>
      </Container>
      <ErrorMessage>{durationError}</ErrorMessage>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default TriggerConditionsSection;
