import React, { useMemo } from "react";
import styled from "styled-components";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  Bar as BarComponent,
  CartesianGrid,
  BarChart as RechartBarChart,
  Tooltip as RechartTooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";

import { CustomGraphDataPoint, EventData, TooltipItemProps } from "../types";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import {
  GraphBackgroundColor,
  GraphChartMargin,
  GraphGridColor,
} from "../styles";
import { SmartPopper } from "../../common/EventsChart/SmartPopper";
import { Tooltip } from "../Tooltip/Tooltip";
import { defaultFormatTooltipTick } from "../utils";
import { useGraphEvents } from "../hooks/useGraphEvents";
import { minimalEventIconsHeight } from "../constants";

import { INVALID_DATAPOINT_VALUE, useGraphProps } from "./hooks";
import { timeXAxisProps, yAxisProps } from "./graphProps";

const { Graph, Yaxis, Xaxis } = AriaLabels.MetricsAvailability.BarChart;

const Container = styled.div`
  height: 100%;
`;

export type MetricBar = Partial<BarComponent>;

interface Props {
  data: CustomGraphDataPoint[];
  bars: MetricBar[];
  tooltipItems: TooltipItemProps[];
  yAxisLabel?: string;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  events?: EventData[] | undefined;
  className?: string;
}

export const BarChart: React.FC<Props> = ({
  data,
  bars,
  yAxisLabel,
  tooltipItems,
  yAxisProps: customYAxisProps,
  xAxisProps: customXAxisProps,
  events,
  className,
}) => {
  const dataWithoutNegatives = useMemo<CustomGraphDataPoint[]>(
    () =>
      data.map((dataPoint) => {
        const values = Object.entries(dataPoint).map(([key, value]) => [
          key,
          value === INVALID_DATAPOINT_VALUE ? null : value,
        ]);
        return Object.fromEntries(values);
      }),
    [data]
  );

  const eventsOnGraph = useGraphEvents(events);
  const graphMargins = {
    ...GraphChartMargin,
    top: events ? minimalEventIconsHeight : 0,
  };

  const { containerProps, chartProps, xAxisProps, tooltipProps } =
    useGraphProps(data);

  return (
    <Container className={className} aria-label={Graph} {...containerProps}>
      <AutoSizer>
        {({ width, height }) => (
          <RechartBarChart
            maxBarSize={40}
            width={width}
            height={height}
            data={dataWithoutNegatives}
            margin={graphMargins}
            {...chartProps}
          >
            <CartesianGrid
              stroke={GraphGridColor}
              strokeWidth="1"
              fill={GraphBackgroundColor}
            />
            <YAxis
              aria-label={Yaxis}
              {...yAxisProps(yAxisLabel)}
              {...customYAxisProps}
            />
            <XAxis
              aria-label={Xaxis}
              {...timeXAxisProps}
              {...customXAxisProps}
              {...xAxisProps}
            />
            <RechartTooltip
              isAnimationActive={false}
              content={(props) => (
                <SmartPopper
                  {...props}
                  tooltipContent={(tooltipProps) => (
                    <Tooltip
                      tooltipProps={tooltipProps}
                      dataTransformFunction={defaultFormatTooltipTick}
                      tooltipItems={tooltipItems}
                    />
                  )}
                  {...tooltipProps}
                />
              )}
            />
            {eventsOnGraph}
            {bars}
          </RechartBarChart>
        )}
      </AutoSizer>
    </Container>
  );
};
