import { FunctionComponent, SVGProps } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { BaseWorkflowCheck } from "../useWorkflowsRuns";

export enum WorkflowConfigType {
  PVC = "PVC",
  NodeIssue = "node",
  Availability = "availability",
  Job = "job",
  CronJob = "cronJob",
  Deploy = "deploy",
  Workflow = "workflow",
}

export enum WorkflowTypeOutsideWorkflowTab {
  StaticPrevention = "static_prevention",
}

export type Tab = {
  label: string;
  route: string;
  accessLevel: string[];
};

export type WorkflowTemplate = {
  title: string;
  shortDescription: string;
  longDescription: string;
  configurationType: WorkflowConfigType;
  previewDescription?: string;
  endpoint?: string;
  displayName?: string;
  checksDescription: Array<string>;
  requiredAgentVersion: string;
  triggerConditions: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  sinks: AvailableSinks[];
  docsLink: string;
};

export type Sensor = {
  cluster: string;
  namespaces?: string[];
};

export type SinksOptions = {
  notifyOn?: string[];
  shouldSend?: boolean;
};

export enum AvailableSinks {
  Slack = "Slack",
  Teams = "Teams",
  Opsgenie = "Opsgenie",
  PagerDuty = "PagerDuty",
  GenericWebhook = "Webhook",
}

export type K8sService = {
  apiVersion: string;
  Kind: string;
  Metadata: Record<string, unknown>;
  Spec: Record<string, unknown>;
};

export type OpsgenieResponder = {
  name: string;
  id: string;
};

export type CheckDetails = {
  workflowType: "" | WorkflowConfigType;
  results: BaseWorkflowCheck[];
  eventTime?: Date;
  closedAt?: Date | null;
  services?: string[];
  clusterName?: string;
  namespace?: string;
};

export const SelectorTypes = [
  "namespaces",
  "annotations",
  "labels",
  "services",
];
