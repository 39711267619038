import { useMemo, useEffect } from "react";

import { useDataForFlag } from "../../../../shared/hooks/useDataForFlag";
import { TimeWindow } from "../../../../shared/types/TimeWindow";
import { Uid } from "../../../../shared/utils/generateUid";
import { buildKomodorUid } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { PodNativeEventsResponse } from "../../../../generated/resourcesApi";
import { useGetPodNativeEventsPaginated } from "../../../../shared/hooks/resources-api/client/nativeEvents/useGetPodNativeEvents";
import { useGetNativePodEvents } from "../../../../shared/hooks/resources-api/client/events/useGetNativePodEvents";

import { NativePodFragment } from "./types";

export const useBuildKomodorUidsFromPodUids = (podUids: Uid[] | null) => {
  return useMemo(() => {
    if (!podUids) return [];
    return podUids.map((podUid) => {
      return buildKomodorUid({
        kind: "Pod",
        clusterName: podUid.cluster,
        namespace: podUid.namespace,
        resourceName: podUid.name,
      });
    });
  }, [podUids]);
};

export const useNativePodEvents = (
  podsKomodorUids: string[],
  showPodEvents: unknown,
  enabled: boolean,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): { data: NativePodFragment[] | null; isFetching: boolean } => {
  const { data: nativePodEventsFromAPI, isFetching } = useGetNativePodEvents(
    {
      komodorUids: podsKomodorUids,
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
    },
    enabled && !!timeWindow && podsKomodorUids.length > 0
  );

  const result = filterEventsFromAPI(nativePodEventsFromAPI, podsKomodorUids);

  return { data: useDataForFlag(showPodEvents, result), isFetching };
};

const filterEventsFromAPI = (
  events: PodNativeEventsResponse | undefined,
  selectedPodKomodorUidsForEvents: string[] | null
) => {
  return (events?.data ?? [])
    .filter((event) =>
      selectedPodKomodorUidsForEvents?.includes(
        buildKomodorUid({
          resourceName: event.podName ?? "",
          clusterName: event.clusterName ?? "",
          namespace: event.namespace ?? "",
          kind: "Pod",
        })
      )
    )
    .map((event) => event as NativePodFragment);
};

export const useNativePodEventsByKomodorUidsFromAPI = (
  podUids: string[] | undefined,
  fromEpoch?: Date,
  toEpoch?: Date
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [NativePodFragment[] | undefined, boolean, boolean] => {
  const {
    data,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPodNativeEventsPaginated(
    {
      komodorUids: podUids,
      fromEpoch: fromEpoch?.getTime().toString(),
      toEpoch: toEpoch?.getTime().toString(),
      limit: 100,
      order: "ASC",
    },
    !!podUids?.length
  );

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return useMemo(() => {
    const events = data?.pages.flatMap((page) =>
      Object.values(page.data).flatMap((pageEvents) => pageEvents)
    ) as NativePodFragment[] | undefined;
    return [events, isInitialLoading, hasNextPage ?? false];
  }, [data?.pages, isInitialLoading, hasNextPage]);
};
