import { NodeCount } from "@/generated/accounts";
import { allMonths } from "@/components/Settings/General/NodesCount/consts";
import {
  BAR_CHART_MONTH_LABEL_NAME,
  OTHERS,
} from "@/pages/organization-settings/account/UsagePage/StackedBarChart/constants";

export interface BarChartData {
  [key: string]: number | string;
}

const aggregateNodeCounts = ({
  data,
  topClusters,
  otherClusters,
}: {
  data: NodeCount[];
  topClusters: string[];
  otherClusters: string[];
}) => {
  const aggregatedData: { [p: string]: { [key: string]: number } } = {};

  data.reduce((acc, item) => {
    const date = new Date(String(item.createdAt));
    const month = allMonths[date.getUTCMonth()];

    if (!acc[month]) {
      acc[month] = {};
    }

    // handle edge cases
    if (!item.clusterName || !item.nodeCount) {
      return acc;
    }

    if (
      !topClusters.includes(item.clusterName) &&
      !otherClusters.includes(item.clusterName)
    ) {
      return acc;
    }

    const clusterName = topClusters.includes(item.clusterName)
      ? item.clusterName
      : OTHERS;
    acc[month][clusterName] = (acc[month][clusterName] || 0) + item.nodeCount;

    return acc;
  }, aggregatedData);

  return aggregatedData;
};

const formatToBarChartData = ({
  data,
  topClusters,
  otherClusters,
}: {
  data: NodeCount[];
  topClusters: string[];
  otherClusters: string[];
}) => {
  const aggregatedData = aggregateNodeCounts({
    data,
    topClusters,
    otherClusters,
  });

  return Object.entries(aggregatedData).reduce((acc, [key, value]) => {
    acc.push({
      ...value,
      [BAR_CHART_MONTH_LABEL_NAME]: key,
    });
    return acc;
  }, [] as BarChartData[]);
};

export const getBarChartData = (
  data: NodeCount[] | undefined,
  sortedMonths: string[],
  topClusters: string[],
  otherClusters: string[]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  if (!data?.length) {
    return { data: undefined };
  }

  const barCharData = formatToBarChartData({
    data,
    topClusters,
    otherClusters,
  });

  barCharData.sort(
    (a, b) =>
      sortedMonths.indexOf(a[BAR_CHART_MONTH_LABEL_NAME] as string) -
      sortedMonths.indexOf(b[BAR_CHART_MONTH_LABEL_NAME] as string)
  );

  return {
    data: barCharData,
  };
};
