/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import styled from "styled-components";
import { IconButton } from "@komodorio/design-system/deprecated";
import { Trash16 } from "@komodorio/design-system/icons";
import { TooltipWrapper } from "react-tooltip";
import { Record, String, Array, Partial } from "runtypes";

import { isSandbox } from "../../../../shared/utils/sandbox";
import { greenBrand, disabledGray } from "../../../../Colors";
import slackIcon from "../../../integrations/management/logos/slack.svg";
import teamsIcon from "../../../integrations/management/logos/teams.svg";
import opsgenieIcon from "../../../integrations/management/logos/opsgenie.svg";
import pagerdutyIcon from "../../../integrations/management/logos/pagerduty.svg";
import webhookIcon from "../../../integrations/management/logos/webhook.svg";
import { Row } from "../../../common/table/Table";
import Switcher from "../../../common/controls/Switcher";
import { flexAlignedCenter } from "../../common/styles";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { MONITORS_RULE_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";
import { DEFAULT_TOOLTIP } from "../../../../shared/constants/tooltipIds";
import {
  ALL_CATEGORIES,
  AvailabilityCategories,
} from "../../monitorsConfiguration/AvailabilityRuleSections";

import ScopeColumn from "./ScopeColumn";
import SwitcherConfirmationModal from "./SwitcherConfirmationModal";

import { MonitorConfiguration, MonitorType } from "@/generated/monitorsApi";

const Sinks = styled.div`
  ${flexAlignedCenter};
  gap: 0.5rem;
`;

const Sink = styled.div`
  ${flexAlignedCenter};
  gap: 0.25rem;
`;

const SinkIcon = styled.div<{ icon: string }>`
  width: 1rem;
  height: 1rem;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
`;

const Actions = styled.div`
  display: flex;
  column-gap: 0.5rem;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useGetTriggerText = (
  rule: MonitorConfiguration
): JSX.Element | "-" => {
  const categories =
    rule?.variables?.categories?.toString() === ALL_CATEGORIES.toString()
      ? AvailabilityCategories.map((c) => c.label)
      : rule?.variables?.categories;

  switch (rule.type) {
    case MonitorType.Pvc:
      return (
        <>
          PVC <b>is in pending state</b>{" "}
          {rule.variables?.duration && (
            <>
              for more than <b>{rule.variables.duration} seconds</b>
            </>
          )}
        </>
      );
    case MonitorType.Node:
      return (
        <>
          Node <b>conditions are not ok</b>{" "}
          {rule.variables?.duration && (
            <>
              for more than <b>{rule.variables.duration} seconds</b>
            </>
          )}
          <br />
          {rule.variables?.nodeCreationThreshold && (
            <>
              Node startup <b>grace period</b> is{" "}
              <b>{rule.variables.nodeCreationThreshold}</b>
            </>
          )}
        </>
      );
    case MonitorType.Availability:
      return (
        <>
          Available replicas <b>are less than {rule.variables?.minAvailable}</b>{" "}
          {rule.variables?.duration && (
            <>
              for more than <b>{rule.variables.duration} seconds</b>
            </>
          )}
          {categories && categories.length > 0 && (
            <>
              {" "}
              {categories.length === AvailabilityCategories.length ? (
                <>
                  for <b>all</b> categories
                </>
              ) : (
                <>
                  for the following categories:{" "}
                  <b>{categories.slice(0, 2).join(", ")}</b>
                  {categories.length > 2 && (
                    <>
                      {" "}
                      and <b>{categories.length - 2}</b> more
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      );
    case MonitorType.Job:
      return (
        <>
          Job <b>failed</b> execution
        </>
      );
    case MonitorType.CronJob:
      return (
        <>
          Job <b>failed</b> execution
        </>
      );
    case MonitorType.Deploy:
      return (
        <>
          A Deploy occurs, workload <b>generation</b> has changed
        </>
      );
    case MonitorType.Workflow:
      return (
        <>
          Workflow pod <b>is experiencing a failure</b>
        </>
      );
    default:
      return "-";
  }
};

const SinksType = Partial({
  slack: Array(String),
  teams: Array(String),
  opsgenie: Array(String),
  pagerduty: Array(
    Record({
      channel: String,
      integrationKey: String,
    })
  ),
  genericWebhook: Array(String),
});

const RuleRow: React.FC<{
  rule: MonitorConfiguration;
  updateActiveInDB: (rule: MonitorConfiguration, active: boolean) => void;
  handleEditRule: (ruleId: string) => void;
  handleDeleteRule: (ruleId: string) => void;
}> = ({ rule, updateActiveInDB, handleEditRule, handleDeleteRule }) => {
  const [active, setActive] = useState(rule.active);
  useEffect(() => {
    setActive(rule.active);
  }, [rule.active]);
  const selectedRuleId = new URLSearchParams(location.search).get(
    MONITORS_RULE_PARAM_KEY
  );
  const [showSwitcherConfirmation, setShowSwitcherConfirmation] =
    useState(false);

  const handleActiveSwitcher = () => {
    if (active) {
      setShowSwitcherConfirmation(true);
    } else {
      updateActiveInDB(rule, !active);
      setActive(!active);
      dispatchEvent(
        active
          ? AnalyticEvents.MonitorsView.Rule_Enabled
          : AnalyticEvents.MonitorsView.Rule_Disabled,
        {
          type: rule.type,
        }
      );
    }
  };

  const noop = () => null;
  const handleSwitcherChange = isSandbox() ? noop : handleActiveSwitcher;

  const switcherColor = isSandbox() ? disabledGray : greenBrand;

  const sinks = SinksType.guard(rule.sinks) ? rule.sinks : {};
  const { slack, teams, opsgenie, pagerduty, genericWebhook: webhook } = sinks;

  const pdServices = pagerduty?.length ? pagerduty.map((p) => p.channel) : [];
  const hasSinkConfigured =
    slack?.length ||
    teams?.length ||
    opsgenie?.length ||
    pdServices?.length ||
    webhook?.length;

  const triggerText = useGetTriggerText(rule);
  return (
    <Row
      data-rule-type={rule.type}
      selected={selectedRuleId === rule.id}
      onClick={() => handleEditRule(rule.id)}
    >
      <td>{triggerText} </td>
      <td>{rule.sensors && <ScopeColumn sensors={rule.sensors} />}</td>
      <td>
        {hasSinkConfigured ? (
          <Sinks>
            {slack?.length ? (
              <Sink>
                <SinkIcon icon={slackIcon} />
                {`#${slack.join(", #")}`}
              </Sink>
            ) : null}
            {teams?.length ? (
              <Sink>
                <SinkIcon icon={teamsIcon} />
                {`@${teams.join(", @")}`}
              </Sink>
            ) : null}
            {opsgenie?.length ? (
              <Sink>
                <SinkIcon icon={opsgenieIcon} />
                {`${opsgenie.join(", ")}`}
              </Sink>
            ) : null}
            {webhook?.length ? (
              <Sink>
                <SinkIcon icon={webhookIcon} />
              </Sink>
            ) : null}
            {pdServices?.length ? (
              <Sink>
                <SinkIcon icon={pagerdutyIcon} />
                {`${pdServices.join(", ")}`}
              </Sink>
            ) : null}
          </Sinks>
        ) : (
          "-"
        )}
      </td>
      <td>
        {rule.updatedAt
          ? formatDistanceToNow(new Date(rule.updatedAt), { addSuffix: true })
          : "-"}
      </td>
      <td onClick={(e) => e.stopPropagation()}>
        <TooltipWrapper
          tooltipId={DEFAULT_TOOLTIP}
          content={
            isSandbox()
              ? "This option is disabled in sandbox version"
              : undefined
          }
        >
          <Actions>
            <Switcher
              checked={active ?? false}
              handleSwitcherChange={handleSwitcherChange}
              color={switcherColor}
            />
            <IconButton
              icon={Trash16}
              noBorder
              disabled={isSandbox()}
              onClick={() => handleDeleteRule(rule.id)}
            />
          </Actions>
        </TooltipWrapper>
        {showSwitcherConfirmation && (
          <SwitcherConfirmationModal
            isModalOpen={showSwitcherConfirmation}
            handleClose={() => setShowSwitcherConfirmation(false)}
            updateActiveInDB={updateActiveInDB}
            setActive={setActive}
            rule={rule}
            active={active ?? false}
          />
        )}
      </td>
    </Row>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RuleRow;
