/* eslint-disable max-lines */
import React from "react";
import { Line } from "recharts";

import EventGroup from "../../EventGroup";
import { POD_GROUP_TYPE } from "../../EventGroup/groupedPodEvent/PodPhaseGroup";

import Dot from "./Dot";
import { CustomLabel } from "./Label";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NUM_OF_TICKS } from "./TimelineChart";

import { TimelineChartConfig } from "@/components/common/EventsChart/TimelineChart/useTimelineChartConfig";

const TEN_MINUTES_IN_MILLISECONDS = 600000;

export const calculatePodGroupAttributes = ({
  eventGroup,
  startTimeWindow,
  endTimeWindow,
  width,
  ticksGapSize,
  chartConfig,
}: {
  eventGroup: EventGroup;
  startTimeWindow: Date;
  endTimeWindow: Date;
  width: number;
  ticksGapSize: number;
  chartConfig: TimelineChartConfig;
}): {
  startX: number;
  endX: number;
} => {
  const isTimeScaleLargeEnough =
    endTimeWindow.getTime() - startTimeWindow.getTime() >
    TEN_MINUTES_IN_MILLISECONDS;

  const onePixelInTime = Math.floor(
    width ? (endTimeWindow.getTime() - startTimeWindow.getTime()) / width : 0
  );

  const ticksGapSizePx = ticksGapSize / onePixelInTime;

  const pixelAdjustFactor =
    onePixelInTime < ticksGapSizePx
      ? Math.min(2.5, ticksGapSizePx / onePixelInTime)
      : 1;

  const longEventStartAdjustFactor =
    eventGroup.type === POD_GROUP_TYPE
      ? onePixelInTime * chartConfig.eventDotWidth
      : 0;
  const longEventEndAdjustFactor =
    eventGroup.type === POD_GROUP_TYPE
      ? onePixelInTime * chartConfig.eventDotWidth
      : 0;

  const isLongEvent =
    Math.min(
      eventGroup.isCompleted
        ? eventGroup.endTime.getTime()
        : endTimeWindow.getTime(),
      endTimeWindow.getTime()
    ) -
      Math.max(eventGroup.startTime.getTime(), startTimeWindow.getTime()) >
    onePixelInTime * pixelAdjustFactor * chartConfig.eventDotWidth;

  const startX =
    eventGroup.startTime < startTimeWindow
      ? startTimeWindow.getTime() - ticksGapSize
      : eventGroup.startTime.getTime() +
        (isLongEvent ? longEventStartAdjustFactor : 0);

  const endX =
    (eventGroup.isCompleted ?? true) && eventGroup.endTime < endTimeWindow
      ? eventGroup.endTime.getTime() -
        (isLongEvent && isTimeScaleLargeEnough ? longEventEndAdjustFactor : 0)
      : endTimeWindow.getTime() + ticksGapSize;

  return {
    startX,
    endX,
  };
};

export const calculateCommonEventsAttributes = ({
  eventGroup,
  startTimeWindow,
  endTimeWindow,
  ticksGapSize,
}: {
  eventGroup: EventGroup;
  startTimeWindow: Date;
  endTimeWindow: Date;
  ticksGapSize: number;
}): {
  startX: number;
  endX: number;
} => {
  return {
    startX:
      eventGroup.startTime < startTimeWindow
        ? startTimeWindow.getTime() - ticksGapSize
        : eventGroup.startTime.getTime(),
    endX:
      (eventGroup.isCompleted ?? true) && eventGroup.endTime < endTimeWindow
        ? eventGroup.endTime.getTime()
        : endTimeWindow.getTime() + ticksGapSize,
  };
};

export const calculateEventsLineAttributes = ({
  eventGroup,
  startTimeWindow,
  endTimeWindow,
  width,
  chartConfig,
}: {
  eventGroup: EventGroup;
  startTimeWindow: Date;
  endTimeWindow: Date;
  width: number;
  chartConfig: TimelineChartConfig;
}) => {
  const ticksGapSize =
    (endTimeWindow.getTime() - startTimeWindow.getTime()) / (NUM_OF_TICKS - 1);

  const showMidEventIcon =
    eventGroup.startTime < startTimeWindow &&
    (eventGroup.endTime > endTimeWindow || !eventGroup.isCompleted);

  const showEndingIcon =
    !showMidEventIcon &&
    (eventGroup.isCompleted ?? true) &&
    eventGroup.startTime < startTimeWindow;

  const { startX, endX } =
    eventGroup.type === POD_GROUP_TYPE
      ? calculatePodGroupAttributes({
          eventGroup,
          startTimeWindow,
          endTimeWindow,
          width,
          ticksGapSize,
          chartConfig,
        })
      : calculateCommonEventsAttributes({
          eventGroup,
          startTimeWindow,
          endTimeWindow,
          ticksGapSize,
        });

  // Show label only if the event is long enough (1.5 ticks looks like a reasonable threshold)
  const showLabel = endX - startX > 1.5 * ticksGapSize;

  return {
    showEndingIcon,
    showMidEventIcon,
    startX,
    endX,
    showLabel,
  };
};

const createEventsLine = (
  eventGroup: EventGroup,
  y: number,
  startTimeWindow: Date,
  endTimeWindow: Date,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
  onClick: (() => void) | undefined,
  highlightedId: string | undefined,
  maxWidth: number,
  chartConfig: TimelineChartConfig
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): React.ReactElement<Line> => {
  const highlighted = highlightedId === eventGroup.id;

  const { showEndingIcon, showMidEventIcon, startX, endX, showLabel } =
    calculateEventsLineAttributes({
      eventGroup,
      startTimeWindow,
      endTimeWindow,
      width: maxWidth,
      chartConfig,
    });

  const onMouseEnterInternal = () => {
    document
      ?.getElementById(eventGroup.id)
      ?.setAttribute(
        "filter",
        "drop-shadow(0px 2px 8px rgba(22, 24, 31, 0.15))"
      );
    document
      ?.getElementById(eventGroup.id)
      ?.setAttribute("stroke", eventGroup.fillColor);
    document
      ?.getElementById(eventGroup.id)
      ?.setAttribute("class", "highlighted-line");
    document
      ?.getElementById(`${eventGroup.id}-dot-0`)
      ?.setAttribute("class", "highlighted-dot-icon");
    document
      ?.getElementById(`${eventGroup.id}-dot-1`)
      ?.setAttribute("class", "highlighted-dot-icon");
    onMouseEnter();
  };

  const onMouseLeaveInternal = () => {
    document?.getElementById(eventGroup.id)?.removeAttribute("filter");
    document
      ?.getElementById(eventGroup.id)
      ?.setAttribute("stroke", eventGroup.backgroundColor);
    document?.getElementById(eventGroup.id)?.setAttribute("class", "");
    document
      ?.getElementById(`${eventGroup.id}-dot-0`)
      ?.setAttribute("class", "dot-icon");
    document
      ?.getElementById(`${eventGroup.id}-dot-1`)
      ?.setAttribute("class", "dot-icon");
    onMouseLeave();
  };

  return (
    <Line
      aria-label={eventGroup.id}
      activeDot={false}
      id={eventGroup.id}
      cursor={"pointer"}
      key={eventGroup.id}
      data={[
        {
          x: startX,
          y,
          eventGroup: eventGroup,
        },
        {
          x: endX,
          y,
          eventGroup: eventGroup,
        },
      ]}
      dataKey="y"
      strokeWidth={chartConfig.eventStrokeWidth}
      style={
        highlighted
          ? {
              strokeLinecap: "round",
              stroke: eventGroup.fillColor,
              filter: "drop-shadow(0px 2px 8px rgba(22, 24, 31, 0.15))",
            }
          : {
              strokeLinecap: "round",
            }
      }
      stroke={eventGroup.backgroundColor}
      isAnimationActive={false}
      dot={({ key, cx, cy, index }) => (
        <Dot
          aria-label={`${eventGroup.id}-dot`}
          id={`${eventGroup.id}-dot-${index}`}
          key={key}
          cx={cx}
          cy={cy}
          index={index}
          size={chartConfig.eventDotWidth}
          iconSize={chartConfig.eventDotIconSize}
          eventGroup={eventGroup}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          highlighted={highlighted}
          showEndingIcon={showEndingIcon}
          showMidEventIcon={showMidEventIcon}
        />
      )}
      label={(props) => (
        <CustomLabel
          eventGroup={eventGroup}
          showLabel={showLabel}
          isEndingLabel={showEndingIcon}
          isMidEventLabel={showMidEventIcon}
          onClick={onClick}
          onMouseEnter={onMouseEnterInternal}
          onMouseLeave={onMouseLeaveInternal}
          chartConfig={chartConfig}
          {...props}
        />
      )}
      onMouseEnter={onMouseEnterInternal}
      onMouseLeave={onMouseLeaveInternal}
      onClick={onClick}
      display={"Line"}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default createEventsLine;
