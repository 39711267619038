import { muiColors } from "@komodorio/design-system";

import {
  AllocationTableColumns,
  RightSizingTableColumns,
} from "../types/tableTypes";
import {
  CostStrategy,
  AllocationFiltersGroupBy,
  AllocationFiltersTimeWindow,
  CostOptimizationTab,
  CostAllocationStatsResponseField,
  OverviewCardDataKey,
  OverviewCardDetails,
} from "../types/costOptimizationTypes";
import { formatCurrency } from "../../../shared/utils/formatCurrency";
import { formatPercentage } from "../../../shared/utils/formatPercentage";

import {
  DatadogContext,
  DatadogViewNamesEnum,
} from "@/shared/types/datadogReporting";

export const STATIC_TIME_FRAME: AllocationFiltersTimeWindow = "30d";

export const SAVINGS_COLOR = muiColors.teal[700];

export const DETAILS_COLOR = muiColors.gray[500];

export const DEFAULT_TAB: CostOptimizationTab = "allocation";

export const COST_UTILIZATION_TOOLTIP_TEXT =
  "Your cost optimization score is a performance grade ranging from 0 to 100%. The score is calculated based on how well optimized your resources are for cost efficiency compared to how much more optimizations we recommend.";

export const TABLE_IDLE_ENTRY = "Unallocated resources";

export const STRATEGY_OPTIONS: CostStrategy[] = [
  "conservative",
  "moderate",
  "aggressive",
];

export const STRATEGY_VALUE_TO_LABEL: Record<CostStrategy, string> = {
  aggressive: "Aggressive",
  conservative: "Conservative",
  moderate: "Moderate",
};

export const STRATEGY_VALUE_TO_SUBLABEL: Record<CostStrategy, string> = {
  aggressive: "Prioritize cost savings over availability",
  conservative: "Prioritize availability over cost savings",
  moderate: "Balance between cost and availability",
};

export const GROUP_BY_FILTER_NAME_TO_LABEL: Record<
  AllocationFiltersGroupBy,
  string
> = {
  clusterName: "Cluster",
  namespace: "Namespace",
  komodorServiceName: "Service",
  label: "Label",
};

export const ALLOCATION_TABLE_COLUMNS: Record<
  AllocationTableColumns,
  { field: AllocationTableColumns; name: string }
> = {
  namespace: { field: "namespace", name: "Namespace" },
  komodorServiceName: { field: "komodorServiceName", name: "Service" },
  komodorServiceKind: { field: "komodorServiceKind", name: "Kind" },
  clusterName: { field: "clusterName", name: "Cluster" },
  shareOfCost: { field: "shareOfCost", name: "Share of Allocated Cost" },
  cpuRequestCoreHours: {
    field: "cpuRequestCoreHours",
    name: "CPU hours (Cores)",
  },
  memoryRequestGBHours: {
    field: "memoryRequestGBHours",
    name: "Memory hours (GBs)",
  },
  totalCost: { field: "totalCost", name: "Total Cost" },
  optimizationScore: {
    field: "optimizationScore",
    name: "Optimization Score",
  },
  potentialSaving: { field: "potentialSaving", name: "Savings" },
  idleResourcesCost: {
    field: "idleResourcesCost",
    name: "Unallocated Resources",
  },
};

export const ALLOCATION_TABLE_COLUMN_NAME_TO_DATA_KEY: Record<
  string,
  AllocationTableColumns
> = {
  Namespace: "namespace",
  Service: "komodorServiceName",
  Cluster: "clusterName",
  "Share of Allocated Cost": "shareOfCost",
  "CPU hours (Cores)": "cpuRequestCoreHours",
  "Memory hours (GBs)": "memoryRequestGBHours",
  "Total Cost": "totalCost",
  "Optimization Score": "optimizationScore",
  Savings: "potentialSaving",
  "Unallocated Resources": "idleResourcesCost",
};

export const RIGHT_SIZING_TABLE_COLUMN_NAME_TO_DATA_KEY: Record<
  string,
  RightSizingTableColumns
> = {
  Namespace: "namespace",
  Service: "service",
  Cluster: "clusterName",
  Kind: "komodorServiceKind",
  "Share of Allocated Cost": "shareOfCost",
  "CPU Req/Rec (Cores)": "cpuRequestCores",
  "Memory Req/Rec (MiB)": "memoryRequestsMiB",
  CPU: "cpuRecommendation",
  Memory: "memoryRecommendation",
  "Optimization Score": "optimizationScore",
  Savings: "potentialSaving",
};

export const RIGHT_SIZING_TABLE_COLUMNS: Record<
  RightSizingTableColumns,
  { field: RightSizingTableColumns; name: string; fullName?: string }
> = {
  clusterName: { field: "clusterName", name: "Cluster" },
  namespace: { field: "namespace", name: "Namespace" },
  service: { field: "service", name: "Service" },
  komodorServiceKind: { field: "komodorServiceKind", name: "Kind" },
  shareOfCost: { field: "shareOfCost", name: "Share of Allocated Cost" },
  cpuRequestCores: {
    field: "cpuRequestCores",
    name: "CPU Req/Rec (Cores)",
    fullName: "CPU Requested/Recommended (Cores)",
  },
  cpuRecommendation: { field: "cpuRecommendation", name: "CPU" },
  memoryRequestsMiB: {
    field: "memoryRequestsMiB",
    name: "Memory Req/Rec (MiB)",
    fullName: "Memory Requested/Recommended (MiB)",
  },
  memoryRecommendation: { field: "memoryRecommendation", name: "Memory" },
  optimizationScore: {
    field: "optimizationScore",
    name: "Optimization Score",
  },
  potentialSaving: { field: "potentialSaving", name: "Savings" },
};

export const DEFAULT_PAGINATION_PAGE_SIZE = 10;

export const overviewCardsOrder: OverviewCardDataKey[] = [
  CostAllocationStatsResponseField.totalCost,
  CostAllocationStatsResponseField.totalOptimizationScore,
  CostAllocationStatsResponseField.totalPotentialSaving,
];

export const cardConfigToDataKey: Record<
  OverviewCardDataKey,
  {
    label: string;
    formatter: (value: number, options?: Intl.NumberFormatOptions) => string;
    color?: string;
    details?: OverviewCardDetails;
  }
> = {
  totalCost: {
    label: "Total Cost",
    formatter: formatCurrency,
    details: CostAllocationStatsResponseField.totalUnallocatedCost,
  },
  totalOptimizationScore: {
    label: "Optimization Score",
    formatter: formatPercentage,
  },
  totalPotentialSaving: {
    label: "Potential Savings",
    formatter: formatCurrency,
    color: SAVINGS_COLOR,
  },
};

export const costOptimizationTabToViewName: Record<
  CostOptimizationTab,
  DatadogViewNamesEnum
> = {
  allocation: DatadogViewNamesEnum.costOptimizationAllocation,
  rightSizing: DatadogViewNamesEnum.costOptimizationRightSizing,
  riskAnalysis: DatadogViewNamesEnum.costOptimizationRightSizing,
};

export const staticCostOptimizationContext: DatadogContext = {
  feTeam: "operate",
  beTeam: "operate",
};
