import React from "react";

import { EmailSettingsContextProvider } from "@/components/Settings/email/context/EmailSettingsContext";
import { EmailSettingsContent } from "@/components/Settings/email/EmailSettingsContent";
import { SnackbarMessageContextProvider } from "@/components/Settings/email/context/SnackbarMessageContext";
import { UserSettingsWrapper } from "@/pages/user-settings/UserSettingsWrapper";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { ViewOptions } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

const ddViewOptions: ViewOptions = {
  name: DatadogViewNamesEnum.userSettingsEmailDigestPage,
  context: {
    beTeam: "operate",
    feTeam: "operate",
  },
};

export const EmailSettings: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider viewOptions={ddViewOptions}>
      <UserSettingsWrapper title={"Email Digest Settings"}>
        <EmailSettingsContextProvider>
          <SnackbarMessageContextProvider>
            <EmailSettingsContent />
          </SnackbarMessageContextProvider>
        </EmailSettingsContextProvider>
      </UserSettingsWrapper>
    </DatadogReportLoadingTimeContextProvider>
  );
};
