import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { KomobotEmptyState } from "@komodorio/design-system/assets";

export const NoRowsOverlay: React.FC = () => (
  <Stack justifyContent="center" alignItems="center" height="100%" rowGap={1}>
    <KomobotEmptyState />
    <Typography variant="body2">No data</Typography>
  </Stack>
);
