import React from "react";
import { parseISO } from "date-fns";

import { blueForUI, blueForUIText } from "../../../../Colors";
import { SecretFragment } from "../../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { SecretDetails } from "../../ProcessList/details/SecretDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../groupEvents";
import ConfigMapScatterShape from "../shapes/config.svg?react";
import EventGroup, { EventBase } from "../index";

const secretChangeType = "Secret change";

export type SecretEvent = EventBase & Omit<SecretFragment, keyof EventBase>;

const toSecretEvent = (e: SecretFragment, serviceId: string): SecretEvent => {
  return {
    ...e,
    type: secretChangeType,
    eventTime: parseISO(e.eventTime),
    serviceId,
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class SecretEventGroup implements EventGroup {
  readonly backgroundColor = blueForUI;
  readonly fillColor = blueForUIText;
  readonly icon = ConfigMapScatterShape;

  readonly events: SecretEvent[];
  readonly id;
  readonly type;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details;
  readonly status;
  readonly serviceId;

  constructor(e: SecretEvent) {
    this.events = [e];
    this.id = `${e.serviceId}:${e.id}`;
    this.type = secretChangeType;
    this.summary = `${e.action} Secret - ${e.clusterName}/${e.namespace}/${e.secretName}`;
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.details = "";
    this.status = "Completed";
    this.serviceId = e.serviceId;
  }

  renderEventDetails(): JSX.Element {
    return <SecretDetails eventGroup={this} />;
  }
}
export const groupSecrets = (
  events: SecretFragment[],
  serviceId: string,
  { showConfigMapAndSecrets }: Record<string, unknown>
): // [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
SecretEventGroup[] => {
  if (!showConfigMapAndSecrets) return [];
  return mapAndSort(withServices(events, serviceId), toSecretEvent)
    .filter((e) => !e.k8sDefault)
    .map((e) => new SecretEventGroup(e));
};
