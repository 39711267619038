import { useEffect } from "react";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { usePodContainersRequestStatusContext } from "@/components/ResourceView/tabs/MetricsTab/context/usePodContainersRequestStatusContext";

export const useReportDatadogLoadingState = () => {
  const { cpuStatus, memoryStatus } = usePodContainersRequestStatusContext();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("cpuStatus", cpuStatus.isLoading);
    reportLoadingState("memoryStatus", memoryStatus.isLoading);
  }, [memoryStatus, cpuStatus, reportLoadingState]);
};
