import React from "react";
import styled from "styled-components";
import { GitHub as GithubIcon } from "@komodorio/design-system/icons";

import { contactMessage } from "../../../../constants";

import useGithubInstallation from "./useGithubInstallation";

const Container = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: 1rem;
  padding-top: 4rem;
  text-align: center;
`;

const wrongInputMessage = [
  "The installation was cancelled because of wrong input.",
  contactMessage,
];
const messages: Record<string, string[]> = {
  query: wrongInputMessage,
  input: wrongInputMessage,
  github: [
    "The installation was canceled because of an error with Github API.",
    "Please make sure the app has been installed on Github.",
  ],
  persistence: ["The installation could not be saved.", contactMessage],
};
const generateErrorMessage = (errorCode: string) =>
  messages[errorCode] || [`Unknown error code: ${errorCode}.`, contactMessage];

const defaultMessage = [
  "Installing Github integration...",
  "Do not leave this page until the installation is complete.",
];

const GithubInstallation: React.FC = () => {
  const errorCode = useGithubInstallation();
  const message = errorCode ? generateErrorMessage(errorCode) : defaultMessage;
  return (
    <Container>
      <GithubIcon width="5rem" height="5rem" />
      <div>
        {message.map((line) => (
          <div key={line}>{line}</div>
        ))}
      </div>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default GithubInstallation;
