import { useMemo } from "react";

import { useResourcesAPIPost } from "../../../../../../shared/hooks/resources-api/client";
import { DEPLOYMENT_STATE } from "../../../../../../shared/hooks/resources-api/requestResponseMaps";
import { useActiveAgent } from "../../../../../../shared/hooks/useAgents";
import {
  Json,
  buildKomodorUid,
} from "../../../../../../shared/hooks/resources-api/resourcesAPIUtils";

const useNewSpec = (
  clusterName: string | undefined,
  namespace: string | undefined,
  deploymentName: string | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): Record<string, unknown> | undefined => {
  const agentId = useActiveAgent(clusterName);
  const uid = useMemo(
    () =>
      buildKomodorUid({
        clusterName: clusterName ?? "",
        namespace: namespace ?? "",
        resourceName: deploymentName ?? "",
        kind: "Deployment",
      }),
    [clusterName, namespace, deploymentName]
  );

  const { data } = useResourcesAPIPost(
    DEPLOYMENT_STATE,
    {
      agentId: agentId ?? "",
      komodorUids: [uid],
      fields: [Json],
    },
    !agentId || !clusterName || !namespace || !deploymentName
  );

  return useMemo(() => {
    return (data?.data?.json?.[0] as Record<string, unknown>) || undefined;
  }, [data?.data]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useNewSpec;
