import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1WorkloadsPodsPostUrl,
  PodsStateResponse,
  PodStateRequest,
} from "../../../../generated/resourcesApi/api";

import { useResourcesApiClient } from "./apiClient";

const fetchPodsState = async (
  apiClient: AxiosInstance,
  body: PodStateRequest
) => {
  const { data } = await apiClient.post<PodsStateResponse>(
    apiV1WorkloadsPodsPostUrl(
      { podStateRequest: body },
      apiClient.defaults.baseURL ?? ""
    ),
    body
  );
  return data;
};

export const useGetPodsState = (body: PodStateRequest, enabled?: boolean) => {
  const apiClient = useResourcesApiClient();
  let url = "";
  try {
    url = apiV1WorkloadsPodsPostUrl({}, apiClient.defaults.baseURL ?? "");
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useQuery([url, body], () => fetchPodsState(apiClient, body), {
    enabled: enabled && !!url,
  });
};
