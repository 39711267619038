import { ResourceTableModelRow } from "komodor-types/build/entities/AgentTask";
import { clone } from "lodash";
import { useMemo } from "react";

import {
  INSPECTION_GROUP_PARAM_KEY,
  INSPECTION_LABELS_PARAM_KEY,
  INSPECTION_MATCH_EXACT_LABELS_PARAM_KEY,
  INSPECTION_POD_IP_PARAM_KEY,
  INSPECTION_SEARCH_TERM_PARAM_KEY,
  INSPECTION_STATUS_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  filterResourceTableByColumn,
  filterResourceTableByLabels,
  filterResourceTableBySearchTerm,
} from "../utils/filterResultsList";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { useNamespacesFilter } from "../InspectionViews/inpectionViewsHooks";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceListResponse } from "./../InspectionViews/common";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

interface FilteringArgs {
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  filterNamespaces?: string[] | null;
  filterStatus?: string[] | null;
  filterGroup?: string[] | null;
  filterLabels?: string[] | null;
  filterLabelsExactMatch?: boolean | null;
  filterSearchTerm?: string | null;
  filterIps?: string[] | null;
  showIpFilter?: boolean | null;
}

export const filterInspectionResourcesBySearchParams = ({
  resourceList,
  kubernetesResource,
  filterNamespaces,
  filterStatus,
  filterGroup,
  filterLabels,
  filterLabelsExactMatch,
  filterIps,
  showIpFilter,
  filterSearchTerm,
}: FilteringArgs): ResourceTableModelRow[] => {
  let output = clone(resourceList.rows);
  if (kubernetesResource.Namespaced && filterNamespaces?.length) {
    output = filterResourceTableByColumn(output, filterNamespaces, "namespace");
  }
  if (kubernetesResource.ShowStatusFilter && filterStatus?.length) {
    output = filterResourceTableByColumn(output, filterStatus, "status");
  }

  if (kubernetesResource.showGroupFilter && filterGroup?.length) {
    output = filterResourceTableByColumn(output, filterGroup, "group");
  }

  if (filterLabels?.length) {
    output = filterResourceTableByLabels(
      output,
      filterLabels,
      filterLabelsExactMatch ?? false
    );
  }

  if (filterSearchTerm) {
    output = filterResourceTableBySearchTerm(output, filterSearchTerm);
  }

  if (
    kubernetesResource.NameInK8S === "pods" &&
    showIpFilter === true &&
    filterIps?.length
  ) {
    output = filterResourceTableByColumn(output, filterIps, "ip");
  }

  return output;
};

type InspectionFiltersParams = {
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  resourceKeyInLS?: string;
};

const useInspectionFilters = ({
  resourceList,
  kubernetesResource,
  resourceKeyInLS,
}: InspectionFiltersParams): ResourceTableModelRow[] => {
  const { showIpFilter: showIpFilterFF } = useOverridableFlags();
  const showIpFilter = validateFFtype(showIpFilterFF);

  const [filterNamespaces] = useNamespacesFilter(resourceKeyInLS);

  const [filterStatus] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_STATUS_PARAM_KEY
  );
  const [filterLabels] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_LABELS_PARAM_KEY
  );
  const [filterLabelsExactMatch] = useStringifiedStateInSearchParams<boolean>(
    INSPECTION_MATCH_EXACT_LABELS_PARAM_KEY
  );
  const [filterSearchTerm] = useStateInSearchParams(
    INSPECTION_SEARCH_TERM_PARAM_KEY
  );
  const [filterGroup] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_GROUP_PARAM_KEY
  );
  const [filterIps] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_POD_IP_PARAM_KEY
  );

  return useMemo(
    () =>
      filterInspectionResourcesBySearchParams({
        filterGroup,
        filterLabels,
        filterLabelsExactMatch,
        filterNamespaces,
        filterSearchTerm,
        filterStatus,
        kubernetesResource,
        resourceList,
        filterIps,
        showIpFilter,
      }),
    [
      filterGroup,
      filterLabels,
      filterLabelsExactMatch,
      filterNamespaces,
      filterSearchTerm,
      filterStatus,
      kubernetesResource,
      resourceList,
      filterIps,
      showIpFilter,
    ]
  );
};

const validateFFtype = (ffValue: unknown): boolean => ffValue === true;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useInspectionFilters;
