export const apiKeysAriaLabels = {
  table: "Api keys table",
  search: "Search api keys",
  buttons: {
    generate: "generate button",
    config: "config button",
    delete: "delete button",
    expirationRemove: "remove expiration button",
    showExpired: "show expired button",
  },
  modals: {
    delete: "delete modal",
    config: {
      modal: "config modal",
      enable: "enable ttl button",
      days: "days in ttl input",
    },
    generate: "generate modal",
    ttl: "ttl modal",
  },
};
