import { useMemo } from "react";

import { getButton } from "@/components/Actions/common/getActionButtonStyle";
import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import CertManagerCertificate from "@/components/ResourceView/resources/certManagerCertificate";

export const useGetDescribeButtonProps = (
  certificateResource: CertManagerCertificate
) => {
  return useMemo(() => {
    const actionItem = getButton(
      AvailableActions.Describe,
      false, // disableAll,
      certificateResource
    );

    return { actionItem };
  }, [certificateResource]);
};
