import { useMemo } from "react";
import { groupBy } from "lodash";

import { ServiceInfo } from "../../../../../../../shared/types/ServiceInfo";
import useAllServicesAttributes from "../../../../../../servicesView/servicesAttributes/useAllServicesAttributes";
import Selected from "../../../../../../../shared/components/FilterBar/Interfaces/Selected";
import { useFiltersList } from "../../../../../../../shared/components/FilterBar/hooks/useFiltersList";
import useGenerateCategories from "../../../../../../../shared/components/FilterBar/hooks/useGenerateCategories";
import { FilterBarCategory } from "../../../../../../../shared/components/FilterBar/FilterBar";
import useCheckedFilters from "../../../../../../../shared/components/FilterBar/hooks/useCheckedFilters";
import useCheckedServices from "../../../../../../../shared/components/FilterBar/hooks/useCheckedServices";
import { isAmong, serviceNameIncludes } from "../ServicesTable/utils";

export const useServicesTableFilters = (
  services: ServiceInfo[] | undefined,
  servicesSearchTerm: string,
  showOnlySelectedServices: boolean,
  selectedServiceIds: Record<string, boolean>,
  selectedFilters: Selected,
  setSelectedFilters: (selectedFilters: Selected) => void,
  desiredFilterCategories: string[]
): {
  filterCategories: FilterBarCategory[] | undefined;
  filteredServices: ServiceInfo[] | undefined;
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
} => {
  const allServicesAttributes = useAllServicesAttributes();
  const serviceAttributesByService = useMemo(() => {
    return groupBy(allServicesAttributes, (attr) => attr.serviceId);
  }, [allServicesAttributes]);
  const [filtersList] = useFiltersList(services, serviceAttributesByService);
  const checkedFilters = useCheckedFilters();

  const preliminarilyFilteredServices = useMemo(
    () =>
      services
        ?.filter(
          (service) =>
            !showOnlySelectedServices || isAmong(selectedServiceIds)(service)
        )
        .filter(serviceNameIncludes(servicesSearchTerm)),
    [services, servicesSearchTerm, showOnlySelectedServices, selectedServiceIds]
  );

  const { checkedServices } = useCheckedServices(
    filtersList,
    preliminarilyFilteredServices,
    checkedFilters
  );

  const filterCategories = useGenerateCategories(
    checkedServices,
    filtersList,
    selectedFilters,
    setSelectedFilters
  )?.filter(isNamedAsEither(desiredFilterCategories));

  return {
    filterCategories,
    filteredServices: checkedServices,
  };
};

const isNamedAsEither = (names: string[]) => (category: FilterBarCategory) =>
  names.includes(category.name);
