import React, { useMemo } from "react";
import styled from "styled-components";

import { useViolationHistory } from "../../../hooks/useViolationHistory";
import { useReportDrawerLoadingTime } from "../../../hooks/useReportDrawerLoadingTime";

import { HistoryItem } from "./HistoryItem";
import { StyledTimeline } from "./historyStyles";
import { HistoryFetchingState } from "./HistoryFetchingState";

const Container = styled.div``;

export const History: React.FC = () => {
  const { data, isFetching } = useViolationHistory();
  useReportDrawerLoadingTime(!isFetching);

  const items = useMemo(() => {
    if (!data?.data?.history) return [];
    return (
      data.data.history
        .filter((history, idx) => {
          const prevItem = data.data.history[idx + 1];
          return (
            !prevItem ||
            prevItem.status !== history.status ||
            prevItem.severity !== history.severity
          );
        })
        // [CU-86c1gn74n] fix max-params
        // eslint-disable-next-line max-params
        .map((item, idx, items) => {
          const prevItem = items[idx + 1];
          return <HistoryItem key={item.id} item={item} prevItem={prevItem} />;
        })
    );
  }, [data?.data?.history]);

  return (
    <Container>
      {isFetching ? (
        <HistoryFetchingState />
      ) : (
        <StyledTimeline>{items}</StyledTimeline>
      )}
    </Container>
  );
};
