import React, { Suspense, lazy } from "react";
import type { EditorProps } from "@komodorio/design-system/komodor-ui/editor";
import { datadogRum } from "@datadog/browser-rum";
import Typography from "@mui/material/Typography";

import { CenteredLoader } from "@/shared/components/CenteredLoader";

const Editor = lazy(() =>
  import("@komodorio/design-system/komodor-ui/editor")
    .then((module) => ({
      default: module.Editor,
    }))
    .catch((err) => {
      datadogRum.addError(err);
      return {
        default: () => (
          <Typography>
            Error loading editor, please refresh the page and try again
          </Typography>
        ),
      };
    })
);

export const LazyEditor: React.FC<EditorProps> = (props) => {
  return (
    <Suspense fallback={<CenteredLoader />}>
      <div aria-label={"YAML editor"}>
        <Editor {...props} />
      </div>
    </Suspense>
  );
};
