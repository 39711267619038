import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import { JobFragment } from "../../../../../../generated/graphql";
import { useDataForFlag } from "../../../../useDataForFlag";
import { TimeWindow } from "../../../../../types/TimeWindow";
import { useResourcesAPIGet } from "../../../client";
import { WORKLOADS_JOBS_EVENTS_SEARCH } from "../../../requestResponseMaps";
import {
  apiV1WorkloadsJobsEventsSearchGetUrl,
  EventsApiApiV1WorkloadsJobsEventsSearchGetRequest,
  JobEventsResponse,
} from "../../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../../apiClient";
import { useWorkspaceQueryKey } from "../../../../workspaces-api/useWorkspaceQueryKey";

export interface UseJobEventsParams {
  showJobEvents: unknown;
  timeWindow: Pick<TimeWindow, "start" | "end">;
  serviceIds?: string[];
}

const fetchJobEvents = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1WorkloadsJobsEventsSearchGetRequest
): Promise<JobEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1WorkloadsJobsEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useJobEvents = (
  params: UseJobEventsParams
): JobFragment[] | null => {
  const keys = useWorkspaceQueryKey([
    WORKLOADS_JOBS_EVENTS_SEARCH,
    params.timeWindow,
  ]);
  const apiClient = useResourcesApiClient();
  const { data } = useQuery(
    keys,
    () =>
      fetchJobEvents(apiClient, {
        fromEpoch: params.timeWindow.start.getTime().toString(),
        toEpoch: params.timeWindow.end.getTime().toString(),
        serviceIds: params.serviceIds,
        fields: [
          "id",
          "uid",
          "name",
          "eventTime",
          "status",
          "cronJobUid",
          "services",
          "action",
          "selector",
          "clusterName",
          "namespace",
          "subEvents",
        ],
      }),
    { enabled: params.showJobEvents === true }
  );

  return useDataForFlag(params.showJobEvents, convertToJobFragment(data));
};

const convertToJobFragment = (
  data: JobEventsResponse | undefined
): JobFragment[] | undefined => {
  return data?.data?.map((job) => {
    return job as JobFragment;
  });
};

export const useJobEventsByUid = (
  timeWindow: Pick<TimeWindow, "start" | "end">,
  uid: string
): JobFragment[] | undefined => {
  const { data } = useResourcesAPIGet(
    WORKLOADS_JOBS_EVENTS_SEARCH,
    {
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
      uid: uid,
      fields: [
        "id",
        "uid",
        "name",
        "eventTime",
        "status",
        "cronJobUid",
        "services",
        "action",
        "selector",
        "clusterName",
        "namespace",
        "subEvents",
      ],
    },
    !uid
  );

  return data?.data as JobFragment[] | undefined;
};
