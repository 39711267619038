import React, { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";

import {
  TimeWindow,
  TimeWindowProps,
} from "../../../../../shared/types/TimeWindow";
import groupEvents from "../../../../common/EventGroup/groupEvents";
import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";
import EventGroup from "../../../../common/EventGroup";
import useServiceViewEvents from "../../../../common/EventsPagination/useServiceViewEvents";

import NewEventsAlert from "./NewEventsAlert";

const useRemainingEventGroups = (
  serviceId: string,
  timeWindow: TimeWindow,
  clusterName?: string,
  namespace?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): EventGroup[] => {
  const flags = useOverridableFlags();
  const [remainingEnd, setRemainingEnd] = useState(new Date());
  const remainingEventsWindow = useServiceViewEvents(
    serviceId,
    {
      start: timeWindow.end,
      end: remainingEnd,
    },
    clusterName,
    namespace
  );

  const groupedRemainingEvents = useMemo(() => {
    return groupEvents(
      remainingEventsWindow ? [remainingEventsWindow] : [],
      serviceId,
      flags
    ).filter((e) => e.startTime.getTime() >= timeWindow.end.getTime());
  }, [flags, remainingEventsWindow, serviceId, timeWindow.end]);

  useEffect(() => {
    if (!groupedRemainingEvents) return;
    const timeoutId = setTimeout(() => setRemainingEnd(new Date()), 5000);
    return () => clearTimeout(timeoutId);
  }, [groupedRemainingEvents]);

  const [state, setState] = useState<EventGroup[]>([]);
  useEffect(() => {
    if (groupedRemainingEvents && !isEqual(groupedRemainingEvents, state)) {
      setState(groupedRemainingEvents);
    }
  }, [groupedRemainingEvents, state]);

  return state;
};

const StatefulNewEventsAlert: React.FC<
  TimeWindowProps & {
    serviceId: string;
    clusterName?: string;
  }
> = ({ serviceId, timeWindow, setTimeWindow, clusterName }) => {
  const remainingEventGroups = useRemainingEventGroups(
    serviceId,
    timeWindow,
    clusterName
  );
  return (
    <NewEventsAlert
      visible={remainingEventGroups.length > 0}
      timeWindow={timeWindow}
      setTimeWindow={setTimeWindow}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default StatefulNewEventsAlert;
