import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { AuditLogApiApiV1AuditLogGetRequest } from "@/generated/accounts";

export type AuditMultipleValuesFilters =
  | "actions"
  | "entityTypes"
  | "operations"
  | "userIds";

export interface AuditFilters {
  actions: MuiSelectionOption<string>[] | undefined;
  entityTypes: MuiSelectionOption<string>[] | undefined;
  operations: MuiSelectionOption<string>[] | undefined;
  userIds: MuiSelectionOption<string>[] | undefined;
  status: MuiSelectionOption<string> | undefined;
  dateRange: MuiSelectionOption<string>;
  entityName: string | undefined;
}

type AuditFiltersKeys = keyof AuditFilters | "startTime";

// Overriding the AuditLogApiApiV1AuditLogGetRequest type to exclude the AuditFiltersKeys
// This is because we transform the data from string (single/array) to MuiSelectionOption<string>
type OriginalApiData = Omit<
  AuditLogApiApiV1AuditLogGetRequest,
  AuditFiltersKeys
>;

export type Context = OriginalApiData & AuditFilters;

export type Unit = "h" | "d";

type DictionaryDetails = object;
type ComparisonDetails = {
  new: object;
  old: object;
};

export type LogDetailsData = DictionaryDetails | ComparisonDetails;

export function isComparisonDetails(
  details: LogDetailsData
): details is ComparisonDetails {
  return (
    details &&
    typeof details === "object" &&
    "new" in details &&
    "old" in details
  );
}

export type ResourceData = {
  cluster: string;
  namespace: string;
  resourceType: string;
  resourceName: string;
};

export type AuditLogDetails = {
  detailsType: "dictionary" | "comparison";
  resourceData: ResourceData | undefined;
  data: LogDetailsData;
  responseData: {
    message: string;
    statusCode: number;
  };
};
