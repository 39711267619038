import { useEffect, useMemo, useState } from "react";

import { useGetPodsBatchesFromOwnerUid } from "../podEvent/usePodEvents";
import { useGetGroupedPodEvents } from "../../../../shared/hooks/resources-api/client/events/useGetGroupedPodEvents";
import { PodGroups } from "../../../../generated/resourcesApi";

export const usePodPhasesPaginationByOwnerUid = (
  uid: string,
  cluster: string,
  namespace: string,
  enable: boolean,
  fromEpoch?: Date,
  toEpoch?: Date
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const [phaseGroups, setPhaseGroups] = useState<PodGroups[]>();
  const [isLoadingFirstPage, setIsLoadingFirstPage] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const [podsUidsBatches, isLoadingPodBatches] = useGetPodsBatchesFromOwnerUid(
    uid,
    cluster,
    namespace,
    fromEpoch,
    toEpoch
  );

  const currentPagePods = useMemo(() => {
    return podsUidsBatches?.[currentPage];
  }, [currentPage, podsUidsBatches]);

  const { data: groupedPodEventsData } = useGetGroupedPodEvents(
    {
      komodorUids: currentPagePods?.podsUids ?? [],
      fromEpoch: fromEpoch?.getTime(),
      toEpoch: toEpoch?.getTime(),
    },
    enable
  );
  const groupedPodEventsPerPage = useMemo(
    () => groupedPodEventsData?.data ?? [],
    [groupedPodEventsData]
  );

  useEffect(() => {
    if (podsUidsBatches?.length === 0) {
      setIsLoadingFirstPage(false);
    }
  }, [podsUidsBatches?.length]);

  useEffect(() => {
    if (groupedPodEventsPerPage?.length) {
      setPhaseGroups((prev) => {
        if (!prev) {
          return groupedPodEventsPerPage;
        }
        return [...prev, ...groupedPodEventsPerPage];
      });
      setIsLoadingFirstPage(false);
      if (podsUidsBatches) {
        setCurrentPage((prev) => {
          if (prev < podsUidsBatches.length - 1) {
            return prev + 1;
          }
          return prev;
        });
      }
    }
  }, [groupedPodEventsPerPage, podsUidsBatches]);

  return useMemo(() => {
    const isLoading = isLoadingPodBatches || isLoadingFirstPage;
    const events = !isLoading && !phaseGroups ? [] : phaseGroups;
    return {
      podPhases: events,
      isLoading,
    };
  }, [phaseGroups, isLoadingPodBatches, isLoadingFirstPage]);
};
