import React from "react";
import {
  Button,
  Typography,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@komodorio/design-system/deprecated";
import { QueryObserverResult } from "@tanstack/react-query";

import { MonitorConfiguration } from "@/generated/monitorsApi";
import { useDeleteMonitorConfigurationById } from "@/shared/hooks/monitors-api/client/monitors/useDeleteMonitorConfigurationById";

interface DeleteRuleModalProps {
  ruleToDelete: string | null;
  handleClose: () => void;
  refreshMonitors: () => Promise<
    QueryObserverResult<MonitorConfiguration[], unknown>
  >;
}

const DeleteRuleModal: React.FC<DeleteRuleModalProps> = ({
  ruleToDelete,
  handleClose,
  refreshMonitors,
}) => {
  const { mutateAsync: deleteRule } = useDeleteMonitorConfigurationById();
  return (
    <Modal isOpen={!!ruleToDelete} width="25rem" onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Delete Rule</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          Are you sure you want to delete this rule?
          <br />
          (This action can not be undone)
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          size="small"
          onClick={async () => {
            if (ruleToDelete) {
              await deleteRule({ id: ruleToDelete });
            }
            await refreshMonitors();
            handleClose();
          }}
        >
          Delete
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DeleteRuleModal;
