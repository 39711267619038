import { QueryObserverOptions } from "@tanstack/react-query";

import { getAppConfig } from "../../config/appConfig";

import { useCreateAxiosClient } from "@/shared/hooks/useCreateAxiosClient";

export type QueryOptions = Pick<
  QueryObserverOptions,
  "enabled" | "keepPreviousData"
>;

export const useReliabilityApiClient = () => {
  return useCreateAxiosClient(getAppConfig().reliabilityAPIServerURL);
};
