import { AxiosInstance } from "axios";
import { useMutation } from "@tanstack/react-query";

import {
  apiV1UsersCanIGetUrl,
  apiV1UsersIdCanIGetUrl,
  CanIResponse,
  UsersApiApiV1UsersCanIGetRequest,
  UsersApiApiV1UsersIdCanIGetRequest,
} from "@/generated/auth";
import { useAuthApiClient } from "@/shared/hooks/auth-service/client/apiClient";

const PATH = "/users/can-i";
const ADMIN_PATH = "/users/:id/can-i";

export interface CanIParams extends UsersApiApiV1UsersCanIGetRequest {
  id?: UsersApiApiV1UsersIdCanIGetRequest["id"];
}

function hasUserId(
  params: UsersApiApiV1UsersCanIGetRequest | UsersApiApiV1UsersIdCanIGetRequest
): params is UsersApiApiV1UsersIdCanIGetRequest {
  return (params as UsersApiApiV1UsersIdCanIGetRequest).id !== undefined;
}

const fetchCanI = async (
  apiClient: AxiosInstance,
  params: CanIParams
): Promise<CanIResponse> => {
  if (hasUserId(params)) {
    const { data } = await apiClient.get(
      apiV1UsersIdCanIGetUrl(params, apiClient.defaults.baseURL ?? "")
    );
    return data;
  }

  const { data } = await apiClient.get(
    apiV1UsersCanIGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetCanI = (params: CanIParams) => {
  const apiClient = useAuthApiClient();
  return useMutation([params.id ? ADMIN_PATH : PATH, params], () =>
    fetchCanI(apiClient, params)
  );
};
