import { datadogRum } from "@datadog/browser-rum";

import { ONE_DAY_IN_MS } from "../../constants/time";

import { CookieKeys } from "./cookieKeys";

export const getCookieValue = (cookieName: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);
  if (parts.length !== 2) return undefined;
  return parts.pop()?.split(";").shift();
};

export const setCookie = (
  cookieName: CookieKeys,
  cookieValue: string,
  expirationMinutes: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): void => {
  if (cookieName === CookieKeys.JWT) {
    datadogRum.addAction("setCookie", {
      status: "attempt",
      expirationMinutes,
      actualExpiration: new Date(
        Date.now() + expirationMinutes * ONE_DAY_IN_MS
      ),
      cookiesLength: document.cookie.length,
      cookiesWeight: new Blob([document.cookie]).size,
    });
  }

  const d = new Date();
  d.setTime(d.getTime() + expirationMinutes * ONE_DAY_IN_MS);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";

  if (cookieName === CookieKeys.JWT) {
    const status =
      getCookieValue(CookieKeys.JWT) === cookieValue ? "success" : "failure";
    datadogRum.addAction("setCookie", {
      status,
      expirationMinutes,
      actualExpiration: d,
      cookiesLength: document.cookie.length,
      cookiesWeight: new Blob([document.cookie]).size,
    });
  }
};
