import { useMemo } from "react";

import { LocalStorageItem } from "../../utils/localStorageSettings";

import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";

type Props<T> = {
  localStorageItem: LocalStorageItem;
  defaultValue: T;
};

export const useGetParseState = <T>(state: string, defaultValue: T): T => {
  return useMemo(() => {
    try {
      const parsed = JSON.parse(state);
      return parsed ?? defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }, [defaultValue, state]);
};

export const useLocalStorage = <T>({
  localStorageItem,
  defaultValue,
}: Props<T>): [T, (value: string) => void] => {
  const [item, setItem] = useStateInLocalStorage(
    localStorageItem,
    JSON.stringify(defaultValue)
  );

  const parsedItem = useGetParseState<T>(item, defaultValue);

  return [parsedItem, setItem];
};
