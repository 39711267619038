import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import MuiTypography from "@mui/material/Typography";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import { KomobotEmptyState } from "@komodorio/design-system/assets";
import { muiColors } from "@komodorio/design-system";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../../resources/pod";
import { useGetGroupedPodEventsForResource } from "../../../../../shared/hooks/resources-api/client/events/useGetGroupedPodEvents";
import { groupGroupedPodEvents } from "../../../../common/EventGroup/groupedPodEvent/PodPhaseGroup";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";

import { PodPhase } from "./PodPhase";
import { PodPhasesTable } from "./PodPhaseTable";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";

const PodEventsHeader = styled.div`
  display: flex;
  padding: 1.5rem 0.3rem;
  justify-content: space-between;
  align-items: center;
`;

const DirectionContainer = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
  cursor: pointer;
`;
const Container = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export interface PodEventsTabProps {
  resource: Pod;
}

const ROWS_PER_PAGE = 10;

const PodEventsTabContent: React.FC<PodEventsTabProps> = ({ resource }) => {
  const [phasesDirection, setPhasesDirection] = React.useState<"asc" | "desc">(
    "desc"
  );

  const { data, isFetching } = useGetGroupedPodEventsForResource(resource);

  const podPhasesGroups = useMemo(
    () => groupGroupedPodEvents(data?.data?.flatMap((e) => e) ?? [], ""),
    [data]
  );

  const rows = podPhasesGroups
    .sort((a, b) => {
      return phasesDirection === "desc"
        ? b.startTime.getTime() - a.startTime.getTime()
        : a.startTime.getTime() - b.startTime.getTime();
    })
    .map((event) => <PodPhase key={event.id} phase={event} />);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () => reportLoadingState("podEventsData", isFetching),
    [isFetching, reportLoadingState]
  );

  if (isFetching) {
    return (
      <Container>
        <LinesLoader />
      </Container>
    );
  }
  if (rows.length === 0) {
    return (
      <Container>
        <KomobotEmptyState />
        <MuiTypography variant="h4" color={muiColors.gray[800]}>
          No data
        </MuiTypography>
      </Container>
    );
  }
  return (
    <div
      aria-label={AriaLabels.PodPhases.PodEvents.EventsDrawer}
      style={{ overflow: "hidden" }}
    >
      <PodEventsHeader>
        <DirectionContainer
          aria-label={AriaLabels.PodPhases.PodEvents.DirectionToggle}
          onClick={() => {
            setPhasesDirection((prev) => (prev === "asc" ? "desc" : "asc"));
          }}
        >
          <MuiTypography variant="body2" color={"text.secondary"}>
            {phasesDirection === "desc" ? "Newest First" : "Oldest First"}
          </MuiTypography>
          <ArrowUpward
            sx={{
              fontSize: "1rem",
              color: "text.secondary",
              transform: phasesDirection === "asc" ? "rotate(180deg)" : "",
            }}
          />
        </DirectionContainer>
      </PodEventsHeader>
      <PodPhasesTable rows={rows} rowsPerPage={ROWS_PER_PAGE} />
    </div>
  );
};

export const PodEventsTab: React.FC<PodEventsTabProps> = ({ resource }) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.podEventsTab,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <PodEventsTabContent resource={resource} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
