import {
  DeprecatedIntegrationType,
  MonitoringProviderType,
} from "komodor-types";
import { get } from "lodash";

import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { Installation } from "../../integrations/management/installed/useInstallationSubscription";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";

import {
  buildPrometheusMetricsTaskForNodes,
  buildPrometheusMetricsTaskForPods,
  MetricsAgentTaskData,
} from "./metricsTaskDataBuilder";
import {
  HttpTaskResult,
  MetricsTaskResult,
  MetricTaskResultError,
  parseNodesResponseFromPrometheus,
  parsePodsResponseFromPrometheus,
} from "./metricsTaskResultParser";

export class MonitoringProvider {
  private monitoringProvider: MonitoringProviderType | null = null;
  private kubernetesResource: KubernetesResource;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(
    kubernetesResource: KubernetesResource,
    agentInfo: AgentProperties | null,
    installations: Installation[] | undefined
  ) {
    this.kubernetesResource = kubernetesResource;
    if (!agentInfo) {
      return;
    }

    const provider = getMonitoringProviderType(agentInfo, installations);
    if (provider) {
      this.monitoringProvider = provider;
    }
  }

  getMetricsTasksData = (
    endTimeInUnix: number
  ): MetricsAgentTaskData[] | null => {
    if (this.isPods()) {
      if (this.isPrometheus()) {
        return buildPrometheusMetricsTaskForPods(endTimeInUnix);
      }
    }

    if (this.isNodes() && this.isPrometheus()) {
      return buildPrometheusMetricsTaskForNodes(endTimeInUnix);
    }
    // TODO: notify error
    return null;
  };

  parseMetricTasksResponse = (
    httpResults: unknown
  ): MetricsTaskResult | null => {
    if (!HttpTaskResult.guard(httpResults)) {
      return MetricTaskResultError;
    }
    if (!httpResults.length) {
      return MetricTaskResultError;
    }

    if (this.isPods()) {
      if (this.isPrometheus()) {
        return parsePodsResponseFromPrometheus(httpResults);
      }
    }

    if (this.isNodes()) {
      if (this.isPrometheus()) {
        return parseNodesResponseFromPrometheus(httpResults);
      }
    }
    // TODO: notify error
    return null;
  };

  getType = (): MonitoringProviderType | null => {
    return this.monitoringProvider;
  };

  private isPrometheus = () => {
    return (
      this.monitoringProvider === "prometheus-helm" ||
      this.monitoringProvider === "prometheus-operator"
    );
  };

  private isPods = (): boolean => {
    return this.kubernetesResource.NameInK8S === "pods";
  };

  private isNodes = (): boolean => {
    return this.kubernetesResource.NameInK8S === "nodes";
  };
}

function isMonitoringProvider(type: string): type is MonitoringProviderType {
  return (type as MonitoringProviderType) !== undefined;
}

function getMonitorProviderFromAgent(
  agentInfo: AgentProperties
): MonitoringProviderType | null {
  if (agentInfo.monitoringProviders?.length) {
    for (const provider of agentInfo.monitoringProviders) {
      if (isMonitoringProvider(provider.type)) {
        return provider.type;
      }
    }
  }
  return null;
}

function getMonitorProviderFromInstallations(
  installations: Installation[] | undefined,
  clusterName: string
): MonitoringProviderType | null {
  const configuration = installations
    ?.filter(
      (i) =>
        i.integration === DeprecatedIntegrationType.PROMETHEUS_METRICS_SERVER
    )
    ?.find(
      (i) => get(i.configuration, "clusterName", "") === clusterName
    )?.configuration;

  const type = get(configuration, "type", "");
  if (configuration && isMonitoringProvider(type)) {
    return type;
  }
  return null;
}

function getMonitoringProviderType(
  agentInfo: AgentProperties,
  installations: Installation[] | undefined
): MonitoringProviderType | null {
  let monitorProviderType = getMonitorProviderFromInstallations(
    installations,
    agentInfo.clusterName
  );

  // fallback to auto discover
  if (!monitorProviderType) {
    monitorProviderType = getMonitorProviderFromAgent(agentInfo);
  }

  return monitorProviderType;
}
