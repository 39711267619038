import Box from "@mui/material/Box";
import React, { useLayoutEffect, useRef } from "react";
import { omit } from "lodash";

import { LazyEditor } from "@/components/common/LazyEditor";
import { ObjectDiffAsYaml } from "@/components/common/ObjectDiff";
import {
  AuditLogDetails,
  isComparisonDetails,
} from "@/pages/organization-settings/account/AuditPage/types";
import { auditPageAriaLabels } from "@/pages/organization-settings/account/AuditPage/constants";

const excludedFields = [
  "createdAt",
  "updatedAt",
  "id",
  "accountId",
  "userId",
  "isVerified",
  "allowedAccounts",
  "showInWeb",
];

const { details: ariaLabels } = auditPageAriaLabels.drawer;

const MARGIN_BOTTOM = 20;

export const LogDetails: React.FC<{
  details: AuditLogDetails;
}> = ({ details }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = React.useState(0);
  const [containerWidth, setContainerWidth] = React.useState(0);

  useLayoutEffect(() => {
    const tempHeight = ref.current?.getBoundingClientRect().height;
    const tempWidth = ref.current?.getBoundingClientRect().width;
    if (tempHeight) {
      setContainerHeight(tempHeight - MARGIN_BOTTOM);
    }

    if (tempWidth) {
      setContainerWidth(tempWidth);
    }
  }, []);

  if (
    isComparisonDetails(details.data) &&
    details.detailsType === "comparison"
  ) {
    let oldValue = details.data.old;
    let newValue = details.data.new;
    // in case the objects contain a native type (e.g int, string), we need to wrap them in an object
    // to prevent the diff viewer from crashing
    if (typeof oldValue !== "object" || typeof newValue !== "object") {
      oldValue = { value: oldValue };
      newValue = { value: newValue };
    }

    const oldObj = omit(oldValue, excludedFields);
    const newObj = omit(newValue, excludedFields);

    return (
      <Box
        aria-label={ariaLabels.comparisonContainer}
        sx={{
          overflowY: "auto",
          display: "flex",
          boxSizing: "border-box",
          flexGrow: 1,
        }}
        ref={ref}
      >
        <ObjectDiffAsYaml
          oldObj={oldObj}
          newObj={newObj}
          overridingStyles={{
            diffContainer: {
              display: "block",
              boxSizing: "border-box",
              height: containerHeight,
              width: containerWidth,
            },
          }}
        />
      </Box>
    );
  }

  const data = omit(details.data, excludedFields);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,

        // Override the height of the editor to fill the remaining space
        "& > div": {
          height: "100%",
        },
      }}
      aria-label={ariaLabels.dictionaryContainer}
    >
      <LazyEditor
        value={JSON.stringify(data || {}, null, 2)}
        readOnly
        width="100%"
        height="100%"
        mode="json"
        variant="dark"
      />
    </Box>
  );
};
