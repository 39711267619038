import { useEffect, useState } from "react";

import { getCodeVerifier } from "../../../../shared/utils/pkceGenerator";
import { PAGER_DUTY_CLIENT_ID } from "../../../../constants";
import { getAppConfig } from "../../../../shared/config/appConfig";
import { useCreatePagerDutyInstallationMutation } from "../../../../generated/graphql";

import { notifyDDError } from "@/shared/hooks/exceptionManagement";

export const ErrorNames = {
  AuthorizationError: "AuthorizationError",
  FetchTokenError: "FetchTokenError",
  CombinedError: "CombinedError",
  InstallationIdUnavailableError: "InstallationIdUnavailableError",
};

const urlParams = new URLSearchParams(window.location.search);

interface ResponseToken {
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

const useHandlePDInstallationResponse = (): {
  data?: { success: boolean };
  error?: Error;
} => {
  const [, createInstallation] = useCreatePagerDutyInstallationMutation();

  const [token, setToken] = useState<ResponseToken | null>(null);
  const [error, setError] = useState<Error | undefined>();
  const [done, setDone] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      const code = urlParams.get("code");
      const verifier = getCodeVerifier();
      const url = `https://app.pagerduty.com/oauth/token?grant_type=authorization_code&client_id=${PAGER_DUTY_CLIENT_ID}&redirect_uri=${
        getAppConfig().pagerDutyRedirectUrl
      }&code=${code}&code_verifier=${verifier}`;
      const res = await fetch(url, { method: "POST" });
      if (!res.ok) {
        const error = new Error(
          "Failed exchanging code with authorization token"
        );
        error.name = ErrorNames.FetchTokenError;
        notifyDDError({
          name: "PagerDuty Installation Error",
          message: error.message,
        });
        return setError(error);
      }
      const token = await res.json();
      setToken(token as ResponseToken);
    };

    const redirectError = urlParams.get("error");
    if (redirectError) {
      const error = new Error(redirectError);
      error.name = ErrorNames.AuthorizationError;
      return setError(error);
    }
    fetchToken();
    return () => setToken(null);
  }, []);

  useEffect(() => {
    if (!token) {
      return;
    }

    const install = async () => {
      const { error, data } = await createInstallation({
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
      });
      if (error) {
        return setError(error);
      }
      if (!data?.createPagerDutyInstallation?.installationId) {
        const error = new Error(
          "Pager duty installation ID is not available, failed to retrive past incidents"
        );
        error.name = ErrorNames.InstallationIdUnavailableError;
        return setError(error);
      }
      setDone(true);
    };

    if (!done) {
      install();
    }
  }, [createInstallation, done, token]);

  return {
    error: error,
    data: { success: done },
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useHandlePDInstallationResponse;
