import { EventsApiApiV1DeploysEventsSearchGetRequest } from "../../../../../../../../../generated/resourcesApi";
import { MetricType } from "../../../../../../../../Metrics/types";

export type SpecMetricTarget = {
  averageUtilization?: number; // e.g. 50
  averageValue?: string; // e.g. 2G
  type: string;
};

type NewSpecMetric = {
  resource: {
    name: MetricType;
    target: SpecMetricTarget;
  };
  type: string;
};

export type ScaleTargetRef = {
  kind: string;
  name: string;
  apiVersion: string;
};

export type Container = {
  name: string;
  image: string;
  resources: {
    limits: { cpu: string; memory: string };
    requests: { cpu: string; memory: string };
  };
};

export type NewSpec = {
  spec: {
    maxReplicas: number;
    minReplicas: number;
    metrics: NewSpecMetric[];
    scaleTargetRef: ScaleTargetRef;
    template: {
      spec: {
        containers: Container[];
      };
    };
  };
  metadata?: { name: string };
};

export type ScalingMetric = {
  resourceType: MetricType;
  metricType: AverageUtilizationMetricType;
  value: number | string;
};

export type SingleMetricsGraphProps = {
  resourceType: MetricType;
  unit: ValueType;
  value: number;
  averageUtilizationValue?: number;
};

export const basicRequestParams: Pick<
  EventsApiApiV1DeploysEventsSearchGetRequest,
  "limit" | "order"
> = {
  limit: 1,
  order: "DESC",
};

export type AverageUtilizationMetricType = "percentage" | "unit";
export type ValueType = "percentage" | "bytes";
