import React, { useMemo, useState } from "react";
import { ContainerStatus } from "kubernetes-types/core/v1";

import { MetricsGraphContainer } from "../../../Metrics/graphs/MetricsGraphContainer";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { formatMemoryTick } from "../../../Metrics/utils";
import { GraphContainerWithStatus } from "../../../Metrics/GraphContainerWithStatus";
import { formatNumber } from "../../../../shared/utils/formatNumber";
import { Uid } from "../../../../shared/utils/generateUid";
import { EnlargedMetricsGraph } from "../../../Metrics/graphs/EnlargedMetricsGraph";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import { GraphProps } from "../NodeMetricsTab/NodeMetricsTabContent/MetricsPreferences/types";

import { MetricsTabContextProvider } from "./context/MetricsTabContext";
import { graphHeight, timelineTicksNum } from "./constants";
import {
  useChartLinesFromContainerNames,
  useMetricParams,
} from "./hooks/metricsTabHooks";
import {
  generateMetricsResponse,
  getCommonGraphContainerWithStatusProps,
  podContainerTypeToLabel,
} from "./utils";
import { podContainersType } from "./types";
import {
  PodContainersCpuMetricsTooltip,
  PodContainersMemoryMetricsTooltip,
} from "./PodContainersTooltip";
import { useContainersMetricsFetchers } from "./ContainerMetricsFetcher";
import { MetricsTimeSelector } from "./MetricsTimeSelector";
import { usePodMetricsWithZoom } from "./hooks/usePodMetricsWithZoom";

import { useMetricsTabContext } from "@/components/ResourceView/tabs/MetricsTab/context/useMetricsTabContext";
import { usePodContainersRequestStatusContext } from "@/components/ResourceView/tabs/MetricsTab/context/usePodContainersRequestStatusContext";

type PodContainersGraphProps = {
  uid: Uid;
  type: podContainersType;
  resource: Pod;
  containerStatuses: ContainerStatus[] | undefined;
  endTime: number;
  syncId?: string;
  graphProps?: GraphProps;
};
export const PodContainersGraph: React.FC<PodContainersGraphProps> = ({
  uid,
  type,
  resource,
  containerStatuses,
  endTime,
  syncId,
  graphProps,
}) => {
  const [isMetricsGraphEnlarged, setIsMetricsGraphEnlarged] = useState(false);
  const { timeWindow, podContainersNames } = useMetricsTabContext();
  const {
    label,
    ariaLabels,
    tooltipContent,
    podContainersMetric,
    title,
    statusData,
    linesContent,
  } = useMetricsContainersGraph(type);

  return (
    <>
      <GraphContainerWithStatus
        {...getCommonGraphContainerWithStatusProps(statusData)}
        uid={uid}
        title={title}
        onEnlargeGraph={() => setIsMetricsGraphEnlarged(true)}
      >
        <MetricsGraphContainer
          data={podContainersMetric}
          graphHeight={graphHeight}
          timelineTicksNum={timelineTicksNum}
          tooltipContent={tooltipContent}
          ariaLabels={ariaLabels}
          tickFormatter={
            type === "cpu"
              ? undefined
              : (tick) => {
                  const { value, formatOptions } = formatMemoryTick(tick);
                  return formatNumber(value, formatOptions);
                }
          }
          label={label}
          syncId={syncId}
          {...graphProps}
        >
          {linesContent}
        </MetricsGraphContainer>
      </GraphContainerWithStatus>
      {isMetricsGraphEnlarged && (
        <MetricsTabContextProvider
          givenPodContainersNames={podContainersNames}
          givenTimeWindow={timeWindow}
        >
          <EnlargedPodContainerGraph
            endTime={endTime}
            type={type}
            uid={uid}
            onClose={() => setIsMetricsGraphEnlarged(false)}
            resource={resource}
            containerStatuses={containerStatuses}
          />
        </MetricsTabContextProvider>
      )}
    </>
  );
};

const EnlargedPodContainerGraph = ({
  type,
  uid,
  onClose,
  resource,
  containerStatuses,
  endTime,
}: PodContainersGraphProps & { onClose: () => void }) => {
  const {
    title,
    podContainersMetric,
    linesContent,
    label,
    ariaLabels,
    tooltipContent,
    statusData,
  } = useMetricsContainersGraph(type);

  const metricsParams = useMetricParams(resource, endTime);

  const { fromEpoch, toEpoch, graphProps } = usePodMetricsWithZoom();

  const containersMetricsFetchers = useContainersMetricsFetchers(
    containerStatuses,
    { ...metricsParams, fromEpoch, toEpoch }
  );

  return (
    <>
      <EnlargedMetricsGraph
        uid={uid}
        onClose={onClose}
        title={title}
        hasGraphData={!statusData.loading && podContainersMetric.length > 0}
        error={statusData.error}
        loading={statusData.loading}
        controls={[<MetricsTimeSelector key="MetricsTimeSelector" />]}
      >
        <MetricsGraphContainer
          data={podContainersMetric}
          timelineTicksNum={timelineTicksNum}
          tooltipContent={tooltipContent}
          ariaLabels={ariaLabels}
          tickFormatter={
            type === "cpu"
              ? undefined
              : (tick) => {
                  const { value, formatOptions } = formatMemoryTick(tick);
                  return formatNumber(value, formatOptions);
                }
          }
          label={label}
          {...graphProps}
        >
          {linesContent}
        </MetricsGraphContainer>
      </EnlargedMetricsGraph>
      {containersMetricsFetchers}
    </>
  );
};

const useMetricsContainersGraph = (type: podContainersType) => {
  const { podContainersCpuUsage, podContainersMemoryUsage } =
    useMetricsTabContext();
  const { cpuStatus, memoryStatus } = usePodContainersRequestStatusContext();

  const podContainersMetric =
    type === "cpu" ? podContainersCpuUsage : podContainersMemoryUsage;
  const podContainerStatus = type === "cpu" ? cpuStatus : memoryStatus;

  const linesContent = useChartLinesFromContainerNames(type);

  const statusData = useMemo(() => {
    const hasData = podContainersMetric.length > 0;
    return generateMetricsResponse({
      hasData,
      loading: podContainerStatus.isLoading,
      error: podContainerStatus.hasError,
    });
  }, [
    podContainerStatus.hasError,
    podContainerStatus.isLoading,
    podContainersMetric.length,
  ]);

  const title = `${podContainerTypeToLabel(type)} Usage by container(s)`;

  let ariaLabels = AriaLabels.MetricsAvailability.MemoryGraph;
  let tooltipContent = PodContainersMemoryMetricsTooltip;
  let label = "MiB";

  if (type === "cpu") {
    ariaLabels = AriaLabels.MetricsAvailability.CpuGraph;
    tooltipContent = PodContainersCpuMetricsTooltip;
    label = "MilliCores";
  }
  return {
    title,
    statusData,
    linesContent,
    label,
    ariaLabels,
    tooltipContent,
    podContainersMetric,
  };
};
