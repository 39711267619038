import React, { useMemo } from "react";
import { orderBy } from "lodash";
import { useParams } from "react-router-dom";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeUnderPressureCheck from "../NodeUnderPressureCheck";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import Recommendations from "../../../../common/Recommendations";
import {
  GrayHighlight,
  Margin,
  NormalText,
  StyledFontTable,
  TableContainer,
} from "../../../../common/styles";
import { PodOutput } from "../NodeEvictedPodsCheck";
import ExpandedTableRow from "../../../../common/ExpandedTableRow";
import HiddenPodsInfo from "../../../../common/HiddenPodsInfo";

import {
  ContainersResourceTable,
  getPodTableCells,
} from "./NodeEvictedPodsDetails";

const PRESSURE_MEMORY_RECOMMENDATIONS = [
  "Configure pod resuorces and QOS",
  "Add additional node",
  "Replace to a node with more memory",
];
const info = {
  memory:
    "Low QoS pods that consume a large amount of memory might be evicted first based on their priority.",
  disk: "The kubelet uses the Priority and disk usage to determine the eviction order.",
  pid: "The kubelet uses the Priority to determine the eviction order.",
};
const PodRow: React.FC<{
  pod: PodOutput;
  showExpanded: boolean;
}> = ({ pod, showExpanded }) => {
  const cells = getPodTableCells(pod);
  return (
    <ExpandedTableRow
      key={pod.name}
      cells={cells}
      expandComponent={
        showExpanded ? (
          <>
            <Margin>
              <ContainersResourceTable pod={pod} />
            </Margin>
          </>
        ) : undefined
      }
    />
  );
};
const NodeUnderPressureDetails: React.FC<{
  check: NodeUnderPressureCheck;
}> = ({ check }) => {
  const checkOutput = check.output;
  const isMemoryPressure = checkOutput?.memoryPressureMessage?.length > 0;
  const isDiskPressure = checkOutput?.diskPressureMessage?.length > 0;
  const isPidPressure = checkOutput?.pidPressureMessage?.length > 0;

  const isPressure =
    checkOutput?.memoryPressureMessage ||
    checkOutput?.diskPressureMessage ||
    checkOutput?.pidPressureMessage ||
    null;
  const filteredPods = useMemo(
    () =>
      checkOutput?.pods?.length
        ? isMemoryPressure
          ? checkOutput?.pods?.filter((pod) => pod.qos !== "Guaranteed")
          : checkOutput?.pods
        : [],
    [checkOutput?.pods, isMemoryPressure]
  );
  const { serviceId } = useParams<{ serviceId: string }>();
  const orderedPods = useMemo(
    () =>
      serviceId
        ? orderBy(
            filteredPods,
            (p) => [p.serviceId === serviceId, "priority", "name"],
            ["desc", "desc", "asc"]
          )
        : orderBy(filteredPods, ["priority", "name"], ["desc", "asc"]),
    [filteredPods, serviceId]
  );
  return !check.passed ? (
    <>
      <br />
      {isPressure && (
        <>
          <BoldGrayText>Error message</BoldGrayText>
          <GrayHighlight>
            {checkOutput?.memoryPressureMessage ||
              checkOutput?.diskPressureMessage ||
              checkOutput?.pidPressureMessage}
          </GrayHighlight>
          <br /> <br />
          <NormalText>
            {isMemoryPressure
              ? info.memory
              : isDiskPressure
              ? info.disk
              : isPidPressure
              ? info.pid
              : null}
          </NormalText>
          <br />
        </>
      )}

      {!check.passed && filteredPods.length > 0 && (
        <>
          <BoldGrayText>
            {isMemoryPressure ? "Non-guaranteed QoS pods" : "Pods on the node"}
          </BoldGrayText>
          <TableContainer>
            <StyledFontTable>
              <thead>
                <tr>
                  <th />
                  <th>Pod name</th>
                  <th>QOS</th>
                  <th>PriorityClass</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {orderedPods.map((pod) => (
                  <PodRow key={pod.name} pod={pod} showExpanded />
                ))}
              </tbody>
            </StyledFontTable>
          </TableContainer>
        </>
      )}
      <br />
      <HiddenPodsInfo hiddenPodsCount={checkOutput?.hiddenPods} />
      {!check.passed &&
        filteredPods.length > 0 &&
        checkOutput?.memoryPressureMessage && (
          <>
            <br />
            <Recommendations list={PRESSURE_MEMORY_RECOMMENDATIONS} />
          </>
        )}
    </>
  ) : null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeUnderPressureDetails;
