import { TaskState } from "komodor-types";
import { useState, useMemo, useCallback, useEffect, useRef } from "react";

import {
  InsertAgentTaskMutationVariables,
  useGetAgentTaskStatusSubscription,
  useInsertAgentTaskMutation,
  useSetAgentTaskStateMutation,
} from "../../../generated/graphql";

const useAgentTaskExecution = (
  executeTaskVars: InsertAgentTaskMutationVariables | null,
  triggerManually?: boolean,
  setTriggerManually?: React.Dispatch<React.SetStateAction<boolean>>
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [{ taskId: string } | null, string, () => void, () => void] => {
  const [, requestAgent] = useInsertAgentTaskMutation();
  const [, updateAgentTaskState] = useSetAgentTaskStateMutation();
  const [taskId, setTaskId] = useState<string>();
  const callbackExecuted = useRef<boolean>(false);

  const executeTaskCallback = useCallback(() => {
    if (!executeTaskVars) return;
    (async () => {
      const res = (await requestAgent(executeTaskVars)).data
        ?.insert_agent_task_one?.id;
      setTaskId(res);
    })();
  }, [executeTaskVars, requestAgent]);

  const setTaskTimeoutCallback = useCallback(() => {
    (async () => {
      if (taskId) {
        await updateAgentTaskState({
          id: taskId,
          state: TaskState.TIMEOUT,
        });
      }
    })();
  }, [taskId, updateAgentTaskState]);

  useEffect(() => {
    if (!executeTaskVars) return;
    if (!callbackExecuted.current || triggerManually) {
      callbackExecuted.current = true;
      setTriggerManually?.(false);
      executeTaskCallback();
    }
  }, [
    callbackExecuted,
    executeTaskCallback,
    executeTaskVars,
    setTriggerManually,
    triggerManually,
  ]);

  const [{ data: agentTaskStatus }] = useGetAgentTaskStatusSubscription({
    variables: { taskId },
    pause: !taskId,
  });

  const fetchPayloadVars = useMemo(() => {
    if (!taskId || agentTaskStatus?.agent_task[0]?.state !== "completed") {
      return null;
    }
    return { taskId };
  }, [agentTaskStatus?.agent_task, taskId]);

  const failureMessage = useMemo(
    () => agentTaskStatus?.agent_task[0]?.failureMessage || "",
    [agentTaskStatus?.agent_task]
  );

  return [
    fetchPayloadVars,
    failureMessage,
    executeTaskCallback,
    setTaskTimeoutCallback,
  ];
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useAgentTaskExecution;
