/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from "react";
import yaml from "js-yaml";
import {
  Button,
  Divider,
  Input,
  OptionType,
  Select,
  Typography,
} from "@komodorio/design-system/deprecated";
import { Deploy, Loader16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import {
  ActionTypes,
  HelmChartSummary,
  HelmManifest,
  TaskType,
} from "komodor-types";
import { uniqBy } from "lodash";
import { ActionMetadataHelmChartInstall } from "komodor-types/build/entities/AgentTask";

import { WhiteContainer } from "../../../ResourceView/resourceStyles";
import ResponsiveLayout from "../../../common/ResponsiveLayout";
import { KubernetesHelmResource } from "../../inspectionConfiguration/supportedResourcesTypes/KubernetesHelmResource";
import { HelmRepoSearchResultObject } from "../../../Actions/buttons/helm/types";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { LinesLoader } from "../../../common/loaders/Line";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import HelmChart from "../helmChart";
import { StyledDrawer } from "../../../ResourceView/ResourceDrawer";
import {
  useCheckForAvailableCharts,
  useHelmChartSummaryToChart,
} from "../hooks/useHelmCharts";
import {
  getChartNameAndVersion,
  manifestToMap,
  removeCommentLines,
  taskResultToMessage,
} from "../utils";
import Komo from "../../../../shared/assets/happyKomo.svg?react";
import { EmptyState } from "../tabs/components/EmptyData";
import { ActionFiredMessage } from "../tabs/components/ActionFiredMessage";
import GenericManifestCompare from "../tabs/components/GenericManifestCompare";
import { Selectors } from "../tabs/ManifestsTab";
import { useAgentSupportSecretRedaction } from "../hooks/useAgentSupportSecretRedaction";

import { HelmErrorMessage } from "./HelmErrorMessage";
import {
  Content,
  LoaderContainer,
  MODAL_HEIGHT_PCT,
  ResourceContainer,
  StyledModal,
  Z_INDEX_ABOVE_MODAL,
} from "./styles";
import ValuesEditor from "./upgrade/ChartUpgradeEditor";
import HelmDrawerHeader from "./upgrade/ChartUpgradeHeader";

const FixedResourceContainer = styled(ResourceContainer)`
  overflow-y: hidden;
`;

const ScrollableDiv = styled.div`
  margin-top: 1rem;
  overflow-y: auto;
`;

export interface HelmChartUpgradeDrawerProps {
  allCharts: HelmRepoSearchResultObject[];
  chart: HelmChart;
  onClose: () => void;
  open: boolean;
}

export interface HelmChartUpgradeModalSummaryProps {
  chartSummary: HelmChartSummary;
  open: boolean;
  agentId: string;
  onClose: () => void;
}

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 1.5rem;
`;
const ButtonsContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  flex-direction: row;
  gap: 0.3rem;
`;
const Loader = styled(LinesLoader)`
  margin-block-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  height: 100%;
`;
const EDITOR_MARGIN_FROM_BOTTOM = "18rem";
const DIFF_MARGIN_FROM_BOTTOM = "14.1rem";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum DrawerType {
  ShowValues = "showValues",
  ShowDiff = "showDiff",
}

interface ChartUpgrade {
  chart: unknown;
  config: unknown;
  info: unknown;
  manifest: string;
  name: string;
  namespace: string;
  version: string;
}

const ChartUpgradeDrawerContent: React.FC<{
  allCharts: HelmRepoSearchResultObject[];
  chart: HelmChart;
  onClose: () => void;
  fullVH?: string;
  showErrors?: boolean;
}> = ({ allCharts, chart, onClose, fullVH, showErrors }) => {
  const [done, setDone] = useState(false);
  const [selectedRepo, setSelectedRepo] = useState<string>("");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [editor, setEditor] = useState<JSX.Element | null>(null);
  const [selectedVersionValues, setSelectedVersionValues] = useState("");
  const [drawerKind, setDrawerKind] = useState<string>(DrawerType.ShowValues);
  const [upgradeCommandSent, setUpgradeCommandSent] = useState(false);
  const [expectedUpgradeManifestString, setExpectedUpgradeManifestString] =
    useState<string>("");
  const [expectedUpgradeManifestError, setExpectedUpgradeManifestError] =
    useState<string>("");

  const agentId = useActiveAgent(chart?.cluster) ?? "";
  const shouldDecodeManifest = useAgentSupportSecretRedaction(chart?.cluster);

  const reposOptions: Array<OptionType<string>> = useMemo(() => {
    return uniqBy(allCharts, "name").map((r) => {
      return { label: r.name, value: r.name };
    });
  }, [allCharts]);

  useEffect(() => {
    setSelectedRepo(reposOptions[0]?.value ?? "");
  }, [reposOptions]);

  const versionOptions: Array<OptionType<string>> = useMemo(() => {
    return allCharts
      .filter((c) => c.name === selectedRepo)
      .map((c) => c.version)
      .map((r) => {
        return { label: r, value: r };
      });
  }, [allCharts, selectedRepo]);

  useEffect(() => {
    setSelectedVersion(versionOptions[0]?.value);
  }, [reposOptions, versionOptions]);

  useEffect(() => {
    if (selectedVersion && selectedRepo) {
      setEditor(
        <ValuesEditor
          agentId={agentId}
          chart={selectedRepo}
          chartVersion={selectedVersion}
          cluster={chart.cluster}
          onValueChange={(v) => setSelectedVersionValues(v)}
        />
      );
    }
  }, [agentId, chart.cluster, selectedRepo, selectedVersion]);

  const metadata: ActionMetadataHelmChartInstall = useMemo(() => {
    return {
      name: chart.name,
      chartName: selectedRepo,
      namespace: chart.namespace,
      values: removeCommentLines(selectedVersionValues),
      version: selectedVersion,
      cluster: chart.cluster,
      type: ActionTypes.InstallHelmChart,
    };
  }, [
    chart.cluster,
    chart.name,
    chart.namespace,
    selectedRepo,
    selectedVersion,
    selectedVersionValues,
  ]);

  const {
    execute: upgrade,
    result: upgradeResult,
    failureMessage,
    deniedObject,
    isFetching,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  const templateMetadata = {
    ...metadata,
    ...{ type: ActionTypes.HelmTemplate },
  };
  const {
    execute: calculateTemplate,
    result: calculateTemplateResult,
    isFetching: calculatingTemplate,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, templateMetadata);
  useEffect(() => {
    try {
      const manifest = (
        yaml.load(calculateTemplateResult as string) as ChartUpgrade
      )?.manifest;
      setExpectedUpgradeManifestString(manifest);
    } catch (e) {
      setExpectedUpgradeManifestError(calculateTemplateResult as string);
    }
  }, [calculateTemplateResult, chart.manifest]);

  useEffect(() => {
    if (upgradeCommandSent && !isFetching && !done) {
      setDone(true);
      const message = taskResultToMessage(
        upgradeResult as string,
        failureMessage,
        deniedObject
      );
      const isSuccess = (upgradeResult as string).includes("has been upgraded");
      chart.setLastActionResult({
        command: `Upgrade to version ${selectedVersion}`,
        success: isSuccess,
        message: message,
        timestamp: new Date(),
      });
      if (!(showErrors && !isSuccess)) {
        onClose();
      }
    }
  }, [
    chart,
    chart.name,
    onClose,
    done,
    showErrors,
    upgradeResult,
    selectedVersion,
    upgradeCommandSent,
    isFetching,
    failureMessage,
    deniedObject,
  ]);

  const newValue = useMemo(() => {
    const newValueYaml = yaml
      .loadAll(expectedUpgradeManifestString, null, { json: true })
      .filter((item) => (item as HelmManifest)?.metadata?.name);
    return manifestToMap(newValueYaml, !!shouldDecodeManifest);
  }, [expectedUpgradeManifestString, shouldDecodeManifest]);

  const oldValue = useMemo(() => {
    return manifestToMap(chart?.parsedManifests, !!shouldDecodeManifest);
  }, [chart?.parsedManifests, shouldDecodeManifest]);

  return (
    <Content aria-label="helm-upgrade">
      <WhiteContainer>
        <ResponsiveLayout>
          <HelmDrawerHeader
            title={
              drawerKind === DrawerType.ShowValues
                ? "Change version"
                : "Review changes"
            }
            icon={Deploy}
            onClose={onClose}
            labels={[
              { name: "type", value: KubernetesHelmResource.Kind },
              { name: "name", value: chart.name },
              { name: "cluster", value: chart.cluster },
              { name: "namespace", value: chart.namespace },
            ]}
          />
          <br />
          {drawerKind === DrawerType.ShowValues && (
            <SelectContainer>
              <Select
                label={"Repo"}
                onChange={(selected) => {
                  setSelectedRepo(selected.value);
                  setEditor(null);
                }}
                options={reposOptions}
                value={reposOptions.find((o) => o.value === selectedRepo)}
                listZIndex={Z_INDEX_ABOVE_MODAL}
                listMaxHeight={"20rem"}
                width="10rem"
              />
              <Input
                label={"Current version"}
                defaultValue={getChartNameAndVersion(chart.appVersion).version}
                readOnly
                width="7rem"
                disabled={true}
              />
              <Select
                label={"Target version"}
                onChange={(selected) => {
                  setSelectedVersion(selected.value);
                  setEditor(null);
                }}
                options={versionOptions}
                value={versionOptions.find((o) => o.value === selectedVersion)}
                listZIndex={Z_INDEX_ABOVE_MODAL}
                listMaxHeight={"20rem"}
                width="7rem"
              />
            </SelectContainer>
          )}
        </ResponsiveLayout>
        <Divider />
      </WhiteContainer>
      {drawerKind === DrawerType.ShowValues && (
        <ResponsiveLayout>
          <ResourceContainer
            marginFromBottom={EDITOR_MARGIN_FROM_BOTTOM}
            fullVh={fullVH ?? "100"}
          >
            {editor}
          </ResourceContainer>
        </ResponsiveLayout>
      )}
      {drawerKind === DrawerType.ShowDiff && (
        <ResponsiveLayout>
          <Content>
            <FixedResourceContainer
              marginFromBottom={DIFF_MARGIN_FROM_BOTTOM}
              fullVh={fullVH ?? "100"}
            >
              {expectedUpgradeManifestError !== "" ? (
                <EmptyState
                  text={`Could not calculate diff:  ${expectedUpgradeManifestError}`}
                />
              ) : newValue.size &&
                oldValue.size &&
                expectedUpgradeManifestError === "" ? (
                <>
                  <Selectors>
                    <Input
                      label={"From version"}
                      defaultValue={
                        getChartNameAndVersion(chart.appVersion).version
                      }
                      readOnly
                      width="7rem"
                      disabled={true}
                    />{" "}
                    <Input
                      label={"To version"}
                      defaultValue={selectedVersion}
                      readOnly
                      width="7rem"
                      disabled={true}
                    />{" "}
                  </Selectors>
                  <ScrollableDiv>
                    <GenericManifestCompare
                      oldValue={oldValue}
                      newValue={newValue}
                      showOnlyChanges={true}
                      oldTitle={"Current version"}
                      newTitle={"TBD"}
                    />
                  </ScrollableDiv>
                </>
              ) : (
                <Loader />
              )}
            </FixedResourceContainer>
          </Content>
        </ResponsiveLayout>
      )}
      <Divider />
      <WhiteContainer>
        <ResponsiveLayout>
          {drawerKind === DrawerType.ShowValues && (
            <ButtonsContainer>
              <Button
                size="small"
                variant="primary"
                disabled={!(selectedVersion && selectedRepo)}
                onClick={() => {
                  setExpectedUpgradeManifestString("");
                  setExpectedUpgradeManifestError("");
                  calculateTemplate();
                  setDrawerKind(DrawerType.ShowDiff);
                }}
              >
                Review changes
              </Button>
              <Button
                size="small"
                variant="secondary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </ButtonsContainer>
          )}
          {drawerKind === "showDiff" && (
            <ButtonsContainer>
              <Button
                size="small"
                variant="primary"
                disabled={calculatingTemplate}
                onClick={() => {
                  if (!isFetching) {
                    setUpgradeCommandSent(true);
                    upgrade();
                  }
                }}
              >
                {isFetching ? (
                  <Loader16 />
                ) : expectedUpgradeManifestError !== "" ? (
                  "Continue anyway"
                ) : (
                  "Upgrade"
                )}
              </Button>
              <Button
                size="small"
                variant="secondary"
                onClick={() => {
                  setExpectedUpgradeManifestString("");
                  setExpectedUpgradeManifestError("");
                  setDrawerKind(DrawerType.ShowValues);
                }}
              >
                Back
              </Button>
              <Button
                size="small"
                variant="secondary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              {isFetching && (
                <ActionFiredMessage
                  locationType="drawer"
                  isFetching={isFetching}
                />
              )}
              {showErrors &&
                done &&
                chart.lastActionResult?.success === false && (
                  <HelmErrorMessage result={chart.lastActionResult} />
                )}
            </ButtonsContainer>
          )}
        </ResponsiveLayout>
      </WhiteContainer>
    </Content>
  );
};

const ChartUpgradeDrawer: React.FC<HelmChartUpgradeDrawerProps> = ({
  allCharts,
  chart,
  onClose,
  open,
}) => {
  return (
    <StyledDrawer
      open={open}
      width={"60%"}
      onOverlayClick={onClose}
      zIndex={Number(Z_INDEX_ABOVE_MODAL)}
    >
      <ChartUpgradeDrawerContent
        allCharts={allCharts}
        chart={chart}
        onClose={onClose}
      />
    </StyledDrawer>
  );
};

export const ChartUpgradeModalForSummary: React.FC<
  HelmChartUpgradeModalSummaryProps
> = ({ chartSummary, open, agentId, onClose }) => {
  const { data: chart, isFetching } = useHelmChartSummaryToChart(
    chartSummary,
    agentId
  );

  const { availableCharts, isFetching: isFetchingAvailableCharts } =
    useCheckForAvailableCharts(chart);

  return (
    <StyledModal
      isOpen={open}
      width={"60%"}
      onRequestClose={onClose}
      height={"90%"}
    >
      {isFetching || isFetchingAvailableCharts ? (
        <LoaderContainer>
          <LinesLoader marginTop="0" />
          <Typography variant="title" size="medium">
            Checking for available versions...
          </Typography>
        </LoaderContainer>
      ) : !availableCharts?.length ? (
        <NoReposFound />
      ) : (
        <ChartUpgradeDrawerContent
          allCharts={availableCharts}
          chart={chart}
          onClose={onClose}
          fullVH={MODAL_HEIGHT_PCT}
          showErrors={true}
        />
      )}
    </StyledModal>
  );
};

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const NoReposFound: React.FC = () => {
  return (
    <EmptyStateContainer>
      <Komo />
      <Typography
        variant={"title"}
        data-e2e-selector="empty-state-no-repos-title"
      >
        There are no relevant Helm repositories for this chart.
      </Typography>
      <Typography data-e2e-selector="empty-state-no-repos-action">
        Connect your repositories to enable upgrading Helm charts directly from
        Komodor.
      </Typography>
    </EmptyStateContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ChartUpgradeDrawer;
