import { useMemo } from "react";

import {
  StyledOrderedList,
  TextContainer,
} from "../../../violationDrawerStyles";

import {
  CheckType,
  SinglePointOfFailureSupportingDataCorrelatedViolationsInner,
} from "@/generated/reliabilityApi";

export const getWhyDescription = () => (
  <TextContainer>
    Single Points of Failure (SPOFs) are critical vulnerabilities in your
    Kubernetes environment where the failure of a single component can cause the
    entire application to become unavailable. Ensuring redundancy and resilience
    in your deployment configurations is essential to prevent these failures and
    maintain high availability. This section outlines common SPOF issues and
    provides best practice solutions to address them.
  </TextContainer>
);

export const deploymentMissingReplicasText = `Make sure that the deployment has multiple replicas by increasing
            the number of replicas for your deployment. This can be done by
            modifying the spec.replicas field in your deployment YAML.`;

export const missingPdbText = `Define a PDB to ensure that a minimum number of pods are always
            available during disruptions.`;

export const missingTopologyText = `Define topology spread constraints to distribute pods across
            different nodes or zones, enhancing fault tolerance.`;

export const hpaMinAvailabilityText = `Increase the minimum number of replicas in the HPA configuration
            to ensure redundancy.`;

export const getCorrelatedViolationsText = (
  checkType: CheckType
): string | undefined => {
  switch (checkType) {
    case CheckType.DeploymentMissingReplicas:
      return deploymentMissingReplicasText;
    case CheckType.MissingPdb:
      return missingPdbText;
    case CheckType.MissingTopologySpreadConstraint:
      return missingTopologyText;
    case CheckType.HpaMinAvailability:
      return hpaMinAvailabilityText;
    default:
      return;
  }
};

export const useHowDescription = (
  correlatedViolations:
    | SinglePointOfFailureSupportingDataCorrelatedViolationsInner[]
    | undefined
) => {
  const textContent = useMemo(() => {
    if (!correlatedViolations) return null;
    if (correlatedViolations.length === 1)
      return (
        <>
          <br />
          {getCorrelatedViolationsText(correlatedViolations[0].type)}
        </>
      );

    return (
      <StyledOrderedList type="1">
        {correlatedViolations.map(({ id, type }) => (
          <li key={id}>
            <TextContainer>{getCorrelatedViolationsText(type)}</TextContainer>
          </li>
        ))}
      </StyledOrderedList>
    );
  }, [correlatedViolations]);

  return (
    <TextContainer>
      In a Kubernetes, addressing Single Points of Failure (SPOFs) involves
      leveraging Kubernetes' built-in features and best practices to ensure high
      availability, redundancy, and fault tolerance. Here’s how you can tackle
      SPOFs in Kubernetes:
      {textContent}
    </TextContainer>
  );
};
