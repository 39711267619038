import React from "react";

import { useActiveAgent } from "../../../../shared/hooks/useAgents";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Node from "../../resources/node";
import { KubernetesPodsResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPodsResource";
import { DescribeLoader } from "../DescribeTab/common/DescribeLoader";

import PodsContentAtm from "./PodsData";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";

interface IProps {
  resource: Node;
}

const overriddenHeaders = KubernetesPodsResource.Headers?.filter(
  (h) => h.name !== "Node"
);

export const NodePodsTab: React.FC<IProps> = ({ resource }) => {
  const agentId = useActiveAgent(resource.cluster);
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.Pods);

  if (!agentId) {
    return <DescribeLoader />;
  }

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <PodsContentAtm
        resource={resource}
        agentId={agentId}
        overridingColumns={overriddenHeaders}
      />
    </DatadogReportLoadingTimeContextProvider>
  );
};
