import { useMemo } from "react";
import { isEmpty } from "lodash";
import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import getK8sDiff, {
  K8sDiff,
} from "../../../EventGroup/deployEvent/getK8sDiff";
import getSpecsForDiff, {
  Specs,
} from "../../../EventGroup/deployEvent/getSpecsForDiff";
import K8sConfig, {
  prepareK8sConfig,
} from "../../../EventGroup/deployEvent/K8sConfig";
import { DEPLOY_EVENTS_ID } from "../../../../../shared/hooks/resources-api/requestResponseMaps";
import { useResourcesApiClient } from "../../../../../shared/hooks/resources-api/client/apiClient";
import {
  DeployEventsResponse,
  EventsApiApiV1DeploysEventsIdGetRequest,
  apiV1DeploysEventsIdGetUrl,
} from "../../../../../generated/resourcesApi";

const fetchDeployEventById = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1DeploysEventsIdGetRequest
): Promise<DeployEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1DeploysEventsIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

const useK8sData = (
  eventId: string | undefined
): {
  specsForDiff: Specs | null;
  k8sDiff: K8sDiff | undefined;
  k8sConfig: K8sConfig[] | undefined;
  isOldSpecEmpty: boolean | undefined;
  isFetching: boolean;
} => {
  const params: EventsApiApiV1DeploysEventsIdGetRequest = useMemo(() => {
    return {
      id: eventId ?? "",
      fields: ["kubernetesConfigChanges", "newSpec", "oldSpec"],
    };
  }, [eventId]);
  const apiClient = useResourcesApiClient();
  const { data, isFetching } = useQuery(
    [DEPLOY_EVENTS_ID, params],
    () => fetchDeployEventById(apiClient, params),
    {
      enabled: !!eventId,
    }
  );

  return useMemo(() => {
    if (!data?.data?.[0]) {
      return {
        specsForDiff: null,
        k8sDiff: undefined,
        k8sConfig: undefined,
        isOldSpecEmpty: undefined,
        isFetching,
      };
    }
    const {
      oldSpec: oldSpecAsString,
      newSpec: newSpecAsString,
      kubernetesConfigChanges,
    } = data.data?.[0] ?? {};
    const oldSpec: object = JSON.parse((oldSpecAsString ?? "").toString());
    const newSpec: object = JSON.parse((newSpecAsString ?? "").toString());
    const isOldSpecEmpty = isEmpty(oldSpec);
    const specsForDiff =
      oldSpec && newSpec ? getSpecsForDiff(oldSpec, newSpec) : null;
    const k8sDiff = specsForDiff
      ? getK8sDiff(specsForDiff.oldSpec, specsForDiff.newSpec)
      : undefined;
    const k8sConfig = kubernetesConfigChanges
      ? prepareK8sConfig(kubernetesConfigChanges)
      : undefined;
    return {
      specsForDiff,
      k8sDiff,
      k8sConfig,
      isOldSpecEmpty,
      isFetching,
    };
  }, [data?.data, isFetching]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useK8sData;
