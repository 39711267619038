import { CheckType } from "../../../generated/reliabilityApi";

export const checkTypeToDescription: Record<CheckType, string> = {
  noisyNeighbor: "Workloads that impact co-located workloads",
  underProvisionedWorkloads:
    "Workloads that consume more resources than allocated.",
  requestsLimitsRatio:
    "Workloads that are configured with a substantial gap between requests and limits.",
  throttledCPU:
    "Workloads with containers that reach their CPU limits (throttled) for an extended period of time.",
  HPAMax:
    "Horizontal Pod Autoscalers (HPA) that consistently reach its maximum replicas over time.",
  restartingContainers:
    "Workloads that suffer from frequent container restarts.",
  kubernetesEndOfLife:
    "Kubernetes version or release reaches the end of its official support.",
  deprecatedApis: "APIs that require migration to supported versions",
  //
  kubernetesVersionDeprecated:
    "APIs that require migration to supported versions",
  nodeTerminationAutoScaling:
    "Node terminations due to Autoscaling description",
  nodeTerminationSpotInstance:
    "Node terminations due to Spot Interruptions description",
  unschedulableTime: "Unschedulable time description",
  singlePointOfFailure: "Single point of failure",
  deploymentMissingReplicas: "Low number of replicas",
  missingPDB: "Missing pod disruption budget",
  missingTopologySpreadConstraint: "Missing topology spread constraints",
  HPAMinAvailability: "HPA min availability",
  missingHPA: "Missing HPA",
  readinessProbeMissing: "Readiness probe missing",
  livenessProbeMissing: "Liveness probe missing",
  cpuLimitsMissing: "CPU limits missing",
  memoryLimitsMissing: "Memory limits missing",
  cpuRequestsMissing: "CPU requests missing",
  memoryRequestsMissing: "Memory requests missing",
  priorityClassNotSet: "Priority class not set",
  certificateExpiration: "Certificate expiration",
  syntheticDegradedService: "Other degraded service",
  syntheticEOLDeprecationOutdated: "Other EOL deprecation outdated",
  syntheticNodePressure: "Other node pressure",
  cascadingFailure: "Cascading failure",
  failedCronJob: "Failed cron job", // TODO: Add description
  failedJob: "Failed job", // TODO: Add description
  unhealthyNode: "Unhealthy node", // TODO: Add description
  unhealthyPVC: "Unhealthy PVC", // TODO: Add description
  unhealthyService: "Unhealthy service", // TODO: Add description
  unhealthyWorkflow: "Unhealthy workflow", // TODO: Add description
  externalDNSNotSynced: "External DNS not synced",
  scaleDownImpact: "Scale down impact",
};
