import { useQuery } from "@tanstack/react-query";

import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import {
  apiV1SearchGetUrl,
  SearchResponse,
} from "../../../generated/searchApi/api";

import { useSearchApiClient } from "./client/apiClient";

const ROOT_URL = "/";

type SearchAPIGetParams = {
  query: string;
  workspaceId: string | undefined;
  options: SearchAPIGetParamsOptions;
};

type SearchAPIGetParamsOptions = {
  pause: boolean;
  staleTime: number;
  skipAppViewInHeaders?: boolean;
};

export const useSearchAPIGet = ({
  query,
  workspaceId,
  options,
}: SearchAPIGetParams) => {
  const { staleTime, skipAppViewInHeaders, pause } = options;
  const apiClient = useSearchApiClient(skipAppViewInHeaders);
  const res = useQuery(
    [ROOT_URL, query, workspaceId, skipAppViewInHeaders],
    async () => {
      const res = await apiClient.get<SearchResponse>(
        apiV1SearchGetUrl({ query }, apiClient.defaults.baseURL ?? "")
      );
      if (res.data.results.length === 0) {
        dispatchEvent(AnalyticEvents.GlobalSearch.NoResults, { query });
      }
      return res;
    },
    {
      enabled: !pause,
      staleTime: staleTime,
    }
  );
  return {
    loading: res.isLoading,
    error: res.error as string,
    data: res.data?.data,
  };
};
