import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useState, MouseEvent } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";

const EllipsisTypography = styled(Typography)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TextSpan = styled.span`
  color: ${muiColors.gray[800]};
`;

interface SmartTextsJoinProps {
  texts: string[];
  icon: React.ReactNode;
  ariaLabel?: string;
}
export const SmartTextsJoin: React.FC<SmartTextsJoinProps> = ({
  texts,
  icon,
  ariaLabel,
}) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (
      texts.length > 1 ||
      currentTarget.scrollWidth > currentTarget.clientWidth
    ) {
      setTooltipEnabled(true);
    }
  };
  const tooltipContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "4px 8px",
        rowGap: "4px",
      }}
      aria-label={ariaLabel}
    >
      {texts.map((text, index) => (
        <Box
          key={index}
          sx={{
            display: "grid",
            alignItems: "start",
            columnGap: "4px",
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
          }}
        >
          {icon}
          <Typography variant="body3">{text}</Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Tooltip
      open={tooltipEnabled}
      onClose={() => setTooltipEnabled(false)}
      componentsProps={lightMuiTooltipStyle}
      title={tooltipContent}
      placement="top"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          cursor: "default",
        }}
        onMouseEnter={handleShouldShow}
      >
        <EllipsisTypography variant="body2">{texts[0]}</EllipsisTypography>
        {texts.length > 1 && (
          <Typography variant="body2" color="text.secondary">
            <TextSpan>, </TextSpan>+{texts.length - 1}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};
