import { useCallback, useMemo, useState } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useGetUserEffectivePermissions } from "@/shared/hooks/auth-service/client/users/useGetUserEffectivePermissions";
import { EffectivePermission, User } from "@/generated/auth";
import { mapObjectsToOptions } from "@/shared/utils/selectInputOptions";

// mapObjectsToOptions returns a list of generic MuiSelectionOption objects, but eventually it will be returned like a MuiSelectionOption<string>[]
// This function is used to cast the return value of mapObjectsToOptions to MuiSelectionOption<string>[]
const getUniqueOptions = (
  data: EffectivePermission[],
  key: keyof EffectivePermission
) => mapObjectsToOptions(data, key) as MuiSelectionOption<string>[];

export const useEffectivePermissions = ({ user }: { user: User }) => {
  const { data, isFetching, error } = useGetUserEffectivePermissions(
    {
      id: user.id,
    },
    true,
    true
  );

  const [dataFilters, setDataFilters] = useState<{
    action: MuiSelectionOption<string>[];
    policyName: MuiSelectionOption<string>[];
    roleName: MuiSelectionOption<string>[];
    cluster: MuiSelectionOption<string>[];
    namespace: MuiSelectionOption<string>[];
  }>({
    action: [],
    policyName: [],
    roleName: [],
    cluster: [],
    namespace: [],
  });

  type DataFiltersKey = keyof typeof dataFilters;

  const setSelectedProperty = useCallback(
    (
      property: keyof typeof dataFilters,
      values: MuiSelectionOption<
        EffectivePermission[keyof EffectivePermission]
      >[]
    ) => {
      setDataFilters((prev) => ({
        ...prev,
        [property]: values,
      }));
    },
    [setDataFilters]
  );

  const filteredRows = useMemo(() => {
    if (!data?.length) {
      return [];
    }

    let filteredData: EffectivePermission[] = [...data];

    Object.keys(dataFilters).forEach((key: string) => {
      if (dataFilters[key as DataFiltersKey].length === 0) {
        return;
      }
      filteredData = filteredData.filter((row) =>
        dataFilters[key as DataFiltersKey].some(
          (filter) => filter.value === row[key as DataFiltersKey]
        )
      );
    });

    return filteredData;
  }, [dataFilters, data]);

  const {
    uniqueActionNamesOptions,
    uniquePolicyNamesOptions,
    uniqueRoleNamesOptions,
    uniqueClusterNamesOptions,
    uniqueNamespaceNamesOptions,
  } = useMemo(() => {
    if (!filteredRows?.length) {
      return {
        uniqueActionNamesOptions: [],
        uniquePolicyNamesOptions: [],
        uniqueRoleNamesOptions: [],
        uniqueClusterNamesOptions: [],
        uniqueNamespaceNamesOptions: [],
      };
    }
    return {
      uniqueActionNamesOptions: getUniqueOptions(filteredRows, "action"),
      uniquePolicyNamesOptions: getUniqueOptions(filteredRows, "policyName"),
      uniqueRoleNamesOptions: getUniqueOptions(filteredRows, "roleName"),
      uniqueClusterNamesOptions: getUniqueOptions(filteredRows, "cluster"),
      uniqueNamespaceNamesOptions: getUniqueOptions(filteredRows, "namespace"),
    };
  }, [filteredRows]);

  return {
    data,
    isFetching,
    error,
    uniqueActionNamesOptions,
    uniquePolicyNamesOptions,
    uniqueRoleNamesOptions,
    uniqueClusterNamesOptions,
    uniqueNamespaceNamesOptions,
    dataFilters,
    setSelectedProperty,
    filteredRows,
  };
};
