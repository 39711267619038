export const ariaLabels = {
  cani: {
    tab: "can-i tab",
    container: "can-i container",
    sections: {
      arguments: "can-i arguments section",
      results: "can-i results section",
    },
    form: {
      apiGroup: "can-i form apiGroup",
      resource: "can-i form resource",
      cluster: "can-i form cluster",
      namespace: "can-i form namespace",
      verb: "can-i form verb",
    },
  },
  effectivePermissions: {
    tab: "effective-permissions tab",
  },
};
