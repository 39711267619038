import { datadogRum } from "@datadog/browser-rum";

const originalStartView = datadogRum.startView;
const originalAddTiming = datadogRum.addTiming;
const originalAddAction = datadogRum.addAction;

(function () {
  datadogRum.startView = function (...args) {
    originalAddAction("startView", { args }); // temp for debugging
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return originalStartView.apply(this, args);
  };

  datadogRum.addTiming = function (...args) {
    originalAddAction("addTiming"); // temp for debugging
    return originalAddTiming.apply(this, args);
  };
})();
