import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

type NoServicesAddedStateProps = {
  withButton?: boolean;
  onButtonClick?: () => void;
};

export function NoServicesAddedState({
  withButton = false,
  onButtonClick,
}: NoServicesAddedStateProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Typography variant="body2">
        No services were added,
        <br />
        please add a service for comparison
      </Typography>
      {withButton && (
        <Button
          sx={{ flexGrow: 0 }}
          variant="contained"
          size="medium"
          onClick={onButtonClick}
        >
          Add service to compare
        </Button>
      )}
    </Box>
  );
}
