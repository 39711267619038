import { sub } from "date-fns";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { GridSortModel } from "@mui/x-data-grid-pro";
import {
  GridSortDirection,
  GridSortItem,
} from "@mui/x-data-grid/models/gridSortModel";

import { AuditMultipleValuesFilters, Unit } from "./types";

import { DEFAULT_PAGE_SIZE } from "@/pages/organization-settings/account/AuditPage/constants";

const UNITS = {
  h: "hours",
  d: "days",
};

export const parseTimeRange = (label: string) => {
  const regex = /(\d+)([a-z])/;
  const matches = label.match(regex);
  if (!matches) {
    return;
  }
  const [, value, unit] = matches;
  const unitToUse = UNITS[unit as Unit];
  return sub(new Date(), { [unitToUse]: parseInt(value) });
};

export const startTimeToApiFormat = (
  startTime: MuiSelectionOption<string> | undefined
) => {
  if (!startTime) {
    return;
  }
  return parseTimeRange(startTime.value)?.toISOString();
};

export const filterKeyToTitle: Record<AuditMultipleValuesFilters, string> = {
  operations: "Event Type",
  actions: "Action",
  entityTypes: "Resource Type",
  userIds: "User",
};

export const getMockRows = (count: number = DEFAULT_PAGE_SIZE) => {
  return Array.from({ length: count }, (_, index) => ({
    id: index,
    eventTime: new Date(),
    operation: "operation",
    action: "action",
    entityName: "entityName",
    entityType: "entityType",
    user: "user",
    status: "success",
    isMock: true,
  }));
};

/**
 * Converts the sorting model to a string that can be used in the API.
 *
 * DataGridPro uses GridSortModel to represent the sorting model
 * Which is an array of GridSortItem (field: string, sort: "asc" | "desc")
 *
 * The API expects a comma delimited string with the field name and the sort direction (field1,-field2)
 *
 * Example:
 * [{ field: 'event_time', sort: 'asc' }, { field: 'operation', sort: 'desc' }] => 'event_time,-operation'
 * @param model GridSortModel (array of GridSortItem)
 */
export const sortingModelToString = (model: GridSortModel) => {
  return model
    .map(({ field, sort }: GridSortItem) => {
      if (sort === "desc") {
        return `-${field}`;
      }
      return field;
    })
    .join(",");
};

/*
 * Converts the sorting string from the API to the sorting model that the DataGridPro uses.
 *
 * @param sort string with the format 'field1,-field2'
 */
export const sortingStringToModel = (sort: string) => {
  return sort.split(",").map((item) => {
    if (item.startsWith("-")) {
      return { field: item.slice(1), sort: "desc" as GridSortDirection };
    }
    return { field: item, sort: "asc" as GridSortDirection };
  });
};
