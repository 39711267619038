import {
  MonitorsResponse,
  MonitorResponse,
  MonitorsApiApiV1SearchGetRequest,
  MonitorApiApiV1TypeIdGetRequest,
  apiV1TypeIdGetUrl,
  apiV1SearchGetUrl,
  apiV1DeploysSearchGetUrl,
  DeploysResponse,
  DeploysApiApiV1DeploysSearchGetRequest,
} from "../../../generated/monitorsApi";

export const MONITORS_SEARCH_ENDPOINT = "/search";
export const MONITOR_ISSUE_ID_ENDPOINT = "/";

export const DEPLOYS_SEARCH_ENDPOINT = "/deploys/search";

export const EndpointRequestPathMap = {
  [MONITORS_SEARCH_ENDPOINT]: apiV1SearchGetUrl,
  [MONITOR_ISSUE_ID_ENDPOINT]: apiV1TypeIdGetUrl,
  [DEPLOYS_SEARCH_ENDPOINT]: apiV1DeploysSearchGetUrl,
};
export type MonitorParameters = MonitorsApiApiV1SearchGetRequest &
  MonitorApiApiV1TypeIdGetRequest &
  DeploysApiApiV1DeploysSearchGetRequest;

export type EndpointsResponseMap = {
  [MONITORS_SEARCH_ENDPOINT]: MonitorsResponse;
  [MONITOR_ISSUE_ID_ENDPOINT]: MonitorResponse;
  [DEPLOYS_SEARCH_ENDPOINT]: DeploysResponse;
};

export type EndpointsRequestMap = {
  [MONITORS_SEARCH_ENDPOINT]: MonitorsApiApiV1SearchGetRequest;
  [MONITOR_ISSUE_ID_ENDPOINT]: MonitorApiApiV1TypeIdGetRequest;
  [DEPLOYS_SEARCH_ENDPOINT]: DeploysApiApiV1DeploysSearchGetRequest;
};
