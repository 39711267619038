import { Props } from "recharts/types/component/Legend";
import { GridFilterItem } from "@mui/x-data-grid/models/gridFilterItem";

import {
  CostAllocationSummaryResponse,
  CostApiApiV1CostAllocationOverTimeGetRequest,
  AllocationCostDataPoints,
  CostApiApiV1CostRightSizingSummaryGetRequest,
  RightSizingCostSummaryResponse,
  RightSizingCostDataPoints,
  CostApiApiV1CostRightSizingOverTimeGetRequest,
  RightSizingCostSummaryByServiceResponse,
  CostAllocationStatsResponse,
  RightSizingCostStatsResponse,
  CostApiApiV1CostRightSizingRowsGetRequest,
} from "../../../generated/metricsApi";
import {
  GetConfigurationResponse,
  UpdateConfigurationRequest,
} from "../../../generated/komodorCost";
import { ClusterData } from "../../../shared/hooks/useGetAllClusters/useGetAllClusters";
import { Dictionary } from "../../../shared/types/Dictionary";

export type Response = {
  loading: boolean;
  error: string | null;
  refresh?: () => void;
};

export type CostOptimizationTab = "allocation" | "rightSizing" | "riskAnalysis";
export type AllocationFiltersScope = { clusters: ClusterData[] };
export type AllocationFiltersScopeInUrl = { clusters: string[] };
export type AllocationFiltersGroupBy =
  CostApiApiV1CostAllocationOverTimeGetRequest["groupBy"];
export type AllocationFiltersTimeWindow = "30d" | "14d" | "7d" | "24h";
export type RightSizingOrderBy =
  CostApiApiV1CostRightSizingRowsGetRequest["orderBy"];
export type RightSizingFilterBy =
  CostApiApiV1CostRightSizingRowsGetRequest["filterBy"];

export type CostStrategy =
  CostApiApiV1CostRightSizingSummaryGetRequest["strategy"];

export type CostTable = {
  filter: GridFilterItem | undefined;
};

export type AllocationFiltersState = {
  groupBy: AllocationFiltersGroupBy;
  timeWindow: AllocationFiltersTimeWindow;
};

export type RightSizingFiltersState = {
  strategy: CostStrategy;
};

export type SharedCostFiltersState = {
  scope: AllocationFiltersScope;
  table: CostTable;
};

export type ClustersEarliestRowTime = {
  clusters: Dictionary<string> | undefined;
};

export type AllocationSummaryState = {
  data?: CostAllocationSummaryResponse;
} & Response;

export type AllocationCostMetricsState = {
  data?: AllocationCostDataPoints;
} & Response;

export type RightSizingSummaryState = {
  data?: RightSizingCostSummaryResponse;
} & Response;

export type RightSizingCpuMetricsState = {
  data?: RightSizingCostDataPoints;
} & Response;

export type RightSizingMemoryMetricsState = {
  data?: RightSizingCostDataPoints;
} & Response;

export type RightSizingRecommendationsModalState = {
  rowId?: string;
};

export type RightSizingRecommendationsState = {
  data?: RightSizingCostSummaryByServiceResponse;
} & Response;

export type RightSizingCostMetricsState = {
  cpu: RightSizingCpuMetricsState;
  memory: RightSizingMemoryMetricsState;
};

export type RightSizingStatsState = {
  data?: RightSizingCostStatsResponse;
} & Response;

export type TokensMap = Record<number, string | undefined>;

export type RightSizingPaginatedTableState = {
  tokens?: TokensMap;
  pageSize: number;
  pageIndex: number;
  orderBy?: RightSizingOrderBy;
  filterBy?: RightSizingFilterBy;
} & Response;

export type MetricType =
  CostApiApiV1CostRightSizingOverTimeGetRequest["metricType"];

export type NumberFormatType = "currency" | "percentage";

type SharedCostFilterStateInUrl = Omit<SharedCostFiltersState, "scope"> & {
  scope: AllocationFiltersScopeInUrl;
};

export type CostOptimizationPersistentState = {
  tab: CostOptimizationTab;
  sharedFilters: Partial<SharedCostFilterStateInUrl>;
  allocationFilters: Partial<AllocationFiltersState>;
  optimizationStrategy: CostStrategy;
};

export type CostOptimizationConfig = {
  monthlyCpuCost: number;
  monthlyMemoryCost: number;
  // allocateIdleResources: boolean;
  // monthlySharedOverhead: number;
};

export type CostOptimizationConfigEntry = UpdateConfigurationRequest;

export type CostOptimizationConfigResponse = {
  data?: GetConfigurationResponse;
};

export type AllocationCostStatsState = {
  data?: CostAllocationStatsResponse;
} & Response;

export type CostOptimizationConfigFetchState = CostOptimizationConfigResponse &
  Response;
export type CostOptimizationConfigUpdateState = Response;

export type CostOptimizationConfigState = {
  fetchState: CostOptimizationConfigFetchState;
  updateState: CostOptimizationConfigUpdateState;
};

export type RightSizingRecommendationsStrategiesState = Record<
  string,
  CostStrategy
>;

export type ConfigFormInputError = "empty" | "invalid";
export type ConfigFormValidationState = Record<
  keyof CostOptimizationConfig,
  { error?: ConfigFormInputError }
>;

export type CostsChartsLegend = {
  hide: Dictionary<boolean>;
  setHide: (key: string) => void;
  props: Props;
};

export enum CostAllocationStatsResponseField {
  totalCost = "totalCost",
  totalOptimizationScore = "totalOptimizationScore",
  totalPotentialSaving = "totalPotentialSaving",
  totalUnallocatedCost = "totalUnallocatedCost",
}

export type OverviewCardDataKey = keyof Pick<
  CostAllocationStatsResponse,
  | CostAllocationStatsResponseField.totalCost
  | CostAllocationStatsResponseField.totalOptimizationScore
  | CostAllocationStatsResponseField.totalPotentialSaving
>;

export type OverviewCardDetails = keyof Pick<
  CostAllocationStatsResponse,
  CostAllocationStatsResponseField.totalUnallocatedCost
>;
