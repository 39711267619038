import React, { useCallback, useEffect } from "react";
import { OptionType } from "@komodorio/design-system/deprecated";

import { SelectorTypes, SinksOptions } from "../../common/types";
import { RuleStepSectionContainer } from "../common/RuleStepSectionContainer";
import SinkSection from "../common/SinkSection";
import ScopeSection from "../common/ScopeSection";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NotifyOnCategories from "../common/SinkSection/NotifyOnCategories";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AvailabilityTriggersSection } from "./AvailabilityTriggersSections";

import {
  ConfigurationSensor,
  ConfigurationVariables,
  HealthCategories,
  MonitorConfiguration,
  MonitorType,
} from "@/generated/monitorsApi";
export const All_CATEGORIES_CHAR = "*";
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const ALL_CATEGORIES = [HealthCategories.Star];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const AvailabilityCategories: OptionType<HealthCategories>[] = [
  {
    label: "All Categories",
    value: HealthCategories.Star,
  },
  {
    label: "NonZeroExitCode",
    value: HealthCategories.NonZeroExitCode,
  },
  {
    label: "Unhealthy - failed probes",
    value: HealthCategories.UnhealthyFailedProbes,
  },
  {
    label: "OOMKilled",
    value: HealthCategories.OomKilled,
  },
  {
    label: "Creating/Initializing",
    value: HealthCategories.CreatingInitializing,
  },
  {
    label: "BackOff",
    value: HealthCategories.BackOff,
  },
  {
    label: "Infrastructure",
    value: HealthCategories.Infrastructure,
  },
  {
    label: "Scheduling",
    value: HealthCategories.Scheduling,
  },
  { label: "Image", value: HealthCategories.Image },
  {
    label: "Volume/Secret/ConfigMap",
    value: HealthCategories.VolumeSecretConfigMap,
  },
  {
    label: "Container Creation",
    value: HealthCategories.ContainerCreation,
  },
  {
    label: "Pod Termination",
    value: HealthCategories.PodTermination,
  },
  {
    label: "Completed",
    value: HealthCategories.Completed,
  },
  { label: "Other", value: HealthCategories.Other },
];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const availabilityDefaultRule: MonitorConfiguration = {
  id: "",
  name: "",
  type: MonitorType.Availability,
  active: true,
  sensors: [{ cluster: "" }],
  sinksOptions: {
    notifyOn: ALL_CATEGORIES,
  },
  variables: {
    duration: 30,
    minAvailable: "100%",
    categories: ALL_CATEGORIES,
  },
};

const AvailabilityRuleSections: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleSensorChange = useCallback(
    (sensor: ConfigurationSensor) => {
      setRule((prev) => ({
        ...prev,
        sensors: [sensor],
      }));
    },
    [setRule]
  );
  const handleVariablesChange = useCallback(
    (variables: ConfigurationVariables) => {
      setRule((prev) => ({
        ...prev,
        variables,
      }));
    },
    [setRule]
  );

  const handleNotifyOnChange = useCallback(
    (sinksOptions: SinksOptions) => {
      setRule((prev) => ({
        ...prev,
        sinksOptions,
      }));
    },
    [setRule]
  );

  useEffect(() => {
    if (!rule?.variables?.categories) {
      handleVariablesChange({
        ...rule?.variables,
        categories: ALL_CATEGORIES,
      });
    }
  }, [handleVariablesChange, rule?.variables]);

  return (
    <>
      <RuleStepSectionContainer sectionNumber={2} title="Trigger conditions">
        <AvailabilityTriggersSection
          id="availability-conditions"
          variables={rule.variables}
          handleChange={handleVariablesChange}
        />
      </RuleStepSectionContainer>
      {rule.sensors && rule.sensors.length > 0 && (
        <RuleStepSectionContainer sectionNumber={3} title="Select Scope:">
          <ScopeSection
            id="scope"
            sensor={rule.sensors[0]}
            selectorTypes={SelectorTypes}
            handleChange={handleSensorChange}
          />
        </RuleStepSectionContainer>
      )}
      {!isElectronContext() && (
        <RuleStepSectionContainer
          sectionNumber={4}
          title="Where do you want to receive notifications? (Optional)"
        >
          <NotifyOnCategories rule={rule} handleChange={handleNotifyOnChange} />
          <SinkSection rule={rule} setRule={setRule} />
        </RuleStepSectionContainer>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default AvailabilityRuleSections;
