import React, { useEffect, useMemo } from "react";
import { muiColors, theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { noop } from "lodash";

import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { issueSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { ProcessList } from "../../../common/ProcessList";
import { ProcessListLoader } from "../../../common/ProcessList/ProcessListLoader";
import { groupNodeChangeEvents } from "../../../common/EventGroup/nodeEvent/NodeChangeGroup";
import { SYSTEM_WIDE_SERVICE_ID } from "../../../../shared/constants/systemWideServiceId";
import { EVENT_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";
import { Timeframe } from "../../../../shared/types/TimeWindow";
import { useNodeEventsByIds } from "../../../common/EventGroup/nodeEvent/useNodeChangeEvents";
import { useNodeIssuesByIdsFromAPI } from "../../../common/EventGroup/workflowIssues/useWorkflowIssues";
import { groupWorkflowIssuesEvents } from "../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useScrollTracking } from "../common/useScrollTracking";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const defaultTimeWindow = {
  start: new Date(),
  end: new Date(),
  timeframe: Timeframe.Custom,
};

const NodeIssuesComponent: React.FC = () => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const issue = useInvestigationModeStore(issueSelector);
  const { nextStep, goToNextStep } = useNextStep();
  const [eventId, setEventId] = useStateInSearchParams(EVENT_PARAM_KEY);

  const { nodeIssuesIds, nodeTerminationsIds } = useMemo(() => {
    const { nodeIssues, nodeTerminations } =
      issue?.results.nodeIssues.output ?? {};
    return {
      nodeIssuesIds: nodeIssues?.map((nodeIssue) => nodeIssue.id) ?? [],
      nodeTerminationsIds:
        nodeTerminations?.map((nodeTermination) => nodeTermination.id) ?? [],
    };
  }, [issue]);

  const [nodeIssues, isFetchingNodeIssues] =
    useNodeIssuesByIdsFromAPI(nodeIssuesIds);
  const nodeIssuesEventGroups = groupWorkflowIssuesEvents(
    nodeIssues.flatMap((e) => e),
    SYSTEM_WIDE_SERVICE_ID
  );
  const [nodeTerminations, isFetchingNodeTerminations] =
    useNodeEventsByIds(nodeTerminationsIds);
  const nodeTerminationEventGroups = groupNodeChangeEvents(
    nodeTerminations.flatMap((e) => e),
    SYSTEM_WIDE_SERVICE_ID
  );

  const eventGroups = [...nodeIssuesEventGroups, ...nodeTerminationEventGroups];
  const elementRef = useScrollTracking<HTMLDivElement>();

  useEffect(() => {
    reportLoadingState(
      "nodeIssuesData",
      !issue || isFetchingNodeIssues || isFetchingNodeTerminations
    );
  }, [
    isFetchingNodeIssues,
    isFetchingNodeTerminations,
    issue,
    reportLoadingState,
  ]);

  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          Review correlated node events
        </Typography>
        <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
          We found {eventGroups.length} Node events that occurred within 10
          minutes of the issue
        </Typography>
      </StepHeader>
      <StepContent ref={elementRef}>
        {eventGroups.length > 0 ? (
          <ProcessList
            highlightedEvents={[]}
            showServiceColumn={false}
            eventGroups={eventGroups}
            eventId={eventId}
            setEventId={setEventId}
            setIntentId={noop}
            timeWindow={defaultTimeWindow}
            setTimeWindow={noop}
          />
        ) : (
          <ProcessListLoader />
        )}
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.NodeEventsStep.SkipStepButton
            }
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};

export const NodeIssues: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.investigationModeNodeIssues,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <NodeIssuesComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
