import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

export const toOptions = (values: string[]): MuiSelectionOption<string>[] =>
  values.map((value) => ({
    label: value,
    value: value,
  }));

export const fromOptions = (options: MuiSelectionOption<string>[]): string[] =>
  options.map((option) => option.value);

export function getUniqueValues<T>(array: T[], property: keyof T): T[] {
  const uniqueMap = new Map(array.map((item) => [item[property], item]));
  return Array.from(uniqueMap.values());
}

export function mapObjectsToOptions<T>(
  array: T[],
  property: keyof T
): { label: T[keyof T]; value: T[keyof T] }[] {
  return getUniqueValues(array, property)
    .filter((item) => item[property] !== undefined)
    .map((item) => ({
      label: item[property],
      value: item[property],
    }));
}
