import { useCallback } from "react";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

type OpenReliabilityDrawerProps = {
  impactGroupId: string;
  clusters?: string[];
  checkTypes?: string[];
  checkCategory?: string[];
};

export const useOpenReliabilityDrawer = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useCallback(
    ({
      impactGroupId,
      clusters,
      checkTypes,
      checkCategory,
    }: OpenReliabilityDrawerProps) => {
      pushDrawer({
        drawerType: DrawerType.ViolationsDrawerByScope,
        scope: {
          ...(clusters ? { clusterName: clusters } : {}),
          impactGroupId: [impactGroupId],
          checkType: checkTypes,
          checkCategory,
        },
        additionalConfig: {
          paginationModel: {
            page: 0,
            pageSize: 10,
          },
          showImpactAggregations: true,
          showGroupSeverities: true,
        },
      });
    },
    [pushDrawer]
  );
};
