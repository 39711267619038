import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import DeletedPod, {
  DELETED_POD_KIND,
  DeletedPodType,
} from "@/components/ResourceView/resources/deletedPod";
import { Dictionary } from "@/shared/types/Dictionary";
import useParsedResource from "@/shared/hooks/resources/useParsedResource";
import { useGetDeletedPodsState } from "@/shared/hooks/resources-api/client/useGetDeletedPodsState";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import { KubernetesPodsResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const useGetDeletedPod = ({
  clusterName,
  namespace,
  podName,
  fromEpoch,
  toEpoch,
  status,
  labels,
  enabled,
}: {
  clusterName: string;
  namespace: string;
  podName: string;
  fromEpoch?: string;
  toEpoch?: string;
  status?: string;
  labels?: Record<string, string>;
  enabled?: boolean;
}): DeletedPod | undefined => {
  const agentId = useActiveAgent(clusterName);

  const { data } = useGetDeletedPodsState(
    {
      clusterName,
      fromEpoch,
      toEpoch,
      komodorUids: [
        buildKomodorUid({
          kind: KubernetesPodsResource.Kind,
          clusterName,
          namespace,
          resourceName: podName,
        }),
      ],
      labels: labels ? JSON.stringify(labels) : undefined,
      limit: 1,
    },
    { enabled }
  );
  const deletedPodData = data?.data?.[0];
  const podStatus = (deletedPodData?.status as unknown as string) ?? "";
  const deletedPod: DeletedPodType = {
    age: "",
    id: "",
    namespace: namespace,
    name: deletedPodData?.podName ?? "",
    metadata: (deletedPodData?.metadata ?? {}) as Dictionary<unknown>,
    status: podStatus,
    spec: (deletedPodData?.spec ?? {}) as Dictionary<unknown>,
    node: (deletedPodData?.nodeName ?? "") as string,
    controlledBy: (deletedPodData?.resourceOwnerName ?? "") as string,
    containerStatuses: (deletedPodData?.containerStatuses ??
      []) as Array<unknown>,
  };

  return useParsedResource(
    deletedPod,
    DELETED_POD_KIND,
    clusterName,
    agentId ?? "",
    status ?? podStatus
  ) as DeletedPod | undefined;
};
