import React from "react";
import styled from "styled-components";

import EventDetailsSection from "../components/EventDetailsSection";
import teamsIcon from "../../../../integrations/management/logos/teams.svg";

import { StaticHeight } from "./SlackSection";

const Channel = styled.span`
  font-weight: bold;
  margin-inline-start: 0.2rem;
`;

interface TeamsSectionProps {
  sentToChannels: string[] | null;
}

const TeamsSection: React.FC<TeamsSectionProps> = ({ sentToChannels }) => {
  return (
    <EventDetailsSection icon={teamsIcon} title="Teams">
      <StaticHeight>
        {sentToChannels && sentToChannels.length > 0 && (
          <>
            Notification sent on
            {sentToChannels.map((ch) => (
              <Channel key={ch}>{ch}</Channel>
            ))}
          </>
        )}
      </StaticHeight>
    </EventDetailsSection>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default TeamsSection;
