import EventGroup from "../../common/EventGroup";
import WorkflowIssueEventGroup from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { WorkflowConfigType } from "../../monitorsView/common/types";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default function* selectAvailaiblityReasons(
  g: EventGroup
): Iterable<string> {
  if (
    !(g instanceof WorkflowIssueEventGroup) ||
    g.workflowType !== WorkflowConfigType.Availability
  ) {
    return;
  }
  for (const reason of (g as WorkflowIssueEventGroup).reasons) {
    yield reason;
  }
}

export function* selectStandalonePodReasons(g: EventGroup): Iterable<string> {
  if (
    !(g instanceof WorkflowIssueEventGroup) ||
    g.workflowType !== WorkflowConfigType.Workflow
  ) {
    return;
  }
  for (const reason of (g as WorkflowIssueEventGroup).reasons) {
    yield reason;
  }
}
