import React, { useMemo, useCallback } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components";

import { gray5 } from "../../../Colors";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";

import { EventsChartParams } from "./types";
import { getTickComponent } from "./ticks";
import useTicks from "./ticks/useTicks";
import useZoom from "./useZoom";
import LineChartTooltip from "./LineChartTooltip";
import useLineChartEvents, { LineDot } from "./useLineChartEvents";
import DotElement from "./DotElement";
import { tickTextProps } from "./ticks/tickUtils";

const Container = styled.div`
  position: relative;
  z-index: 15;
  height: 15rem;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
`;

// This fixes the issue where the tooltip has a blue border on Chrome.
// See https://github.com/recharts/recharts/issues/2920#issuecomment-1206113558
const tooltipOutlineFix = { outline: "none" };

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getYTicks = (
  dataMax: number,
  count = 5,
  ratio = 1.1
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): number[] => {
  const interval = Math.ceil((dataMax * ratio) / count);
  return Array.from({ length: count }, (_, i) => (i + 1) * interval);
};

const chartMargin = { top: 15, left: -25, bottom: 7, right: 30 };

export const EventsLineChart: React.FC<EventsChartParams> = ({
  eventGroups,
  timeWindow: { start, end },
  setTimeWindow,
}: EventsChartParams) => {
  const onZoomChange = useCallback(
    (startTime: number, endTime: number) => {
      setTimeWindow({
        start: new Date(startTime),
        end: new Date(endTime),
      });
      dispatchEvent(AnalyticEvents.Charts.Linechart.Zoom_Change, {
        page: window.location.pathname,
      });
    },
    [setTimeWindow]
  );
  const { onMouseDown, onMouseMove, onMouseUp, zoom } = useZoom(onZoomChange);
  const data = useLineChartEvents(eventGroups, start, end);
  const colors = useMemo(
    () => Array.from(new Set(data.flatMap((d) => Object.keys(d.events)))),
    [data]
  );
  const ticks = useTicks(start, end, 13);
  const yTicks = useMemo(() => {
    const dataMax = Math.max(
      ...data.flatMap((d) => Object.values(d.events).map((eg) => eg.length))
    );
    return getYTicks(dataMax);
  }, [data]);

  const TickComponent = getTickComponent(start, end);
  return (
    <Container>
      <AutoSizer>
        {({ width, height }) => (
          <LineChart
            data={data}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            margin={chartMargin}
            width={width}
            height={height}
          >
            <CartesianGrid vertical={false} horizontal stroke={gray5} />
            <XAxis
              dataKey="x"
              domain={["auto", "auto"]}
              tick={<TickComponent />}
              interval={0}
              type="number"
              scale="linear"
              ticks={ticks}
            />
            <YAxis
              dataKey={(d: LineDot) =>
                Math.max(0, ...Object.values(d.events).map((e) => e.length))
              }
              type="number"
              domain={[0, yTicks[yTicks.length - 1]]}
              ticks={yTicks}
              tick={tickTextProps}
            />
            {colors.map((color) => (
              <Line
                key={color}
                dataKey={(d: LineDot) => d.events[color]?.length ?? 0}
                fill="white"
                stroke={color}
                strokeWidth={2.5}
                r={4}
                dot={DotElement}
                isAnimationActive={true}
                animationDuration={300}
              />
            ))}
            <Tooltip
              wrapperStyle={tooltipOutlineFix}
              isAnimationActive={false}
              content={LineChartTooltip}
            />
            {zoom}
          </LineChart>
        )}
      </AutoSizer>
    </Container>
  );
};
