import { useMemo } from "react";
import { get } from "lodash";

import { KubernetesPodsResource } from "../../../components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useLabelSelector } from "../../utils/podUtils";
import { useActiveAgent } from "../useAgents";

import useAtmResourcesListLiveResult, {
  ListResourcesResult,
} from "./useAtmResourcesListLiveResult";

import Resource from "@/components/ResourceView/resources";

type GetPodAtmResourcesParams = {
  namespace?: string;
  agentId?: string;
  cluster?: string;
  resource?: Resource;
};

export const useGetPodAtmResources = ({
  resource,
  namespace,
  cluster,
  agentId,
}: GetPodAtmResourcesParams): ListResourcesResult => {
  const selector = get(resource, "selector");
  const labelSelector = useLabelSelector(selector);

  const namespaces = useMemo(
    () =>
      namespace
        ? [namespace]
        : resource?.namespace
        ? [resource?.namespace]
        : undefined,
    [resource?.namespace, namespace]
  );

  const currentCluster = useMemo(
    () => cluster ?? resource?.cluster ?? "",
    [cluster, resource?.cluster]
  );
  const activeAgent = useActiveAgent(currentCluster) ?? "";

  return useAtmResourcesListLiveResult({
    agentId: agentId ?? activeAgent,
    cluster: currentCluster,
    namespaces,
    labelSelector,
    resourceKind: KubernetesPodsResource.Kind,
  });
};
