import React from "react";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { AuditLogsContextProvider } from "@/pages/organization-settings/account/AuditPage/context/AuditLogsContext";
import { AuditPageContent } from "@/pages/organization-settings/account/AuditPage/AuditPageContent";
import { AccountUsersContextProvider } from "@/shared/context/users/AccountUsersContext";

export const AuditPage = () => {
  return (
    <SettingsViewVerticalLayout title="Audit">
      <AuditLogsContextProvider>
        <AccountUsersContextProvider>
          <AuditPageContent />
        </AccountUsersContextProvider>
      </AuditLogsContextProvider>
    </SettingsViewVerticalLayout>
  );
};
