import React, { PropsWithChildren, useLayoutEffect, useRef } from "react";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useGetCurrentTab } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useGetCurrentTab";
import { ViolationDrawerValue } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerTypes";

type ContentContainerProps = PropsWithChildren<{
  ariaLabel?: string;
}>;

export const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  ariaLabel,
}) => {
  const { triggerStartView, resetLoadingState } =
    useDatadogReportLoadingTimeContext();
  const currentTab = useGetCurrentTab();
  const lastTabReported = useRef<ViolationDrawerValue | undefined>(undefined);

  useLayoutEffect(() => {
    if (currentTab !== lastTabReported.current) {
      lastTabReported.current && resetLoadingState();
      triggerStartView();
      lastTabReported.current = currentTab;
    }
  }, [triggerStartView, resetLoadingState, currentTab]);

  return <div aria-label={ariaLabel}>{children}</div>;
};
