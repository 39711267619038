import styled from "styled-components";
import { muiColors } from "themes";
import React from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: ${muiColors.gray[50]};
  gap: 32px;
`;

export const MetadataView: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return <Container>{children}</Container>;
};
