import { maxBy, minBy } from "lodash";
import { useEffect, useMemo } from "react";

import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import EventGroup from "../common/EventGroup";
import {
  TimeWindow,
  SetTimeWindow,
  Timeframe,
} from "../../shared/types/TimeWindow";
import { EVENTS_VIEW_SHRINK_TIMELINE_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { POD_EVENT_TYPE } from "../common/EventGroup/nativePodEvent/utils";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

const FIT_POD_EVENTS = "fitPods";
const FIT_MARGIN = 5 * 60 * 1000; //  5 minutes

const shrinkByPods = (
  podEvents: EventGroup[],
  timeWindow: TimeWindow
): TimeWindow | null => {
  const maxEventsTime = new Date(
    (maxBy(podEvents, (e) => e.endTime)?.endTime ?? timeWindow.end).getTime() +
      FIT_MARGIN
  );

  const minEventsTime = new Date(
    (
      minBy(podEvents, (e) => e.startTime)?.startTime ?? timeWindow.start
    ).getTime() - FIT_MARGIN
  );

  const timeWindowHasChanged =
    minEventsTime > timeWindow.start || maxEventsTime < timeWindow.end;

  return timeWindowHasChanged
    ? {
        start:
          minEventsTime > timeWindow.start ? minEventsTime : timeWindow.start,
        end: maxEventsTime < timeWindow.end ? maxEventsTime : timeWindow.end,
        timeframe: Timeframe.Custom,
      }
    : null;
};

const useShrinkTimeLine = (
  events: EventGroup[],
  timeWindow: TimeWindow,
  setTimeWindow: SetTimeWindow
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): void => {
  const [fitType, setFitType] = useStateInSearchParams(
    EVENTS_VIEW_SHRINK_TIMELINE_PARAM_KEY
  );
  const { shrinkTimelineKeyword } = useOverridableFlags();

  const podEvents = useMemo(() => {
    return events.filter((event) => event.type === POD_EVENT_TYPE);
  }, [events]);

  const fittedTimeWindow: TimeWindow | null = useMemo(() => {
    return shrinkTimelineKeyword &&
      fitType === FIT_POD_EVENTS &&
      podEvents.length
      ? shrinkByPods(podEvents, timeWindow)
      : null;
  }, [fitType, podEvents, shrinkTimelineKeyword, timeWindow]);

  useEffect(() => {
    if (shrinkTimelineKeyword) {
      if (fittedTimeWindow) {
        setTimeWindow(fittedTimeWindow);
      }
      if (podEvents.length) setFitType(null);
    }
  }, [
    podEvents.length,
    fittedTimeWindow,
    setFitType,
    setTimeWindow,
    shrinkTimelineKeyword,
  ]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useShrinkTimeLine;
