import { useQueries, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { useWorkspaceQueryKey } from "../workspaces-api/useWorkspaceQueryKey";

import { QueryOptions, useK8sAddonsApiClient } from "./apiClient";

import {
  AddonsApiGetEntityRequest,
  EntityResourceResponse,
  getEntityUrl,
} from "@/generated/addonsApi";
import { useAppViewsStore } from "@/shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "@/shared/store/appViewsStore/appViewStoreSelectors";
import { getReactQueryOptions } from "@/shared/utils/react-query/getReactQueryOptions";

export const GET_ADDONS_FULL_LIVE_STATE =
  "/api/v1/addons/:addon/:entity/:name/:uid";

export const useGetAddonFullLiveState = <T>(
  params: AddonsApiGetEntityRequest,
  select: (json: AxiosResponse<EntityResourceResponse, unknown>) => T,
  queryOptions?: QueryOptions
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const apiClient = useK8sAddonsApiClient();

  let url = "";
  try {
    url = getEntityUrl(params, apiClient.defaults.baseURL ?? "");
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  const keys = useWorkspaceQueryKey([url, params]);

  return useQuery(
    keys,
    async () =>
      await apiClient.post<T>(
        getEntityUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.getEntityRequest }
      ),
    {
      select: select as (json: unknown) => T,
      ...(queryOptions || {}),
      enabled: queryOptions?.enabled && !!url,
    }
  );
};

export const useGetAddonsFullLiveState = <T>(
  paramsList: AddonsApiGetEntityRequest[],
  select: (json: AxiosResponse<EntityResourceResponse, unknown>) => T,
  queryOptions?: QueryOptions
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const apiClient = useK8sAddonsApiClient();

  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);

  let url = "";
  try {
    url = getEntityUrl(paramsList[0], apiClient.defaults.baseURL ?? "");
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useQueries({
    queries: paramsList.map((params) => ({
      queryKey: [url, params, selectedAppViewId],
      queryFn: async () =>
        await apiClient.post<T>(
          getEntityUrl(params, apiClient.defaults.baseURL ?? ""),
          { ...params.getEntityRequest }
        ),
      select: select as (json: unknown) => T,
      queryOptions: {
        ...getReactQueryOptions(queryOptions, url),
      },
    })),
  });
};
