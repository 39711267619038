import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  Button,
  ModalContent,
  ModalHeader,
  Tag,
  Typography,
} from "@komodorio/design-system/deprecated";

import Divider from "../../../monitorsView/common/Divider";
import { LinesLoader } from "../../../common/loaders/Line";
import UpgradeAgentInstruction from "../../../common/upgradeAgentCta/UpgradeAgentInstruction";
import upgradeEmojiUrl from "../../../common/upgradeAgentCta/upgrade.svg";
import { useDateFormatter } from "../../../../shared/hooks/useDateFormatter";
import { useGetContainerImages } from "../../../ResourceView/headers/utils/hooks/useGetContainerImages";
import { useGetImageLabel } from "../../../ResourceView/headers/utils/hooks/useGetImageLabel";

import {
  ServiceDescribeFailed,
  ServiceDescribeSuccess,
} from "./ServiceDescribeFailedAndSuccess";
import { RefreshButton } from "./RefreshButton";
import {
  CalculatedProps,
  DescribeModalContentProps,
} from "./DescribeModal.types";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const UPGRADE_AGENT_SUGGESTION = `Upgrade the agent to enjoy the new describe view`;

const Tags = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-block-start: 1rem;
`;

const StyledModalContent = styled(ModalContent)`
  overflow-y: auto;
  height: inherit;
`;

const CustomUpgradeModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin: 1rem auto auto auto;
  width: auto;
  padding: 1rem;
  gap: 1rem;
  background-color: white;
`;

const CustomHeadline = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 140.3%;
  text-align: center;
  color: #6e7a96;
  width: 24rem;
`;

export const DescribeContent: React.FC<
  DescribeModalContentProps & CalculatedProps
> = ({
  resourceType,
  cluster,
  namespace,
  resourceName,
  lastDeployEndDate,
  resourceId,
  refreshCallback,
  isAgentAbleToSendTasks,
  closeModalCallback,
  serviceDescribeRes,
  retries,
  maxRetries,
  failureMessage,
  isDoneFetching,
}) => {
  const { format } = useDateFormatter();
  const title = `${resourceType} Describe: ${cluster}/${namespace}/${resourceName}`;
  const lastUpdated = useMemo(() => {
    if (!lastDeployEndDate) {
      return null;
    }
    return format(lastDeployEndDate);
  }, [format, lastDeployEndDate]);

  const images = useGetContainerImages({
    serviceId: resourceId,
  });
  const imageLabel = useGetImageLabel(images);

  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();
  const mainContainerRef = useRef<HTMLDivElement>(null);
  setMainContainerDivRef(mainContainerRef);
  useEffect(() => {
    reportLoadingState(`DescribeModal`, !isDoneFetching);
  }, [reportLoadingState, isDoneFetching]);

  return (
    <div ref={mainContainerRef}>
      <ModalHeader>
        <RefreshButton onClick={refreshCallback} />
        <Typography variant="headline">{title}</Typography>
        <Tags>
          {imageLabel !== undefined && <Tag>{imageLabel.value}</Tag>}
          {lastUpdated && (
            <Tag>
              <Typography>
                Last updated: <b>{lastUpdated}</b>{" "}
              </Typography>
            </Tag>
          )}
        </Tags>
      </ModalHeader>
      <Divider />
      <StyledModalContent>
        {!isAgentAbleToSendTasks ? (
          <CustomUpgradeModal>
            <img src={upgradeEmojiUrl} />
            <CustomHeadline>{UPGRADE_AGENT_SUGGESTION}</CustomHeadline>
            <UpgradeAgentInstruction />
            <Button variant="secondary" onClick={closeModalCallback}>
              Done!
            </Button>
          </CustomUpgradeModal>
        ) : !serviceDescribeRes && retries < maxRetries ? (
          <LinesLoader />
        ) : failureMessage || retries >= maxRetries ? (
          <ServiceDescribeFailed
            failureMessage={failureMessage}
            serviceId={resourceId}
            serviceType={resourceType}
          />
        ) : !isDoneFetching ? (
          <LinesLoader />
        ) : (
          <ServiceDescribeSuccess
            serviceDescribeRes={serviceDescribeRes}
            serviceId={resourceId}
            serviceType={resourceType}
          />
        )}
      </StyledModalContent>
    </div>
  );
};
