import { useQuery } from "@tanstack/react-query";

import {
  WorkloadReplicasStatusResponse,
  MetricsApiGetWorkloadReplicasStatusRequest,
  getWorkloadReplicasStatusUrl,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

export const GET_WORKLOADS_REPLICAS_STATUS =
  "/api/v1/workloads/:komodorUid/replicas/status";

type GetWorkloadsReplicasStatusMetricsParams = {
  params: MetricsApiGetWorkloadReplicasStatusRequest;
  enabled?: boolean;
};

export const useGetWorkloadsReplicasStatusMetrics = ({
  params,
  enabled,
}: GetWorkloadsReplicasStatusMetricsParams) => {
  const apiClient = useMetricsApiClient();
  let url = "";
  try {
    url = getWorkloadReplicasStatusUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    );
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useQuery(
    [GET_WORKLOADS_REPLICAS_STATUS, params],
    async () => await apiClient.get<WorkloadReplicasStatusResponse>(url),
    { enabled: enabled && !!url }
  );
};
