import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";

import { MONITOR_CONFIGURATION_BY_ID_PATH } from "./useGetMonitorConfigurationById";

import {
  apiV1ConfigurationsIdPutUrl,
  ConfigurationsApiApiV1ConfigurationsIdPutRequest,
  MonitorConfiguration,
} from "@/generated/monitorsApi";

const updateMonitorConfigurationById = async (
  apiClient: AxiosInstance,
  params: ConfigurationsApiApiV1ConfigurationsIdPutRequest
) => {
  const { data } = await apiClient.put<MonitorConfiguration>(
    apiV1ConfigurationsIdPutUrl(params, apiClient.defaults.baseURL ?? ""),
    params.monitorConfigurationPostRequestBody
  );

  return data;
};

export const usePutMonitorConfigurationById = () => {
  const apiClient = useMonitorsApiClient();
  return useMutation(
    [MONITOR_CONFIGURATION_BY_ID_PATH],
    async (params: ConfigurationsApiApiV1ConfigurationsIdPutRequest) =>
      updateMonitorConfigurationById(apiClient, params)
  );
};
