import React, { useEffect, useMemo, useState } from "react";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import PencilIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { CIDRWhitelist } from "@/generated/accounts/api";
import { Column } from "@/components/common/table/types";
import DataTable from "@/components/common/table/DataTable";
import {
  ActionsCol,
  Buttons,
  Filters,
  Header,
} from "@/components/Settings/styles";
import { ValidationsProvider } from "@/shared/context/ValidationsProvider";
import CIDRCreateModal from "@/components/Settings/whitelistCIDR/Form/WhitelistCreateModal";
import { VerificationDeleteDialog } from "@/components/Settings/whitelistCIDR/VerificationDeleteDialog";
import {
  useDeleteCIDRs,
  useGetCIDRs,
} from "@/shared/hooks/accounts-service/client/cidrWhitelist/useCIDR";
import CIDREditModal from "@/components/Settings/whitelistCIDR/Form/WhitelistEditModal";
import { whitelistAriaLabels } from "@/components/Settings/whitelistCIDR/whitelistAriaLabels";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const cidrDescOrValueMatchSearch = (term: string) => {
  return (c: CIDRWhitelist) => {
    return (
      c.cidr.toLowerCase().includes(term.toLowerCase()) ||
      c.description?.toLowerCase().includes(term.toLowerCase())
    );
  };
};

const Container = styled.div`
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  font-size: 0.75rem;
  border-radius: 4px;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WhitelistTabView = () => {
  const [search, setSearch] = useState("");
  const [openAddCIDR, setOpenAddCIDR] = useState(false);
  const [openEditCIDR, setOpenEditCIDR] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [focusCIDR, setFocusCIDR] = useState<CIDRWhitelist | null>(null);
  const { data: ipCIDRs, refetch, isFetching } = useGetCIDRs();
  const { mutateAsync: deleteCIDR } = useDeleteCIDRs();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isFetchingIpWhitelist", isFetching);
  }, [isFetching, reportLoadingState]);

  const filteredCIDR = useMemo((): CIDRWhitelist[] => {
    if (!ipCIDRs) return [];
    const lowerCaseFilter = search.toLowerCase();
    return ipCIDRs.filter(cidrDescOrValueMatchSearch(lowerCaseFilter));
  }, [ipCIDRs, search]);

  const removeCIDRGroup = async () => {
    if (!focusCIDR) return;
    await deleteCIDR({ id: focusCIDR.id });
    await refetch();
    setAlertDialogOpen(false);
  };

  const columns: Column<CIDRWhitelist>[] = [
    {
      name: "CIDR",
      selector: (c: CIDRWhitelist) => c.cidr,
    },
    {
      name: "Description",
      selector: (c: CIDRWhitelist) => c.description,
    },
    {
      name: "",
      selector: (c): React.ReactNode => (
        <ActionsCol>
          <IconButton
            size={"small"}
            onClick={() => {
              setFocusCIDR(c);
              setOpenEditCIDR(true);
            }}
          >
            <PencilIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setFocusCIDR(c);
              setAlertDialogOpen(true);
            }}
          >
            <DeleteIcon color={"error"} fontSize="inherit" />
          </IconButton>
        </ActionsCol>
      ),
    },
  ];

  return (
    <SettingsViewVerticalLayout title={"IP Whitelist"}>
      <Header>
        <Filters>
          <SearchField
            size="medium"
            width="15rem"
            placeholder="Search"
            value={search}
            showCloseIcon={true}
            ariaLabel="Search CIDRs"
            onSearch={(value: string) => setSearch(value)}
          />
        </Filters>
        <Buttons>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<AddIcon />}
            onClick={() => setOpenAddCIDR(true)}
            aria-label={whitelistAriaLabels.addCIDR}
          >
            Add CIDR
          </Button>
        </Buttons>
      </Header>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      {filteredCIDR.length !== 0 ? (
        <DataTable
          aria-label={whitelistAriaLabels.tableCIDR}
          data={filteredCIDR}
          columns={columns}
          getRowId={(c: CIDRWhitelist) => c.id}
          highlightedRows
        />
      ) : (
        <Container aria-label={whitelistAriaLabels.emptyTablePlaceholder}>
          <Typography variant={"body2"} color={"text.secondary"}>
            No CIDR found.
          </Typography>
        </Container>
      )}
      <VerificationDeleteDialog
        open={alertDialogOpen}
        onClose={() => setAlertDialogOpen(false)}
        onConfirm={() => removeCIDRGroup()}
      />
      <ValidationsProvider>
        <CIDREditModal
          open={openEditCIDR}
          cidr={focusCIDR}
          key={focusCIDR?.id}
          onClose={() => setOpenEditCIDR(false)}
          refreshCIDRs={() => refetch()}
        />

        <CIDRCreateModal
          open={openAddCIDR}
          handleClose={() => setOpenAddCIDR(false)}
          refreshCIDRs={() => refetch()}
        />
      </ValidationsProvider>
    </SettingsViewVerticalLayout>
  );
};

export const WhitelistTab = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.accountSettingsIPWhitelistPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <WhitelistTabView />
    </DatadogReportLoadingTimeContextProvider>
  );
};
