export const getPageLabel = (
  itemOffset: number,
  pageSize: number,
  listSize: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): string => {
  const from = listSize === 0 ? 0 : itemOffset + 1;
  const to = Math.min(itemOffset + pageSize, listSize);
  return `Displaying ${from}-${to} of
        ${listSize} items`;
};
