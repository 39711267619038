import {
  AvailabilityIssuesResponse,
  SumByDayDataSumByDayInner,
} from "../../../../../../../generated/applicationInsights";
import { getBucketTimeFrame } from "../../../components/OverviewPage/utils/overviewPageUtils";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { getDateIntervals, getTotalDays } from "./testUtils";
import { mockKomodorServices } from "./mockKomodorServices";

const generateMockServices = (daysToSimulate: number, randomize: boolean) => {
  const services = mockKomodorServices(25);

  return services.map((service) => {
    const maxEventsPerDay = Math.round(Math.random() * 10);
    return {
      ...service,
      countPerDay: Array.from({ length: daysToSimulate }).map((_, idx) => {
        if (randomize) {
          return Math.round(Math.random() * maxEventsPerDay);
        }
        return idx === 0 ? 0 : maxEventsPerDay;
      }),
    };
  });
};

const calculateSomeByDay = (
  mockServices: ReturnType<typeof generateMockServices>,
  intervals: number[]
) => {
  return Array.from({ length: intervals.length }).map((_, i) => ({
    ts: intervals[i],
    value: mockServices.reduce((acc, curr) => acc + curr.countPerDay[i], 0),
  }));
};

const calculateAffectedServices = (
  mockServices: ReturnType<typeof generateMockServices>
) => {
  return mockServices.map((service) => ({
    count: service.countPerDay.reduce((acc, curr) => acc + curr, 0),
    komodorServiceId: service.komodorServiceId,
    cluster: service.cluster,
    komodorServiceName: service.komodorServiceName,
    namespace: service.namespace,
  }));
};

type AdjustedSumByDayParams = {
  currentSumByDay: SumByDayDataSumByDayInner[];
  daysWithNoData: number;
};

type AdjustedSumByDayOutput = {
  sumByDay: SumByDayDataSumByDayInner[];
  totalIssues: number;
};

export const getAdjustedSumByDay = ({
  currentSumByDay,
  daysWithNoData,
}: AdjustedSumByDayParams) => {
  return [...currentSumByDay].reduce<AdjustedSumByDayOutput>(
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
    (acc, curr, idx) => {
      if (idx > daysWithNoData) {
        acc.totalIssues += curr.value;
      }
      acc.sumByDay.push({
        ts: curr.ts,
        value: idx > daysWithNoData ? curr.value : 0,
      });
      return acc;
    },
    {
      sumByDay: [],
      totalIssues: 0,
    }
  );
};

type GenerateMockAvailabilityIssuesParams = {
  fromEpoch: number;
  toEpoch: number;
  randomize?: boolean;
};

export const generateMockAvailabilityIssuesTrends = ({
  fromEpoch,
  toEpoch,
  randomize = true,
}: GenerateMockAvailabilityIssuesParams): AvailabilityIssuesResponse => {
  const totalDays = getTotalDays(fromEpoch, toEpoch);
  const mockServices = generateMockServices(totalDays, randomize);
  const intervals = getDateIntervals({
    intervalsNum: totalDays,
    endTime: toEpoch,
    bucketTimeFrame: getBucketTimeFrame(),
  });
  const sumByDay = calculateSomeByDay(mockServices, intervals);
  const affectedServices = calculateAffectedServices(mockServices);

  return {
    sumByDay,
    affectedServices,
    bucketTimeFrame: "24h",
  };
};
