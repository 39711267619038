import { Column, Row, TableData } from "./types";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line import/no-default-export, max-params
export default function useDataTable<T>(
  data: TableData<T>[],
  columns: Column<T>[],
  getRowId: (row: T) => string | number
): { headers: string[]; rows: Row[] } {
  const headers = columns.map((c) => c.name);
  const rows = data.map((e) => {
    const rowId = getRowId(e);
    return {
      id: rowId,
      cells: columns.map((c) => ({
        id: `${rowId}-${c.name}`,
        cell: c.selector(e),
      })),
      disabled: !!e.disabled,
    };
  });
  return { headers, rows };
}
