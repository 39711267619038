import React, { useMemo } from "react";
import {
  Client as UrqlClient,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  Provider as UrqlProvider,
  subscriptionExchange,
} from "urql";
import { SubscriptionClient } from "subscriptions-transport-ws";

import { getAppConfig } from "../config/appConfig";
import {
  DDError,
  ExceptionMetadataKeys,
  getDatadogReplayUrl,
  notifyDDError,
} from "../hooks/exceptionManagement";

const urlForScheme = (scheme: string) =>
  `${scheme}${getAppConfig().useHttps ? "s" : ""}://${
    getAppConfig().serverUrl
  }`;

const createUrqlClient = (accessToken: string | null): UrqlClient => {
  const headers = accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : undefined;

  const notifyToExceptionManager = (
    e: DDError,
    operationCopy: Record<string, unknown>,
    query: string | undefined
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
  ) => {
    if (e.name === "GraphQLError" && !e.message.includes("JWTExpired")) {
      e.dd_fingerprint = `graphql:${e.name}:${e.message}:${query}`;
    } else {
      e.dd_fingerprint = `graphql:${e.name}:${e.message}`;
    }

    // do not notify for unauthorized errors
    if (e.message.includes("Unauthorized")) {
      return;
    }

    notifyDDError(e as Error, {
      operation: { ...operationCopy, error: e },
      [ExceptionMetadataKeys.Datadog]: {
        sessionReplayURL: getDatadogReplayUrl(),
      },
    });
  };

  const subscriptionClient = new SubscriptionClient(urlForScheme("ws"), {
    reconnect: true,
    connectionParams: { headers },
  });
  return createClient({
    url: urlForScheme("http"),
    fetchOptions: {
      headers,
      mode: "cors",
      cache: "no-store",
      credentials: "omit",
    },
    exchanges: [
      dedupExchange,
      errorExchange({
        onError(error, operation) {
          const query = operation.query.loc?.source.body;
          const operationCopy = {
            ...operation,
            context: { ...operation.context, fetchOptions: null },
            query,
          };
          error.graphQLErrors.forEach((e) => {
            notifyToExceptionManager(e, operationCopy, query);
            if (getAppConfig().env === "development") {
              // eslint-disable-next-line no-console
              console.log("Graphql Error: ", { error: e });
            }
          });
          if (error.networkError) {
            notifyToExceptionManager(error.networkError, operationCopy, query);
            if (getAppConfig().env === "development") {
              // eslint-disable-next-line no-console
              console.log(
                "Network error: " + JSON.stringify(error.networkError)
              );
            }
          }
        },
      }),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(operation) {
          return subscriptionClient.request(operation);
        },
      }),
    ],
  });
};

const UrqlProviderAuthWrapper: React.FC<{
  accessToken: string | null;
  children?: React.ReactNode;
}> = ({ accessToken, children }) => {
  const client = useMemo(() => createUrqlClient(accessToken), [accessToken]);
  return <UrqlProvider value={client}>{children}</UrqlProvider>;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default UrqlProviderAuthWrapper;
