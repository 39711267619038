import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { last, sum } from "lodash";
import { muiColors } from "@komodorio/design-system";

import { LinesLoader } from "../../loaders/Line";
import { greenBrand } from "../../../../Colors";
import {
  PodLogLine,
  PodLogsMode,
} from "../../../../shared/hooks/podLogs/types";
import { LogEvent, LogsType } from "../logsTypes";
import { useLogsViewerStore } from "../store/logsViewerStore";
import { selectViewSettings } from "../store/logsViewerStoreSelectors";

import PodMessage from "./PodMessage";
import getFormattedErrorMessage from "./getFormattedErrorMessage";
import { useMapEventToLogs } from "./hooks/useMapEventToLogs";
import { MessageContainer } from "./MessageContainer";
import { LogsList } from "./LogsList";
import { useOnContainerResizeWidth } from "./hooks/useContainerResizeWidth";
import { useFullyHighlightedLines } from "./hooks/useFullyHighlightedLines";
import { useHighlightedLines } from "./hooks/useHighlightedLines";

const LOG_PADDING = 16;

export const LogsContainer = styled.div<{
  disableTextWrapping: boolean;
  maxHeight?: string;
}>`
  padding: 0.5rem 0.7rem;
  background-color: ${muiColors.gray[800]};
  color: white;
  overflow: hidden;
  scroll-behavior: smooth;
  border-radius: 4px;
  flex-grow: 1;
  position: relative;

  ${({ disableTextWrapping }) =>
    disableTextWrapping ? "white-space: pre;" : ""}
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : "")}
`;

interface BlackTextProps {
  logs: PodLogLine[];
  failureMessage: string | null;
  loading?: boolean;
  searchTerm?: string;
  setSearchResults?: React.Dispatch<React.SetStateAction<number>>;
  scrollIndex?: number;
  onLoadMoreClicked?: () => void;
  containerName?: string;
  logsType?: LogsType;
  events?: LogEvent[];
  mode: PodLogsMode;
}

export const LogsBlackText: React.FC<BlackTextProps> = ({
  logs,
  failureMessage,
  loading = false,
  searchTerm,
  setSearchResults,
  scrollIndex,
  onLoadMoreClicked,
  containerName,
  logsType = LogsType.Current,
  events,
  mode,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerWidth = useOnContainerResizeWidth(ref);
  const [moreLogsLoading, setMoreLogsLoading] = useState(false);
  const [totalProcessedLogs, setTotalProcessedLogs] = useState(0);
  const [newLogsIndicator, setNewLogsIndicator] = useState(-1);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const rectHeight = ref.current?.getBoundingClientRect().height;

    if (rectHeight && !height) {
      setHeight(() => rectHeight - LOG_PADDING);
    }
  }, [height]);

  const { wrapText } = useLogsViewerStore(selectViewSettings);
  const highlightedLines = useHighlightedLines({ logs, searchTerm });

  useEffect(() => {
    if (logs.length > totalProcessedLogs) {
      setTotalProcessedLogs(logs.length);
    }
    setMoreLogsLoading(false);
  }, [logs, totalProcessedLogs]);

  const messageMatches = useMemo(() => {
    return sum(highlightedLines.map((line) => line.matchesInLine));
  }, [highlightedLines]);

  useEffect(() => {
    if (setSearchResults) {
      setSearchResults(messageMatches);
    }
  }, [messageMatches, setSearchResults]);

  const cumulativeMatchCount = useMemo(() => {
    const cmc: number[] = [];
    highlightedLines.forEach((line) => {
      cmc.push((last(cmc) ?? 0) + line.matchesInLine);
    });
    return cmc;
  }, [highlightedLines]);

  const fullyHighlightedLines = useFullyHighlightedLines({
    scrollIndex,
    highlightedLines,
    cumulativeMatchCount,
  });

  const indexToScrollTo = useMemo(() => {
    return cumulativeMatchCount.findIndex((count) => count === scrollIndex);
  }, [cumulativeMatchCount, scrollIndex]);

  const eventDictionary = useMapEventToLogs(logs, events);
  let content = (
    <MessageContainer>
      <LinesLoader />
    </MessageContainer>
  );

  if (logs.length > 0) {
    content = (
      <LogsList
        logs={fullyHighlightedLines}
        newLogsIndicator={newLogsIndicator}
        eventDictionary={eventDictionary}
        hasFailureMessage={!!failureMessage}
        mode={mode}
        indexToScrollTo={indexToScrollTo}
        containerWidth={containerWidth}
        moreLogsLoading={moreLogsLoading}
        containerHeight={height}
        onLoadMoreClicked={
          onLoadMoreClicked
            ? () => {
                setMoreLogsLoading(true);
                onLoadMoreClicked();
                setNewLogsIndicator(logs.length);
              }
            : undefined
        }
      />
    );
  }

  if (!logs.length && (failureMessage || !loading)) {
    const formattedErrorMessage = getFormattedErrorMessage(
      failureMessage,
      containerName
    );
    let icon, title, message;
    if (formattedErrorMessage) {
      [icon, title, message] = [
        null,
        "Logs are not available",
        formattedErrorMessage,
      ];
    } else if (failureMessage) {
      [icon, title, message] = [null, "Logs can't be loaded", failureMessage];
    } else {
      [icon, title, message] = [
        null,
        "Log data does not exist",
        logsType === LogsType.Issue
          ? "Logs are saved for up to 72 hours after the issue"
          : "",
      ];
    }
    content = (
      <PodMessage
        icon={icon}
        title={title}
        message={message}
        iconColor={greenBrand}
        data-e2e-selector={"podLogsError"}
      />
    );
  }

  return (
    <LogsContainer disableTextWrapping={!wrapText} ref={ref}>
      {content}
    </LogsContainer>
  );
};
