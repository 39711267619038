import React from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material";

import { selectSize, defaultSelectSize } from "../../../constants/index";
import { muiTheme } from "../../../themes/theme";
import { Label } from "../Select/shared/components/Label";

const Root = styled.div``;

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    background: ${muiTheme.palette.common.white};
    font-size: 14px;
  }
`;

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

export function TextInput({ label, className, ...rest }: TextFieldProps) {
  return (
    <Root className={className}>
      <Label value={label} />
      <StyledTextField
        {...rest}
        sx={{
          ...rest.sx,
          "& .MuiFormHelperText-root": {
            marginLeft: "0",
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            fontSize: "14px",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: 1,
            },
          "& .MuiInputBase-root": {
            height: `${selectSize[rest.size ?? defaultSelectSize]}px`,
          },
        }}
      />
    </Root>
  );
}
