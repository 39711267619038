import React from "react";
import { Pod as PodType } from "kubernetes-types/core/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Pod as PodIcon } from "@komodorio/design-system/icons";
import { compact } from "lodash";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodDescribeTab from "../tabs/DescribeTab/pod/PodDescribeTab";
import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { MetricsTab } from "../tabs/MetricsTab/MetricsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PodEventsTab } from "../tabs/EventsTab/PodEventsTab/PodEventsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import LogsTab from "../tabs/LogsTab/LogsTab";
// eslint-disable-next-line import/no-cycle
import { IssueBannersForPod } from "../banners/pod/IssueBannersForPod";
import { StatusTag } from "../tabs/DescribeTab/common/StatusTag";
import { podStatusMapper } from "../tabs/DescribeTab/common/PodStatusMapper";
import { PodAiInvestigationTab } from "../tabs/PodAiInvestigationTab/PodAiInvestigationTab";

import Resource, { ResourceTabName } from ".";

import { features } from "@/components/Settings/General/hooks/features";

enum DefaultTab {
  Details = 0,
  RootCauseAnalysis = 1,
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class Pod implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly icon;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly resourceVersion;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.RootCauseAnalysis },
    { label: ResourceTabName.Phases },
    { label: ResourceTabName.Logs },
    { label: ResourceTabName.Metrics },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [
    AvailableActions.ExecPodShell,
    AvailableActions.Describe,
    AvailableActions.Delete,
  ];
  readonly containerStatuses;
  readonly controlledBy;
  readonly fullObj;
  readonly calculatedStatus;
  readonly isPodReadyOrCompleted;
  readonly isRCAShown;

  readonly featureFlags: Record<string, unknown>;
  readonly accountFeatures: Record<string, unknown> | undefined;
  readonly defaultTab;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(
    cluster: string,
    pod: PodType,
    agentId: string,
    featureFlags: Record<string, unknown>,
    calculatedStatus: string,
    accountFeatures?: Record<string, unknown> | undefined
  ) {
    this.featureFlags = featureFlags;
    this.accountFeatures = accountFeatures;
    this.agentId = agentId;
    this.id = pod.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = pod.metadata?.namespace ?? "";
    this.name = pod.metadata?.name ?? "";
    this.kind = pod.kind ?? "";
    this.labels = pod.metadata?.labels ?? {};
    this.annotations = pod.metadata?.annotations ?? {};
    this.containerStatuses = pod.status?.containerStatuses;
    this.icon = PodIcon;
    this.controlledBy = pod.metadata?.ownerReferences?.[0].name;
    this.fullObj = pod;
    this.calculatedStatus = calculatedStatus;
    this.resourceVersion = pod.metadata?.resourceVersion ?? "";

    this.isPodReadyOrCompleted = pod?.status?.conditions?.some(
      (condition) =>
        condition.type === "Ready" &&
        (condition.status === "True" ||
          (condition.status === "False" && condition.reason === "PodCompleted"))
    );

    this.isRCAShown =
      this.isPodReadyOrCompleted === false && this.featureFlags["klaudiaAi"];
    this.defaultTab =
      this.isRCAShown && this.accountFeatures?.[features.KLAUDIA_AI]
        ? DefaultTab.RootCauseAnalysis
        : DefaultTab.Details;

    this.drawerTabs = compact([
      { label: ResourceTabName.Details },
      this.isRCAShown ? { label: ResourceTabName.RootCauseAnalysis } : null,
      featureFlags["groupPodsByPhases"]
        ? { label: ResourceTabName.Phases }
        : null,
      { label: ResourceTabName.Logs },
      { label: ResourceTabName.Metrics },
      { label: ResourceTabName.Yaml },
    ]);

    if (this.featureFlags.portForwardingUi) {
      this.actions.unshift(AvailableActions.PortForward);
    }
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    let i = 0;
    const getNextIndex = () => i++;

    return (
      <>
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <PodDescribeTab
            resource={this}
            calculatedStatus={this.calculatedStatus}
          />
        </TabPanel>
        {this.isRCAShown && (
          <TabPanel value={selectedTab} index={getNextIndex()}>
            <PodAiInvestigationTab resource={this} />
          </TabPanel>
        )}
        {this.featureFlags["groupPodsByPhases"] && (
          <TabPanel value={selectedTab} index={getNextIndex()}>
            <PodEventsTab resource={this} />
          </TabPanel>
        )}
        <TabPanel value={selectedTab} index={getNextIndex()}>
          <LogsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <MetricsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
          {
            name: "controlled by",
            value: this.controlledBy,
          },
        ]}
        leftActions={leftActions}
      />
    );
  }

  renderDrawerMessage() {
    return <IssueBannersForPod pod={this} />;
  }

  renderStatus(): JSX.Element {
    return (
      <StatusTag
        value={this.calculatedStatus}
        mapOverride={podStatusMapper()}
      />
    );
  }
}
