import React from "react";

import { DatadogReportingContainer } from "@/components/k8sAddons/components/DatadogReportingContainer/DatadogReportingContainer";
import { StyledPageContainer } from "@/components/k8sAddons/styles";
import { IfSupportedEntityForWorkspaceKind } from "@/components/k8sAddons/components/IfSupportedEntityForWorkspaceKind";

export const AddonPageContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <DatadogReportingContainer>
      <StyledPageContainer>
        <IfSupportedEntityForWorkspaceKind>
          {children}
        </IfSupportedEntityForWorkspaceKind>
      </StyledPageContainer>
    </DatadogReportingContainer>
  );
};
