import { getAppConfig } from "../../../config/appConfig";

import { useCreateAxiosClient } from "@/shared/hooks/useCreateAxiosClient";
import { RequestHeadersParams } from "@/shared/hooks/common-api/headers";

export const useAccountsApiClient = () => {
  return useCreateAxiosClient(getAppConfig().accountsServiceAPIServerURL);
};

export const useAccountApiClientWithHeaders = (
  headers: RequestHeadersParams
) => {
  return useCreateAxiosClient(
    getAppConfig().accountsServiceAPIServerURL,
    headers
  );
};
