import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { useCanI } from "@/components/Settings/Users/Permissions/CanI/useCanI";
import { ResultSection } from "@/components/Settings/Users/Permissions/CanI/ResultSection";
import { ArgumentsSection } from "@/components/Settings/Users/Permissions/CanI/ArgumentsSection";
import { ariaLabels } from "@/components/Settings/Users/Permissions/constants";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DdTimingsViews } from "@/components/Settings/Users/Permissions/ddTimings";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";

const padding = "16px";
const CanIComponent: React.FC<{
  userId: string;
  isAdminView: boolean;
}> = ({ userId, isAdminView }) => {
  const { handleRequest, isLoading, isError, data, selectedValues, setters } =
    useCanI(userId, isAdminView);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState(DdTimingsViews.canICheckRequest, isLoading);
  }, [isLoading, reportLoadingState]);

  return (
    <Paper
      sx={{ width: "100%", backgroundColor: "transparent" }}
      aria-label={ariaLabels.cani.container}
    >
      <Stack>
        <Paper sx={{ padding }} elevation={0}>
          <ArgumentsSection
            values={selectedValues}
            setters={setters}
            onRequest={handleRequest}
          />
        </Paper>
        {(data || isLoading) && (
          <>
            <Divider />
            <Stack padding={padding}>
              <ResultSection isLoading={isLoading} data={data} />
            </Stack>
          </>
        )}
        {isError && (
          <Stack padding={padding}>
            <Typography variant="h4">
              There was an error fetching the data. Please try again.
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

type CanIComponentProps = React.ComponentProps<typeof CanIComponent>;

export const CanIContainer: React.FC<CanIComponentProps> = (props) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.userSettingsCanIPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <CanIComponent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
