import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  ChatBot,
  CloseButton,
  DrawerHeader,
  ChatMessageType,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MagicAi24 } from "@komodorio/design-system/icons";
import { muiPalette } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";

import type { DrawerProps } from "@/shared/store/drawersStackStore/types";
import { AriaLabels } from "@/shared/config/ariaLabels";

export type KlaudiaChatProps = {
  sessionId: string;
};

type KlaudiaChatDrawerProps = DrawerProps & KlaudiaChatProps;
export const KlaudiaChatDrawer: React.FC<KlaudiaChatDrawerProps> = ({
  open,
  onClose,
}) => {
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  // Simulate sending a message and getting a response
  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage: ChatMessageType = {
      id: messages.length + 1,
      text: input,
      isUser: true,
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput("");

    // Simulate server response
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulate API delay

    const botMessage: ChatMessageType = {
      id: messages.length + 2,
      text: "This is a response from the server.",
      isUser: false,
    };

    setMessages((prev) => [...prev, botMessage]);
    setLoading(false);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      elevation={5}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          width: "60%",
        },
      }}
      aria-label={AriaLabels.KlaudiaChat.ChatDrawer}
    >
      <Box paddingTop="16px" />
      <DrawerHeader
        title={
          <Box display="flex" alignItems="center" gap="8px">
            <MagicAi24 color={muiPalette.action?.active} />
            <Typography variant="h3">Klaudia Chat</Typography>
          </Box>
        }
        leftActions={<CloseButton onClose={onClose} />}
      />
      <Divider />
      <ChatBot
        messages={messages}
        input={input}
        setInput={setInput}
        loading={loading}
        handleSendMessage={handleSendMessage}
      />
    </Drawer>
  );
};
