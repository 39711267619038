import { useCallback } from "react";

import { useHealthType } from "@/components/reliability/hooks/useHealthType";
import {
  DatadogReportLoadingTimeContextProviderProps,
  ViewOptions,
} from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { getFullPageViewName } from "@/components/reliability/constants/dataDogReporting";
import { useGetCurrentTab } from "@/components/reliability/hooks/useGetCurrentTab";

export const useDatadogReportingProps =
  (): DatadogReportLoadingTimeContextProviderProps => {
    const healthType = useHealthType();
    const currentTab = useGetCurrentTab();
    const getCurrentViewOptions = useCallback((): ViewOptions => {
      return {
        name: getFullPageViewName(healthType, currentTab),
        context: { feTeam: "operate", beTeam: "operate" },
      };
    }, [healthType, currentTab]);

    return { getCurrentViewOptions };
  };
