import React from "react";
import Button from "@mui/material/Button";

import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const aiInvestigationUrl = "https://app.komodor.com/ai-investigator/";

export const AnalyzeKlaudiaSessionButton: React.FC = () => {
  const { openKlaudiaAiLink } = useOverridableFlags();
  const { data } = useViolation();

  const onClick = () => {
    const komodorUid = data?.data.violation?.komodorUid;
    if (komodorUid) {
      window.open(`${aiInvestigationUrl}?requestId=${komodorUid}`);
    }
  };

  if (!openKlaudiaAiLink) {
    return null;
  }

  return (
    <Button onClick={onClick} variant={"text"}>
      Analyze Klaudia Session
    </Button>
  );
};
