import { useCallback, useEffect, useMemo, useState } from "react";

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export function useBatches<T>(
  batchSize: number,
  elements: T[],
  fetchFromServer?: () => void
): {
  limited: T[];
  resetBatches: () => void;
  loadMore: () => void;
} {
  const [batches, setBatches] = useState(1);

  const limited = useMemo(() => {
    return elements.slice(0, batches * batchSize);
  }, [batchSize, batches, elements]);

  const resetBatches = useCallback(() => setBatches(1), []);
  const maxBatches = Math.ceil(elements.length / batchSize);

  const loadMore = useCallback(() => {
    setBatches((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (!fetchFromServer) return;

    if (batches >= maxBatches) fetchFromServer();
  }, [batches, maxBatches, fetchFromServer]);

  return { limited, resetBatches, loadMore };
}
