import { muiColors } from "@komodorio/design-system";
import { useMemo } from "react";
import styled from "styled-components";

import { ServiceTitle } from "../ServiceTitle";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 8px 0px;
  margin-top: 18px;
`;

const Rectangle = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${muiColors.indigo[500]};
`;

export const LegendContent: React.FC<{
  baselineKomodorUid: string;
}> = ({ baselineKomodorUid }) => {
  const baselineParsedKomodorUid = useMemo(() => {
    return parseKomodorUid(baselineKomodorUid);
  }, [baselineKomodorUid]);
  return baselineParsedKomodorUid ? (
    <Container>
      <Rectangle />
      <ServiceTitle parsedKomodorUid={baselineParsedKomodorUid} />
    </Container>
  ) : null;
};
