import React from "react";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";

import { useDateFormatter } from "../../shared/hooks/useDateFormatter";
import { stringDateComparator } from "../../shared/utils/dateUtils";

import { ArgoStatus } from "./ArgoStatus";

const getStatusSortValue = (status: string) => {
  switch (status) {
    case "Running":
      return 1;
    case "Failed":
      return 2;
    case "Succeeded":
      return 3;
    default:
      return 4;
  }
};

const parseCustomDurationToMilliseconds = (durationStr: string) => {
  const durationPattern = /(\d+)\s*([wdhms])/g;
  const durationMap: Record<string, number> = {
    w: 604800000,
    d: 86400000,
    h: 3600000,
    m: 60000,
    s: 1000,
  };
  let totalMilliseconds = 0;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  durationStr.replace(durationPattern, (_, value, unit) => {
    totalMilliseconds += parseInt(value) * durationMap[unit];
    return "";
  });

  return totalMilliseconds;
};

const sortDuration = (a: string, b: string) => {
  const durationA = parseCustomDurationToMilliseconds(a);
  const durationB = parseCustomDurationToMilliseconds(b);

  return durationA - durationB;
};

export const useArgoMainTableColumns = () => {
  const { format } = useDateFormatter();

  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "cluster",
      headerName: "Cluster",
      flex: 1,
    },
    {
      field: "namespace",
      headerName: "Namespace",
      flex: 1,
    },
    {
      field: "runs",
      headerName: "Runs",
      flex: 0.5,
    },
    {
      field: "startedAt",
      headerName: "Started",
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams<Date | undefined>) =>
        params.value ? format(params.value) : "",
      sortComparator: (a: string, b: string) => stringDateComparator(a, b),
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 0.5,
      sortComparator: (a: string, b: string) => sortDuration(a, b),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <ArgoStatus status={params.value} />
      ),
      sortComparator: (statusA: string, statusB: string) =>
        getStatusSortValue(statusA) - getStatusSortValue(statusB),
    },
  ];
};
