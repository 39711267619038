import React from "react";

import { Severity } from "../../../../ReliabilityTypes";

import { CheckType } from "@/generated/reliabilityApi";
import { FormConfigurations } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { ConfirmationDialogStaticProps } from "@/components/reliability/components/pages/policies/shared/ConfirmationDialog";

export const INPUT_FIELD_SMALL_WIDTH = 216;
export const MIN_POLICY_NAME_LENGTH = 3;

export const detailFormErrors = {
  required: "This field is required",
  getMinLength: (length: number) => `Length must be ${length} or more`,
  getRegexError: (field: string) => `"${field}" is an invalid regex`,
};

export const checkTypeToConfigurationDescription: Record<
  CheckType,
  { title: string; subTitle?: string }
> = {
  deprecatedApis: {
    title: "At least 1 resource utilizes an API version that will be:",
  },
  restartingContainers: {
    title: "The total number of containers restarts of a workload",
  },
  requestsLimitsRatio: {
    title: "The ratio of requests to limits is higher than the threshold",
  },
  kubernetesVersionDeprecated: {
    title: "At least 1 resource utilizes an API version that will be:",
  },
  kubernetesEndOfLife: {
    title:
      "The time (in months) a clusters’ version will reach its End of Life:",
    subTitle:
      "* If a cluster has reached EOL, the severity will always be High",
  },
  noisyNeighbor: { title: "The total number of victims" },
  throttledCPU: {
    title:
      "The percentage (%) of time the CPU has reached the resource Limits in the last 7d:",
  },
  HPAMax: {
    title:
      "The percentage (%) of time the HPA has reached maxReplicas in the last 7d:",
  },
  underProvisionedWorkloads: {
    title: "The CPU or Memory usage is higher than the resource requests by:",
    subTitle: "* If a request is not configured, the value is treated as 0",
  },
  nodeTerminationAutoScaling: { title: "Node terminations due to Autoscaling" },
  nodeTerminationSpotInstance: {
    title: "Node terminations due to Spot Interruptions",
  },
  unschedulableTime: { title: "Unschedulable time" },
  singlePointOfFailure: {
    title:
      "The total amount of time (minutes) the service suffered form an availability issue",
  },
  deploymentMissingReplicas: { title: "Low number of replicas" },
  missingPDB: { title: "Missing Pod Disruption Budget" },
  missingTopologySpreadConstraint: {
    title: "Missing Topology Spread Constraints",
  },
  HPAMinAvailability: { title: "HPA Min Availability" },
  missingHPA: { title: "Missing HPA" },
  readinessProbeMissing: { title: "Readiness Probe Missing" },
  livenessProbeMissing: { title: "Liveness Probe Missing" },
  cpuLimitsMissing: { title: "CPU Limits Missing" },
  memoryLimitsMissing: { title: "Memory Limits Missing" },
  cpuRequestsMissing: { title: "CPU Requests Missing" },
  memoryRequestsMissing: { title: "Memory Requests Missing" },
  priorityClassNotSet: { title: "Priority Class Not Set" },
  certificateExpiration: { title: "Certificate Expiration" },
  syntheticDegradedService: { title: "Synthetic Degraded Service" },
  syntheticEOLDeprecationOutdated: { title: "Synthetic Cluster Upgrade" },
  syntheticNodePressure: { title: "Synthetic Node Pressuer" },
  cascadingFailure: { title: "Cascading Failure" },
  failedCronJob: { title: "Failed Cron Job" }, // TODO: Add description
  failedJob: { title: "Failed Job" }, // TODO: Add description
  unhealthyNode: { title: "Unhealthy Node" }, // TODO: Add description
  unhealthyPVC: { title: "Unhealthy PVC" }, // TODO: Add description
  unhealthyService: { title: "Unhealthy Service" }, // TODO: Add description
  unhealthyWorkflow: { title: "Unhealthy Workflow" }, // TODO: Add description
  externalDNSNotSynced: { title: "External DNS Not Synced" },
  scaleDownImpact: { title: "Scale Down Impact" },
};

export const discardChangesDialogTexts: ConfirmationDialogStaticProps = {
  title: "Discard changes?",
  textLine1: "Are you sure you want to close this drawer?",
  textLine2: "Changes you've made will not be saved!",
  actionButtonLabel: "Discard changes",
  cancelButtonAriaLabel: "Cancel discard changes",
};

export const supportedDynamicCheckTypes: CheckType[] = [
  CheckType.ThrottledCpu,
  CheckType.RestartingContainers,
  CheckType.HpaMax,
  CheckType.DeprecatedApis,
  CheckType.KubernetesEndOfLife,
  CheckType.NoisyNeighbor,
  CheckType.UnderProvisionedWorkloads,
  CheckType.SinglePointOfFailure,
  CheckType.CertificateExpiration,
  CheckType.ExternalDnsNotSynced,
];

export const supportedStaticCheckTypes: CheckType[] = [
  CheckType.DeploymentMissingReplicas,
  CheckType.MissingPdb,
  CheckType.MissingTopologySpreadConstraint,
  CheckType.HpaMinAvailability,
  CheckType.MissingHpa,
  CheckType.PriorityClassNotSet,
  CheckType.CpuRequestsMissing,
  CheckType.CpuLimitsMissing,
  CheckType.MemoryRequestsMissing,
  CheckType.MemoryLimitsMissing,
  CheckType.LivenessProbeMissing,
  CheckType.ReadinessProbeMissing,
];

export const severitiesList: Severity[] = ["high", "medium", "low"];

export const configurationFieldsDefaultValues: FormConfigurations = {
  configurations: {
    deprecatedApis: {
      enabled: true,
      low: {
        state: "Deprecated",
        versionsAhead: 5,
      },
      medium: {
        state: "Removed",
        versionsAhead: 1,
      },
      high: {
        state: "Removed",
        versionsAhead: 1,
      },
    },
    kubernetesEndOfLife: {
      enabled: true,
      high: 1,
      medium: 1,
      low: 1,
    },
    HPAMax: {
      enabled: true,
      high: 1,
      medium: 1,
      low: 1,
    },
    noisyNeighbor: {
      enabled: true,
      victims: {
        high: 1,
        medium: 1,
        low: 1,
      },
    },
    restartingContainers: {
      enabled: true,
      high: 1,
      medium: 1,
      low: 1,
    },
    throttledCPU: {
      enabled: true,
      high: 1,
      medium: 1,
      low: 1,
    },
    underProvisionedWorkloads: {
      enabled: true,
      memory: {
        high: 1,
        medium: 1,
        low: 1,
      },
      cpu: {
        high: 1,
        medium: 1,
        low: 1,
      },
    },
    singlePointOfFailure: {
      enabled: true,
      low: 1,
      medium: 1,
      high: 1,
    },
    certificateExpiration: {
      enabled: true,
      high: 1,
      medium: 1,
      low: 1,
    },
  },
};

export const reliabilityDrawerTexts = {
  createNewReliabilityPolicy: "New Reliability Policy",
  editReliabilityPolicy: "Edit Reliability Policy",
  viewReliabilityPolicy: "View Reliability Policy",
};

export const policyValidationErrorMessages = {
  identicalValuesMessage: "Severity thresholds cannot hold the same value",
  getComparisonErrorMessage: ({
    higherSeverity,
    lowerSeverity,
    comparative = "higher",
  }: {
    higherSeverity: Severity;
    lowerSeverity: Severity;
    comparative?: "higher" | "lower";
  }) =>
    `The ${lowerSeverity} severity cannot be ${comparative} than the ${higherSeverity} severity`,
  apiDeprecationStateErrorMessage:
    "Removed severity cannot be lower than a deprecated severity",
};

export const detailsFieldsTexts = {
  priority: {
    tooltipContent: (
      <>
        Priority determines which policy will be
        <br />
        checked against a cluster in the event of a
        <br />
        scoping conflict (i.e. two policies that include
        <br />
        the same cluster).
        <br />A higher number indicates a stronger policy.
      </>
    ),
  },
  policyScope: {
    placeHolder: "Select clusters or use a wildcard pattern",
    helperText: `Wildcard use an asterisk (*), to replace or represent one or more characters. For example, "comp*" matches anything starting with "comp" (i.e "comp," "complete," and "computer" are all matched)`,
  },
};
