import React from "react";

import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { HealthSection } from "@/components/ClustersView/ClusterOverview/components/HealthSection/HealthSection";
import { AddonsSection } from "@/components/ClustersView/ClusterOverview/components/AddonsSection/AddonsSection";
import { CostSection } from "@/components/ClustersView/ClusterOverview/components/CostSection/CostSection";
import { OverviewContainer } from "@/components/ClustersView/ClusterOverview/styles";
import { OverviewHeader } from "@/components/workspaces/Overview/OverviewHeader";
import { TypedWorkspace } from "@/shared/hooks/workspaces-api/types";
import { WorkspaceInventorySection } from "@/components/workspaces/Overview/WorkspaceInventorySection";

export const Overview: React.FC<{ workspace: TypedWorkspace }> = ({
  workspace,
}) => {
  return (
    <>
      <OverviewHeader workspace={workspace} />
      <ResponsiveLayout>
        <OverviewContainer>
          <HealthSection />
          <AddonsSection />
          <CostSection />
          <WorkspaceInventorySection />
        </OverviewContainer>
      </ResponsiveLayout>
    </>
  );
};
