import { useMemo } from "react";

import { useFetchAgentsInfoForDowntimeEventsQuery } from "../../../../generated/graphql";
import { useDataForFlag } from "../../../../shared/hooks/useDataForFlag";
import { useQueryWithVariables } from "../../../../shared/hooks/useQueryWithVariables";
import { TimeWindow } from "../../../../shared/types/TimeWindow";
import { formatDateWithUtcSuffix } from "../../../../shared/utils/dateUtils";

import { AgentDowntimeRawKubernetesAgentInfo } from "./agentDowntimeTypes";

const formatCreatedAtAndLastSeenAlive = (
  row: AgentDowntimeRawKubernetesAgentInfo
) => {
  const { createdAt, lastSeenAlive } = row;
  return {
    ...row,
    createdAt: formatDateWithUtcSuffix(createdAt),
    lastSeenAlive: formatDateWithUtcSuffix(lastSeenAlive),
  };
};

export const useAgentDowntimeRawData = (
  ShowAgentDowntimeEvent: unknown,
  timeWindow: Pick<TimeWindow, "start" | "end">,
  clusterName?: string | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): AgentDowntimeRawKubernetesAgentInfo[] | null => {
  const agentDowntimeEventsVariable = useMemo(() => {
    if (!ShowAgentDowntimeEvent) return null;
    return {
      startDate: timeWindow.start.toISOString(),
      endDate: timeWindow.end.toISOString(),
    };
  }, [ShowAgentDowntimeEvent, timeWindow.start, timeWindow.end]);

  const result = useQueryWithVariables(
    useFetchAgentsInfoForDowntimeEventsQuery,
    agentDowntimeEventsVariable
  );
  const relevantResults = useMemo(
    () =>
      (clusterName
        ? result?.kubernetes_agent_info?.filter(
            (row) => row.clusterName === clusterName
          )
        : result?.kubernetes_agent_info
      )?.map(formatCreatedAtAndLastSeenAlive),
    [clusterName, result?.kubernetes_agent_info]
  );
  return useDataForFlag(ShowAgentDowntimeEvent, relevantResults);
};
