/* eslint-disable max-lines */
import {
  AccessControl16,
  Applications16,
  Workflows16,
  Boxes16,
  Clock16,
  Cog16,
  Gauge16,
  Helm16,
  Integration16,
  Kubernetes16,
  List16,
  Network16,
  Nodes16,
  QuestionCircle16,
  Storage16,
  Workloads16,
  Wrench16,
  Team16,
  Namespaces24,
  Person16,
  InfraHealth16,
  WorkloadHealth16,
  Scale16,
} from "@komodorio/design-system/icons";
import Newspaper from "@mui/icons-material/Newspaper";
import ChatBubbleOutline from "@mui/icons-material/ChatBubbleOutline";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Language from "@mui/icons-material/Language";
import React, { useMemo } from "react";
import { AccountPlan } from "komodor-types";

import CostIcon from "../assets/costIcon.svg?react";
import driftAnalysisIconUrl from "../assets/driftAnalysisIcon.svg";
import k8sAddonsCertManagerIcon from "../assets/k8sAddonCertManager.svg";
import {
  COST_OPTIMIZATION,
  EVENTS_ROUTE,
  INTEGRATIONS_ROUTE,
  JOBS,
  SERVICES,
  WORKLOAD_HEALTH,
  INFRA_HEALTH,
  AUDIT_ROUTE,
  CLUSTERS_ROUTE,
  DRIFT_ANALYSIS_DEMO_ROUTE,
  K8S_ADDONS_CERT_MANAGER_ROUTE,
  EXPERT_OVERVIEW,
  USER_PROFILE_ROUTE,
  K8S_ADDONS_WORKFLOWS_ROUTE,
  USERS_ROUTE,
  DRIFT_ANALYSIS_ROUTE,
  POLICIES_ROUTE,
  FEATURES_ROUTE,
  AGENTS_SETTINGS_ROUTE,
  USAGE_ROUTE,
  IP_WHITELIST_ROUTE,
  ACTIONS_ROUTE,
  USER_API_KEYS_ROUTE,
  ROLES_ROUTE,
  K8S_ADDONS_EXTERNAL_DNS_ROUTE,
  K8S_ADDONS_NODE_AUTOSCALERS_ROUTE,
  RELIABILITY_SETTINGS_ROUTE,
} from "../../routes/routes";
import { useAppBarStateContext } from "../internal/appBarStateContext";
import { getRoutePathFromKubernetesResource } from "../../Inspection/inspectionConfiguration/SupportedKubernetesResources";
import {
  KubernetesConfigMapResource,
  KubernetesHPAResource,
  KubernetesIngressResource,
  KubernetesNodesResource,
  KubernetesPVCResource,
  KubernetesPodsResource,
  KubernetesSecretsResource,
  KubernetesServiceResource,
} from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { KubernetesReplicaSetResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesReplicaSetResource";
import { KubernetesDeploymentResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDeploymentResource";
import { KubernetesCronJobResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCronJobResource";
import { KubernetesStatefulSetResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesStatefulSetResource";
import { KubernetesDaemonSetResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDaemonSetResource";
import { KubernetesPVResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPVResource";
import { KubernetesStorageClassResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesStorageClassResource";
import { KubernetesResourceQuotaResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesResourceQuotaResource";
import { KubernetesLimitRangeResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesLimitRangeResource";
import { KubernetesPDBResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPDBResource";
import { KubernetesEndpointResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesEndpointResource";
import { KubernetesNetworkPolicyResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNetworkPolicyResource";
import { KubernetesEndpointSliceResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesEndpointSliceResource";
import { KubernetesServiceAccountsResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesServiceAccountsResource";
import { KubernetesClusterRolesResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRolesResource";
import { KubernetesRolesResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesRolesResource";
import { KubernetesClusterRoleBindingsResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRoleBindingsResource";
import { KubernetesRoleBindingsResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesRoleBindingsResource";
import { KubernetesHelmResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesHelmResource";
import {
  DOCS_LINK,
  KOMODOR_UPDATES_LINK,
} from "../../../shared/constants/docsLinks";
import { KubernetesJobResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";
import { useAppViewsStore } from "../../../shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../../shared/store/appViewsStore/appViewStoreSelectors";
import { getOverviewPageRoute } from "../../appView/sections/AppView/utils/getRoutes";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { KubernetesCRDResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";
import { useCanViewClustersList } from "../../ClustersView/ClusterOverview/permissionsHooks";

import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { KubernetesNamespaceResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNamespaceResource";

export enum PageName {
  Overview = "Overview",
  WorkloadHealth = "Workload Health",
  InfrastructureHealth = "Infrastructure Health",
  Workflows = "Workflows",
  CostOptimization = "Cost Optimization",
  DriftAnalysisDemo = "Drift Analysis ",
  DriftAnalysis = "Drift Analysis",
  Services = "Services",
  Jobs = "Jobs",
  Events = "Events",
  Nodes = "Nodes",
  Pods = "Pods",
  ReplicaSets = "ReplicaSets",
  Deployments = "Deployments",
  InspectionJobs = "Inspection Jobs",
  CronJobs = "CronJobs",
  StatefulSets = "StatefulSets",
  DaemonSets = "DaemonSets",
  PVCs = "PVCs",
  PVs = "PVs",
  StorageClasses = "Storage Classes",
  ConfigMaps = "ConfigMaps",
  Secrets = "Secrets",
  ResourceQuotas = "Resource Quotas",
  LimitRanges = "Limit Ranges",
  Namespaces = "Namespaces",
  HPAs = "HPAs",
  PDBs = "PDBs",
  KubernetesServices = "Kubernetes Services",
  Endpoints = "Endpoints",
  Ingresses = "Ingresses",
  NetworkPolicies = "Network Policies",
  EndpointSlices = "Endpoint Slices",
  ServiceAccounts = "Service Accounts",
  ClusterRoles = "Cluster Roles",
  Roles = "Roles",
  ClusterRoleBinding = "Cluster Role Bindings",
  RoleBinding = "Role Bindings",
  CustomResources = "Custom Resources",
  Helm = "Helm",
  Integrations = "Integrations",
  Clusters = "Clusters",
  Agents = "Agents",
  k8sAddonsCertManager = "Cert Manager",
  DocsAndSupport = "Docs and Support",
  WhatsNew = "What's New?",
  ChatSupport = "Chat Support",
  Audit = "Audit",
  Settings = "Settings",
  Topology = "Topology",
  Policies = "Policies",
  Actions = "Actions",
  IPWhitelist = "IP Whitelist",
  Usage = "Usage",
  Features = "Features",
  UserProfile = "User Profile",
  UserAPIKeys = "User API Keys",
  ExternalDNS = "External DNS",
  NodeAutoscalers = "Autoscalers",
  DemoUsers = "Users",
  SettingsRoles = "Settings Roles",
  HealthPolicies = "Health Policies",
}
export type Page = {
  url: string;
  urlWithCluster?: (clusterName: string) => string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  shouldShow: boolean;
  locked?: boolean;
};
const ICON_SIZE_ATTRS = { height: 20, width: 20 };

// eslint-disable-next-line react-refresh/only-export-components
export const usePages: () => Record<PageName, Page> = () => {
  const appBarStateContext = useAppBarStateContext();
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const defaultCluster = appBarStateContext.defaultCluster ?? "";
  const { canManageUsers } = useHasPermissions();
  const { data: accountFeatures } = useAccountFeatures();
  const {
    showCostOptimization,
    showCertManagerAddon,
    showServiceAccountInspectionPage,
    showClusterRoleInspectionPage,
    showRolesInspectionPage,
    showClusterRoleBindingInspectionPage,
    showRoleBindingInspectionPage,
    showClustersPage,
    showHelm,
    forceShowZendeskChatbot,
    forceShowIntercom,
    showWorkflows,
    showExpertOverview,
    driftDetection,
    usersTabNavBarForDemo,
    showClustersPageDemoV3,
    showExternalDns,
    comingSoonStaticPageExternalDns,
    comingSoonStaticPageNodeAutoscalers,
    showK8SAddonAutoscaler,
  } = useOverridableFlags();

  const { data: canViewClustersList } = useCanViewClustersList();
  const { canViewNamespaces } = useHasPermissions();

  const { accountPlan } = useUserMetadata();

  const shouldShowChatSupport =
    !forceShowIntercom &&
    (!!forceShowZendeskChatbot ||
      (accountPlan != AccountPlan.trial && accountPlan != AccountPlan.free));

  return useMemo(
    () => ({
      [PageName.Overview]: {
        url: selectedAppViewId ? getOverviewPageRoute(selectedAppViewId) : "",
        icon: () => <Gauge16 />,
        shouldShow: !!selectedAppViewId,
      },
      [PageName.WorkloadHealth]: {
        url: `/${WORKLOAD_HEALTH}`,
        icon: () => <WorkloadHealth16 />,
        shouldShow: true,
      },
      [PageName.InfrastructureHealth]: {
        url: `/${INFRA_HEALTH}`,
        icon: () => <InfraHealth16 />,
        shouldShow: true,
      },
      [PageName.DriftAnalysisDemo]: {
        url: showClustersPageDemoV3 ? DRIFT_ANALYSIS_DEMO_ROUTE : "",
        icon: () => <img src={driftAnalysisIconUrl} />,
        shouldShow: Boolean(showClustersPageDemoV3),
      },
      [PageName.CostOptimization]: {
        url: showCostOptimization ? `/${COST_OPTIMIZATION}` : "",
        icon: CostIcon,
        shouldShow: Boolean(showCostOptimization),
      },
      [PageName.DriftAnalysis]: {
        url: driftDetection ? DRIFT_ANALYSIS_ROUTE : "",
        shouldShow: Boolean(driftDetection),
        icon: () => (
          <CompareArrowsIcon
            sx={{
              ...ICON_SIZE_ATTRS,
              marginLeft: "-4px",
              cursor: "pointer",
            }}
          />
        ),
      },
      [PageName.k8sAddonsCertManager]: {
        url: showCertManagerAddon ? K8S_ADDONS_CERT_MANAGER_ROUTE : "",
        icon: () => <img src={k8sAddonsCertManagerIcon} />,
        shouldShow: Boolean(showCertManagerAddon),
      },
      [PageName.Workflows]: {
        url: showWorkflows ? K8S_ADDONS_WORKFLOWS_ROUTE : "",
        icon: () => <Workflows16 {...ICON_SIZE_ATTRS} />,
        shouldShow: Boolean(showWorkflows),
      },
      [PageName.Services]: {
        url: `/${SERVICES}`,
        icon: () => <Applications16 />,
        shouldShow: true,
      },
      [PageName.Jobs]: {
        url: `/${JOBS}`,
        icon: () => <Boxes16 />,
        shouldShow: true,
      },
      [PageName.Events]: {
        url: EVENTS_ROUTE,
        icon: () => <Clock16 />,
        shouldShow: true,
      },
      [PageName.Nodes]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesNodesResource,
          defaultCluster
        ),
        urlWithCluster: (clusterName: string) =>
          getRoutePathFromKubernetesResource(
            KubernetesNodesResource,
            clusterName
          ),
        icon: () => <Nodes16 />,
        shouldShow: true,
      },
      [PageName.Pods]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesPodsResource,
          defaultCluster
        ),
        urlWithCluster: (clusterName: string) =>
          getRoutePathFromKubernetesResource(
            KubernetesPodsResource,
            clusterName
          ),
        shouldShow: true,
      },
      [PageName.ReplicaSets]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesReplicaSetResource,
          defaultCluster
        ),
        icon: () => <Applications16 width={16} height={14} />,
        shouldShow: true,
      },
      [PageName.Deployments]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesDeploymentResource,
          defaultCluster
        ),
        icon: () => <Workloads16 />,
        shouldShow: true,
      },
      [PageName.InspectionJobs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesJobResource,
          defaultCluster
        ),
        shouldShow: true,
      },
      [PageName.CronJobs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesCronJobResource,
          defaultCluster
        ),
        icon: () => <Workloads16 />,
        shouldShow: true,
      },
      [PageName.StatefulSets]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesStatefulSetResource,
          defaultCluster
        ),
        icon: () => <Workloads16 />,
        shouldShow: true,
      },
      [PageName.DaemonSets]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesDaemonSetResource,
          defaultCluster
        ),
        icon: () => <Workloads16 />,
        shouldShow: true,
      },
      [PageName.PVCs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesPVCResource,
          defaultCluster
        ),
        icon: () => <Storage16 />,
        shouldShow: true,
      },
      [PageName.PVs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesPVResource,
          defaultCluster
        ),
        icon: () => <Storage16 />,
        shouldShow: true,
      },
      [PageName.StorageClasses]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesStorageClassResource,
          defaultCluster
        ),
        icon: () => <Storage16 />,
        shouldShow: true,
      },
      [PageName.ConfigMaps]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesConfigMapResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.Secrets]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesSecretsResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.ResourceQuotas]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesResourceQuotaResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.LimitRanges]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesLimitRangeResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.Namespaces]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesNamespaceResource,
          defaultCluster
        ),
        urlWithCluster: (clusterName: string) =>
          getRoutePathFromKubernetesResource(
            KubernetesNamespaceResource,
            clusterName
          ),
        icon: () => <Namespaces24 {...ICON_SIZE_ATTRS} />,
        shouldShow: Boolean(canViewNamespaces),
      },
      [PageName.HPAs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesHPAResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.PDBs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesPDBResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.KubernetesServices]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesServiceResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.Endpoints]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesEndpointResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.Ingresses]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesIngressResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.NetworkPolicies]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesNetworkPolicyResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.EndpointSlices]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesEndpointSliceResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.ServiceAccounts]: {
        url: showServiceAccountInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesServiceAccountsResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showServiceAccountInspectionPage),
      },
      [PageName.ClusterRoles]: {
        url: showClusterRoleInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesClusterRolesResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showClusterRoleInspectionPage),
      },
      [PageName.Roles]: {
        url: showRolesInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesRolesResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showRolesInspectionPage),
      },
      [PageName.ClusterRoleBinding]: {
        url: showClusterRoleBindingInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesClusterRoleBindingsResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showClusterRoleBindingInspectionPage),
      },
      [PageName.RoleBinding]: {
        url: showRoleBindingInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesRoleBindingsResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showRoleBindingInspectionPage),
      },
      [PageName.CustomResources]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesCRDResource,
          defaultCluster
        ),
        icon: () => <Wrench16 />,
        shouldShow: true,
      },
      [PageName.Helm]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesHelmResource,
          defaultCluster
        ),
        icon: () => <Helm16 {...ICON_SIZE_ATTRS} />,
        shouldShow: Boolean(showHelm),
      },
      [PageName.Clusters]: {
        url: showClustersPage ? CLUSTERS_ROUTE : "",
        icon: () => <Kubernetes16 />,
        shouldShow: !!showClustersPage,
        locked:
          canViewClustersList == undefined ? undefined : !canViewClustersList,
      },
      [PageName.Integrations]: {
        url: INTEGRATIONS_ROUTE,
        icon: () => <Integration16 />,
        shouldShow: true,
      },
      [PageName.DocsAndSupport]: {
        url: DOCS_LINK,
        icon: () => <QuestionCircle16 />,
        shouldShow: true,
      },
      [PageName.WhatsNew]: {
        url: KOMODOR_UPDATES_LINK,
        icon: () => (
          <Newspaper
            sx={{
              fontSize: "16px",
              cursor: "pointer",
            }}
          />
        ),
        shouldShow: true,
      },
      [PageName.ChatSupport]: {
        url: "",
        icon: () => (
          <ChatBubbleOutline
            sx={{
              fontSize: "16px",
              cursor: "pointer",
            }}
          />
        ),
        shouldShow: shouldShowChatSupport,
      },
      [PageName.Audit]: {
        url: AUDIT_ROUTE,
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.Settings]: {
        url: USER_PROFILE_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.Topology]: {
        url: `/${EXPERT_OVERVIEW}`,
        icon: () => <Gauge16 />,
        shouldShow: !!showExpertOverview && !selectedAppViewId,
      },
      [PageName.ExternalDNS]: {
        url: K8S_ADDONS_EXTERNAL_DNS_ROUTE,
        icon: () => (
          <Language
            sx={{
              ...ICON_SIZE_ATTRS,
            }}
          />
        ),
        shouldShow: !!comingSoonStaticPageExternalDns || !!showExternalDns,
      },
      [PageName.NodeAutoscalers]: {
        url: K8S_ADDONS_NODE_AUTOSCALERS_ROUTE,
        icon: () => <Scale16 {...ICON_SIZE_ATTRS} />,
        shouldShow:
          !!comingSoonStaticPageNodeAutoscalers || !!showK8SAddonAutoscaler,
      },
      [PageName.DemoUsers]: {
        url: USERS_ROUTE,
        icon: () => <Team16 />,
        shouldShow: Boolean(usersTabNavBarForDemo) || canManageUsers,
      },
      [PageName.SettingsRoles]: {
        url: ROLES_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.Policies]: {
        url: POLICIES_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.Actions]: {
        url: ACTIONS_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: accountFeatures?.rbacClusterSync,
      },
      [PageName.IPWhitelist]: {
        url: IP_WHITELIST_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.Usage]: {
        url: USAGE_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.Agents]: {
        url: AGENTS_SETTINGS_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.HealthPolicies]: {
        url: RELIABILITY_SETTINGS_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.Features]: {
        url: FEATURES_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.UserProfile]: {
        url: USER_PROFILE_ROUTE,
        icon: () => <Person16 />,
        shouldShow: true,
      },
      [PageName.UserAPIKeys]: {
        url: USER_API_KEYS_ROUTE,
        icon: () => <Person16 />,
        shouldShow: true,
      },
    }),
    [
      selectedAppViewId,
      showClustersPageDemoV3,
      showCostOptimization,
      driftDetection,
      showCertManagerAddon,
      showWorkflows,
      defaultCluster,
      canViewNamespaces,
      showServiceAccountInspectionPage,
      showClusterRoleInspectionPage,
      showRolesInspectionPage,
      showClusterRoleBindingInspectionPage,
      showRoleBindingInspectionPage,
      showHelm,
      showClustersPage,
      canViewClustersList,
      shouldShowChatSupport,
      showExpertOverview,
      comingSoonStaticPageExternalDns,
      showExternalDns,
      comingSoonStaticPageNodeAutoscalers,
      showK8SAddonAutoscaler,
      usersTabNavBarForDemo,
      canManageUsers,
      accountFeatures?.rbacClusterSync,
    ]
  );
};
