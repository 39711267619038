import React from "react";

import { LogoIconProps } from "../types";

export const Spark: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Spark logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <g clip-path="url(#clip0_2879_19191)">
        <path
          d="M10.9078 1.00075C10.5178 1.01175 10.1327 1.1972 9.81058 1.5547C9.63355 1.75167 9.48316 1.98611 9.35855 2.21728C9.0337 2.82572 8.96977 3.51442 8.84945 4.18181L8.18739 7.93041C8.16195 8.07753 8.09595 8.13838 7.96395 8.18048L3.10539 9.72392C2.75133 9.83959 2.39641 9.98913 2.08325 10.188C1.12797 10.7947 0.986687 11.793 1.70392 12.6695C2.02052 13.0573 2.42855 13.33 2.88127 13.5282L5.90678 14.8762C5.93205 14.89 5.95903 14.8989 5.98636 14.9041L6.62522 15.365C6.62522 15.365 5.98928 22.403 7.18312 22.8899C7.17264 22.8882 7.11266 22.8758 7.11266 22.8758C7.11266 22.8758 7.23366 23.0066 7.56005 22.9681C7.80995 23.0145 7.77695 23.0069 7.57277 22.9664C8.01672 22.909 8.83381 22.5372 10.2502 21.1129C10.3544 21.0195 10.4562 20.9234 10.5555 20.8248L13.6149 17.5219L14.3304 17.7737C14.3562 17.8093 14.3935 17.8321 14.4531 17.8498L17.8602 18.8864C18.3258 19.0301 18.7864 19.1353 19.2828 19.0628C20.169 18.933 20.8212 18.2034 20.8125 17.311C20.809 16.9191 20.6715 16.5652 20.4911 16.2241L18.2347 11.9447C18.1628 11.8095 18.1651 11.722 18.2684 11.601L22.0589 7.14562C22.3043 6.85292 22.5259 6.53908 22.6545 6.17539C22.9368 5.37273 22.5716 4.73473 21.7384 4.57884C21.3553 4.51044 20.9775 4.54653 20.604 4.64502L15.1733 6.08791C15.0134 6.13088 14.9343 6.1008 14.8475 5.95522L12.6304 2.27125C12.4628 1.99367 12.2625 1.72228 12.0328 1.49317C11.6926 1.15303 11.2978 0.989921 10.9078 1.00075ZM11.0701 4.39287C11.1577 4.45441 11.2021 4.51783 11.2437 4.58623L13.6293 8.5617C13.695 8.66964 13.7539 8.7078 13.8845 8.67308L18.0857 7.55641L19.0972 7.29172C19.0822 7.37645 19.0453 7.4275 19.0049 7.47528L15.5399 11.5439C15.4419 11.6622 15.4328 11.745 15.5065 11.8835L17.6967 16.036L17.7973 16.2486L17.7947 16.2521L13.8885 15.1755L13.8875 15.1724L13.1487 14.9653L8.76713 19.8218L9.56858 13.4366L8.84945 13.1238L8.85083 13.1226L4.78186 11.507C4.85989 11.436 4.92967 11.4064 5.00272 11.3837L9.94877 9.82034C10.065 9.78425 10.1311 9.73956 10.1421 9.60688L10.2114 9.20211L10.8774 5.45403L11.0701 4.39287Z"
          fill="#E15919"
        />
      </g>
      <defs>
        <clipPath id="clip0_2879_19191">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
