import { useMemo } from "react";

import { useAuthorization } from "../roles/useAuthorization";
import { useAppViewsStore } from "../../store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../store/appViewsStore/appViewStoreSelectors";

import { APP_VIEW_ID_HEADER } from "./constants";

export type RequestHeadersParams = {
  skipAppViewInHeaders?: boolean;
  accept?: string;
};

export const useRequestHeaders = (params?: RequestHeadersParams) => {
  const { skipAppViewInHeaders, accept } = params ?? {};
  const authorization = useAuthorization() ?? undefined;
  const appViewId = useAppViewsStore(selectedAppViewIdSelector);
  return useMemo(
    () => ({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: authorization,
      ...(appViewId &&
        !skipAppViewInHeaders && { [APP_VIEW_ID_HEADER]: appViewId }),
      ...(accept && { Accept: accept }),
    }),
    [appViewId, authorization, skipAppViewInHeaders, accept]
  );
};
