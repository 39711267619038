import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

import { VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS } from "../ViolationTableWithContext/violationTableConstants";
import { ViolationsTable } from "../ViolationTableWithContext/ViolationsTable/ViolationsTable";

import { useGetViolations } from "@/shared/hooks/reliability-api/violations/useGetViolations";
import { ImpactGroupType } from "@/generated/reliabilityApi";
import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const MIN_NUM_OF_VIOLATIONS_TO_SHOW_FOOTER = 6;

const { container: containerAriaLabel } =
  reliabilityArialLabels.violationsTableByIds;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  title: string;
  ids: string[] | undefined;
  violationTableType: ViolationsTableType;
  displayIfNoResults?: boolean;
}

export const ViolationsTableByIds: React.FC<Props> = ({
  title,
  ids,
  violationTableType,
  displayIfNoResults = false,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(
    VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS[0]
  );
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  const hasIds = !!ids?.length;

  const violationsRes = useGetViolations(
    {
      pageSize: pageSize,
      offset: page,
      impactGroupType: [ImpactGroupType.Dynamic, ImpactGroupType.Static],
      status: ["open", "confirmed", "dismissed", "ignored"],
      violationId: ids ?? [],
    },
    hasIds
  );

  const setPaginationModel = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setPage(newPaginationModel.page);
      setPageSize(newPaginationModel.pageSize);
    },
    []
  );

  const violationsCount = violationsRes?.data?.data?.violations.length ?? 0;

  useEffect(() => {
    reportLoadingState("ViolationsTableByIds", violationsRes?.isFetching);
  }, [reportLoadingState, violationsRes?.isFetching]);

  if ((!displayIfNoResults && violationsCount === 0) || !hasIds) return null;

  return (
    <Container aria-label={containerAriaLabel}>
      <Typography variant="h4"> {title} </Typography>
      <ViolationsTable
        violationsRes={violationsRes}
        customPaginationObj={{
          customPaginationModel: { page, pageSize },
          setCustomPaginationModel: setPaginationModel,
        }}
        violationTableType={violationTableType}
        hideFooter={violationsCount < MIN_NUM_OF_VIOLATIONS_TO_SHOW_FOOTER}
      />
    </Container>
  );
};
