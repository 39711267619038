import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { ReactNode, useLayoutEffect } from "react";
import styled from "styled-components";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import { History } from "../History/History";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";
import { RELIABILITY_PARAM_KEY } from "../../../../../../../../../shared/config/urlSearchParamsKeys";
import { DEFAULT_DRAWER_TAB } from "../../../../../../../constants/reliabilityConstants";
import { useGetCurrentTab } from "../../../hooks/useGetCurrentTab";
import {
  ViolationDrawerProps,
  ViolationDrawerTab,
  ViolationDrawerValue,
} from "../../../violationDrawerTypes";

import { horizontalPadding } from "./styles";
import { Feedback } from "./Feedback";

import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";

const tabLabels: Record<
  string,
  { label: ViolationDrawerTab; value: ViolationDrawerValue }
> = {
  overview: { label: "Overview", value: "overview" },
  history: { label: "History", value: "history" },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  padding: 0 ${horizontalPadding}px;
`;

const StyledTabPanel = styled(TabPanel)`
  && {
    padding: 24px 64px;
  }
`;

const {
  violationDrawer: {
    tabs: { overview: overviewAriaLabel, history: historyAriaLabel },
  },
} = reliabilityArialLabels;

export interface ContentTabsProps {
  overviewContent: ReactNode;
}

export const ContentTabs: React.FC<ContentTabsProps> = ({
  overviewContent,
}) => {
  const [drawerStateInUrl, setDrawerStateInUrl] =
    useDrawerUrlState<ViolationDrawerProps>(RELIABILITY_PARAM_KEY);
  const currentTab = useGetCurrentTab();
  const { overview, history } = tabLabels;

  useLayoutEffect(() => {
    if (!currentTab) {
      setDrawerStateInUrl({
        ...drawerStateInUrl,
        currentTab: DEFAULT_DRAWER_TAB,
      } as ViolationDrawerProps);
    }
  }, [currentTab, drawerStateInUrl, setDrawerStateInUrl]);

  const setTab = (value: ViolationDrawerValue) => {
    setDrawerStateInUrl({
      ...drawerStateInUrl,
      currentTab: value,
    } as ViolationDrawerProps);
  };

  return (
    <Container>
      <TabContext value={currentTab ?? DEFAULT_DRAWER_TAB}>
        <StyledTabs value={currentTab} onChange={(_, value) => setTab(value)}>
          <Tab {...overview} aria-label={overviewAriaLabel} />
          <Tab {...history} aria-label={historyAriaLabel} />
        </StyledTabs>
        <Divider />
        <StyledTabPanel value={overview.value}>
          {overviewContent}
          <Feedback />
        </StyledTabPanel>
        <StyledTabPanel value={history.value}>
          <History />
        </StyledTabPanel>
      </TabContext>
    </Container>
  );
};
