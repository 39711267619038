import React, { useCallback, useMemo } from "react";

import { AuditLog } from "@/generated/accounts";
import { AuditLogDrawerContent } from "@/pages/organization-settings/account/AuditPage/components/drawer/AuditLogDrawerContent";

export const useAuditLogDrawer = () => {
  const [selectedLog, setSelectedLog] = React.useState<AuditLog | null>(null);

  const isOpen = useMemo(() => selectedLog != null, [selectedLog]);

  const closeDrawer = useCallback(() => {
    setSelectedLog(null);
  }, []);

  const drawerContent = useMemo(() => {
    if (!selectedLog) {
      return null;
    }

    return <AuditLogDrawerContent log={selectedLog} onClose={closeDrawer} />;
  }, [selectedLog, closeDrawer]);

  return {
    closeDrawer,
    drawerContent,
    setSelectedLog,
    isOpen,
  };
};
