import {
  KubernetesNodesResource,
  KubernetesResource,
} from "../inspectionConfiguration/SupportedResourcesTypes";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { ACCOUNT_ADMIN_RBAC_ROLE } from "@/components/Settings/Roles/Roles";
import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { WorkspaceKind } from "@/generated/workspacesApi";

export const useNodeAccessibility = (resource: KubernetesResource): boolean => {
  const { disableNodeViewWithoutWorkspace } = useOverridableFlags();
  const { currentWorkspace } = useWorkspaces();
  const { isKomodorAdmin, userId } = useUserMetadata();
  const currentUser = useGetUserById({ id: userId }, !isKomodorAdmin);
  const userContainsAccountAdminRole = currentUser.data?.rbacRoles?.some(
    (role) => role.name === ACCOUNT_ADMIN_RBAC_ROLE
  );

  if (isKomodorAdmin || userContainsAccountAdminRole) {
    return false;
  }
  return (
    Boolean(disableNodeViewWithoutWorkspace) &&
    resource.Kind === KubernetesNodesResource.Kind &&
    currentWorkspace?.kind !== WorkspaceKind.Label
  );
};
