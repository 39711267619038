import { useQueries, useQuery } from "@tanstack/react-query";

import {
  getWorkloadCpuMetricsUrl,
  getWorkloadMemoryMetricsUrl,
  MetricsApiGetWorkloadCpuMetricsRequest,
  MetricsApiGetWorkloadMemoryMetricsRequest,
  WorkloadMetricsDataPoints,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

import { Dictionary } from "@/shared/types/Dictionary";

export const GET_METRICS_WORKLOAD_MEMORY_PATH = "/workload/memory";
export const GET_METRICS_WORKLOAD_CPU_PATH = "/workload/cpu";

type GetWorkloadMetrics = {
  type: "cpu" | "memory";
  params:
    | MetricsApiGetWorkloadMemoryMetricsRequest
    | MetricsApiGetWorkloadCpuMetricsRequest;
  enabled?: boolean;
};
export type WorkloadsMetricsRequestsParams =
  | MetricsApiGetWorkloadMemoryMetricsRequest[]
  | MetricsApiGetWorkloadCpuMetricsRequest[];

type GetWorkloadsMetricsParams = {
  type: "cpu" | "memory";
  params: WorkloadsMetricsRequestsParams;
  enabledClusters: Dictionary<boolean>;
};

export const useGetWorkloadMetrics = ({
  type,
  params,
  enabled,
}: GetWorkloadMetrics) => {
  const apiClient = useMetricsApiClient();
  let url = "";
  try {
    url =
      type === "cpu"
        ? getWorkloadCpuMetricsUrl(params, apiClient.defaults.baseURL ?? "")
        : getWorkloadMemoryMetricsUrl(params, apiClient.defaults.baseURL ?? "");
  } catch (e) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  const queryKey =
    type === "cpu"
      ? GET_METRICS_WORKLOAD_CPU_PATH
      : GET_METRICS_WORKLOAD_MEMORY_PATH;

  return useQuery(
    [queryKey, params],
    async () => await apiClient.get<WorkloadMetricsDataPoints>(url),
    { enabled: enabled && !!url }
  );
};

export const useGetWorkloadsMetrics = ({
  type,
  params,
  enabledClusters,
}: GetWorkloadsMetricsParams) => {
  const apiClient = useMetricsApiClient();
  const queryKey =
    type === "cpu"
      ? GET_METRICS_WORKLOAD_CPU_PATH
      : GET_METRICS_WORKLOAD_MEMORY_PATH;

  return useQueries({
    queries: params.map((requestParams) => {
      const url =
        type === "cpu"
          ? getWorkloadCpuMetricsUrl(
              requestParams,
              apiClient.defaults.baseURL ?? ""
            )
          : getWorkloadMemoryMetricsUrl(
              requestParams,
              apiClient.defaults.baseURL ?? ""
            );
      const enabled = Boolean(enabledClusters[requestParams.clusterName]);
      return {
        queryKey: [queryKey, requestParams],
        queryFn: async () =>
          await apiClient.get<WorkloadMetricsDataPoints>(url),
        enabled,
      };
    }),
  });
};
