import React from "react";
import Link from "@mui/material/Link";
import styled from "styled-components";

import { KubernetesJobResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const ArgoWorkflowKind = "ArgoWorkflows.Workflows";

const shouldShowLink = (kind: string): boolean => {
  return kind !== KubernetesJobResource.Kind && kind !== ArgoWorkflowKind;
};

type ServiceTextLinkProps = {
  id: string;
  serviceName: string;
  serviceKind: string;
};

export const ServiceTextLink: React.FC<ServiceTextLinkProps> = ({
  serviceName,
  id,
  serviceKind,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const onClick = () => {
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByServiceId,
      serviceId: id,
    });
  };

  if (!id) return <>{serviceName}</>;
  if (!shouldShowLink(serviceKind)) return <>{serviceName}</>;

  return <StyledLink onClick={onClick}>{serviceName}</StyledLink>;
};
