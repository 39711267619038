import React, { useEffect, useMemo, useRef } from "react";
import { muiColors, theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import {
  issueSelector,
  komodorServiceSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useSnapshotTabs } from "../common/useSnapshotTabs";
import { useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useScrollTracking } from "../common/useScrollTracking";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { AvailabilityInvestigationCheckType } from "../types";

import { IntroductionExplanations } from "./IntroductionExplanation";
import { IntroductionSummary } from "./IntroductionSummary";
import { IntroductionSnapshotOutput } from "./types";
import {
  getExplanationCategory,
  OOMKilledReason,
  transformSecondsToText,
  updateUnhealthyReasonSnapshot,
} from "./utils";
import { AiInvestigationSection } from "./AiInvestigation";

import { useShowAiInvestigationSection } from "@/components/common/ProcessList/details/WorkflowIssueEventDetails/triage/AvailabilityTriage/sections/useShowAiInvestigationSection";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const IntroductionComponent: React.FC = () => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const issue = useInvestigationModeStore(issueSelector);
  const service = useInvestigationModeStore(komodorServiceSelector);
  const { nextStep, goToNextStep } = useNextStep();
  const { demoOomKilled } = useOverridableFlags();

  const { firstSnapshot, lastSnapshot } = useMemo(() => {
    const firstSnapshot = updateUnhealthyReasonSnapshot(
      issue?.results?.introduction?.output?.firstSnapshot,
      issue?.results?.unhealthyPods?.output?.firstSnapshot
    );
    const lastSnapshot = updateUnhealthyReasonSnapshot(
      issue?.results?.introduction?.output?.lastSnapshot,
      issue?.results?.unhealthyPods?.output?.lastSnapshot
    );

    return { firstSnapshot, lastSnapshot };
  }, [issue]);

  const [selectedSnapshot, SnapshotTabs] =
    useSnapshotTabs<IntroductionSnapshotOutput>(
      firstSnapshot,
      lastSnapshot,
      firstSnapshot?.reasons?.some(
        (reason: string) =>
          getExplanationCategory(reason, !!demoOomKilled) === OOMKilledReason
      )
    );

  const { duration, minAvailable } = useMemo(() => {
    return issue?.workflowConfiguration?.variables ?? {};
  }, [issue?.workflowConfiguration?.variables]);

  const stepScroller = useScrollTracking<HTMLDivElement>();
  const explanationSection = useRef<HTMLDivElement>(null);
  const scrollToExplanation = () => {
    stepScroller.current &&
      explanationSection.current &&
      stepScroller.current.scrollTo({
        top:
          explanationSection.current.offsetTop -
          stepScroller.current.offsetTop -
          16,
        behavior: "smooth",
      });
  };

  useEffect(() => {
    reportLoadingState("introductionData", !issue);
  }, [issue, reportLoadingState]);

  const aiInvestigationSection = useShowAiInvestigationSection(
    issue?.id ?? "",
    service,
    issue?.results[AvailabilityInvestigationCheckType.KlaudiaSession]?.output,
    issue?.closedAt ?? null,
    issue?.reasons
  );

  if (!selectedSnapshot) {
    return null;
  }

  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          First, let's understand what went wrong
        </Typography>
        {duration && minAvailable ? (
          <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
            Available pods were less than {minAvailable} for more than{" "}
            {transformSecondsToText(duration)}
          </Typography>
        ) : null}
      </StepHeader>
      <StepContent ref={stepScroller}>
        {SnapshotTabs}
        <IntroductionSummary
          introductionSnapshot={selectedSnapshot}
          onWhatIsItClicked={scrollToExplanation}
        />
        {aiInvestigationSection.showSection && issue && service ? (
          <AiInvestigationSection wfRun={issue} service={service} />
        ) : null}

        {selectedSnapshot?.reasons && selectedSnapshot?.reasons.length > 0 && (
          <IntroductionExplanations
            ref={explanationSection}
            reasons={selectedSnapshot.reasons}
            subReason={selectedSnapshot.subReason}
            showSubReason={selectedSnapshot === firstSnapshot}
          />
        )}
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="contained"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.IntroductionStep
                .StartInvestigatingButton
            }
          >
            Start investigating
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};

export const Introduction: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.investigationModeIntroduction,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <IntroductionComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
