import { muiPalette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import styled, { css } from "styled-components";

export const cellsStyleClasses = css`
  .service-name-cell {
    padding: 10px 4px 10px 10px;
    vertical-align: middle;
    .remove-service-button {
      visibility: hidden;
      height: fit-content;
    }
    :hover {
      background-color: ${muiPalette.action?.hover};
      text-decoration: underline;
      cursor: pointer;
      .remove-service-button {
        visibility: visible;
      }
    }
  }

  .containers-cell {
    vertical-align: top;
  }
`;

export const NameFieldCell = styled(Typography).attrs({
  variant: "body2",
  color: "text.primary",
  fontWeight: 500,
})`
  margin-block-end: 4px;
`;
