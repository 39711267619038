import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { Divider } from "@komodorio/design-system/deprecated";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import DaemonSet from "../../resources/daemonset";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../../resources/deployment";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import StatefulSet from "../../resources/statefulset";
import ResponsiveLayout from "../../../common/ResponsiveLayout";
import {
  InsightResult,
  InsightsApiGetInsightsResultsByServiceRequest,
} from "../../../../generated/insights";
import { useInsightsAPIPost } from "../../../../shared/hooks/insights-api/client";
import { INSIGHTS_RESULTS_BY_SERVICE_ENDPOINT } from "../../../../shared/hooks/insights-api/requestResponseMaps";
import { roundToNearestSecondsAsDate } from "../../../../shared/hooks/common-api/utils";
import InsightDetails from "../../../Insights/InsightDetails";
import InsightsCard from "../../../Insights/InsightsCard";
import {
  DeprecatedAPIs,
  InsightsIdentifiers,
  IssuesAfterDeploy,
  ThrottledCPU,
} from "../../../Insights/InsightsIdentifiers";
import { Dictionary } from "../../../../shared/types/Dictionary";

const Container = styled.div`
  background-color: white;
  display: flex;
`;

const CardsContainer = styled.div`
  padding: 1.5rem;
  min-width: 290px;
`;

const DetailsContainer = styled.div`
  width: 100%;
`;

export interface InsightsTabProps {
  resource: Deployment | StatefulSet | DaemonSet;
}

export const InsightsTab: React.FC<InsightsTabProps> = ({ resource }) => {
  const now = roundToNearestSecondsAsDate(60);
  const aWeekAgo = useMemo(
    () => new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
    [now]
  );

  const params = useMemo<InsightsApiGetInsightsResultsByServiceRequest>(
    () => ({
      cluster: resource.cluster,
      namespace: resource.namespace,
      shortResourceName: resource.name,
      kind: resource.kind,
      fromEpoch: aWeekAgo.getTime().toString(),
      toEpoch: now.getTime().toString(),
    }),
    [
      aWeekAgo,
      now,
      resource.cluster,
      resource.kind,
      resource.name,
      resource.namespace,
    ]
  );

  const { data, loading } = useInsightsAPIPost(
    INSIGHTS_RESULTS_BY_SERVICE_ENDPOINT,
    params
  );

  const filteredInsights = useMemo(
    () =>
      data?.filter((insight) =>
        [ThrottledCPU, IssuesAfterDeploy, DeprecatedAPIs].includes(
          insight.insightType
        )
      ) ?? [],
    [data]
  );

  const aggregatedInsights = useMemo(() => {
    const insightsByType = filteredInsights.reduce<Dictionary<InsightResult[]>>(
      // [CU-86c1gn74n] fix max-params
      // eslint-disable-next-line max-params
      (acc, insight, index) => {
        let insightType = insight.insightType;
        if (insightType === IssuesAfterDeploy) {
          insightType = IssuesAfterDeploy + index.toString();
        }
        acc[insightType] = [...(acc[insightType] ?? []), insight];
        return acc;
      },
      {}
    );
    return Object.entries(insightsByType).map(([type, insights]) => insights);
  }, [filteredInsights]);

  const [selectedInsightIndex, setSelectedInsightIndex] = useState<number>(0);

  const onCardClick = (index: number) => {
    setSelectedInsightIndex(index);
  };

  const insightCards = useMemo(
    () => (
      <div>
        {aggregatedInsights.map((insights, index) => {
          const insightType = InsightsIdentifiers[insights[0].insightType];
          const insightData = insightType.aggregateInsightData(insights);
          return (
            <InsightsCard
              key={insights[0].uid}
              title={insightType.cardTitle.title}
              description={insightType.descriptionCallback(insightData)}
              selected={selectedInsightIndex === index}
              icon={insightType.cardTitle.icon}
              loading={loading}
              onClick={() => onCardClick(index)}
            />
          );
        })}
      </div>
    ),
    [aggregatedInsights, loading, selectedInsightIndex]
  );

  return (
    <ResponsiveLayout>
      <Container>
        <CardsContainer>
          <Typography variant="h4" color={muiColors.gray[800]}>
            Insights ({aggregatedInsights?.length ?? 0})
          </Typography>
          <br />
          {insightCards}
        </CardsContainer>
        <Divider variant={"vertical"} />
        <DetailsContainer>
          {aggregatedInsights.length ? (
            <InsightDetails
              key={selectedInsightIndex}
              results={aggregatedInsights[selectedInsightIndex]}
              resource={resource}
            />
          ) : null}
        </DetailsContainer>
      </Container>
    </ResponsiveLayout>
  );
};
