import React, { useEffect, useState } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Node from "../../../../../../ResourceView/resources/node";
import { useTransformSingleMetricData } from "../../../../MetricsTab/hooks/metricsTabHooks";
import { NodeMetricsTabContextProvider } from "../../../context/NodeMetricsTabContext";
import {
  graphHeight,
  timelineTicksNum,
} from "../../../../MetricsTab/constants";
import { GraphContainerWithStatus } from "../../../../../../Metrics/GraphContainerWithStatus";
import { useFormatMetricsResponse } from "../../../../../../Metrics/useMetrics";
import {
  PODS_COUNT_DATA_KEY,
  podsCountTooltipItems,
  usePodsCountLine,
} from "../../../../../../Metrics/graphEntity/podsCount/podsCount";
import { CountGraph } from "../../../../../../Metrics/graphs/CountGraph";
import { METRICS_NODE_PODS_COUNT_ENDPOINT } from "../../../../../../../shared/hooks/metrics-api/requestResponseMaps";
import { MetricsResponse } from "../../../../../../../generated/metricsApi";
import { useMetricsAPIGet } from "../../../../../../../shared/hooks/metrics-api/client";
import { useResourceUid } from "../../../../../../../shared/hooks/resources/useResourceUid";
import { MetricsRequestParams } from "../../types";
import { EnlargedMetricsGraph } from "../../../../../../Metrics/graphs/EnlargedMetricsGraph";
import { MetricsTimeSelector } from "../../MetricsPreferences/MetricsTimeSelector";
import { GraphProps } from "../../MetricsPreferences/types";
import { useNodeMetricsWithZoom } from "../../hooks/useMetricsWithZoom";

import { useNodeMetricsTabContext } from "@/components/ResourceView/tabs/NodeMetricsTab/context/useNodeMetricsTabContext";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type NodePodsRequestParams = Omit<MetricsRequestParams, "endTimestamp">;

type NodesPodsCountGraphProps = {
  node: Node;
  requestParams: NodePodsRequestParams;
  id?: string;
  graphProps?: GraphProps;
};
export const NodesPodsCountGraph: React.FC<NodesPodsCountGraphProps> = ({
  node,
  requestParams,
  id,
  graphProps,
}) => {
  const [isPodsCountGraphEnlarged, setIsPodsCountGraphEnlarged] =
    useState(false);
  const { uid, loading, error, podsCountData, Line, timeWindow } = usePodsCount(
    node,
    requestParams
  );

  return (
    <>
      {isPodsCountGraphEnlarged && (
        <NodeMetricsTabContextProvider givenTimeWindow={timeWindow}>
          <EnlargedNodesPodsCountGraph
            node={node}
            requestParams={requestParams}
            onClose={() => setIsPodsCountGraphEnlarged(false)}
          />
        </NodeMetricsTabContextProvider>
      )}
      <GraphContainerWithStatus
        uid={uid}
        loading={loading}
        error={error}
        hasGraphData={
          !error && !loading && !requestParams.pause && podsCountData.length > 0
        }
        title={"Pods Count"}
        onEnlargeGraph={() => setIsPodsCountGraphEnlarged(true)}
      >
        <CountGraph
          data={podsCountData}
          timelineTicksNum={timelineTicksNum}
          graphHeight={graphHeight}
          tooltipItems={podsCountTooltipItems}
          syncId={id}
          {...graphProps}
        >
          {Line}
        </CountGraph>
      </GraphContainerWithStatus>
    </>
  );
};

export const EnlargedNodesPodsCountGraph = ({
  node,
  requestParams,
  onClose,
}: {
  node: Node;
  requestParams: NodePodsRequestParams;
  onClose: () => void;
  graphProps?: GraphProps;
}) => {
  const { fromEpoch, toEpoch, toEpochMs, graphProps } =
    useNodeMetricsWithZoom();

  const { uid, loading, error, podsCountData, Line } = usePodsCount(node, {
    ...requestParams,
    fromEpoch,
    toEpoch,
    toEpochMs,
  });

  return (
    <EnlargedMetricsGraph
      title={"Pods Count"}
      controls={[<MetricsTimeSelector key="MetricsTimeSelector" />]}
      onClose={onClose}
      uid={uid}
      hasGraphData={podsCountData.length > 0}
      loading={loading}
      error={error}
    >
      <CountGraph
        data={podsCountData}
        timelineTicksNum={timelineTicksNum}
        tooltipItems={podsCountTooltipItems}
        {...graphProps}
      >
        {Line}
      </CountGraph>
    </EnlargedMetricsGraph>
  );
};

const usePodsCount = (node: Node, requestParams: NodePodsRequestParams) => {
  const { timeWindow } = useNodeMetricsTabContext();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  const { loading, data, error } = useMetricsAPIGet<MetricsResponse>(
    METRICS_NODE_PODS_COUNT_ENDPOINT,
    requestParams
  );

  const podsCountState = useFormatMetricsResponse(data);

  const podsCountData = useTransformSingleMetricData({
    name: PODS_COUNT_DATA_KEY,
    metricsData: podsCountState ?? [],
    timeWindow: timeWindow.timeframe,
  });

  const Line = usePodsCountLine();

  useEffect(() => {
    reportLoadingState("podsCount", loading);
  }, [loading, reportLoadingState]);

  const uid = useResourceUid(node);

  return {
    timeWindow,
    podsCountData,
    Line,
    uid,
    error,
    loading,
  };
};
