import { useCallback } from "react";

import { useViolation } from "../../../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../../../hooks/drawerContentHooks";

import { useGetServiceIdFromKomodorUid } from "@/shared/hooks/useGetServiceIdFromKomodorUid";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { MonitorType } from "@/generated/monitorsApi";

export const useOpenDeployEventDrawer = (eventId: string) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { data } = useViolation();
  const { komodorUid } = useGetCommonDrawerProps(data?.data.violation);
  const { serviceId = "" } =
    useGetServiceIdFromKomodorUid(komodorUid, false) ?? {};

  return useCallback(() => {
    pushDrawer({
      drawerType: DrawerType.EventDrawerById,
      eventIds: [eventId],
      eventType: MonitorType.Deploy,
      serviceId,
    });
  }, [eventId, pushDrawer, serviceId]);
};
