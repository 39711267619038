import { ResourceData } from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageTypes";
import { formatPercentage } from "@/shared/utils/formatPercentage";

export const getUsagePercentage = ({ usage, capacity }: ResourceData) =>
  (usage / capacity) * 100;

export const formatUsagePercent = (usagePercent: number) =>
  formatPercentage(usagePercent, {
    maximumFractionDigits: 0,
    minimumIntegerDigits: 1,
  });
