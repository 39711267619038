import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridPro, GridSortModel } from "@mui/x-data-grid-pro";

import { useRightSizingTableColumns } from "../RightSizingTable/rightSizingTableHooks";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectRightSizingFilters,
  selectRightSizingPaginatedTableState,
  selectSetRightSizingPaginatedTableState,
} from "../../../store/costOptimizationStoreSelectors";
import {
  CostRightSizingSummary,
  RightSizingOrderByColumnEnum,
  RightSizingOrderByOrderEnum,
} from "../../../../../generated/metricsApi";
import { ContentContainer } from "../../../../reliability/components/pages/violations/ViolationsDrawer/components/shared/History/historyStyles";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";
import {
  DataGridContainer,
  PaddedCard,
} from "../../styles/CostOptimizationStyledComponents";
import { FetchError } from "../../shared/errors/FetchError";
import {
  useGetFilterModel,
  useGetPaginationModel,
  useOnFilterChange,
  useRightSizingRowsEnabled,
  useSetPaginationModel,
} from "../hooks/rightSizingPaginatedTableHooks";
import { initialPaginatedTableResponse } from "../../../store/initialState";
import { useScopeAsServerRequestParam } from "../../../hooks/useScopeAsServerRequestParam";
import { LinesLoader } from "../../../../common/loaders/Line";
import { CustomToolbar } from "../../shared/tableComponents/CustomToolbar";
import { getTodayDate } from "../../../../../shared/utils/dateUtils";

import {
  calculateLoaderHeight,
  getRowsLength,
} from "./rightSizingPaginatedTableUtils";
import { CsvPaginatedExport } from "./CsvPaginatedExport";

import { EmptyTableResults } from "@/components/common/table/EmptyResults";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useRegisterComponentLoading } from "@/components/CostOptimizationView/hooks/pageDataLoadedHooks";

const LoaderContainer = styled.div<{ height: string }>`
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EMPTY_ROWS = [] as CostRightSizingSummary[];
const PAGE_SIZE_OPTIONS = [5, 10, 20];
const DEFAULT_SORT_COLUMN = "potentialSaving";
const DEFAULT_SORT_ORDER = "desc";

const {
  exportButton: exportButtonAriaLabel,
  tableContent: tableContentAriaLabel,
  table: tableAriaLabel,
} = costOptimizationAriaLabels.rightSizingPage.paginatedTable;

export const RightSizingPaginatedTable: React.FC = () => {
  const columns = useRightSizingTableColumns(true);

  const paginationModel = useGetPaginationModel();

  const scope = useScopeAsServerRequestParam();

  const { reportLoadingState, loadingState } =
    useDatadogReportLoadingTimeContext();
  const componentLoadingReportName = "RightSizingSummaryData";
  useRegisterComponentLoading(componentLoadingReportName);

  const { strategy } = useCostOptimizationStore(selectRightSizingFilters);

  const setRightSizingPaginatedTableState = useCostOptimizationStore(
    selectSetRightSizingPaginatedTableState
  );

  const rightSizingPaginatedTableState = useCostOptimizationStore(
    selectRightSizingPaginatedTableState
  );

  const { pageSize, error, tokens, filterBy } = rightSizingPaginatedTableState;

  const isFilteringTable = !!filterBy?.value;

  const { data, isFetching, isLoading } = useRightSizingRowsEnabled();

  useEffect(() => {
    if (loadingState[componentLoadingReportName] !== isLoading) {
      reportLoadingState(componentLoadingReportName, isLoading);
    }
  }, [isLoading, loadingState, reportLoadingState]);

  useEffect(() => {
    setRightSizingPaginatedTableState(initialPaginatedTableResponse);
    return () => {
      setRightSizingPaginatedTableState(initialPaginatedTableResponse);
    };
  }, [scope, strategy, setRightSizingPaginatedTableState]);

  const setPaginationModel = useSetPaginationModel();

  const onSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (model.length === 0) {
        return;
      }

      setRightSizingPaginatedTableState({
        ...rightSizingPaginatedTableState,
        orderBy: {
          column: model[0].field as RightSizingOrderByColumnEnum,
          order: model[0].sort as RightSizingOrderByOrderEnum,
        },
      });
    },
    [rightSizingPaginatedTableState, setRightSizingPaginatedTableState]
  );

  const onFilterChange = useOnFilterChange();
  const filterModel = useGetFilterModel();

  const resetTableFilters = () => {
    onFilterChange({
      items: [],
    });
  };

  const EmptyResultsComponent = (
    <EmptyTableResults
      onClearFilters={isFilteringTable ? resetTableFilters : undefined}
    />
  );

  const content =
    isFetching && !isFilteringTable ? (
      <LoaderContainer height={calculateLoaderHeight(pageSize)}>
        <LinesLoader />
      </LoaderContainer>
    ) : error ? (
      <FetchError />
    ) : (
      <DataGridContainer>
        <DataGridPro
          rows={data?.data?.rows?.length ? data?.data?.rows : EMPTY_ROWS}
          loading={isFetching}
          columns={columns}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pagination
          initialState={{
            sorting: {
              sortModel: [
                { field: DEFAULT_SORT_COLUMN, sort: DEFAULT_SORT_ORDER },
              ],
            },
          }}
          sx={{
            ".MuiTablePagination-displayedRows": {
              display: "none",
            },
          }}
          rowCount={
            data?.data?.hasMorePages
              ? Number.MAX_VALUE
              : getRowsLength(tokens, data?.data?.rows?.length ?? 0, pageSize)
          }
          paginationMode="server"
          filterMode="server"
          filterModel={filterModel}
          filterDebounceMs={1000}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterChange}
          slots={{
            toolbar: () => (
              <CustomToolbar
                exportButton={
                  <CsvPaginatedExport
                    fileName={`right-sizing-${getTodayDate()}`}
                    ariaLabel={exportButtonAriaLabel}
                  />
                }
                showExport={true}
              />
            ),
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => EmptyResultsComponent,
            noResultsOverlay: () => EmptyResultsComponent,
          }}
          aria-label={tableContentAriaLabel}
        />
      </DataGridContainer>
    );

  return (
    <PaddedCard aria-label={tableAriaLabel}>
      <ContentContainer> {content}</ContentContainer>
    </PaddedCard>
  );
};
