// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export async function executeAPIGet(
  path: string,
  authorization: string,
  appViewId: string
): Promise<Response> {
  return fetch(path, {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: authorization,
      ...(appViewId && { "X-AppView-Id": appViewId }),
    },
  });
}

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export async function executeAPIPost(
  path: string,
  authorization: string,
  body: string,
  appViewId: string
): Promise<Response> {
  return fetch(path, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: authorization,
      ...(appViewId && { "X-AppView-Id": appViewId }),
    },
    body,
  });
}
