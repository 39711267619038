import React, { createContext, useState, useCallback } from "react";
import { UseQueryResult, useQueries } from "@tanstack/react-query";

import { getSessionQuery } from "../../hooks/ai-api/client/sessions/useGetSession";
import { useAiApiClient } from "../../hooks/ai-api/client/apiClient";

import { SessionGetResponse, SessionsBody } from "@/generated/aiApi";
import { INVESTIGATION_POLL_INTERVAL } from "@/components/ResourceView/AiInvestigation/pod/constants";
import { DrawerStatePush } from "@/shared/store/drawersStackStore/types";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";

export type SessionIdentifier = {
  id: string;
  requestId: string;
  kind: string;
  cluster: string;
  namespace?: string;
  name: string;
  type: FlowType;
};

export type MinimizedSession = {
  session: SessionGetResponse["session"];
  sessionIdentifier: SessionIdentifier;
} & DrawerStatePush;

export interface AiInvestigationsSessionsManager {
  openDrawerIds: string[];
  addDrawerSessionId: (id: string) => void;
  addMinimizedSession: (
    type: FlowType,
    sessionIdentifier: SessionsBody,
    data: DrawerStatePush
  ) => void;
  removeMinimizedSession: (id: string) => void;
  investigationsQueries: Array<UseQueryResult<MinimizedSession, unknown>>;
}

export const AiInvestigationsSessionsCtx =
  createContext<AiInvestigationsSessionsManager>({
    openDrawerIds: [],
    addDrawerSessionId: () => undefined,
    addMinimizedSession: () => undefined,
    removeMinimizedSession: () => undefined,
    investigationsQueries: [],
  });

export const AiInvestigationsSessionsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [openDrawerIds, setOpenDrawerIds] = useState<string[]>([]);
  const [minimizedSessions, setMinimizedSessions] = useState<
    Map<string, MinimizedSession>
  >(new Map());

  const addDrawerSessionId = useCallback(
    (newId: string) => {
      setOpenDrawerIds((existing) => {
        return existing.includes(newId) ? existing : [...existing, newId];
      });
    },
    [setOpenDrawerIds]
  );

  const addMinimizedSession = useCallback(
    (
      type: FlowType,
      sessionIdentifier: SessionsBody,
      data: DrawerStatePush
      // [CU-86c1gn74n] fix max-params
      // eslint-disable-next-line max-params
    ) => {
      const id = sessionIdentifier.requestId;
      const sessions = new Map<string, MinimizedSession>(minimizedSessions);
      sessions.set(id, {
        session: null,
        sessionIdentifier: { id, type, ...sessionIdentifier },
        ...data,
      });
      setMinimizedSessions(sessions);
    },
    [minimizedSessions]
  );

  const removeMinimizedSession = useCallback(
    (id: string) => {
      const resources = new Map(minimizedSessions);
      resources.delete(id);
      setMinimizedSessions(resources);
    },
    [minimizedSessions]
  );

  const apiClient = useAiApiClient();
  const investigationsQueries = useQueries({
    queries: Array.from(minimizedSessions.values()).map((minimizedSession) => {
      const query = getSessionQuery(
        apiClient,
        minimizedSession.sessionIdentifier
      );
      return {
        queryKey: query.queryKey,
        queryFn: async (): Promise<MinimizedSession> => {
          const data = await query.queryFn();
          return {
            ...minimizedSession,
            ...data,
          };
        },
        refetchInterval: INVESTIGATION_POLL_INTERVAL,
        staleTime: 0,
        cacheTime: 0,
      };
    }),
  });

  const context = {
    openDrawerIds,
    addDrawerSessionId,
    addMinimizedSession,
    removeMinimizedSession,
    investigationsQueries,
  };

  return (
    <AiInvestigationsSessionsCtx.Provider value={context}>
      {children}
    </AiInvestigationsSessionsCtx.Provider>
  );
};
