import { useState, useMemo, useEffect } from "react";

import { useGetPodsBatchesFromOwnerUid } from "../podEvent/usePodEvents";

import { useNativePodEventsByKomodorUidsFromAPI } from "./useNativePodEvents";
import { NativePodFragment } from "./types";

export const useNativePodEventsPaginationByOwnerUid = (
  uid: string,
  cluster: string,
  namespace: string,
  enable: boolean,
  fromEpoch?: Date,
  toEpoch?: Date
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const [nativePodEvents, setNativePodsEvents] =
    useState<NativePodFragment[]>();
  const [isLoadingFirstPage, setIsLoadingFirstPage] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const [podsUidsBatches, isLoadingPodBatches] = useGetPodsBatchesFromOwnerUid(
    enable ? uid : undefined,
    cluster,
    namespace,
    fromEpoch,
    toEpoch
  );
  const currentPagePods = useMemo(() => {
    return podsUidsBatches?.[currentPage];
  }, [currentPage, podsUidsBatches]);

  const [nativePodEventsForPage, , isPaginating] =
    useNativePodEventsByKomodorUidsFromAPI(
      currentPagePods?.podsUids,
      currentPagePods?.firstEventTime,
      currentPagePods?.lastEventTime
    );

  useEffect(() => {
    if (podsUidsBatches?.length === 0) {
      setIsLoadingFirstPage(false);
    }
  }, [podsUidsBatches?.length]);

  useEffect(() => {
    if (nativePodEventsForPage && !isPaginating) {
      setNativePodsEvents((prev) => {
        if (!prev) {
          return nativePodEventsForPage;
        }
        return [...prev, ...nativePodEventsForPage];
      });
      setIsLoadingFirstPage(false);
      if (podsUidsBatches) {
        setCurrentPage((prev) => {
          if (prev < podsUidsBatches.length - 1) {
            return prev + 1;
          }
          return prev;
        });
      }
    }
  }, [isPaginating, nativePodEventsForPage, podsUidsBatches]);

  return useMemo(() => {
    const isLoading = isLoadingPodBatches || isLoadingFirstPage;
    const events = !isLoading && !nativePodEvents ? [] : nativePodEvents;
    return {
      nativePodEvents: events,
      isLoading,
    };
  }, [nativePodEvents, isLoadingPodBatches, isLoadingFirstPage]);
};
