import { downloadFile } from "../../utils/downloadFile";

import { CsvData } from "./types";

export const downloadCsvFile = (fileName: string, data: CsvData) => {
  const titleKeys = Object.keys(data[0]);
  const refinedData = [];
  refinedData.push(titleKeys);

  data.forEach((item) => {
    refinedData.push(Object.values(item));
  });

  let csvContent = "";

  refinedData.forEach((row) => {
    csvContent += row.join(",") + "\n";
  });

  downloadFile({
    fileName: `${fileName}.csv`,
    data: csvContent,
  });
};
