import React, { useEffect } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";

import { CLUSTERS_ROUTE } from "@/components/routes/routes";
import { getResourceRoutePath } from "@/components/Inspection/inspectionConfiguration/SupportedKubernetesResources";
import {
  KubernetesNodesResource,
  KubernetesPodsResource,
} from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";
import { LocalStorageItem } from "@/shared/utils/localStorageSettings";
import { ONE_WEEK_IN_MS } from "@/shared/constants/time";

const g2WidgetClosedAtLS = new LocalStorageItem("g2WidgetClosedAt");

const TWO_WEEKS_MS = 2 * ONE_WEEK_IN_MS;
const hasTimePassed = (date: Date, timeToPassMs: number) => {
  const startDate = new Date(date);
  const dateToPass = startDate.getTime() + timeToPassMs;
  const now = Date.now();
  return now > dateToPass;
};

const ALLOWED_PAGES = [
  CLUSTERS_ROUTE,
  getResourceRoutePath(KubernetesNodesResource),
  getResourceRoutePath(KubernetesPodsResource),
];

const Container = styled.div`
  position: fixed;
  bottom: 24px;
  right: 32px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: -16px;
  right: -16px;
  border-radius: 100%;
  background-color: white;
`;

const Img = styled.img`
  width: 200px;
  height: 100px;
  border-radius: 8px;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
`;

export const G2ReviewWidget: React.FC = () => {
  const [closedAt, setClosedAt] = useStateInLocalStorage(
    g2WidgetClosedAtLS,
    ""
  );
  const isAllowedPage = ALLOWED_PAGES.some((page) =>
    window.location.pathname.includes(page)
  );

  useEffect(() => {
    // Fetch and insert the JSON-LD structured data
    (function (document: Document, scriptType: string) {
      fetch("https://www.g2.com/products/komodor-2024-05-13/rating_schema.json")
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const contentType = response.headers.get("content-type");
          if (!contentType || !contentType.includes("application/json")) {
            throw new Error("Invalid content-type. Expected application/json.");
          }
          return response.json();
        })
        .then((data) => {
          const scriptTag = document.createElement(
            scriptType
          ) as HTMLScriptElement;
          scriptTag.type = "application/ld+json";
          scriptTag.text = JSON.stringify(data);
          const firstScript = document.getElementsByTagName(scriptType)[0];
          if (firstScript?.parentNode) {
            firstScript.parentNode.insertBefore(scriptTag, firstScript);
          }
        })
        .catch(() => {
          return;
        });
    })(document, "script");
  }, []);

  if (
    !isAllowedPage ||
    (closedAt && !hasTimePassed(new Date(closedAt), TWO_WEEKS_MS))
  ) {
    return null;
  }

  return (
    <Container>
      <CloseButton>
        <IconButton
          size="small"
          onClick={() => {
            setClosedAt(new Date().toISOString());
          }}
          aria-label="g2-review-widget-close"
        >
          <Close />
        </IconButton>
      </CloseButton>
      <a
        href="https://www.g2.com/products/komodor-2024-05-13/take_survey?utm_source=review-widget"
        target="_blank"
        rel="noopener noreferrer"
        title="Write a review of Komodor on G2"
        aria-label="g2-review-widget"
      >
        <Img
          alt="Review Komodor on G2"
          src="https://www.g2.com/products/komodor-2024-05-13/widgets/stars?color=gray&amp;type=reviews"
        />
      </a>
    </Container>
  );
};
