import { WorkflowConfigType } from "@/components/monitorsView/common/types";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

export const getViewOptionsName = (
  workflowType: WorkflowConfigType
): DatadogViewNamesEnum => {
  switch (workflowType) {
    case WorkflowConfigType.PVC:
      return DatadogViewNamesEnum.pvcIssue;
    case WorkflowConfigType.Availability:
      return DatadogViewNamesEnum.eventAvailabilityIssue;
    case WorkflowConfigType.NodeIssue:
      return DatadogViewNamesEnum.nodeIssue;
    case WorkflowConfigType.Job:
      return DatadogViewNamesEnum.jobIssue;
    case WorkflowConfigType.CronJob:
      return DatadogViewNamesEnum.cronJobIssue;
    case WorkflowConfigType.Workflow:
      return DatadogViewNamesEnum.workflowIssue;
    case WorkflowConfigType.Deploy:
      return DatadogViewNamesEnum.eventDeploy;
    default:
      return DatadogViewNamesEnum.undefinedIssue;
  }
};
