import React from "react";
import Stack from "@mui/material/Stack";

import { CanIResponse } from "@/generated/auth";
import { Result } from "@/components/Settings/Users/Permissions/CanI/Result";
import { ariaLabels } from "@/components/Settings/Users/Permissions/constants";

export const ResultSection: React.FC<{
  data?: CanIResponse;
  isLoading: boolean;
}> = ({ data, isLoading }) => {
  return (
    <Stack rowGap={1} aria-label={ariaLabels.cani.sections.results}>
      <Result
        title="Komodor Web permission"
        loading={isLoading}
        isPermitted={data?.komodorPermission ?? false}
      ></Result>
      <Result
        title="Cluster permission"
        loading={isLoading}
        isPermitted={data?.clusterPermission ?? false}
      ></Result>
    </Stack>
  );
};
