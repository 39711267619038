import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { Dictionary } from "lodash";
import { useMemo } from "react";

import { CustomServiceSelection } from "./CustomServiceSelection";
import { MAX_COMPARED_SERVICES } from "./constants";

import { ServiceInfo } from "@/shared/types/ServiceInfo";
import {
  buildKomodorUid,
  parseKomodorUid,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";

export const buildKomodorUidFromServiceInfo = (
  serviceInfo: ServiceInfo
): string => {
  const namespace = serviceInfo?.env ?? "";
  const kind = serviceInfo?.kind ?? "";
  return buildKomodorUid({
    kind,
    namespace,
    resourceName: serviceInfo?.title ?? "",
    clusterName: serviceInfo?.k8sCluster ?? "",
  });
};

const compareByBaselineMatching = (
  aServiceNameMatchesBaseline: boolean,
  bServiceNameMatchesBaseline: boolean,
  a: ServiceInfo,
  b: ServiceInfo
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  if (aServiceNameMatchesBaseline && bServiceNameMatchesBaseline) {
    return compareServiceInfo(a, b);
  }
  if (aServiceNameMatchesBaseline) {
    return -1;
  }
  if (bServiceNameMatchesBaseline) {
    return 1;
  }
  return compareServiceInfo(a, b);
};
export const sortServicesSelection = (
  toSort: Dictionary<ServiceInfo>,
  baselineServiceId: MuiSelectionOption<string> | null,
  comparedServicesIds: MuiSelectionOption<string>[] | null
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  return Object.values(toSort).sort((a, b) => {
    const aUid = buildKomodorUidFromServiceInfo(a);
    const bUid = buildKomodorUidFromServiceInfo(b);
    const isACompared = comparedServicesIds?.some(
      (service) => service.value === aUid
    );
    const isBCompared = comparedServicesIds?.some(
      (service) => service.value === bUid
    );
    const parsedBaselineKomodorUid = baselineServiceId
      ? parseKomodorUid(baselineServiceId.value)
      : null;
    const aServiceNameMatchesBaseline =
      parsedBaselineKomodorUid?.name === a.title;
    const bServiceNameMatchesBaseline =
      parsedBaselineKomodorUid?.name === b.title;

    if (isACompared && isBCompared) {
      // If both are already selected, sort by baseline matching name first, then alphabetically by cluster, namespace, name
      return compareByBaselineMatching(
        aServiceNameMatchesBaseline,
        bServiceNameMatchesBaseline,
        a,
        b
      );
    }
    if (isACompared) {
      return -1;
    }
    if (isBCompared) {
      return 1;
    }

    return compareByBaselineMatching(
      aServiceNameMatchesBaseline,
      bServiceNameMatchesBaseline,
      a,
      b
    );
  });
};

export const compareServiceInfo = (a: ServiceInfo, b: ServiceInfo): number => {
  return (
    a.k8sCluster.localeCompare(b.k8sCluster) ||
    a.env.localeCompare(b.env) ||
    a.title.localeCompare(b.title)
  );
};

export const useComparedServicesOptions = (
  comparedServicesIds: MuiSelectionOption<string>[] | null,
  services: ServiceInfo[],
  baselineServiceId: MuiSelectionOption<string> | null
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): MuiSelectionOption<string>[] => {
  return useMemo(
    () =>
      services
        .filter((service) => {
          const uid = buildKomodorUidFromServiceInfo(service);
          const baselineParsedKomodorUid = baselineServiceId
            ? parseKomodorUid(baselineServiceId.value)
            : null;
          return (
            uid !== baselineServiceId?.value &&
            baselineParsedKomodorUid?.kind === service.kind
          );
        })
        .map<MuiSelectionOption<string> | null>((serviceInfo) => {
          if (!serviceInfo) {
            return null;
          }
          const value = buildKomodorUidFromServiceInfo(serviceInfo);
          return {
            render: () => (
              <CustomServiceSelection
                clusterName={serviceInfo.k8sCluster}
                namespace={serviceInfo.env}
                serviceName={serviceInfo.title}
              />
            ),
            label: serviceInfo.title,
            value: value,
            disabled:
              !!comparedServicesIds &&
              comparedServicesIds?.length >= MAX_COMPARED_SERVICES &&
              comparedServicesIds.length > 0 &&
              !comparedServicesIds.some((service) => {
                const uid = buildKomodorUidFromServiceInfo(serviceInfo);
                return service.value === uid;
              }),
          };
        })
        .filter((s): s is MuiSelectionOption<string> => s !== null),
    [baselineServiceId, comparedServicesIds, services]
  );
};

export const useBaselineServiceOptions = (
  baselineServiceId: MuiSelectionOption<string> | null,
  services: Dictionary<ServiceInfo>
): MuiSelectionOption<string>[] => {
  return useMemo(() => {
    return Object.values(services)
      .sort((a, b) => {
        const aUid = buildKomodorUidFromServiceInfo(a);
        const bUid = buildKomodorUidFromServiceInfo(b);
        if (aUid === baselineServiceId?.value) {
          return -1;
        }
        if (bUid === baselineServiceId?.value) {
          return 1;
        }
        return compareServiceInfo(a, b);
      })
      .map((serviceInfo) => {
        return {
          label: serviceInfo.title,
          value: buildKomodorUidFromServiceInfo(serviceInfo) ?? "",
        };
      });
  }, [baselineServiceId?.value, services]);
};
