import {
  storedLineNumberEnabled,
  storedTextWrappingEnabled,
} from "../logsViewerGlobalSettings";

import { LogsViewerStore } from "./types";

export const initialState: LogsViewerStore = {
  isLiveTailEnabledByUser: false,
  isAnalyzingLogs: false,
  viewSettings: {
    showTimestamp: true,
    showLineNumber: (storedLineNumberEnabled.get() ?? "true") === "true",
    wrapText: (storedTextWrappingEnabled.get() ?? "true") === "true",
  },
  setLiveTailEnabled: () => undefined,
  setLiveTailEnabledByUser: () => undefined,
  setAnalyzingLogs: () => undefined,
  setViewSettings: () => undefined,
};
