import { cloneDeep, get, unset } from "lodash";
import { Typography } from "@komodorio/design-system/deprecated";
import React, { RefObject, useEffect, useMemo, useRef } from "react";
import YAML from "yaml";
import Box from "@mui/material/Box";

import Resource from "../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useResource } from "../useResource";
import { ResourceResultType, ResourceTab } from "../types";

import { DescribeLoader } from "./DescribeTab/common/DescribeLoader";
import { DescribeContainer } from "./DescribeTab/common/DescribeSection";

import { LazyEditor } from "@/components/common/LazyEditor";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useResourceObjectAsYaml = (
  fullObj: unknown,
  options?: Options
) => {
  return useMemo(() => {
    if (!options?.dropManagedFields) {
      return YAML.stringify(fullObj, { indent: 2 });
    }

    const clonedResource = cloneDeep(fullObj);
    if (get(clonedResource, "metadata.managedFields")) {
      unset(
        clonedResource as Record<string, unknown>,
        "metadata.managedFields"
      );
    }

    return YAML.stringify(clonedResource, { indent: 2 });
  }, [options?.dropManagedFields, fullObj]);
};

type Options = {
  dropManagedFields?: boolean;
};
interface ResourceYAMLTabProps {
  resource: Resource & { fullObj?: unknown };
  options?: Options;
}

type ExtendedResourceYAMLTabProps = ResourceYAMLTabProps & {
  divRef: RefObject<HTMLDivElement>;
};

export const ResourceYAMLTabWithData: React.FC<
  ExtendedResourceYAMLTabProps
> = ({ resource, options, divRef }) => {
  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();
  const stringifiedResourceAsYaml = useResourceObjectAsYaml(
    resource.fullObj,
    options
  );

  setMainContainerDivRef(divRef);
  useEffect(() => {
    reportLoadingState("YAML", !resource.id);
  }, [reportLoadingState, resource.id]);

  if (!resource.id) return <DescribeLoader />;

  const title = `${resource.kind} YAML`;
  if (!stringifiedResourceAsYaml) {
    return (
      <DescribeContainer aria-label={title}>
        <p>{title} is not available.</p>
      </DescribeContainer>
    );
  }

  return (
    <DescribeContainer aria-label={title}>
      <LazyEditor
        width="100%"
        height="100%"
        readOnly
        value={stringifiedResourceAsYaml}
        mode="yaml"
      />
    </DescribeContainer>
  );
};

export const ResourceYAMLTabWithoutData: React.FC<
  ExtendedResourceYAMLTabProps
> = ({ resource: resourceWithoutData, options, divRef }) => {
  const {
    agentId,
    cluster,
    namespace,
    kind: resourceType,
    name: resourceName,
  } = resourceWithoutData ?? {};
  const { resource, failureMessage } = useResource({
    agentId,
    cluster,
    namespace,
    resourceName,
    resourceType,
    preferredResultType: ResourceResultType.RawResource,
  });

  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();

  setMainContainerDivRef(divRef);
  useEffect(() => {
    reportLoadingState("YAML", true);
  }, [reportLoadingState]);

  if (!resource && !failureMessage) {
    return <DescribeLoader />;
  }

  if (failureMessage) {
    return <Typography>{failureMessage}</Typography>;
  }

  return resource ? (
    <ResourceYAMLTabWithData
      resource={resource}
      options={options}
      divRef={divRef}
    />
  ) : null;
};

export const ResourceYAMLTab: React.FC<ResourceYAMLTabProps> = (props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.Yaml);

  return (
    <Box
      ref={divRef}
      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <DatadogReportLoadingTimeContextProvider
        {...getLoadingTimeContextPropsForResource()}
      >
        {props.resource.fullObj ? (
          <ResourceYAMLTabWithData {...props} divRef={divRef} />
        ) : (
          <ResourceYAMLTabWithoutData {...props} divRef={divRef} />
        )}
      </DatadogReportLoadingTimeContextProvider>
    </Box>
  );
};
