import React from "react";

import { MetricsControlsSelector } from "../../../../common/Metrics/MetricsControlsSelector";
import { DefaultTimeframeOptions } from "../../../../../Metrics/types";

import { useNodeMetricsTabContext } from "@/components/ResourceView/tabs/NodeMetricsTab/context/useNodeMetricsTabContext";

export const MetricsTimeSelector: React.FC = () => {
  const { timeWindow, setTimeWindow } = useNodeMetricsTabContext();

  return (
    <MetricsControlsSelector
      timeWindow={timeWindow}
      setTimeWindow={setTimeWindow}
      options={DefaultTimeframeOptions}
    />
  );
};
