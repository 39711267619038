import { TokensMap } from "../../../types/costOptimizationTypes";

export const getNewTokens = (
  newPageIndex: number,
  tokens: TokensMap | undefined,
  paginationToken: string | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): TokensMap | undefined => {
  if (tokens && newPageIndex in tokens) {
    return tokens;
  }
  return { ...tokens, [newPageIndex]: paginationToken };
};

const getNumberOfPages = (tokens: TokensMap | undefined): number => {
  return Object.keys(tokens || {}).length;
};

export const getRowsLength = (
  tokens: TokensMap | undefined,
  currentRowsLength: number,
  pageSize: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): number => {
  const prevNumberOfPages = getNumberOfPages(tokens) - 1;
  return prevNumberOfPages * pageSize + currentRowsLength;
};

export const calculateLoaderHeight = (pageSize: number) => {
  switch (pageSize) {
    case 5:
      return "300px";
    case 10:
      return "600px";
    case 20:
      return "1000px";
    default:
      return "600px";
  }
};
