import React, { useMemo } from "react";
import PersonIcon from "@mui/icons-material/Person";
import ListOutlined from "@mui/icons-material/ListOutlined";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CloseButton, DrawerHeader } from "@komodorio/design-system/komodor-ui";
import { Refresh16 } from "@komodorio/design-system/icons";
import { startCase } from "lodash";

import { AuditLog, AuditLogStatus } from "@/generated/accounts";
import { DrawerSection } from "@/pages/organization-settings/account/AuditPage/components/drawer/DrawerSection";
import {
  CLASS_NAME,
  KeyValue,
} from "@/pages/organization-settings/account/AuditPage/components/drawer/KeyValue";
import {
  defaultValueRenderer,
  identityRenderer,
  k8sResourceRenderer,
  statusRenderer,
  timezoneRenderer,
} from "@/pages/organization-settings/account/AuditPage/components/valueRenderers";
import { useAccountUsersContext } from "@/shared/context/users/useAccountUsersContext";
import { LogDetails } from "@/pages/organization-settings/account/AuditPage/components/drawer/LogDetails";
import {
  AuditLogDetails,
  ResourceData,
} from "@/pages/organization-settings/account/AuditPage/types";
import { auditPageAriaLabels } from "@/pages/organization-settings/account/AuditPage/constants";

const COLUMN_GAP = 160;
const KEY_VALUE_WIDTH = `calc(50% - ${COLUMN_GAP / 2}px)`;

export const AuditLogDrawerContent: React.FC<{
  log: AuditLog;
  onClose?: () => void;
}> = ({ log, onClose }) => {
  const { users } = useAccountUsersContext();

  const shouldShowEventDataSection = useMemo(() => {
    const details = log.details as AuditLogDetails;
    return details?.data && Object.keys(details.data).length > 0;
  }, [log]);

  const additionalData: ResourceData | undefined = useMemo(() => {
    return (log.details as AuditLogDetails)?.resourceData ?? undefined;
  }, [log.details]);

  return (
    <>
      <DrawerHeader
        title={<Typography variant="h3">{startCase(log.action)}</Typography>}
        leftActions={
          onClose && (
            <CloseButton
              onClose={onClose}
              ariaLabel={auditPageAriaLabels.drawer.close}
            />
          )
        }
      />
      <Stack height="100%">
        <DrawerSection Icon={Refresh16} title="Details">
          <Stack
            maxHeight={160}
            flexWrap="wrap"
            overflow="auto"
            rowGap="16px"
            width="100%"
            columnGap="160px"
            sx={{
              [`& > .${CLASS_NAME}`]: {
                width: KEY_VALUE_WIDTH,
                height: "24px",
              },
            }}
          >
            <KeyValue
              value={log.action}
              title="Action Type"
              valueRenderer={defaultValueRenderer}
            />
            <KeyValue
              value={log.eventTime ?? ""}
              title="Timestamp"
              valueRenderer={timezoneRenderer}
            />
            <KeyValue
              value={log.status}
              title="Status"
              valueRenderer={statusRenderer}
            />
            {log.status === AuditLogStatus.Failure && (
              <KeyValue
                title="Error Message"
                value={(log.details as AuditLogDetails).responseData.message}
                valueRenderer={identityRenderer}
              />
            )}
            <KeyValue
              value={log.entityName ?? ""}
              title="Affected Resource"
              valueRenderer={identityRenderer}
            />
            <KeyValue
              value={log.entityType}
              title="Resource Type"
              valueRenderer={
                additionalData ? k8sResourceRenderer : defaultValueRenderer
              }
            />
            {!!additionalData && (
              <>
                <KeyValue
                  value={additionalData.cluster ?? ""}
                  title="Cluster"
                  valueRenderer={identityRenderer}
                />
                <KeyValue
                  value={additionalData.namespace ?? ""}
                  title="Namespace"
                  valueRenderer={identityRenderer}
                />
              </>
            )}
          </Stack>
        </DrawerSection>
        <DrawerSection Icon={PersonIcon} title="User">
          <KeyValue
            value={users[log.userId]?.email ?? log.userId}
            title="User Email"
            valueRenderer={identityRenderer}
          />
        </DrawerSection>
        {shouldShowEventDataSection && (
          <DrawerSection
            Icon={ListOutlined}
            title="Event Data"
            sx={{ flexGrow: 1, minHeight: 0 }}
          >
            <LogDetails details={log.details as AuditLogDetails} />
          </DrawerSection>
        )}
      </Stack>
    </>
  );
};
