import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";

import {
  apiV1ConfigurationsIdGetUrl,
  ConfigurationsApiApiV1ConfigurationsIdGetRequest,
  MonitorConfiguration,
} from "@/generated/monitorsApi";

export const MONITOR_CONFIGURATION_BY_ID_PATH = "/configurations/:id";

const fetchMonitorConfigurationById = async (
  apiClient: AxiosInstance,
  params: ConfigurationsApiApiV1ConfigurationsIdGetRequest
) => {
  const { data } = await apiClient.get<MonitorConfiguration>(
    apiV1ConfigurationsIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetMonitorConfigurationById = (
  params: ConfigurationsApiApiV1ConfigurationsIdGetRequest,
  enabled?: boolean
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery(
    [MONITOR_CONFIGURATION_BY_ID_PATH, params],
    () => fetchMonitorConfigurationById(apiClient, params),
    { enabled: enabled }
  );
};
