import React from "react";
import { parseISO } from "date-fns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../../groupEvents";
import { Maybe } from "../../../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { HpaDetails } from "../../../ProcessList/details/HpaDetails";
import EventGroup, { EventBase } from "../..";
import { blueForUI, blueForUIText } from "../../../../../Colors";
import { hpaType } from "../types";
import { HpaEventsResponseDataInner } from "../../../../../generated/resourcesApi";

import ConfigChangeScatterShape from "./../../shapes/config.svg?react";

export interface HpaEvent extends EventBase {
  readonly name: string;
  readonly namespace?: string;
  readonly clusterName: string;
  readonly action?: Maybe<string>;
  readonly oldDesiredReplicas?: Maybe<number>;
  readonly newDesiredReplicas?: Maybe<number>;
}

const toHpaEvent = (
  e: HpaEventsResponseDataInner,
  serviceId: string
): HpaEvent => ({
  id: e.id,
  serviceId,
  eventTime: parseISO(e.eventTime ?? ""),
  type: hpaType,
  name: e.name ?? "",
  namespace: e.namespace,
  clusterName: e.clusterName ?? "",
  action: e.action,
  oldDesiredReplicas: e.oldDesiredReplicas,
  newDesiredReplicas: e.newDesiredReplicas,
});

export const generateSummeryAndDetails = (
  action: Maybe<string> | undefined,
  oldDesiredReplicas: Maybe<number> | undefined,
  newDesiredReplicas: Maybe<number> | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): Record<string, string> => {
  let summery = "";
  let details = "";

  if (action?.toLowerCase() === "update") {
    if (Number(oldDesiredReplicas) < Number(newDesiredReplicas)) {
      summery = "Scale up";
      details = `Desired replicas ${oldDesiredReplicas} -> ${newDesiredReplicas}`;
    } else if (Number(oldDesiredReplicas) > Number(newDesiredReplicas)) {
      summery = "Scale down";
      details = `Desired replicas ${oldDesiredReplicas} -> ${newDesiredReplicas}`;
    } else {
      summery = "Update HPA";
    }
  } else if (!action) {
    summery = "HPA change";
  } else {
    summery = `${action} HPA`;
  }

  return { summery: summery, details: details };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class HpaEventGroup implements EventGroup {
  readonly backgroundColor = blueForUI;
  readonly fillColor = blueForUIText;
  readonly icon = ConfigChangeScatterShape;

  readonly events: HpaEvent[];
  readonly id;
  readonly type;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details;
  readonly status;
  readonly serviceId;
  readonly sendEventWhenClicked = true;

  constructor(e: HpaEvent) {
    const { summery, details } = generateSummeryAndDetails(
      e.action,
      e.oldDesiredReplicas,
      e.newDesiredReplicas
    );
    this.events = [e];
    this.id = `${e.serviceId}:${e.id}`;
    this.type = hpaType;
    this.summary = `${summery} - ${e.clusterName}/${e.namespace}/${e.name}`;
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.details = details;
    this.status = "Completed";
    this.serviceId = e.serviceId;
  }

  renderEventDetails(): JSX.Element {
    return <HpaDetails eventGroup={this} />;
  }
}

export const groupHpaEvents = (
  events: HpaEventsResponseDataInner[],
  serviceId: string
): HpaEventGroup[] => {
  return mapAndSort(withServices(events, serviceId), toHpaEvent).map(
    (e) => new HpaEventGroup(e)
  );
};
