import { CheckType } from "@/generated/reliabilityApi";
import { Severity } from "@/components/reliability/ReliabilityTypes";

type GenericReturnType = {
  fieldName: string;
};
type DeprecatedApisReturnType = {
  stateField: string;
  versionsAheadField: string;
};
type UnderProvisionedWorkloadsReturnType = {
  cpuField: string;
  memoryField: string;
};
type ReturnTypePerCheckType = {
  [CheckType.DeprecatedApis]: DeprecatedApisReturnType;
  [CheckType.UnderProvisionedWorkloads]: UnderProvisionedWorkloadsReturnType;
  [CheckType.NoisyNeighbor]: GenericReturnType;
  [CheckType.HpaMax]: GenericReturnType;
  [CheckType.RequestsLimitsRatio]: GenericReturnType;
  [CheckType.UnschedulableTime]: GenericReturnType;
  [CheckType.RestartingContainers]: GenericReturnType;
  [CheckType.KubernetesVersionDeprecated]: GenericReturnType;
  [CheckType.MissingTopologySpreadConstraint]: GenericReturnType;
  [CheckType.ThrottledCpu]: GenericReturnType;
  [CheckType.KubernetesEndOfLife]: GenericReturnType;
  [CheckType.HpaMinAvailability]: GenericReturnType;
  [CheckType.SinglePointOfFailure]: GenericReturnType;
  [CheckType.NodeTerminationSpotInstance]: GenericReturnType;
  [CheckType.MissingPdb]: GenericReturnType;
  [CheckType.DeploymentMissingReplicas]: GenericReturnType;
  [CheckType.NodeTerminationAutoScaling]: GenericReturnType;
  [CheckType.CpuLimitsMissing]: GenericReturnType;
  [CheckType.MemoryLimitsMissing]: GenericReturnType;
  [CheckType.CpuRequestsMissing]: GenericReturnType;
  [CheckType.MemoryRequestsMissing]: GenericReturnType;
  [CheckType.ReadinessProbeMissing]: GenericReturnType;
  [CheckType.LivenessProbeMissing]: GenericReturnType;
  [CheckType.MissingHpa]: GenericReturnType;
  [CheckType.PriorityClassNotSet]: GenericReturnType;
  [CheckType.CertificateExpiration]: GenericReturnType;
  [CheckType.SyntheticDegradedService]: GenericReturnType;
  [CheckType.SyntheticEolDeprecationOutdated]: GenericReturnType;
  [CheckType.SyntheticNodePressure]: GenericReturnType;
  [CheckType.CascadingFailure]: GenericReturnType;
  [CheckType.UnhealthyService]: GenericReturnType;
  [CheckType.UnhealthyWorkflow]: GenericReturnType;
  [CheckType.FailedJob]: GenericReturnType;
  [CheckType.FailedCronJob]: GenericReturnType;
  [CheckType.UnhealthyNode]: GenericReturnType;
  [CheckType.UnhealthyPvc]: GenericReturnType;
  [CheckType.ExternalDnsNotSynced]: GenericReturnType;
  [CheckType.ScaleDownImpact]: GenericReturnType;
};

export const getConfigurationFieldNamesByCheckType = <T extends CheckType>(
  checkType: T,
  severity?: Severity
): ReturnTypePerCheckType[T] => {
  let returnObject = {};
  switch (checkType) {
    case CheckType.NoisyNeighbor:
      returnObject = {
        victimsField: `configurations.${checkType}.victims.${
          severity ? severity : ""
        }`,
      };
      break;
    case CheckType.DeprecatedApis:
      returnObject = {
        stateField: `configurations.${checkType}.${
          severity ? severity + "." : ""
        }state`,
        versionsAheadField: `configurations.${checkType}.${
          severity ? severity + "." : ""
        }versionsAhead`,
      };
      break;
    case CheckType.UnderProvisionedWorkloads:
      returnObject = {
        cpuField: `configurations.${checkType}.cpu.${severity ? severity : ""}`,
        memoryField: `configurations.${checkType}.memory.${
          severity ? severity : ""
        }`,
      };
      break;
    default:
      returnObject = {
        fieldName: `configurations.${checkType}.${severity ? severity : ""}`,
      };
      break;
  }
  return returnObject as ReturnTypePerCheckType[T];
};

export const getConfigurationFieldNamesByCheckTypeAsList = (
  checkType: CheckType,
  severity?: Severity
): string[] => {
  const formConfigNames = getConfigurationFieldNamesByCheckType(
    checkType,
    severity
  );
  return Object.values(formConfigNames);
};
