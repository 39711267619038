import { LogsViewerStore } from "./types";

// getters
export const selectIsLiveTailEnabledByUser = (state: LogsViewerStore) =>
  state.isLiveTailEnabledByUser;
export const selectIsLiveTailEnabled = (state: LogsViewerStore) =>
  state.isLiveTailEnabledByUser;
export const selectIsAnalyzingLogs = (state: LogsViewerStore) =>
  state.isAnalyzingLogs;
export const selectViewSettings = (state: LogsViewerStore) =>
  state.viewSettings;

// setters
export const setLiveTailEnabledByUser = (state: LogsViewerStore) =>
  state.setLiveTailEnabledByUser;
export const setLiveTailEnabled = (state: LogsViewerStore) =>
  state.setLiveTailEnabled;
export const setIsAnalyzingLogs = (state: LogsViewerStore) =>
  state.setAnalyzingLogs;
export const setViewSettings = (state: LogsViewerStore) =>
  state.setViewSettings;
