import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { WORKSPACES_TOP_BAR_HEIGHT } from "@/components/TopBar/TopBar";

export const Container = styled.div`
  height: calc(100vh - ${WORKSPACES_TOP_BAR_HEIGHT} - 1px);
  display: flex;
  flex-direction: column;
`;

export const WhiteContainer = styled.div`
  background-color: ${muiColors.common.white};
`;

export const Content = styled.div`
  height: -webkit-fill-available;
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
