import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "@komodorio/design-system/komodor-ui";

import { ALL_SELECTOR } from "../../../../../constants/reliabilityConstants";
import { Dictionary } from "../../../../../../../shared/types/Dictionary";

export const toMultiOption = (value: string): MuiSelectionOption<string> => ({
  value,
  label: value,
});

export const toMultiOptions = (
  values: string[] | undefined
): MuiSelectionOption<string>[] => values?.map(toMultiOption) ?? [];

export const optionsToValue = (
  options: MuiSelectionOption<MuiSelectionOptionValue>[]
): MuiSelectionOptionValue[] => options.map(({ value }) => value);

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export const filterUniqueValues = <T>(value: T, index: number, self: T[]) => {
  return self.indexOf(value) === index;
};

export const isAllOptionSelected = (
  options: MuiSelectionOption<MuiSelectionOptionValue>[]
) => options.some(({ value }) => value === ALL_SELECTOR);

export const getInitialValues = (
  selectedValues: string[],
  currentScopeValues?: string[]
): string[] => {
  if (!selectedValues.length && currentScopeValues) {
    return currentScopeValues.length
      ? currentScopeValues
      : [ALL_SELECTOR, ...currentScopeValues];
  }

  return selectedValues.filter(filterUniqueValues);
};

export const getNamespaceOptions = (clusterNames: Dictionary<string[]>[]) => {
  return clusterNames
    .map((namespace) => Object.keys(namespace))
    .flat()
    .filter(filterUniqueValues)
    .map(toMultiOption);
};

export const getServiceOptions = (
  clusterNames: Dictionary<string[]>[],
  namespaces: string[]
) => {
  return clusterNames
    .reduce<string[][]>((acc, curr) => {
      const services = Object.entries(curr).reduce<string[]>(
        (all, [ns, services]) => {
          if (namespaces.includes(ns) || namespaces.includes(ALL_SELECTOR)) {
            all.push(...services);
          }
          return all;
        },
        []
      );
      acc.push(services);
      return acc;
    }, [])
    .flat()
    .filter(filterUniqueValues)
    .map(toMultiOption);
};

export const filterAllSelectionFromList = (list: string[]) =>
  list.filter((item) => item !== ALL_SELECTOR);
