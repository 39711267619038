import { useCallback, useEffect, useMemo, useState } from "react";
import EmojiFlags from "@mui/icons-material/EmojiFlags";
import ManageAccounts from "@mui/icons-material/ManageAccounts";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import { Command, CommandPopper } from "@komodorio/design-system/komodor-ui";
import { useDebounce } from "use-debounce";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { muiColors } from "@komodorio/design-system";

import { favoriteAccountsLS } from "../../AccountSwitcher/components/AccountOptionsMenu/localStorage";
import { FREQUENTLY_USED_ACCOUNT_NAMES } from "../../AccountSwitcher/components/AccountOptionsMenu/constants";
import { useLocalStorage } from "../../../shared/hooks/localStorage/useLocalStorage";

import { useAccountItems } from "./Accounts/AccountItems";
import { commandScore } from "./devCommandScore";
import { CmdKTypography } from "./components/SearchButton";

import { OperatingSystem, getOperatingSystem } from "@/shared/utils/os/os";
import {
  useOverridableFlags,
  useSetFlag,
} from "@/shared/context/featureFlags/OverridableFlags";
import { copyContentToClipboard } from "@/shared/utils/copyContentToClipboard";

export type CmdKEntry = {
  title: string;
  value: string;
};

const MAX_ITEMS = 50;

const filterMaxItems = (
  items: CmdKEntry[],
  query: string,
  mapper: (item: CmdKEntry) => JSX.Element,
  isFavorite?: (item: CmdKEntry) => boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): { score: number; element: JSX.Element }[] => {
  const res: { element: JSX.Element; score: number }[] = [];
  let count = 0;
  for (let i = 0; i < items.length; i++) {
    if (count === MAX_ITEMS) {
      break;
    }
    const item = items[i];
    // filter out items that don't include the query
    const favorite = isFavorite ? isFavorite(item) : false;
    if (item.title.toLowerCase().includes(query.toLowerCase())) {
      count = res.push({
        element: mapper(item),
        // score is used to sort the items that include the query
        // And will always be item.title since item.displayName is optional
        score: favorite ? 1 : commandScore(item.title, query, []),
      });
    } else if (item.value.toLowerCase().includes(query.toLowerCase())) {
      // for searching by id
      count = res.push({
        element: mapper(item),
        // score is used to sort the items that include the query
        score: favorite ? 1 : commandScore(item.value, query, []),
      });
    }
  }
  return res;
};

export const DevCommandBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery, { pending }] = useDebounce(query, 150, {
    leading: false,
    trailing: true,
  });

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setQuery("");
    return setIsOpen(false);
  }, []);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.shiftKey && e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onOpen();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [onOpen]);

  const accounts = useAccountItems();
  const [parsedFavoriteAccounts] = useLocalStorage({
    localStorageItem: favoriteAccountsLS,
    defaultValue: FREQUENTLY_USED_ACCOUNT_NAMES,
  });
  const nonFavoriteAccounts = useMemo(
    () =>
      accounts?.items?.filter(
        (account) => !parsedFavoriteAccounts.includes(account.title)
      ) ?? [],
    [accounts?.items, parsedFavoriteAccounts]
  );

  const favoriteAccountsItems: CmdKEntry[] = useMemo(() => {
    return parsedFavoriteAccounts
      .map((parsedAccount) => {
        const account = accounts?.items?.find((i) => i.title === parsedAccount);
        if (!account) {
          return null;
        }
        return {
          title: account?.title,
          value: account?.value,
        };
      })
      .filter(Boolean) as CmdKEntry[];
  }, [accounts?.items, parsedFavoriteAccounts]);

  const toAccountItem = (item: CmdKEntry, isFavorite = false): JSX.Element => {
    return (
      <Command.Item
        key={item.value}
        kind="account"
        icon={<ManageAccounts sx={{ fontSize: "16px" }} />}
        value={`account-${item.value}`}
      >
        {item.title}
        {isFavorite ? " ⭐" : ""}
      </Command.Item>
    );
  };

  const filteredAccountItems = useMemo(() => {
    return [
      ...filterMaxItems(favoriteAccountsItems, debouncedQuery, (item) =>
        toAccountItem(item, true)
      ),
      ...filterMaxItems(nonFavoriteAccounts, debouncedQuery, toAccountItem),
    ];
  }, [debouncedQuery, favoriteAccountsItems, nonFavoriteAccounts]);

  const flags = useOverridableFlags();
  const flagEntries = useMemo(
    () =>
      Object.entries(flags).map(([name, value]) => ({
        title: name,
        value: `flag-${Boolean(value)}-${name}`,
      })),
    [flags]
  );
  const setFlag = useSetFlag();
  const filteredFlags = useMemo(
    () =>
      filterMaxItems(flagEntries, debouncedQuery, (item) => {
        return (
          <Command.Item
            key={item.title}
            kind="flag"
            icon={<EmojiFlags sx={{ fontSize: "16px" }} />}
            value={item.value}
          >
            <Box display="flex" gap="8px">
              <Switch
                checked={Boolean(item.value.split("-", 3)[1] === "true")}
              />{" "}
              {item.title}
            </Box>
          </Command.Item>
        );
      }),
    [flagEntries, debouncedQuery]
  );

  const onSelect = useCallback(
    async (value: string) => {
      const kindIndex = value.indexOf("-");
      const [kind, valueStr] = [
        value.slice(0, kindIndex),
        value.slice(kindIndex + 1),
      ];
      if (kind === "account") {
        await copyContentToClipboard(valueStr);
        accounts?.onAccountClick?.({
          value: valueStr,
          label:
            accounts?.items?.find((item) => item.value === valueStr)?.title ??
            "",
        });
      } else if (kind === "flag") {
        const [flagValue, flagName] = valueStr.split("-", 2);
        setFlag(flagName, flagValue !== "true");
      }
    },
    [accounts, setFlag]
  );

  const sortedItems = useMemo(() => {
    return [...filteredAccountItems, ...filteredFlags].sort(
      (a, z) => z.score - a.score
    );
  }, [filteredAccountItems, filteredFlags]);

  const operatingSystem = getOperatingSystem();

  return (
    <>
      <Button
        sx={{
          padding: 0,
          ":hover": {
            bgcolor: muiColors.gray[600],
          },
        }}
        onClick={() => setIsOpen(true)}
      >
        <CmdKTypography>
          {operatingSystem === OperatingSystem.Windows ? "Ctrl" : "⌘"} + ⇧ + K
        </CmdKTypography>
      </Button>
      {isOpen && (
        <CommandPopper
          query={query}
          onQueryChange={setQuery}
          onSelect={onSelect}
          onClose={onClose}
          placeholder="Search for accounts or feature flags... 🛠️"
          showItemsOnEmptyQuery={true}
        >
          <Command.Results loading={pending() && query !== debouncedQuery}>
            <Command.Empty>
              <Typography variant="h4">
                No accounts or feature flags found :'(
              </Typography>
            </Command.Empty>
            {sortedItems.map((item) => item.element)}
          </Command.Results>
        </CommandPopper>
      )}
    </>
  );
};
