import React from "react";
import {
  Button,
  Typography,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@komodorio/design-system/deprecated";

import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../shared/hooks/analytics";

import { MonitorConfiguration } from "@/generated/monitorsApi";

interface SwitcherConfirmationModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  updateActiveInDB: (rule: MonitorConfiguration, active: boolean) => void;
  setActive: (active: boolean) => void;
  rule: MonitorConfiguration;
  active: boolean;
}

const SwitcherConfirmationModal: React.FC<SwitcherConfirmationModalProps> = ({
  isModalOpen,
  handleClose,
  updateActiveInDB,
  setActive,
  rule,
  active,
}) => {
  const executeAndReport = () => {
    updateActiveInDB(rule, !active);
    setActive(!active);
    dispatchEvent(
      active
        ? AnalyticEvents.MonitorsView.Rule_Enabled
        : AnalyticEvents.MonitorsView.Rule_Disabled,
      {
        type: rule.type,
      }
    );
    handleClose();
  };

  return (
    <Modal isOpen={isModalOpen} width="25rem" onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Disable notifications</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          This will disable notifications for the monitor, but its events will
          continue to appear in timeline
          <br />
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          size="small"
          onClick={() => {
            executeAndReport();
          }}
        >
          Disable notifications
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SwitcherConfirmationModal;
