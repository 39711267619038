import { useEffect, useMemo, useRef } from "react";
import { get } from "lodash";

import { AtmOutputType } from "../../../../shared/hooks/ATM/useAtmRequest";
import { KubernetesSecretsResource } from "../../inspectionConfiguration/supportedResourcesTypes/KubernetesSecretsResource";

import { HelmChartInfoResponse } from "./types";
import {
  convertResultToHelmChartInfo,
  getSecretNameFromSecretId,
} from "./utils";
import { useAtmGetResourceInterval } from "./useAtmGetResourceInterval";

const secretReleasePath = "data.release";

const interval = 1500;

export const useHelmChartInfo = (
  agentId: string,
  cluster: string,
  namespace: string,
  secretId: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): HelmChartInfoResponse => {
  const prevSecretIdRef = useRef<string>(secretId);

  const {
    execute,
    errorMessage: atmErrorMessage,
    isFetching,
    data,
  } = useAtmGetResourceInterval({
    agentId,
    cluster,
    namespace,
    resourceName: getSecretNameFromSecretId(secretId),
    resourceKind: KubernetesSecretsResource.Kind,
    outputType: AtmOutputType.Json,
    enable: !!secretId,
    interval,
  });

  useEffect(() => {
    if (prevSecretIdRef.current !== secretId) {
      execute();
      prevSecretIdRef.current = secretId;
    }
  }, [secretId, execute]);

  const { data: helmChartData, errorMessage: convertErrorMessage } =
    useMemo(() => {
      return convertResultToHelmChartInfo(
        get(data, secretReleasePath),
        cluster
      );
    }, [cluster, data]);

  return useMemo(() => {
    return {
      execute,
      errorMessage: convertErrorMessage || atmErrorMessage,
      isFetching,
      data: helmChartData,
    };
  }, [
    atmErrorMessage,
    convertErrorMessage,
    execute,
    helmChartData,
    isFetching,
  ]);
};
