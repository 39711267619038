import React, { useRef } from "react";

import Resource from "../../../resources";

import { useReportDescribeTabToDatadog } from "./hooks/useReportDescribeTabToDatadog";
import { DescribeLoader } from "./DescribeLoader";
import { DescribeContainer } from "./DescribeSection";

import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";

type DescribeTabContentWrapperProps = {
  children: React.ReactNode;
  resource: Resource;
};

const DescribeTabContentWrapperComponent: React.FC<
  DescribeTabContentWrapperProps
> = ({ resource, children }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useReportDescribeTabToDatadog({
    resourceId: resource.id,
    divRef,
  });

  return (
    <div ref={divRef}>
      {!resource.id && <DescribeLoader />}
      {resource.id && <DescribeContainer>{children}</DescribeContainer>}
    </div>
  );
};

export const DescribeTabContentWrapper: React.FC<
  DescribeTabContentWrapperProps
> = (props) => {
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.Details, {
      resourceKind: props.resource.kind,
    });

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <DescribeTabContentWrapperComponent
        {...props}
        children={props.children}
      />
    </DatadogReportLoadingTimeContextProvider>
  );
};
