import { Dictionary } from "../types/Dictionary";

type FindClosestInListOutput<T, U> = {
  inputElement: U;
  targetElement: T;
};

type FindClosestInListParams<T, U> = {
  targetArr: T[];
  targetNumProperty: string;
  inputArr: U[];
  inputNumProperty: string;
  maxDiff?: number;
};

/** match target array and input array by closest number property (e.g. timestampMs)
   const targetArr = [{id:"target-id-1", timestamp:123}, {id:2, timestamp:235}]
   const inputArr = [{id:"input-id-1", timestamp:124}]

   findClosestInList({targetArr, targetNumProperty: "timestamp", inputArr, inputNumProperty: "timestamp"})
   returns {input-id-1: {inputElement: {id:"input-id-1", timestamp:124}, targetElement: {id:"target-id-1", timestamp:123}}}
 */

export const findClosestElementsInList = <T, U extends { id: string }>({
  targetArr,
  targetNumProperty,
  inputArr,
  inputNumProperty,
  maxDiff = Number.MAX_VALUE,
}: FindClosestInListParams<T, U>) => {
  return targetArr.reduce<Dictionary<FindClosestInListOutput<T, U>>>(
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
    (acc, curr, index) => {
      const targetElementValue = getPropAsNumber(curr, targetNumProperty);

      inputArr.forEach((input) => {
        const inputElementValue = getPropAsNumber(input, inputNumProperty);
        const diff = Math.abs(targetElementValue - inputElementValue);
        if (diff < maxDiff) {
          const currentInput = acc[input.id];
          const prevValue = getPropAsNumber(
            currentInput?.targetElement,
            targetNumProperty
          );
          if (!currentInput || diff < Math.abs(prevValue - inputElementValue)) {
            acc[input.id] = { inputElement: input, targetElement: curr };
          }
        }
      });

      return acc;
    },
    {}
  );
};

const getPropAsNumber = <T>(obj: T, numberProp: string) =>
  obj?.[numberProp as keyof T] as unknown as number;
