import React from "react";

import { Addon, Entity } from "@/generated/addonsApi";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { DagsTable } from "@/components/k8sAddons/addons/workflows/DagsListPage/DagsTable";
import { Filters } from "@/components/k8sAddons/addons/workflows/DagsListPage/Filters";
import {
  WORKFLOW_DAGS_DEFAULT_SORT_BY_DIRECTION,
  WORKFLOW_DAGS_DEFAULT_SORT_BY_FIELD,
} from "@/components/k8sAddons/addons/workflows/DagsListPage/constants";
import { AddonPageContainer } from "@/components/k8sAddons/components/AddonPageContainer";
import { WorkflowEngine } from "@/components/k8sAddons/addons/workflows/types";

export const DagsListPage: React.FC<{ engineType?: WorkflowEngine }> = ({
  engineType,
}) => {
  return (
    <AddonContextProvider
      addonType={Addon.Workflow}
      addonEntity={Entity.WorkflowDag}
      defaultSortModel={{
        field: WORKFLOW_DAGS_DEFAULT_SORT_BY_FIELD,
        sort: WORKFLOW_DAGS_DEFAULT_SORT_BY_DIRECTION,
      }}
    >
      <AddonPageContainer>
        <Filters engineType={engineType} />
        <DagsTable engineType={engineType} />
      </AddonPageContainer>
    </AddonContextProvider>
  );
};
