/* eslint-disable max-lines */
import Popover, { PopoverProps } from "@mui/material/Popover";
import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab, { TabProps } from "@mui/material/Tab";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { muiColors, muiTheme } from "@komodorio/design-system";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { Pencil16, Trash16 } from "@komodorio/design-system/icons";
import IconButton from "@mui/material/IconButton";
import { useVirtualizer } from "@tanstack/react-virtual";

import { WorkspacesSearchBar } from "./WorkspaceSearchBar";
import type { TabType } from "./WorkspaceSelector";
import { NO_RESULTS_TEXT, WorkspaceOption } from "./constants";

import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";

const StyledSearchBar = styled(WorkspacesSearchBar)`
  padding: 8px 16px 0px 16px;
`;

const StyledMenuItem = styled(MenuItem)`
  &&.MuiMenuItem-root.Mui-selected {
    background-color: ${muiColors.gray[100]}};
  }
  &&.MuiMenuItem-root:hover {
    background-color: ${muiTheme.palette.action.hover}};
  }
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const Results = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-x: auto;
`;

export type WorkspaceTabbedSelectPopoverProps = {
  query: string;
  onQueryChange: (query: string) => void;
  width?: number | string;
  loading?: boolean;
  tabs: { label: string; value: string; sx?: TabProps["sx"] }[];
  items: {
    label: string;
    value: string;
    disabled?: boolean;
    editable?: boolean;
    tooltip?: string;
    match: { start: number; end: number } | null;
  }[];
  selectedTab: string;
  onSelectTab: (tab: TabType) => void;
  selectedItem: string;
  onSelectItem: (item: string) => void;
  onEdit: (option: WorkspaceOption) => void;
  onDelete: (option: WorkspaceOption) => void;
  children?: React.ReactNode;
} & PopoverProps;

export function WorkspaceTabbedSelectPopover({
  query,
  onQueryChange,
  width,
  loading,
  tabs,
  items,
  selectedTab,
  onSelectTab,
  selectedItem,
  onSelectItem,
  onEdit,
  onDelete,
  children,
  ...popoverProps
}: WorkspaceTabbedSelectPopoverProps) {
  const [parentRef, setParentRef] = useState<HTMLUListElement | null>(null);
  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef,
    estimateSize: () => 32,
  });
  return (
    <Popover
      {...popoverProps}
      slotProps={{
        paper: {
          sx: {
            boxSizing: "border-box",
            minWidth: "400px",
            fontSize: "14px",
            width: width ? width : "auto",
            maxWidth: "calc(100vw - 32px)",
            height: "330px",
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden",
          },
        },
      }}
    >
      <StyledSearchBar
        searchPlaceholder="Search"
        onSearch={function (searchText: string): void {
          onQueryChange(searchText);
        }}
        searchValue={query}
      />
      <Content>
        <Tabs
          orientation="vertical"
          sx={{
            minWidth: "fit-content",
            borderRight: `1px solid ${muiColors.gray[200]}`,
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
          value={selectedTab}
          onChange={(_, newValue) => onSelectTab(newValue)}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              sx={{
                padding: "9px 16px 8px 16px",
                minHeight: "42px",
                letterSpacing: "0.4px",
                "&.MuiTab-root.MuiButtonBase-root": {
                  alignItems: "flex-start",
                  padding: "9px 16px",
                  "&.Mui-selected": {
                    color: muiColors.gray[800],
                    backgroundColor: muiTheme.palette.action.hover,
                  },
                },
                ...(tab.sx ?? []),
              }}
              label={tab.label}
              value={tab.value}
              aria-label={`${tab.label} tab`}
            />
          ))}
        </Tabs>
        <Results>
          <MenuList
            data-testid="workspace-select-popover-menu"
            ref={(node) => {
              if (node && node !== parentRef) {
                setParentRef(node);
              }
            }}
            sx={{
              flexGrow: 1,
              position: "relative",
              overflowY: "auto",
            }}
            dense={true}
          >
            {(items.length === 0 || loading) && (
              <Box
                sx={{ color: muiColors.gray[500] }}
                display="flex"
                flexGrow={1}
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                {loading ? (
                  <LinesLoader />
                ) : (
                  <Typography sx={{ color: "text.secondary" }} variant="body2">
                    {NO_RESULTS_TEXT}
                  </Typography>
                )}
              </Box>
            )}
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {rowVirtualizer.getVirtualItems().map((value) => {
                const item = items[value.index];
                return (
                  <div
                    key={value.key}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: `${value.size}px`,
                      transform: `translateY(${value.start}px)`,
                    }}
                  >
                    <CustomTooltip title={item.tooltip} key={item.value}>
                      <Box
                        sx={{
                          position: "relative",
                          "&:hover .MuiIconButton-root": { display: "flex" },
                        }}
                      >
                        <StyledMenuItem
                          key={item.value}
                          value={item.value}
                          disabled={item.disabled}
                          selected={selectedItem === item.value}
                          onClick={() => onSelectItem(item.value)}
                          aria-label={item.label}
                        >
                          <Typography variant="body2">
                            {item.match === null || query.trim() === "" ? (
                              item.label
                            ) : (
                              <>
                                {item.label.slice(0, item.match.start)}
                                <Typography
                                  sx={{ textDecoration: "underline" }}
                                  display="inline"
                                  variant="body2"
                                >
                                  {item.label.slice(
                                    item.match.start,
                                    item.match.end
                                  )}
                                </Typography>
                                {item.label.slice(item.match.end)}
                              </>
                            )}
                          </Typography>
                        </StyledMenuItem>
                        {item.editable ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "8px",
                              position: "absolute",
                              right: 5,
                              top: 8,
                            }}
                          >
                            <IconButton
                              onClick={() => onDelete(item)}
                              sx={{
                                display: "none",
                                color: muiColors.gray[500],
                                padding: 0,
                                "&:hover": {
                                  color: muiColors.pink[700],
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              <Trash16 />
                            </IconButton>
                            <IconButton
                              onClick={() => onEdit(item)}
                              sx={{
                                display: "none",
                                color: muiColors.gray[500],
                                padding: 0,
                                "&:hover": {
                                  color: "text.primary",
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              <Pencil16 />
                            </IconButton>
                          </Box>
                        ) : null}
                      </Box>
                    </CustomTooltip>
                  </div>
                );
              })}
            </div>
          </MenuList>
          {children ? (
            <>
              <Divider />
              <Box padding="6px 16px">{children}</Box>
            </>
          ) : null}
        </Results>
      </Content>
    </Popover>
  );
}

type CustomTooltipProps = {
  title: TooltipProps["title"];
  children: TooltipProps["children"];
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children }) => {
  return (
    <Tooltip
      title={title}
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            ...lightMuiTooltipStyle.tooltip.sx,
            maxWidth: "185px",
          },
        },
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
