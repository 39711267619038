import React, { useMemo } from "react";

import { deployForRollbackSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { Deploy } from "../../../../generated/monitorsApi";

import { DeployCard } from "./DeployCard";
import { CardPadding } from "./styles";

const CorrelatedDeploysCards: React.FC<{
  correlatedDeploys: Deploy[];
}> = ({ correlatedDeploys }) => {
  const deployForRollback = useInvestigationModeStore(
    deployForRollbackSelector
  );

  const sortedDeploys = useMemo(() => {
    return correlatedDeploys.sort(
      (a, b) =>
        new Date(b.eventTime ?? "").getTime() -
        new Date(a.eventTime ?? "").getTime()
    );
  }, [correlatedDeploys]);

  return (
    <>
      {sortedDeploys.map((deploy, index) => (
        <CardPadding key={deploy.id}>
          <DeployCard
            key={deploy.id}
            deploy={deploy}
            isCollapsed={correlatedDeploys.length > 1 || index !== 0}
            canRollback={deploy.id === deployForRollback?.id}
          />
        </CardPadding>
      ))}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CorrelatedDeploysCards;
