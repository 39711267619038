import { useCallback, useMemo } from "react";
import { isEmpty } from "lodash";

import { useCostOptimizationStore } from "../store/costOptimizationStore";
import { selectClustersEarliestRowTime } from "../store/costOptimizationStoreSelectors";
import { doesAgentVersionSupportMetrics } from "../../../shared/utils/agent/doesAgentVersionSupportMetrics";
import { useClusterOptionsByAppView } from "../../../shared/hooks/useGetAllClusters/useClusterOptionsByAppView";
import { isDevMode } from "../../../shared/utils/isDevMode";
import { getIsNewCluster } from "../../../shared/utils/metricsAgentInfoAccuracyUtils";
import { ClusterData } from "../../../shared/hooks/useGetAllClusters/useGetAllClusters";

type SupportedAgentVersionsOutput = {
  hasClustersData: boolean;
  hasMetricsSupportedAgents: boolean;
  unsupportedClusters: string[];
};

export const useSupportedAgentVersions = (): SupportedAgentVersionsOutput => {
  const { clusters: clustersEarliestRowTime } = useCostOptimizationStore(
    selectClustersEarliestRowTime
  );

  const clusterOptions = useClusterOptionsByAppView(isDevMode());
  const isClusterSupported = useIsClusterSupported();

  const hasMetricsSupportedAgents = useMemo(() => {
    if (isEmpty(clustersEarliestRowTime) || !clustersEarliestRowTime)
      return false;
    const hasSupportedAgents = clusterOptions.some(
      doesAgentVersionSupportMetrics
    );
    const allNewClusters = Object.values(clustersEarliestRowTime).every(
      (cluster) => getIsNewCluster(new Date(cluster))
    );

    return hasSupportedAgents && !allNewClusters;
  }, [clusterOptions, clustersEarliestRowTime]);

  const unsupportedClusters = useMemo(
    () =>
      clusterOptions.reduce<string[]>((acc, curr) => {
        if (!isClusterSupported(curr)) {
          acc.push(curr.clusterName);
        }
        return acc;
      }, []),
    [clusterOptions, isClusterSupported]
  );

  return {
    hasClustersData: !!clusterOptions.length && !!clustersEarliestRowTime,
    hasMetricsSupportedAgents: hasMetricsSupportedAgents,
    unsupportedClusters,
  };
};

export const useIsClusterSupported = () => {
  const { clusters: clustersEarliestRowTime } = useCostOptimizationStore(
    selectClustersEarliestRowTime
  );

  return useCallback(
    (clusterData: ClusterData) => {
      return (
        doesAgentVersionSupportMetrics(clusterData) &&
        !!clustersEarliestRowTime?.[clusterData.clusterName]
      );
    },
    [clustersEarliestRowTime]
  );
};

type SupportedClustersData = {
  clusterName: string;
  isSupported: boolean;
  isNew: boolean;
};

export const useGetSupportedClustersData = () => {
  const { clusters: clustersEarliestRowTime } = useCostOptimizationStore(
    selectClustersEarliestRowTime
  );

  const isClusterSupported = useIsClusterSupported();

  return useCallback(
    (clusterOptions: ClusterData[]) => {
      return clusterOptions.reduce<SupportedClustersData[]>((acc, curr) => {
        if (!clustersEarliestRowTime) return acc;

        const isSupported = isClusterSupported(curr);

        const isNew = getIsNewCluster(
          new Date(clustersEarliestRowTime[curr.clusterName])
        );
        acc.push({
          clusterName: curr.clusterName,
          isSupported,
          isNew,
        });

        return acc;
      }, []);
    },

    [clustersEarliestRowTime, isClusterSupported]
  );
};
