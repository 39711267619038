import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Button, { ButtonProps } from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

const SELECT_ALL_ARIA_LABEL = "Select all";
const DEFAULT_SELECT_ALL_TEXT = "Select all";

const Container = styled.div``;
const StyledButton = styled(Button)`
  && {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;

const StyledArrow = styled(ArrowDropDownIcon)<{ $flip: boolean }>`
  && {
    transition: transform 0.2s ease-in-out;
    transform: ${({ $flip }) => ($flip ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

type SelectMenuButtonProps = {
  menuOptions: MuiSelectionOption<string>[];
  buttonText: string;
  ariaLabel: string;
  selectAllText?: string;
  onSelect: (option: MuiSelectionOption<string>) => void;
  onSelectAll?: () => void;
  buttonProps?: ButtonProps;
  className?: string;
  classes?: {
    button?: string;
  };
};

export const SelectMenuButton: React.FC<SelectMenuButtonProps> = ({
  menuOptions,
  buttonText,
  ariaLabel,
  selectAllText = DEFAULT_SELECT_ALL_TEXT,
  onSelect,
  onSelectAll,
  buttonProps,
  className,
  classes,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onOptionClick = useCallback(
    (option: MuiSelectionOption<string>) => {
      onSelect(option);
      handleClose();
    },
    [onSelect]
  );

  const menuItems = useMemo(() => {
    const selectAllItem = onSelectAll
      ? [
          <MenuItem
            key="MenuItem"
            onClick={() => {
              onSelectAll();
              handleClose();
            }}
            aria-label={SELECT_ALL_ARIA_LABEL}
          >
            {selectAllText}
          </MenuItem>,

          <Divider key="Divider" variant="middle" />,
        ]
      : [];

    const items = menuOptions.map((option) => (
      <MenuItem
        key={option.value}
        onClick={() => onOptionClick(option)}
        disabled={option.disabled}
        aria-label={option.label}
      >
        {option.label}
      </MenuItem>
    ));
    return [...selectAllItem, ...items];
  }, [menuOptions, onOptionClick, onSelectAll, selectAllText]);

  return (
    <Container className={className}>
      <StyledButton
        variant={"contained"}
        onClick={handleClick}
        size={"small"}
        aria-label={ariaLabel}
        aria-expanded={open ? "true" : undefined}
        className={classes?.button}
        {...buttonProps}
      >
        {buttonText} <StyledArrow $flip={open} />
      </StyledButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems}
      </Menu>
    </Container>
  );
};
