import { useMemo } from "react";
import { groupBy, sortBy } from "lodash";

import { WorkflowPod } from "@/generated/addonsApi";
import { LineGroup } from "@/components/common/EventsChart/TimelineChart/types";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { groupGroupedPodEvents } from "@/components/common/EventGroup/groupedPodEvent/PodPhaseGroup";
import { groupWorkflowIssuesEvents } from "@/components/common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import {
  NodeEventsResponseDataInner,
  PodGroups,
} from "@/generated/resourcesApi";
import EventGroup from "@/components/common/EventGroup";
import { MonitorData } from "@/generated/monitorsApi";
import { groupNodeChangeEvents } from "@/components/common/EventGroup/nodeEvent/NodeChangeGroup";
import { INFRA_SWIMLANE_PREFIX } from "@/components/common/EventsChart/TimelineChart/useSwimlanesPagination";

const DEFAULT_SERVICE_ID = "default";

export const useWorkflowChartSwimlanes = ({
  nodeTerminations,
  nodeIssues,
  workflowPods,
}: {
  nodeTerminations: NodeEventsResponseDataInner[] | undefined;
  nodeIssues: MonitorData[] | undefined;
  workflowPods: WorkflowPod[] | undefined;
}): LineGroup[] => {
  const nodeTerminationsEventGroups = groupNodeChangeEvents(
    nodeTerminations?.flatMap((e) => e) ?? [],
    DEFAULT_SERVICE_ID
  );
  const nodeIssuesEventGroups = groupWorkflowIssuesEvents(
    nodeIssues?.flatMap((e) => e) ?? [],
    ""
  );
  const infraSwimlane = useMemo(() => {
    const events = sortBy(
      [...nodeTerminationsEventGroups, ...nodeIssuesEventGroups],
      (event) => event.startTime
    );
    return events.length > 0
      ? {
          label: {
            prefix: INFRA_SWIMLANE_PREFIX,
            name: "",
          },
          events,
        }
      : undefined;
  }, [nodeIssuesEventGroups, nodeTerminationsEventGroups]);

  const tasksSwimlanes = useMemo(() => {
    return Object.entries(groupBy(workflowPods, (pod) => pod.taskId))
      .map(
        ([taskId, pods]) =>
          pods.map((pod) => {
            const komodorUid = buildKomodorUid({
              clusterName: pod.cluster,
              namespace: pod.namespace,
              kind: "Pod",
              resourceName: pod.name,
            });
            const events = [
              ...groupGroupedPodEvents(
                [{ komodorUid, groups: pod.phases }] as PodGroups[],
                ""
              ),
              ...groupWorkflowIssuesEvents(
                (pod.issues ?? []) as MonitorData[],
                ""
              ),
            ] as EventGroup[];

            return {
              label: taskId
                ? pods.length > 1
                  ? [
                      { prefix: "Task ID", name: taskId },
                      { prefix: "Pod", name: pod.name },
                    ]
                  : { prefix: "Task ID", name: taskId }
                : { prefix: "Pod", name: pod.name },
              events,
            };
          }) ?? []
      )
      .flat();
  }, [workflowPods]);

  return useMemo(() => {
    let swimlanes = tasksSwimlanes;
    if (infraSwimlane) {
      swimlanes = [...tasksSwimlanes, infraSwimlane];
    }
    return sortBy(swimlanes, (swimlane) => swimlane.events[0]?.startTime);
  }, [infraSwimlane, tasksSwimlanes]);
};
