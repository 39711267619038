import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { Command, CommandPopper } from "@komodorio/design-system/komodor-ui";
import { useDebounce } from "use-debounce";
import { KomobotEmptyState } from "@komodorio/design-system/assets";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Box from "@mui/material/Box";
import differenceInHours from "date-fns/differenceInHours";
import { datadogRum } from "@datadog/browser-rum";

import { useSearchAPIGet } from "../../../shared/hooks/search-api/client";
import { useAppViewsStore } from "../../../shared/store/appViewsStore/appViewsStore";
import {
  selectedAppViewIdSelector,
  setSelectedAppViewIdSelector,
} from "../../../shared/store/appViewsStore/appViewStoreSelectors";
import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import { useWorkspaces } from "../../workspaces/WorkspacesTopBar/hooks";

import { isWorkspaceItemMapping, useItemMappings } from "./components/Items";
import { AllWorkspacesToggle } from "./AllWorkspacesToggle";
import { SearchButton } from "./components/SearchButton";
import { getLabelTextByWorkspace } from "./utils";

type SerializedItem = {
  kind: string;
  id: string;
  isDeleted: boolean;
  deletedAt?: string;
};

const DEBOUNCE_TIME_MS = 100;

const LinkButton = styled(Button)`
  padding: 0;
  && {
    font-weight: normal;
    &:hover {
      background: none;
    }
  }
`;

export const CommandBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, DEBOUNCE_TIME_MS, {
    leading: false,
    trailing: true,
  });
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const [searchInAllWorkspaces, setSearchInAllWorkspaces] = useState(
    selectedAppViewId === undefined
  );
  const { isClusterWorkspace, isClustersGroupWorkspace } = useWorkspaces();

  const onOpen = useCallback(
    (method: "keyboard" | "button") => {
      setQuery("");
      setSearchInAllWorkspaces(selectedAppViewId === undefined);
      dispatchEvent(AnalyticEvents.GlobalSearch.Opened, {
        method,
      });
      if (method === "keyboard") {
        datadogRum.addAction("global search - keyboard");
      }
      setIsOpen(true);
    },
    [selectedAppViewId]
  );

  const onClose = useCallback(() => {
    dispatchEvent(AnalyticEvents.GlobalSearch.Closed);
    setIsOpen(false);
  }, []);

  const { data, loading } = useSearchAPIGet({
    query: debouncedQuery,
    workspaceId: selectedAppViewId,
    options: {
      pause: debouncedQuery === "" || query != debouncedQuery,
      staleTime: DEBOUNCE_TIME_MS,
      skipAppViewInHeaders: searchInAllWorkspaces,
    },
  });

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && !e.shiftKey && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onOpen("keyboard");
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [onOpen]);

  const ITEM_MAPPINGS = useItemMappings(onClose);
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);

  const onSelect = useCallback(
    (value: string) => {
      const { kind, id, isDeleted, deletedAt } = JSON.parse(
        value
      ) as SerializedItem;
      const itemIndex = data?.results?.findIndex((it) => it.id === id);
      if (itemIndex === undefined || itemIndex < 0) {
        return;
      }

      dispatchEvent(AnalyticEvents.GlobalSearch.SelectedItem, {
        query,
        id,
        kind,
        index: itemIndex,
        isDeleted,
        deletedBeforeHours: deletedAt
          ? differenceInHours(new Date(), new Date(deletedAt))
          : undefined,
      });

      const item = data?.results?.[itemIndex];

      let itemRenderer = ITEM_MAPPINGS[kind];
      itemRenderer = isWorkspaceItemMapping(itemRenderer, kind)
        ? itemRenderer(setSelectedAppViewId)
        : itemRenderer;
      if (!item || !itemRenderer?.onClick) {
        return;
      }

      if (searchInAllWorkspaces) {
        setSelectedAppViewId(undefined);
      }
      itemRenderer.onClick(item);
    },
    [
      data?.results,
      query,
      ITEM_MAPPINGS,
      setSelectedAppViewId,
      searchInAllWorkspaces,
    ]
  );

  const items = useMemo(() => {
    return data?.results?.map((result) => {
      const kind = result.kind.toLowerCase();
      const item = ITEM_MAPPINGS[kind];
      if (!item) {
        return null;
      }
      const isWorkspaceItem = isWorkspaceItemMapping(item, kind);
      const itemRenderer = isWorkspaceItem ? item(setSelectedAppViewId) : item;
      const content = itemRenderer.content(result);
      const deslugify = (kind: string) => kind.replace(/-/g, " ");
      return content ? (
        <Command.Item
          key={result.id}
          kind={deslugify(result.kind)}
          value={JSON.stringify({
            deletedAt: result.isDeleted ? result.deletedAt : undefined,
            isDeleted: result.isDeleted,
            kind: result.kind.toLowerCase(),
            id: result.id,
          } as SerializedItem)}
          icon={
            typeof itemRenderer.icon === "function"
              ? itemRenderer.icon(result)
              : itemRenderer.icon
          }
          enterKeyText={isWorkspaceItem ? "Switch to" : "Enter"}
        >
          {content}
        </Command.Item>
      ) : null;
    });
  }, [ITEM_MAPPINGS, data?.results, setSelectedAppViewId]);

  const toggleSearchInAllWorkspaces = useCallback(() => {
    setSearchInAllWorkspaces((val) => !val);
  }, [setSearchInAllWorkspaces]);

  return (
    <>
      <SearchButton onClick={() => onOpen("button")} />
      {isOpen && (
        <CommandPopper
          query={query}
          onQueryChange={setQuery}
          onSelect={onSelect}
          onClose={onClose}
          placeholder="Find a workload, page, workspace..."
        >
          {query !== "" && (
            <AllWorkspacesToggle
              searchInAllWorkspaces={searchInAllWorkspaces}
              onClick={toggleSearchInAllWorkspaces}
            />
          )}
          <Command.Results loading={loading && query !== ""}>
            <Command.Empty>
              <KomobotEmptyState />
              <Typography variant="h4">No results</Typography>
              <Box display="flex" alignItems="center" flexDirection="column">
                <Typography variant="body2" color="textSecondary">
                  Check your spelling and try again
                </Typography>
                {selectedAppViewId != null && (
                  <LinkButton
                    sx={{ padding: 0, background: "none" }}
                    variant="text"
                    onClick={() => setSearchInAllWorkspaces(true)}
                  >
                    <Typography variant="body2">
                      Change search scope to{" "}
                      {getLabelTextByWorkspace(
                        isClusterWorkspace || isClustersGroupWorkspace
                      )}
                    </Typography>
                  </LinkButton>
                )}
              </Box>
            </Command.Empty>
            {query !== "" ? items : null}
          </Command.Results>
        </CommandPopper>
      )}
    </>
  );
};
