import { useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useAuditLogsContext } from "@/pages/organization-settings/account/AuditPage/context/useAuditLogsContext";

export const useGetAuditAppliedFilters = () => {
  const { setConfigurationData, apiStartTime, ...context } =
    useAuditLogsContext();

  const { operations, actions, entityTypes, userIds } = useMemo(() => {
    return {
      operations: context.operations?.map(
        (option: MuiSelectionOption<string>) => option.value
      ),
      actions: context.actions?.map(
        (option: MuiSelectionOption<string>) => option.value
      ),
      entityTypes: context.entityTypes?.map(
        (option: MuiSelectionOption<string>) => option.value
      ),
      userIds: context.userIds?.map(
        (option: MuiSelectionOption<string>) => option.value
      ),
    };
  }, [
    context.operations,
    context.actions,
    context.entityTypes,
    context.userIds,
  ]);

  return {
    startTime: apiStartTime,
    status: context.status?.value,
    operations,
    actions,
    entityTypes,
    userIds,
    entityName: context.entityName,
    pageSize: context.pageSize,
    page: (context.page ?? 0) + 1,
    sort: context.sort,
  };
};
