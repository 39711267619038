import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import {
  useFilterWorkloadsMetricsByContainer,
  useWorkloadsGraphData,
} from "./graphDataHooks";
import {
  useParseMultipleMetricsResults,
  useWorkloadsContainersMetricsParams,
} from "./metricsRequestsHooks";

import { MetricType } from "@/components/Metrics/types";
import { useGetWorkloadsContainersMetrics } from "@/shared/hooks/metrics-api/client/useGetWorkloadContainersMetrics";
import { WorkloadMetricsContainers } from "@/generated/metricsApi";

type WorkloadsMetricsParams = {
  type: MetricType;
  komodorUids: string[];
  wantedContainer: MuiSelectionOption<string> | undefined;
  endTime?: number;
};

export const useWorkloadsContainersMetrics = ({
  type,
  komodorUids,
  wantedContainer,
  endTime,
}: WorkloadsMetricsParams) => {
  const { toEpochMs, fromEpoch, toEpoch, requestsParams, enabledClusters } =
    useWorkloadsContainersMetricsParams({
      komodorUids,
      endTime,
      type,
    });

  const results = useGetWorkloadsContainersMetrics({
    params: requestsParams,
    enabledClusters,
  });

  const { error, isLoading, isFetching, data } =
    useParseMultipleMetricsResults(results);

  const parsedData = data as (WorkloadMetricsContainers | undefined)[];

  const dataForContainer = useFilterWorkloadsMetricsByContainer(
    wantedContainer,
    parsedData
  );

  const komodorUidsWithoutWantedContainer = komodorUids.filter((_, i) =>
    parsedData?.[i]?.containers?.every(
      ({ containerName }) => containerName !== wantedContainer?.value
    )
  );

  const { graphProps, hasGraphData, linesContent } = useWorkloadsGraphData(
    dataForContainer,
    type
  );

  return {
    isFetching,
    isLoading,
    error,
    graphProps,
    hasGraphData,
    fromEpoch,
    toEpoch,
    toEpochMs,
    linesContent,
    komodorUidsWithoutWantedContainer,
  };
};
