import React from "react";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import Select from "../../../common/controls/Select";
import { RowFieldContainer } from "../common/styles";

import { DeployNotifyOn, MonitorConfiguration } from "@/generated/monitorsApi";

const sinksOptions: Record<DeployNotifyOn, string> = {
  [DeployNotifyOn.All]: "ALL",
  [DeployNotifyOn.Failure]: "Only failed deploys",
  [DeployNotifyOn.Successful]: "Only successful deploys",
};

const Container = styled(RowFieldContainer)`
  gap: 0.8rem;
`;
const DeploySinksOptions: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleOptionChange = (option: string) => {
    setRule((prev) => ({
      ...prev,
      sinksOptions: { notifyOn: [option] },
    }));
  };

  return (
    <Container>
      <Typography variant="text" size="medium">
        For
      </Typography>
      <Select
        onChange={(e) => {
          handleOptionChange(e.target.value);
        }}
        value={rule?.sinksOptions?.notifyOn?.[0]}
      >
        {Object.entries(sinksOptions).map(([sinkKey, sinkLabel]) => (
          <option key={sinkKey} value={sinkKey}>
            {sinkLabel}
          </option>
        ))}
      </Select>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DeploySinksOptions;
