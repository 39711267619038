import React, { useMemo, useState } from "react";
import { get } from "lodash";

import { DescribeModalContainer } from "../modals/DescribeModal/DescribeModal";

import { ActionButtonProps } from "./types";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { ViewOptions } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

const resourceKindToViewName: Record<string, DatadogViewNamesEnum> = {
  Pod: DatadogViewNamesEnum.podDescribeTab,
};

export const DescribeButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [open, setOpen] = useState(false);
  const lastDeployEndDate = get(resource, "lastDeployEndDate");

  const handleClick = () => {
    setOpen(true);
    setIsActionActive?.(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsActionActive?.(false);
  };

  const datadogViewOptions = useMemo(
    (): ViewOptions => ({
      name:
        resourceKindToViewName[resource.kind] ||
        DatadogViewNamesEnum.describeAction,
      context: { feTeam: "operate", beTeam: "operate" },
    }),
    [resource.kind]
  );

  return (
    <>
      <Button onClick={handleClick} />
      <DatadogReportLoadingTimeContextProvider viewOptions={datadogViewOptions}>
        <DescribeModalContainer
          isOpen={open}
          handleClose={handleClose}
          resourceId={resource.id}
          resourceName={resource.name}
          resourceType={resource.kind}
          namespace={resource.namespace}
          cluster={resource.cluster}
          agentId={resource.agentId}
          lastDeployEndDate={lastDeployEndDate}
        />
      </DatadogReportLoadingTimeContextProvider>
    </>
  );
};
