import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import { EmptyState } from "@komodorio/design-system/komodor-ui";

import { Feedback } from "../pod/Feedback";
import { RelatedEvidence } from "../pod/RelatedEvidence";
import { SuggestedRemediation } from "../pod/SuggestedRemediation";
import { WhatHappened } from "../pod/WhatHappened";
import { RichSessionData } from "../common/useEnrichedSession";
import { Loader } from "../pod/Loader";
import { Error } from "../pod/Error";

export type InvestigationContentProps = {
  className?: string;
  richSession: RichSessionData | null;
  isLoading: boolean;
  isError?: boolean;
  onRetryCallback: () => void;
  showBorder?: boolean;
  showFeedback?: boolean;
};

const InvestigationContainer = styled.div<{ showBorder: boolean }>`
  padding: 16px;
  background-color: ${muiColors.common.white};
  gap: 10px;
  border: ${({ showBorder }) =>
    showBorder ? `1px solid ${muiColors.gray[200]}` : "none"};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const InvestigationContent: React.FC<InvestigationContentProps> = ({
  className,
  richSession,
  isLoading,
  onRetryCallback,
  isError,
  showBorder = true,
  showFeedback = true,
}) => {
  const contents = useMemo(() => {
    // Render states are:
    // isError => Error state
    // isLoading => Loading state
    // isComplete + isNoProblemFound => Empty state (see CU-86c1bgnu6: https://app.clickup.com/t/86c1bgnu6)
    // Anything else => Complete and finished session, show results
    if (isError) {
      return (
        <Error
          text={
            richSession?.isStuck
              ? "Investigation Incomplete"
              : "Analysis Failed"
          }
          onRetryCallback={onRetryCallback}
        />
      );
    }

    if (isLoading) {
      return (
        <Box height="200px" width="100%" display="flex">
          <Loader session={richSession} />
        </Box>
      );
    }

    const isNoProblemFound =
      richSession?.isComplete && richSession?.isNoProblemFound;
    if (isNoProblemFound) {
      return (
        <Box width="100%">
          <EmptyState title="No issues found for this investigation." />
        </Box>
      );
    }

    return (
      richSession && (
        <>
          <WhatHappened session={richSession} />
          <RelatedEvidence session={richSession} />
          <SuggestedRemediation session={richSession} />
          {showFeedback && <Feedback />}
        </>
      )
    );
  }, [isError, isLoading, richSession, onRetryCallback, showFeedback]);

  if (richSession?.isComplete && richSession?.isNoProblemFound) {
    return null;
  }

  return (
    <InvestigationContainer className={className} showBorder={showBorder}>
      {contents}
    </InvestigationContainer>
  );
};
