import { useMemo } from "react";

import { ServiceInfo } from "../../../types/ServiceInfo";
import { intersection } from "../../../utils/collections/collections";

import { CheckedOptions } from "./useCheckedOptions";
import { FiltersList } from "./useFiltersList";

export type FiltersCount = { [category: string]: { [option: string]: number } };

const useCheckedServices = (
  filtersList: FiltersList | null,
  prefilteredServices: ServiceInfo[] | undefined,
  checkedOptions: CheckedOptions | null
): {
  checkedServices: ServiceInfo[] | undefined;
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
} => {
  const categoryCheckedServices = useMemo(() => {
    return Object.entries(checkedOptions ?? {}).map(([category, options]) => {
      const services = Array.from(options).flatMap((option) => {
        if (!filtersList?.[category]) return [];
        return filtersList[category][option];
      });
      return { category, services: new Set(services) };
    });
  }, [checkedOptions, filtersList]);

  const checkedServiceIds = useMemo(() => {
    return categoryCheckedServices.length
      ? intersection(categoryCheckedServices.map((s) => s.services))
      : undefined;
  }, [categoryCheckedServices]);

  const checkedServices = useMemo(() => {
    return checkedServiceIds
      ? prefilteredServices?.filter((s) => checkedServiceIds.has(s.id))
      : prefilteredServices;
  }, [checkedServiceIds, prefilteredServices]);

  return { checkedServices };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useCheckedServices;
