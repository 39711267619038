/* tslint:disable */
/* eslint-disable */
/**
 * Auth Service API
 * This is an API that exposes users, authentication and authorization related functionality
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface APIKey
 */
export interface APIKey {
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    'expiredAt'?: string;
}


/**
 * Check if a given object implements the APIKey interface.
 */
export function instanceOfAPIKey(value: object): value is APIKey {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    return true;
}

export function APIKeyFromJSON(json: any): APIKey {
    return APIKeyFromJSONTyped(json, false);
}

export function APIKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIKey {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'key': json['key'],
        'createdAt': json['createdAt'],
        'accountId': json['accountId'],
        'userId': json['userId'],
        'expiredAt': json['expiredAt'] == null ? undefined : json['expiredAt'],
    };
}

export function APIKeyToJSON(value?: APIKey | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'key': value['key'],
        'createdAt': value['createdAt'],
        'accountId': value['accountId'],
        'userId': value['userId'],
        'expiredAt': value['expiredAt'],
    };
}

        /**
 * 
 * @export
 * @interface ApiResource
 */
export interface ApiResource {
    /**
     * 
     * @type {string}
     * @memberof ApiResource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApiResource
     */
    'apiVersion': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiResource
     */
    'verbs': Array<string>;
}


/**
 * Check if a given object implements the ApiResource interface.
 */
export function instanceOfApiResource(value: object): value is ApiResource {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('apiVersion' in value) || value['apiVersion'] === undefined) return false;
    if (!('verbs' in value) || value['verbs'] === undefined) return false;
    return true;
}

export function ApiResourceFromJSON(json: any): ApiResource {
    return ApiResourceFromJSONTyped(json, false);
}

export function ApiResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResource {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'apiVersion': json['apiVersion'],
        'verbs': json['verbs'],
    };
}

export function ApiResourceToJSON(value?: ApiResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'apiVersion': value['apiVersion'],
        'verbs': value['verbs'],
    };
}

        /**
 * 
 * @export
 * @interface ApiServerUrlClusterErrorResponse
 */
export interface ApiServerUrlClusterErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiServerUrlClusterErrorResponse
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiServerUrlClusterErrorResponse
     */
    'error'?: string;
}


/**
 * Check if a given object implements the ApiServerUrlClusterErrorResponse interface.
 */
export function instanceOfApiServerUrlClusterErrorResponse(value: object): value is ApiServerUrlClusterErrorResponse {
    return true;
}

export function ApiServerUrlClusterErrorResponseFromJSON(json: any): ApiServerUrlClusterErrorResponse {
    return ApiServerUrlClusterErrorResponseFromJSONTyped(json, false);
}

export function ApiServerUrlClusterErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiServerUrlClusterErrorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'] == null ? undefined : json['clusterName'],
        'error': json['error'] == null ? undefined : json['error'],
    };
}

export function ApiServerUrlClusterErrorResponseToJSON(value?: ApiServerUrlClusterErrorResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
        'error': value['error'],
    };
}

        /**
 * 
 * @export
 * @interface ApiServerUrlClusterRequest
 */
export interface ApiServerUrlClusterRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiServerUrlClusterRequest
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiServerUrlClusterRequest
     */
    'apiServerUrl': string;
}


/**
 * Check if a given object implements the ApiServerUrlClusterRequest interface.
 */
export function instanceOfApiServerUrlClusterRequest(value: object): value is ApiServerUrlClusterRequest {
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('apiServerUrl' in value) || value['apiServerUrl'] === undefined) return false;
    return true;
}

export function ApiServerUrlClusterRequestFromJSON(json: any): ApiServerUrlClusterRequest {
    return ApiServerUrlClusterRequestFromJSONTyped(json, false);
}

export function ApiServerUrlClusterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiServerUrlClusterRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'],
        'apiServerUrl': json['apiServerUrl'],
    };
}

export function ApiServerUrlClusterRequestToJSON(value?: ApiServerUrlClusterRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
        'apiServerUrl': value['apiServerUrl'],
    };
}

        /**
 * 
 * @export
 * @interface ApiServerUrlUpdateRequest
 */
export interface ApiServerUrlUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiServerUrlUpdateRequest
     */
    'accountId': string;
    /**
     * 
     * @type {Array<ApiServerUrlClusterRequest>}
     * @memberof ApiServerUrlUpdateRequest
     */
    'data': Array<ApiServerUrlClusterRequest>;
}


/**
 * Check if a given object implements the ApiServerUrlUpdateRequest interface.
 */
export function instanceOfApiServerUrlUpdateRequest(value: object): value is ApiServerUrlUpdateRequest {
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ApiServerUrlUpdateRequestFromJSON(json: any): ApiServerUrlUpdateRequest {
    return ApiServerUrlUpdateRequestFromJSONTyped(json, false);
}

export function ApiServerUrlUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiServerUrlUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'],
        'data': ((json['data'] as Array<any>).map(ApiServerUrlClusterRequestFromJSON)),
    };
}

export function ApiServerUrlUpdateRequestToJSON(value?: ApiServerUrlUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'data': ((value['data'] as Array<any>).map(ApiServerUrlClusterRequestToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ApiServerUrlUpdateResponse
 */
export interface ApiServerUrlUpdateResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiServerUrlUpdateResponse
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {Array<ApiServerUrlClusterErrorResponse>}
     * @memberof ApiServerUrlUpdateResponse
     */
    'errors'?: Array<ApiServerUrlClusterErrorResponse>;
}


/**
 * Check if a given object implements the ApiServerUrlUpdateResponse interface.
 */
export function instanceOfApiServerUrlUpdateResponse(value: object): value is ApiServerUrlUpdateResponse {
    return true;
}

export function ApiServerUrlUpdateResponseFromJSON(json: any): ApiServerUrlUpdateResponse {
    return ApiServerUrlUpdateResponseFromJSONTyped(json, false);
}

export function ApiServerUrlUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiServerUrlUpdateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : json['data'],
        'errors': json['errors'] == null ? undefined : ((json['errors'] as Array<any>).map(ApiServerUrlClusterErrorResponseFromJSON)),
    };
}

export function ApiServerUrlUpdateResponseToJSON(value?: ApiServerUrlUpdateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'errors': value['errors'] == null ? undefined : ((value['errors'] as Array<any>).map(ApiServerUrlClusterErrorResponseToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ApiV1AuthCanIDoInScopePostRequest
 */
export interface ApiV1AuthCanIDoInScopePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiV1AuthCanIDoInScopePostRequest
     */
    'action': string;
    /**
     * 
     * @type {Array<RbacPolicyResources>}
     * @memberof ApiV1AuthCanIDoInScopePostRequest
     */
    'scope': Array<RbacPolicyResources>;
}


/**
 * Check if a given object implements the ApiV1AuthCanIDoInScopePostRequest interface.
 */
export function instanceOfApiV1AuthCanIDoInScopePostRequest(value: object): value is ApiV1AuthCanIDoInScopePostRequest {
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('scope' in value) || value['scope'] === undefined) return false;
    return true;
}

export function ApiV1AuthCanIDoInScopePostRequestFromJSON(json: any): ApiV1AuthCanIDoInScopePostRequest {
    return ApiV1AuthCanIDoInScopePostRequestFromJSONTyped(json, false);
}

export function ApiV1AuthCanIDoInScopePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiV1AuthCanIDoInScopePostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'action': json['action'],
        'scope': ((json['scope'] as Array<any>).map(RbacPolicyResourcesFromJSON)),
    };
}

export function ApiV1AuthCanIDoInScopePostRequestToJSON(value?: ApiV1AuthCanIDoInScopePostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'action': value['action'],
        'scope': ((value['scope'] as Array<any>).map(RbacPolicyResourcesToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ApiV1RbacCustomK8sActionsIdDelete200Response
 */
export interface ApiV1RbacCustomK8sActionsIdDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof ApiV1RbacCustomK8sActionsIdDelete200Response
     */
    'id'?: string;
}


/**
 * Check if a given object implements the ApiV1RbacCustomK8sActionsIdDelete200Response interface.
 */
export function instanceOfApiV1RbacCustomK8sActionsIdDelete200Response(value: object): value is ApiV1RbacCustomK8sActionsIdDelete200Response {
    return true;
}

export function ApiV1RbacCustomK8sActionsIdDelete200ResponseFromJSON(json: any): ApiV1RbacCustomK8sActionsIdDelete200Response {
    return ApiV1RbacCustomK8sActionsIdDelete200ResponseFromJSONTyped(json, false);
}

export function ApiV1RbacCustomK8sActionsIdDelete200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiV1RbacCustomK8sActionsIdDelete200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
    };
}

export function ApiV1RbacCustomK8sActionsIdDelete200ResponseToJSON(value?: ApiV1RbacCustomK8sActionsIdDelete200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
    };
}

        /**
 * 
 * @export
 * @interface CanIDoInScopeResponse
 */
export interface CanIDoInScopeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CanIDoInScopeResponse
     */
    'canDo': boolean;
}


/**
 * Check if a given object implements the CanIDoInScopeResponse interface.
 */
export function instanceOfCanIDoInScopeResponse(value: object): value is CanIDoInScopeResponse {
    if (!('canDo' in value) || value['canDo'] === undefined) return false;
    return true;
}

export function CanIDoInScopeResponseFromJSON(json: any): CanIDoInScopeResponse {
    return CanIDoInScopeResponseFromJSONTyped(json, false);
}

export function CanIDoInScopeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanIDoInScopeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'canDo': json['canDo'],
    };
}

export function CanIDoInScopeResponseToJSON(value?: CanIDoInScopeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'canDo': value['canDo'],
    };
}

        /**
 * 
 * @export
 * @interface CanIResponse
 */
export interface CanIResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CanIResponse
     */
    'komodorPermission': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanIResponse
     */
    'clusterPermission': boolean;
}


/**
 * Check if a given object implements the CanIResponse interface.
 */
export function instanceOfCanIResponse(value: object): value is CanIResponse {
    if (!('komodorPermission' in value) || value['komodorPermission'] === undefined) return false;
    if (!('clusterPermission' in value) || value['clusterPermission'] === undefined) return false;
    return true;
}

export function CanIResponseFromJSON(json: any): CanIResponse {
    return CanIResponseFromJSONTyped(json, false);
}

export function CanIResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanIResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'komodorPermission': json['komodorPermission'],
        'clusterPermission': json['clusterPermission'],
    };
}

export function CanIResponseToJSON(value?: CanIResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'komodorPermission': value['komodorPermission'],
        'clusterPermission': value['clusterPermission'],
    };
}

        /**
 * 
 * @export
 * @interface ClusterList
 */
export interface ClusterList {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusterList
     */
    'clusters': Array<string>;
}


/**
 * Check if a given object implements the ClusterList interface.
 */
export function instanceOfClusterList(value: object): value is ClusterList {
    if (!('clusters' in value) || value['clusters'] === undefined) return false;
    return true;
}

export function ClusterListFromJSON(json: any): ClusterList {
    return ClusterListFromJSONTyped(json, false);
}

export function ClusterListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterList {
    if (json == null) {
        return json;
    }
    return {
        
        'clusters': json['clusters'],
    };
}

export function ClusterListToJSON(value?: ClusterList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusters': value['clusters'],
    };
}

        /**
 * 
 * @export
 * @interface CreateAPIKeyRequest
 */
export interface CreateAPIKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAPIKeyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPIKeyRequest
     */
    'key': string;
}


/**
 * Check if a given object implements the CreateAPIKeyRequest interface.
 */
export function instanceOfCreateAPIKeyRequest(value: object): value is CreateAPIKeyRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    return true;
}

export function CreateAPIKeyRequestFromJSON(json: any): CreateAPIKeyRequest {
    return CreateAPIKeyRequestFromJSONTyped(json, false);
}

export function CreateAPIKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAPIKeyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'key': json['key'],
    };
}

export function CreateAPIKeyRequestToJSON(value?: CreateAPIKeyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'key': value['key'],
    };
}

        /**
 * 
 * @export
 * @interface CreateRbacCustomK8sActionPayload
 */
export interface CreateRbacCustomK8sActionPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateRbacCustomK8sActionPayload
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRbacCustomK8sActionPayload
     */
    'description'?: string;
    /**
     * 
     * @type {Array<KubernetesRbacPolicyRuleV1Inner>}
     * @memberof CreateRbacCustomK8sActionPayload
     */
    'k8sRuleset': Array<KubernetesRbacPolicyRuleV1Inner>;
}


/**
 * Check if a given object implements the CreateRbacCustomK8sActionPayload interface.
 */
export function instanceOfCreateRbacCustomK8sActionPayload(value: object): value is CreateRbacCustomK8sActionPayload {
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('k8sRuleset' in value) || value['k8sRuleset'] === undefined) return false;
    return true;
}

export function CreateRbacCustomK8sActionPayloadFromJSON(json: any): CreateRbacCustomK8sActionPayload {
    return CreateRbacCustomK8sActionPayloadFromJSONTyped(json, false);
}

export function CreateRbacCustomK8sActionPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRbacCustomK8sActionPayload {
    if (json == null) {
        return json;
    }
    return {
        
        'action': json['action'],
        'description': json['description'] == null ? undefined : json['description'],
        'k8sRuleset': ((json['k8sRuleset'] as Array<any>).map(KubernetesRbacPolicyRuleV1InnerFromJSON)),
    };
}

export function CreateRbacCustomK8sActionPayloadToJSON(value?: CreateRbacCustomK8sActionPayload | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'action': value['action'],
        'description': value['description'],
        'k8sRuleset': ((value['k8sRuleset'] as Array<any>).map(KubernetesRbacPolicyRuleV1InnerToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface CreateRbacPolicyRequest
 */
export interface CreateRbacPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRbacPolicyRequest
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRbacPolicyRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<RbacPolicyStatementsInner>}
     * @memberof CreateRbacPolicyRequest
     */
    'statements'?: Array<RbacPolicyStatementsInner>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateRbacPolicyRequest
     */
    'tags'?: { [key: string]: string; };
    /**
     * 
     * @type {RbacPolicyType}
     * @memberof CreateRbacPolicyRequest
     */
    'type'?: RbacPolicyType;
}


/**
 * Check if a given object implements the CreateRbacPolicyRequest interface.
 */
export function instanceOfCreateRbacPolicyRequest(value: object): value is CreateRbacPolicyRequest {
    return true;
}

export function CreateRbacPolicyRequestFromJSON(json: any): CreateRbacPolicyRequest {
    return CreateRbacPolicyRequestFromJSONTyped(json, false);
}

export function CreateRbacPolicyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRbacPolicyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'name': json['name'] == null ? undefined : json['name'],
        'statements': json['statements'] == null ? undefined : ((json['statements'] as Array<any>).map(RbacPolicyStatementsInnerFromJSON)),
        'tags': json['tags'] == null ? undefined : json['tags'],
        'type': json['type'] == null ? undefined : RbacPolicyTypeFromJSON(json['type']),
    };
}

export function CreateRbacPolicyRequestToJSON(value?: CreateRbacPolicyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'name': value['name'],
        'statements': value['statements'] == null ? undefined : ((value['statements'] as Array<any>).map(RbacPolicyStatementsInnerToJSON)),
        'tags': value['tags'],
        'type': RbacPolicyTypeToJSON(value['type']),
    };
}

        /**
 * 
 * @export
 * @interface CreateRbacRolePolicyRequest
 */
export interface CreateRbacRolePolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRbacRolePolicyRequest
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRbacRolePolicyRequest
     */
    'policyId': string;
}


/**
 * Check if a given object implements the CreateRbacRolePolicyRequest interface.
 */
export function instanceOfCreateRbacRolePolicyRequest(value: object): value is CreateRbacRolePolicyRequest {
    if (!('roleId' in value) || value['roleId'] === undefined) return false;
    if (!('policyId' in value) || value['policyId'] === undefined) return false;
    return true;
}

export function CreateRbacRolePolicyRequestFromJSON(json: any): CreateRbacRolePolicyRequest {
    return CreateRbacRolePolicyRequestFromJSONTyped(json, false);
}

export function CreateRbacRolePolicyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRbacRolePolicyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'roleId': json['roleId'],
        'policyId': json['policyId'],
    };
}

export function CreateRbacRolePolicyRequestToJSON(value?: CreateRbacRolePolicyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'roleId': value['roleId'],
        'policyId': value['policyId'],
    };
}

        /**
 * 
 * @export
 * @interface CreateRbacRoleRequest
 */
export interface CreateRbacRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRbacRoleRequest
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRbacRoleRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRbacRoleRequest
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRbacRoleRequest
     */
    'rbacPolicyIds'?: Array<string>;
}


/**
 * Check if a given object implements the CreateRbacRoleRequest interface.
 */
export function instanceOfCreateRbacRoleRequest(value: object): value is CreateRbacRoleRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CreateRbacRoleRequestFromJSON(json: any): CreateRbacRoleRequest {
    return CreateRbacRoleRequestFromJSONTyped(json, false);
}

export function CreateRbacRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRbacRoleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'name': json['name'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
        'rbacPolicyIds': json['rbacPolicyIds'] == null ? undefined : json['rbacPolicyIds'],
    };
}

export function CreateRbacRoleRequestToJSON(value?: CreateRbacRoleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'name': value['name'],
        'isDefault': value['isDefault'],
        'rbacPolicyIds': value['rbacPolicyIds'],
    };
}

        /**
 * 
 * @export
 * @interface CreateRbacUserRoleRequest
 */
export interface CreateRbacUserRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRbacUserRoleRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRbacUserRoleRequest
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRbacUserRoleRequest
     */
    'expiration'?: string;
}


/**
 * Check if a given object implements the CreateRbacUserRoleRequest interface.
 */
export function instanceOfCreateRbacUserRoleRequest(value: object): value is CreateRbacUserRoleRequest {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('roleId' in value) || value['roleId'] === undefined) return false;
    return true;
}

export function CreateRbacUserRoleRequestFromJSON(json: any): CreateRbacUserRoleRequest {
    return CreateRbacUserRoleRequestFromJSONTyped(json, false);
}

export function CreateRbacUserRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRbacUserRoleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'roleId': json['roleId'],
        'expiration': json['expiration'] == null ? undefined : json['expiration'],
    };
}

export function CreateRbacUserRoleRequestToJSON(value?: CreateRbacUserRoleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'roleId': value['roleId'],
        'expiration': value['expiration'],
    };
}

        /**
 * 
 * @export
 * @interface CreateRoleRequest
 */
export interface CreateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleRequest
     */
    'permissions': Array<string>;
}


/**
 * Check if a given object implements the CreateRoleRequest interface.
 */
export function instanceOfCreateRoleRequest(value: object): value is CreateRoleRequest {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('permissions' in value) || value['permissions'] === undefined) return false;
    return true;
}

export function CreateRoleRequestFromJSON(json: any): CreateRoleRequest {
    return CreateRoleRequestFromJSONTyped(json, false);
}

export function CreateRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRoleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'permissions': json['permissions'],
    };
}

export function CreateRoleRequestToJSON(value?: CreateRoleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'permissions': value['permissions'],
    };
}

        /**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'acceptedTos'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest
     */
    'allowedAccounts'?: Array<string>;
}


/**
 * Check if a given object implements the CreateUserRequest interface.
 */
export function instanceOfCreateUserRequest(value: object): value is CreateUserRequest {
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function CreateUserRequestFromJSON(json: any): CreateUserRequest {
    return CreateUserRequestFromJSONTyped(json, false);
}

export function CreateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'displayName': json['displayName'],
        'email': json['email'],
        'isVerified': json['isVerified'] == null ? undefined : json['isVerified'],
        'acceptedTos': json['acceptedTos'] == null ? undefined : json['acceptedTos'],
        'allowedAccounts': json['allowedAccounts'] == null ? undefined : json['allowedAccounts'],
    };
}

export function CreateUserRequestToJSON(value?: CreateUserRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'displayName': value['displayName'],
        'email': value['email'],
        'isVerified': value['isVerified'],
        'acceptedTos': value['acceptedTos'],
        'allowedAccounts': value['allowedAccounts'],
    };
}

        /**
 * 
 * @export
 * @interface DeleteModelByIdResponse
 */
export interface DeleteModelByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof DeleteModelByIdResponse
     */
    'id'?: string;
}


/**
 * Check if a given object implements the DeleteModelByIdResponse interface.
 */
export function instanceOfDeleteModelByIdResponse(value: object): value is DeleteModelByIdResponse {
    return true;
}

export function DeleteModelByIdResponseFromJSON(json: any): DeleteModelByIdResponse {
    return DeleteModelByIdResponseFromJSONTyped(json, false);
}

export function DeleteModelByIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteModelByIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
    };
}

export function DeleteModelByIdResponseToJSON(value?: DeleteModelByIdResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
    };
}

        /**
 * 
 * @export
 * @interface DeleteRbacRolePolicyRequest
 */
export interface DeleteRbacRolePolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteRbacRolePolicyRequest
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteRbacRolePolicyRequest
     */
    'policyId': string;
}


/**
 * Check if a given object implements the DeleteRbacRolePolicyRequest interface.
 */
export function instanceOfDeleteRbacRolePolicyRequest(value: object): value is DeleteRbacRolePolicyRequest {
    if (!('roleId' in value) || value['roleId'] === undefined) return false;
    if (!('policyId' in value) || value['policyId'] === undefined) return false;
    return true;
}

export function DeleteRbacRolePolicyRequestFromJSON(json: any): DeleteRbacRolePolicyRequest {
    return DeleteRbacRolePolicyRequestFromJSONTyped(json, false);
}

export function DeleteRbacRolePolicyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteRbacRolePolicyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'roleId': json['roleId'],
        'policyId': json['policyId'],
    };
}

export function DeleteRbacRolePolicyRequestToJSON(value?: DeleteRbacRolePolicyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'roleId': value['roleId'],
        'policyId': value['policyId'],
    };
}

        /**
 * 
 * @export
 * @interface EffectivePermission
 */
export interface EffectivePermission {
    /**
     * 
     * @type {string}
     * @memberof EffectivePermission
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof EffectivePermission
     */
    'policyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EffectivePermission
     */
    'roleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EffectivePermission
     */
    'cluster'?: string;
    /**
     * 
     * @type {string}
     * @memberof EffectivePermission
     */
    'namespace'?: string;
}


/**
 * Check if a given object implements the EffectivePermission interface.
 */
export function instanceOfEffectivePermission(value: object): value is EffectivePermission {
    return true;
}

export function EffectivePermissionFromJSON(json: any): EffectivePermission {
    return EffectivePermissionFromJSONTyped(json, false);
}

export function EffectivePermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EffectivePermission {
    if (json == null) {
        return json;
    }
    return {
        
        'action': json['action'] == null ? undefined : json['action'],
        'policyName': json['policyName'] == null ? undefined : json['policyName'],
        'roleName': json['roleName'] == null ? undefined : json['roleName'],
        'cluster': json['cluster'] == null ? undefined : json['cluster'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
    };
}

export function EffectivePermissionToJSON(value?: EffectivePermission | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'action': value['action'],
        'policyName': value['policyName'],
        'roleName': value['roleName'],
        'cluster': value['cluster'],
        'namespace': value['namespace'],
    };
}

        /**
 * 
 * @export
 * @interface GetManyUsersByIDsRequest
 */
export interface GetManyUsersByIDsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetManyUsersByIDsRequest
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetManyUsersByIDsRequest
     */
    'accountId'?: string;
}


/**
 * Check if a given object implements the GetManyUsersByIDsRequest interface.
 */
export function instanceOfGetManyUsersByIDsRequest(value: object): value is GetManyUsersByIDsRequest {
    if (!('ids' in value) || value['ids'] === undefined) return false;
    return true;
}

export function GetManyUsersByIDsRequestFromJSON(json: any): GetManyUsersByIDsRequest {
    return GetManyUsersByIDsRequestFromJSONTyped(json, false);
}

export function GetManyUsersByIDsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetManyUsersByIDsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
    };
}

export function GetManyUsersByIDsRequestToJSON(value?: GetManyUsersByIDsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ids': value['ids'],
        'accountId': value['accountId'],
    };
}

        /**
 * 
 * @export
 * @interface KubernetesRbacPolicyRuleV1Inner
 */
export interface KubernetesRbacPolicyRuleV1Inner {
    /**
     * 
     * @type {Array<string>}
     * @memberof KubernetesRbacPolicyRuleV1Inner
     */
    'apiGroups': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KubernetesRbacPolicyRuleV1Inner
     */
    'resources': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KubernetesRbacPolicyRuleV1Inner
     */
    'verbs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KubernetesRbacPolicyRuleV1Inner
     */
    'resourcesNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KubernetesRbacPolicyRuleV1Inner
     */
    'nonResourceURLs'?: Array<string>;
}


/**
 * Check if a given object implements the KubernetesRbacPolicyRuleV1Inner interface.
 */
export function instanceOfKubernetesRbacPolicyRuleV1Inner(value: object): value is KubernetesRbacPolicyRuleV1Inner {
    if (!('apiGroups' in value) || value['apiGroups'] === undefined) return false;
    if (!('resources' in value) || value['resources'] === undefined) return false;
    if (!('verbs' in value) || value['verbs'] === undefined) return false;
    return true;
}

export function KubernetesRbacPolicyRuleV1InnerFromJSON(json: any): KubernetesRbacPolicyRuleV1Inner {
    return KubernetesRbacPolicyRuleV1InnerFromJSONTyped(json, false);
}

export function KubernetesRbacPolicyRuleV1InnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): KubernetesRbacPolicyRuleV1Inner {
    if (json == null) {
        return json;
    }
    return {
        
        'apiGroups': json['apiGroups'],
        'resources': json['resources'],
        'verbs': json['verbs'],
        'resourcesNames': json['resourcesNames'] == null ? undefined : json['resourcesNames'],
        'nonResourceURLs': json['nonResourceURLs'] == null ? undefined : json['nonResourceURLs'],
    };
}

export function KubernetesRbacPolicyRuleV1InnerToJSON(value?: KubernetesRbacPolicyRuleV1Inner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'apiGroups': value['apiGroups'],
        'resources': value['resources'],
        'verbs': value['verbs'],
        'resourcesNames': value['resourcesNames'],
        'nonResourceURLs': value['nonResourceURLs'],
    };
}

        /**
 * 
 * @export
 * @interface RawHasuraClaims
 */
export interface RawHasuraClaims {
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-role'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-user-id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-accepted-tos'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-disabled'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-trial-end-date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-origin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-allowed-accounts'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-original-account-id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-allowed-resources'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-allowed-actions'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-salesforce-id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-registration-date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-acquisition-channel'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-purchase-date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawHasuraClaims
     */
    'x-hasura-account-plan'?: string;
}


/**
 * Check if a given object implements the RawHasuraClaims interface.
 */
export function instanceOfRawHasuraClaims(value: object): value is RawHasuraClaims {
    return true;
}

export function RawHasuraClaimsFromJSON(json: any): RawHasuraClaims {
    return RawHasuraClaimsFromJSONTyped(json, false);
}

export function RawHasuraClaimsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawHasuraClaims {
    if (json == null) {
        return json;
    }
    return {
        
        'x-hasura-role': json['x-hasura-role'] == null ? undefined : json['x-hasura-role'],
        'x-hasura-account-id': json['x-hasura-account-id'] == null ? undefined : json['x-hasura-account-id'],
        'x-hasura-account-name': json['x-hasura-account-name'] == null ? undefined : json['x-hasura-account-name'],
        'x-hasura-user-id': json['x-hasura-user-id'] == null ? undefined : json['x-hasura-user-id'],
        'x-hasura-accepted-tos': json['x-hasura-accepted-tos'] == null ? undefined : json['x-hasura-accepted-tos'],
        'x-hasura-account-disabled': json['x-hasura-account-disabled'] == null ? undefined : json['x-hasura-account-disabled'],
        'x-hasura-account-trial-end-date': json['x-hasura-account-trial-end-date'] == null ? undefined : json['x-hasura-account-trial-end-date'],
        'x-hasura-account-origin': json['x-hasura-account-origin'] == null ? undefined : json['x-hasura-account-origin'],
        'x-hasura-allowed-accounts': json['x-hasura-allowed-accounts'] == null ? undefined : json['x-hasura-allowed-accounts'],
        'x-hasura-original-account-id': json['x-hasura-original-account-id'] == null ? undefined : json['x-hasura-original-account-id'],
        'x-hasura-allowed-resources': json['x-hasura-allowed-resources'] == null ? undefined : json['x-hasura-allowed-resources'],
        'x-hasura-allowed-actions': json['x-hasura-allowed-actions'] == null ? undefined : json['x-hasura-allowed-actions'],
        'x-hasura-account-salesforce-id': json['x-hasura-account-salesforce-id'] == null ? undefined : json['x-hasura-account-salesforce-id'],
        'x-hasura-account-registration-date': json['x-hasura-account-registration-date'] == null ? undefined : json['x-hasura-account-registration-date'],
        'x-hasura-account-acquisition-channel': json['x-hasura-account-acquisition-channel'] == null ? undefined : json['x-hasura-account-acquisition-channel'],
        'x-hasura-account-purchase-date': json['x-hasura-account-purchase-date'] == null ? undefined : json['x-hasura-account-purchase-date'],
        'x-hasura-account-plan': json['x-hasura-account-plan'] == null ? undefined : json['x-hasura-account-plan'],
    };
}

export function RawHasuraClaimsToJSON(value?: RawHasuraClaims | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'x-hasura-role': value['x-hasura-role'],
        'x-hasura-account-id': value['x-hasura-account-id'],
        'x-hasura-account-name': value['x-hasura-account-name'],
        'x-hasura-user-id': value['x-hasura-user-id'],
        'x-hasura-accepted-tos': value['x-hasura-accepted-tos'],
        'x-hasura-account-disabled': value['x-hasura-account-disabled'],
        'x-hasura-account-trial-end-date': value['x-hasura-account-trial-end-date'],
        'x-hasura-account-origin': value['x-hasura-account-origin'],
        'x-hasura-allowed-accounts': value['x-hasura-allowed-accounts'],
        'x-hasura-original-account-id': value['x-hasura-original-account-id'],
        'x-hasura-allowed-resources': value['x-hasura-allowed-resources'],
        'x-hasura-allowed-actions': value['x-hasura-allowed-actions'],
        'x-hasura-account-salesforce-id': value['x-hasura-account-salesforce-id'],
        'x-hasura-account-registration-date': value['x-hasura-account-registration-date'],
        'x-hasura-account-acquisition-channel': value['x-hasura-account-acquisition-channel'],
        'x-hasura-account-purchase-date': value['x-hasura-account-purchase-date'],
        'x-hasura-account-plan': value['x-hasura-account-plan'],
    };
}

        /**
 * 
 * @export
 * @interface RbacClusterScheduleSyncRequest
 */
export interface RbacClusterScheduleSyncRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RbacClusterScheduleSyncRequest
     */
    'clusters': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RbacClusterScheduleSyncRequest
     */
    'updatedAt'?: string;
}


/**
 * Check if a given object implements the RbacClusterScheduleSyncRequest interface.
 */
export function instanceOfRbacClusterScheduleSyncRequest(value: object): value is RbacClusterScheduleSyncRequest {
    if (!('clusters' in value) || value['clusters'] === undefined) return false;
    return true;
}

export function RbacClusterScheduleSyncRequestFromJSON(json: any): RbacClusterScheduleSyncRequest {
    return RbacClusterScheduleSyncRequestFromJSONTyped(json, false);
}

export function RbacClusterScheduleSyncRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacClusterScheduleSyncRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clusters': json['clusters'],
        'updatedAt': json['updatedAt'] == null ? undefined : json['updatedAt'],
    };
}

export function RbacClusterScheduleSyncRequestToJSON(value?: RbacClusterScheduleSyncRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusters': value['clusters'],
        'updatedAt': value['updatedAt'],
    };
}

        /**
 * 
 * @export
 * @interface RbacClusterStatus
 */
export interface RbacClusterStatus {
    /**
     * 
     * @type {string}
     * @memberof RbacClusterStatus
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof RbacClusterStatus
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof RbacClusterStatus
     */
    'lastUpdatedKomodorRbac': string | null;
    /**
     * 
     * @type {string}
     * @memberof RbacClusterStatus
     */
    'lastUpdatedCluster': string | null;
    /**
     * 
     * @type {string}
     * @memberof RbacClusterStatus
     */
    'lastSyncAttemptedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RbacClusterStatus
     */
    'error': string | null;
    /**
     * 
     * @type {string}
     * @memberof RbacClusterStatus
     */
    'apiServerUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RbacClusterStatus
     */
    'isAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RbacClusterStatus
     */
    'isSynced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RbacClusterStatus
     */
    'requiresCleanup'?: boolean;
}


/**
 * Check if a given object implements the RbacClusterStatus interface.
 */
export function instanceOfRbacClusterStatus(value: object): value is RbacClusterStatus {
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('lastUpdatedKomodorRbac' in value) || value['lastUpdatedKomodorRbac'] === undefined) return false;
    if (!('lastUpdatedCluster' in value) || value['lastUpdatedCluster'] === undefined) return false;
    if (!('error' in value) || value['error'] === undefined) return false;
    return true;
}

export function RbacClusterStatusFromJSON(json: any): RbacClusterStatus {
    return RbacClusterStatusFromJSONTyped(json, false);
}

export function RbacClusterStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacClusterStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'],
        'clusterName': json['clusterName'],
        'lastUpdatedKomodorRbac': json['lastUpdatedKomodorRbac'],
        'lastUpdatedCluster': json['lastUpdatedCluster'],
        'lastSyncAttemptedAt': json['lastSyncAttemptedAt'] == null ? undefined : json['lastSyncAttemptedAt'],
        'error': json['error'],
        'apiServerUrl': json['apiServerUrl'] == null ? undefined : json['apiServerUrl'],
        'isAvailable': json['isAvailable'] == null ? undefined : json['isAvailable'],
        'isSynced': json['isSynced'] == null ? undefined : json['isSynced'],
        'requiresCleanup': json['requiresCleanup'] == null ? undefined : json['requiresCleanup'],
    };
}

export function RbacClusterStatusToJSON(value?: RbacClusterStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'clusterName': value['clusterName'],
        'lastUpdatedKomodorRbac': value['lastUpdatedKomodorRbac'],
        'lastUpdatedCluster': value['lastUpdatedCluster'],
        'lastSyncAttemptedAt': value['lastSyncAttemptedAt'],
        'error': value['error'],
        'apiServerUrl': value['apiServerUrl'],
        'isAvailable': value['isAvailable'],
        'isSynced': value['isSynced'],
        'requiresCleanup': value['requiresCleanup'],
    };
}

        /**
 * 
 * @export
 * @interface RbacClusterStatusDeleteRequest
 */
export interface RbacClusterStatusDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof RbacClusterStatusDeleteRequest
     */
    'clusterName': string;
}


/**
 * Check if a given object implements the RbacClusterStatusDeleteRequest interface.
 */
export function instanceOfRbacClusterStatusDeleteRequest(value: object): value is RbacClusterStatusDeleteRequest {
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    return true;
}

export function RbacClusterStatusDeleteRequestFromJSON(json: any): RbacClusterStatusDeleteRequest {
    return RbacClusterStatusDeleteRequestFromJSONTyped(json, false);
}

export function RbacClusterStatusDeleteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacClusterStatusDeleteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'],
    };
}

export function RbacClusterStatusDeleteRequestToJSON(value?: RbacClusterStatusDeleteRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
    };
}

        /**
 * 
 * @export
 * @interface RbacCustomK8sAction
 */
export interface RbacCustomK8sAction {
    /**
     * 
     * @type {string}
     * @memberof RbacCustomK8sAction
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RbacCustomK8sAction
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof RbacCustomK8sAction
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RbacCustomK8sAction
     */
    'type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RbacCustomK8sAction
     */
    'scopingMethods': Array<string>;
    /**
     * 
     * @type {Array<KubernetesRbacPolicyRuleV1Inner>}
     * @memberof RbacCustomK8sAction
     */
    'k8sRuleset': Array<KubernetesRbacPolicyRuleV1Inner>;
    /**
     * 
     * @type {string}
     * @memberof RbacCustomK8sAction
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof RbacCustomK8sAction
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RbacCustomK8sAction
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof RbacCustomK8sAction
     */
    'deletedAt'?: string;
}


/**
 * Check if a given object implements the RbacCustomK8sAction interface.
 */
export function instanceOfRbacCustomK8sAction(value: object): value is RbacCustomK8sAction {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('scopingMethods' in value) || value['scopingMethods'] === undefined) return false;
    if (!('k8sRuleset' in value) || value['k8sRuleset'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function RbacCustomK8sActionFromJSON(json: any): RbacCustomK8sAction {
    return RbacCustomK8sActionFromJSONTyped(json, false);
}

export function RbacCustomK8sActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacCustomK8sAction {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'action': json['action'],
        'description': json['description'],
        'type': json['type'],
        'scopingMethods': json['scopingMethods'],
        'k8sRuleset': ((json['k8sRuleset'] as Array<any>).map(KubernetesRbacPolicyRuleV1InnerFromJSON)),
        'accountId': json['accountId'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'deletedAt': json['deletedAt'] == null ? undefined : json['deletedAt'],
    };
}

export function RbacCustomK8sActionToJSON(value?: RbacCustomK8sAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'action': value['action'],
        'description': value['description'],
        'type': value['type'],
        'scopingMethods': value['scopingMethods'],
        'k8sRuleset': ((value['k8sRuleset'] as Array<any>).map(KubernetesRbacPolicyRuleV1InnerToJSON)),
        'accountId': value['accountId'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'deletedAt': value['deletedAt'],
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicy
 */
export interface RbacPolicy {
    /**
     * 
     * @type {string}
     * @memberof RbacPolicy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RbacPolicy
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RbacPolicy
     */
    'accountId': string;
    /**
     * 
     * @type {Array<RbacPolicyStatementsInner>}
     * @memberof RbacPolicy
     */
    'statements': Array<RbacPolicyStatementsInner>;
    /**
     * 
     * @type {string}
     * @memberof RbacPolicy
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RbacPolicy
     */
    'updatedAt': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RbacPolicy
     */
    'tags': { [key: string]: string; };
    /**
     * 
     * @type {RbacPolicyType}
     * @memberof RbacPolicy
     */
    'type': RbacPolicyType;
}




/**
 * Check if a given object implements the RbacPolicy interface.
 */
export function instanceOfRbacPolicy(value: object): value is RbacPolicy {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('statements' in value) || value['statements'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function RbacPolicyFromJSON(json: any): RbacPolicy {
    return RbacPolicyFromJSONTyped(json, false);
}

export function RbacPolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicy {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'accountId': json['accountId'],
        'statements': ((json['statements'] as Array<any>).map(RbacPolicyStatementsInnerFromJSON)),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'tags': json['tags'],
        'type': RbacPolicyTypeFromJSON(json['type']),
    };
}

export function RbacPolicyToJSON(value?: RbacPolicy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'accountId': value['accountId'],
        'statements': ((value['statements'] as Array<any>).map(RbacPolicyStatementsInnerToJSON)),
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'tags': value['tags'],
        'type': RbacPolicyTypeToJSON(value['type']),
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicyAction
 */
export interface RbacPolicyAction {
    /**
     * 
     * @type {string}
     * @memberof RbacPolicyAction
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof RbacPolicyAction
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RbacPolicyAction
     */
    'type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RbacPolicyAction
     */
    'scopingMethods': Array<string>;
    /**
     * 
     * @type {Array<KubernetesRbacPolicyRuleV1Inner>}
     * @memberof RbacPolicyAction
     */
    'k8sRuleset': Array<KubernetesRbacPolicyRuleV1Inner>;
}


/**
 * Check if a given object implements the RbacPolicyAction interface.
 */
export function instanceOfRbacPolicyAction(value: object): value is RbacPolicyAction {
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('scopingMethods' in value) || value['scopingMethods'] === undefined) return false;
    if (!('k8sRuleset' in value) || value['k8sRuleset'] === undefined) return false;
    return true;
}

export function RbacPolicyActionFromJSON(json: any): RbacPolicyAction {
    return RbacPolicyActionFromJSONTyped(json, false);
}

export function RbacPolicyActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicyAction {
    if (json == null) {
        return json;
    }
    return {
        
        'action': json['action'],
        'description': json['description'],
        'type': json['type'],
        'scopingMethods': json['scopingMethods'],
        'k8sRuleset': ((json['k8sRuleset'] as Array<any>).map(KubernetesRbacPolicyRuleV1InnerFromJSON)),
    };
}

export function RbacPolicyActionToJSON(value?: RbacPolicyAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'action': value['action'],
        'description': value['description'],
        'type': value['type'],
        'scopingMethods': value['scopingMethods'],
        'k8sRuleset': ((value['k8sRuleset'] as Array<any>).map(KubernetesRbacPolicyRuleV1InnerToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicyAggregationsResponse
 */
export interface RbacPolicyAggregationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RbacPolicyAggregationsResponse
     */
    'success': boolean;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof RbacPolicyAggregationsResponse
     */
    'groups': Array<Array<string>>;
}


/**
 * Check if a given object implements the RbacPolicyAggregationsResponse interface.
 */
export function instanceOfRbacPolicyAggregationsResponse(value: object): value is RbacPolicyAggregationsResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    if (!('groups' in value) || value['groups'] === undefined) return false;
    return true;
}

export function RbacPolicyAggregationsResponseFromJSON(json: any): RbacPolicyAggregationsResponse {
    return RbacPolicyAggregationsResponseFromJSONTyped(json, false);
}

export function RbacPolicyAggregationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicyAggregationsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'groups': json['groups'],
    };
}

export function RbacPolicyAggregationsResponseToJSON(value?: RbacPolicyAggregationsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'groups': value['groups'],
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicyResources
 */
export interface RbacPolicyResources {
    /**
     * 
     * @type {string}
     * @memberof RbacPolicyResources
     */
    'cluster': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RbacPolicyResources
     */
    'namespaces'?: Array<string>;
}


/**
 * Check if a given object implements the RbacPolicyResources interface.
 */
export function instanceOfRbacPolicyResources(value: object): value is RbacPolicyResources {
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    return true;
}

export function RbacPolicyResourcesFromJSON(json: any): RbacPolicyResources {
    return RbacPolicyResourcesFromJSONTyped(json, false);
}

export function RbacPolicyResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicyResources {
    if (json == null) {
        return json;
    }
    return {
        
        'cluster': json['cluster'],
        'namespaces': json['namespaces'] == null ? undefined : json['namespaces'],
    };
}

export function RbacPolicyResourcesToJSON(value?: RbacPolicyResources | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cluster': value['cluster'],
        'namespaces': value['namespaces'],
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicyStatementsInner
 */
export interface RbacPolicyStatementsInner {
    /**
     * 
     * @type {Array<string>}
     * @memberof RbacPolicyStatementsInner
     */
    'actions'?: Array<string>;
    /**
     * 
     * @type {Array<RbacPolicyResources>}
     * @memberof RbacPolicyStatementsInner
     */
    'resources'?: Array<RbacPolicyResources>;
}


/**
 * Check if a given object implements the RbacPolicyStatementsInner interface.
 */
export function instanceOfRbacPolicyStatementsInner(value: object): value is RbacPolicyStatementsInner {
    return true;
}

export function RbacPolicyStatementsInnerFromJSON(json: any): RbacPolicyStatementsInner {
    return RbacPolicyStatementsInnerFromJSONTyped(json, false);
}

export function RbacPolicyStatementsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicyStatementsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'actions': json['actions'] == null ? undefined : json['actions'],
        'resources': json['resources'] == null ? undefined : ((json['resources'] as Array<any>).map(RbacPolicyResourcesFromJSON)),
    };
}

export function RbacPolicyStatementsInnerToJSON(value?: RbacPolicyStatementsInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'actions': value['actions'],
        'resources': value['resources'] == null ? undefined : ((value['resources'] as Array<any>).map(RbacPolicyResourcesToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicySyncByTagsRequest
 */
export interface RbacPolicySyncByTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof RbacPolicySyncByTagsRequest
     */
    'operation': RbacPolicySyncByTagsRequestOperationEnum;
    /**
     * 
     * @type {string}
     * @memberof RbacPolicySyncByTagsRequest
     */
    'clusterName': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RbacPolicySyncByTagsRequest
     */
    'tags': { [key: string]: string; };
}


/**
 * @export
 */
export const RbacPolicySyncByTagsRequestOperationEnum = {
    Add: 'ADD',
    Remove: 'REMOVE'
} as const;
export type RbacPolicySyncByTagsRequestOperationEnum = typeof RbacPolicySyncByTagsRequestOperationEnum[keyof typeof RbacPolicySyncByTagsRequestOperationEnum];



/**
 * Check if a given object implements the RbacPolicySyncByTagsRequest interface.
 */
export function instanceOfRbacPolicySyncByTagsRequest(value: object): value is RbacPolicySyncByTagsRequest {
    if (!('operation' in value) || value['operation'] === undefined) return false;
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    return true;
}

export function RbacPolicySyncByTagsRequestFromJSON(json: any): RbacPolicySyncByTagsRequest {
    return RbacPolicySyncByTagsRequestFromJSONTyped(json, false);
}

export function RbacPolicySyncByTagsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicySyncByTagsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'operation': json['operation'],
        'clusterName': json['clusterName'],
        'tags': json['tags'],
    };
}

export function RbacPolicySyncByTagsRequestToJSON(value?: RbacPolicySyncByTagsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operation': value['operation'],
        'clusterName': value['clusterName'],
        'tags': value['tags'],
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicySyncByTagsResponse
 */
export interface RbacPolicySyncByTagsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof RbacPolicySyncByTagsResponse
     */
    'updatedPolicies'?: Array<string>;
}


/**
 * Check if a given object implements the RbacPolicySyncByTagsResponse interface.
 */
export function instanceOfRbacPolicySyncByTagsResponse(value: object): value is RbacPolicySyncByTagsResponse {
    return true;
}

export function RbacPolicySyncByTagsResponseFromJSON(json: any): RbacPolicySyncByTagsResponse {
    return RbacPolicySyncByTagsResponseFromJSONTyped(json, false);
}

export function RbacPolicySyncByTagsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicySyncByTagsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'updatedPolicies': json['updatedPolicies'] == null ? undefined : json['updatedPolicies'],
    };
}

export function RbacPolicySyncByTagsResponseToJSON(value?: RbacPolicySyncByTagsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'updatedPolicies': value['updatedPolicies'],
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicySyncByWildcardRequest
 */
export interface RbacPolicySyncByWildcardRequest {
    /**
     * 
     * @type {string}
     * @memberof RbacPolicySyncByWildcardRequest
     */
    'accountId'?: string;
}


/**
 * Check if a given object implements the RbacPolicySyncByWildcardRequest interface.
 */
export function instanceOfRbacPolicySyncByWildcardRequest(value: object): value is RbacPolicySyncByWildcardRequest {
    return true;
}

export function RbacPolicySyncByWildcardRequestFromJSON(json: any): RbacPolicySyncByWildcardRequest {
    return RbacPolicySyncByWildcardRequestFromJSONTyped(json, false);
}

export function RbacPolicySyncByWildcardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicySyncByWildcardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
    };
}

export function RbacPolicySyncByWildcardRequestToJSON(value?: RbacPolicySyncByWildcardRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
    };
}

        /**
 * 
 * @export
 * @interface RbacPolicySyncByWildcardResponse
 */
export interface RbacPolicySyncByWildcardResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RbacPolicySyncByWildcardResponse
     */
    'success': boolean;
}


/**
 * Check if a given object implements the RbacPolicySyncByWildcardResponse interface.
 */
export function instanceOfRbacPolicySyncByWildcardResponse(value: object): value is RbacPolicySyncByWildcardResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    return true;
}

export function RbacPolicySyncByWildcardResponseFromJSON(json: any): RbacPolicySyncByWildcardResponse {
    return RbacPolicySyncByWildcardResponseFromJSONTyped(json, false);
}

export function RbacPolicySyncByWildcardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicySyncByWildcardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function RbacPolicySyncByWildcardResponseToJSON(value?: RbacPolicySyncByWildcardResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
    };
}

        
/**
 * 
 * @export
 */
export const RbacPolicyType = {
    Static: 'static',
    DynamicTag: 'dynamic_tag',
    Wildcard: 'wildcard'
} as const;
export type RbacPolicyType = typeof RbacPolicyType[keyof typeof RbacPolicyType];


export function instanceOfRbacPolicyType(value: any): boolean {
    for (const key in RbacPolicyType) {
        if (Object.prototype.hasOwnProperty.call(RbacPolicyType, key)) {
            if (RbacPolicyType[key as keyof typeof RbacPolicyType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RbacPolicyTypeFromJSON(json: any): RbacPolicyType {
    return RbacPolicyTypeFromJSONTyped(json, false);
}

export function RbacPolicyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacPolicyType {
    return json as RbacPolicyType;
}

export function RbacPolicyTypeToJSON(value?: RbacPolicyType | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface RbacRole
 */
export interface RbacRole {
    /**
     * 
     * @type {string}
     * @memberof RbacRole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RbacRole
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RbacRole
     */
    'accountId': string;
    /**
     * 
     * @type {boolean}
     * @memberof RbacRole
     */
    'isDefault': boolean;
    /**
     * 
     * @type {string}
     * @memberof RbacRole
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RbacRole
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<RbacPolicy>}
     * @memberof RbacRole
     */
    'rbacPolicies'?: Array<RbacPolicy>;
}


/**
 * Check if a given object implements the RbacRole interface.
 */
export function instanceOfRbacRole(value: object): value is RbacRole {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('isDefault' in value) || value['isDefault'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function RbacRoleFromJSON(json: any): RbacRole {
    return RbacRoleFromJSONTyped(json, false);
}

export function RbacRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'accountId': json['accountId'],
        'isDefault': json['isDefault'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'rbacPolicies': json['rbacPolicies'] == null ? undefined : ((json['rbacPolicies'] as Array<any>).map(RbacPolicyFromJSON)),
    };
}

export function RbacRoleToJSON(value?: RbacRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'accountId': value['accountId'],
        'isDefault': value['isDefault'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'rbacPolicies': value['rbacPolicies'] == null ? undefined : ((value['rbacPolicies'] as Array<any>).map(RbacPolicyToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface RbacRolePolicy
 */
export interface RbacRolePolicy {
    /**
     * 
     * @type {string}
     * @memberof RbacRolePolicy
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof RbacRolePolicy
     */
    'policyId': string;
}


/**
 * Check if a given object implements the RbacRolePolicy interface.
 */
export function instanceOfRbacRolePolicy(value: object): value is RbacRolePolicy {
    if (!('roleId' in value) || value['roleId'] === undefined) return false;
    if (!('policyId' in value) || value['policyId'] === undefined) return false;
    return true;
}

export function RbacRolePolicyFromJSON(json: any): RbacRolePolicy {
    return RbacRolePolicyFromJSONTyped(json, false);
}

export function RbacRolePolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacRolePolicy {
    if (json == null) {
        return json;
    }
    return {
        
        'roleId': json['roleId'],
        'policyId': json['policyId'],
    };
}

export function RbacRolePolicyToJSON(value?: RbacRolePolicy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'roleId': value['roleId'],
        'policyId': value['policyId'],
    };
}

        /**
 * 
 * @export
 * @interface RbacUserRole
 */
export interface RbacUserRole {
    /**
     * 
     * @type {string}
     * @memberof RbacUserRole
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof RbacUserRole
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof RbacUserRole
     */
    'expiration': string;
}


/**
 * Check if a given object implements the RbacUserRole interface.
 */
export function instanceOfRbacUserRole(value: object): value is RbacUserRole {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('roleId' in value) || value['roleId'] === undefined) return false;
    if (!('expiration' in value) || value['expiration'] === undefined) return false;
    return true;
}

export function RbacUserRoleFromJSON(json: any): RbacUserRole {
    return RbacUserRoleFromJSONTyped(json, false);
}

export function RbacUserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RbacUserRole {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'roleId': json['roleId'],
        'expiration': json['expiration'],
    };
}

export function RbacUserRoleToJSON(value?: RbacUserRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'roleId': value['roleId'],
        'expiration': value['expiration'],
    };
}

        /**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Role
     */
    'permissions': Array<string>;
}


/**
 * Check if a given object implements the Role interface.
 */
export function instanceOfRole(value: object): value is Role {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('permissions' in value) || value['permissions'] === undefined) return false;
    return true;
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'permissions': json['permissions'],
    };
}

export function RoleToJSON(value?: Role | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'permissions': value['permissions'],
    };
}

        /**
 * 
 * @export
 * @interface UpdateRbacCustomK8sActionPayload
 */
export interface UpdateRbacCustomK8sActionPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacCustomK8sActionPayload
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacCustomK8sActionPayload
     */
    'description'?: string;
    /**
     * 
     * @type {Array<KubernetesRbacPolicyRuleV1Inner>}
     * @memberof UpdateRbacCustomK8sActionPayload
     */
    'k8sRuleset'?: Array<KubernetesRbacPolicyRuleV1Inner>;
}


/**
 * Check if a given object implements the UpdateRbacCustomK8sActionPayload interface.
 */
export function instanceOfUpdateRbacCustomK8sActionPayload(value: object): value is UpdateRbacCustomK8sActionPayload {
    return true;
}

export function UpdateRbacCustomK8sActionPayloadFromJSON(json: any): UpdateRbacCustomK8sActionPayload {
    return UpdateRbacCustomK8sActionPayloadFromJSONTyped(json, false);
}

export function UpdateRbacCustomK8sActionPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRbacCustomK8sActionPayload {
    if (json == null) {
        return json;
    }
    return {
        
        'action': json['action'] == null ? undefined : json['action'],
        'description': json['description'] == null ? undefined : json['description'],
        'k8sRuleset': json['k8sRuleset'] == null ? undefined : ((json['k8sRuleset'] as Array<any>).map(KubernetesRbacPolicyRuleV1InnerFromJSON)),
    };
}

export function UpdateRbacCustomK8sActionPayloadToJSON(value?: UpdateRbacCustomK8sActionPayload | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'action': value['action'],
        'description': value['description'],
        'k8sRuleset': value['k8sRuleset'] == null ? undefined : ((value['k8sRuleset'] as Array<any>).map(KubernetesRbacPolicyRuleV1InnerToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface UpdateRbacPolicyRequest
 */
export interface UpdateRbacPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacPolicyRequest
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacPolicyRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<RbacPolicyStatementsInner>}
     * @memberof UpdateRbacPolicyRequest
     */
    'statements'?: Array<RbacPolicyStatementsInner>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateRbacPolicyRequest
     */
    'tags'?: { [key: string]: string; };
    /**
     * 
     * @type {RbacPolicyType}
     * @memberof UpdateRbacPolicyRequest
     */
    'type'?: RbacPolicyType;
}


/**
 * Check if a given object implements the UpdateRbacPolicyRequest interface.
 */
export function instanceOfUpdateRbacPolicyRequest(value: object): value is UpdateRbacPolicyRequest {
    return true;
}

export function UpdateRbacPolicyRequestFromJSON(json: any): UpdateRbacPolicyRequest {
    return UpdateRbacPolicyRequestFromJSONTyped(json, false);
}

export function UpdateRbacPolicyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRbacPolicyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'name': json['name'] == null ? undefined : json['name'],
        'statements': json['statements'] == null ? undefined : ((json['statements'] as Array<any>).map(RbacPolicyStatementsInnerFromJSON)),
        'tags': json['tags'] == null ? undefined : json['tags'],
        'type': json['type'] == null ? undefined : RbacPolicyTypeFromJSON(json['type']),
    };
}

export function UpdateRbacPolicyRequestToJSON(value?: UpdateRbacPolicyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'name': value['name'],
        'statements': value['statements'] == null ? undefined : ((value['statements'] as Array<any>).map(RbacPolicyStatementsInnerToJSON)),
        'tags': value['tags'],
        'type': RbacPolicyTypeToJSON(value['type']),
    };
}

        /**
 * 
 * @export
 * @interface UpdateRbacRoleRequest
 */
export interface UpdateRbacRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacRoleRequest
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacRoleRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRbacRoleRequest
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRbacRoleRequest
     */
    'rbacPolicyIds'?: Array<string>;
}


/**
 * Check if a given object implements the UpdateRbacRoleRequest interface.
 */
export function instanceOfUpdateRbacRoleRequest(value: object): value is UpdateRbacRoleRequest {
    return true;
}

export function UpdateRbacRoleRequestFromJSON(json: any): UpdateRbacRoleRequest {
    return UpdateRbacRoleRequestFromJSONTyped(json, false);
}

export function UpdateRbacRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRbacRoleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'name': json['name'] == null ? undefined : json['name'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
        'rbacPolicyIds': json['rbacPolicyIds'] == null ? undefined : json['rbacPolicyIds'],
    };
}

export function UpdateRbacRoleRequestToJSON(value?: UpdateRbacRoleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'name': value['name'],
        'isDefault': value['isDefault'],
        'rbacPolicyIds': value['rbacPolicyIds'],
    };
}

        /**
 * 
 * @export
 * @interface UpdateRbacUserRoleRequest
 */
export interface UpdateRbacUserRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacUserRoleRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacUserRoleRequest
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRbacUserRoleRequest
     */
    'expiration': string;
}


/**
 * Check if a given object implements the UpdateRbacUserRoleRequest interface.
 */
export function instanceOfUpdateRbacUserRoleRequest(value: object): value is UpdateRbacUserRoleRequest {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('roleId' in value) || value['roleId'] === undefined) return false;
    if (!('expiration' in value) || value['expiration'] === undefined) return false;
    return true;
}

export function UpdateRbacUserRoleRequestFromJSON(json: any): UpdateRbacUserRoleRequest {
    return UpdateRbacUserRoleRequestFromJSONTyped(json, false);
}

export function UpdateRbacUserRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRbacUserRoleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'roleId': json['roleId'],
        'expiration': json['expiration'],
    };
}

export function UpdateRbacUserRoleRequestToJSON(value?: UpdateRbacUserRoleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'roleId': value['roleId'],
        'expiration': value['expiration'],
    };
}

        /**
 * 
 * @export
 * @interface UpdateRoleRequest
 */
export interface UpdateRoleRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleRequest
     */
    'permissions': Array<string>;
}


/**
 * Check if a given object implements the UpdateRoleRequest interface.
 */
export function instanceOfUpdateRoleRequest(value: object): value is UpdateRoleRequest {
    if (!('permissions' in value) || value['permissions'] === undefined) return false;
    return true;
}

export function UpdateRoleRequestFromJSON(json: any): UpdateRoleRequest {
    return UpdateRoleRequestFromJSONTyped(json, false);
}

export function UpdateRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRoleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'permissions': json['permissions'],
    };
}

export function UpdateRoleRequestToJSON(value?: UpdateRoleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'permissions': value['permissions'],
    };
}

        /**
 * 
 * @export
 * @interface UpdateUserAccountRequest
 */
export interface UpdateUserAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserAccountRequest
     */
    'newAccountId': string;
}


/**
 * Check if a given object implements the UpdateUserAccountRequest interface.
 */
export function instanceOfUpdateUserAccountRequest(value: object): value is UpdateUserAccountRequest {
    if (!('newAccountId' in value) || value['newAccountId'] === undefined) return false;
    return true;
}

export function UpdateUserAccountRequestFromJSON(json: any): UpdateUserAccountRequest {
    return UpdateUserAccountRequestFromJSONTyped(json, false);
}

export function UpdateUserAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserAccountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'newAccountId': json['newAccountId'],
    };
}

export function UpdateUserAccountRequestToJSON(value?: UpdateUserAccountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newAccountId': value['newAccountId'],
    };
}

        /**
 * 
 * @export
 * @interface UpdateUserBulkRequest
 */
export interface UpdateUserBulkRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBulkRequest
     */
    'deletedAt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserBulkRequest
     */
    'excludedUserIds'?: Array<string>;
}


/**
 * Check if a given object implements the UpdateUserBulkRequest interface.
 */
export function instanceOfUpdateUserBulkRequest(value: object): value is UpdateUserBulkRequest {
    if (!('deletedAt' in value) || value['deletedAt'] === undefined) return false;
    return true;
}

export function UpdateUserBulkRequestFromJSON(json: any): UpdateUserBulkRequest {
    return UpdateUserBulkRequestFromJSONTyped(json, false);
}

export function UpdateUserBulkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserBulkRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'deletedAt': json['deletedAt'],
        'excludedUserIds': json['excludedUserIds'] == null ? undefined : json['excludedUserIds'],
    };
}

export function UpdateUserBulkRequestToJSON(value?: UpdateUserBulkRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deletedAt': value['deletedAt'],
        'excludedUserIds': value['excludedUserIds'],
    };
}

        /**
 * 
 * @export
 * @interface UpdateUserBulkResponse
 */
export interface UpdateUserBulkResponse {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserBulkResponse
     */
    'rowsAffected': number;
}


/**
 * Check if a given object implements the UpdateUserBulkResponse interface.
 */
export function instanceOfUpdateUserBulkResponse(value: object): value is UpdateUserBulkResponse {
    if (!('rowsAffected' in value) || value['rowsAffected'] === undefined) return false;
    return true;
}

export function UpdateUserBulkResponseFromJSON(json: any): UpdateUserBulkResponse {
    return UpdateUserBulkResponseFromJSONTyped(json, false);
}

export function UpdateUserBulkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserBulkResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'rowsAffected': json['rowsAffected'],
    };
}

export function UpdateUserBulkResponseToJSON(value?: UpdateUserBulkResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rowsAffected': value['rowsAffected'],
    };
}

        /**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'displayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'acceptedTos'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    'allowedAccounts'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'lastLoginAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    'rbacRoleIds'?: Array<string>;
}


/**
 * Check if a given object implements the UpdateUserRequest interface.
 */
export function instanceOfUpdateUserRequest(value: object): value is UpdateUserRequest {
    return true;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
    return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'isVerified': json['isVerified'] == null ? undefined : json['isVerified'],
        'acceptedTos': json['acceptedTos'] == null ? undefined : json['acceptedTos'],
        'allowedAccounts': json['allowedAccounts'] == null ? undefined : json['allowedAccounts'],
        'lastLoginAt': json['lastLoginAt'] == null ? undefined : json['lastLoginAt'],
        'deletedAt': json['deletedAt'] == null ? undefined : json['deletedAt'],
        'rbacRoleIds': json['rbacRoleIds'] == null ? undefined : json['rbacRoleIds'],
    };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'displayName': value['displayName'],
        'isVerified': value['isVerified'],
        'acceptedTos': value['acceptedTos'],
        'allowedAccounts': value['allowedAccounts'],
        'lastLoginAt': value['lastLoginAt'],
        'deletedAt': value['deletedAt'],
        'rbacRoleIds': value['rbacRoleIds'],
    };
}

        /**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isVerified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'acceptedTos': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'allowedAccounts'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLoginAt'?: string;
    /**
     * 
     * @type {Array<RbacRole>}
     * @memberof User
     */
    'rbacRoles'?: Array<RbacRole>;
    /**
     * 
     * @type {Array<RbacUserRole>}
     * @memberof User
     */
    'rbacUserRoles'?: Array<RbacUserRole>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isKomodorAdmin'?: boolean;
}


/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('isVerified' in value) || value['isVerified'] === undefined) return false;
    if (!('acceptedTos' in value) || value['acceptedTos'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'accountId': json['accountId'],
        'displayName': json['displayName'],
        'email': json['email'],
        'isVerified': json['isVerified'],
        'acceptedTos': json['acceptedTos'],
        'allowedAccounts': json['allowedAccounts'] == null ? undefined : json['allowedAccounts'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'deletedAt': json['deletedAt'] == null ? undefined : json['deletedAt'],
        'lastLoginAt': json['lastLoginAt'] == null ? undefined : json['lastLoginAt'],
        'rbacRoles': json['rbacRoles'] == null ? undefined : ((json['rbacRoles'] as Array<any>).map(RbacRoleFromJSON)),
        'rbacUserRoles': json['rbacUserRoles'] == null ? undefined : ((json['rbacUserRoles'] as Array<any>).map(RbacUserRoleFromJSON)),
        'isKomodorAdmin': json['isKomodorAdmin'] == null ? undefined : json['isKomodorAdmin'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'accountId': value['accountId'],
        'displayName': value['displayName'],
        'email': value['email'],
        'isVerified': value['isVerified'],
        'acceptedTos': value['acceptedTos'],
        'allowedAccounts': value['allowedAccounts'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'deletedAt': value['deletedAt'],
        'lastLoginAt': value['lastLoginAt'],
        'rbacRoles': value['rbacRoles'] == null ? undefined : ((value['rbacRoles'] as Array<any>).map(RbacRoleToJSON)),
        'rbacUserRoles': value['rbacUserRoles'] == null ? undefined : ((value['rbacUserRoles'] as Array<any>).map(RbacUserRoleToJSON)),
        'isKomodorAdmin': value['isKomodorAdmin'],
    };
}

        /**
 * 
 * @export
 * @interface ZendeskJWTResponse
 */
export interface ZendeskJWTResponse {
    /**
     * 
     * @type {string}
     * @memberof ZendeskJWTResponse
     */
    'data': string;
}


/**
 * Check if a given object implements the ZendeskJWTResponse interface.
 */
export function instanceOfZendeskJWTResponse(value: object): value is ZendeskJWTResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ZendeskJWTResponseFromJSON(json: any): ZendeskJWTResponse {
    return ZendeskJWTResponseFromJSONTyped(json, false);
}

export function ZendeskJWTResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskJWTResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

export function ZendeskJWTResponseToJSON(value?: ZendeskJWTResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
    };
}

    
        /**
* List API Keys
* @param params ApiKeysApiApiV1ApiKeysGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ApiKeysGetUrl = (params: ApiKeysApiApiV1ApiKeysGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/api-keys`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.id !== undefined) {
            localVarQueryParameter['id'] = params.id;
    }
    if (params.name !== undefined) {
            localVarQueryParameter['name'] = params.name;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.key !== undefined) {
            localVarQueryParameter['key'] = params.key;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Delete API Key by ID
* @param params ApiKeysApiApiV1ApiKeysIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ApiKeysIdDeleteUrl = (params: ApiKeysApiApiV1ApiKeysIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ApiKeysIdDelete', 'id', params.id)
    const localVarPath = `/api/v1/api-keys/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get API Key by ID
* @param params ApiKeysApiApiV1ApiKeysIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ApiKeysIdGetUrl = (params: ApiKeysApiApiV1ApiKeysIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ApiKeysIdGet', 'id', params.id)
    const localVarPath = `/api/v1/api-keys/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Set API Key to never expire by ID
* @param params ApiKeysApiApiV1ApiKeysIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ApiKeysIdPutUrl = (params: ApiKeysApiApiV1ApiKeysIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ApiKeysIdPut', 'id', params.id)
    const localVarPath = `/api/v1/api-keys/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Create API Key
* @param params ApiKeysApiApiV1ApiKeysPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ApiKeysPostUrl = (params: ApiKeysApiApiV1ApiKeysPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/api-keys`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ApiKeysGet operation in ApiKeysApi.
* @export
* @interface ApiKeysApiApiV1ApiKeysGetRequest
*/
export interface ApiKeysApiApiV1ApiKeysGetRequest {
    /**
    * 
    * @type {string}
    * @memberof ApiKeysApiApiV1ApiKeysGet
    */
    readonly id?: string

    /**
    * 
    * @type {string}
    * @memberof ApiKeysApiApiV1ApiKeysGet
    */
    readonly name?: string

    /**
    * 
    * @type {number}
    * @memberof ApiKeysApiApiV1ApiKeysGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {'ASC' | 'DESC'}
    * @memberof ApiKeysApiApiV1ApiKeysGet
    */
    readonly sort?: 'ASC' | 'DESC'

    /**
    * 
    * @type {string}
    * @memberof ApiKeysApiApiV1ApiKeysGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {string}
    * @memberof ApiKeysApiApiV1ApiKeysGet
    */
    readonly userId?: string

    /**
    * 
    * @type {string}
    * @memberof ApiKeysApiApiV1ApiKeysGet
    */
    readonly key?: string
}

/**
* Request parameters for apiV1ApiKeysIdDelete operation in ApiKeysApi.
* @export
* @interface ApiKeysApiApiV1ApiKeysIdDeleteRequest
*/
export interface ApiKeysApiApiV1ApiKeysIdDeleteRequest {
    /**
    * 
    * @type {string}
    * @memberof ApiKeysApiApiV1ApiKeysIdDelete
    */
    readonly id: string
}

/**
* Request parameters for apiV1ApiKeysIdGet operation in ApiKeysApi.
* @export
* @interface ApiKeysApiApiV1ApiKeysIdGetRequest
*/
export interface ApiKeysApiApiV1ApiKeysIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof ApiKeysApiApiV1ApiKeysIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1ApiKeysIdPut operation in ApiKeysApi.
* @export
* @interface ApiKeysApiApiV1ApiKeysIdPutRequest
*/
export interface ApiKeysApiApiV1ApiKeysIdPutRequest {
    /**
    * 
    * @type {string}
    * @memberof ApiKeysApiApiV1ApiKeysIdPut
    */
    readonly id: string
}

/**
* Request parameters for apiV1ApiKeysPost operation in ApiKeysApi.
* @export
* @interface ApiKeysApiApiV1ApiKeysPostRequest
*/
export interface ApiKeysApiApiV1ApiKeysPostRequest {
    /**
    * 
    * @type {CreateAPIKeyRequest}
    * @memberof ApiKeysApiApiV1ApiKeysPost
    */
    readonly createAPIKeyRequest?: CreateAPIKeyRequest
}


    
        /**
* List API Resources
* @param params ApiResourcesApiApiV1ApiResourcesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ApiResourcesGetUrl = (params: ApiResourcesApiApiV1ApiResourcesGetRequest, baseUrl: string): string => {
    // verify required parameter 'cluster' is not null or undefined
    assertParamExists('apiV1ApiResourcesGet', 'cluster', params.cluster)
    const localVarPath = `/api/v1/api-resources`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.cluster !== undefined) {
            localVarQueryParameter['cluster'] = params.cluster;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ApiResourcesGet operation in ApiResourcesApi.
* @export
* @interface ApiResourcesApiApiV1ApiResourcesGetRequest
*/
export interface ApiResourcesApiApiV1ApiResourcesGetRequest {
    /**
    * 
    * @type {string}
    * @memberof ApiResourcesApiApiV1ApiResourcesGet
    */
    readonly cluster: string
}


    
        /**
* Check if the user can perform an action in a specific scope
* @param params AuthApiApiV1AuthCanIDoInPartialScopePostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AuthCanIDoInPartialScopePostUrl = (params: AuthApiApiV1AuthCanIDoInPartialScopePostRequest, baseUrl: string): string => {
    // verify required parameter 'apiV1AuthCanIDoInScopePostRequest' is not null or undefined
    assertParamExists('apiV1AuthCanIDoInPartialScopePost', 'apiV1AuthCanIDoInScopePostRequest', params.apiV1AuthCanIDoInScopePostRequest)
    const localVarPath = `/api/v1/auth/can-i-do-in-partial-scope`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Check if the user can perform an action in a specific scope
* @param params AuthApiApiV1AuthCanIDoInScopePostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AuthCanIDoInScopePostUrl = (params: AuthApiApiV1AuthCanIDoInScopePostRequest, baseUrl: string): string => {
    // verify required parameter 'apiV1AuthCanIDoInScopePostRequest' is not null or undefined
    assertParamExists('apiV1AuthCanIDoInScopePost', 'apiV1AuthCanIDoInScopePostRequest', params.apiV1AuthCanIDoInScopePostRequest)
    const localVarPath = `/api/v1/auth/can-i-do-in-scope`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Authorization call for internal services
* @param params AuthApiApiV1AuthInternalAuthPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AuthInternalAuthPostUrl = (params: AuthApiApiV1AuthInternalAuthPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/auth-internal/auth`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Auth Webhook for Hasura
* @param params AuthApiApiV1HasuraAuthGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1HasuraAuthGetUrl = (params: AuthApiApiV1HasuraAuthGetRequest, baseUrl: string): string => {
    // verify required parameter 'authorization' is not null or undefined
    assertParamExists('apiV1HasuraAuthGet', 'authorization', params.authorization)
    const localVarPath = `/api/v1/hasura/auth`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1AuthCanIDoInPartialScopePost operation in AuthApi.
* @export
* @interface AuthApiApiV1AuthCanIDoInPartialScopePostRequest
*/
export interface AuthApiApiV1AuthCanIDoInPartialScopePostRequest {
    /**
    * 
    * @type {ApiV1AuthCanIDoInScopePostRequest}
    * @memberof AuthApiApiV1AuthCanIDoInPartialScopePost
    */
    readonly apiV1AuthCanIDoInScopePostRequest: ApiV1AuthCanIDoInScopePostRequest
}

/**
* Request parameters for apiV1AuthCanIDoInScopePost operation in AuthApi.
* @export
* @interface AuthApiApiV1AuthCanIDoInScopePostRequest
*/
export interface AuthApiApiV1AuthCanIDoInScopePostRequest {
    /**
    * 
    * @type {ApiV1AuthCanIDoInScopePostRequest}
    * @memberof AuthApiApiV1AuthCanIDoInScopePost
    */
    readonly apiV1AuthCanIDoInScopePostRequest: ApiV1AuthCanIDoInScopePostRequest
}

/**
* Request parameters for apiV1AuthInternalAuthPost operation in AuthApi.
* @export
* @interface AuthApiApiV1AuthInternalAuthPostRequest
*/
export interface AuthApiApiV1AuthInternalAuthPostRequest {
    /**
    * JWT token
    * @type {string}
    * @memberof AuthApiApiV1AuthInternalAuthPost
    */
    readonly authorization?: string

    /**
    * public API key
    * @type {string}
    * @memberof AuthApiApiV1AuthInternalAuthPost
    */
    readonly xApiKey?: string

    /**
    * user id which we impersonated to. It must be provided alongside to komodor admin api key - required with X-ACCOUNT-ID
    * @type {string}
    * @memberof AuthApiApiV1AuthInternalAuthPost
    */
    readonly xImpersonatedUserId?: string
}

/**
* Request parameters for apiV1HasuraAuthGet operation in AuthApi.
* @export
* @interface AuthApiApiV1HasuraAuthGetRequest
*/
export interface AuthApiApiV1HasuraAuthGetRequest {
    /**
    * JWT token
    * @type {string}
    * @memberof AuthApiApiV1HasuraAuthGet
    */
    readonly authorization: string
}


    
        /**
* Auth endpoint for Zendesk
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ZendeskAuthGetUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/zendesk/auth`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};


    
        /**
* Generate kubeconfigs for list of clusters
* @param params KubeconfigsApiApiV1KubeconfigsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KubeconfigsPostUrl = (params: KubeconfigsApiApiV1KubeconfigsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/kubeconfigs`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Generate Helm chart with RBAC for cluster
* @param params KubeconfigsApiApiV1RbacChartAccountIdClusterGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacChartAccountIdClusterGetUrl = (params: KubeconfigsApiApiV1RbacChartAccountIdClusterGetRequest, baseUrl: string): string => {
    // verify required parameter 'accountId' is not null or undefined
    assertParamExists('apiV1RbacChartAccountIdClusterGet', 'accountId', params.accountId)
    // verify required parameter 'cluster' is not null or undefined
    assertParamExists('apiV1RbacChartAccountIdClusterGet', 'cluster', params.cluster)
    const localVarPath = `/api/v1/rbac-chart/{accountId}/{cluster}`
        .replace(`{${"accountId"}}`, encodeURIComponent(String(params.accountId)))
        .replace(`{${"cluster"}}`, encodeURIComponent(String(params.cluster)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1KubeconfigsPost operation in KubeconfigsApi.
* @export
* @interface KubeconfigsApiApiV1KubeconfigsPostRequest
*/
export interface KubeconfigsApiApiV1KubeconfigsPostRequest {
    /**
    * 
    * @type {string}
    * @memberof KubeconfigsApiApiV1KubeconfigsPost
    */
    readonly accountId?: string

    /**
    * 
    * @type {string}
    * @memberof KubeconfigsApiApiV1KubeconfigsPost
    */
    readonly userId?: string

    /**
    * 
    * @type {ClusterList}
    * @memberof KubeconfigsApiApiV1KubeconfigsPost
    */
    readonly clusterList?: ClusterList
}

/**
* Request parameters for apiV1RbacChartAccountIdClusterGet operation in KubeconfigsApi.
* @export
* @interface KubeconfigsApiApiV1RbacChartAccountIdClusterGetRequest
*/
export interface KubeconfigsApiApiV1RbacChartAccountIdClusterGetRequest {
    /**
    * 
    * @type {string}
    * @memberof KubeconfigsApiApiV1RbacChartAccountIdClusterGet
    */
    readonly accountId: string

    /**
    * 
    * @type {string}
    * @memberof KubeconfigsApiApiV1RbacChartAccountIdClusterGet
    */
    readonly cluster: string
}


    
        /**
* Schedule cleanup of RBAC resources from all clusters
* @param params RbacClusterStatusApiApiV1RbacClusterStatusCleanupPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacClusterStatusCleanupPutUrl = (params: RbacClusterStatusApiApiV1RbacClusterStatusCleanupPutRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-cluster-status/cleanup`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Delete RBAC Cluster Status
* @param params RbacClusterStatusApiApiV1RbacClusterStatusDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacClusterStatusDeleteUrl = (params: RbacClusterStatusApiApiV1RbacClusterStatusDeleteRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-cluster-status`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get RBAC Cluster Statuses
* @param params RbacClusterStatusApiApiV1RbacClusterStatusGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacClusterStatusGetUrl = (params: RbacClusterStatusApiApiV1RbacClusterStatusGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-cluster-status`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.clusterName) {
        localVarQueryParameter['clusterName'] = params.clusterName.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Update RBAC Cluster ApiServer URL
* @param params RbacClusterStatusApiApiV1RbacClusterStatusPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacClusterStatusPostUrl = (params: RbacClusterStatusApiApiV1RbacClusterStatusPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-cluster-status`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Schedule RBAC Cluster Status Sync
* @param params RbacClusterStatusApiApiV1RbacClusterStatusScheduleSyncPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacClusterStatusScheduleSyncPostUrl = (params: RbacClusterStatusApiApiV1RbacClusterStatusScheduleSyncPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-cluster-status/schedule-sync`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1RbacClusterStatusCleanupPut operation in RbacClusterStatusApi.
* @export
* @interface RbacClusterStatusApiApiV1RbacClusterStatusCleanupPutRequest
*/
export interface RbacClusterStatusApiApiV1RbacClusterStatusCleanupPutRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacClusterStatusApiApiV1RbacClusterStatusCleanupPut
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1RbacClusterStatusDelete operation in RbacClusterStatusApi.
* @export
* @interface RbacClusterStatusApiApiV1RbacClusterStatusDeleteRequest
*/
export interface RbacClusterStatusApiApiV1RbacClusterStatusDeleteRequest {
    /**
    * 
    * @type {RbacClusterStatusDeleteRequest}
    * @memberof RbacClusterStatusApiApiV1RbacClusterStatusDelete
    */
    readonly rbacClusterStatusDeleteRequest?: RbacClusterStatusDeleteRequest
}

/**
* Request parameters for apiV1RbacClusterStatusGet operation in RbacClusterStatusApi.
* @export
* @interface RbacClusterStatusApiApiV1RbacClusterStatusGetRequest
*/
export interface RbacClusterStatusApiApiV1RbacClusterStatusGetRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacClusterStatusApiApiV1RbacClusterStatusGet
    */
    readonly accountId?: string

    /**
    * Cluster name to filter on
    * @type {Array<string>}
    * @memberof RbacClusterStatusApiApiV1RbacClusterStatusGet
    */
    readonly clusterName?: Array<string>
}

/**
* Request parameters for apiV1RbacClusterStatusPost operation in RbacClusterStatusApi.
* @export
* @interface RbacClusterStatusApiApiV1RbacClusterStatusPostRequest
*/
export interface RbacClusterStatusApiApiV1RbacClusterStatusPostRequest {
    /**
    * 
    * @type {ApiServerUrlUpdateRequest}
    * @memberof RbacClusterStatusApiApiV1RbacClusterStatusPost
    */
    readonly apiServerUrlUpdateRequest?: ApiServerUrlUpdateRequest
}

/**
* Request parameters for apiV1RbacClusterStatusScheduleSyncPost operation in RbacClusterStatusApi.
* @export
* @interface RbacClusterStatusApiApiV1RbacClusterStatusScheduleSyncPostRequest
*/
export interface RbacClusterStatusApiApiV1RbacClusterStatusScheduleSyncPostRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacClusterStatusApiApiV1RbacClusterStatusScheduleSyncPost
    */
    readonly accountId?: string

    /**
    * 
    * @type {RbacClusterScheduleSyncRequest}
    * @memberof RbacClusterStatusApiApiV1RbacClusterStatusScheduleSyncPost
    */
    readonly rbacClusterScheduleSyncRequest?: RbacClusterScheduleSyncRequest
}


    
        /**
* Get which policies are using this custom action
* @param params RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacCustomK8sActionsActionRbacPoliciesGetUrl = (params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGetRequest, baseUrl: string): string => {
    // verify required parameter 'action' is not null or undefined
    assertParamExists('apiV1RbacCustomK8sActionsActionRbacPoliciesGet', 'action', params.action)
    const localVarPath = `/api/v1/rbac-custom-k8s-actions/{action}/rbac-policies`
        .replace(`{${"action"}}`, encodeURIComponent(String(params.action)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get RBAC Custom K8s Actions
* @param params RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacCustomK8sActionsGetUrl = (params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-custom-k8s-actions`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.actions) {
        localVarQueryParameter['actions'] = params.actions.join(COLLECTION_FORMATS.csv);
    }
    if (params.scopingMethods) {
        localVarQueryParameter['scopingMethods'] = params.scopingMethods.join(COLLECTION_FORMATS.csv);
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.offset !== undefined) {
            localVarQueryParameter['offset'] = params.offset;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Delete RBAC Custom K8s Actions
* @param params RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacCustomK8sActionsIdDeleteUrl = (params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RbacCustomK8sActionsIdDelete', 'id', params.id)
    const localVarPath = `/api/v1/rbac-custom-k8s-actions/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Update RBAC Custom K8s Actions
* @param params RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacCustomK8sActionsIdPutUrl = (params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RbacCustomK8sActionsIdPut', 'id', params.id)
    // verify required parameter 'updateRbacCustomK8sActionPayload' is not null or undefined
    assertParamExists('apiV1RbacCustomK8sActionsIdPut', 'updateRbacCustomK8sActionPayload', params.updateRbacCustomK8sActionPayload)
    const localVarPath = `/api/v1/rbac-custom-k8s-actions/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Create RBAC Custom K8s Action
* @param params RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacCustomK8sActionsPostUrl = (params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsPostRequest, baseUrl: string): string => {
    // verify required parameter 'createRbacCustomK8sActionPayload' is not null or undefined
    assertParamExists('apiV1RbacCustomK8sActionsPost', 'createRbacCustomK8sActionPayload', params.createRbacCustomK8sActionPayload)
    const localVarPath = `/api/v1/rbac-custom-k8s-actions`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1RbacCustomK8sActionsActionRbacPoliciesGet operation in RbacCustomK8sActionsApi.
* @export
* @interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGetRequest
*/
export interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGetRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGet
    */
    readonly action: string

    /**
    * 
    * @type {string}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1RbacCustomK8sActionsGet operation in RbacCustomK8sActionsApi.
* @export
* @interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGetRequest
*/
export interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGet
    */
    readonly actions?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGet
    */
    readonly scopingMethods?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {number}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGet
    */
    readonly limit?: number

    /**
    * 
    * @type {number}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGet
    */
    readonly offset?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {'ASC' | 'DESC'}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGet
    */
    readonly sort?: 'ASC' | 'DESC'
}

/**
* Request parameters for apiV1RbacCustomK8sActionsIdDelete operation in RbacCustomK8sActionsApi.
* @export
* @interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDeleteRequest
*/
export interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDeleteRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDelete
    */
    readonly id: string

    /**
    * 
    * @type {string}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDelete
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1RbacCustomK8sActionsIdPut operation in RbacCustomK8sActionsApi.
* @export
* @interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPutRequest
*/
export interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPutRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPut
    */
    readonly id: string

    /**
    * 
    * @type {UpdateRbacCustomK8sActionPayload}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPut
    */
    readonly updateRbacCustomK8sActionPayload: UpdateRbacCustomK8sActionPayload

    /**
    * 
    * @type {string}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPut
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1RbacCustomK8sActionsPost operation in RbacCustomK8sActionsApi.
* @export
* @interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsPostRequest
*/
export interface RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsPostRequest {
    /**
    * 
    * @type {CreateRbacCustomK8sActionPayload}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsPost
    */
    readonly createRbacCustomK8sActionPayload: CreateRbacCustomK8sActionPayload

    /**
    * 
    * @type {string}
    * @memberof RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsPost
    */
    readonly accountId?: string
}


    
        /**
* rbac policies aggregations by type.
* @param params RbacPoliciesApiApiV1RbacPoliciesAggregationsTypeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPoliciesAggregationsTypeGetUrl = (params: RbacPoliciesApiApiV1RbacPoliciesAggregationsTypeGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1RbacPoliciesAggregationsTypeGet', 'type', params.type)
    const localVarPath = `/api/v1/rbac-policies/aggregations/{type}`
        .replace(`{${"type"}}`, encodeURIComponent(String(params.type)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* List RBAC Policies
* @param params RbacPoliciesApiApiV1RbacPoliciesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPoliciesGetUrl = (params: RbacPoliciesApiApiV1RbacPoliciesGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-policies`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.id !== undefined) {
            localVarQueryParameter['id'] = params.id;
    }
    if (params.name !== undefined) {
            localVarQueryParameter['name'] = params.name;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.role) {
        localVarQueryParameter['role'] = params.role;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Deletes a RBAC Policy by ID
* @param params RbacPoliciesApiApiV1RbacPoliciesIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPoliciesIdDeleteUrl = (params: RbacPoliciesApiApiV1RbacPoliciesIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RbacPoliciesIdDelete', 'id', params.id)
    const localVarPath = `/api/v1/rbac-policies/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get a RBAC Policy by ID
* @param params RbacPoliciesApiApiV1RbacPoliciesIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPoliciesIdGetUrl = (params: RbacPoliciesApiApiV1RbacPoliciesIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RbacPoliciesIdGet', 'id', params.id)
    const localVarPath = `/api/v1/rbac-policies/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update a RBAC Policy by ID
* @param params RbacPoliciesApiApiV1RbacPoliciesIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPoliciesIdPutUrl = (params: RbacPoliciesApiApiV1RbacPoliciesIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RbacPoliciesIdPut', 'id', params.id)
    const localVarPath = `/api/v1/rbac-policies/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Create a RBAC Policy
* @param params RbacPoliciesApiApiV1RbacPoliciesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPoliciesPostUrl = (params: RbacPoliciesApiApiV1RbacPoliciesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-policies`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Sync RBAC Policies resources statement by tags (adds/removes Cluster permission)
* @param params RbacPoliciesApiApiV1RbacPoliciesSyncByTagsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPoliciesSyncByTagsPostUrl = (params: RbacPoliciesApiApiV1RbacPoliciesSyncByTagsPostRequest, baseUrl: string): string => {
    // verify required parameter 'accountId' is not null or undefined
    assertParamExists('apiV1RbacPoliciesSyncByTagsPost', 'accountId', params.accountId)
    // verify required parameter 'rbacPolicySyncByTagsRequest' is not null or undefined
    assertParamExists('apiV1RbacPoliciesSyncByTagsPost', 'rbacPolicySyncByTagsRequest', params.rbacPolicySyncByTagsRequest)
    const localVarPath = `/api/v1/rbac-policies/sync-by-tags`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Sync RBAC Policies resources statement by wildcards (adds/removes Cluster permission)
* @param params RbacPoliciesApiApiV1RbacPoliciesSyncWildcardPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPoliciesSyncWildcardPostUrl = (params: RbacPoliciesApiApiV1RbacPoliciesSyncWildcardPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-policies/sync-wildcard`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1RbacPoliciesAggregationsTypeGet operation in RbacPoliciesApi.
* @export
* @interface RbacPoliciesApiApiV1RbacPoliciesAggregationsTypeGetRequest
*/
export interface RbacPoliciesApiApiV1RbacPoliciesAggregationsTypeGetRequest {
    /**
    * 
    * @type {'statement'}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesAggregationsTypeGet
    */
    readonly type: 'statement'

    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesAggregationsTypeGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1RbacPoliciesGet operation in RbacPoliciesApi.
* @export
* @interface RbacPoliciesApiApiV1RbacPoliciesGetRequest
*/
export interface RbacPoliciesApiApiV1RbacPoliciesGetRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesGet
    */
    readonly id?: string

    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesGet
    */
    readonly name?: string

    /**
    * 
    * @type {number}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {'ASC' | 'DESC'}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesGet
    */
    readonly sort?: 'ASC' | 'DESC'

    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesGet
    */
    readonly userId?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesGet
    */
    readonly role?: Array<string>
}

/**
* Request parameters for apiV1RbacPoliciesIdDelete operation in RbacPoliciesApi.
* @export
* @interface RbacPoliciesApiApiV1RbacPoliciesIdDeleteRequest
*/
export interface RbacPoliciesApiApiV1RbacPoliciesIdDeleteRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesIdDelete
    */
    readonly id: string

    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesIdDelete
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1RbacPoliciesIdGet operation in RbacPoliciesApi.
* @export
* @interface RbacPoliciesApiApiV1RbacPoliciesIdGetRequest
*/
export interface RbacPoliciesApiApiV1RbacPoliciesIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1RbacPoliciesIdPut operation in RbacPoliciesApi.
* @export
* @interface RbacPoliciesApiApiV1RbacPoliciesIdPutRequest
*/
export interface RbacPoliciesApiApiV1RbacPoliciesIdPutRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesIdPut
    */
    readonly id: string

    /**
    * 
    * @type {UpdateRbacPolicyRequest}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesIdPut
    */
    readonly updateRbacPolicyRequest?: UpdateRbacPolicyRequest
}

/**
* Request parameters for apiV1RbacPoliciesPost operation in RbacPoliciesApi.
* @export
* @interface RbacPoliciesApiApiV1RbacPoliciesPostRequest
*/
export interface RbacPoliciesApiApiV1RbacPoliciesPostRequest {
    /**
    * 
    * @type {CreateRbacPolicyRequest}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesPost
    */
    readonly createRbacPolicyRequest?: CreateRbacPolicyRequest
}

/**
* Request parameters for apiV1RbacPoliciesSyncByTagsPost operation in RbacPoliciesApi.
* @export
* @interface RbacPoliciesApiApiV1RbacPoliciesSyncByTagsPostRequest
*/
export interface RbacPoliciesApiApiV1RbacPoliciesSyncByTagsPostRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesSyncByTagsPost
    */
    readonly accountId: string

    /**
    * 
    * @type {RbacPolicySyncByTagsRequest}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesSyncByTagsPost
    */
    readonly rbacPolicySyncByTagsRequest: RbacPolicySyncByTagsRequest
}

/**
* Request parameters for apiV1RbacPoliciesSyncWildcardPost operation in RbacPoliciesApi.
* @export
* @interface RbacPoliciesApiApiV1RbacPoliciesSyncWildcardPostRequest
*/
export interface RbacPoliciesApiApiV1RbacPoliciesSyncWildcardPostRequest {
    /**
    * 
    * @type {RbacPolicySyncByWildcardRequest}
    * @memberof RbacPoliciesApiApiV1RbacPoliciesSyncWildcardPost
    */
    readonly rbacPolicySyncByWildcardRequest?: RbacPolicySyncByWildcardRequest
}


    
        /**
* Get RBAC Policy Actions
* @param params RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacPolicyActionsGetUrl = (params: RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-policy-actions`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.actions) {
        localVarQueryParameter['actions'] = params.actions.join(COLLECTION_FORMATS.csv);
    }
    if (params.type !== undefined) {
            localVarQueryParameter['type'] = params.type;
    }
    if (params.scopingMethods) {
        localVarQueryParameter['scopingMethods'] = params.scopingMethods.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1RbacPolicyActionsGet operation in RbacPolicyActionsApi.
* @export
* @interface RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest
*/
export interface RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof RbacPolicyActionsApiApiV1RbacPolicyActionsGet
    */
    readonly actions?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof RbacPolicyActionsApiApiV1RbacPolicyActionsGet
    */
    readonly type?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof RbacPolicyActionsApiApiV1RbacPolicyActionsGet
    */
    readonly scopingMethods?: Array<string>
}


    
        /**
* Deletes a RBAC Policy
* @param params RbacRolePoliciesApiApiV1RbacRolePoliciesDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacRolePoliciesDeleteUrl = (params: RbacRolePoliciesApiApiV1RbacRolePoliciesDeleteRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-role-policies`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* List RBAC Role Policies
* @param params RbacRolePoliciesApiApiV1RbacRolePoliciesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacRolePoliciesGetUrl = (params: RbacRolePoliciesApiApiV1RbacRolePoliciesGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-role-policies`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.roleId !== undefined) {
            localVarQueryParameter['roleId'] = params.roleId;
    }
    if (params.policyId !== undefined) {
            localVarQueryParameter['policyId'] = params.policyId;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Create a RBAC Role Policy
* @param params RbacRolePoliciesApiApiV1RbacRolePoliciesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacRolePoliciesPostUrl = (params: RbacRolePoliciesApiApiV1RbacRolePoliciesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-role-policies`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1RbacRolePoliciesDelete operation in RbacRolePoliciesApi.
* @export
* @interface RbacRolePoliciesApiApiV1RbacRolePoliciesDeleteRequest
*/
export interface RbacRolePoliciesApiApiV1RbacRolePoliciesDeleteRequest {
    /**
    * 
    * @type {DeleteRbacRolePolicyRequest}
    * @memberof RbacRolePoliciesApiApiV1RbacRolePoliciesDelete
    */
    readonly deleteRbacRolePolicyRequest?: DeleteRbacRolePolicyRequest
}

/**
* Request parameters for apiV1RbacRolePoliciesGet operation in RbacRolePoliciesApi.
* @export
* @interface RbacRolePoliciesApiApiV1RbacRolePoliciesGetRequest
*/
export interface RbacRolePoliciesApiApiV1RbacRolePoliciesGetRequest {
    /**
    * Role ID
    * @type {string}
    * @memberof RbacRolePoliciesApiApiV1RbacRolePoliciesGet
    */
    readonly roleId?: string

    /**
    * Policy ID
    * @type {string}
    * @memberof RbacRolePoliciesApiApiV1RbacRolePoliciesGet
    */
    readonly policyId?: string

    /**
    * 
    * @type {number}
    * @memberof RbacRolePoliciesApiApiV1RbacRolePoliciesGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {'ASC' | 'DESC'}
    * @memberof RbacRolePoliciesApiApiV1RbacRolePoliciesGet
    */
    readonly sort?: 'ASC' | 'DESC'
}

/**
* Request parameters for apiV1RbacRolePoliciesPost operation in RbacRolePoliciesApi.
* @export
* @interface RbacRolePoliciesApiApiV1RbacRolePoliciesPostRequest
*/
export interface RbacRolePoliciesApiApiV1RbacRolePoliciesPostRequest {
    /**
    * 
    * @type {CreateRbacRolePolicyRequest}
    * @memberof RbacRolePoliciesApiApiV1RbacRolePoliciesPost
    */
    readonly createRbacRolePolicyRequest?: CreateRbacRolePolicyRequest
}


    
        /**
* List RBAC Roles
* @param params RbacRolesApiApiV1RbacRolesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacRolesGetUrl = (params: RbacRolesApiApiV1RbacRolesGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-roles`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.id !== undefined) {
            localVarQueryParameter['id'] = params.id;
    }
    if (params.name !== undefined) {
            localVarQueryParameter['name'] = params.name;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.isDefault !== undefined) {
            localVarQueryParameter['isDefault'] = params.isDefault;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Deletes a RBAC Role by ID
* @param params RbacRolesApiApiV1RbacRolesIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacRolesIdDeleteUrl = (params: RbacRolesApiApiV1RbacRolesIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RbacRolesIdDelete', 'id', params.id)
    const localVarPath = `/api/v1/rbac-roles/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get a RBAC Role by ID
* @param params RbacRolesApiApiV1RbacRolesIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacRolesIdGetUrl = (params: RbacRolesApiApiV1RbacRolesIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RbacRolesIdGet', 'id', params.id)
    const localVarPath = `/api/v1/rbac-roles/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update a RBAC Role by ID
* @param params RbacRolesApiApiV1RbacRolesIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacRolesIdPutUrl = (params: RbacRolesApiApiV1RbacRolesIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RbacRolesIdPut', 'id', params.id)
    const localVarPath = `/api/v1/rbac-roles/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Create a Role
* @param params RbacRolesApiApiV1RbacRolesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacRolesPostUrl = (params: RbacRolesApiApiV1RbacRolesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-roles`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1RbacRolesGet operation in RbacRolesApi.
* @export
* @interface RbacRolesApiApiV1RbacRolesGetRequest
*/
export interface RbacRolesApiApiV1RbacRolesGetRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacRolesApiApiV1RbacRolesGet
    */
    readonly id?: string

    /**
    * 
    * @type {string}
    * @memberof RbacRolesApiApiV1RbacRolesGet
    */
    readonly name?: string

    /**
    * 
    * @type {number}
    * @memberof RbacRolesApiApiV1RbacRolesGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {'ASC' | 'DESC'}
    * @memberof RbacRolesApiApiV1RbacRolesGet
    */
    readonly sort?: 'ASC' | 'DESC'

    /**
    * 
    * @type {string}
    * @memberof RbacRolesApiApiV1RbacRolesGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {boolean}
    * @memberof RbacRolesApiApiV1RbacRolesGet
    */
    readonly isDefault?: boolean
}

/**
* Request parameters for apiV1RbacRolesIdDelete operation in RbacRolesApi.
* @export
* @interface RbacRolesApiApiV1RbacRolesIdDeleteRequest
*/
export interface RbacRolesApiApiV1RbacRolesIdDeleteRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacRolesApiApiV1RbacRolesIdDelete
    */
    readonly id: string
}

/**
* Request parameters for apiV1RbacRolesIdGet operation in RbacRolesApi.
* @export
* @interface RbacRolesApiApiV1RbacRolesIdGetRequest
*/
export interface RbacRolesApiApiV1RbacRolesIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacRolesApiApiV1RbacRolesIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1RbacRolesIdPut operation in RbacRolesApi.
* @export
* @interface RbacRolesApiApiV1RbacRolesIdPutRequest
*/
export interface RbacRolesApiApiV1RbacRolesIdPutRequest {
    /**
    * 
    * @type {string}
    * @memberof RbacRolesApiApiV1RbacRolesIdPut
    */
    readonly id: string

    /**
    * 
    * @type {UpdateRbacRoleRequest}
    * @memberof RbacRolesApiApiV1RbacRolesIdPut
    */
    readonly updateRbacRoleRequest?: UpdateRbacRoleRequest
}

/**
* Request parameters for apiV1RbacRolesPost operation in RbacRolesApi.
* @export
* @interface RbacRolesApiApiV1RbacRolesPostRequest
*/
export interface RbacRolesApiApiV1RbacRolesPostRequest {
    /**
    * 
    * @type {CreateRbacRoleRequest}
    * @memberof RbacRolesApiApiV1RbacRolesPost
    */
    readonly createRbacRoleRequest?: CreateRbacRoleRequest
}


    
        /**
* List rbac user role relations
* @param params RbacUserRolesApiApiV1RbacUserRolesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacUserRolesGetUrl = (params: RbacUserRolesApiApiV1RbacUserRolesGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-user-roles`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.roleId !== undefined) {
            localVarQueryParameter['roleId'] = params.roleId;
    }
    if (params.includeExpiration !== undefined) {
            localVarQueryParameter['includeExpiration'] = params.includeExpiration;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Create a RBAC User Role relation
* @param params RbacUserRolesApiApiV1RbacUserRolesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacUserRolesPostUrl = (params: RbacUserRolesApiApiV1RbacUserRolesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-user-roles`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update a RBAC User Role expiration date
* @param params RbacUserRolesApiApiV1RbacUserRolesPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacUserRolesPutUrl = (params: RbacUserRolesApiApiV1RbacUserRolesPutRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/rbac-user-roles`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Deletes a RBAC user Role by userID & roleID
* @param params RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RbacUserRolesRoleIdUserIdDeleteUrl = (params: RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'roleId' is not null or undefined
    assertParamExists('apiV1RbacUserRolesRoleIdUserIdDelete', 'roleId', params.roleId)
    // verify required parameter 'userId' is not null or undefined
    assertParamExists('apiV1RbacUserRolesRoleIdUserIdDelete', 'userId', params.userId)
    const localVarPath = `/api/v1/rbac-user-roles/{roleId}/{userId}`
        .replace(`{${"roleId"}}`, encodeURIComponent(String(params.roleId)))
        .replace(`{${"userId"}}`, encodeURIComponent(String(params.userId)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1RbacUserRolesGet operation in RbacUserRolesApi.
* @export
* @interface RbacUserRolesApiApiV1RbacUserRolesGetRequest
*/
export interface RbacUserRolesApiApiV1RbacUserRolesGetRequest {
    /**
    * User ID
    * @type {string}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesGet
    */
    readonly userId?: string

    /**
    * Role ID
    * @type {string}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesGet
    */
    readonly roleId?: string

    /**
    * Specify RBAC user role type to include (default returns all)
    * @type {'null' | 'notNull'}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesGet
    */
    readonly includeExpiration?: 'null' | 'notNull'

    /**
    * 
    * @type {number}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {'ASC' | 'DESC'}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesGet
    */
    readonly sort?: 'ASC' | 'DESC'
}

/**
* Request parameters for apiV1RbacUserRolesPost operation in RbacUserRolesApi.
* @export
* @interface RbacUserRolesApiApiV1RbacUserRolesPostRequest
*/
export interface RbacUserRolesApiApiV1RbacUserRolesPostRequest {
    /**
    * 
    * @type {CreateRbacUserRoleRequest}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesPost
    */
    readonly createRbacUserRoleRequest?: CreateRbacUserRoleRequest
}

/**
* Request parameters for apiV1RbacUserRolesPut operation in RbacUserRolesApi.
* @export
* @interface RbacUserRolesApiApiV1RbacUserRolesPutRequest
*/
export interface RbacUserRolesApiApiV1RbacUserRolesPutRequest {
    /**
    * 
    * @type {UpdateRbacUserRoleRequest}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesPut
    */
    readonly updateRbacUserRoleRequest?: UpdateRbacUserRoleRequest
}

/**
* Request parameters for apiV1RbacUserRolesRoleIdUserIdDelete operation in RbacUserRolesApi.
* @export
* @interface RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDeleteRequest
*/
export interface RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDeleteRequest {
    /**
    * Role ID
    * @type {string}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDelete
    */
    readonly roleId: string

    /**
    * User ID
    * @type {string}
    * @memberof RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDelete
    */
    readonly userId: string
}


    
        /**
* List Roles
* @param params RolesApiApiV1RolesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RolesGetUrl = (params: RolesApiApiV1RolesGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/roles`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.id !== undefined) {
            localVarQueryParameter['id'] = params.id;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get a Role by ID
* @param params RolesApiApiV1RolesIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RolesIdGetUrl = (params: RolesApiApiV1RolesIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RolesIdGet', 'id', params.id)
    const localVarPath = `/api/v1/roles/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update a Role by ID
* @param params RolesApiApiV1RolesIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RolesIdPutUrl = (params: RolesApiApiV1RolesIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1RolesIdPut', 'id', params.id)
    const localVarPath = `/api/v1/roles/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Create a Role
* @param params RolesApiApiV1RolesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1RolesPostUrl = (params: RolesApiApiV1RolesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/roles`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1RolesGet operation in RolesApi.
* @export
* @interface RolesApiApiV1RolesGetRequest
*/
export interface RolesApiApiV1RolesGetRequest {
    /**
    * 
    * @type {string}
    * @memberof RolesApiApiV1RolesGet
    */
    readonly id?: string

    /**
    * 
    * @type {number}
    * @memberof RolesApiApiV1RolesGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {'ASC' | 'DESC'}
    * @memberof RolesApiApiV1RolesGet
    */
    readonly sort?: 'ASC' | 'DESC'
}

/**
* Request parameters for apiV1RolesIdGet operation in RolesApi.
* @export
* @interface RolesApiApiV1RolesIdGetRequest
*/
export interface RolesApiApiV1RolesIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof RolesApiApiV1RolesIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1RolesIdPut operation in RolesApi.
* @export
* @interface RolesApiApiV1RolesIdPutRequest
*/
export interface RolesApiApiV1RolesIdPutRequest {
    /**
    * 
    * @type {string}
    * @memberof RolesApiApiV1RolesIdPut
    */
    readonly id: string

    /**
    * 
    * @type {UpdateRoleRequest}
    * @memberof RolesApiApiV1RolesIdPut
    */
    readonly updateRoleRequest?: UpdateRoleRequest
}

/**
* Request parameters for apiV1RolesPost operation in RolesApi.
* @export
* @interface RolesApiApiV1RolesPostRequest
*/
export interface RolesApiApiV1RolesPostRequest {
    /**
    * 
    * @type {CreateRoleRequest}
    * @memberof RolesApiApiV1RolesPost
    */
    readonly createRoleRequest?: CreateRoleRequest
}


    
        /**
* Update multiple users
* @param params UsersApiApiV1UsersBulkUpdatePutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersBulkUpdatePutUrl = (params: UsersApiApiV1UsersBulkUpdatePutRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/users/bulk-update`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* For users - check if a user can perform an action
* @param params UsersApiApiV1UsersCanIGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersCanIGetUrl = (params: UsersApiApiV1UsersCanIGetRequest, baseUrl: string): string => {
    // verify required parameter 'verb' is not null or undefined
    assertParamExists('apiV1UsersCanIGet', 'verb', params.verb)
    // verify required parameter 'resource' is not null or undefined
    assertParamExists('apiV1UsersCanIGet', 'resource', params.resource)
    // verify required parameter 'cluster' is not null or undefined
    assertParamExists('apiV1UsersCanIGet', 'cluster', params.cluster)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1UsersCanIGet', 'namespace', params.namespace)
    const localVarPath = `/api/v1/users/can-i`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.verb !== undefined) {
            localVarQueryParameter['verb'] = params.verb;
    }
    if (params.resource !== undefined) {
            localVarQueryParameter['resource'] = params.resource;
    }
    if (params.apiGroup !== undefined) {
            localVarQueryParameter['apiGroup'] = params.apiGroup;
    }
    if (params.cluster !== undefined) {
            localVarQueryParameter['cluster'] = params.cluster;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* List users
* @param params UsersApiApiV1UsersGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersGetUrl = (params: UsersApiApiV1UsersGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/users`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.id !== undefined) {
            localVarQueryParameter['id'] = params.id;
    }
    if (params.email !== undefined) {
            localVarQueryParameter['email'] = params.email;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.displayName !== undefined) {
            localVarQueryParameter['displayName'] = params.displayName;
    }
    if (params.isVerified !== undefined) {
            localVarQueryParameter['isVerified'] = params.isVerified;
    }
    if (params.isDeleted !== undefined) {
            localVarQueryParameter['isDeleted'] = params.isDeleted;
    }
    if (params.isKomodorAdmin !== undefined) {
            localVarQueryParameter['isKomodorAdmin'] = params.isKomodorAdmin;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* For account-admin - check if a user can perform an action
* @param params UsersApiApiV1UsersIdCanIGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersIdCanIGetUrl = (params: UsersApiApiV1UsersIdCanIGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1UsersIdCanIGet', 'id', params.id)
    // verify required parameter 'verb' is not null or undefined
    assertParamExists('apiV1UsersIdCanIGet', 'verb', params.verb)
    // verify required parameter 'resource' is not null or undefined
    assertParamExists('apiV1UsersIdCanIGet', 'resource', params.resource)
    // verify required parameter 'cluster' is not null or undefined
    assertParamExists('apiV1UsersIdCanIGet', 'cluster', params.cluster)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1UsersIdCanIGet', 'namespace', params.namespace)
    const localVarPath = `/api/v1/users/{id}/can-i`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.verb !== undefined) {
            localVarQueryParameter['verb'] = params.verb;
    }
    if (params.resource !== undefined) {
            localVarQueryParameter['resource'] = params.resource;
    }
    if (params.apiGroup !== undefined) {
            localVarQueryParameter['apiGroup'] = params.apiGroup;
    }
    if (params.cluster !== undefined) {
            localVarQueryParameter['cluster'] = params.cluster;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Deletes a User by ID
* @param params UsersApiApiV1UsersIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersIdDeleteUrl = (params: UsersApiApiV1UsersIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1UsersIdDelete', 'id', params.id)
    const localVarPath = `/api/v1/users/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get User\'s effective permissions
* @param params UsersApiApiV1UsersIdEffectivePermissionsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersIdEffectivePermissionsGetUrl = (params: UsersApiApiV1UsersIdEffectivePermissionsGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1UsersIdEffectivePermissionsGet', 'id', params.id)
    const localVarPath = `/api/v1/users/{id}/effective-permissions`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get a User by ID
* @param params UsersApiApiV1UsersIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersIdGetUrl = (params: UsersApiApiV1UsersIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1UsersIdGet', 'id', params.id)
    const localVarPath = `/api/v1/users/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update a User by ID
* @param params UsersApiApiV1UsersIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersIdPutUrl = (params: UsersApiApiV1UsersIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1UsersIdPut', 'id', params.id)
    const localVarPath = `/api/v1/users/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update a User account by userID and accountID
* @param params UsersApiApiV1UsersIdUpdateAccountPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersIdUpdateAccountPutUrl = (params: UsersApiApiV1UsersIdUpdateAccountPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1UsersIdUpdateAccountPut', 'id', params.id)
    const localVarPath = `/api/v1/users/{id}/update-account`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get Users by specified IDs
* @param params UsersApiApiV1UsersIdsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersIdsGetUrl = (params: UsersApiApiV1UsersIdsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/users/ids`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Create a User
* @param params UsersApiApiV1UsersPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1UsersPostUrl = (params: UsersApiApiV1UsersPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/users`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1UsersBulkUpdatePut operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersBulkUpdatePutRequest
*/
export interface UsersApiApiV1UsersBulkUpdatePutRequest {
    /**
    * 
    * @type {UpdateUserBulkRequest}
    * @memberof UsersApiApiV1UsersBulkUpdatePut
    */
    readonly updateUserBulkRequest?: UpdateUserBulkRequest
}

/**
* Request parameters for apiV1UsersCanIGet operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersCanIGetRequest
*/
export interface UsersApiApiV1UsersCanIGetRequest {
    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersCanIGet
    */
    readonly verb: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersCanIGet
    */
    readonly resource: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersCanIGet
    */
    readonly cluster: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersCanIGet
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersCanIGet
    */
    readonly apiGroup?: string
}

/**
* Request parameters for apiV1UsersGet operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersGetRequest
*/
export interface UsersApiApiV1UsersGetRequest {
    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly id?: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly email?: string

    /**
    * 
    * @type {number}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {'ASC' | 'DESC'}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly sort?: 'ASC' | 'DESC'

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly displayName?: string

    /**
    * 
    * @type {boolean}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly isVerified?: boolean

    /**
    * 
    * @type {boolean}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly isDeleted?: boolean

    /**
    * 
    * @type {boolean}
    * @memberof UsersApiApiV1UsersGet
    */
    readonly isKomodorAdmin?: boolean
}

/**
* Request parameters for apiV1UsersIdCanIGet operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersIdCanIGetRequest
*/
export interface UsersApiApiV1UsersIdCanIGetRequest {
    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdCanIGet
    */
    readonly id: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdCanIGet
    */
    readonly verb: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdCanIGet
    */
    readonly resource: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdCanIGet
    */
    readonly cluster: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdCanIGet
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdCanIGet
    */
    readonly apiGroup?: string
}

/**
* Request parameters for apiV1UsersIdDelete operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersIdDeleteRequest
*/
export interface UsersApiApiV1UsersIdDeleteRequest {
    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdDelete
    */
    readonly id: string
}

/**
* Request parameters for apiV1UsersIdEffectivePermissionsGet operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersIdEffectivePermissionsGetRequest
*/
export interface UsersApiApiV1UsersIdEffectivePermissionsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdEffectivePermissionsGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1UsersIdGet operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersIdGetRequest
*/
export interface UsersApiApiV1UsersIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1UsersIdPut operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersIdPutRequest
*/
export interface UsersApiApiV1UsersIdPutRequest {
    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdPut
    */
    readonly id: string

    /**
    * 
    * @type {UpdateUserRequest}
    * @memberof UsersApiApiV1UsersIdPut
    */
    readonly updateUserRequest?: UpdateUserRequest
}

/**
* Request parameters for apiV1UsersIdUpdateAccountPut operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersIdUpdateAccountPutRequest
*/
export interface UsersApiApiV1UsersIdUpdateAccountPutRequest {
    /**
    * 
    * @type {string}
    * @memberof UsersApiApiV1UsersIdUpdateAccountPut
    */
    readonly id: string

    /**
    * 
    * @type {UpdateUserAccountRequest}
    * @memberof UsersApiApiV1UsersIdUpdateAccountPut
    */
    readonly updateUserAccountRequest?: UpdateUserAccountRequest
}

/**
* Request parameters for apiV1UsersIdsGet operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersIdsGetRequest
*/
export interface UsersApiApiV1UsersIdsGetRequest {
    /**
    * 
    * @type {GetManyUsersByIDsRequest}
    * @memberof UsersApiApiV1UsersIdsGet
    */
    readonly getManyUsersByIDsRequest?: GetManyUsersByIDsRequest
}

/**
* Request parameters for apiV1UsersPost operation in UsersApi.
* @export
* @interface UsersApiApiV1UsersPostRequest
*/
export interface UsersApiApiV1UsersPostRequest {
    /**
    * 
    * @type {CreateUserRequest}
    * @memberof UsersApiApiV1UsersPost
    */
    readonly createUserRequest?: CreateUserRequest
}


    
