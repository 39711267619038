import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import { useCallback, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { muiTheme } from "@komodorio/design-system";

import { APIKey } from "@/generated/auth";
import { useUpdateApiKey } from "@/shared/hooks/auth-service/client/apiKeys/useUpdateApiKey";
import { apiKeysAriaLabels } from "@/components/Settings/ApiKeys/apiKeysAriaLabels";
import { Result } from "@/components/Actions/common/styles";

export const NonExpireKeyModal: React.FC<{
  handleClose: () => void;
  apiKey: APIKey;
  refreshKeys: () => void;
}> = ({ handleClose, apiKey, refreshKeys }) => {
  const [error, setError] = useState<string | undefined>();
  const [confirmCheck, setConfirmCheck] = useState<boolean>(false);
  const { mutateAsync: updateKey } = useUpdateApiKey();
  const handleSave = useCallback(
    async (confirmed: boolean) => {
      try {
        if (confirmed) {
          await updateKey({ id: apiKey.key });
          refreshKeys();
        }
        handleClose();
      } catch (e) {
        setError(
          "Encountered an issue while removing the API key expiration. Please retry or contact support for assistance."
        );
      }
    },
    [updateKey, refreshKeys, handleClose, apiKey]
  );

  const content = useMemo(() => {
    return (
      <Stack rowGap={2}>
        <Typography variant={"body2"} color={muiTheme.palette.info.dark}>
          This action will remove the expiration from the API key. This is
          irreversible, and will mark the key as “Non-expiring”.
        </Typography>
        <Box>
          <Stack alignItems={"center"} direction={"row"}>
            <Checkbox
              checked={confirmCheck}
              onChange={() => setConfirmCheck(!confirmCheck)}
            />

            <Typography variant={"body2"} color={muiTheme.palette.text.primary}>
              Remove API Key Expiration
            </Typography>
          </Stack>
        </Box>
        {error && (
          <Result>
            <Typography color={muiTheme.palette.error.main}>{error}</Typography>
          </Result>
        )}
      </Stack>
    );
  }, [error, confirmCheck]);

  return (
    <ConfirmationDialog
      aria-label={apiKeysAriaLabels.modals.ttl}
      title={"Remove API Key Expiration"}
      content={content}
      onClose={handleSave}
      okText={"Apply"}
      okDisabled={!confirmCheck}
      height={"unset"}
    />
  );
};
