import React, { useEffect } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ArgoWorkflow from "../../resources/argoWorkflow";
import { Timeframe } from "../../../../shared/types/TimeWindow";

import PodsTab from "./PodsTab";

import { useDeletedPodsProperties } from "@/components/Inspection/historicalFetch/useDeletedPodsProperties";

export const ArgoWFPodsTab: React.FC<{ resource: ArgoWorkflow }> = ({
  resource,
}) => {
  const {
    showDeletedPodsUrlParam,
    setShowDeletedPodsUrlParam,
    setDeletedPodsTimeWindowUrlParam,
  } = useDeletedPodsProperties();

  useEffect(() => {
    if (showDeletedPodsUrlParam === null) {
      setShowDeletedPodsUrlParam(true, true);
      setDeletedPodsTimeWindowUrlParam(
        { timeframe: Timeframe.Last48Hours },
        { replace: true }
      );
    }
  }, [
    setDeletedPodsTimeWindowUrlParam,
    setShowDeletedPodsUrlParam,
    showDeletedPodsUrlParam,
  ]);

  return <PodsTab resource={resource} />;
};
