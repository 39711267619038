import React, { useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Node from "../../../resources/node";
import { useIsMetricsDrawerSupported } from "../../../../Metrics/metricsHooks";
import { GraphsContainer, MainContainer } from "../../MetricsTab/styles";

import { MetricsTimeSelector } from "./MetricsPreferences/MetricsTimeSelector";
import { NodesCpuGraph } from "./components/graphs/NodesCpuGraph";
import { NodesMemoryGraph } from "./components/graphs/NodesMemoryGraph";
import { NodesPodsCountGraph } from "./components/graphs/NodesPodsCountGraph";
import { useNodeMetricsWithZoom } from "./hooks/useMetricsWithZoom";

import { useNodeMetricsTabContext } from "@/components/ResourceView/tabs/NodeMetricsTab/context/useNodeMetricsTabContext";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";
import { getTimeWindowLabel } from "@/shared/constants/datadog";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";

interface NodeMetricsTabContentProps {
  node: Node;
}

const NodeMetricsTabContentComponent: React.FC<NodeMetricsTabContentProps> = ({
  node,
}) => {
  const { cluster, name } = node;
  const isMetricsSupported = useIsMetricsDrawerSupported(cluster);

  const paused = useMemo(
    () => !cluster || !name || !isMetricsSupported,
    [cluster, isMetricsSupported, name]
  );

  const { fromEpoch, toEpoch, toEpochMs, graphProps } =
    useNodeMetricsWithZoom();

  const nodePodsCountParams = useMemo(
    () => ({
      nodeName: name,
      clusterName: cluster,
      fromEpoch,
      toEpoch,
      pause: paused,
      toEpochMs,
    }),
    [cluster, fromEpoch, name, paused, toEpoch, toEpochMs]
  );

  const metricsParams = useMemo(
    () => ({
      ...nodePodsCountParams,
      endTimestamp: new Date().getTime(),
    }),
    [nodePodsCountParams]
  );

  const id = useMemo(() => `${cluster}/${name}`, [cluster, name]);

  return (
    <MainContainer>
      <MetricsTimeSelector />
      <GraphsContainer>
        <NodesMemoryGraph
          requestParams={metricsParams}
          node={node}
          id={id}
          graphProps={graphProps}
        />
        <NodesCpuGraph
          requestParams={metricsParams}
          node={node}
          id={id}
          graphProps={graphProps}
        />
        <NodesPodsCountGraph
          requestParams={nodePodsCountParams}
          node={node}
          id={id}
          graphProps={graphProps}
        />
      </GraphsContainer>
    </MainContainer>
  );
};

export const NodeMetricsTabContent: React.FC<NodeMetricsTabContentProps> = ({
  node,
}) => {
  const { timeWindow } = useNodeMetricsTabContext();
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.NodeMetrics, {
      filters: {
        timeWindow: getTimeWindowLabel(timeWindow.timeframe),
      },
    });
  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <NodeMetricsTabContentComponent node={node} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
