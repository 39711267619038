import React from "react";
import { PersistentVolumeClaim as PVCType } from "kubernetes-types/core/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { PVC as pvcIcon } from "@komodorio/design-system/icons";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PVCDescribeTab } from "../tabs/DescribeTab/pvc/PVCDescribeTab";
import { formatQuantityWithPrettyBinaryUnits } from "../../../shared/utils/formatQuantity";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";
import { greenTag, StatusTag } from "../tabs/DescribeTab/common/StatusTag";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class PVC implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.Delete];
  readonly creationTimestamp;
  readonly phase;
  readonly requestStorage;
  readonly capacityStorage;
  readonly storageClass;
  readonly volumeName;
  readonly finalizers;
  readonly accessModes;
  readonly selector;
  readonly matchLabels;
  readonly matchExpressions;
  readonly fullObj;
  readonly defaultTab;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(cluster: string, pvc: PVCType, agentId: string) {
    this.agentId = agentId;
    this.id = pvc.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = pvc.metadata?.namespace ?? "";
    this.name = pvc.metadata?.name ?? "";
    this.kind = pvc.kind ?? "";
    this.labels = pvc.metadata?.labels ?? {};
    this.annotations = pvc.metadata?.annotations ?? {};
    this.icon = pvcIcon;
    this.creationTimestamp = pvc.metadata?.creationTimestamp ?? "";
    this.phase = pvc.status?.phase ?? "";
    this.requestStorage = pvc.spec?.resources?.requests?.storage
      ? formatQuantityWithPrettyBinaryUnits(
          pvc.spec?.resources?.requests?.storage
        )
      : "N/A";
    this.capacityStorage = pvc.status?.capacity?.storage
      ? formatQuantityWithPrettyBinaryUnits(pvc.status?.capacity?.storage)
      : "N/A";
    this.storageClass = pvc.spec?.storageClassName ?? "";
    this.volumeName = pvc.spec?.volumeName ?? "N/A";
    this.finalizers = pvc.metadata?.finalizers ?? [];
    this.accessModes = pvc.spec?.accessModes ?? [];
    this.selector = pvc.spec?.selector ?? {};
    this.matchLabels = pvc.spec?.selector?.matchLabels ?? {};
    this.matchExpressions = pvc.spec?.selector?.matchExpressions ?? [];
    this.fullObj = pvc;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <PVCDescribeTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }

  renderStatus(): JSX.Element {
    const mapStatusOverride: { [key: string]: { bg: string; fg: string } } = {
      BOUND: greenTag,
      Released: greenTag,
      Available: greenTag,
    };
    return <StatusTag value={this.phase} mapOverride={mapStatusOverride} />;
  }
}
