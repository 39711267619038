import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  padding: 0px 16px 16px 16px;
  gap: 8px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: self-start;
  gap: 8px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: self-start;
  gap: 8px;
`;

interface DrawerHeaderProps {
  title: React.ReactNode;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  title,
  leftActions,
  rightActions,
}) => {
  return (
    <Container>
      <LeftContainer>{leftActions != null && leftActions}</LeftContainer>

      {title != null && title}

      <RightContainer>{rightActions != null && rightActions}</RightContainer>
    </Container>
  );
};
