import { AllocationTableColumns } from "../../../../CostOptimizationView/types/tableTypes";

import { CostSectionGroupBy } from "@/components/ClustersView/ClusterOverview/components/CostSection/types";

export const _30_DAYS_MS = 1000 * 60 * 60 * 24 * 30;

export const costSectionTableColumns = (
  groupBy: CostSectionGroupBy
): AllocationTableColumns[] => [
  groupBy,
  "shareOfCost",
  "totalCost",
  "optimizationScore",
  "potentialSaving",
];

export const COST_SECTION_TITLE = "Cost Optimization";
export const COST_SECTION_EMPTY_MESSAGE =
  "No cost optimization suggestions are available to you";

const groupByToTitle: Record<CostSectionGroupBy, string> = {
  komodorServiceName: "Service",
  namespace: "Namespace",
  clusterName: "Cluster",
};
export const costSectionTableTitle = (groupBy: CostSectionGroupBy) =>
  `Top Saving Opportunities by ${groupByToTitle[groupBy]}`;
