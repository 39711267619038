import { DatadogReportLoadingTimeContextState } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

export const initialState: DatadogReportLoadingTimeContextState = {
  reportLoadingState: () => undefined,
  triggerStartView: () => undefined,
  removeFromLoadingState: () => undefined,
  loadingState: {},
  getCurrentViewOptions: () => ({
    name: DatadogViewNamesEnum.reliabilityWorkloadHealth,
    context: {
      feTeam: "operate",
      beTeam: "operate",
    },
  }),
  resetLoadingState: () => undefined,
  setMainContainerDivRef: () => undefined,
};
