import { AxiosInstance } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  apiV1AuditLogFiltersGetUrl,
  apiV1AuditLogGetUrl,
  AuditLogApiApiV1AuditLogFiltersGetRequest,
  AuditLogApiApiV1AuditLogGetRequest,
  PaginatedAuditLogs,
} from "@/generated/accounts";
import {
  useAccountApiClientWithHeaders,
  useAccountsApiClient,
} from "@/shared/hooks/accounts-service/client/apiClient";

export const AUDIT_PATH = "api/v1/audit-log";
export const AUDIT_FILTERS_PATH = `${AUDIT_PATH}/filters`;

const getAudit = async (
  apiClient: AxiosInstance,
  params: AuditLogApiApiV1AuditLogGetRequest
): Promise<PaginatedAuditLogs> => {
  const { data } = await apiClient.get(
    apiV1AuditLogGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetAudit = ({
  enabled,
  params,
}: {
  enabled?: boolean;
  params: AuditLogApiApiV1AuditLogGetRequest;
}) => {
  const apiClient = useAccountsApiClient();
  return useQuery([AUDIT_PATH, params], () => getAudit(apiClient, params), {
    enabled,
  });
};

const getAuditFilters = async (
  apiClient: AxiosInstance,
  params: AuditLogApiApiV1AuditLogFiltersGetRequest
) => {
  const { data } = await apiClient.get(
    apiV1AuditLogFiltersGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetAuditFilters = ({
  enabled,
  params,
}: {
  enabled: boolean;
  params: AuditLogApiApiV1AuditLogFiltersGetRequest;
}) => {
  const apiClient = useAccountsApiClient();
  return useQuery(
    [AUDIT_FILTERS_PATH, params],
    () => getAuditFilters(apiClient, params),
    {
      enabled,
    }
  );
};

const getAuditAsCSV = async (
  apiClient: AxiosInstance,
  params: AuditLogApiApiV1AuditLogGetRequest
): Promise<string> => {
  const { data } = await apiClient.get(
    apiV1AuditLogGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetAuditInCSVFormat = ({
  params,
}: {
  params: AuditLogApiApiV1AuditLogGetRequest;
}) => {
  const apiClient = useAccountApiClientWithHeaders({ accept: "text/csv" });
  return useMutation([AUDIT_PATH, params], () =>
    getAuditAsCSV(apiClient, params)
  );
};
