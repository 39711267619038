import { useQueries, useQuery } from "@tanstack/react-query";
import { Dictionary } from "lodash";

import {
  getWorkloadContainersMetricsUrl,
  MetricsApiGetWorkloadContainersMetricsRequest,
  WorkloadMetricsContainers,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

export const GET_METRICS_WORKLOAD_CONTAINERS_MEMORY_PATH =
  "/workload/containers/memory";
export const GET_METRICS_WORKLOAD_CONTAINERS_CPU_PATH =
  "/workload/containers/cpu";

type GetWorkloadContainersMetricsParams = {
  params: MetricsApiGetWorkloadContainersMetricsRequest;
  enabled?: boolean;
};

export const useGetWorkloadContainersMetrics = ({
  params,
  enabled,
}: GetWorkloadContainersMetricsParams) => {
  const apiClient = useMetricsApiClient();
  let url = "";
  try {
    url = getWorkloadContainersMetricsUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    );
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  const queryKey =
    params.kind === "cpu"
      ? GET_METRICS_WORKLOAD_CONTAINERS_CPU_PATH
      : GET_METRICS_WORKLOAD_CONTAINERS_MEMORY_PATH;

  return useQuery(
    [queryKey, params],
    async () => await apiClient.get<WorkloadMetricsContainers>(url),
    { enabled: enabled && !!url }
  );
};

type GetWorkloadsContainersMetricsParams = {
  params: MetricsApiGetWorkloadContainersMetricsRequest[];
  enabledClusters: Dictionary<boolean>;
};

export const useGetWorkloadsContainersMetrics = ({
  params,
  enabledClusters,
}: GetWorkloadsContainersMetricsParams) => {
  const apiClient = useMetricsApiClient();

  return useQueries({
    queries: params.map((requestParams) => {
      const queryKey =
        requestParams.kind === "cpu"
          ? GET_METRICS_WORKLOAD_CONTAINERS_CPU_PATH
          : GET_METRICS_WORKLOAD_CONTAINERS_MEMORY_PATH;
      let url = "";
      try {
        url = getWorkloadContainersMetricsUrl(
          requestParams,
          apiClient.defaults.baseURL ?? ""
        );
      } catch (error) {
        // there was an issue getting the url, probably due to a missing parameter
      }
      const enabled =
        Boolean(enabledClusters[requestParams.clusterName]) && !!url;
      return {
        queryKey: [queryKey, requestParams],
        queryFn: async () =>
          await apiClient.get<WorkloadMetricsContainers>(url),
        enabled,
      };
    }),
  });
};
