import React, { useCallback, useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useResourceListOfDeletedPods } from "../historicalFetch/useResourceListFromDatabase";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useInspectionData } from "../utils/useGetInspectionData";
import { useDeletedPodsProperties } from "../historicalFetch/useDeletedPodsProperties";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import InspectionViewComponent, {
  InspectionComponentProps,
  ResourceListResponse,
} from "./common";
import { useShouldShowDeletedPods } from "./inspectionViewsHooks";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { getViewOptionsByResource } from "@/components/Inspection/InspectionViews/datadogReporting/inspectionViewsReportingUtils";

export interface NamespacesResult {
  namespaces?: string[];
  errorMessage: string;
}

const InspectionViewAtmComponent: React.FC<InspectionComponentProps> = ({
  kubernetesResource,
  cluster,
  onClusterChange,
  agentInfo,
  agentId,
}) => {
  const { namespacesResult, resourceList, refresh, isLiveDataSupported } =
    useInspectionData({
      agentId,
      agentInfo,
      cluster,
      kubernetesResource,
    });

  const shouldShowDeletedPods = useShouldShowDeletedPods(kubernetesResource);

  return shouldShowDeletedPods ? (
    <InspectionViewAtmWithDeletedPods
      kubernetesResource={kubernetesResource}
      cluster={cluster}
      onClusterChange={onClusterChange}
      namespacesResult={namespacesResult}
      resourceList={resourceList}
      refresh={refresh}
      shouldUseLiveData={isLiveDataSupported}
    />
  ) : (
    <InspectionViewComponent
      cluster={cluster}
      onClusterChange={onClusterChange}
      resourceList={resourceList}
      kubernetesResource={kubernetesResource}
      refresh={refresh}
      namespaces={namespacesResult}
      shouldUseLiveData={isLiveDataSupported}
    />
  );
};
type InspectionWithDeletedPodsComponentProps = {
  kubernetesResource: KubernetesResource;
  cluster: string;
  onClusterChange: (clusterName: string) => void;
  namespacesResult: NamespacesResult;
  resourceList: ResourceListResponse;
  refresh: () => void;
  shouldUseLiveData: boolean;
};

const InspectionViewAtmWithDeletedPods: React.FC<
  InspectionWithDeletedPodsComponentProps
> = ({
  kubernetesResource,
  cluster,
  onClusterChange,
  namespacesResult,
  resourceList,
  refresh,
  shouldUseLiveData,
}) => {
  const { deletedPodsTimeWindowUrlParam } = useDeletedPodsProperties();

  const params = useMemo(
    () => (cluster ? { clusterName: cluster } : null),
    [cluster]
  );
  const {
    data: deletedPodsData,
    fetching: isDeletedPodsFetching,
    emptyResult: deletedPodsEmptyResult,
    refresh: refreshDeletedPods,
    errorMessage: deletedPodsErrorMessage,
  } = useResourceListOfDeletedPods(deletedPodsTimeWindowUrlParam, params);

  const resourceListWithDeletedPods: ResourceListResponse = useMemo(() => {
    return {
      ...resourceList,
      rows: deletedPodsData.concat(resourceList.rows),
      fetching: resourceList.fetching || isDeletedPodsFetching,
      emptyResult: resourceList.emptyResult && deletedPodsEmptyResult,
      errorMessage: resourceList.errorMessage || deletedPodsErrorMessage,
    };
  }, [
    deletedPodsData,
    deletedPodsEmptyResult,
    deletedPodsErrorMessage,
    isDeletedPodsFetching,
    resourceList,
  ]);
  const refreshWithDeletedPods = useCallback(() => {
    refresh();
    refreshDeletedPods();
  }, [refresh, refreshDeletedPods]);

  return (
    <InspectionViewComponent
      cluster={cluster}
      onClusterChange={onClusterChange}
      resourceList={resourceListWithDeletedPods}
      kubernetesResource={kubernetesResource}
      refresh={refreshWithDeletedPods}
      namespaces={namespacesResult}
      shouldUseLiveData={shouldUseLiveData}
    />
  );
};

const InspectionViewAtm: React.FC<InspectionComponentProps> = (props) => {
  const isDeletedPods = useShouldShowDeletedPods(props.kubernetesResource);

  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        ...getViewOptionsByResource(props.kubernetesResource, isDeletedPods),
      }}
    >
      <InspectionViewAtmComponent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InspectionViewAtm;
