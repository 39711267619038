import { RELIABILITY_PARAM_KEY } from "../../../../../../../shared/config/urlSearchParamsKeys";
import { ViolationDrawerProps } from "../violationDrawerTypes";

import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";

export const useGetCurrentTab = () => {
  const [drawerStateInUrl] = useDrawerUrlState<ViolationDrawerProps>(
    RELIABILITY_PARAM_KEY
  );
  return drawerStateInUrl?.currentTab;
};
