import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { useIsDynamicChecksTab } from "../hooks/useIsDynamicChecksTab";

import { AddCheckButton } from "./AddCheckButton";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

export const dynamicChecksText =
  "Add checks to replace existing reliability policies on your chosen clusters.";

export const staticChecksSubTitle = "Notice:";
export const staticChecksText1 =
  "All the best practice checks are active by default.";
export const staticChecksText2 =
  " It is necessary to add them only if they are disabled in the default policy.";

const {
  policies: {
    policiesDrawer: {
      configurationEmptyState: {
        description: configurationEmptyStateDescriptionAriaLabel,
      },
    },
  },
} = reliabilityArialLabels;

const Container = styled.div`
  height: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  & > *:first-child {
    margin-bottom: 16px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddCheckPrompt: React.FC = () => {
  const isDynamicChecksTab = useIsDynamicChecksTab();

  return (
    <Container>
      <Description aria-label={configurationEmptyStateDescriptionAriaLabel}>
        {isDynamicChecksTab ? (
          <Typography variant={"body2"}>{dynamicChecksText}</Typography>
        ) : (
          <>
            <Typography variant={"h5"}>{staticChecksSubTitle}</Typography>
            <Typography variant={"body2"}>{staticChecksText1}</Typography>
            <Typography variant={"body2"}>{staticChecksText2}</Typography>
          </>
        )}
      </Description>

      <AddCheckButton />
    </Container>
  );
};
