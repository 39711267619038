import { useCallback } from "react";
import { snakeCase } from "lodash";
import { parseISO } from "date-fns";

import {
  useGetAudit,
  useGetAuditInCSVFormat,
} from "@/shared/hooks/accounts-service/client/audit/useGetAudit";
import { useGetAuditAppliedFilters } from "@/pages/organization-settings/account/AuditPage/hooks/useGetAuditAppliedFilters";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { downloadFile } from "@/shared/utils/downloadFile";
import { notifyDDError } from "@/shared/hooks/exceptionManagement";

export const useAuditData = () => {
  const appliedFilters = useGetAuditAppliedFilters();

  return useGetAudit({
    enabled: true,
    params: appliedFilters,
  });
};

export const useExportToCsv = () => {
  const appliedFilters = useGetAuditAppliedFilters();
  const { format } = useDateFormatter({
    timeZoneName: undefined,
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  const { mutateAsync: getContentInCSV, isLoading: isLoadingCsv } =
    useGetAuditInCSVFormat({
      params: appliedFilters,
    });

  const exportToCsv = useCallback(async () => {
    try {
      const result = await getContentInCSV();

      downloadFile({
        data: result,
        fileName: `audit-log-${snakeCase(
          format(parseISO(new Date().toISOString()))
        )}.csv`,
      });

      return result;
    } catch (error) {
      notifyDDError({
        message: `Error exporting audit log to file - ${error}`,
        name: "useExportToCsv",
      });
      return;
    }
  }, [getContentInCSV, format]);

  return { exportToCsv, isLoadingCsv };
};
