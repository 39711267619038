import { useMemo } from "react";

import { useFetchWorkloadMetricsData } from "../../hooks/fetchWorkloadMetricsHooks";
import { useGraphData } from "../../hooks/graphDataHooks";
import { MetricType } from "../../../../../Metrics/types";

import {
  generateWorkloadMetricsRequestParams,
  useEpochsFromWorkloadMetricsTimeWindow,
} from "@/components/Metrics/hooks/workload/workloadMetricsTabHooks";
import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";

type WorkloadMetricsParams = {
  type: MetricType;
  komodorUidParams: KomodorUidParams;
  endTime?: number;
};

export const useMetricsGraphRequestParams = (
  komodorUidParams: KomodorUidParams,
  fromEpoch: number,
  toEpoch: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) =>
  useMemo(
    () =>
      generateWorkloadMetricsRequestParams(
        komodorUidParams,
        fromEpoch,
        toEpoch
      ),
    [fromEpoch, komodorUidParams, toEpoch]
  );

export const useWorkloadMetrics = ({
  type,
  komodorUidParams,
  endTime,
}: WorkloadMetricsParams) => {
  const { toEpochMs, fromEpoch, toEpoch } =
    useEpochsFromWorkloadMetricsTimeWindow(endTime);
  const requestParams = useMetricsGraphRequestParams(
    komodorUidParams,
    fromEpoch,
    toEpoch
  );

  const uid = useMemo(
    () => ({
      name: komodorUidParams.name,
      cluster: komodorUidParams.cluster,
      namespace: komodorUidParams.namespace,
    }),
    [
      komodorUidParams.cluster,
      komodorUidParams.name,
      komodorUidParams.namespace,
    ]
  );
  const {
    data: metricsData,
    isLoading,
    error,
  } = useFetchWorkloadMetricsData({
    requestParams,
    type,
  });

  const { graphProps, hasGraphData } = useGraphData(metricsData?.data);

  const hasLimit = metricsData?.data.limit?.some((dp) => dp.value > 0);

  return {
    loading: isLoading,
    error,
    graphProps,
    hasGraphData,
    uid,
    fromEpoch,
    toEpoch,
    now: endTime ?? toEpochMs,
    hasLimit,
  };
};
