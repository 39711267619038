import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import Komo from "../../../shared/assets/komo.svg?react";

const Center = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5rem 0.5rem;
  row-gap: 16px;
  width: 30%;
  margin: auto;
`;

type EmptyStateProps = {
  title: React.ReactNode;
  subtitle?: string;
  children?: React.ReactNode;
  className?: string;
};

/** @deprecated Use @komodorio/komodor-ui/EmptyState instead. */
export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  subtitle,
  children,
  className,
}) => {
  return (
    <Center>
      <Container className={className}>
        <Komo />
        <Typography variant="h4" color={muiColors.gray[800]}>
          {typeof title === "string"
            ? title.split("\n").map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : title}
        </Typography>
        {subtitle && (
          <Typography
            variant={"body2"}
            color={muiColors.gray[500]}
            sx={{
              maxWidth: "20rem",
            }}
          >
            {subtitle}
          </Typography>
        )}
        {children}
      </Container>
    </Center>
  );
};
