import React from "react";
import { TagsCell } from "@komodorio/design-system/komodor-ui";

import { User } from "@/generated/auth";
import {
  getFilteredUserRoles,
  getTimeLeft,
  sortByNameLength,
} from "@/components/Settings/Users/utils";
import { RoleWithUserExpiration } from "@/components/Settings/Users/types";
import { ROLES_CELL_WIDTH } from "@/components/Settings/Users/const";

export const LABEL_PREFIX = "Role";

export const createFilteredRoleGetter = (
  user: User,
  filterTemporaryRoles: boolean
) => {
  const filteredRoles = getFilteredUserRoles(user, filterTemporaryRoles)?.sort(
    sortByNameLength
  );

  const itemMapper = (role: RoleWithUserExpiration) => ({
    key: role.name,
    label: filterTemporaryRoles
      ? `${role.name} (expires in ${getTimeLeft(role.expiration)})`
      : role.name,
  });

  return (
    <TagsCell
      items={filteredRoles}
      textPath="name"
      itemMapper={itemMapper}
      labelPrefix={LABEL_PREFIX}
      cellWidth={ROLES_CELL_WIDTH}
    />
  );
};
