import {
  CostOptimizationConfig,
  Response,
} from "../types/costOptimizationTypes";
import { ALL_CLUSTER_NAMES_SELECTOR } from "../components/CostOptimizationSettings/constants";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "../constants/costOptimizationConstants";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CostOptimizationStore } from "./costOptimizationStore";

const initialResponse: Response = {
  loading: true,
  error: null,
  refresh: () => undefined,
};

export const initialPaginatedTableResponse = {
  tokens: { 0: undefined },
  pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  pageIndex: 0,
  orderBy: undefined,
  filterBy: undefined,
  ...initialResponse,
};

export const initialSettingsConfiguration: CostOptimizationConfig = {
  monthlyCpuCost: 16,
  monthlyMemoryCost: 2.15,
  // allocateIdleResources: true,
  // monthlySharedOverhead: 0,
};

export const initialState: CostOptimizationStore = {
  allocationFilters: {
    groupBy: "clusterName",
    timeWindow: "7d",
  },
  rightSizingFilters: {
    strategy: "moderate",
  },
  rightSizingRecommendationsStrategiesState: {},
  rightSizingRecommendationsState: { ...initialResponse },
  sharedCostFilters: {
    scope: { clusters: [] },
    table: { filter: undefined },
  },
  rightSizingRecommendationsModalState: {},
  setAllocationFilters: () => undefined,
  setRightSizingFilters: () => undefined,
  setRightSizingRecommendationsStrategiesState: () => undefined,
  setRightSizingRecommendationsState: () => undefined,
  setSharedCostFilters: () => undefined,
  setClustersEarliestRowTime: () => undefined,
  clustersEarliestRowTime: { clusters: undefined },
  allocationSummaryState: {
    ...initialResponse,
  },
  setAllocationSummaryState: () => undefined,
  allocationMetricsState: {
    ...initialResponse,
  },
  setAllocationMetricsState: () => undefined,
  allocationStatsState: {
    ...initialResponse,
  },
  setAllocationStatsState: () => undefined,
  rightSizingSummaryState: {
    ...initialResponse,
  },
  setRightSizingCPUMetricsState: () => undefined,
  setRightSizingMemoryMetricsState: () => undefined,
  rightSizingMetricsState: {
    cpu: { ...initialResponse },
    memory: { ...initialResponse },
  },
  setRightSizingSummaryState: () => undefined,
  setRightSizingStatsState: () => undefined,
  rightSizingStatsState: {
    ...initialResponse,
  },
  configState: {
    fetchState: {
      ...initialResponse,
      data: {
        configurations: [
          {
            clusterName: ALL_CLUSTER_NAMES_SELECTOR,
            configuration: initialSettingsConfiguration,
          },
        ],
      },
    },
    updateState: {
      loading: false,
      error: null,
    },
  },
  setConfigState: () => undefined,
  setConfigFetchState: () => undefined,
  setConfigUpdateState: () => undefined,
  setRightSizingRecommendationModal: () => undefined,
  lastVisitTime: new Date().getTime(),
  setLastVisitTime: () => undefined,
  rightSizingPaginatedTableState: initialPaginatedTableResponse,
  setRightSizingPaginatedTableState: () => undefined,
};
