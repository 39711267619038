import { useUserMetadata } from "./useUserMetadata/useUserMetadata";
import useAccount from "./useAccount/useAccount";

export const useAccountName = (): string => {
  const { accountId, accountName } = useUserMetadata();
  // accountName should be set in the user metadata. If it is we set an empty string to the useAccount hook
  // to avoid fetching the account by id.
  const account = useAccount(accountName ? "" : accountId);
  return account?.name ?? accountName;
};
