import React, { useEffect } from "react";
import { muiColors, theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import {
  issueSelector,
  komodorServiceSelector,
  reviewMemoryAllocationSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { ConfigureButton } from "../../../Actions/buttons/ConfigureButton";
import { getButton } from "../../../Actions/common/getActionButtonStyle";
import Resource from "../../../ResourceView/resources";
import { AvailableActions } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { Metrics } from "../../../Metrics/Metrics";
import { useIssueMetricsEvents } from "../useCorrelatedMetrics";
import { MetricsGraphType } from "../../../Metrics/types";
import { useMetricsDefaultAggregationType } from "../../metricsHooks";
import { useScrollTracking } from "../common/useScrollTracking";
import { MetricsContainer } from "../common/styles";
import { MetricsTitle } from "../common/MetricsTitle";

import {
  ContainerResources,
  ContainerResourcesSpaced,
  ResourceGrid,
  ResourceGridDivider,
  ResourceGridTitle,
} from "./styles";
import { ContainerData } from "./ReviewMemoryAllocationDataFetch";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const RequestsTooltipMessage =
  "Requests ensure that the container will only be scheduled on Nodes with enough capacity to accommodate it`s resource Requests.";
const LimitsTooltipMessage =
  "Resource limits define the maximum amount of CPU and memory a container can consume. Containers exceeding limits get throttled (CPU) or terminated (Memory).";

const ReviewMemoryAllocationComponent: React.FC = () => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const issue = useInvestigationModeStore(issueSelector);
  const reviewMemoryAllocationData = useInvestigationModeStore(
    reviewMemoryAllocationSelector
  );
  const resource = useInvestigationModeStore(komodorServiceSelector);

  const { nextStep, goToNextStep } = useNextStep();
  const isDeletedResource = resource?.isDeleted || resource?.inactive;
  const elementRef = useScrollTracking<HTMLDivElement>();

  const isLoading = !issue || !resource || !reviewMemoryAllocationData;

  useEffect(() => {
    reportLoadingState("reviewMemoryAllocationData", isLoading);
  }, [isLoading, reportLoadingState]);

  if (isLoading) {
    return null;
  }
  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          Review your memory allocation needs
        </Typography>
        <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
          Check to see if your application actually needs more memory. If an
          application is facing increased load, it may require more memory than
          was originally provided or if it`s mission critical, it may need a
          higher guarantee of memory.
        </Typography>
      </StepHeader>
      <StepContent ref={elementRef}>
        {isDeletedResource ? (
          <Typography variant="h4">Resource is deleted</Typography>
        ) : (
          reviewMemoryAllocationData.containersData.map((container, index) => (
            <ContainerRow
              key={index}
              container={container}
              resource={resource}
              issueTime={issue.eventTime}
              isMetricsSupported={reviewMemoryAllocationData.isMetricsSupported}
            />
          ))
        )}
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.ReviewMemoryAllocation.SkipStepButton
            }
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};

export const ReviewMemoryAllocation: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.investigationModeMemoryAllocation,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <ReviewMemoryAllocationComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};

const ContainerRow: React.FC<{
  container: ContainerData;
  resource: Resource;
  issueTime: Date;
  isMetricsSupported: boolean;
}> = ({ container, resource, issueTime, isMetricsSupported }) => {
  const issueMetricsEvents = useIssueMetricsEvents(issueTime);
  const metricsAggType = useMetricsDefaultAggregationType();

  return (
    <MetricsContainer>
      <ContainerResourcesSpaced>
        <div>
          <MetricsTitle
            kind="container"
            metadata={[
              {
                title: "pod",
                value: container.podName,
              },
              {
                title: "container",
                value: container.containerInfo.name,
              },
            ]}
          />
          <br />
          {resource?.actions?.includes(AvailableActions.Configure) && (
            <ConfigureButton
              resource={resource}
              Button={getButton(AvailableActions.Configure, false, undefined, {
                text: "Change memory allocation",
                buttonVariant: "secondary",
                actionItemVariant: "default",
              })}
              defaultContainerName={container.containerInfo.name}
            />
          )}
        </div>
        <ContainerResources>
          <ResourceGrid>
            <ResourceGridTitle>
              <Typography
                variant="overline"
                color={muiColors.gray[600]}
                fontSize="12px"
                lineHeight="19.2px"
                fontWeight={500}
              >
                REQUESTS
              </Typography>
              <Tooltip arrow={false} title={RequestsTooltipMessage}>
                <InfoOutlined color={"disabled"} fontSize="inherit" />
              </Tooltip>
            </ResourceGridTitle>
            <ResourceGridDivider />
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="12px"
              color={muiColors.gray[600]}
            >
              CPU (milliCPU)
            </Typography>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="12px"
              color={muiColors.gray[600]}
            >
              Memory (MiB)
            </Typography>
            <Typography variant="body1">
              {container.containerInfo.resources?.requests?.["cpu"] ??
                "Not set"}
            </Typography>
            <Typography variant="body1">
              {container.containerInfo.resources?.requests?.["memory"] ??
                "Not set"}
            </Typography>
          </ResourceGrid>
          <ResourceGrid>
            <ResourceGridTitle>
              <Typography
                variant="overline"
                color={muiColors.gray[600]}
                fontWeight={500}
                fontSize="12px"
                lineHeight="19.2px"
              >
                Limits
              </Typography>
              <Tooltip arrow={false} title={LimitsTooltipMessage}>
                <InfoOutlined color={"disabled"} fontSize="inherit" />
              </Tooltip>
            </ResourceGridTitle>
            <ResourceGridDivider />
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="12px"
              color={muiColors.gray[600]}
            >
              CPU (milliCPU)
            </Typography>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="12px"
              color={muiColors.gray[600]}
            >
              Memory (MiB)
            </Typography>
            <Typography variant="body1">
              {container.containerInfo.resources?.limits?.["cpu"] ?? "Not set"}
            </Typography>
            <Typography variant="body1">
              {container.containerInfo.resources?.limits?.["memory"] ??
                "Not set"}
            </Typography>
          </ResourceGrid>
        </ContainerResources>
      </ContainerResourcesSpaced>
      {container.containerMetrics && (
        <Metrics
          isMetricsSupported={isMetricsSupported}
          endTimestamp={container.metricsEndTime}
          metrics={container.containerMetrics}
          events={issueMetricsEvents}
          graphType={MetricsGraphType.CONTAINER}
          aggregationType={metricsAggType}
          paddingInMinutes={10}
        />
      )}
    </MetricsContainer>
  );
};
