import React from "react";

import { FeaturePreviewPage } from "@/shared/components/FeaturePreviewPage";
import externalDnsPreview from "@/components/k8sAddons/addons/externalDns/assets/externalDnsPreview.png";

export const ExternalDNSEmptyState: React.FC = () => {
  return (
    <FeaturePreviewPage
      title="External DNS"
      bannerTitle="No External DNS detected"
      bannerText="No External DNS was detected on your clusters. Once deployed, you'll gain operational data and insights on this screen!"
      bullets={[
        "Seamlessly integrate External DNS across your clusters.",
        "Gain unified visibility into all your External DNS instances, managed records, and affected services.",
        "Easily identify and troubleshoot unsynced records.",
      ]}
      img={
        <img src={externalDnsPreview} width="99%" alt="External DNS preview" />
      }
    >
      Gain comprehensive insights into External DNS with Komodor, enabling
      effective DNS management across your clusters. Keep your DNS records
      consistently updated and synchronized with providers, while effortlessly
      monitoring performance and troubleshooting synchronization issues to
      prevent disruptions.
    </FeaturePreviewPage>
  );
};
