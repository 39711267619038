import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_TIME_RANGE,
} from "@/pages/organization-settings/account/AuditPage/constants";
import { Context } from "@/pages/organization-settings/account/AuditPage/types";

export const auditLogsInitialState: Context = {
  status: undefined,
  actions: undefined,
  entityTypes: undefined,
  operations: undefined,
  userIds: undefined,
  entityName: "",
  dateRange: DEFAULT_TIME_RANGE,
  pageSize: DEFAULT_PAGE_SIZE,
  page: 0,
  sort: "-eventTime",
};
