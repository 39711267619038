import React from "react";
import ShowChart from "@mui/icons-material/ShowChart";
import { muiColors } from "@komodorio/design-system";

import { SectionAccordion } from "@/components/ClustersView/ClusterOverview/styles";
import { SectionAccordionSummary } from "@/components/ClustersView/ClusterOverview/components/SectionAccordionSummary";
import { INVENTORY_SECTION_TITLE } from "@/components/ClustersView/ClusterOverview/components/InventorySection/constants";

const WORKSPACE_INVENTORY_SECTION_DISABLED_MESSAGE =
  "The data isn't available for this type of workspace";

export const WorkspaceInventorySection: React.FC = () => {
  const isExpanded = false;
  const isDisabled = true;

  return (
    <SectionAccordion expanded={isExpanded} disabled={isDisabled}>
      <SectionAccordionSummary
        title={INVENTORY_SECTION_TITLE}
        icon={<ShowChart sx={{ color: muiColors.gray[500] }} />}
        isExpanded={isExpanded}
        isDisabled={isDisabled}
        disabledMessage={WORKSPACE_INVENTORY_SECTION_DISABLED_MESSAGE}
      />
    </SectionAccordion>
  );
};
