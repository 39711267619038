import { palette } from "@komodorio/design-system";
import Drawer from "@mui/material/Drawer";
import React, { memo } from "react";
import styled from "styled-components";

import { PodExecDrawerContent } from "./PodExecDrawerContent";
import { PodExecDrawerHeader } from "./PodExecDrawerHeader";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import {
  ReportLoadingTimeProps,
  ViewOptions,
} from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";

interface PodExecDrawerProps {
  open: boolean;
  onClose: () => void;
  onMinimize: () => void;
  containerName: string;
  namespace: string;
  podName: string;
  agentId: string;
  cluster: string;
  identifier: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${palette.white[0]};
`;

const datadogViewOptions: ViewOptions = {
  name: DatadogViewNamesEnum.podExecAction,
  context: {
    feTeam: "operate",
    beTeam: "operate",
  },
};

const reportLoadingTimeProps: ReportLoadingTimeProps = {
  skipDrawerStackCheck: true,
};

export const PodExecDrawer: React.FC<PodExecDrawerProps> = memo(
  ({
    open,
    onClose,
    containerName,
    onMinimize,
    podName,
    namespace,
    agentId,
    cluster,
    identifier,
  }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={onMinimize}
      elevation={5}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{ sx: { width: "60%" } }}
      data-e2e-selector="drawer"
      aria-label="Pod exec drawer"
      aria-expanded={open}
    >
      <Container>
        <PodExecDrawerHeader
          podName={podName}
          containerName={containerName}
          onClose={onClose}
          onMinimize={onMinimize}
        />
        <DatadogReportLoadingTimeContextProvider
          viewOptions={datadogViewOptions}
          reportLoadingTimeProps={reportLoadingTimeProps}
        >
          <PodExecDrawerContent
            containerName={containerName}
            podName={podName}
            namespace={namespace}
            agentId={agentId}
            cluster={cluster}
            identifier={identifier}
          />
        </DatadogReportLoadingTimeContextProvider>
      </Container>
    </Drawer>
  )
);
