import { parseISO } from "date-fns";
import { useMemo } from "react";

import { DeployStatusEnum } from "../../common/EventGroup/deployEvent/getDeployStatus";
import { KubernetesServiceTypeEnum } from "../../../shared/types/ServiceVertex";
import WorkflowCheck from "../../monitorsView/workflowRunView/checks";
import { WorkloadInfoOutput } from "../../monitorsView/workflowRunView/checks/availability/types";
import { AvailabilityCheckType } from "../../monitorsView/workflowRunView/checks/useWorkflowsChecks";

export const getTimeSinceLatestDeploy = (
  output: WorkloadInfoOutput | undefined,
  eventTime?: Date
): number | null => {
  const deployTime =
    output?.latestDeploy?.endEventTime ?? output?.latestDeploy?.eventTime;
  const deployDate = deployTime ? parseISO(deployTime) : null;
  const durationInMinutes =
    deployDate && eventTime
      ? Math.abs((eventTime.getTime() - deployDate.getTime()) / 60000)
      : null;
  return durationInMinutes;
};

const isLatestDeployCorrelated = (
  output?: WorkloadInfoOutput,
  eventTime?: Date
): boolean => {
  const PROBLEMATIC_DEPLOY_TIME_MINUTES = 20;
  const latestDeployTime = getTimeSinceLatestDeploy(output, eventTime);
  if (!latestDeployTime) {
    return false;
  }
  return latestDeployTime < PROBLEMATIC_DEPLOY_TIME_MINUTES;
};

const changeResourcesReasons = ["OOMKilled", "137", "139"];

const useShouldChangeResourcesForAvailability = (reasons: string[]) => {
  return useMemo(() => {
    return changeResourcesReasons.some((r) =>
      reasons.some((reason) => reason.includes(r))
    );
  }, [reasons]);
};

const imageRelatedErrors = ["ErrImagePull", "ImagePullBackOff"];
const miscErrors = ["OOMKilled", "137", "NonZeroExitCode - Exit Code: 1"];
const useShouldShowRollbackForAvailability = (
  reasons: string[],
  allChecks: WorkflowCheck[],
  createdAt?: Date | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const deployCheck = allChecks.find(
    (c) => c.type === AvailabilityCheckType.WorkloadInfo
  );
  const output = deployCheck?.output as WorkloadInfoOutput | undefined;
  const correlatedDeployExist = isLatestDeployCorrelated(output, createdAt);
  const isFirstGeneration = output?.latestDeploy.generation === 1;
  return useMemo(() => {
    if (isFirstGeneration) {
      return false;
    }
    const isImageRelatedErrors = imageRelatedErrors.some((el) =>
      reasons.some((r) => r.includes(el))
    );
    const isMiscErrors = miscErrors.some((el) =>
      reasons.some((r) => r.includes(el))
    );
    return correlatedDeployExist && (isImageRelatedErrors || isMiscErrors);
  }, [isFirstGeneration, reasons, correlatedDeployExist]);
};
const useShouldChangeResourcesForDeploy = (
  reasons: string[],
  newSuggestedActions: boolean
) => {
  return useMemo(() => {
    if (newSuggestedActions) {
      return false;
    }
    return changeResourcesReasons.some((r) =>
      reasons.some((reason) => reason.includes(r))
    );
  }, [reasons, newSuggestedActions]);
};

interface AvailabilityActionProps {
  shouldShowRollback: boolean;
  shouldShowChangeResources: boolean;
}
export const useAvailabilityActions = (
  reasons: string[],
  allChecks: WorkflowCheck[],
  createdAt?: Date | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): AvailabilityActionProps => {
  return {
    shouldShowRollback: useShouldShowRollbackForAvailability(
      reasons,
      allChecks,
      createdAt
    ),
    shouldShowChangeResources: useShouldChangeResourcesForAvailability(reasons),
  };
};

interface DeployActionProps {
  shouldShowRollback: boolean;
  shouldShowChangeResources: boolean;
}
export const useDeployActions = (
  deployStatus: string,
  reasons: string[],
  newSuggestedActions: boolean,
  deployGeneration: number | null | undefined,
  kind?: string | null
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): DeployActionProps => {
  const isFirstGeneration = deployGeneration === 1;

  const shouldShowRollback = useMemo(() => {
    return (
      !isFirstGeneration &&
      deployStatus === DeployStatusEnum.Failed &&
      (kind === KubernetesServiceTypeEnum.Deployment ||
        kind === KubernetesServiceTypeEnum.StatefulSet ||
        kind === KubernetesServiceTypeEnum.DaemonSet)
    );
  }, [deployStatus, isFirstGeneration, kind]);
  return {
    shouldShowRollback,
    shouldShowChangeResources: useShouldChangeResourcesForDeploy(
      reasons,
      newSuggestedActions
    ),
  };
};
