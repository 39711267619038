import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { LightTooltip } from "../../index";
import { get } from "lodash";

import {
  calculateFittingItems,
  getExtraLabel,
} from "../utils/calcFittingItems";

const PADDING_WIDTH = 10;
const DEFAULT_CELL_WIDTH = 200;

interface GridTagsCellProps<T> {
  items: T[];
  textPath: string;
  itemMapper: (item: T) => { key: string; label: string };
  labelPrefix: string;
  cellWidth?: number;
}

export function TagsCell<T>({
  items,
  textPath,
  itemMapper,
  labelPrefix,
  cellWidth = DEFAULT_CELL_WIDTH,
}: GridTagsCellProps<T>) {
  const fitItemsAmount = calculateFittingItems({
    items,
    textPath,
    cellWidth: cellWidth - PADDING_WIDTH * 2,
  });

  const displayedItems = items.slice(0, fitItemsAmount) ?? [];
  const hiddenItems = items.slice(fitItemsAmount) ?? [];
  const tooltipContent = hiddenItems
    .map((item) => get(item, textPath, item))
    .join("\n");
  const itemsLeft = hiddenItems.length;
  const extraLabel = getExtraLabel(labelPrefix);

  return (
    <Grid container columns={3} columnGap={1}>
      {displayedItems.map((item) => {
        const { key, label } = itemMapper(item);
        return (
          <Chip
            key={key}
            aria-label={`${labelPrefix}-${label}`}
            label={label}
          />
        );
      })}
      {itemsLeft > 0 && (
        <LightTooltip
          title={tooltipContent}
          componentsProps={{ tooltip: { sx: { whiteSpace: "pre-line" } } }}
        >
          <Chip
            key={extraLabel}
            aria-label={extraLabel}
            label={`+${itemsLeft}`}
          />
        </LightTooltip>
      )}
    </Grid>
  );
}
