import { useMemo } from "react";
import { compact } from "lodash";

import { useDataForFlag } from "../../../../shared/hooks/useDataForFlag";
import { TimeWindow } from "../../../../shared/types/TimeWindow";
import { useResourcesAPIGet } from "../../../../shared/hooks/resources-api/client";
import {
  NODES_EVENTS_BY_AFFECTED_RESOURCES,
  NODES_EVENTS_ID,
} from "../../../../shared/hooks/resources-api/requestResponseMaps";
import {
  EventsApiApiV1NodesEventsSearchGetRequest,
  NodeEventsResponseDataInner,
} from "../../../../generated/resourcesApi";
import { useGetNodeEventsByAffectedResources } from "../../../../shared/hooks/resources-api/client/events/useGetNodeEventsByAffectedResources";
import { useGetNodeEventsByIds } from "../../../../shared/hooks/resources-api/client/events/useGetNodeEventsByIds";
import { useGetNodeEventsSearch } from "../../../../shared/hooks/resources-api/client/events/useGetNodeEventsSearch";

type EventAction = "Create" | "Update" | "Delete";

export const useNodeCreatedEvents = (
  timeWindow: Pick<TimeWindow, "start" | "end">,
  showNodeCreatedEvents: unknown
): NodeEventsResponseDataInner[] | null => {
  const actions = useMemo<EventAction[]>(() => ["Create"], []);
  const data = useNodeImpactfulEvents(
    timeWindow,
    showNodeCreatedEvents,
    actions
  );
  return useDataForFlag(showNodeCreatedEvents, data);
};

export const useNodeTerminatedEvents = (
  timeWindow: Pick<TimeWindow, "start" | "end">,
  showNodeTerminatedEvents: unknown
): NodeEventsResponseDataInner[] | null => {
  const actions = useMemo<EventAction[]>(() => ["Delete"], []);
  const data = useNodeImpactfulEvents(
    timeWindow,
    showNodeTerminatedEvents,
    actions
  );

  return useDataForFlag(showNodeTerminatedEvents, data);
};

const fieldsFromAPI: EventsApiApiV1NodesEventsSearchGetRequest["fields"] = [
  "id",
  "nodeName",
  "eventTime",
  "ready",
  "readyReason",
  "readyMessage",
  "memoryPressure",
  "diskPressure",
  "lastHeartbeatTime",
  "capacity",
  "isDeleted",
  "services",
  "clusterName",
  "action",
  "nodeCreationTime",
  "region",
  "zone",
  "instanceType",
  "allocationType",
  "os",
  "cloudProvider",
  "terminationReason",
  "event_node_enrichment",
];

export const useNodeImpactfulEvents = (
  timeWindow: Pick<TimeWindow, "start" | "end">,
  flag: unknown,
  actions?: EventAction[]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): NodeEventsResponseDataInner[] | undefined => {
  const { data } = useGetNodeEventsSearch(
    {
      fromEpoch: timeWindow.start.getTime().toString(),
      toEpoch: timeWindow.end.getTime().toString(),
      actions,
      fields: fieldsFromAPI,
    },
    { enabled: flag === true }
  );
  return useMemo(() => data?.data, [data]);
};

export const useNodeEventsByIds = (
  eventIds: string[]
): [NodeEventsResponseDataInner[], boolean] => {
  const { data, isFetching } = useGetNodeEventsByIds(
    {
      ids: eventIds,
      fields: fieldsFromAPI,
    },
    eventIds?.length > 0
  );

  return useMemo(
    () => [data?.data ?? [], isFetching],
    [data?.data, isFetching]
  );
};

export const useNodeEventById = (
  eventId: string
): NodeEventsResponseDataInner | undefined => {
  const { data } = useResourcesAPIGet(
    NODES_EVENTS_ID,
    {
      id: eventId,
      fields: fieldsFromAPI,
    },
    !eventId
  );
  return useMemo(() => data?.data?.[0], [data]);
};

export const useNodeTerminationsByOwnerKomodorUidsFromAPI = (
  ownerKomodorUids: string[],
  timeWindow?: Pick<TimeWindow, "start" | "end">
): [NodeEventsResponseDataInner[] | undefined, boolean] => {
  const { data, loading } = useResourcesAPIGet(
    NODES_EVENTS_BY_AFFECTED_RESOURCES,
    {
      komodorUids: ownerKomodorUids,
      fields: fieldsFromAPI,
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
    },
    !ownerKomodorUids.length
  );

  return useMemo(() => [data?.data, loading], [data, loading]);
};

export const useNodeTerminationsByOwnerKomodorUidsFromAPIQuery = (
  ownerKomodorUids: string[],
  timeWindow?: Pick<TimeWindow, "start" | "end">
): [NodeEventsResponseDataInner[] | undefined, boolean, boolean] => {
  const { data, isLoading, isFetching } = useGetNodeEventsByAffectedResources(
    {
      komodorUids: ownerKomodorUids,
      fields: fieldsFromAPI,
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
    },
    compact(ownerKomodorUids).length > 0
  );

  return useMemo(
    () => [data?.data, isLoading, isFetching],
    [data, isLoading, isFetching]
  );
};
