import React from "react";

import { UserSettingsWrapper } from "@/pages/user-settings/UserSettingsWrapper";
import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { PermissionsTabsContainer } from "@/components/Settings/Users/Permissions/PermissionsTabsContainer";

export const MyPermissionsPage = () => {
  const { userId } = useUserMetadata();

  const { data: user, error } = useGetUserById({ id: userId });

  return (
    <UserSettingsWrapper title="My Permissions">
      <PermissionsTabsContainer
        user={user}
        error={error?.toString()}
        isAdminView={false}
      />
    </UserSettingsWrapper>
  );
};
