import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import { AriaLabels } from "../../../../../shared/config/ariaLabels";

export interface PodPhaseTableProps {
  rows: JSX.Element[];
  rowsPerPage: number;
}
export const PodPhasesTable: React.FC<PodPhaseTableProps> = ({
  rows,
  rowsPerPage,
}) => {
  const [page, setPage] = React.useState(0);

  const handleChangePage = (
    _event: unknown,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  if (!rows || rows.length === 0) {
    return null;
  }

  return (
    <div>
      <TableContainer aria-label={AriaLabels.PodPhases.PodEvents.PhasesTable}>
        <Table>
          <TableBody>
            {rows?.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => <TableRow key={row.key}>{row}</TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      {rows?.length > rowsPerPage && (
        <TablePagination
          aria-label={AriaLabels.PodPhases.PodEvents.PhasesTablePagination}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </div>
  );
};
