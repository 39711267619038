import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import yaml from "js-yaml";
import ReactDiffViewer from "react-diff-viewer";
import { diff, flattenChangeset } from "json-diff-ts";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import HelmChart from "../../helmChart";
import { useHelmChartInfo } from "../../hooks/useHelmChartInfo";
import { Panel, Selectors } from "../ManifestsTab";
import { LinesLoader } from "../../../../common/loaders/Line";
import { CopyButtonsContainer, HelmTabContainer } from "../../drawers/styles";
import { diffStyles } from "../../../../common/ObjectDiff";

import RevisionComparer from "./RevisionComparer";
import ShowChangesSwitcher from "./ChangesOnlySwitcher";
import { CopyButton } from "./CopyButton";
import { EmptyState } from "./EmptyData";

import { LazyEditor } from "@/components/common/LazyEditor";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { ViewOptions } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

interface GenericViewCompareProps {
  compareType: CompareType;
  resource: HelmChart;
  datadogViewName: DatadogViewNamesEnum;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum CompareType {
  Values = "values",
  Notes = "notes",
}

export const GenericViewCompare: React.FC<GenericViewCompareProps> = (
  props
) => {
  const datadogViewOptions = useMemo(
    (): ViewOptions => ({
      name: props.datadogViewName,
      context: {
        feTeam: "operate",
        beTeam: "operate",
      },
    }),
    [props.datadogViewName]
  );
  return (
    <DatadogReportLoadingTimeContextProvider viewOptions={datadogViewOptions}>
      <GenericViewCompareConsumer {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};

const GenericViewCompareConsumer: React.FC<GenericViewCompareProps> = ({
  compareType,
  resource,
}) => {
  const [compareToRevision, setCompareToRevision] = useState<{
    revision: string;
    id: string;
  }>();
  const [showChangesOnly, setShowChangesOnly] = useState(true);
  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();

  const divRef = useRef<HTMLDivElement>(null);
  setMainContainerDivRef(divRef);

  const onRevisionChange = useCallback(
    (value?: string) => {
      setCompareToRevision(
        resource.revisions.find((r) => r.revision === value)
      );
    },
    [resource.revisions]
  );
  const { data: compareChart, isFetching: isFetchingInfo } = useHelmChartInfo(
    resource.agentId,
    resource.cluster,
    resource.namespace,
    compareToRevision?.id ?? ""
  );

  const onFetchingStateChange = useCallback(
    (value: boolean) => {
      reportLoadingState(`GenericViewCompare`, value);
    },
    [reportLoadingState]
  );

  useEffect(() => {
    onFetchingStateChange(isFetchingInfo);
  }, [isFetchingInfo, onFetchingStateChange]);

  const yamlsData = useMemo(() => {
    switch (compareType) {
      case CompareType.Values: {
        return {
          currentYaml: yaml.dump(resource?.values),
          compareYaml: yaml.dump(compareChart?.values),
        };
      }
      case CompareType.Notes: {
        return {
          currentYaml: yaml.dump(resource?.notes),
          compareYaml: yaml.dump(compareChart?.notes),
        };
      }
      default: {
        return {
          currentYaml: yaml.dump(resource?.values),
          compareYaml: yaml.dump(compareChart?.values),
        };
      }
    }
  }, [
    compareChart?.notes,
    compareChart?.values,
    compareType,
    resource?.notes,
    resource?.values,
  ]);

  const hasChanges = useMemo(() => {
    const changes = flattenChangeset(
      diff(yamlsData.currentYaml, yamlsData.compareYaml)
    );

    if (showChangesOnly) return changes.length > 0;
    return true;
  }, [showChangesOnly, yamlsData.compareYaml, yamlsData.currentYaml]);

  return (
    <div ref={divRef}>
      {(compareType === CompareType.Notes && !!resource?.notes) ||
      (compareType === CompareType.Values &&
        yamlsData.currentYaml.length > 3) ? (
        <HelmTabContainer>
          {resource.revisions.length > 1 && (
            <Selectors>
              <RevisionComparer
                revisions={resource.revisions.map((r) => r.revision)}
                currentRevision={resource.currentRevision}
                onRevisionChange={onRevisionChange}
              />
              <div />
              <CopyButtonsContainer>
                {compareToRevision === undefined && (
                  <CopyButton
                    value={yamlsData?.currentYaml}
                    tooltipText={`Copy ${compareType} to clipboard`}
                  />
                )}
                {compareToRevision !== undefined && (
                  <ShowChangesSwitcher
                    showChangesOnly={showChangesOnly}
                    setShowChangesOnly={setShowChangesOnly}
                  />
                )}
              </CopyButtonsContainer>
            </Selectors>
          )}
          {compareToRevision ? (
            isFetchingInfo ? (
              <LinesLoader />
            ) : hasChanges ? (
              <ReactDiffViewer
                oldValue={yamlsData.currentYaml}
                newValue={yamlsData.compareYaml}
                rightTitle={
                  <CopyButtonsContainer>
                    <Typography
                      variant="title"
                      size="medium"
                      color={palette.gray[800]}
                    >
                      Revision #<b>{compareToRevision.revision}</b>
                    </Typography>
                    <CopyButton
                      value={yamlsData.currentYaml}
                      tooltipText={`Copy ${compareType} to clipboard`}
                    />
                  </CopyButtonsContainer>
                }
                leftTitle={
                  <CopyButtonsContainer>
                    <Typography
                      variant="title"
                      size="medium"
                      color={palette.gray[800]}
                    >
                      Revision #<b>{resource.currentRevision}</b>
                    </Typography>
                    <CopyButton
                      value={yamlsData.compareYaml}
                      tooltipText={`Copy ${compareType} to clipboard`}
                    />
                  </CopyButtonsContainer>
                }
                splitView
                disableWordDiff
                showDiffOnly={showChangesOnly}
                styles={diffStyles}
              />
            ) : (
              <EmptyState text={"There are no changes to show"} />
            )
          ) : (
            <Panel>
              <LazyEditor
                style={{ borderColor: "transparent" }}
                width="100%"
                readOnly
                value={yamlsData.currentYaml.replace(/\n$/, "")}
                maxLines={Infinity}
              />
            </Panel>
          )}
        </HelmTabContainer>
      ) : (
        <EmptyState text={`There are no ${compareType} to show`} />
      )}
    </div>
  );
};
