import React from "react";
import Typography from "@mui/material/Typography";
import { muiTheme } from "@komodorio/design-system";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { useDateFormatter } from "@/shared/hooks/useDateFormatter";

export const CLASS_NAME = "komodor_audit-drawer-key-value";

export const KeyValue: React.FC<{
  title: string;
  value: string;
  valueRenderer: (
    value: string,
    format?: (raw: Date) => string
  ) => React.ReactNode;
}> = ({ title, value, valueRenderer }) => {
  const { format } = useDateFormatter({ timeZoneName: undefined });
  return (
    <Stack direction="row" className={CLASS_NAME}>
      <Box sx={{ width: "136px", flexShrink: 0 }}>
        <Typography variant="body2" color={muiTheme.palette.text.secondary}>
          {title}
        </Typography>
      </Box>
      <Box overflow="hidden">{valueRenderer(value, format)}</Box>
    </Stack>
  );
};
