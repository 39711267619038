import { useMemo, useEffect, RefObject } from "react";

import { useServicesComparisonContext } from "./context/useServicesComparisonContext";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

interface DriftDatadogReportTimeProps {
  component: string;
  isLoading: boolean;
  divRef: RefObject<HTMLDivElement>;
}
export const useDriftDatadogReportTime = ({
  component,
  isLoading,
  divRef,
}: DriftDatadogReportTimeProps) => {
  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();

  setMainContainerDivRef(divRef);

  const { baselineServiceId } = useServicesComparisonContext();
  const isFetchingEnabled = useMemo(
    () => baselineServiceId !== null,
    [baselineServiceId]
  );
  useEffect(() => {
    if (!isFetchingEnabled) return;
    reportLoadingState(component, isLoading);
  }, [reportLoadingState, isFetchingEnabled, isLoading, component]);
};
