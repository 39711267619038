/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { groupBy } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import { AccountPlan } from "komodor-types";

import KubernetesInstallationModal from "../integrations/installation/kubernetes/KubernetesInstallationModal";
import Select from "../common/controls/Select";
import Input from "../common/controls/Input";
import { H2, h4LightStyle, textStyle } from "../common/typography";
import { dispatchEvent } from "../../shared/hooks/analytics";
import {
  useUserMetadata,
  useIsTrialEnded,
} from "../../shared/hooks/useUserMetadata/useUserMetadata";
import { useBatches } from "../../shared/hooks/useBatches";
import IntersectionDetector from "../common/IntersectionDetector";
import JobTile from "../jobsView/JobTile";
import FilterBar from "../../shared/components/FilterBar/FilterBar";
import Selected from "../../shared/components/FilterBar/Interfaces/Selected";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { useIsAgentConnected } from "../../shared/hooks/useIsAgentConnected";
import { useHasPermissions } from "../../shared/hooks/useUserMetadata/rbac";
import { ServiceInfo } from "../../shared/types/ServiceInfo";
import {
  EXPLORE_SERVICES_FILTER_TERM_PARAM_KEY,
  EXPLORE_SERVICES_SORT_PARAM_KEY,
  FILTERS_BAR_FILTERS_PARAM_KEY,
} from "../../shared/config/urlSearchParamsKeys";
import { useFiltersList } from "../../shared/components/FilterBar/hooks/useFiltersList";
import useCheckedServices from "../../shared/components/FilterBar/hooks/useCheckedServices";
import useGenerateCategories from "../../shared/components/FilterBar/hooks/useGenerateCategories";
import useCheckedFilters from "../../shared/components/FilterBar/hooks/useCheckedFilters";
import { TrialEndedModal } from "../trialEndedModal/TrialEndedModal";
import {
  useFreemiumModals,
  useFreemiumModalsUpdate,
  freemiumModalNames,
} from "../../shared/hooks/useFreemiumModals";
import useTiersVisible from "../Freemium/useTiersVisible";
import isElectronContext from "../../shared/context/electron/isElectronContext";
import { K8S_INTEGRATION_MODAL_INITIAL_OPEN } from "../../constants";

import {
  JobsSortStrategy,
  ServicesSortStrategy,
  useSortedServicesOrJobs,
  jobsStrategyNames,
  servicesStrategyNames,
} from "./service-sort-hook";
import { useTextFilteredServices } from "./service-filter-hook";
import useAllServicesAttributes from "./servicesAttributes/useAllServicesAttributes";
import ServiceTile from "./ServiceTile";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ServicesLoader } from "./ServicesLoader";
import { AgentNotInstalledState } from "./AgentNotInstalledState";
import { KomodorServicesEmptyState } from "./KomodorServicesEmptyState";
import { KomodorServiceType } from "./types";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";

export const Container = styled.div``;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 15rem auto;
  grid-template-rows: 4rem auto;
  grid-gap: 0 1.5rem;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
  color: #3d4048;
`;

export const Sidebar = styled.div`
  grid-area: sidebar;
  align-self: start;
`;

export const StyledInput = styled(Input)`
  background: #f8f8f8;
  ${textStyle}
`;

export const ServicesGrid = styled.div<{ tileWidth?: string }>`
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15.625rem, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 1rem;
  background-color: var(--window-bg);
`;

export const ServicesHeaderArea = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ServicesSortGrid = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto auto;
  align-items: center;
  label {
    ${h4LightStyle}
  }
`;

export const GroupTitle = styled.div`
  grid-column: 1 / -1;
  border-bottom: 1px solid #bcc0c8;
  padding-bottom: 0.5rem;
  margin-bottom: -0.5rem;
  ${H2} {
    font-size: 1rem;
  }
`;

const newServiceTile = (
  service: ServiceInfo,
  filterTerm: string,
  type: KomodorServiceType
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) =>
  type === KomodorServiceType.service ? (
    <ServiceTile key={service.id} service={service} filterTerm={filterTerm} />
  ) : (
    <JobTile key={service.id} job={service} filterTerm={filterTerm} />
  );

const ExploreKomodorServicesComponent: React.FC<{
  komodorServices: ServiceInfo[] | undefined;
  type: KomodorServiceType;
}> = ({ komodorServices, type }) => {
  const { reportLoadingState, triggerStartView } =
    useDatadogReportLoadingTimeContext();
  const { accountDisabled, accountPlan } = useUserMetadata();
  const isTrialEnded = useIsTrialEnded();

  const [selected, setSelected] = useStringifiedStateInSearchParams<Selected>(
    FILTERS_BAR_FILTERS_PARAM_KEY
  );

  const [filterTerm, setFilterTerm] = useStateInSearchParams(
    EXPLORE_SERVICES_FILTER_TERM_PARAM_KEY
  );
  const [localFilterTerm, setLocalFilterTerm] = useState<string | null>(
    filterTerm
  );
  useEffect(() => {
    setLocalFilterTerm(filterTerm);
  }, [filterTerm]);

  const [debouncedFilterTerm] = useDebounce(localFilterTerm, 250);
  useEffect(() => {
    setFilterTerm(debouncedFilterTerm, true);
  }, [debouncedFilterTerm, setFilterTerm]);

  const [sortStrategy, setSortStrategy] = useStateInSearchParams(
    EXPLORE_SERVICES_SORT_PARAM_KEY
  );

  const isJobs = type === KomodorServiceType.job;

  const sortedServices = useSortedServicesOrJobs(
    komodorServices,
    isJobs
      ? (sortStrategy as JobsSortStrategy)
      : (sortStrategy as ServicesSortStrategy),
    isJobs
  );

  const textFilteredServices = useTextFilteredServices(
    sortedServices,
    filterTerm
  );

  const allServicesAttributes = useAllServicesAttributes();

  const serviceAttributesByService = useMemo(() => {
    return groupBy(allServicesAttributes, (attr) => attr.serviceId);
  }, [allServicesAttributes]);

  const [filtersList, refreshServiceAttsConfig] = useFiltersList(
    komodorServices,
    serviceAttributesByService,
    isJobs
  );

  const checkedFilters = useCheckedFilters();

  const { checkedServices } = useCheckedServices(
    filtersList,
    textFilteredServices,
    checkedFilters
  );

  const isInitialLoad = useRef(true);
  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      reportLoadingState("servicesData", !checkedServices);
      !!checkedServices && triggerStartView();
    }
  }, [checkedServices, reportLoadingState, triggerStartView]);

  useEffect(() => {
    reportLoadingState("servicesData", !checkedServices);
  }, [checkedServices, reportLoadingState]);

  const { limited, loadMore, resetBatches } = useBatches(
    24,
    checkedServices ?? []
  );

  const generatedCategories = useGenerateCategories(
    checkedServices,
    filtersList,
    selected,
    setSelected
  );

  const tileListEmpty = komodorServices ? !komodorServices.length : null;
  useEffect(() => {
    if (tileListEmpty === null) return;
    dispatchEvent(
      isJobs
        ? AnalyticEvents.ExploreServicesView.ViewedJobExplorer
        : AnalyticEvents.ExploreServicesView.ViewedServiceExplorer,
      { tileListEmpty }
    );
  }, [isJobs, tileListEmpty]);

  const namespaceCount = useMemo(() => {
    const count = new Map<string, number>();
    checkedServices?.forEach((s) => {
      count.set(s.env, (count.get(s.env) ?? 0) + 1);
    });
    return count;
  }, [checkedServices]);

  let currentEnv = "";

  const isAgentConnected = useIsAgentConnected();

  const isAgentNotInstalledState = useMemo(() => {
    if (isElectronContext()) return false;
    return !komodorServices?.length && isAgentConnected === false;
  }, [komodorServices, isAgentConnected]);
  const [isInstallationModalOpen, setInstallationModalOpen] = useState(false);
  const { canManageIntegrations } = useHasPermissions();

  useEffect(() => {
    if (
      isAgentNotInstalledState &&
      sessionStorage.getItem(K8S_INTEGRATION_MODAL_INITIAL_OPEN) === "false" &&
      !isTrialEnded &&
      canManageIntegrations
    ) {
      setInstallationModalOpen(true);
    }
  }, [canManageIntegrations, isAgentNotInstalledState, isTrialEnded]);
  const handleModalClose = () => {
    sessionStorage.setItem(K8S_INTEGRATION_MODAL_INITIAL_OPEN, "true");
    setInstallationModalOpen(false);
    refreshServiceAttsConfig();
  };
  const tiersVisible = useTiersVisible();

  const updateOpenFreemiumModal = useFreemiumModalsUpdate();
  const currentFreemiumModal = useFreemiumModals();

  const isTrialEndedAndActiveAccount = isTrialEnded && !accountDisabled;

  useEffect(() => {
    if (!tiersVisible) return;
    if (!isTrialEndedAndActiveAccount) return;
    if (accountPlan !== AccountPlan.trial) return;
    if (currentFreemiumModal) return;
    updateOpenFreemiumModal &&
      updateOpenFreemiumModal(freemiumModalNames.TRIAL_ENDED);
  }, [
    currentFreemiumModal,
    tiersVisible,
    isTrialEndedAndActiveAccount,
    updateOpenFreemiumModal,
    accountPlan,
  ]);

  const getOldTrialEndModal = () => {
    if (!isTrialEndedAndActiveAccount) return null;
    if (tiersVisible) return null;
    if (accountPlan !== AccountPlan.trial) return null;

    return <TrialEndedModal />;
  };

  return (
    <Container>
      {getOldTrialEndModal()}
      <Content>
        <Sidebar>
          <FilterBar
            title={`Filter ${isJobs ? "jobs" : "services"}`}
            isLoading={!checkedServices || !generatedCategories}
            categories={generatedCategories ?? []}
            inputFilter={
              <StyledInput
                id="servicesTextFilter"
                placeholder="Search name"
                value={localFilterTerm ?? ""}
                data-e2e-selector={"exploreServiceSearchBox"}
                onChange={(c) => setLocalFilterTerm(c.target.value)}
              />
            }
          />
        </Sidebar>
        {isAgentNotInstalledState && canManageIntegrations ? (
          <AgentNotInstalledState
            komodorServiceType={type}
            setOpen={setInstallationModalOpen}
          />
        ) : (
          <>
            <ServicesHeaderArea>
              <H2 data-e2e-selector="services-title">
                {isJobs ? "Jobs" : "Services"}{" "}
                {checkedServices ? `(${checkedServices.length})` : <>&nbsp;</>}
              </H2>
              <ServicesSortGrid>
                <label htmlFor="servicesSort">Sort by</label>
                <Select
                  id="servicesSort"
                  onChange={(e) => {
                    setSortStrategy(e.target.value);
                    dispatchEvent(
                      AnalyticEvents.ExploreServicesView
                        .SortBy_Dropdown_Clicked,
                      {
                        viewType: type,
                        clickedSort: e.target.value,
                      }
                    );
                  }}
                  value={
                    sortStrategy
                      ? sortStrategy
                      : isJobs
                      ? jobsStrategyNames[0]
                      : servicesStrategyNames[0]
                  }
                >
                  {(isJobs ? jobsStrategyNames : servicesStrategyNames).map(
                    (strategy) => (
                      <option key={strategy} value={strategy}>
                        {strategy}
                      </option>
                    )
                  )}
                </Select>
              </ServicesSortGrid>
            </ServicesHeaderArea>
            {!checkedServices ? (
              <ServicesLoader />
            ) : !checkedServices.length ? (
              <KomodorServicesEmptyState
                komodorServiceType={type}
                isFiltering={!!filterTerm || !!selected}
                onResetFilters={() => {
                  setSelected(null);
                  setFilterTerm(null);
                }}
              />
            ) : (
              <div>
                <InfiniteScroll
                  loadMore={loadMore}
                  hasMore={limited.length < checkedServices.length}
                >
                  <IntersectionDetector onShow={resetBatches} />
                  <ServicesGrid tileWidth={isJobs ? "17.5rem" : "16rem"}>
                    {limited.map((service) => {
                      if (
                        sortStrategy ===
                        (JobsSortStrategy.JobNamespace ||
                          ServicesSortStrategy.ServiceNamespace)
                      ) {
                        if (currentEnv !== service.env) {
                          currentEnv = service.env;
                          return [
                            <GroupTitle key={service.env}>
                              <H2>
                                namespace: {service.env} (
                                {namespaceCount.get(service.env)})
                              </H2>
                            </GroupTitle>,
                            newServiceTile(service, filterTerm ?? "", type),
                          ];
                        }
                      }
                      return newServiceTile(service, filterTerm ?? "", type);
                    })}
                  </ServicesGrid>
                </InfiniteScroll>
              </div>
            )}
          </>
        )}
      </Content>
      <KubernetesInstallationModal
        closeModalCallback={handleModalClose}
        isOpen={isInstallationModalOpen}
      />
    </Container>
  );
};

export const ExploreKomodorServices: React.FC<{
  komodorServices: ServiceInfo[] | undefined;
  type: KomodorServiceType;
}> = ({ komodorServices, type }) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name:
          type == KomodorServiceType.service
            ? DatadogViewNamesEnum.serviceExplorer
            : DatadogViewNamesEnum.jobExplorer,
        context: {
          feTeam: "troubleshooting",
          beTeam: "barzelim",
          viewLoadingKind:
            !komodorServices || komodorServices.length === 0
              ? "initial"
              : "non_initial",
        },
      }}
    >
      <ExploreKomodorServicesComponent
        komodorServices={komodorServices}
        type={type}
      />
    </DatadogReportLoadingTimeContextProvider>
  );
};
