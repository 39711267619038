import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { TextInput } from "components/komodor-ui/TextInput/TextInput";
import { muiPalette } from "themes";

export interface ChatMessageType {
  id: number;
  text: string;
  isUser: boolean;
}
export interface ChatBotProps {
  messages: ChatMessageType[];
  loading: boolean;
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  handleSendMessage: () => void;
}
export const ChatBot: React.FC<ChatBotProps> = ({
  messages,
  input,
  setInput,
  loading,
  handleSendMessage,
}) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Auto-scroll to the bottom when new messages are added
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Box
      display="grid"
      gridTemplateRows="auto max-content"
      alignItems="center"
      width="100%"
      height="calc(100% - 74px)"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        overflow="auto"
      >
        <List>
          {messages.map((msg) => (
            <ListItem
              key={msg.id}
              alignItems="flex-start"
              sx={{
                justifyContent: msg.isUser ? "flex-end" : "flex-start",
                display: "flex",
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  paddingInline: "16px",
                  backgroundColor: msg.isUser ? "#cfe9ff" : "#f5f5f5",
                  maxWidth: "70%",
                  textAlign: msg.isUser ? "right" : "left",
                }}
              >
                <ListItemText
                  primary={msg.isUser ? "You" : "Bot"}
                  secondary={msg.text}
                />
              </Paper>
            </ListItem>
          ))}
          {loading && (
            <ListItem
              alignItems="flex-start"
              sx={{
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  padding: "8px 16px",
                  backgroundColor: "#f5f5f5",
                  width: "fit-content",
                  textAlign: "left",
                }}
              >
                <CircularProgress size={20} />
              </Paper>
            </ListItem>
          )}
          {/* Reference for auto-scrolling */}
          <div ref={messagesEndRef} />
        </List>
      </Box>
      <Box
        display="grid"
        gap={1}
        padding="16px"
        borderTop={`1px solid ${muiPalette.divider}`}
        gridTemplateColumns="auto max-content"
      >
        <TextInput
          variant="outlined"
          size="large"
          fullWidth
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          disabled={loading}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};
