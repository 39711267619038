import { muiColors, palette, theme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  DataGridPro,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";

import FetchDataLoading from "../common/loaders/LoadingState";
import { NoItemsContainer } from "../Inspection/styles";
import { buildKomodorUid } from "../../shared/hooks/resources-api/resourcesAPIUtils";
import { LocalStorageItem } from "../../shared/utils/localStorageSettings";
import { useWorkspaces } from "../workspaces/WorkspacesTopBar/hooks";

import { ArgoWorkflowTableData, IArgoWorkflowTableData } from "./types";
import { useArgoWorkflowsTableRows } from "./groupWorkflowsByOwner";
import { ArgoFilters } from "./filters/ArgoFilters";
import { useArgoFilters } from "./filters/useArgoFilters";
import { useArgoMainTableColumns } from "./argoMainTableHooks";
import { FetchError } from "./EmptyStates";
import { UpdateArgoWorkflowAlert } from "./UpdateArgoWorkflowsAlert";
import { useArgoWorkflowsFromAtm } from "./useArgoWorkflowsFromAtm";

import { ClusterWorkspace } from "@/generated/workspacesApi";
import { useQueryStringInLocalStorage } from "@/shared/hooks/state/useQueryStringInLocalStorage";
import { dagsListPageAriaLabels } from "@/components/k8sAddons/addons/workflows/DagsListPage/ariaLabels";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const PAGINATION_SIZE_OPTIONS = [10, 15, 25];
const DEFAULT_PAGE_SIZE = 10;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-block-start: 24px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
  background-color: ${theme.background.bgWhite};
  width: 100%;
  gap: 1rem;
  min-height: 30rem;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  min-height: 30rem;
  align-items: center;
  justify-content: center;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const storedArgoWorkflowsTableView = new LocalStorageItem(
  "ArgoWorkflowsTableViewQS"
);

export const ArgoMainTableContent: React.FC = () => {
  const { currentWorkspace, isClusterWorkspace, isLoading } = useWorkspaces();
  const defaultCluster = useMemo(() => {
    if (!isLoading && currentWorkspace != null && isClusterWorkspace) {
      return (currentWorkspace.value as ClusterWorkspace).clusterName;
    }
    return undefined;
  }, [currentWorkspace, isClusterWorkspace, isLoading]);
  const {
    argoWorkflowsGroups,
    cluster,
    setCluster,
    selectedCluster,
    filteredClusterSuggestions,
    fetching,
    isAgentSupported,
    errorMessage,
  } = useArgoWorkflowsFromAtm(defaultCluster);

  const ariaLabel = fetching
    ? `${dagsListPageAriaLabels.table} loading`
    : `${dagsListPageAriaLabels.table} content`;

  const tableRows = useArgoWorkflowsTableRows(argoWorkflowsGroups);

  const filteredTableResults = useArgoFilters(
    tableRows as unknown as IArgoWorkflowTableData[]
  );

  useQueryStringInLocalStorage({ item: storedArgoWorkflowsTableView });

  const navigate = useNavigate();
  const onRowClick = ({ row }: GridRowParams<ArgoWorkflowTableData>) => {
    const workflowKomodorUid = buildKomodorUid({
      clusterName: cluster,
      namespace: row.namespace,
      resourceName: row.name,
      kind: row.type,
    });
    navigate(`${workflowKomodorUid}`);
  };

  const columns = useArgoMainTableColumns();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "startedAt",
      sort: "desc",
    },
  ]);

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () => reportLoadingState("argoTableData", fetching),
    [fetching, reportLoadingState]
  );
  return (
    <Container>
      <Card>
        {cluster ? (
          <ArgoFilters
            clustersOptions={filteredClusterSuggestions}
            resourceList={tableRows}
            setCluster={setCluster}
            selectedCluster={selectedCluster ?? cluster}
          />
        ) : null}
        {fetching ? (
          <FetchDataLoading dataName={"Argo Workflows"} />
        ) : !isAgentSupported ? (
          <UpdateArgoWorkflowAlert />
        ) : errorMessage &&
          errorMessage !== "no supported kubernetes resource found" ? (
          <EmptyStateContainer>
            <FetchError />
          </EmptyStateContainer>
        ) : tableRows?.length === 0 ? (
          <EmptyStateContainer>
            <NoItemsContainer>There are no items to show</NoItemsContainer>
          </EmptyStateContainer>
        ) : (
          <DataGridPro
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={[
              {
                groupId: "latest-run",
                headerName: "Latest Run",
                children: [
                  { field: "status" },
                  { field: "duration" },
                  { field: "startedAt" },
                ],
                renderHeaderGroup: (params) => (
                  <Typography variant="overline2" color={muiColors.gray[500]}>
                    {params.headerName}
                  </Typography>
                ),
              },
            ]}
            rows={filteredTableResults}
            columns={columns}
            pageSizeOptions={PAGINATION_SIZE_OPTIONS}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: DEFAULT_PAGE_SIZE,
                },
              },
            }}
            onRowClick={onRowClick}
            sx={{
              "& .MuiDataGrid-withBorderColor": {
                borderColor: muiColors.gray[100],
              },
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
              "& .MuiTablePagination-root": {
                marginRight: "4rem",
              },
            }}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            autoHeight
            pagination
            disableVirtualization
            aria-label={ariaLabel}
          />
        )}
      </Card>
    </Container>
  );
};

export const ArgoMainTable: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.argoWorkflowPage,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <ArgoMainTableContent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
