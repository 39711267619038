import React from "react";
import { LabelSelector } from "kubernetes-types/meta/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { addSeconds, parseISO } from "date-fns";

import Resource, { ResourceTab, ResourceTabName } from ".";

import { WorkflowRun as WorkflowRunType } from "@/generated/addonsApi";
import { isValidDate } from "@/shared/utils/dateUtils";
import { WorkflowEventsTab } from "@/components/ResourceView/tabs/EventsTab/WorkflowEventsTab/WorkflowEventsTab";
import { WorkflowPodsTab } from "@/components/ResourceView/tabs/PodsTab/WorkflowPodsTab";
import { WorkflowEngine } from "@/components/k8sAddons/addons/workflows/types";
import { Timeframe, TimeWindow } from "@/shared/types/TimeWindow";
import {
  getLabelWithoutKomodorRunIdPrefix,
  isKomodorRunIdPrefix,
  LabelAirflowDagId,
  LabelAirflowExecutionDate,
  LabelAirflowRunId,
  LabelSparkRunId,
  LabelWorkflowDagId,
  LabelWorkflowEngine,
  LabelWorkflowRunId,
} from "@/components/k8sAddons/addons/workflows/workflowUtils";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ServiceLogsTab from "@/components/ResourceView/tabs/ServiceLogsTab/ServiceLogsTab";

const WORKFLOW_RUN_KIND = "WorkflowRun";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class WorkflowRun implements Resource {
  readonly agentId = "";
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind = WORKFLOW_RUN_KIND;
  readonly name;
  readonly annotations = {};
  readonly labels: Record<string, string>;
  readonly drawerTabs: ResourceTab[] = [
    { label: ResourceTabName.Events },
    { label: ResourceTabName.Pods },
    { label: ResourceTabName.Logs },
  ];
  readonly defaultTab;
  readonly actions = [];

  readonly dagId;
  readonly engine;
  readonly displayName;
  readonly selector;
  readonly status;
  readonly startedAt;
  readonly finishedAt;
  readonly durationSec;
  readonly hasIssues: boolean = false;
  readonly timeWindow: TimeWindow;

  constructor(wfRun: WorkflowRunType) {
    this.id = wfRun.id ?? "";
    this.dagId = wfRun.dagId ?? "";
    this.cluster = wfRun.clusterName ?? "";
    this.namespace = wfRun.namespace ?? "";
    this.engine = wfRun.engine ?? "";
    this.name = wfRun.runId ?? "";
    this.displayName = wfRun.runName ?? wfRun.runId ?? "";
    this.labels = this.getLabels(wfRun);
    this.selector = {
      matchLabels: this.labels,
    } as LabelSelector;
    this.status = wfRun.status;
    this.startedAt = isValidDate(wfRun.startedAt)
      ? parseISO(wfRun.startedAt)
      : null;
    this.durationSec = wfRun.durationSec;
    this.finishedAt = addSeconds(this.startedAt ?? 0, this.durationSec);
    this.defaultTab = 0;
    this.hasIssues = wfRun.hasIssues;
    this.timeWindow = {
      timeframe: Timeframe.Custom,
      start: this.startedAt ? addSeconds(this.startedAt, -10) : new Date(0),
      end: this.finishedAt ? addSeconds(this.finishedAt, 10) : new Date(),
    };
  }

  getLabels(wfRun: WorkflowRunType): Record<string, string> {
    const labels: Record<string, string> = {};
    switch (wfRun.engine) {
      case WorkflowEngine.Airflow:
        labels[LabelAirflowDagId] = wfRun.dagId;
        if (isKomodorRunIdPrefix(wfRun.runId)) {
          labels[LabelAirflowExecutionDate] = getLabelWithoutKomodorRunIdPrefix(
            wfRun.runId
          );
        } else {
          labels[LabelAirflowRunId] = wfRun.runId;
        }
        break;
      case WorkflowEngine.Spark:
        labels[LabelSparkRunId] = wfRun.runId;
        break;
      default:
        labels[LabelWorkflowEngine] = wfRun.engine;
        labels[LabelWorkflowDagId] = wfRun.dagId;
        labels[LabelWorkflowRunId] = wfRun.runId;
    }
    return labels;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <WorkflowEventsTab resource={this} key={this.id} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <WorkflowPodsTab resource={this} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <ServiceLogsTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader() {
    return <></>;
  }
}
