import React from "react";

import { usePodMetricsWithZoom } from "@/components/ResourceView/tabs/MetricsTab/hooks/usePodMetricsWithZoom";
import {
  MetricsPerTypeParams,
  useMetricsTabGraphData,
} from "@/components/ResourceView/tabs/MetricsTab/hooks/metricsTabHooks";
import { GraphContainerWithStatus } from "@/components/Metrics/GraphContainerWithStatus";
import { getCommonGraphContainerWithStatusProps } from "@/components/ResourceView/tabs/MetricsTab/utils";
import { ConditionalNoLimitLabel } from "@/components/Metrics/NoLimitLabel";
import { ResourceGraph } from "@/components/Metrics/graphs/ResourceGraph";
import { timelineTicksNum } from "@/components/ResourceView/tabs/MetricsTab/constants";
import {
  METRICS_POD_CPU_ENDPOINT,
  METRICS_POD_MEMORY_ENDPOINT,
} from "@/shared/hooks/metrics-api/requestResponseMaps";
import { Uid } from "@/shared/utils/generateUid";

export type MetricsTabGraphCommonProps = {
  title: string;
  metricsParams: MetricsPerTypeParams;
  endpoint:
    | typeof METRICS_POD_MEMORY_ENDPOINT
    | typeof METRICS_POD_CPU_ENDPOINT;
  uid: Uid;
  isMultipleContainers?: boolean;
  syncId?: string;
};

export const MetricsTabGraph = ({
  title,
  metricsParams,
  endpoint,
  uid,
  onEnlargeGraph,
  isMultipleContainers,
  syncId,
}: MetricsTabGraphCommonProps & { onEnlargeGraph: () => void }) => {
  const { fromEpoch, toEpoch, graphProps } = usePodMetricsWithZoom();

  const { data, state, type } = useMetricsTabGraphData(
    { ...metricsParams, fromEpoch, toEpoch },
    endpoint
  );

  return (
    <GraphContainerWithStatus
      {...getCommonGraphContainerWithStatusProps(state)}
      title={title}
      uid={uid}
      onEnlargeGraph={onEnlargeGraph}
      info={
        <ConditionalNoLimitLabel
          data={state.metrics}
          isMultipleContainers={isMultipleContainers}
        />
      }
    >
      <ResourceGraph
        type={type}
        data={data}
        timelineTicksNum={timelineTicksNum}
        graphHeight={"15rem"}
        syncId={syncId}
        {...graphProps}
      />
    </GraphContainerWithStatus>
  );
};
