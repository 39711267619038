import { getYear } from "date-fns";

import {
  allMonths,
  CLUSTER_FIELD,
  GROUPS_ROW_CLASSNAME,
  HEADERS_ROW_CLASSNAME,
} from "@/components/Settings/General/NodesCount/consts";

export const getColumnsDefinitions = (sortedMonths: string[]) => [
  {
    field: CLUSTER_FIELD,
    headerName: "Cluster/Month",
    pinned: "left",
    minWidth: 140,
    flex: 1,
    headerClassName: HEADERS_ROW_CLASSNAME,
  },
  ...sortedMonths.map((month) => ({
    field: month,
    headerName: month,
    headerClassName: HEADERS_ROW_CLASSNAME,
    minWidth: 64,
    flex: 1,
  })),
];

export const getColumnGroupingModel = (
  sortedMonths: string[],
  currentMonth: string
) => {
  const currentYear = getYear(new Date());

  const groupedColumns = [
    {
      groupId: "fixedGroup",
      headerName: "Year",
      children: [{ field: CLUSTER_FIELD }],
      headerClassName: GROUPS_ROW_CLASSNAME,
    },
  ];

  // if the first month of the sorted months is the first month of the year, there is only one year
  if (sortedMonths[0] === allMonths[0]) {
    const lastYear = currentYear - 1;
    return [
      ...groupedColumns,
      {
        groupId: lastYear.toString(),
        headerName: lastYear.toString(),
        children: sortedMonths.map((month) => ({ field: month })),
        headerClassName: GROUPS_ROW_CLASSNAME,
      },
    ];
  }

  const previousYear = currentYear - 1;
  const currentYearMonths = allMonths.slice(
    allMonths.indexOf(currentMonth),
    allMonths.length
  );

  const nextYearMonths = allMonths.slice(0, allMonths.indexOf(currentMonth));

  return [
    ...groupedColumns,
    {
      groupId: previousYear.toString(),
      headerName: previousYear.toString(),
      children: currentYearMonths.map((month) => ({ field: month })),
      headerClassName: GROUPS_ROW_CLASSNAME,
    },
    {
      groupId: currentYear.toString(),
      headerName: currentYear.toString(),
      children: nextYearMonths.map((month) => ({ field: month })),
      headerClassName: GROUPS_ROW_CLASSNAME,
    },
  ];
};
