import { LinkCell } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import { muiPalette } from "@komodorio/design-system";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

const SOURCE_K8S_RESOURCE_KINDS = ["Service", "Ingress"];

const SourceDataContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: max-content auto;
  gap: 8px;
`;
const SourceContainer = styled.div`
  padding: 16px 60px 16px 60px;
  display: grid;
  gap: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.background.paper};
  align-items: center;
`;

export const SourceDetailsPanel: React.FC<{
  sourceKomodorUid: string | undefined;
  isUnresolved: boolean;
}> = ({ sourceKomodorUid, isUnresolved }) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const {
    cluster = "",
    namespace = "",
    kind = "",
    name = "",
  } = parseKomodorUid(sourceKomodorUid ?? "") ?? {};

  return (
    <SourceContainer>
      <SourceDataContainer>
        <Typography variant="h5" color={muiPalette.text?.secondary}>
          Source name:
        </Typography>
        <LinkCell
          onClick={() => {
            pushDrawer({
              drawerType: DrawerType.ResourceDrawerByData,
              cluster,
              namespace,
              resourceType: kind,
              resourceName: name,
              buildPreloadResource: true,
              additionalData: {
                isCustomResource: !SOURCE_K8S_RESOURCE_KINDS.includes(kind),
              },
            });
          }}
        >
          {name}
        </LinkCell>
        <Typography variant="h5" color={muiPalette.text?.secondary}>
          Source kind:
        </Typography>
        <Typography variant="body2">{kind}</Typography>
        <Typography variant="h5" color={muiPalette.text?.secondary}>
          Source namespace:
        </Typography>
        <Typography variant="body2">{namespace}</Typography>
      </SourceDataContainer>
      {isUnresolved && (
        <Alert
          severity="warning"
          variant="outlined"
          sx={{
            fontSize: "14px",
            "& .MuiAlert-message": {
              padding: "8px 0px",
            },
          }}
          icon={
            <WarningAmberOutlined
              sx={{
                width: "16px",
                height: "16px",
              }}
            />
          }
        >
          Komodor agent couldn’t resolve the target host from within the cluster
        </Alert>
      )}
    </SourceContainer>
  );
};
