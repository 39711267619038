import React, { useCallback, useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useGetCanI } from "@/shared/hooks/auth-service/client/users/useGetCanI";
import { CanIResponse } from "@/generated/auth";

export enum selectors {
  apiGroup = "apiGroup",
  resource = "resource",
  verb = "verb",
  cluster = "cluster",
  namespace = "namespace",
}

export type SelectorKey = keyof typeof selectors;

export type CanISelection = MuiSelectionOption<string> | undefined;

export type CanISelections = {
  [selectors.apiGroup]: CanISelection;
  [selectors.resource]: CanISelection;
  [selectors.verb]: CanISelection;
  [selectors.cluster]: CanISelection;
  [selectors.namespace]: CanISelection;
};

export const useCanI = (userId: string, isAdminView: boolean) => {
  const [selectedApiGroup, setSelectedApiGroup] =
    React.useState<CanISelection>(undefined);
  const [selectedResource, setSelectedResource] =
    React.useState<CanISelection>(undefined);
  const [selectedVerb, setSelectedVerb] =
    React.useState<CanISelection>(undefined);
  const [selectedCluster, setSelectedCluster] =
    React.useState<CanISelection>(undefined);
  const [selectedNamespace, setSelectedNamespace] =
    React.useState<CanISelection>(undefined);

  const selectedValues = useMemo(
    () => ({
      [selectors.apiGroup]: selectedApiGroup,
      [selectors.resource]: selectedResource,
      [selectors.verb]: selectedVerb,
      [selectors.cluster]: selectedCluster,
      [selectors.namespace]: selectedNamespace,
    }),
    [
      selectedCluster,
      selectedNamespace,
      selectedVerb,
      selectedResource,
      selectedApiGroup,
    ]
  );

  const setters = useMemo(
    () => ({
      [selectors.apiGroup]: (value: CanISelection) => {
        setSelectedApiGroup(value);
      },
      [selectors.resource]: (value: CanISelection) => {
        setSelectedResource(value);
      },
      [selectors.verb]: (value: CanISelection) => {
        setSelectedVerb(value);
      },
      [selectors.cluster]: (value: CanISelection) => {
        setSelectedCluster(value);
      },
      [selectors.namespace]: (value: CanISelection) => {
        setSelectedNamespace(value);
      },
    }),
    []
  );

  const {
    mutateAsync: requestUserCanI,
    isLoading,
    isError,
    data,
  } = useGetCanI({
    [selectors.apiGroup]: selectedApiGroup?.value ?? "",
    [selectors.resource]: selectedResource?.value ?? "",
    [selectors.verb]: selectedVerb?.value ?? "",
    [selectors.cluster]: selectedCluster?.label ?? "",
    [selectors.namespace]: selectedNamespace?.value ?? "",
    // No need to send the userId if the view is not admin
    id: isAdminView ? userId : undefined,
  });

  const memoizedResponse = useMemo(
    () => ({ data, isError, isLoading, requestUserCanI }),
    [data, isError, requestUserCanI, isLoading]
  );

  const handleRequest = useCallback(async () => {
    try {
      return await requestUserCanI();
    } catch (error) {
      return {
        komodorPermission: false,
        clusterPermission: false,
      } as CanIResponse;
    }
  }, [requestUserCanI]);

  return {
    handleRequest,
    isLoading: memoizedResponse.isLoading,
    isError: memoizedResponse.isError,
    data: memoizedResponse.data,
    selectedValues,
    setters,
  };
};
