import { v4 as uuidv4 } from "uuid";
import { IntegrationType } from "komodor-types";

import { InstallationCreateFnAsync } from "../../hooks/accounts-service/client/installations/useCreateInstallation";

import { notifyDesktopAppError } from "./listeners/notifyDesktopAppError";

export const runDesktopAgent = async (
  createInstallation: InstallationCreateFnAsync,
  clusterContext: string,
  clusterName: string | undefined,
  index: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): Promise<boolean> => {
  const apiKey = uuidv4();
  if (!clusterName) {
    notifyDesktopAppError(
      new Error(
        `couldn't find machine cluster name for: ${JSON.stringify({
          clusterContext,
          clusterName,
        })}`
      )
    );
    return false;
  }
  await createInstallation({
    id: apiKey,
    integration: IntegrationType.KUBERNETES,
    configuration: {
      labels: { clusterName: clusterName },
    },
  });
  const isSuccess = await electronInstallAgent(
    apiKey,
    clusterContext,
    clusterName,
    index
  );
  return isSuccess ?? false;
};

const electronInstallAgent = async (
  apiKey: string,
  context: string,
  displayName: string,
  index: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): Promise<boolean | undefined> => {
  const ipcResult: boolean | undefined = await window.electronAPI?.installAgent(
    apiKey,
    context,
    displayName,
    index
  );
  if (ipcResult === undefined) return false;
  return ipcResult;
};
