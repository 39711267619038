import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { ServiceYamlFile, YamlFile } from "../common/types";

export const refresh = (
  serviceYamls: (ServiceYamlFile | undefined)[],
  freshConfigFiles: (YamlFile | undefined)[],
  freshServiceHealthStatuses: boolean[]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): (ServiceYamlFile | undefined)[] =>
  serviceYamls.map((yaml, index) => {
    const freshConfigFile = freshConfigFiles[index];

    return freshConfigFile !== undefined
      ? attachServiceHealthStatusTo(
          freshConfigFile,
          freshServiceHealthStatuses[index]
        )
      : yaml;
  });

const attachServiceHealthStatusTo = (
  configFile: YamlFile,
  serviceHealthStatus: boolean
): ServiceYamlFile => ({
  ...configFile,
  serviceHealthy: serviceHealthStatus,
});

export const findServiceBy = (
  cluster: string | undefined,
  namespace: string | undefined,
  services: ServiceInfo[]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ServiceInfo | undefined =>
  services.find(
    (service) => service.k8sCluster === cluster && service.env === namespace
  );

export const replaceElementAt = <T>(
  index: number,
  array: T[],
  newValue: T
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): T[] => array.map((element, i) => (i === index ? newValue : element));

export const equalByContent = (
  configFile: YamlFile | undefined,
  otherConfigFile: YamlFile
): boolean => (configFile?.content ?? "") === otherConfigFile.content;
