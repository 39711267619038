import { useCallback } from "react";

import {
  DatadogContext,
  DatadogViewNamesEnum,
} from "@/shared/types/datadogReporting";
import { ViewName } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import {
  ResourceTabToViewNameMap,
  serviceViewResourceTabToViewNameMap,
  ResourceTabToTeamAor,
} from "@/components/ResourceView/resourceViewConstants";
import { useIsServicesViewPath } from "@/shared/hooks/useIsServicesPath";
import { ResourceTab } from "@/components/ResourceView/types";

export const useGetLoadingTimeContextPropsForResource = (
  resourceTab: ResourceTab,
  additionalContext?: Partial<DatadogContext>
) => {
  const isServicesViewPath = useIsServicesViewPath();

  return useCallback((): ViewName => {
    const mapToUse = isServicesViewPath
      ? serviceViewResourceTabToViewNameMap
      : ResourceTabToViewNameMap;
    const viewName = mapToUse[resourceTab] as DatadogViewNamesEnum;
    const context: DatadogContext = ResourceTabToTeamAor[resourceTab];

    return {
      getCurrentViewOptions: () => ({
        name: viewName,
        context: {
          ...context,
          ...additionalContext,
        },
      }),
    };
  }, [additionalContext, isServicesViewPath, resourceTab]);
};
