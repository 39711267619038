import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { AutoscalersMetricsGraph } from "./AutoscalersMetricsGraph/AutoscalersMetricsGraph";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
  gap: 8px;
`;

const GraphsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const NodeMetrics: React.FC = () => {
  return (
    <Container>
      <Typography variant="h5"> Node Metrics (last 7 days) </Typography>
      <GraphsContainer>
        <AutoscalersMetricsGraph />
      </GraphsContainer>
    </Container>
  );
};
