import React from "react";

import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import { ManagedRecordsTable } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ManagedRecordsTable";
import { ExternalDnsManagedRecord } from "@/generated/addonsApi";

export const ManagedRecordsSection: React.FC<{
  isLoadingManagedRecords: boolean;
  isErrorFetchingManagedRecords: boolean;
  managedRecords: ExternalDnsManagedRecord[];
  refetchManagedRecords: () => void;
}> = ({
  isLoadingManagedRecords,
  isErrorFetchingManagedRecords,
  managedRecords,
  refetchManagedRecords,
}) => {
  return (
    <AccordionWrapper
      title={`Managed Records (${managedRecords.length})`}
      accordionContent={
        <ManagedRecordsTable
          isLoadingManagedRecords={isLoadingManagedRecords}
          isErrorFetchingManagedRecords={isErrorFetchingManagedRecords}
          managedRecords={managedRecords}
          refetchManagedRecords={refetchManagedRecords}
        />
      }
      ariaLabel={
        externalDnsListPageAriaLabels.drawer.sections.managedRecords.section
      }
    />
  );
};
