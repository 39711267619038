import { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {
  MuiSelectionOption,
  SearchField,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";

import { TimeRangeSelector } from "@/pages/organization-settings/account/AuditPage/components/TimeRangeSelector";
import {
  auditPageAriaLabels,
  STATUS_OPTIONS,
} from "@/pages/organization-settings/account/AuditPage/constants";
import { useAuditLogsContext } from "@/pages/organization-settings/account/AuditPage/context/useAuditLogsContext";
import { MultiSelectByKey } from "@/pages/organization-settings/account/AuditPage/components/MultiSelectByKey";
import { useExportToCsv } from "@/pages/organization-settings/account/AuditPage/hooks/useAuditData";

const DEBOUNCE_TIME = 150;

export const FiltersSection: React.FC = () => {
  const [text, setText] = useState("");
  const { setConfigurationData, apiStartTime, ...context } =
    useAuditLogsContext();

  const { exportToCsv, isLoadingCsv } = useExportToCsv();
  const { exportButton, filters: filtersLabels } = auditPageAriaLabels;

  const { callback: debouncedOnSearch } = useDebouncedCallback(
    (newValue: string) => {
      setConfigurationData({ entityName: newValue ?? "" });
    },
    DEBOUNCE_TIME
  );

  const onChange = useCallback(
    (newValue: string) => {
      setText(newValue);
      debouncedOnSearch(newValue);
    },
    [debouncedOnSearch]
  );

  return (
    <Stack rowGap={2}>
      <Stack direction="row" columnGap={1}>
        <SearchField
          placeholder="Search affected resources"
          value={text}
          onSearch={onChange}
          size="medium"
          width="240px"
          ariaLabel={filtersLabels.entityName}
        />
        <TimeRangeSelector />
        <Button
          variant="outlined"
          sx={{ marginLeft: "auto" }}
          aria-label={exportButton}
          disabled={isLoadingCsv}
          onClick={exportToCsv}
        >
          Export To CSV
        </Button>
      </Stack>
      <Stack direction="row" spacing={1} flexGrow={1} alignItems="flex-end">
        <MultiSelectByKey
          filterOptionsKey="operations"
          ariaLabel={filtersLabels.eventType}
        />
        <MultiSelectByKey
          filterOptionsKey="actions"
          ariaLabel={filtersLabels.action}
        />
        <MultiSelectByKey
          filterOptionsKey="entityTypes"
          ariaLabel={filtersLabels.resourceType}
        />
        <MultiSelectByKey
          filterOptionsKey="userIds"
          ariaLabel={filtersLabels.user}
        />
        <Box flex={1}>
          <SingleSelect
            options={STATUS_OPTIONS}
            value={context.status}
            label={"Status"}
            clearable
            onChange={(
              selectedOption: MuiSelectionOption<string> | undefined
            ) => {
              setConfigurationData({ status: selectedOption });
            }}
            ariaLabel={filtersLabels.status}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
