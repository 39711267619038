import React from "react";
import { sortBy } from "lodash";
import { useParams } from "react-router-dom";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeHealthySystemPodsCheck from "../NodeHealthySystemPodsCheck";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import Recommendations from "../../../../common/Recommendations";
import { StyledFontTable, TableContainer } from "../../../../common/styles";
import HiddenPodsInfo from "../../../../common/HiddenPodsInfo";

import { FailedPodRow } from "./NodeHealthyUserPodsDetails";

const POD_HEALTH_RECOMMENDATIONS =
  "View the reason for unhealthy pods, it might be connected to the node issue";

const NodeHealthySystemPodsDetails: React.FC<{
  check: NodeHealthySystemPodsCheck;
}> = ({ check }) => {
  const { serviceId } = useParams<{ serviceId: string }>();
  return (
    <>
      {check?.output?.pods && check.output.pods.length > 0 && (
        <>
          <br />
          <Recommendations>{POD_HEALTH_RECOMMENDATIONS}</Recommendations>
          <br />
          <BoldGrayText>Unhealthy System Pods</BoldGrayText>
          <TableContainer>
            <StyledFontTable>
              <thead>
                <tr>
                  <th />
                  <th>Pod name</th>
                  <th>Phase</th>
                  <th>Failed containers</th>
                </tr>
              </thead>
              <tbody>
                {sortBy(
                  check.output.pods,
                  (p) =>
                    serviceId ? [p.serviceId === serviceId, p.name] : p.name,
                  ["desc"]
                ).map((pod) => (
                  <FailedPodRow key={pod.name} pod={pod} />
                ))}
              </tbody>
            </StyledFontTable>
          </TableContainer>
        </>
      )}
      <br />
      <HiddenPodsInfo hiddenPodsCount={check?.output?.hiddenPods} />
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeHealthySystemPodsDetails;
