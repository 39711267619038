import React, { ReactNode } from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import OpenInFull from "@mui/icons-material/OpenInFull";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { Divider } from "../common/ProcessList/details/common";
import { Uid } from "../../shared/utils/generateUid";
import { AriaLabels } from "../../shared/config/ariaLabels";

import EmptyStateGraph, {
  EmptyState,
  EmptyStateStatus,
} from "./EmptyStateGraph";
import { getEmptyGraphStatus } from "./utils";
import { useIsMetricsDrawerSupported } from "./metricsHooks";
import { defaultGraphHeight } from "./constants";

const RootContainer = styled.div``;

const Title = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  gap: 8px;
`;

const TitleContainer = styled.div<{
  $hasTitleElements: boolean;
  height: string;
}>`
  display: flex;
  margin-bottom: ${({ $hasTitleElements }) =>
    $hasTitleElements ? "10px" : "0"};
  height: ${({ height }) => height};
`;

const VisibleOnHoverIconButton = styled(IconButton)`
  visibility: hidden;

  ${RootContainer}:hover & {
    visibility: visible;
  }
`;

const StyledDivider = styled(Divider)`
  border-color: #d8dbe3;
  margin-right: 5px;
`;

const MIN_TITLE_CONTAINER_HEIGHT = "24px";

export type GraphContainerWithStatusProps = {
  className?: string;
  uid: Uid | undefined;
  graphHeight?: string;
  hasGraphData: boolean;
  error: unknown | null;
  loading: boolean;
  title?: string | ReactNode;
  showDivider?: boolean;
  graphSelector?: ReactNode;
  children: ReactNode;
  onEnlargeGraph?: () => void;
  info?: React.ReactNode;
  customEmptyState?: EmptyState;
};

export const GraphContainerWithStatus = ({
  className,
  graphHeight = defaultGraphHeight,
  hasGraphData,
  error,
  loading,
  title,
  graphSelector,
  showDivider = true,
  uid,
  children,
  onEnlargeGraph,
  info,
  customEmptyState,
}: GraphContainerWithStatusProps) => {
  const isMetricsSupported = useIsMetricsDrawerSupported(uid?.cluster);

  const status: EmptyStateStatus = getEmptyGraphStatus({
    data: { loading, error },
    isMetricsSupported,
    isOverRetention: false, // TODO: add shared util isOverRetention()
  });

  const hasTitleElements = !!title || !!info || !!graphSelector;
  const titleContainerHeight = hasTitleElements
    ? "auto"
    : onEnlargeGraph
    ? MIN_TITLE_CONTAINER_HEIGHT
    : "0";

  return (
    <RootContainer className={className}>
      <TitleContainer
        $hasTitleElements={hasTitleElements}
        height={titleContainerHeight}
      >
        <Title>
          <Typography variant="h6" color={theme.foreground.fgSubtle}>
            {title}
          </Typography>
          {info}
          <Box sx={{ flexGrow: 1 }}>{graphSelector}</Box>
          {onEnlargeGraph && hasGraphData && (
            <VisibleOnHoverIconButton
              onClick={onEnlargeGraph}
              size="small"
              aria-label={AriaLabels.Metrics.EnlargeGraphButton}
            >
              <OpenInFull fontSize="inherit" />
            </VisibleOnHoverIconButton>
          )}
        </Title>
        {showDivider && <StyledDivider />}
      </TitleContainer>
      {!hasGraphData ? (
        <EmptyStateGraph
          status={status}
          excludeAxes
          graphHeight={graphHeight}
          customEmptyState={customEmptyState}
        />
      ) : (
        <div style={{ height: graphHeight }}>{children}</div>
      )}
    </RootContainer>
  );
};
