import { useCallback, useEffect, useMemo } from "react";
import { sortBy } from "lodash";
import { useNavigate } from "react-router-dom";

import { useInvestigationModeStore } from "../../shared/store/investigationModeStore/investigationModeStore";
import {
  issueSelector,
  komodorServiceSelector,
  setStepVisitedSelector,
  setStepsSelector,
  stepsSelector,
} from "../../shared/store/investigationModeStore/investigationModeSelectors";
import { INVESTIGATION } from "../routes/routes";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { useAccountName } from "../../shared/hooks/useAccountName";

import { AvailabilityRunWithResults, IStep } from "./types";
import {
  AvailabilityInvestigationCheckType,
  EvictedIssueType,
  OOMIssueType,
  AvailabilityInvestigationIssueType,
  SubReasonContainerLimitReached,
} from "./availability/types";
import { getStepInitStatus as getIntroductionStepInitStatus } from "./availability/Introduction/stepInitStatus";
import { getStepInitStatus as getLogsStepInitStatus } from "./availability/Logs/stepInitStatus";
import { getStepInitStatus as getUnhealthyPodsStepInitStatus } from "./availability/UnhealthyPods/stepInitStatus";
import { getStepInitStatus as getCorrelatedDeploysStepInitStatus } from "./availability/CorrelatedDeploys/stepInitStatus";
import { getStepInitStatus as getNodeIssuesStepInitStatus } from "./availability/NodeIssues/stepInitStatus";
import { getStepInitStatus as getNoisyNeighborsStepInitStatus } from "./availability/NoisyNeighbors/stepInitStatus";
import { getStepInitStatus as getLimitDecreaseStepInitStatus } from "./availability/./LimitDecreased/stepInitStatus";
import { getStepInitStatus as getMemoryLeakStepInitStatus } from "./availability/MemoryLeak/stepInitStatus";
import { getStepInitStatus as getReviewMemoryAllocationStepInitStatus } from "./availability/ReviewMemoryAllocation/stepInitStatus";
import { getStepInitStatus as getDependenciesStepInitStatus } from "./availability/Dependencies/stepInitStatus";

const DEMO_SERVICE_NAME = "events-puller-service";
const DEMO_ACCOUNT = "demo";

export const useIsDemoService = (): boolean => {
  const accountName = useAccountName();
  const issue = useInvestigationModeStore(issueSelector);

  return useMemo(
    () =>
      accountName === DEMO_ACCOUNT &&
      issue?.shortResourceName === DEMO_SERVICE_NAME,
    [accountName, issue?.shortResourceName]
  );
};

export const useNextStep = (): {
  nextStep: string | undefined;
  goToNextStep: () => void;
} => {
  const issue = useInvestigationModeStore(issueSelector);
  const steps = useInvestigationModeStore(stepsSelector);
  const setStepVisitedInStore = useInvestigationModeStore(
    setStepVisitedSelector
  );
  const navigate = useNavigate();

  const currentStepType = window.location.pathname
    .split("/")
    .pop() as AvailabilityInvestigationCheckType;
  const currentIndex = steps.findIndex((step) => step.type === currentStepType);

  const nextStep = steps.find((step, index) => {
    return index > currentIndex && !step.disabled && step.visible;
  });

  const goToNextStep = useCallback(() => {
    setStepVisitedInStore(currentStepType, true);
    navigate(`/${INVESTIGATION}/${issue?.id}/${nextStep?.type}`);
  }, [currentStepType, issue?.id, navigate, nextStep, setStepVisitedInStore]);

  return { nextStep: nextStep?.type, goToNextStep };
};

const filterStepsByFlags = (
  steps: IStep[],
  investigationDependencies: boolean
): IStep[] => {
  if (!investigationDependencies) {
    return steps.filter(
      (step) => step.type !== AvailabilityInvestigationCheckType.Dependencies
    );
  }
  return steps;
};

export const useInitSteps = (issue: AvailabilityRunWithResults | undefined) => {
  const setStepsInStore = useInvestigationModeStore(setStepsSelector);
  const { investigationDependencies } = useOverridableFlags();
  const resource = useInvestigationModeStore(komodorServiceSelector);

  const isDemoService = useIsDemoService();
  const stepsSortedByReason = useMemo(
    () =>
      issue
        ? sortBy(
            filterStepsByFlags(
              getStepsOrderByReason(
                issue,
                isDemoService,
                !!resource?.isDeleted
              ),
              !!investigationDependencies
            ),
            (step) => step.disabled
          ).filter((step) => step.visible)
        : [],
    [investigationDependencies, isDemoService, issue, resource?.isDeleted]
  );

  useEffect(() => {
    setStepsInStore(stepsSortedByReason);
  }, [setStepsInStore, stepsSortedByReason]);
};

const getStepsOrderByReason = (
  issue: AvailabilityRunWithResults,
  isDemoService: boolean,
  isResourceDeleted: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): IStep[] => {
  const issueType =
    issue?.results?.introduction?.output?.firstSnapshot?.issueType;
  const subReason =
    issue?.results?.introduction?.output?.firstSnapshot?.subReason;

  switch (getReasonType(issueType, subReason)) {
    case AvailabilityInvestigationIssueType.Generic:
      return isDemoService
        ? [
            getIntroductionStepInitStatus(),
            getDependenciesStepInitStatus(),
            getNodeIssuesStepInitStatus(issue),
            getLogsStepInitStatus(issue),
            getCorrelatedDeploysStepInitStatus(issue),
            getUnhealthyPodsStepInitStatus(),
          ]
        : [
            getIntroductionStepInitStatus(),
            getCorrelatedDeploysStepInitStatus(issue),
            getLogsStepInitStatus(issue),
            getNodeIssuesStepInitStatus(issue),
            getDependenciesStepInitStatus(),
            getUnhealthyPodsStepInitStatus(),
          ];
    case AvailabilityInvestigationIssueType.Evicted:
      return isDemoService
        ? [
            getIntroductionStepInitStatus(),
            getDependenciesStepInitStatus(),
            getLogsStepInitStatus(issue),
            getNodeIssuesStepInitStatus(issue),
            getCorrelatedDeploysStepInitStatus(issue),
            getUnhealthyPodsStepInitStatus(),
            getReviewMemoryAllocationStepInitStatus(issue, isResourceDeleted),
            getNoisyNeighborsStepInitStatus(issue),
            getLimitDecreaseStepInitStatus(issue),
            getMemoryLeakStepInitStatus(issue),
          ]
        : [
            getIntroductionStepInitStatus(),
            getNodeIssuesStepInitStatus(issue),
            getCorrelatedDeploysStepInitStatus(issue),
            getReviewMemoryAllocationStepInitStatus(issue, isResourceDeleted),
            getNoisyNeighborsStepInitStatus(issue),
            getLogsStepInitStatus(issue),
            getDependenciesStepInitStatus(),
            getUnhealthyPodsStepInitStatus(),
            getLimitDecreaseStepInitStatus(issue),
            getMemoryLeakStepInitStatus(issue),
          ];
    case AvailabilityInvestigationIssueType.OOMKilled:
      return isDemoService
        ? [
            getIntroductionStepInitStatus(),
            getDependenciesStepInitStatus(),
            getLogsStepInitStatus(issue),
            getNodeIssuesStepInitStatus(issue),
            getCorrelatedDeploysStepInitStatus(issue),
            getUnhealthyPodsStepInitStatus(),
            getLimitDecreaseStepInitStatus(issue),
            getReviewMemoryAllocationStepInitStatus(issue, isResourceDeleted),
            getNoisyNeighborsStepInitStatus(issue),
            getMemoryLeakStepInitStatus(issue),
          ]
        : [
            getIntroductionStepInitStatus(),
            getLimitDecreaseStepInitStatus(issue),
            getNodeIssuesStepInitStatus(issue),
            getCorrelatedDeploysStepInitStatus(issue),
            getReviewMemoryAllocationStepInitStatus(issue, isResourceDeleted),
            getNoisyNeighborsStepInitStatus(issue),
            getLogsStepInitStatus(issue),
            getDependenciesStepInitStatus(),
            getUnhealthyPodsStepInitStatus(),
            getMemoryLeakStepInitStatus(issue),
          ];
    case AvailabilityInvestigationIssueType.OOMKilledContainerLimit:
      return isDemoService
        ? [
            getIntroductionStepInitStatus(),
            getDependenciesStepInitStatus(),
            getLogsStepInitStatus(issue),
            getCorrelatedDeploysStepInitStatus(issue),
            getUnhealthyPodsStepInitStatus(),
            getLimitDecreaseStepInitStatus(issue),
            getReviewMemoryAllocationStepInitStatus(issue, isResourceDeleted),
            getNodeIssuesStepInitStatus(issue),
            getMemoryLeakStepInitStatus(issue),
          ]
        : [
            getIntroductionStepInitStatus(),
            getLimitDecreaseStepInitStatus(issue),
            getCorrelatedDeploysStepInitStatus(issue),
            getReviewMemoryAllocationStepInitStatus(issue, isResourceDeleted),
            getUnhealthyPodsStepInitStatus(),
            getLogsStepInitStatus(issue),
            getDependenciesStepInitStatus(),
            getNodeIssuesStepInitStatus(issue),
            getMemoryLeakStepInitStatus(issue),
          ];
  }
};

export const getReasonType = (
  issueType?: string,
  subReason?: string
): AvailabilityInvestigationIssueType => {
  if (issueType === OOMIssueType) {
    if (subReason === SubReasonContainerLimitReached) {
      return AvailabilityInvestigationIssueType.OOMKilledContainerLimit;
    }
    return AvailabilityInvestigationIssueType.OOMKilled;
  } else if (issueType === EvictedIssueType) {
    return AvailabilityInvestigationIssueType.Evicted;
  }
  return AvailabilityInvestigationIssueType.Generic;
};

/**
 * Generic Investigation (All reasons except for OOM, Evicted):
 --------------------------------
 * * Introduction
 * * Correlated Deploys
 * * Correlated node issues & terminations.
 * * Logs
 * * Unhealthy pods
 *
 * For Evicted reasons, execute these steps:
 --------------------------------
 * * Introduction
 * * Node Issues & terminations
 * * Correlated Deploys
 * * Review memory config
 * * Noisy neighbors
 * * Container Logs
 * * Unhealthy Pods
 * * Correlated limit decrease
 * * Memory leak - Only if we have metric data
 *
 * OOM - Node's Limit Overcommitted
 --------------------------------
 * * Introduction
 * * Correlated limit decrease
 * * Node Issues & terminations
 * * Correlated Deploys
 * * Review memory config
 * * Noisy neighbors
 * * Container Logs
 * * Unhealthy Pods
 * * Memory leak - Only if we have metric data
 *
 * OOM - Container Limit Reached
 * --------------------------------
 * * Introduction
 * * Correlated limit decrease
 * * Correlated Deploys
 * * Review memory config
 * * Unhealthy Pods
 * * Container Logs
 * * Node Issues & terminations
 * * Memory leak - Only if we have metric data
 */
