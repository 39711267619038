import { useEffect, useMemo, useState } from "react";
import { ActionTypes, TaskType } from "komodor-types";
import { get } from "lodash";

import useAtmGetResource, {
  AtmGetResourceResponseType,
} from "../../shared/hooks/ATM/requests/useAtmGetResource";
import { AtmOutputType, Status } from "../../shared/hooks/ATM/useAtmRequest";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { useAgentInfoById } from "../../shared/hooks/useAgentInfo/useAgentInfo";
import { isAgentAtmSupported } from "../../shared/utils/agent/agent";
import useAgentTask from "../../shared/hooks/useAgentTask/useAgentTask";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import useParsedResource from "../../shared/hooks/resources/useParsedResource";
import { useAccountName } from "../../shared/hooks/useAccountName";

import Resource from "./resources";
import {
  useKomodorServiceAsResource,
  useKomodorServiceAsResourceWithInterval,
} from "./useKomodorServiceAsResource";
import { useDeletedPodsData } from "./useDeletedPodsData";
import { DELETED_POD_KIND } from "./resources/deletedPod";
import { ResourceResultType } from "./types";

import { buildServiceId } from "@/shared/utils/serviceHelpers";

export type UseResourceParams = {
  agentId: string;
  cluster: string;
  namespace?: string;
  resourceType: string;
  resourceName: string;
  existData?: unknown;
  executeMutation?: boolean;
  preferredResultType?: ResourceResultType;
  fetchFromDb?: boolean;
};

interface GetResultByPreferredTypeParams {
  komodorServiceAsResource: Resource | undefined;
  parsedResource: Resource | undefined;
  preferredResultType?: ResourceResultType;
}

const getResultByPreferredType = ({
  komodorServiceAsResource,
  parsedResource,
  preferredResultType,
}: GetResultByPreferredTypeParams): Resource | undefined => {
  if (!preferredResultType) {
    return komodorServiceAsResource || parsedResource;
  }

  switch (preferredResultType) {
    case ResourceResultType.KomodorService:
      return komodorServiceAsResource;

    case ResourceResultType.RawResource:
      return parsedResource;
  }
};

const useResourceWithInterval = ({
  agentId,
  resourceType,
  resourceName,
  cluster,
  namespace = "",
  existData,
  executeMutation = true,
  fetchFromDb = true,
  preferredResultType,
}: UseResourceParams): {
  resource: Resource | undefined;
  failureMessage: string;
  clearState: () => void;
  isFetching: boolean;
} => {
  const [atmResult, setAtmResult] = useState<AtmGetResourceResponseType>();

  const accountName = useAccountName();
  const serviceId =
    accountName && cluster && namespace && resourceName
      ? buildServiceId(accountName, cluster, namespace, resourceName)
      : undefined;
  const [komodorServiceAsResource, isFetchingKomodorService] =
    useKomodorServiceAsResourceWithInterval(
      serviceId,
      resourceType,
      atmResult?.data,
      fetchFromDb
    );

  return useResourceFromATM({
    resourceType,
    resourceName,
    agentId,
    cluster,
    namespace,
    existData,
    executeMutation,
    preferredResultType,
    atmResult,
    setAtmResult,
    komodorServiceAsResource,
    isFetchingKomodorService,
  });
};

export const useResource = ({
  resourceType,
  resourceName,
  agentId,
  cluster,
  namespace = "",
  existData,
  executeMutation = true,
  preferredResultType,
  fetchFromDb = true,
}: UseResourceParams): {
  resource: Resource | undefined;
  failureMessage: string;
  clearState: () => void;
  isFetching: boolean;
} => {
  const [atmResult, setAtmResult] = useState<AtmGetResourceResponseType>();

  const accountName = useAccountName();
  const serviceId =
    accountName && cluster && namespace && resourceName
      ? buildServiceId(accountName, cluster, namespace, resourceName)
      : undefined;
  const [komodorServiceAsResource, isFetchingKomodorService] =
    useKomodorServiceAsResource({
      serviceId,
      expectedKind: resourceType,
      resultData: atmResult?.data,
      fetchFromDb,
    });
  return useResourceFromATM({
    resourceType,
    resourceName,
    agentId,
    cluster,
    namespace,
    existData,
    executeMutation,
    preferredResultType,
    atmResult,
    setAtmResult,
    komodorServiceAsResource,
    isFetchingKomodorService,
  });
};

const useResourceFromATM = ({
  resourceType,
  resourceName,
  agentId,
  cluster,
  namespace = "",
  existData,
  executeMutation = true,
  preferredResultType,
  atmResult,
  setAtmResult,
  komodorServiceAsResource,
  isFetchingKomodorService,
}: UseResourceParams & {
  atmResult: AtmGetResourceResponseType | undefined;
  setAtmResult: (res: AtmGetResourceResponseType | undefined) => void;
  komodorServiceAsResource: Resource | undefined;
  isFetchingKomodorService: boolean;
}): {
  resource: Resource | undefined;
  failureMessage: string;
  clearState: () => void;
  isFetching: boolean;
} => {
  const { inspectionAtm } = useOverridableFlags();
  const { agentProperties: agentInfo } = useAgentInfoById(agentId, cluster);
  const atmSupported = isAgentAtmSupported({
    agentInfo,
    inspectionAtm: !!inspectionAtm,
    agentId,
  });
  const isDeletedPod = resourceType === DELETED_POD_KIND;

  const existMetadata = useMemo(
    () => get(existData, "metadata", {}),
    [existData]
  );

  const deletedPodData = useDeletedPodsData({
    id: get(existMetadata, "id", ""),
    isDeletedPod,
  });

  const {
    execute: getResource,
    failureMessage,
    result,
    isFetching: isFetchingGetResource,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, {
    namespace,
    type: ActionTypes.GetResource,
    resourceType: resourceType,
    resourceName,
    isJsonOutput: true,
  });

  const clearState = () => {
    setAtmResult(undefined);
  };

  const {
    execute: getResourceAtm,
    status: getResourceAtmStatus,
    isFetching: isFetchingGetResourceAtm,
    errorMessage,
  } = useAtmGetResource(
    agentId,
    cluster,
    namespace,
    resourceType,
    resourceName,
    AtmOutputType.Json,
    (response: AtmGetResourceResponseType) => setAtmResult(response)
  );

  useEffect(() => {
    if (!executeMutation) return;
    if (
      !komodorServiceAsResource ||
      preferredResultType === ResourceResultType.RawResource
    ) {
      if (atmSupported) {
        if (
          !(
            (atmResult && !atmResult.isPaginating) ||
            isFetchingGetResourceAtm ||
            errorMessage ||
            atmResult?.failureMessage
          )
        ) {
          getResourceAtm();
        }
      } else if (!result && !failureMessage && !isFetchingGetResource) {
        getResource();
      }
    }
  }, [
    atmResult,
    atmSupported,
    errorMessage,
    executeMutation,
    failureMessage,
    getResource,
    getResourceAtm,
    isFetchingGetResource,
    isFetchingGetResourceAtm,
    komodorServiceAsResource,
    preferredResultType,
    result,
  ]);

  const parsedResource = useParsedResource(
    isDeletedPod
      ? deletedPodData.data[0]
      : atmResult?.data ?? (result || existData),
    resourceType,
    cluster,
    agentId,
    get(existData, "calculatedStatus"),
    get(existData, "isCustomResource")
  );

  const resource = getResultByPreferredType({
    komodorServiceAsResource,
    parsedResource,
    preferredResultType,
  });

  return {
    resource,
    failureMessage,
    clearState,
    isFetching:
      isFetchingKomodorService ||
      isFetchingGetResource ||
      getResourceAtmStatus === Status.Initializing,
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useResourceWithInterval;
