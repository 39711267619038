import { Dictionary } from "lodash";

import {
  WorkflowEngine,
  WorkflowEngineType,
  WorkflowsRoutes,
} from "@/components/k8sAddons/addons/workflows/types";

export const LabelWorkflowEngine = "app.komodor.com/WorkflowEngine";
export const LabelWorkflowDagId = "app.komodor.com/WorkflowDagId";
export const LabelWorkflowRunId = "app.komodor.com/WorkflowRunId";
export const LabelWorkflowTaskId = "app.komodor.com/WorkflowTaskId";

export const LabelAirflowDagId = "dag_id";
export const LabelAirflowRunId = "run_id";
export const LabelAirflowTaskId = "task_id";
export const LabelAirflowTryNumber = "try_number";
export const LabelAirflowExecutionDate = "execution_date";
export const KomodorAirflowRunIdPrefix = "komodor_run_id";
export const KomodorAirflowRunIdPrefixDelimiter = "_";

export const LabelSparkRunId = "spark-app-selector";
export const LabelSparkAppDagId = "sparkoperator.k8s.io/app-name";
export const LabelSparkScheduledAppDagId =
  "sparkoperator.k8s.io/scheduled-app-name";

const isAirflowResource = (labels: Dictionary<string>): boolean => {
  const hasDagId = !!labels[LabelAirflowDagId];
  const hasRunId = !!labels[LabelAirflowRunId];
  const hasTaskId = !!labels[LabelAirflowTaskId];
  const hasExecutionDate = !!labels[LabelAirflowExecutionDate];

  return (
    (hasDagId && hasRunId && hasTaskId) ||
    (hasDagId && !hasRunId && hasTaskId && hasExecutionDate)
  );
};
const isSparkResource = (labels: Dictionary<string>): boolean => {
  const hasDagId =
    !!labels[LabelSparkScheduledAppDagId] || !!labels[LabelSparkAppDagId];
  const hasRunId = !!labels[LabelSparkRunId];

  return (hasDagId && hasRunId) || (!hasDagId && hasRunId);
};
const getWorkflowEngine = (
  labels: Record<string, string>
): string | undefined => {
  if (isAirflowResource(labels)) {
    return WorkflowEngine.Airflow;
  }
  if (isSparkResource(labels)) {
    return WorkflowEngine.Spark;
  }
  return labels[LabelWorkflowEngine];
};

const getWorkflowDagId = (
  labels: Record<string, string>
): string | undefined => {
  return (
    labels[LabelWorkflowDagId] ||
    labels[LabelAirflowDagId] ||
    labels[LabelSparkScheduledAppDagId] ||
    labels[LabelSparkAppDagId]
  );
};

const komodorAirflowRunId = (
  labels: Dictionary<string>
): string | undefined => {
  const label = labels[LabelAirflowExecutionDate];
  if (!label) {
    return undefined;
  }
  return `${KomodorAirflowRunIdPrefix}${KomodorAirflowRunIdPrefixDelimiter}${label}`;
};
const getWorkflowRunId = (labels: Dictionary<string>): string | undefined => {
  return (
    labels[LabelWorkflowRunId] ||
    labels[LabelAirflowRunId] ||
    komodorAirflowRunId(labels) ||
    labels[LabelSparkRunId]
  );
};

const getWorkflowTaskId = (labels: Dictionary<string>): string | undefined => {
  return labels[LabelWorkflowTaskId] || labels[LabelAirflowTaskId];
};

const getWorkflowTryNumber = (
  labels: Dictionary<string>
): string | undefined => {
  return labels[LabelAirflowTryNumber];
};

export const isWorkflowResource = (labels: Dictionary<string>): boolean => {
  return !!getWorkflowDagId(labels) && !!getWorkflowRunId(labels);
};

export const isKomodorRunIdPrefix = (runId: string): boolean => {
  return runId.startsWith(KomodorAirflowRunIdPrefix);
};

export const getLabelWithoutKomodorRunIdPrefix = (runId: string): string => {
  return runId.replace(
    `${KomodorAirflowRunIdPrefix}${KomodorAirflowRunIdPrefixDelimiter}`,
    ""
  );
};

export type WorkflowLabels = {
  engine: string;
  dagId: string;
  runId: string;
  taskId: string;
  tryNumber: string;
};
export const getWorkflowLabels = (
  labels: Dictionary<string>
): WorkflowLabels => {
  return {
    engine: getWorkflowEngine(labels) ?? "",
    dagId: getWorkflowDagId(labels) ?? "",
    runId: getWorkflowRunId(labels) ?? "",
    taskId: getWorkflowTaskId(labels) ?? "",
    tryNumber: getWorkflowTryNumber(labels) ?? "",
  };
};

export const getWorkflowRoute = (
  engine: WorkflowEngineType
): WorkflowsRoutes => {
  switch (engine) {
    case WorkflowEngine.Airflow:
      return WorkflowsRoutes.airflow;
    case WorkflowEngine.Spark:
      return WorkflowsRoutes.spark;
    default:
      return WorkflowsRoutes.custom;
  }
};

export const getWorkflowPodLabelsSelector = (
  labels: Record<string, string> | undefined
): Record<string, string> | undefined => {
  if (!labels) {
    return undefined;
  }

  const engine = getWorkflowEngine(labels);
  const labelsSelector: Record<string, string> = {};

  const addLabelIfExists = (key: string) => {
    if (labels[key]) {
      labelsSelector[key] = labels[key];
    }
  };

  switch (engine) {
    case WorkflowEngine.Airflow:
      addLabelIfExists(LabelAirflowDagId);
      addLabelIfExists(LabelAirflowRunId);
      addLabelIfExists(LabelAirflowExecutionDate);
      break;
    case WorkflowEngine.Spark:
      addLabelIfExists(LabelSparkRunId);
      break;
    default:
      addLabelIfExists(LabelWorkflowDagId);
      addLabelIfExists(LabelWorkflowRunId);
      break;
  }

  return labelsSelector;
};
