import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import {
  AdvancedMultiSelect,
  TextInput,
} from "@komodorio/design-system/komodor-ui";

import { UserSettingsWrapper } from "@/pages/user-settings/UserSettingsWrapper";
import isElectronContext from "@/shared/context/electron/isElectronContext";
import TimezoneSelect from "@/components/Settings/General/TimezoneSelect";
import { useProfilePage } from "@/pages/user-settings/useProfilePage";
import { ariaLabels } from "@/pages/user-settings/constants";
import { useUserLoginInfo } from "@/shared/hooks/useUserMetadata/useUserLoginInfo";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const WIDTH = "360px";

export const ProfileComponent: React.FC = () => {
  const { userRoles, user } = useProfilePage();

  // We get the email from the Auth0 too, in case we are in switch-account mode
  const { email: fallbackEmail, name: fallbackName } = useUserLoginInfo() || {};
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isUserDefined", !user);
  }, [user, reportLoadingState]);

  return (
    <UserSettingsWrapper title="Profile">
      <Stack direction="column" spacing={2} sx={{ width: WIDTH }}>
        <TextInput
          size="medium"
          label="Name"
          defaultValue={user?.displayName ?? fallbackName}
          disabled
          fullWidth
          aria-label={ariaLabels.name}
        />
        {!isElectronContext() && (
          <TextInput
            size="medium"
            label="Email"
            disabled
            defaultValue={user?.email ?? fallbackEmail}
            fullWidth
            aria-label={ariaLabels.email}
          />
        )}
        <TimezoneSelect
          width={WIDTH}
          selectPopoverProps={{
            height: "200px",
            width: WIDTH,
          }}
        />
        {!!userRoles && (
          <AdvancedMultiSelect
            size="medium"
            width={WIDTH}
            label="Roles"
            value={userRoles}
            disabled
            options={userRoles}
            showTooltip
            tooltipPlacement="right"
            ariaLabel={ariaLabels.roles}
          />
        )}
      </Stack>
    </UserSettingsWrapper>
  );
};

export const ProfilePage: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.userSettingsProfilePage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <ProfileComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
