import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";

import {
  apiV1ConfigurationsGetUrl,
  ConfigurationsApiApiV1ConfigurationsGetRequest,
  MonitorConfiguration,
} from "@/generated/monitorsApi";

export const MONITORS_CONFIGURATIONS_PATH = "/configurations";

const fetchMonitorsConfigurations = async (
  apiClient: AxiosInstance,
  params: ConfigurationsApiApiV1ConfigurationsGetRequest
) => {
  const { data } = await apiClient.get<MonitorConfiguration[]>(
    apiV1ConfigurationsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetMonitorsConfigurations = (
  params: ConfigurationsApiApiV1ConfigurationsGetRequest
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery([MONITORS_CONFIGURATIONS_PATH, params], () =>
    fetchMonitorsConfigurations(apiClient, params)
  );
};
