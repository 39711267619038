import { useCallback } from "react";
import {
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";

import {
  auditPageAriaLabels,
  TIME_RANGE_OPTIONS,
} from "@/pages/organization-settings/account/AuditPage/constants";
import { useAuditLogsContext } from "@/pages/organization-settings/account/AuditPage/context/useAuditLogsContext";

export const TimeRangeSelector: React.FC = () => {
  const { setConfigurationData, ...context } = useAuditLogsContext();

  const handleChange = useCallback(
    (selectedOption: MuiSelectionOption<string> | undefined) => {
      setConfigurationData({ dateRange: selectedOption });
    },
    [setConfigurationData]
  );

  return (
    <SingleSelect
      options={TIME_RANGE_OPTIONS}
      onChange={handleChange}
      value={context.dateRange}
      width={"200px"}
      ariaLabel={auditPageAriaLabels.filters.timeRange}
    />
  );
};
