import React from "react";

import { SelectorTypes } from "../../common/types";
import { RuleStepSectionContainer } from "../common/RuleStepSectionContainer";
import SinkSection from "../common/SinkSection";
import ScopeSection from "../common/ScopeSection";
import TriggerConditionsSection from "../common/TriggerConditionsSection";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

import {
  ConfigurationSensor,
  ConfigurationVariables,
  MonitorConfiguration,
  MonitorType,
} from "@/generated/monitorsApi";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const pvcDefaultRule: MonitorConfiguration = {
  id: "",
  name: "",
  type: MonitorType.Pvc,
  active: true,
  sensors: [{ cluster: "" }],
  variables: {
    duration: 30,
  },
};

const PVCTriggerText = (
  <>
    PVC in <b>Pending</b> state for more than
  </>
);

const PVCRuleSections: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleSensorChange = (sensor: ConfigurationSensor) => {
    setRule((prev) => ({
      ...prev,
      sensors: [sensor],
    }));
  };
  const handleVariablesChange = (variables: ConfigurationVariables) => {
    setRule((prev) => ({
      ...prev,
      variables,
    }));
  };

  return (
    <>
      <RuleStepSectionContainer sectionNumber={2} title="Trigger conditions">
        <TriggerConditionsSection
          id="conditions"
          variables={rule.variables}
          handleChange={handleVariablesChange}
          triggerText={PVCTriggerText}
        />
      </RuleStepSectionContainer>
      {rule.sensors && rule.sensors.length > 0 && (
        <RuleStepSectionContainer sectionNumber={3} title="Select Scope:">
          <ScopeSection
            id="scope"
            sensor={rule.sensors[0]}
            selectorTypes={SelectorTypes}
            handleChange={handleSensorChange}
          />
        </RuleStepSectionContainer>
      )}
      {!isElectronContext() && (
        <RuleStepSectionContainer
          sectionNumber={4}
          title="Where do you want to receive notifications? (Optional)"
        >
          <SinkSection rule={rule} setRule={setRule} />
        </RuleStepSectionContainer>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PVCRuleSections;
