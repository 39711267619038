import React from "react";

import ResponsiveLayout from "../common/ResponsiveLayout";
import { useKomodorServices } from "../../shared/hooks/useKomodorServices";
import { useServicesPageFiltersLocalStorageContext } from "../../shared/context/ServicesPageFiltersLocalStorageProvider";

import { ExploreKomodorServices } from "./ExploreKomodorServices";
import { KomodorServiceType } from "./types";

import { useQueryStringInLocalStorage } from "@/shared/hooks/state/useQueryStringInLocalStorage";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isKindJobs = (kind: string | null | undefined): boolean =>
  ["Job", "CronJob"].includes(kind ?? "");

const ServicesView: React.FC = () => {
  const services = useKomodorServices().services;
  const storedServicesViewQS = useServicesPageFiltersLocalStorageContext();

  useQueryStringInLocalStorage({ item: storedServicesViewQS });

  return (
    <>
      <ResponsiveLayout>
        <ExploreKomodorServices
          komodorServices={services}
          type={KomodorServiceType.service}
        />
      </ResponsiveLayout>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ServicesView;
