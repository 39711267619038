import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { get, merge, set, clone, cloneDeep } from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { useIsEmailDigestFormDisabled } from "./useIsEmailDigestFormDisabled";

import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";
import { DataKeyToLabel } from "@/components/Settings/email/emailSettingsConstants";
import { StyledCheckbox } from "@/components/Settings/email/emailSettingsStyles";
import { emailSettingsAriaLabels } from "@/components/Settings/email/emailSettingsAriaLabels";

const ContentContainer = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
`;

type CheckboxGroupControllerProps = PropsWithChildren<{
  groupKey: string;
  childrenContextKey: string;
}>;

export const CheckboxGroupController: React.FC<
  CheckboxGroupControllerProps
> = ({ groupKey, childrenContextKey, children }) => {
  const { setConfigurationData, ...context } = useEmailSettingsContext();
  const value: boolean = get(context, groupKey) ?? false;
  const label: string = get(DataKeyToLabel, groupKey) ?? "";
  const childrenValues: boolean[] = Object.values(
    get(context, childrenContextKey)
  );

  const isIndeterminate = useMemo(() => {
    return (
      childrenValues.some((value) => value) &&
      childrenValues.some((value) => !value)
    );
  }, [childrenValues]);

  const getAreSomeOptionsEnabled = useCallback(() => {
    return childrenValues.some((value) => value);
  }, [childrenValues]);

  useEffect(() => {
    const anyEnabled = getAreSomeOptionsEnabled();
    if (anyEnabled !== value) {
      const newEnabledState = set(clone(context), groupKey, anyEnabled);
      setConfigurationData(merge(context, newEnabledState));
    }
  }, [
    context,
    groupKey,
    getAreSomeOptionsEnabled,
    setConfigurationData,
    value,
  ]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    const currentChildrenState: boolean[] = get(context, childrenContextKey);

    const newChildrenState = Object.keys(currentChildrenState).reduce(
      (acc, key) => ({ ...acc, [key]: newValue }),
      {}
    );
    const contentClone = cloneDeep(context);
    const newContent = set(contentClone, childrenContextKey, newChildrenState);
    const newEnabledState = set(contentClone, groupKey, newValue);

    setConfigurationData(merge(newContent, newEnabledState));
  };

  const ariaLabel = get(emailSettingsAriaLabels, groupKey) ?? "";
  const isEmailDigestFormDisabled = useIsEmailDigestFormDisabled();

  return (
    <FormGroup>
      <FormControlLabel
        label={<Typography variant={"body2"}>{label}</Typography>}
        control={
          <StyledCheckbox
            checked={value}
            onChange={onChange}
            indeterminate={isIndeterminate}
            aria-label={`${ariaLabel} checked: ${value}`}
            disabled={isEmailDigestFormDisabled}
          />
        }
      />
      <ContentContainer>{children}</ContentContainer>
    </FormGroup>
  );
};
