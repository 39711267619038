import React from "react";

import Resource, { ResourceTab, ResourceTabName } from ".";

import { WorkflowDag as WorkflowDagType } from "@/generated/addonsApi";
import WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { WorkflowEngineType } from "@/components/k8sAddons/addons/workflows/types";
import { WorkflowDagContent } from "@/components/k8sAddons/addons/workflows/DagView/WorkflowDagContent";
import { WorkflowDagHeader } from "@/components/k8sAddons/addons/workflows/DagView/WorkflowDagHeader";
import { K8S_ADDONS_WORKFLOWS_ROUTE } from "@/components/routes/routes";
import { getWorkflowRoute } from "@/components/k8sAddons/addons/workflows/workflowUtils";

export const WORKFLOW_DAG_KIND = "WorkflowDag";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class WorkflowDag implements Resource {
  readonly agentId = "";
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly engine;
  readonly labels = {};
  readonly annotations = {};
  readonly drawerTabs: ResourceTab[] = [
    { label: ResourceTabName.Events },
    { label: ResourceTabName.Pods },
    { label: ResourceTabName.Logs },
  ];
  readonly defaultTab;
  readonly actions = [];

  readonly lastRun;

  constructor(wfDag: WorkflowDagType) {
    this.id = wfDag.komodorWorkflowId ?? "";
    this.cluster = wfDag.clusterName ?? "";
    this.namespace = wfDag.namespace ?? "";
    this.name = wfDag.dagId ?? "";
    this.kind = wfDag.dagKind ?? WORKFLOW_DAG_KIND;
    this.engine = wfDag.workflowEngine as WorkflowEngineType;
    this.lastRun = new WorkflowRun(wfDag.lastRun);
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return <WorkflowDagContent workflowDag={this} selectedTab={selectedTab} />;
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return <WorkflowDagHeader resource={this} leftActions={leftActions} />;
  }

  getFullScreenUrl(): string {
    return `${K8S_ADDONS_WORKFLOWS_ROUTE}/${getWorkflowRoute(this.engine)}/${
      this.id
    }`;
  }

  getBackUrl(): string {
    return `${K8S_ADDONS_WORKFLOWS_ROUTE}/${getWorkflowRoute(this.engine)}`;
  }
}
