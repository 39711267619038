import { useMemo } from "react";
import { isToday } from "date-fns";

import { TimeStampDataPoint } from "../../types/overviewPageTypes";
import { removeTimezoneOffset } from "../../../../../../../../shared/utils/dateUtils";
import { Dictionary } from "../../../../../../../../shared/types/Dictionary";

export const useTimestampIndexDictionary = (data: TimeStampDataPoint[]) => {
  return useMemo(() => {
    if (!data?.length) return {};
    const formattedTick = removeTimezoneOffset(
      new Date(data[data.length - 1].ts)
    );
    const lastTimeStampFormat = isToday(formattedTick) ? "Today" : "Yesterday";

    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
    return data.reduce<Dictionary<string>>((acc, curr, idx) => {
      if (idx < data.length - 1) {
        // this assumes bucketTimeFrame is 24h
        acc[curr.ts] = `${data.length - idx}d`;
      } else {
        acc[curr.ts] = lastTimeStampFormat;
      }

      return acc;
    }, {});
  }, [data]);
};
