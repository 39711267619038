import {
  Workflows24,
  LogoIconProps,
  Spark,
} from "@komodorio/design-system/icons";
import { Airflow } from "@komodorio/design-system/icons/logos/Airflow";
import { Argo } from "@komodorio/design-system/icons/logos/Argo";

import {
  WorkflowEngine,
  WorkflowEngineType,
} from "@/components/k8sAddons/addons/workflows/types";

const workflowEngineIcon: { [key in WorkflowEngine]: React.FC<LogoIconProps> } =
  {
    [WorkflowEngine.Airflow]: Airflow,
    [WorkflowEngine.Spark]: Spark,
    [WorkflowEngine.Argo]: Argo,
  };

export const WorkflowEngineIcon: React.FC<{
  engineType: WorkflowEngineType;
}> = ({ engineType }) => {
  if (engineType in workflowEngineIcon) {
    const EngineIcon = workflowEngineIcon[engineType as WorkflowEngine];
    return <EngineIcon />;
  }

  return <Workflows24 />;
};
