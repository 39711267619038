/* eslint-disable max-lines */
import React from "react";
import { Deployment as DeploymentType } from "kubernetes-types/apps/v1.d";
import { LabelSelector } from "kubernetes-types/meta/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Deploy } from "@komodorio/design-system/icons";
import { parseISO } from "date-fns";
import { compact } from "lodash";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodsTab from "../tabs/PodsTab/PodsTab";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import { isServiceHealthy } from "../../../shared/utils/serviceHelpers";
import {
  extractLastModifyFromService,
  extractReplicasFromService,
} from "../../../shared/utils/postgresMigrationUtils";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import EventsTab from "../tabs/EventsTab/EventsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import InfoTab from "../tabs/InfoTab/InfoTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { KomodorServiceHeader } from "../headers/KomodorServiceHeader";
import NodesTab from "../tabs/NodesTab/NodesTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DependenciesTab } from "../tabs/DependenciesTab/DependenciesTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { InsightsTab } from "../tabs/InsightsTab/InsightsTab";
import { WorkloadMetricsTab } from "../tabs/WorkloadMetricsTab/WorkloadMetricsTab";
import { SERVICES } from "../../routes/routes";
import ServiceLogsTab from "../tabs/ServiceLogsTab/ServiceLogsTab";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { isCurrentlyDeploying, isExternalDNS } from "./utils";

import Resource, { KomodorServiceType, ResourceTabName } from ".";

import { ExternalDNSSummaryTab } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/ExternalDNSSummaryTab";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { IssueBannersForService } from "@/components/ResourceView/banners/service/IssueBannersForService";

type DeploymentProps = {
  cluster?: string;
  deployment?: DeploymentType;
  agentId?: string;
  komodorService?: KomodorServiceType;
  featureFlags?: Record<string, unknown>;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class Deployment implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly icon = Deploy;
  readonly drawerTabs;
  readonly actions = [
    AvailableActions.DetectDrift,
    AvailableActions.Describe,
    AvailableActions.Restart,
    AvailableActions.Edit,
    AvailableActions.Scale,
    AvailableActions.Rollback,
    AvailableActions.GitRevert,
    AvailableActions.Configure,
    AvailableActions.Compare,
    AvailableActions.Delete,
  ];

  readonly healthy;
  readonly replicas;
  readonly selector;
  readonly containers;
  readonly k8sUid;
  readonly currentlyDeploying;
  readonly lastModified;
  readonly lastDeployEndDate;
  readonly lastDeployStartDate: Date | undefined;
  readonly datadogData;
  readonly sentryData;
  readonly isDeleted: boolean | undefined = false;
  readonly inactive: boolean | undefined = false;
  readonly deletedAt;
  readonly fullObj;
  readonly featureFlags: Record<string, unknown>;
  readonly defaultTab;
  readonly komodorUid;
  readonly isExternalDNS: boolean = false;

  constructor({
    cluster,
    deployment,
    agentId,
    komodorService,
    featureFlags,
  }: DeploymentProps) {
    this.featureFlags = featureFlags ?? {};
    this.containers = deployment?.spec?.template?.spec?.containers;
    if (komodorService) {
      this.agentId = komodorService.agentId ?? "";
      this.id = komodorService.id ?? "";
      this.cluster = komodorService.k8sClusterName ?? "";
      this.namespace = komodorService.namespace ?? "";
      this.name = komodorService.displayName ?? "";
      this.kind = komodorService.kind ?? "";
      this.komodorUid = buildKomodorUid({
        resourceName: this.name,
        clusterName: this.cluster,
        namespace: this.namespace,
        kind: this.kind,
      });
      this.labels = (komodorService.k8s_metadata?.labels ?? {}) as {
        [key: string]: string;
      };
      this.isExternalDNS = isExternalDNS(this.labels);
      this.annotations = (komodorService.k8s_metadata?.annotations ?? {}) as {
        [key: string]: string;
      };
      this.healthy = isServiceHealthy(
        komodorService.health_state?.healthStatus
      );
      this.replicas = extractReplicasFromService(komodorService);
      this.k8sUid = komodorService?.k8s_metadata?.k8sUid;
      this.selector = komodorService.k8s_metadata?.selector as
        | LabelSelector
        | undefined;
      this.datadogData = komodorService.datadog_data;
      this.sentryData = komodorService.sentry_data;

      const lastDeployEndTime = komodorService.deploy_state?.lastDeployEndTime;
      const lastDeployStartTime =
        komodorService.deploy_state?.lastDeployStartTime;
      this.currentlyDeploying = isCurrentlyDeploying(
        lastDeployStartTime,
        lastDeployEndTime
      );
      this.lastModified = extractLastModifyFromService(komodorService);
      this.lastDeployEndDate = lastDeployEndTime
        ? parseISO(lastDeployEndTime)
        : undefined;
      this.lastDeployStartDate = lastDeployStartTime
        ? parseISO(lastDeployStartTime)
        : undefined;
      this.isDeleted = komodorService.isDeleted;
      this.deletedAt = komodorService.deletedAt
        ? parseISO(komodorService.deletedAt)
        : undefined;

      this.inactive = komodorService.inactive;
    } else {
      this.fullObj = deployment;
      this.agentId = agentId ?? "";
      this.id = deployment?.metadata?.uid ?? "";
      this.cluster = cluster ?? "";
      this.namespace = deployment?.metadata?.namespace ?? "";
      this.name = deployment?.metadata?.name ?? "";
      this.kind = deployment?.kind ?? "";
      this.komodorUid = buildKomodorUid({
        resourceName: this.name,
        clusterName: this.cluster,
        namespace: this.namespace,
        kind: this.kind,
      });
      this.labels = deployment?.metadata?.labels ?? {};
      this.isExternalDNS = isExternalDNS(this.labels);
      this.annotations = deployment?.metadata?.annotations ?? {};
      this.replicas = {
        ready: deployment?.status?.readyReplicas ?? 0,
        desired: deployment?.status?.replicas ?? 0,
      };
      this.selector = deployment?.spec?.selector;
    }
    this.defaultTab = 0;
    this.drawerTabs = compact([
      this.featureFlags.showExternalDns && this.isExternalDNS
        ? { label: ResourceTabName.AddonSummary }
        : undefined,
      { label: ResourceTabName.Events },
      {
        label: ResourceTabName.Logs,
        disabled: this.isDeleted || this.inactive,
      },
      {
        label: ResourceTabName.Pods,
        disabled: this.isDeleted || this.inactive,
      },
      {
        label: ResourceTabName.Nodes,
        disabled: this.isDeleted || this.inactive,
      },
      { label: ResourceTabName.Info },
      { label: ResourceTabName.Metrics },
      this.featureFlags.resourceDependenciesUi
        ? { label: ResourceTabName.Dependencies }
        : undefined,
      this.featureFlags.insightsServiceView
        ? { label: ResourceTabName.Insights }
        : undefined,
      { label: ResourceTabName.Yaml },
    ]);
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    let i = 0;
    const getNextIndex = () => i++;

    return (
      <>
        {this.featureFlags.showExternalDns && this.isExternalDNS && (
          <TabPanel
            aria-label={AriaLabels.ResourceView.SummaryTab}
            eager
            value={selectedTab}
            index={getNextIndex()}
          >
            <ExternalDNSSummaryTab resource={this} />
          </TabPanel>
        )}
        <TabPanel
          aria-label={AriaLabels.ResourceView.EventsTab}
          eager
          value={selectedTab}
          index={getNextIndex()}
        >
          <EventsTab resource={this} key={this.id} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.LogsTab}
          value={selectedTab}
          index={getNextIndex()}
        >
          <ServiceLogsTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.PodsTab}
          eager
          value={selectedTab}
          index={getNextIndex()}
        >
          <PodsTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.NodesTab}
          eager
          value={selectedTab}
          index={getNextIndex()}
        >
          <NodesTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.InfoTab}
          eager
          value={selectedTab}
          index={getNextIndex()}
        >
          <InfoTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.MetricsTab}
          value={selectedTab}
          index={getNextIndex()}
          eager
        >
          <WorkloadMetricsTab resource={this} />
        </TabPanel>
        {this.featureFlags.resourceDependenciesUi && (
          <TabPanel eager value={selectedTab} index={getNextIndex()}>
            <DependenciesTab resource={this} />
          </TabPanel>
        )}
        {this.featureFlags.insightsServiceView && (
          <TabPanel eager value={selectedTab} index={getNextIndex()}>
            <InsightsTab resource={this} />
          </TabPanel>
        )}
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <KomodorServiceHeader
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }

  renderDrawerMessage() {
    return <IssueBannersForService service={this} />;
  }

  getFullScreenUrl(): string {
    return `/${SERVICES}/${this.id}`;
  }
}
