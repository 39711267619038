import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";

import {
  APIKey,
  CreateAPIKeyRequest,
  apiV1ApiKeysPostUrl,
} from "@/generated/auth";

const CREATE_API_KEY_PATH = "/api-keys";

const createApiKey = async (
  apiClient: AxiosInstance,
  params: CreateAPIKeyRequest
): Promise<APIKey> => {
  const { data } = await apiClient.post(
    apiV1ApiKeysPostUrl(
      { createAPIKeyRequest: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useCreateApiKey = () => {
  const apiClient = useAuthApiClient();
  return useMutation([CREATE_API_KEY_PATH], (params: CreateAPIKeyRequest) =>
    createApiKey(apiClient, params)
  );
};
