import React from "react";

import { SelectorTypes } from "../../common/types";
import { RuleStepSectionContainer } from "../common/RuleStepSectionContainer";
import SinkSection from "../common/SinkSection";
import ScopeSection from "../common/ScopeSection";
import { Label } from "../common/styles";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

import DeploySinksOptions from "./DeploySinksSections";

import {
  ConfigurationSensor,
  DeployNotifyOn,
  MonitorConfiguration,
  MonitorType,
} from "@/generated/monitorsApi";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const deployDefaultRule: MonitorConfiguration = {
  id: "",
  name: "",
  type: MonitorType.Deploy,
  active: true,
  sensors: [{ cluster: "" }],
  sinksOptions: { notifyOn: [DeployNotifyOn.All] },
};

const DeployTriggerText = <>A Deploy occurs, workload generation has changed</>;

const DeployRuleSections: React.VFC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleSensorChange = (sensor: ConfigurationSensor) => {
    setRule((prev) => ({
      ...prev,
      sensors: [sensor],
    }));
  };

  return (
    <>
      <RuleStepSectionContainer sectionNumber={2} title="Trigger conditions">
        <Label>{DeployTriggerText}</Label>
      </RuleStepSectionContainer>
      {rule.sensors && rule.sensors.length > 0 && (
        <RuleStepSectionContainer sectionNumber={3} title="Select Scope:">
          <>
            This defines the scope for notifications only
            <br />
            <br />
            <ScopeSection
              id="scope"
              sensor={rule.sensors[0]}
              selectorTypes={SelectorTypes}
              handleChange={handleSensorChange}
            />
          </>
        </RuleStepSectionContainer>
      )}

      {!isElectronContext() && (
        <RuleStepSectionContainer
          sectionNumber={4}
          title="Where do you want to receive notifications? (Optional)"
        >
          <DeploySinksOptions rule={rule} setRule={setRule} />
          <br />
          <SinkSection rule={rule} setRule={setRule} />
        </RuleStepSectionContainer>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DeployRuleSections;
