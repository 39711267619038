import { useCallback, useMemo } from "react";
import { noop } from "lodash";

import { LocalStorageItem } from "../utils/localStorageSettings";

import { notifyDDError } from "./exceptionManagement";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";

const useGetCurrentStateAsJSON = <T extends object>(state?: string | null) => {
  return useMemo<T>(() => {
    let parsedState;
    try {
      parsedState = state ? JSON.parse(state) : {};
    } catch (error) {
      notifyDDError(error as Error, {
        name: "useGetCurrentStateAsJSON",
        message: `Error parsing JSON: ${error}`,
      });
      parsedState = {};
    }
    return parsedState;
  }, [state]);
};

export const useStateInUrlAsJSON = <T extends object>(
  paramKey: string
): [T, (data: Partial<T> | null, replace?: boolean) => void] => {
  const [dataInUrl, setDataInUrl] = useStateInSearchParams(paramKey);

  const currentState = useGetCurrentStateAsJSON<T>(dataInUrl);

  const onsetDataInUrl = useCallback(
    (data: Partial<T> | null, replace?: boolean) => {
      try {
        const state =
          data !== null
            ? JSON.stringify({
                ...currentState,
                ...data,
              })
            : null;
        setDataInUrl(state, replace);
      } catch (error) {
        notifyDDError({
          name: "useStateInUrlAsJSON",
          message: `Error stringifying JSON: ${error}`,
        });
        noop();
      }
    },
    [currentState, setDataInUrl]
  );

  return [currentState, onsetDataInUrl];
};

export const useStateInLocalStorageAsJSON = <T extends object>(
  item: LocalStorageItem
): [T, (data: Partial<T>, resetExistingData?: boolean) => void] => {
  const [dataInLocalStorage, setDataInLocalStorage] = useStateInLocalStorage(
    item,
    ""
  );

  const currentState = useGetCurrentStateAsJSON<T>(dataInLocalStorage);

  const onsetDataInLocalStorage = useCallback(
    (data: Partial<T>, resetExistingData?: boolean) => {
      try {
        const state = JSON.stringify({
          ...(!resetExistingData && currentState),
          ...data,
        });
        setDataInLocalStorage(state);
      } catch (error) {
        notifyDDError({
          name: "useStateInLocalStorageAsJSON",
          message: `Error stringifying JSON: ${error}`,
        });
        noop();
      }
    },
    [currentState, setDataInLocalStorage]
  );
  return [currentState, onsetDataInLocalStorage];
};
