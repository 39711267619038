import { useCallback } from "react";

import {
  InspectionStore,
  useInspectionStore,
} from "../../../shared/store/inspection/inspectionStore";
import {
  selectedNamespacesSelector,
  setNamespacesSelector,
} from "../../../shared/store/inspection/inspectionSelectors";

import { NamespacesInLS, namespacesInLsItem } from "./localStorage";

import { useLocalStorage } from "@/shared/hooks/localStorage/useLocalStorage";

/**
 * @param resourceKeyInLS if the param is not provided, the namespaces will be managed in the store
 * @returns namespaces getter and setter
 */
export const useNamespacesFilter = (
  resourceKeyInLS?: string
): [
  InspectionStore["selectedNamespaces"],
  InspectionStore["setSelectedNamespaces"]
] => {
  const selectedNamespaces = useInspectionStore(selectedNamespacesSelector);
  const setNamespaces = useInspectionStore(setNamespacesSelector);

  const [namespacesInLs, setNamespcesInLs] = useLocalStorage<NamespacesInLS>({
    localStorageItem: namespacesInLsItem,
    defaultValue: {},
  });

  const setNamespacesInLs = useCallback(
    (namespaces: string[]) => {
      if (!resourceKeyInLS) return;
      const namespacesObj = {
        ...namespacesInLs,
        [resourceKeyInLS]: namespaces,
      };
      setNamespcesInLs(JSON.stringify(namespacesObj));
    },
    [namespacesInLs, resourceKeyInLS, setNamespcesInLs]
  );

  if (!resourceKeyInLS) {
    return [selectedNamespaces, setNamespaces];
  }

  const resourceNamespaces = namespacesInLs[resourceKeyInLS];

  return [resourceNamespaces ?? selectedNamespaces, setNamespacesInLs];
};
