import React, { useCallback, useMemo } from "react";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";
import { GridSortModel } from "@mui/x-data-grid-pro";
import Skeleton from "@mui/material/Skeleton";

import {
  getMockRows,
  sortingModelToString,
  sortingStringToModel,
} from "@/pages/organization-settings/account/AuditPage/utils";
import { getColumnsDefinition } from "@/pages/organization-settings/account/AuditPage/gridHelpers";
import { useAuditLogsContext } from "@/pages/organization-settings/account/AuditPage/context/useAuditLogsContext";
import { DEFAULT_PAGE_SIZE } from "@/pages/organization-settings/account/AuditPage/constants";
import { useAccountUsersContext } from "@/shared/context/users/useAccountUsersContext";
import { useTimezone } from "@/shared/context/TimezoneProvider";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { useAuditData } from "@/pages/organization-settings/account/AuditPage/hooks/useAuditData";

export const useAuditGrid = () => {
  const { setConfigurationData, ...context } = useAuditLogsContext();
  const { data, isFetching } = useAuditData();
  const { users } = useAccountUsersContext();
  const { timeZoneName: timezoneName } = useTimezone();
  const { format } = useDateFormatter({ timeZoneName: undefined });

  const handleOnPaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setConfigurationData({
        page: model.page,
        pageSize: model.pageSize,
      });
    },
    [setConfigurationData]
  );

  const handleOnSortModelChange = useCallback(
    (model: GridSortModel) => {
      setConfigurationData({
        sort: sortingModelToString(model),
      });
    },
    [setConfigurationData]
  );

  const columns = useMemo(() => {
    // The table's data "sits" in data.logs (data is a paginated object which is the response from the API)
    // If the data is not fetched yet, we show a skeleton loader
    // When fetched, data should be an object with a logs key, which is an array of logs
    const isLoading = isFetching || !data;
    return getColumnsDefinition({ timezoneName, format }).map((column) => ({
      ...column,
      renderCell: isLoading
        ? () => <Skeleton width={column.skeletonWidth} />
        : column.renderCell,
    }));
  }, [isFetching, data, timezoneName, format]);

  const rows = useMemo(() => {
    if (!data?.logs) {
      return getMockRows();
    }

    return data.logs.map((log) => {
      const user = users[log.userId];
      return {
        ...log,
        user: user?.email ?? log.userId,
      };
    });
  }, [data, users]);

  const paginationModel = useMemo(() => {
    return {
      page: context.page ?? 0,
      pageSize: context.pageSize ?? DEFAULT_PAGE_SIZE,
    };
  }, [context.page, context.pageSize]);

  const sortModel = useMemo(() => {
    return sortingStringToModel(context.sort ?? "");
  }, [context.sort]);

  return {
    columns,
    rows,
    handleOnPaginationModelChange,
    handleOnSortModelChange,
    paginationModel,
    sortModel,
    totalCount: data?.totalCount || 0,
  };
};
