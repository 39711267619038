import { useQuery } from "@tanstack/react-query";

import {
  apiV1ClustersNodesDataGetUrl,
  ClustersApiApiV1ClustersNodesDataGetRequest,
  ClustersNodesDataResponse,
} from "@/generated/metricsApi";
import { useMetricsApiClient } from "@/shared/hooks/metrics-api/client/apiClient";

export const METRICS_CLUSTERS_NODES_DATA_ENDPOINT = "/clusters/nodes-data";

export const useFetchNodesData = (
  params: ClustersApiApiV1ClustersNodesDataGetRequest,
  enabled: boolean
) => {
  const apiClient = useMetricsApiClient();
  const url = apiV1ClustersNodesDataGetUrl(
    params,
    apiClient.defaults.baseURL ?? ""
  );

  return useQuery(
    [METRICS_CLUSTERS_NODES_DATA_ENDPOINT, params],
    async () => await apiClient.get<ClustersNodesDataResponse>(url),
    { enabled: enabled }
  );
};
