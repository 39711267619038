import React, { useRef } from "react";
import styled from "styled-components";

import { DependenciesMap } from "../../../ResourceDependenciesPage/DependenciesMap";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useGetNormalizedNetworkMapNodes } from "../../../ResourceDependenciesPage/hooks/useGetNormalizedNetworkMapNodes";
import { useNetworkMapNodes } from "../../../ResourceDependenciesPage/hooks/useNetworkMapNodes";
import { useNetworkMapNodesData } from "../../../ResourceDependenciesPage/hooks/useNetworkMapNodesData";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DependenciesTabProps } from "./DependenciesTab";

const Container = styled.div`
  height: 95vh;
`;

export const DependenciesTabContent: React.FC<DependenciesTabProps> =
  React.memo(
    ({ resource }) => {
      const divRef = useRef<HTMLDivElement>(null);
      const { data: networkNodes } = useNetworkMapNodes(resource);
      const networkNodesData = useNetworkMapNodesData(
        networkNodes?.nodes ?? []
      );

      const nodesData = useGetNormalizedNetworkMapNodes({
        resource,
        networkNodes,
        networkNodesData,
      });

      return (
        <div ref={divRef}>
          {nodesData?.nodes && (
            <Container>
              <DependenciesMap
                nodes={nodesData.nodes}
                edges={nodesData.edges}
              ></DependenciesMap>
            </Container>
          )}
        </div>
      );
    },
    (prevProps: DependenciesTabProps, nextProps: DependenciesTabProps) => {
      const { resource: prevResource } = prevProps;
      const { resource: nextResource } = nextProps;
      return prevResource.id === nextResource.id;
    }
  );
