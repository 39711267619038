import React, { useEffect, useState } from "react";

import { Action, EventDetailsContainer, Change, From, To } from "../common";
import EventDetailsSection from "../components/EventDetailsSection";
import EventDetailsTitle from "../components/EventDetailsTitle";
import detailsIcon from "../../assets/details.svg";
import configChangesIcon from "../../assets/config-changes.svg";
import { TextBolder, Text } from "../../../typography";
import { useDateFormatter } from "../../../../../shared/hooks/useDateFormatter";
import Modal from "../../../Modal";
import { ObjectDiff } from "../../../ObjectDiff";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import HpaEventGroup from "../../../EventGroup/commonEventGroup/HpaEvents/HpaEventGroup";
import { useResourcesAPIGet } from "../../../../../shared/hooks/resources-api/client";
import { CONFIGURATION_HPAS_EVENT_ID } from "../../../../../shared/hooks/resources-api/requestResponseMaps";

import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

interface HpaDetailsProps {
  eventGroup: HpaEventGroup;
}

interface HpaData {
  [key: string]: string | Record<string, string>;
  data: Record<string, string>;
}

const HpaDetailsComponent: React.FC<HpaDetailsProps> = ({ eventGroup }) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { format } = useDateFormatter();
  const [modalOpen, setModalOpen] = useState(false);

  const firstEvent = eventGroup.events[0];
  const eventId = firstEvent.id;
  const oldReplicas = firstEvent.oldDesiredReplicas
    ? String(firstEvent.oldDesiredReplicas)
    : null;
  const newReplicas = firstEvent.newDesiredReplicas
    ? String(firstEvent.newDesiredReplicas)
    : null;

  const { data, loading, error } = useResourcesAPIGet(
    CONFIGURATION_HPAS_EVENT_ID,
    {
      id: eventId,
      fields: ["newSpec", "oldSpec"],
    }
  );

  useEffect(() => {
    reportLoadingState("eventHPADetails", loading);
  }, [loading, reportLoadingState]);

  if (loading) {
    return (
      <EventDetailsContainer>
        <EventDetailsTitle title={"Fetching data..."} />
      </EventDetailsContainer>
    );
  }

  if (error) {
    return (
      <EventDetailsContainer>
        <EventDetailsTitle title={"Failed to fetch data."} />
      </EventDetailsContainer>
    );
  }

  const oldHpaSpec = data?.data[0].oldSpec as HpaData;
  const newHpaSpec = data?.data[0].newSpec as HpaData;

  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={eventGroup.summary} />
      <EventDetailsSection
        icon={detailsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Details"
      >
        <TextBolder>Change time</TextBolder>
        <br />
        <Text>{format(eventGroup.endTime)}</Text>
      </EventDetailsSection>
      <EventDetailsSection
        title="Changes"
        icon={configChangesIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
      >
        {oldReplicas && newReplicas && oldReplicas !== newReplicas ? (
          <Change key="Desired Replicas">
            <div>Desired Replicas</div>
            {<From title={oldReplicas}>{oldReplicas}</From>}
            {<To title={newReplicas}>{newReplicas}</To>}
          </Change>
        ) : null}
        <br />
        <Action onClick={() => setModalOpen(true)}>View diff</Action>
      </EventDetailsSection>
      <Modal
        isOpen={modalOpen}
        handleCloseModal={() => setModalOpen(false)}
        title={eventGroup.summary}
      >
        <ObjectDiff oldObj={oldHpaSpec} newObj={newHpaSpec} />
      </Modal>
    </EventDetailsContainer>
  );
};

export const HpaDetails: React.FC<HpaDetailsProps> = ({ eventGroup }) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.eventHPA,
        context: {
          feTeam: "troubleshooting",
          beTeam: "barzelim",
        },
      }}
    >
      <HpaDetailsComponent eventGroup={eventGroup} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
