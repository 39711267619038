export const TIME_RANGE_OPTIONS = [
  {
    label: "Last Hour",
    value: "1h",
  },
  {
    label: "Last 8 Hours",
    value: "8h",
  },
  {
    label: "Last 24 Hours",
    value: "1d",
  },
  {
    label: "Last 7 Days",
    value: "7d",
  },
  {
    label: "Last 30 Days",
    value: "30d",
  },
];

export const STATUS_OPTIONS = [
  { value: "success", label: "Success" },
  { value: "failure", label: "Failure" },
];

export const DEFAULT_TIME_RANGE = TIME_RANGE_OPTIONS[1];
export const DEFAULT_PAGE_SIZE = 10;

export const auditPageAriaLabels = {
  header: "audit page header",
  table: "audit table",
  filters: {
    entityName: "entity name filter",
    timeRange: "time range filter",
    eventType: "event type filter",
    action: "action filter",
    resourceType: "resource type filter",
    user: "user filter",
    status: "status filter",
  },
  exportButton: "export button",
  drawer: {
    close: "audit log drawer close",
    container: "audit log drawer container",
    details: {
      dictionaryContainer: "audit log details dictionary container",
      comparisonContainer: "audit log details comparison container",
    },
  },
};

export const K8S_RESOURCE_TOOLTIP = "This is a K8s resource";
