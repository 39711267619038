import { Timeframe } from "../types/TimeWindow";
import { defaultLabels } from "../../components/common/DateTimeSelector";

export const KOMODOR_LOADING_TIME = "komodor_loading_time";

const toSnakeCase = (str: string) =>
  str
    .replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");

export const getTimeWindowLabel = (timeframe: Timeframe) =>
  toSnakeCase(defaultLabels[timeframe]);
