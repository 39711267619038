import useGetAccountPlanData from "../useGetAccountPlanData";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { restrictionProps } from ".";

export const hasReachedUserLimit = (
  usersLimit: number | undefined | null,
  currentValue: number | undefined,
  isTrialEnded: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): boolean => {
  // null means unlimited
  if (isTrialEnded) return true;
  if (usersLimit === null) return false;
  if (usersLimit === undefined) return true;
  if (currentValue === undefined) return true;
  return currentValue >= usersLimit;
};

export const useUsersRestrictionsData = (): restrictionProps => {
  const plansData = useGetAccountPlanData();
  return {
    modalTitle: "Upgrade to get more users",
    modalContent: `You are now using a Free Plan. \n In order to get more than ${plansData?.usersLimit} users, \nyou have to upgrade.`,
    messageTitle: "Upgrade to add more users",
    messageContent: `You can have up to ${plansData?.usersLimit} with your Free Plan. Ready to upgrade?
  `,
  };
};
